import React, { useState } from 'react';
import { useMutation } from 'react-query';

import { BookingsAndCreditsWorkflow, Tracking } from '../../../amplitude';
import { createCheckoutSession, CreateCheckoutSessionInput } from '../../../API/checkoutSessions';
import { AuthProvider } from '../../../Authentication/Authentication';
import Alert, { AlertData, errorAlert } from '../../../shared/Alert';
import Button from '../../../shared/Button/Button';
import Modal from '../../../shared/Modal/Modal';
import useLockBodyScroll from '../../../shared/Modal/useLockBodyScroll';
import { redirectToExternalUrl } from '../../../shared/routing/routing';
import { MonthlyLedger } from './PastMonthsLedgerTable';

type PayBalanceModalProps = {
  authProvider: AuthProvider;
  practiceId: string;
  bookingPeriodToDisplay: string;
  balanceToDisplay: string;
  selectedMonthlyLedger: MonthlyLedger;
  onCancel: () => void;
} & Tracking;

const PayBalanceModal: React.FC<PayBalanceModalProps> = ({
  authProvider,
  practiceId,
  bookingPeriodToDisplay,
  balanceToDisplay,
  selectedMonthlyLedger,
  onCancel,
}) => {
  const [alert, setAlert] = useState<AlertData | null>(null);
  useLockBodyScroll(true);

  const checkoutMutation = useMutation(createCheckoutSession, {
    onSuccess: (res) => {
      redirectToExternalUrl(res.redirectUrl);
    },
    onError: (error) => {
      setAlert(errorAlert(error as any));
    },
  });

  const { isLoading: isCreatingCheckoutSession } = checkoutMutation;

  const renderPaymentDisclaimer = () => {
    return (
      <p className={`mt-4 text-xs 'opacity-100'`}>
        {`By clicking the 'Continue to Stripe' button below, you will start the process of paying
          your booking charge balance from the month of ${bookingPeriodToDisplay}.`}
      </p>
    );
  };

  const onContinueToStripeClicked = () => {
    let input: CreateCheckoutSessionInput;
    const [year, month] = selectedMonthlyLedger.monthYear.split('-');
    const balanceMonthToPay = parseInt(month, 10);
    const balanceYearToPay = parseInt(year, 10);

    input = {
      type: 'single-month',
      month: balanceMonthToPay,
      year: balanceYearToPay,
    };
    checkoutMutation.mutate({
      authProvider,
      practiceId,
      input,
    });
  };

  return (
    <Modal isOpen onRequestClose={onCancel} shape="square">
      <div id={'pay-balance-modal-content'} className={'flex flex-col gap-3 text-base-content'}>
        <span className={'text-lg font-bold text-left mb-2'}>Pay Balance</span>
        {alert && <Alert {...alert} />}
        <div
          id={'balance-info'}
          className={
            'flex flex-col gap-1 font-bold text-center rounded-md bg-primary bg-opacity-25 border border-opacity-30 py-4'
          }
        >
          <span className={'text-lg'}>{`${bookingPeriodToDisplay} Balance`}</span>
          <span className={'text-2xl'}>{balanceToDisplay}</span>
        </div>
        {renderPaymentDisclaimer()}
        <div id={'action-buttons'} className={'flex flex-row gap-4 mb-2 justify-center w-full'}>
          <Button
            onClick={onContinueToStripeClicked}
            className="bg-secondary-content text-white hover:opacity-75 border-0"
            loading={isCreatingCheckoutSession}
            workflow={BookingsAndCreditsWorkflow}
            trackingLabel="Continue to Stripe Button"
            context="payBookingBalanceModal"
          >
            Continue to Stripe
          </Button>
          <Button
            onClick={onCancel}
            disabled={isCreatingCheckoutSession}
            className="border border-rule text-primary hover:opacity-75 bg-white shadow-none"
            workflow={BookingsAndCreditsWorkflow}
            trackingLabel="Cancel Button"
            context="payBookingBalanceModal"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PayBalanceModal;
