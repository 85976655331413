import React from 'react';

import { Appointment } from '../../ServiceContext/appointments';
import { Timestamp } from '../../ServiceContext/shared';
import { toShortDateTimeString } from '../../shared/dates/dates';
import { formatPhoneNumber } from '../../shared/strings';

type Props = {
  appointment: Appointment;
  rescheduleTime: Timestamp;
};
const RescheduleConfirmationModalContent: React.FC<Props> = ({ appointment, rescheduleTime }) => {
  if (!appointment.user) {
    return null;
  }
  return (
    <div
      id={'reschedule-confirmation-modal-content'}
      className={'flex flex-col gap-3 bg-highlight border border-primary rounded-md'}
    >
      <span className="font-semibold text-secondary text-lg">
        Please contact this patient to reschedule
      </span>
      <div
        className={
          'flex flex-col gap-1.5 border border-rule rounded-lg bg-blue-50 p-2 text-neutral'
        }
      >
        <span className={'text-sm font-semibold'}>
          {appointment.user.firstName} {appointment.user.lastName}
        </span>
        <span className={'text-xs'}>{`${toShortDateTimeString(
          rescheduleTime,
          appointment.timeZone
        )} with ${appointment.dentist.firstName} ${appointment.dentist.lastName}`}</span>
      </div>
      <ul className="p-0 text-xs">
        <li className="text-secondary mt-3">
          <div className="flex justify-between">
            <span className={'font-semibold'}>Name:</span>
            <span>
              {appointment.user.firstName} {appointment.user.lastName}
            </span>
          </div>
        </li>
        <div className="bg-base-200 h-px my-2"></div>
        <li className="text-secondary">
          <div className="flex justify-between">
            <span className={'font-semibold'}>Phone number:</span>
            <span>{formatPhoneNumber(appointment.user.phone)}</span>
          </div>
        </li>
        <div className="bg-base-200 h-px my-2"></div>
        <li className="text-secondary">
          <div className="flex justify-between">
            <span className={'font-semibold'}>Email:</span>
            <span>{appointment.user.email}</span>
          </div>
        </li>
        <div className="bg-base-200 h-px my-2"></div>
        <li className="text-secondary">
          <div className="flex justify-between">
            <span className={'font-semibold'}>Preferred contact method:</span>
            <span>
              {appointment.user.preferredContactMethod
                ? appointment.user.preferredContactMethod
                : 'Not specified'}
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default RescheduleConfirmationModalContent;
