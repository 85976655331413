import React, { useState } from 'react';
import Button from '../shared/Button/Button';
import Alert, { AlertData, errorAlert } from '../shared/Alert';
import { Dentist } from '../ServiceContext/user';
import { useServices } from '../ServiceContext/ServiceContext';
import { dateStringWithoutTimezone, toShortDateTimeString } from '../shared/dates/dates';
import { DateTime } from 'luxon';
import { isErrorResponse } from '../API/response';
import { AvailabilityWorkflow } from '../amplitude';
import { getTimeZoneOfDentist } from '../shared/timezone/timeZone';

interface Props {
  event: EventDeletionModel;
  dentist: Dentist;
  onCancelled: () => void;
  onDeletionConfirmed: () => void;
}

export interface EventDeletionModel {
  id: string;
  title: string;
  start: Date;
  end: Date;
}

const DeleteAvailabilityEvent: React.FC<Props> = ({
  event,
  dentist,
  onCancelled,
  onDeletionConfirmed,
}) => {
  const services = useServices();

  const [alert, setAlert] = useState<AlertData | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const onConfirmClicked = async () => {
    setIsDeleting(true);
    const res = await services.calendarService.deleteEvent(dentist.id, event.id);
    setIsDeleting(false);

    if (isErrorResponse(res)) {
      setAlert(errorAlert(res.errorResponse));
      return;
    }

    onDeletionConfirmed();
  };

  const renderTopControlButtons = () => {
    return (
      <div className="delete-availability-modal-control-buttons flex gap-3">
        <Button
          id="confirm-deletion-button"
          onClick={onConfirmClicked}
          loading={isDeleting}
          disabled={isDeleting}
          omitBorder
          workflow={AvailabilityWorkflow}
          trackingLabel="Delete Availability Confirm Button"
          context="deleteAvailabilityEvent"
        >
          Confirm
        </Button>
        <Button
          id="cancel-deletion-button"
          onClick={onCancelled}
          noFill
          workflow={AvailabilityWorkflow}
          trackingLabel="Cancel Delete Availability Button"
          context="deleteAvailabilityEvent"
        >
          Cancel
        </Button>
      </div>
    );
  };

  const renderAlert = () => {
    if (alert) {
      return <Alert {...alert} />;
    }

    return null;
  };

  const renderConfirmationText = () => {
    const timeZone = getTimeZoneOfDentist(dentist);

    let startTimeInOfficeTz = DateTime.fromISO(dateStringWithoutTimezone(event.start));
    startTimeInOfficeTz = startTimeInOfficeTz.setZone(timeZone);
    let endTimeInOfficeTz = DateTime.fromISO(dateStringWithoutTimezone(event.end));
    endTimeInOfficeTz = endTimeInOfficeTz.setZone(timeZone);

    return (
      <div className="delete-availability-modal-confirmation-text">
        Are you sure you want to delete <strong>"{event.title}"</strong>, happening from&nbsp;
        <strong>{toShortDateTimeString(startTimeInOfficeTz)}</strong>
        &nbsp;to <strong>{toShortDateTimeString(endTimeInOfficeTz)}</strong>?
      </div>
    );
  };

  return (
    <div className="delete-availability-modal-content flex flex-col justify-start items-start text-[#5a5a5a] mx-2 my-2">
      <div className="header flex flex-row justify-between w-full mb-4">
        <div className="modal-title flex flex-row">
          <h5 className="title font-bold text-[26px]">Delete Availability</h5>
        </div>
        {renderTopControlButtons()}
      </div>
      {renderAlert()}
      {renderConfirmationText()}
    </div>
  );
};

export default DeleteAvailabilityEvent;
