import React, { createContext, PropsWithChildren, useContext } from 'react';

import { AuthProvider } from '../Authentication/Authentication';
import createAppointmentsService, { AppointmentsService } from './appointments';
import createCalendarService, { CalendarService } from './calendar';
import createDentistsService, { DentistsService } from './dentist';
import createManagersService, { ManagersService } from './managers';
import createMessagesService, { MessagesService } from './messages';
import createPatientsService, { PatientsService } from './patients';
import createPracticesService, { PracticesService } from './practices';
import createProceduresService, { ProceduresService } from './procedures';

export interface Services {
  appointmentsService: AppointmentsService;
  patientsService: PatientsService;
  proceduresService: ProceduresService;
  dentistsService: DentistsService;
  calendarService: CalendarService;
  practicesService: PracticesService;
  messagesService: MessagesService;
  managersService: ManagersService;
}

const serviceContext = createContext<Services>(undefined!);

export function useServices() {
  return useContext(serviceContext);
}

type Props = {
  authentication: AuthProvider;
};

const ServiceContext: React.FC<PropsWithChildren<Props>> = ({ children, authentication }) => {
  const services = {
    appointmentsService: createAppointmentsService(authentication),
    patientsService: createPatientsService(authentication),
    proceduresService: createProceduresService(authentication),
    dentistsService: createDentistsService(authentication),
    calendarService: createCalendarService(authentication),
    practicesService: createPracticesService(authentication),
    messagesService: createMessagesService(authentication),
    managersService: createManagersService(authentication),
  };
  return <serviceContext.Provider value={services}>{children}</serviceContext.Provider>;
};

export default ServiceContext;
