import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { authenticatedGet, authenticatedNoContentPost, authenticatedPost } from './service';
import { apiUrl, Timestamp } from './shared';

export type MessageType =
  | 'new_booked_appointment'
  | 'appointment_reschedule'
  | 'appointment_cancellation'
  | 'referral_credit_applied'
  | 'new_bookings_credits_bill';

export type MessageTarget = 'portal-app' | 'portal-web-push' | 'email' | 'sms';

export type Message =
  | BookedAppointmentMessage
  | AppointmentRescheduleMessage
  | AppointmentCancellationMessage
  | ReferralCreditAppliedMessage
  | NewBookingsCreditsBillMessage;

type BaseMessage = {
  id: string;
  target: MessageTarget;
  recipientType: 'manager' | 'dentist' | 'practice';
  recipientId: string;
  sentAt: Timestamp;
  readAt: Timestamp | null;
  clearedAt: Timestamp | null;
  createdAt: Timestamp;
};

export type BookedAppointmentMessage = BaseMessage & {
  type: 'new_booked_appointment';
  payload: {
    patientName: string;
    dentistName: string;
    appointmentId: string;
    timeZone: string;
    start: string;
  };
};

export type AppointmentRescheduleMessage = BaseMessage & {
  type: 'appointment_reschedule';
  payload: {
    patientName: string;
    dentistName: string;
    appointmentId: string;
    timeZone: string;
    previousStart: string;
    updatedStart: string;
  };
};

export type AppointmentCancellationMessage = BaseMessage & {
  type: 'appointment_cancellation';
  payload: {
    patientName: string;
    dentistName: string;
    appointmentId: string;
    timeZone: string;
    start: string;
    cancellationReason: string;
    cancellationNotes: string;
  };
};

export type ReferralCreditAppliedMessage = BaseMessage & {
  type: 'referral_credit_applied';
  payload: {
    patientName: string;
    referralCreditId: string;
    creditAmount: number;
    appointmentId: string;
  };
};

export type NewBookingsCreditsBillMessage = BaseMessage & {
  type: 'new_bookings_credits_bill';
  payload: {
    period: string;
  };
};

export interface MessagesService {
  getMessages: ({ managerId }: { managerId: string }) => Promise<Message[] | ErrorResponse>;
  markMessageAsRead: ({
    notificationId,
  }: {
    notificationId: string;
  }) => Promise<Message | ErrorResponse>;
  markMessageAsCleared: ({
    notificationId,
  }: {
    notificationId: string;
  }) => Promise<Message | ErrorResponse>;
  markAllMessagesAsCleared: ({ id }: { id: string }) => Promise<null | ErrorResponse>;
}

const getMessages = (authProvider: AuthProvider) => {
  return async ({ managerId }: { managerId: string }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedGet<Message[]>(
      authProvider,
      apiUrl(`/managers/${managerId}/notifications?notificationTarget=portal-app`)
    );
  };
};

const markMessageAsRead = (authProvider: AuthProvider) => {
  return async ({ notificationId }: { notificationId: string }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedPost<Message>(
      authProvider,
      apiUrl(`/notifications/${notificationId}/markRead`),
      {}
    );
  };
};

const markAllMessagesAsCleared = (authProvider: AuthProvider) => {
  return async ({ id }: { id: string }) => {
    const authUser = authProvider.authUser;
    const formData = new FormData();
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedNoContentPost(
      authProvider,
      apiUrl(`/managers/${id}/notifications/markCleared`),
      formData
    );
  };
};

const markMessageAsCleared = (authProvider: AuthProvider) => {
  return async ({ notificationId }: { notificationId: string }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedPost<Message>(
      authProvider,
      apiUrl(`/notifications/${notificationId}/markCleared`),
      {}
    );
  };
};

const makeService = (auth: AuthProvider) => {
  return {
    getMessages: getMessages(auth),
    markMessageAsRead: markMessageAsRead(auth),
    markMessageAsCleared: markMessageAsCleared(auth),
    markAllMessagesAsCleared: markAllMessagesAsCleared(auth),
  };
};

export default makeService;
