import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppointmentWorkflow, ClickTrackingAction, runFTrack } from '../../../amplitude';

/**
 * Header component for the Appointment Details page.
 *
 * @returns {JSX.Element} The rendered component.
 */
const AppointmentDetailsHeader: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="title-and-controls font-sans text-secondary flex flex-row justify-between w-full pb-2">
      <div className="details-title font-medium text-2xl">Appointment Details</div>
      <div
        className="text-sm font-medium cursor-pointer"
        onClick={() => {
          runFTrack({
            event: 'Press Return To Appointments Button',
            workflow: AppointmentWorkflow,
            action: ClickTrackingAction,
            context: 'appointmentDetailsHeader',
            componentId: 'returnToAppointmentsButton',
          });
          navigate(`/appointments/`);
        }}
      >
        <span className={'text-base-content-navigation'}>
          <span>&larr;</span> Return to Appointments
        </span>
      </div>
    </div>
  );
};

export default AppointmentDetailsHeader;
