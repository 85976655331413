import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { QueryClient, QueryKey, useMutation, useQuery } from 'react-query';

import { BookingWorkflow } from '../../amplitude';
import {
  createPatientInsuranceForAppointment,
  getInsuranceCarriers,
  InsuranceCarrier,
} from '../../API/insurance';
import { ErrorResponse } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import { Appointment } from '../../ServiceContext/appointments';
import Alert, { AlertData, errorAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import FlashingMessage from '../../shared/FlashingMessage/FlashingMessage';
import SearchSelect from '../../shared/SearchSelect/SearchSelect';
import ActionButton from './ActionButton';
import ActionButtonSpinner from './ActionButtonSpinner';

type Props = {
  authProvider: AuthProvider;
  appointment: Appointment;
  onAppointmentUpdated: (newValue: Appointment) => void;
  onCancel: () => void;
  queryContext: {
    client: QueryClient;
    key: QueryKey;
  };
};

const CreatePatientInsuranceForm: React.FC<Props> = ({
  authProvider,
  appointment,
  onAppointmentUpdated,
  onCancel,
  queryContext,
}) => {
  const [alert, setAlert] = useState<AlertData | null>(null);

  const getInsuranceCarriersQueryKey = 'getInsuranceCarriers';
  const {
    isLoading: isLoadingInsuranceCarriers,
    error: fetchInsuranceCarriersError,
    data: insuranceCarriers,
  } = useQuery<InsuranceCarrier[], ErrorResponse>(
    getInsuranceCarriersQueryKey,
    () => {
      return getInsuranceCarriers({
        authProvider,
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setAlert(fetchInsuranceCarriersError ? errorAlert(fetchInsuranceCarriersError) : null);
  }, [fetchInsuranceCarriersError]);

  const [insuranceCarrierId, setInsuranceCarrierId] = useState<string | null>(null);
  const [patientFirstName, setPatientFirstName] = useState('');
  const [patientLastName, setPatientLastName] = useState('');
  const [memberId, setMemberId] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);

  const isFormFilled: boolean = Boolean(
    insuranceCarrierId && patientFirstName && patientLastName && memberId && dateOfBirth
  );

  const createInsuranceMutation = useMutation(createPatientInsuranceForAppointment, {
    onSuccess: (data) => {
      onAppointmentUpdated({
        ...appointment,
        patientInsuranceId: data.id,
      });
      queryContext.client.setQueryData(queryContext.key, data);
    },
    onError: (error) => {
      setAlert(errorAlert(error as any));
    },
  });

  const { isLoading: isCreatingPatientInsurance } = createInsuranceMutation;

  const handleSubmitForVerification = (e: React.FormEvent) => {
    if (!isFormFilled) {
      return;
    }

    e.preventDefault();

    const formattedDOB = dateOfBirth ? DateTime.fromJSDate(dateOfBirth).toISODate() : '';

    createInsuranceMutation.mutate({
      authProvider,
      appointmentId: appointment.id,
      insuranceCarrierId: insuranceCarrierId!,
      patientFirstName,
      patientLastName,
      memberId,
      dateOfBirth: formattedDOB!,
    });
  };

  return (
    <form
      onSubmit={handleSubmitForVerification}
      className="pt-4 pl-4 pr-4 pb-20 bg-white rounded-md relative"
    >
      <span className="pl-6 text-md italic">
        Please fill out the details of the patient's insurance.
      </span>
      {alert && <Alert {...alert} />}

      <div className="p-4 bg-white rounded-md">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="firstName">
            Insurer
          </label>
          <SearchSelect
            id="insurance-carrier-select"
            aria-label="insurance carrier select"
            overlayPlatformCustomClass="w-full"
            componentContainerCustomClass="w-full"
            inputCustomClass={`shadow appearance-none border rounded w-full py-2 px-3 ${
              isCreatingPatientInsurance ? 'bg-base-200 cursor-not-allowed' : ''
            }`}
            options={(insuranceCarriers || []).map((c) => ({
              id: c.id,
              label: c.name,
            }))}
            loading={isLoadingInsuranceCarriers}
            defaultValue={null}
            onChange={(value) => {
              setInsuranceCarrierId(value ? value.id : null);
            }}
            placeholder="Select an Insurer"
            allowClear={true}
            includeAngle
            disabled={isCreatingPatientInsurance}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="firstName">
            Patient First Name
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 ${
              isCreatingPatientInsurance ? 'bg-base-200 cursor-not-allowed' : ''
            }`}
            id="firstName"
            type="text"
            value={patientFirstName}
            onChange={(e) => setPatientFirstName(e.target.value)}
            placeholder="Enter First Name"
            disabled={isCreatingPatientInsurance}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="lastName">
            Patient Last Name
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 ${
              isCreatingPatientInsurance ? 'bg-base-200 cursor-not-allowed' : ''
            }`}
            id="lastName"
            type="text"
            value={patientLastName}
            onChange={(e) => setPatientLastName(e.target.value)}
            placeholder="Enter Last Name"
            disabled={isCreatingPatientInsurance}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="memberId">
            Member ID
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 ${
              isCreatingPatientInsurance ? 'bg-base-200 cursor-not-allowed' : ''
            }`}
            id="memberId"
            type="text"
            value={memberId}
            onChange={(e) => setMemberId(e.target.value)}
            placeholder="Enter Member ID"
            disabled={isCreatingPatientInsurance}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="dateOfBirth">
            Date of Birth
          </label>
          <DatePicker
            selected={dateOfBirth}
            placeholderText="Enter Date of Birth"
            autoComplete="off"
            onChange={(date: Date) => setDateOfBirth(date)}
            dateFormat="MMMM d, yyyy"
            className={`shadow appearance-none border rounded w-full py-2 px-3 ${
              isCreatingPatientInsurance ? 'bg-base-200 cursor-not-allowed' : ''
            }`}
            disabled={isCreatingPatientInsurance}
          />
        </div>
      </div>

      <div className="flex flex-col items-end space-y-4 absolute bottom-4 right-4">
        {isCreatingPatientInsurance ? (
          <FlashingMessage
            isItalic
            message="This process can take up to a minute. Please do not close the modal."
          />
        ) : null}
        <div className="flex space-x-4">
          <Button
            onClick={onCancel}
            noFill
            workflow={BookingWorkflow}
            trackingLabel="Cancel Create Patient Insurance Button"
            context="createPatientInsuranceForm"
          >
            Cancel
          </Button>
          <ActionButton
            disabled={!isFormFilled || isCreatingPatientInsurance}
            className="flex items-center"
          >
            {isCreatingPatientInsurance ? <ActionButtonSpinner /> : null}
            Submit for Verification
          </ActionButton>
        </div>
      </div>
    </form>
  );
};

export default CreatePatientInsuranceForm;
