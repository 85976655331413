import React from 'react';

import { ErrorResponse } from '../../API/response';
import Alert, { errorAlert } from '../Alert';

type Props = {
  errorResponse: ErrorResponse | null;
};

const APIErrorAlert: React.FC<Props> = ({ errorResponse }) => {
  if (!errorResponse) {
    return null;
  }

  return <Alert {...errorAlert(errorResponse.errorResponse)} />;
};

export default APIErrorAlert;
