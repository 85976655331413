import '@stripe/stripe-js';
import 'react-toastify/dist/ReactToastify.min.css';

import Hotjar from '@hotjar/browser';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Authentication from './Authentication/Authentication';
import { SelectedDentistProvider } from './Dentists/SelectedDentistContext';
import ForceRefresh from './ForceRefresh';
import Frame from './Frame/Frame';
import GATracking from './GATracking';
import HotkeyCommander from './HotkeyCommander';
import Router from './Routing/Router';
import RoutingContext from './Routing/RoutingContext';
import ServiceContext from './ServiceContext/ServiceContext';
import AppVersionWatcher from './shared/AppVersionWatcher';
import CheckTermsOfService from './termsOfService/CheckTermsOfService';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_APP_ID || '');

const queryClient = new QueryClient();

const siteId = process.env.REACT_APP_HOTJAR_ID;
const hotjarVersion = 6;

if (siteId !== undefined) Hotjar.init(Number(siteId), hotjarVersion);

function App() {
  return (
    <div className="flossy-app text-center text-base-content">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ForceRefresh>
            <AppVersionWatcher>
              <Authentication>
                {({ authProvider }) => (
                  <Elements stripe={stripePromise}>
                    <ServiceContext authentication={authProvider}>
                      <CheckTermsOfService authentication={authProvider}>
                        <RoutingContext>
                          <GATracking>
                            <ToastContainer />
                            <Frame authProvider={authProvider}>
                              <HotkeyCommander authProvider={authProvider}>
                                <SelectedDentistProvider>
                                  <Router authentication={authProvider} />
                                </SelectedDentistProvider>
                              </HotkeyCommander>
                            </Frame>
                          </GATracking>
                        </RoutingContext>
                      </CheckTermsOfService>
                    </ServiceContext>
                  </Elements>
                )}
              </Authentication>
            </AppVersionWatcher>
          </ForceRefresh>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
