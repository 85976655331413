import React from 'react';
import { useNavigate } from 'react-router-dom';

import { InvoicingWorkflow } from '../amplitude';
import { useInvoicePrintout } from '../Appointments/Invoicing/PrintComponents/usePrint';
import SavingsSummary from '../Appointments/Invoicing/SavingsSummary';
import { extractInvoicingComponents } from '../Appointments/Invoicing/utilities';
import { Appointment } from '../ServiceContext/appointments';
import {
  cardLastFourFromPayment,
  isLegacyPayment,
  isSplitPayment,
  LegacyPaymentSummary,
  PaymentSplitSummary,
} from '../ServiceContext/invoices';
import Button from '../shared/Button/Button';
import { toShortDateString } from '../shared/dates/dates';
import { isNumber } from '../shared/math/math';
import InvoiceItemsTable from './InvoiceItemsTable';
import ReturnToAppointments from './ReturnToAppointments';

type Props = {
  appointment: Appointment;
};
const InvoiceManager: React.FC<Props> = ({ appointment }) => {
  const navigate = useNavigate();

  const { invoice, payment } = appointment;

  const isInsuranceAppointment =
    Boolean(appointment.patientInsuranceId) ||
    (isLegacyPayment(appointment.payment) && appointment.payment.paymentType === 'insurance');

  const patientPricingTotal = appointment.invoice?.invoiceItems
    .filter((e) => e.type === 'procedure')
    .reduce(
      (accumulator, currentValue) =>
        accumulator + (currentValue.patientPrice ?? currentValue.amount),
      0
    );
  const retailPricingTotal =
    appointment.invoice?.pricingQuote?.items.reduce(
      (accumulator, currentValue) => accumulator + currentValue.anchorPrice,
      0
    ) || 0;

  let paymentLastFour: string | undefined = undefined;
  let paymentSplits: PaymentSplitSummary[] = [];
  let legacyPayment: LegacyPaymentSummary | null = null;

  // TODO: We'll need to change this to account for however the invoice printout changes in a split payment world.
  if (payment) {
    if (isLegacyPayment(payment)) {
      paymentLastFour = cardLastFourFromPayment(payment);
      legacyPayment = {
        paymentType: payment.paymentType,
        paymentMethod: payment.paymentMethod,
        amount: payment.amount,
      };
    } else if (isSplitPayment(payment)) {
      // TODO: This is arbitrarily picking one of the cards from the splits. We'll need to change this once the printout changes
      //       to accommodate multiple payment splits.
      const cardSplit = payment.paymentSplits.find((s) => s.paymentType === 'credit-card');
      if (cardSplit) {
        paymentLastFour = cardLastFourFromPayment(cardSplit);
      }
      paymentSplits = payment.paymentSplits.map((ps) => {
        const { paymentType, paymentMethod, splitAmount } = ps;
        return { paymentType, paymentMethod, splitAmount };
      });
    }
  }

  const { printButton, printComponent } = useInvoicePrintout({
    appointmentTimeInUTC: appointment.start,
    appointmentTimeZone: appointment.timeZone,
    dentistLocation: appointment.location,
    invoice,
    dentistName:
      (appointment.dentist?.firstName || '') + ' ' + (appointment.dentist?.lastName || ''),
    patientName: (appointment.user?.firstName || '') + ' ' + (appointment.user?.lastName || ''),
    paymentLastFour: paymentLastFour || '',
    patientOnly: true,
    isInsurance: Boolean(appointment.patientInsuranceId),
    legacyPayment,
    paymentSplits,
  });

  if (!invoice) {
    return (
      <div id={'invoice-page'} className={'flex flex-col gap-3 text-base-content'}>
        <div id={'header'} className={'flex flex-row justify-between'}>
          <span id={'title'} className={'font-semibold text-2xl'}>
            Invoice Details
          </span>
          <ReturnToAppointments />
        </div>
        <div>
          <span className={'font-semibold text-2xl'}>No invoice found for this appointment.</span>
        </div>
      </div>
    );
  }

  const { savingsAmount } = extractInvoicingComponents({ invoice });

  const renderActionButtons = (appointment: Appointment) => {
    if (appointment.invoice?.isDraft) {
      return (
        <Button
          className={`h-[90%]`}
          onClick={() => navigate(`/appointments/${appointment.id}/invoicing`)}
          workflow={InvoicingWorkflow}
          context="invoiceManager"
          trackingLabel="Edit Invoice Button"
        >
          Edit Invoice
        </Button>
      );
    }
    return (
      <div className={'flex flex-row gap-2 h-[90%]'}>
        {printButton}
        <Button
          onClick={() => navigate(`/patients/${appointment.userId}/treatment-plans/new`)}
          id={'upload-treatment-plan-button'}
          workflow={InvoicingWorkflow}
          context="invoiceManager"
          trackingLabel="Add Treatment Plan Button"
        >
          Add Treatment Plan
        </Button>
        <Button
          id={'book-follow-up-button'}
          onClick={() => navigate(`/appointments/${appointment.id}/book-follow-up`)}
          workflow={InvoicingWorkflow}
          context="invoiceManager"
          trackingLabel="Book Follow Up Button"
        >
          Book Follow-up
        </Button>
      </div>
    );
  };

  return (
    <div id={'invoice-page'} className={'flex flex-col gap-3 text-base-content'}>
      <div id={'header'} className={'flex flex-row justify-between'}>
        <span id={'title'} className={'font-semibold text-2xl'}>
          {appointment.invoice?.isDraft ? 'Invoice Draft' : 'Invoice Details'}
        </span>
        <ReturnToAppointments />
      </div>
      <div id={'body'} className={'bg-white rounded-md flex flex-col gap-2'}>
        <div id={'info-and-controls'} className={'flex flex-row m-4 justify-between'}>
          <div id={'invoice-info'} className={'flex flex-col gap-1.5 text-left'}>
            <span className={'font-bold'}>
              {appointment.user?.firstName} {appointment.user?.lastName}
            </span>
            <span className={'text-sm'}>{toShortDateString(appointment.start)}</span>
          </div>
          {renderActionButtons(appointment)}
        </div>
        <div id={'items-list'}>
          <InvoiceItemsTable
            appointment={appointment}
            paymentSplits={paymentSplits}
            legacyPayment={legacyPayment}
            invoice={invoice}
            anchorPricingTotal={retailPricingTotal}
            patientPricingTotal={
              patientPricingTotal === 0 ? invoice.total ?? 0 : patientPricingTotal ?? 0
            }
            alternateRowStyle={true}
            usingInsurance={isInsuranceAppointment}
          />
        </div>
        {isInsuranceAppointment && (
          <div className="p-4 w-full text-start">*This appointment was paid with insurance.</div>
        )}
        {!isInsuranceAppointment && isNumber(savingsAmount) ? (
          <div className="pb-4">
            <SavingsSummary savingsAmount={savingsAmount} />
          </div>
        ) : null}
      </div>
      {printComponent}
    </div>
  );
};

export default InvoiceManager;
