import React, { PropsWithChildren, useEffect, useState } from 'react';

import { isErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { reportErrorToSentry } from '../ServiceContext/error';
import { TermsOfServiceConsentStatus } from '../ServiceContext/practices';
import { useServices } from '../ServiceContext/ServiceContext';
import TermsOfServiceModal from './TermsOfServiceModal';

type Props = {
  authentication: AuthProvider;
};

const CheckTermsOfService: React.FC<PropsWithChildren<Props>> = ({ children, authentication }) => {
  const services = useServices();

  const authUserTermsOfServiceStatus = authentication.termsOfServiceStatus;

  const [latestTermsOfServiceStatus, setLatestTermsOfServiceStatus] =
    useState<TermsOfServiceConsentStatus | null>(null);

  useEffect(() => {
    (async () => {
      if (
        authUserTermsOfServiceStatus &&
        authUserTermsOfServiceStatus.needsToAgreeToNewTermsOfService
      ) {
        const res = await services.practicesService.getTermsOfServiceConsentStatus({
          practiceId: authUserTermsOfServiceStatus.practiceIds[0],
        });
        if (isErrorResponse(res)) {
          reportErrorToSentry({
            summary: `Received error when trying to fetch the latest TOS consent status for practice`,
            extra: { ...res, ...authUserTermsOfServiceStatus },
          });
          return;
        }
        setLatestTermsOfServiceStatus(res);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUserTermsOfServiceStatus]);

  return (
    <div>
      {latestTermsOfServiceStatus && (
        <TermsOfServiceModal
          termsOfServiceStatus={latestTermsOfServiceStatus}
          onAgreedToTermsOfService={() => setLatestTermsOfServiceStatus(null)}
          authentication={authentication}
        />
      )}
      {children}
    </div>
  );
};

export default CheckTermsOfService;
