import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { ReferralWorkflow } from '../amplitude';
import { getPractice } from '../API/practice';
import {
  getPatientReferrals,
  getPatientReferralStats,
  PatientReferral,
  PatientReferralStats,
} from '../API/referrals';
import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { Practice } from '../ServiceContext/user';
import Alert, { errorAlert } from '../shared/Alert';
import Button from '../shared/Button/Button';
import Cookies from '../shared/cookies/cookies';
import Modal from '../shared/Modal/Modal';
import { useURLQuery } from '../shared/routing/routing';
import NewPagination from '../shared/Table/NewPagination';
import Table from '../shared/Table/Table';
import { isReferralUnused } from './helper';
import ReferPatientModal from './ReferPatientModal';
import ReferralPromo from './ReferralPromo';
import { referralsTableColumns } from './ReferralsTableColumns';
import PatientReferralStatsView from './ReferralStats/PatientReferralStatsView';
import ResendReferralModalContent from './ResendReferralModalContent';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
};
type ResendReferralProps = {
  referralId: string;
  patientFirstName: string;
  patientLastName: string;
  requestEmail: string;
};

const PatientReferralDashboard: React.FC<Props> = ({ authProvider, practiceId }) => {
  const pageSize = 5;
  const queryClient = useQueryClient();
  const { query, updateQuery } = useURLQuery();
  const [isShowingReferPatientModal, setIsShowingReferPatientModal] = useState(false);
  const [isResendReferralModalOpen, setIsResendReferralModalOpen] = useState(false);
  // const [isAddNewPatientModalOpen, setIsAddNewPatientModalOpen] = useState(false);
  const [patientReferral, setPatientReferral] = useState<ResendReferralProps>({
    referralId: '',
    patientFirstName: '',
    patientLastName: '',
    requestEmail: '',
  });
  const [successNotification, setSuccessNotification] = useState<React.ReactNode>(null);
  const currentPage = parseInt(query.get('page') || '1');
  const [selectedPage, setSelectedPage] = useState<number>(currentPage || 1);

  const setCookies = useCallback(
    (newParam: { [p: string]: string | null | undefined }) => {
      Cookies.referralSearchMemory.set(updateQuery(newParam));
    },
    [updateQuery]
  );

  const getPatientReferralsKey = ['getPatientReferrals', practiceId];
  const { error: fetchReferralsError, data: patientReferrals } = useQuery<
    PatientReferral[],
    ErrorResponse
  >(
    getPatientReferralsKey,
    () => {
      return getPatientReferrals({
        authProvider,
        practiceId,
      });
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  const getPatientReferralStatsKey = ['getPatientReferralStats', practiceId];
  const {
    isLoading: isLoadingStats,
    error: fetchStatsError,
    data: patientReferralStatsData,
  } = useQuery<PatientReferralStats, ErrorResponse>(getPatientReferralStatsKey, () => {
    return getPatientReferralStats({
      authProvider,
      practiceId,
    });
  });

  const getPracticeKey = ['getPractice', practiceId];
  const {
    error: fetchPracticeError,
    isLoading: isFetchingPractice,
    data: resPractice,
  } = useQuery<Practice, ErrorResponse>(getPracticeKey, () => {
    return getPractice({ authProvider, practiceId });
  });

  const buttonStyling =
    'bg-primary text-white font-semibold text-xs disabled:opacity-40 enabled:hover:opacity-75';

  const onNewPageSelected = useCallback(
    (page: number) => {
      setCookies({ page: page.toString() });
      setSelectedPage(page);
    },
    [setCookies]
  );
  const disableResendReferral = (referral: PatientReferral) => {
    return !isReferralUnused(referral);
  };

  const referralsTableColumnsWithResend = referralsTableColumns.concat([
    {
      columnKey: 'resend-referral-button',
      title: '',
      widthProportion: 1,
      render: (record: PatientReferral) => {
        return (
          <>
            <Button
              onClick={() => {
                setIsResendReferralModalOpen(true);
                setPatientReferral({
                  referralId: record.id || '',
                  patientFirstName: record.firstName || '',
                  patientLastName: record.lastName || '',
                  requestEmail: record.requestEmail,
                });
              }}
              className={buttonStyling}
              disabled={disableResendReferral(record)}
              workflow={ReferralWorkflow}
              context="patientReferralDashboard"
              trackingLabel="Resend Referral Button"
            >
              Resend Referral
            </Button>
          </>
        );
      },
    },
  ]);

  let disableReferringPatients: string | boolean = false;
  if (authProvider.authUser?.user.isFlossyAdmin) {
    disableReferringPatients = 'Flossy admins cannot refer patients';
  }

  let paginatedReferrals: PatientReferral[] = [];
  const startIndex = (selectedPage - 1) * pageSize;
  if (patientReferrals) {
    paginatedReferrals = patientReferrals.slice(startIndex, startIndex + pageSize);
  }

  return (
    <div className="patient-referral-dashboard text-secondary flex flex-col gap-8 align-items-start">
      <div className="header flex flex-row justify-between w-full">
        <span className="font-semibold text-2xl text-left"> Patient Referrals & Credits</span>
        <div className="control-buttons flex flex-row gap-2">
          {/*<Button*/}
          {/*  onClick={() => setIsAddNewPatientModalOpen(true)}*/}
          {/*  className={`${buttonStyling} items-center gap-1.5`}*/}
          {/*>*/}
          {/*  <FontAwesomeIcon*/}
          {/*    icon={faUserPlus}*/}
          {/*    style={{*/}
          {/*      color: 'white',*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  Add New Patient*/}
          {/*</Button>*/}
          <Button
            onClick={() => setIsShowingReferPatientModal(true)}
            className={`${buttonStyling} items-center gap-1.5`}
            disabled={disableReferringPatients}
            workflow={ReferralWorkflow}
            context="patientReferralDashboard"
            trackingLabel="Send Referral Email Button"
          >
            <FontAwesomeIcon
              icon={faStar}
              style={{
                color: 'white',
              }}
            />
            Send Referral Email
          </Button>
        </div>
      </div>
      {fetchReferralsError && <Alert {...errorAlert(fetchReferralsError)} />}
      {successNotification && (
        <div
          id="success-notification"
          className="rounded-md text-left bg-base-200 w-full py-3 pl-4 text-sm"
        >
          {successNotification}
        </div>
      )}
      {resPractice && <ReferralPromo practice={resPractice} />}
      {patientReferralStatsData && resPractice && (
        <PatientReferralStatsView statsData={patientReferralStatsData} practice={resPractice} />
      )}
      {isLoadingStats && <span>...Loading referral stats</span>}
      {isFetchingPractice && <span>...Loading practice</span>}
      {fetchStatsError && <Alert {...errorAlert(fetchStatsError)} />}
      {fetchPracticeError && <Alert {...errorAlert(fetchPracticeError)} />}
      <Table
        id="patient-referrals-table"
        tableClassNameExt="bg-white"
        columns={referralsTableColumnsWithResend}
        // data={patientReferrals || []}
        data={paginatedReferrals || []}
        rowKeyGenerator={(record: PatientReferral) => `${record.id}`}
      />
      <div className={'w-full'}>
        {patientReferrals && (
          <NewPagination
            currentPage={selectedPage}
            pageSize={pageSize}
            totalItemCount={patientReferrals.length}
            onPageChange={onNewPageSelected}
            tracking={{
              workflow: ReferralWorkflow,
              context: 'patientReferralDashboard',
            }}
          />
        )}
      </div>
      {isShowingReferPatientModal && (
        <ReferPatientModal
          authProvider={authProvider}
          practiceId={practiceId}
          onReferralCreated={(newReferral) => {
            setSuccessNotification(
              <span>
                A referral email has been sent to{' '}
                <span className="font-bold">{newReferral.requestEmail}</span>!
              </span>
            );
            setIsShowingReferPatientModal(false);
          }}
          onCancel={() => setIsShowingReferPatientModal(false)}
          queryContext={{
            client: queryClient,
            key: getPatientReferralsKey,
          }}
          workflow={ReferralWorkflow}
          context="patientReferralDashboard"
        />
      )}
      <Modal
        isOpen={isResendReferralModalOpen}
        onRequestClose={() => setIsResendReferralModalOpen(false)}
        shape={'square'}
      >
        <ResendReferralModalContent
          authProvider={authProvider}
          practiceId={practiceId}
          referredPatientFirstName={patientReferral.patientFirstName || ''}
          referredPatientLastName={patientReferral.patientLastName || ''}
          onCloseClick={() => setIsResendReferralModalOpen(false)}
          onReferralResent={(resentReferral) => {
            setSuccessNotification(
              <span>
                A referral email has been resent to{' '}
                <span className="font-bold">{resentReferral.requestEmail}</span>!
              </span>
            );
            setIsResendReferralModalOpen(false);
          }}
          requestEmail={patientReferral.requestEmail}
          referralId={patientReferral.referralId}
          queryContext={{
            client: queryClient,
            key: getPatientReferralsKey,
          }}
        />
      </Modal>
      {/*<Modal*/}
      {/*  isOpen={isAddNewPatientModalOpen}*/}
      {/*  onRequestClose={() => setIsAddNewPatientModalOpen(false)}*/}
      {/*  shape={'big-square'}*/}
      {/*>*/}
      {/*  <CreatePatientForm*/}
      {/*    authProvider={authProvider}*/}
      {/*    practiceId={practiceId}*/}
      {/*    onCloseClick={() => setIsAddNewPatientModalOpen(false)}*/}
      {/*  />*/}
      {/*</Modal>*/}
    </div>
  );
};

export default PatientReferralDashboard;
