import { useNavigate } from 'react-router-dom';

import { Tracking } from '../../amplitude';
import { Appointment } from '../../ServiceContext/appointments';
import Button from '../../shared/Button/Button';

export default function RescheduleButton({
  appointment,
  disabled,
  tracking,
}: {
  appointment: Appointment;
  disabled: boolean;
  tracking: Tracking;
}) {
  const navigate = useNavigate();
  return (
    <Button
      id={`reschedule-appointment-button-${appointment.id}`}
      className={`reschedule-appointment-button`}
      onClick={() => {
        navigate(`/appointments/${appointment.id}/reschedule`);
      }}
      noFill
      disabled={!appointment.user || disabled}
      trackingLabel="Reschedule Button"
      {...{
        ...tracking,
        properties: {
          appointmentId: appointment.id,
        },
      }}
    >
      Reschedule
    </Button>
  );
}
