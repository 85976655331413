import cx from 'classnames';
import React, { HTMLProps, useCallback, useEffect, useRef, useState } from 'react';

import { stringifyMoney } from '../money/stringifyMoney';
import { applyKeyPress } from '../strings';

const incompleteMoneyRegex = /^(?:([1-9]\d*(\.\d{0,2})?)|(0(\.\d{0,2})?))$/;
const returnKeyCode = 13;

interface Props {
  id: string;
  name: string;
  onBlur: (e: React.FocusEvent<HTMLInputElement>, valueInCents: number | undefined) => void;
  defaultValueInCents: number | undefined;
  showErrorFn: (currentValueInCents: number | undefined) => boolean | string;
  placeholder?: string;
  max?: number;
  min?: number;
  style?: React.StyleHTMLAttributes<HTMLInputElement>;
  className?: string;
  wrapperClassName?: string;
  disabled?: boolean;
  update?: number;
}

function MoneyInput({
  id,
  name,
  onBlur,
  defaultValueInCents,
  showErrorFn,
  placeholder = '$0.00',
  max = Number.MAX_SAFE_INTEGER,
  min = 0,
  style,
  className = '',
  wrapperClassName = '',
  disabled,
  update,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [valueDisplay, setValueDisplay] = useState(
    defaultValueInCents !== undefined
      ? stringifyMoney(defaultValueInCents, { includeCommas: true })
      : ''
  );
  const [nextSelectionStart, setNextSelectionStart] = useState(0);
  const [nextSelectionEnd, setNextSelectionEnd] = useState(0);
  const [toNextValue, setToNextValue] = useState('');
  const [valueInCents, setValueInCents] = useState(defaultValueInCents);

  useEffect(() => {
    if (defaultValueInCents !== undefined) {
      setValueDisplay(stringifyMoney(defaultValueInCents, { includeCommas: true }));
    }
  }, [defaultValueInCents, update]);

  const getMoneyValueDisplay = useCallback(() => {
    if (!valueDisplay) {
      return '';
    }

    return valueDisplay.replace(/[$,]+/g, '');
  }, [valueDisplay]);

  const onFocus = useCallback(() => {
    if (valueDisplay) {
      setValueDisplay(getMoneyValueDisplay());
    }
  }, [getMoneyValueDisplay, valueDisplay]);

  const onKeyDown = useCallback(
    (e: any) => {
      e.stopPropagation();

      if (disabled) {
        return;
      }

      const { key, keyCode, target } = e;
      const { selectionStart, selectionEnd } = target;
      const valueDisplayMoneyComponent = getMoneyValueDisplay();
      if (keyCode === returnKeyCode && inputRef.current) {
        inputRef.current.blur();
        return;
      }
      const {
        newValue: nextValueDisplay,
        newSelectionStart,
        newSelectionEnd,
      } = applyKeyPress(valueDisplayMoneyComponent, keyCode, key, selectionStart, selectionEnd);
      setNextSelectionStart(newSelectionStart);
      setNextSelectionEnd(newSelectionEnd);
      let nextValueInCents: number | undefined = Number.parseFloat(nextValueDisplay);
      if (nextValueInCents !== undefined) {
        nextValueInCents *= 100;
      }
      if (nextValueInCents !== undefined && nextValueInCents > max) {
        return;
      }
      if (nextValueInCents !== undefined && nextValueInCents < min) {
        return;
      }
      if (nextValueDisplay !== '' && !incompleteMoneyRegex.test(nextValueDisplay)) {
        return;
      }
      if (Number.isNaN(nextValueInCents)) {
        nextValueInCents = undefined;
      }
      setToNextValue(`${nextValueDisplay || ''}`);
      setValueInCents(nextValueInCents !== undefined ? Math.round(nextValueInCents) : undefined);
    },
    [disabled, getMoneyValueDisplay, max, min]
  );

  const innerOnChange = useCallback(() => {
    // We need to ensure that valueDisplay (which is what's actually shown by the input) is set in
    // onChange because otherwise we cannot set the selection range correctly in the input after
    // a key press happens (see https://github.com/facebook/react/issues/6483 for more).
    //
    // Setting valueDisplay in here _may_ not be necessary, but it appears to keep the selection
    // more consistent.
    setValueDisplay(`${toNextValue || ''}`);
    if (inputRef.current && nextSelectionStart && nextSelectionEnd) {
      inputRef.current.setSelectionRange(nextSelectionStart, nextSelectionEnd, 'forward');
    }
  }, [nextSelectionStart, nextSelectionEnd, toNextValue]);

  const innerOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (valueDisplay === undefined || valueInCents === undefined) {
        setValueDisplay('');
      } else {
        const valueAbsTrunc = Math.trunc(Math.abs(valueInCents));
        if (
          Math.round(valueInCents) !== valueAbsTrunc ||
          !Number.isFinite(valueInCents) ||
          Number.isNaN(valueInCents)
        ) {
          return;
        }

        setValueDisplay(stringifyMoney(Math.round(valueInCents), { includeCommas: true }));
      }

      onBlur(e, valueInCents);
    },
    [onBlur, valueDisplay, valueInCents]
  );

  let errorMessage: string | null = null;
  const showErrorResult = showErrorFn(valueInCents);
  if (showErrorResult) {
    if (typeof showErrorResult === 'string') {
      errorMessage = showErrorResult;
    } else {
      errorMessage = 'The value entered is invalid';
    }
    className += ' error border border-solid border-[#bf2e3c]';
  } else {
    className += ' border border-solid border-[#cccccc]';
  }

  if (disabled) {
    className += 'text-gray-500 bg-gray-100 cursor-not-allowed';
  }

  className += ' money-input';

  const inputProps = {
    id: id,
    name: name,
    'data-testid': 'currency-input',
    className: className,
    inputMode: 'numeric' as HTMLProps<HTMLInputElement>['inputMode'],
    onFocus: onFocus,
    onChange: innerOnChange,
    onBlur: innerOnBlur,
    onKeyDown: onKeyDown,
    style: style,
    value: valueDisplay,
    placeholder: placeholder,
    ref: inputRef,
    disabled,
  };

  return (
    <div className={cx('money-input-container flex flex-col', wrapperClassName)}>
      <input {...inputProps} autoComplete="off" />
      {errorMessage && <span className="text-error text-xs mt-1 break-words">{errorMessage}</span>}
    </div>
  );
}

export default MoneyInput;
