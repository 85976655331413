import { faCreditCard, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { InvoicingWorkflow } from '../../../amplitude';
import { cardLastFourFromPaymentMethod, PaymentMethod } from '../../../ServiceContext/invoices';
import Button from '../../../shared/Button/Button';
import { toShortDateString } from '../../../shared/dates/dates';
import { formatCardTypeName } from '../utilities';

const buttonStyling = 'bg-primary text-white hover:bg-opacity-[75%] disabled:bg-opacity-60';
type Props = {
  selectedPaymentMethod: PaymentMethod | null;
  availablePaymentMethods: PaymentMethod[];
  onConfirmSelection: (newPaymentMethod: PaymentMethod) => void;
  onAddCardClick: () => void;
  closeModal: () => void;
};

const ChangeCreditCardModalContent: React.FC<Props> = ({
  selectedPaymentMethod: originalSelectedPaymentMethod,
  availablePaymentMethods,
  onConfirmSelection,
  closeModal,
  onAddCardClick,
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(
    originalSelectedPaymentMethod || null
  );

  let paymentMethodOptions: PaymentMethod[] = [];
  if (originalSelectedPaymentMethod) {
    paymentMethodOptions = [originalSelectedPaymentMethod, ...availablePaymentMethods];
  } else {
    paymentMethodOptions = [...availablePaymentMethods];
  }

  return (
    <div className={'bg-white p-4 rounded-lg text-secondary w-[40%]'}>
      <span className="text-xl font-bold mb-10 block">Change Payment Card</span>
      <div id={'card-options-wrapper'} className={'flex flex-col gap-2'}>
        <div
          id={'title'}
          className={'flex flex-row justify-between font-semibold text-xs border-b pb-2'}
        >
          <span className={'w-[45%]'}>Card</span>
          <span className={'w-[20%]'}>Expires</span>
        </div>
        {paymentMethodOptions.map((pm) => {
          return (
            <div key={pm.id} className={'flex flex-row justify-between py-2'}>
              <div className={'flex flex-row items-center w-[45%]'}>
                <input
                  type={'radio'}
                  name={'paymentMethod'}
                  id={`paymentMethod-${pm.id}`}
                  onChange={() => setSelectedPaymentMethod(pm)}
                  className={'mr-2'}
                  defaultValue={
                    originalSelectedPaymentMethod?.id
                      ? `paymentMethod-${originalSelectedPaymentMethod?.id}`
                      : undefined
                  }
                  checked={Boolean(selectedPaymentMethod && selectedPaymentMethod.id === pm.id)}
                />
                <FontAwesomeIcon
                  icon={faCreditCard}
                  style={{ color: 'lightslategray' }}
                  className={'mr-2'}
                />
                <label htmlFor={`paymentMethod-${pm.id}`} className={'text-xs'}>
                  <span className={'font-semibold'}>{formatCardTypeName(pm.type)}</span> ending in{' '}
                  <span className={'font-semibold'}>{cardLastFourFromPaymentMethod(pm)}</span>
                </label>
              </div>
              <span className={'text-xs w-[20%]'}>{`${toShortDateString(pm.expirationDate)}`}</span>
            </div>
          );
        })}
        <div className={'flex flex-row items-center justify-between border-t pt-2 mt-2'}>
          <button
            onClick={onAddCardClick}
            className={'flex flex-row items-center hover:opacity-75 focus:outline-none'}
          >
            <FontAwesomeIcon
              icon={faSquarePlus}
              style={{ color: 'lightslategray', scale: '80%' }}
              className={'mr-2'}
            />
            <FontAwesomeIcon
              icon={faCreditCard}
              style={{ color: 'lightslategray', opacity: '60%' }}
              className={'mr-2'}
            />
            <span className={'text-xs underline'}>Add a credit or debit card</span>
          </button>
        </div>
        <div id={'action-buttons'} className={'flex flex-row justify-center gap-2 mt-8 mb-2'}>
          <Button
            onClick={() => {
              if (selectedPaymentMethod) {
                onConfirmSelection(selectedPaymentMethod);
              }
            }}
            disabled={!selectedPaymentMethod}
            className={buttonStyling}
            workflow={InvoicingWorkflow}
            trackingLabel="Confirm Change Payment Method Button"
            context="changeCreditCardModalContent"
          >
            Confirm
          </Button>
          <Button
            onClick={closeModal}
            className={'border border-rule bg-white text-primary shadow-none hover:opacity-75'}
            workflow={InvoicingWorkflow}
            trackingLabel="Confirm Change Payment Method Button"
            context="changeCreditCardModalContent"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChangeCreditCardModalContent;
