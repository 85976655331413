import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import { PatientReferralStats } from '../../API/referrals';
import ReferralsSentTooltip from './ReferralsSentTooltip';

type Props = {
  statsData: PatientReferralStats;
};
const VerticalSeparator = () => <div className="w-px bg-base-300 mx-4 h-[90%]"></div>;
const ReferralsSent: React.FC<Props> = ({ statsData }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      id={'referrals-sent'}
      className={'relative z-10 flex flex-col gap-2 bg-white rounded-md p-4 w-1/3'}
    >
      <div id={'title'} className={'flex flex-row gap-1 items-center'}>
        <span className={'font-semibold'}>Referrals Sent</span>
        <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="cursor-pointer"
            style={{ color: 'lightslategray', opacity: '75%', scale: '80%' }}
          />
        </div>
      </div>
      <div id={'content'} className={'flex flex-row justify-items-start items-center text-left'}>
        <div id={'this-month'} className={'flex flex-col gap-1 w-1/2'}>
          <span className={'text-xs font-semibold'}>This Month</span>
          <span className={'text-xl font-bold'}>{statsData.currentMonth.sentCount}</span>
        </div>
        <VerticalSeparator />
        <div id={'overall'} className={'flex flex-col gap-1 w-1/2'}>
          <span className={'text-xs font-semibold'}>Overall</span>
          <span className={'text-xl font-bold'}>{statsData.cumulative.sentCount}</span>
        </div>
      </div>
      {isHovered && <ReferralsSentTooltip />}
    </div>
  );
};

export default ReferralsSent;
