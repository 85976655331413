export function removeItem<T>(
  array: T[],
  indexToRemove: number
): { removedItem: T | undefined; remainingArray: T[] } {
  // We copy the original array to avoid mutating it directly.
  const newArray = [...array];

  let removedItem: T | undefined = undefined;

  if (indexToRemove !== -1) {
    removedItem = newArray.splice(indexToRemove, 1)[0];
  }

  return {
    removedItem,
    remainingArray: newArray,
  };
}
