import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthProvider } from './Authentication/Authentication';

type Props = {
  authentication: AuthProvider;
};

const Logout: React.FC<Props> = ({ authentication }) => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await authentication.logOut();
    })();
  }, [authentication, navigate]);
  return null;
};

export default Logout;
