import { useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { getPatientFiles, SupplementaryFile, uploadPatientFile } from '../../../API/patients';
import { ErrorResponse } from '../../../API/response';
import { AuthProvider } from '../../../Authentication/Authentication';
import { PatientDetail } from '../../../ServiceContext/patients';
import Alert, { AlertData, errorAlert, successAlert } from '../../../shared/Alert';
import { toShortDateTimeString } from '../../../shared/dates/dates';
import Table, { ColumnDef } from '../../../shared/Table/Table';
import { PatientContext } from '../PatientDetailPage';
import UploadPatientFileButton from './UploadPatientFileButton';

type Props = {
  authProvider: AuthProvider;
};

const PatientFilesTable: React.FC<Props> = ({ authProvider }) => {
  const patient = useContext(PatientContext) as PatientDetail;
  const patientId = patient?.id;

  const patientFilesQueryKey = ['getPatientFiles', patientId];
  const [alert, setAlert] = useState<AlertData | null>(null);

  const {
    error: patientFilesResponseError,
    data: patientFilesData,
    refetch: refetchPatientFiles,
  } = useQuery<SupplementaryFile[], ErrorResponse>(
    patientFilesQueryKey,
    () => {
      return getPatientFiles({
        authProvider,
        patientId: patientId!,
      });
    },
    {
      enabled: Boolean(patientId),
    }
  );

  const { mutate, isLoading: isUploadingPatientFiles } = useMutation(uploadPatientFile, {
    onSuccess: async (data) => {
      setAlert(
        successAlert(
          <div>
            <div className="font-semibold">Upload successful!</div>
            <div className="text-sm">
              File uploaded for {patient?.firstName} {patient?.lastName}.
            </div>
          </div>
        )
      );
      await refetchPatientFiles();
    },
    onError: (error) => {
      setAlert(errorAlert(error as any));
    },
  });

  if (patientFilesResponseError) {
    setAlert(errorAlert(patientFilesResponseError));
  }

  const generateColumnDef = (): ColumnDef<SupplementaryFile>[] => {
    return [
      {
        columnKey: 'fileName',
        title: 'File Name',
        render: (record) => record.fileName,
        widthProportion: 1,
      },
      {
        columnKey: 'createdAt',
        title: 'Uploaded At',
        render: (record) => {
          return toShortDateTimeString(record.createdAt);
        },
        widthProportion: 1,
      },
      {
        columnKey: 'signedDownloadUrl',
        title: 'Download Link',
        render: (record) => {
          return (
            <a href={record.signedDownloadUrl} target="_blank" rel="noopener noreferrer">
              Download
            </a>
          );
        },
        widthProportion: 1,
      },
    ];
  };

  if (alert) {
    return <Alert {...alert} />;
  }

  return (
    <div
      id="patient-files-table-container"
      className="table-container w-full flex flex-column justify-start items-start"
    >
      <Table
        id="patient-files-table"
        columns={generateColumnDef()}
        data={patientFilesData ?? []}
        rowKeyGenerator={(record: SupplementaryFile) => `${record.id}`}
        rowClassName="patient-files-row"
      />
      <div className="flex justify-end mt-4 w-full">
        <UploadPatientFileButton
          onFilesUploaded={(e) =>
            mutate({
              authProvider,
              file: e.file,
              fileName: e.fileName,
              patientId: patientId,
            })
          }
          isLoading={isUploadingPatientFiles}
        />
      </div>
    </div>
  );
};

export default PatientFilesTable;
