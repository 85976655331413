import { AuthProvider } from '../Authentication/Authentication';
import { PaymentMethod } from '../ServiceContext/invoices';
import {
  authenticatedDelete,
  authenticatedGet,
  authenticatedNoContentPost,
  authenticatedPost,
} from '../ServiceContext/service';
import { apiUrl } from '../ServiceContext/shared';
import { Practice } from '../ServiceContext/user';
import { Environment } from '../shared/environment';
import { handleAPIResponse } from './response';

export async function getPractice({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
}): Promise<Practice> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedGet<Practice>(authProvider, apiUrl(`/practices/${practiceId}`))
  );
}

export async function getPractices({
  authProvider,
}: {
  authProvider: AuthProvider;
}): Promise<Practice[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedGet<Practice[]>(authProvider, apiUrl(`/managers/${authUser.user.id}/practices`))
  );
}

export async function addPaymentMethod({
  authProvider,
  practiceId,
  paymentMethod,
  isDefault,
}: {
  authProvider: AuthProvider;
  practiceId: string;
  paymentMethod: PaymentMethod;
  isDefault: boolean;
}): Promise<Practice> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  if (isDefault) {
    return handleAPIResponse(
      authenticatedPost<Practice>(
        authProvider,
        apiUrl(`/practices/${practiceId}/payment-methods`),
        {
          defaultPaymentMethod: paymentMethod,
        }
      )
    );
  } else {
    return handleAPIResponse(
      authenticatedPost<Practice>(
        authProvider,
        apiUrl(`/practices/${practiceId}/payment-methods`),
        {
          paymentMethods: [paymentMethod],
        }
      )
    );
  }
}

export async function deletePaymentMethod({
  authProvider,
  practiceId,
  paymentMethodId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
  paymentMethodId: string;
}): Promise<Practice> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  console.log('practiceId', practiceId);
  console.log('paymentMethodId', paymentMethodId);
  return handleAPIResponse(
    authenticatedDelete(
      authProvider,
      apiUrl(`/practices/${practiceId}/payment-methods/${paymentMethodId}`)
    )
  );
}

export async function createSetupIntent({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
}): Promise<{ clientSecret: string }> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedPost<{ clientSecret: string }>(
      authProvider,
      apiUrl(`/practices/${practiceId}/setup-intents`),
      {}
    )
  );
}

export async function testOnly_deletePracticePayments({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
}): Promise<null> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  if (!Environment.allowTestOnlyFeatures()) {
    throw new Error('This function is only available in test environments');
  }
  return handleAPIResponse(
    authenticatedNoContentPost(authProvider, apiUrl(`/test/delete-practice-payments`), {
      practiceId,
    })
  );
}

export async function testOnly_triggerMonthlyBookingsAndCreditsNotifications({
  authProvider,
  practiceId,
  period,
}: {
  authProvider: AuthProvider;
  practiceId: string;
  period: string;
}): Promise<null> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  if (!Environment.allowTestOnlyFeatures()) {
    throw new Error('This function is only available in test environments');
  }
  return handleAPIResponse(
    authenticatedNoContentPost(
      authProvider,
      apiUrl(`/test/trigger-bookings-and-credits-invoice-notifications`),
      {
        practiceId,
        period,
      }
    )
  );
}
