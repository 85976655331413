import React, { useState } from 'react';
import Modal from 'react-modal';

import { InvoicingWorkflow, Tracking } from '../../amplitude';
import { isErrorResponse } from '../../API/response';
import { Appointment, AppointmentsService } from '../../ServiceContext/appointments';
import { PaymentMethod } from '../../ServiceContext/invoices';
import Alert, { AlertData, errorAlert, successAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import useLockBodyScroll from '../../shared/Modal/useLockBodyScroll';
import RadioButtonList from '../../shared/RadioButtonList/RadioButtonList';
import CloseIcon from './CloseIcon';

type Props = {
  appointmentId: string;
  selectedPaymentMethod: PaymentMethod | null;
  paymentMethods: PaymentMethod[];

  onCancel: () => void;
  onPaymentCardChangedOnAppointment: (refreshedAppointment: Appointment) => void;

  appointmentsService: AppointmentsService;
} & Tracking;

const ChangePaymentCardModal: React.FC<Props> = ({
  appointmentId,
  selectedPaymentMethod: initialSelectedPaymentMethod,
  paymentMethods,
  onCancel,
  onPaymentCardChangedOnAppointment,
  appointmentsService,
  ...tracking
}) => {
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(initialSelectedPaymentMethod);
  const [isUpdatingSelectedPaymentMethod, setIsUpdatingSelectedPaymentMethod] = useState(false);
  const [disableChange, setDisableChange] = useState(true);

  useLockBodyScroll(true);

  const onPaymentMethodSelected = (key: string) => {
    const selected = paymentMethods.find((pm) => pm.id === key);
    if (!selected) {
      return;
    }
    if (initialSelectedPaymentMethod && selected.id === initialSelectedPaymentMethod.id) {
      setDisableChange(true);
    } else {
      setDisableChange(false);
      setAlert(null);
    }

    setSelectedPaymentMethod(selected);
  };

  const onChangePaymentMethodClicked = async () => {
    if (!selectedPaymentMethod) {
      return;
    }
    setIsUpdatingSelectedPaymentMethod(true);
    const res = await appointmentsService.associatePaymentMethod(
      selectedPaymentMethod,
      appointmentId
    );
    setIsUpdatingSelectedPaymentMethod(false);
    if (isErrorResponse(res)) {
      setAlert(errorAlert(res.errorResponse));
      return;
    }

    setAlert(successAlert('Payment card changed successfully!'));
    onPaymentCardChangedOnAppointment(res);
    setDisableChange(true);
  };

  let uniquePaymentMethodIds = new Set(paymentMethods.map((pm) => pm.id));
  let uniquePaymentMethods: PaymentMethod[] = [];
  paymentMethods.forEach((pm) => {
    if (uniquePaymentMethodIds.has(pm.id)) {
      uniquePaymentMethods.push(pm);
      uniquePaymentMethodIds.delete(pm.id);
    }
  });

  const hasUniquePaymentMethods = uniquePaymentMethods.length > 0;

  return (
    <Modal
      isOpen
      onRequestClose={onCancel}
      contentLabel="Change Payment Card"
      className="outline-none mx-auto my-10 bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 max-h-4/5 overflow-auto text-secondary"
      overlayClassName="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
    >
      <div className="bg-white rounded-lg">
        <div className="flex justify-between items-center border-b p-4">
          <h3 className="font-bold">Change Payment Card</h3>
          <Button
            onClick={onCancel}
            className="focus:outline-none transition-all duration-200 ease-in-out transform hover:scale-105"
            workflow={InvoicingWorkflow}
            trackingLabel="Close Change Payment Card Modal Button"
          >
            <CloseIcon strokeWidth={2} color="hover:text-base-content text-base-content" />
          </Button>
        </div>
        <div id="change-payment-card" className="h-full flex flex-column p-4">
          <div className="body flex flex-column items-start">
            {alert && <Alert {...alert} />}
            {hasUniquePaymentMethods ? (
              <>
                <RadioButtonList
                  name="payment-methods"
                  options={uniquePaymentMethods.map((pm) => {
                    const isChecked = pm.id === initialSelectedPaymentMethod?.id;
                    let label = pm.name;
                    if (isChecked) {
                      label += ' (Current)';
                    }
                    return { key: pm.id, displayValue: label };
                  })}
                  onButtonSelected={onPaymentMethodSelected}
                  selectedKey={selectedPaymentMethod ? selectedPaymentMethod.id : ''}
                  orientation="vertical"
                />
                <Button
                  id="change-payment-card-button"
                  className="change-payment-card-button mt-4"
                  onClick={onChangePaymentMethodClicked}
                  loading={isUpdatingSelectedPaymentMethod}
                  disabled={
                    isUpdatingSelectedPaymentMethod ||
                    selectedPaymentMethod === null ||
                    disableChange
                  }
                  omitBorder
                  trackingLabel="Change Payment Card Button"
                  {...tracking}
                >
                  Change Payment Card
                </Button>
              </>
            ) : (
              <p>No payment cards uploaded.</p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ChangePaymentCardModal;
