import { useNavigate } from 'react-router-dom';

export default function PatientProfileButton({ userId }: { userId: string }) {
  const navigate = useNavigate();
  return (
    <button
      className="rounded-md border text-sm border-primary text-primary font-bold py-2 px-3 hover:opacity-75"
      onClick={() => {
        navigate(`/patients/${userId}`);
      }}
    >
      View Patient Profile <span>&rarr;</span>
    </button>
  );
}
