import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { TreatmentPlanWorkflow } from '../../amplitude';
import { QueryContext } from '../../API/context';
import { TreatmentPlanItem } from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import { Patient } from '../../ServiceContext/patients';
import Button from '../../shared/Button/Button';
import { isNumber } from '../../shared/math/math';
import Modal from '../../shared/Modal/Modal';
import { stringifyMoney } from '../../shared/money/stringifyMoney';
import Table, { ColumnDef } from '../../shared/Table/Table';
import AddRowForm from './AddRowForm';
import EditForm from './EditForm';

type Props = {
  items: TreatmentPlanItem[];
  isLoading?: boolean;
  inEditMode?: boolean;
  authentication: AuthProvider;
  patient: Patient;
  treatmentPlanId: string;
  queryContext: QueryContext;
};

const TreatmentPlanItemsTable: React.FC<Props> = ({
  items,
  isLoading,
  inEditMode,
  authentication,
  patient,
  treatmentPlanId,
  queryContext,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<TreatmentPlanItem | null>(null);

  const renderEditAction = (record: TreatmentPlanItem) => {
    return (
      <Button
        id={`edit-item-button-${record.id}`}
        className="edit-item-button border-0"
        noFill
        onClick={() => {
          setIsEditModalOpen(true);
          setItemToEdit(record);
        }}
        workflow={TreatmentPlanWorkflow}
        context="treatmentPlanItemsTable"
        trackingLabel="Edit Item Button"
      >
        <FontAwesomeIcon
          icon={faPencil}
          style={{
            color: '#d47766',
            opacity: '75%',
          }}
        />
      </Button>
    );
  };

  const generateColumnDef = (): ColumnDef<TreatmentPlanItem>[] => {
    let tableColumns: ColumnDef<TreatmentPlanItem>[];
    tableColumns = [
      {
        columnKey: 'emptyColumn',
        title: '',
        widthProportion: 0.1,
        render: () => {
          return <></>;
        },
      },
      {
        columnKey: 'cdtCode',
        title: 'CDT Code',
        render: (record: TreatmentPlanItem) => {
          let { cdtCode } = record;
          if (!cdtCode) {
            return '---';
          }

          if (cdtCode.startsWith('D')) {
            return cdtCode;
          } else if (cdtCode.startsWith('d')) {
            return cdtCode.toUpperCase();
          } else {
            return 'D' + cdtCode;
          }
        },
        widthProportion: 0.5,
      },
      {
        columnKey: 'description',
        title: 'Description',
        render: (record: TreatmentPlanItem) => record.description,
        widthProportion: 1,
      },
      {
        columnKey: 'additionalProcedureDetails',
        title: 'Additional Procedure Details',
        render: (record: TreatmentPlanItem) => record.additionalNotes,
        widthProportion: 1,
      },
      {
        columnKey: 'visit',
        title: 'Visit',
        render: (record: TreatmentPlanItem) => record.visit || '--',
        widthProportion: 0.25,
      },
      {
        columnKey: 'retailPrice',
        title: 'Retail Price',
        render: (record: TreatmentPlanItem) => {
          return stringifyMoney(record.anchorPrice || 0, {
            includeCommas: true,
          });
        },
        widthProportion: 0.5,
      },
      {
        columnKey: 'flossyPrice',
        title: 'Flossy Price',
        render: (record: TreatmentPlanItem) => {
          let finalPatientPrice: number;
          if (isNumber(record.adjustedPrice)) {
            finalPatientPrice = record.adjustedPrice;
          } else {
            finalPatientPrice = record.patientPrice || 0;
          }

          return stringifyMoney(finalPatientPrice, {
            includeCommas: true,
          });
        },
        widthProportion: 0.5,
      },
      /* {
        columnKey: 'editAction',
        title: '',
        render: (record: TreatmentPlanItem) => {
          return (
            <div className="w-full flex justify-center">
              <div
                className="border rounded-md self-center p-2 cursor-pointer"
                onClick={() => {
                  setIsEditModalOpen(true);
                  setItemToEdit(record);
                }}
              >
                <PencilIcon />
              </div>
            </div>
          );
        },
        widthProportion: 0.5,
      }, */
    ];
    if (inEditMode) {
      tableColumns.push({
        columnKey: 'edit-action',
        title: '',
        widthProportion: 0.3,
        render: (record: TreatmentPlanItem) => {
          return <div className="edit-action">{renderEditAction(record)}</div>;
        },
      });
    }
    return tableColumns;
  };

  return (
    <>
      <div className="treatment-plan-items-table-container flex flex-col items-start w-full">
        <div className="flex justify-between mb-4 w-full px-4 pt-4 items-center">
          <div className="text-base-content text-xl font-medium">{`Treatment Plan Items (${items.length})`}</div>
          {/* <Button
            onClick={() => {
              setIsAddItemModalOpen(true);
            }}
          >
            + Add Item to Treatment Plan
          </Button> */}
        </div>
        <Table
          id="treatment-plan-items-table"
          columns={generateColumnDef()}
          data={items}
          rowKeyGenerator={(record: TreatmentPlanItem) => `${record.id}`}
          rowClassName="treatment-plan-item-row"
        />
      </div>
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        shape="big-square"
      >
        {itemToEdit && (
          <EditForm
            authProvider={authentication}
            onCloseClick={() => setIsEditModalOpen(false)}
            patient={patient}
            treatmentPlanId={treatmentPlanId}
            itemToEdit={itemToEdit}
            queryContext={queryContext}
          />
        )}
      </Modal>
      <Modal
        isOpen={isAddItemModalOpen}
        onRequestClose={() => setIsAddItemModalOpen(false)}
        shape="big-square"
      >
        <AddRowForm
          authProvider={authentication}
          onCloseClick={() => setIsAddItemModalOpen(false)}
          patient={patient}
          treatmentPlanId={treatmentPlanId}
          queryContext={queryContext}
        />
      </Modal>
    </>
  );
};

export default TreatmentPlanItemsTable;
