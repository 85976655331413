import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { stringifyMoney } from '../../../../shared/money/stringifyMoney';

type Props = {
  totalCreditsSum: number;
  totalCreditsCount: number;
};

const FlossyCredits: React.FC<Props> = ({ totalCreditsSum, totalCreditsCount }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="relative flex flex-col items-start p-4">
      <div className="credits-with-info flex flex-row gap-1.5 items-center">
        <span className="text-base-content text-sm font-semibold underline">Flossy Credits</span>
        <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="cursor-pointer"
            style={{ color: 'lightslategray', opacity: '75%', scale: '0.8' }}
          />
        </div>
      </div>
      <span className="text-xl font-bold text-base-content-success">
        {stringifyMoney(totalCreditsSum, { includeCommas: true })}
      </span>
      {isHovered && (
        <div className="absolute z-20 flex flex-col gap-2 text-left top-1/2 left-1/2 mt-2 p-4 bg-white rounded-md shadow-lg w-64 transform -translate-x-1/2">
          <span className="text-base-content text-sm font-bold mb-2">Flossy Credit Details</span>
          <div className="credits flex flex-row justify-between gap-1 text-xs">
            <div className="flex flex-row gap-1 font-semibold">
              <span>{totalCreditsCount}</span>
              {totalCreditsCount > 1 ? <span>Flossy Referrals</span> : <span>Flossy Referral</span>}
            </div>
            <span>{stringifyMoney(totalCreditsSum, { includeCommas: true })}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlossyCredits;
