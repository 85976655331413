import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import {
  PatientReferral,
  ReferralCreditStatus,
  ReferralStatus as PatientReferralStatus,
} from '../API/referrals';

type ReferralStatusProps = {
  referral: PatientReferral;
};

const ReferralStatus: React.FC<ReferralStatusProps> = ({ referral }) => {
  const [isTooltipVisible, setTooltipVisibility] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const labelRef = useRef<HTMLSpanElement>(null);

  let label = '---';
  let tooltip =
    'Unable to determine the state of this referral. Please contact your Flossy representative to get more details.';
  let labelColor = 'text-error';

  if (referral.status === PatientReferralStatus.OPEN) {
    if (referral.resultingPatientId) {
      label = 'Account Created';
      tooltip = 'The patient has created an account but has not booked an appointment.';
      labelColor = 'text-base-content';
    } else {
      label = 'Unused';
      tooltip =
        'The patient has yet to use their referral. You will receive a notification once they do.';
      labelColor = 'text-base-content';
    }
  } else if (
    referral.status === PatientReferralStatus.USED &&
    referral.credit &&
    referral.credit.status === ReferralCreditStatus.PENDING
  ) {
    label = 'Unused';
    tooltip =
      'The patient has yet to use their referral. You will receive a notification once they do.';
    labelColor = 'text-base-content';
  } else if (
    referral.status === PatientReferralStatus.USED &&
    referral.credit &&
    (referral.credit.status === ReferralCreditStatus.APPLIED ||
      referral.credit.status === ReferralCreditStatus.CONSUMED)
  ) {
    label = 'Redeemed';
    tooltip =
      'This patient has booked an appointment using your referral code, and your account has been credited accordingly.';
    labelColor = 'text-green-700 font-semibold';
  }
  const handleMouseEnter = () => {
    if (labelRef.current) {
      const rect = labelRef.current.getBoundingClientRect();
      setPosition({
        left: rect.left + window.scrollX,
        top: rect.bottom + window.scrollY + 10,
      });
    }
    setTooltipVisibility(true);
  };

  return (
    <div className={`relative inline-block ${labelColor}`}>
      <span
        ref={labelRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setTooltipVisibility(false)}
      >
        {label}
      </span>
      {isTooltipVisible &&
        ReactDOM.createPortal(
          <div
            style={{
              position: 'fixed',
              top: `${position.top}px`,
              left: `${position.left}px`,
              zIndex: 1000,
            }}
            className="px-2 py-1 bg-base-content text-white text-sm rounded-md shadow-lg w-64"
          >
            {tooltip}
          </div>,
          document.body
        )}
    </div>
  );
};

export default ReferralStatus;
