import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PatientReferralStats } from '../../API/referrals';
import { Practice } from '../../ServiceContext/user';
import { stringifyMoney } from '../../shared/money/stringifyMoney';
import LinkText from '../../shared/Text/LinkText';
import CreditsEarnedTooltip from './CreditsEarnedTooltip';

type Props = {
  practice: Practice;
  statsData: PatientReferralStats;
};
const VerticalSeparator = () => <div className="w-px bg-base-300 mx-4 h-[90%]"></div>;

const CreditsEarned: React.FC<Props> = ({ statsData, practice }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      id={'credits-earned'}
      className={'relative z-10 flex flex-col gap-2 bg-white rounded-md p-4 w-1/3'}
    >
      <div id={'title'} className={'flex flex-row gap-1 items-center'}>
        <span className={'font-semibold'}>Credits Earned</span>
        <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="cursor-pointer"
            style={{ color: 'lightslategray', opacity: '75%', scale: '80%' }}
          />
        </div>
      </div>
      <div id={'content'} className={'flex flex-row justify-items-start items-center text-left'}>
        <div id={'this-month'} className={'flex flex-col gap-1 w-1/2'}>
          <span className={'text-xs font-semibold'}>This Month</span>
          <span className={'text-xl font-bold'}>
            {stringifyMoney(statsData.currentMonth.creditsEarned, { includeCommas: true })}
          </span>
        </div>
        <VerticalSeparator />
        <div id={'overall'} className={'flex flex-col gap-1 w-1/2'}>
          <span className={'text-xs font-semibold'}>Overall</span>
          <span className={'text-xl font-bold'}>
            {stringifyMoney(statsData.cumulative.creditsEarned, { includeCommas: true })}
          </span>
        </div>
      </div>
      <LinkText
        className={'!text-base-content !text-xs !pl-0 hover:opacity-75'}
        onClick={() => navigate(`/accounting/bookings-and-credits`)}
      >
        View Bookings and Credits
      </LinkText>
      {isHovered && <CreditsEarnedTooltip practice={practice} />}
    </div>
  );
};

export default CreditsEarned;
