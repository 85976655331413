import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

import { PaymentMethod } from '../../ServiceContext/invoices';
import { capitalize } from '../strings';

export function cardBrandToName(stripeCardBrand: string) {
  switch (stripeCardBrand) {
    case 'visa':
    case 'mastercard':
    case 'discover':
      return capitalize(stripeCardBrand);
    case 'amex':
      return 'AmEx';
    default:
      return 'Unsupported Card';
  }
}

export function createPaymentMethodModel({
  stripePaymentMethodId,
  type,
  cardBrand,
  cardLast4,
  stripeExpirationMonth,
  stripeExpirationYear,
}: {
  stripePaymentMethodId: string;
  type: string;
  cardBrand: string;
  cardLast4: string;
  stripeExpirationMonth: number;
  stripeExpirationYear: number;
}): PaymentMethod {
  // luxon zero-indexes months, Stripe just reads what the user types
  const expirationDate = DateTime.fromJSDate(
    new Date(stripeExpirationYear, stripeExpirationMonth - 1, 1)
  );
  return {
    id: uuidv4(),
    stripeId: stripePaymentMethodId,
    type: type,
    name: `${cardBrandToName(cardBrand)} ${cardLast4}`,
    expirationDate: `${expirationDate.toISODate()}`,
  };
}
