import { BookingChargeCredit } from '../../../../API/bookingsAndCredits';

type Props = {
  allCreditItems: BookingChargeCredit[];
};

const AvailableCredits: React.FC<Props> = ({ allCreditItems }) => {
  const availableCredits = allCreditItems.filter((c) => c.status === 'applied');

  return (
    <div className="flex flex-col gap-2 text-left bg-white rounded-md p-4">
      <div className="flex flex-col gap-1">
        <span className={'text-lg font-bold'}>Available Credits</span>
        <span className={'text-2xl font-bold'}>{availableCredits.length}</span>
      </div>
      <span className={'text-xs'}>
        Available credits will be automatically applied to your most recent Bookings payment. Any
        remaining credits will rollover to the upcoming months.
      </span>
    </div>
  );
};

export default AvailableCredits;
