import { useCallback } from 'react';

import Cookies from '../../shared/cookies/cookies';
import { useURLQuery } from '../../shared/routing/routing';

export const usePayoutQuery = () => {
  const { query, updateQuery } = useURLQuery();

  const currentPage = parseInt(query.get('page') || '1', 10);

  const setCookies = useCallback(
    (newParam: { [p: string]: string | null | undefined }) => {
      Cookies.payoutSearchMemory.set(updateQuery(newParam));
    },
    [updateQuery]
  );

  const onNewPageSelected = useCallback(
    (newPage: number) => {
      setCookies({ page: `${newPage}` });
    },
    [setCookies]
  );

  return {
    updateQuery: setCookies,
    currentPage,
    onNewPageSelected,
  };
};
