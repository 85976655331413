import React from 'react';
import { useQuery } from 'react-query';

import { ErrorResponse } from '../API/response';
import { getReviews, Review } from '../API/reviews';
import { AuthProvider } from '../Authentication/Authentication';
import Alert, { errorAlert } from '../shared/Alert';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  practiceName: string;
  onClearPractice?: (() => void) | null;
};

const PracticeReviews: React.FC<Props> = ({
  authProvider,
  practiceId,
  practiceName,
  onClearPractice,
}) => {
  const getReviewsKey = ['getReviews', practiceId];
  const {
    isLoading,
    error: fetchError,
    data: reviews,
  } = useQuery<Review[], ErrorResponse>(
    getReviewsKey,
    () => {
      return getReviews({
        authProvider,
        practiceId: practiceId,
      });
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <div className="text-center font-medium py-5">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-5">
      <h1 className="text-2xl font-bold text-center mb-6">Reviews for {practiceName}</h1>
      {fetchError && <Alert {...errorAlert(fetchError)} />}
      <div className="space-y-4">
        {reviews &&
          reviews.map((review) => (
            <div key={review.id} className="flex bg-white shadow-lg rounded-lg p-4">
              <img
                src={review.reviewerPhotoUrl || 'default-avatar.png'}
                alt={review.reviewerName}
                className="w-16 h-16 rounded-full mr-4"
              />
              <div className="w-full">
                <h3 className="font-semibold">{review.reviewerName}</h3>
                <p className="text-sm text-base-content">Rating: {review.rating}</p>
                <p className="text-base-content mt-1">{review.text}</p>
                <small className="text-base-content">Posted on: {review.postDate}</small>
              </div>
            </div>
          ))}
      </div>
      {onClearPractice && (
        <button
          onClick={onClearPractice}
          className="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to Practice
        </button>
      )}
    </div>
  );
};

export default PracticeReviews;
