import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthProvider } from '../Authentication/Authentication';
import { Appointment } from '../ServiceContext/appointments';
import { reportErrorToSentry } from '../ServiceContext/error';
import { InvoiceStatus } from '../ServiceContext/invoices';
import { navigateToAppointments } from '../shared/routing/routing';

type Props = {
  authProvider: AuthProvider;
  appointment: Appointment;
};

const AppointmentDashboard: React.FC<Props> = ({ authProvider, appointment }) => {
  const navigate = useNavigate();

  const returnToAppointments = useCallback(() => {
    navigateToAppointments({ navigate });
  }, [navigate]);

  const { invoice, user } = appointment;

  useEffect(() => {
    if (
      !invoice ||
      (invoice && invoice.status !== InvoiceStatus.paid && invoice.status !== InvoiceStatus.settled)
    ) {
      navigate(`/appointments/${appointment.id}/invoicing`);
    } else {
      navigate(`/appointments/${appointment.id}/details`);
    }
  }, [appointment.id, invoice, navigate]);

  useEffect(() => {
    if (!user) {
      reportErrorToSentry(`No patient found on appointment with id: ${appointment.id}`);
      returnToAppointments();
    }
  }, [appointment.id, returnToAppointments, user]);

  navigate(`/home`);
  return null;
};

export default AppointmentDashboard;
