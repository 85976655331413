import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { recordPageView } from './shared/analytics';

const GATracking: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    recordPageView(location.pathname + location.search).then();
  }, [location]);

  return <>{children}</>;
};

export default GATracking;
