import PortalContainer from '../../shared/Container/PortalContainer';

export default function TreatmentPlanNotes({ notes }: { notes: string | null }) {
  return (
    <PortalContainer>
      <div>
        <span className="text-base-content text-sm font-bold">Additional Notes: </span>
        <span className="text-base-content text-sm font-normal">
          {notes
            ? notes
            : 'Additional notes made by office manager or dentist will be listed here.'}
        </span>
      </div>
    </PortalContainer>
  );
}
