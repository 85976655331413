import React, { PropsWithChildren } from 'react';

interface Props {
  id: string;
  href: string;
  className?: string;
  omitBorder?: boolean;
  noFill?: boolean;
}

const AnchorButton: React.FC<PropsWithChildren<Props>> = ({ id, href, className, children }) => {
  let classNames = 'flossy-anchor-button rounded-md p-2.5 ';

  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <a id={id} className={classNames} href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default AnchorButton;
