import { useCallback, useState } from 'react';

import { BookingsAndCreditsWorkflow } from '../../../amplitude';
import {
  Booking,
  BookingCharge,
  BookingChargeCredit,
  BookingLedger,
  LedgerMonth,
} from '../../../API/bookingsAndCredits';
import { QueryContext } from '../../../API/context';
import { AuthProvider } from '../../../Authentication/Authentication';
import Cookies from '../../../shared/cookies/cookies';
import { useURLQuery } from '../../../shared/routing/routing';
import NewPagination from '../../../shared/Table/NewPagination';
import { bookingSortFunctions } from '../../../shared/Table/SortFunction';
import BookingsAndCreditStats from './B&CStats/BookingsAndCreditsStats';
import BCTable from './BCTable';
import { getRedeemedCreditsAmount } from './bookingLedgerManipulation';
import CurrentMonthBalanceSummary from './CurrentMonthBalanceSummary';

type Props = {
  authProvider: AuthProvider;
  monthYearString: string; // API-friendly format YYYY-MM
  ledgerMonth: LedgerMonth;
  practiceId: string;
  queryContext: QueryContext;
  allCreditItems: BookingChargeCredit[];
};

const CurrentMonth: React.FC<Props> = ({
  authProvider,
  monthYearString,
  ledgerMonth,
  practiceId,
  queryContext,
  allCreditItems,
}) => {
  const pageSize = 6;
  const { query, updateQuery } = useURLQuery();
  const currentPage = parseInt(query.get('page') || '1', 10);

  const [selectedPage, setSelectedPage] = useState<number>(currentPage || 1);
  const [dateOrderByDirection, setDateOrderByDirection] = useState<'ASC' | 'DESC'>('DESC');

  const setCookies = useCallback(
    (newParam: { [p: string]: string | null | undefined }) => {
      Cookies.bookingSearchMemory.set(updateQuery(newParam));
    },
    [updateQuery]
  );

  let paginatedBookings: Booking[] = [];
  if (ledgerMonth.bookings) {
    const startIndex = (selectedPage - 1) * pageSize;
    let sortedBookings: Booking[];
    if (dateOrderByDirection === 'ASC') {
      sortedBookings = ledgerMonth.bookings.sort(bookingSortFunctions.ascending);
    } else {
      sortedBookings = ledgerMonth.bookings.sort(bookingSortFunctions.descending);
    }
    paginatedBookings = sortedBookings.slice(startIndex, startIndex + pageSize);
  }

  const onNewPageSelected = useCallback(
    (page: number) => {
      setCookies({ page: page.toString() });
      setSelectedPage(page);
    },
    [setCookies]
  );

  const applyRefundedCharge = useCallback(
    (updatedCharge: BookingCharge) => {
      const { client, key } = queryContext;

      const currentData = client.getQueryData<BookingLedger>(key);

      if (currentData && currentData.monthlyBreakdown[monthYearString]) {
        const index = currentData.monthlyBreakdown[monthYearString].bookings.findIndex(
          (booking) => booking.charge?.id === updatedCharge.id
        );
        if (index === -1) {
          return;
        }

        currentData.monthlyBreakdown[monthYearString].bookings[index] = {
          ...currentData.monthlyBreakdown[monthYearString].bookings[index],
          charge: updatedCharge,
        };

        client.setQueryData(key, currentData);
      }
    },
    [monthYearString, queryContext]
  );

  return (
    <div id={'current-month-tab-content'} className="flex flex-row gap-4">
      <div id={'stats-and-summary'} className={'flex flex-col gap-3 w-1/4'}>
        <CurrentMonthBalanceSummary ledgerMonth={ledgerMonth} />
        <BookingsAndCreditStats
          breakdown={ledgerMonth.breakdown}
          totalCredits={getRedeemedCreditsAmount(ledgerMonth)}
          totalCreditsCount={ledgerMonth.credits.count}
          charges={ledgerMonth.charges}
        />
      </div>
      <div id={'table-and-pagination'} className="w-full overflow-x-hidden w-3/4">
        <BCTable
          authProvider={authProvider}
          bookingData={paginatedBookings}
          creditItems={allCreditItems}
          practiceId={practiceId}
          onRefundCompleted={applyRefundedCharge}
          setDateOrderByDirection={setDateOrderByDirection}
          workflow={BookingsAndCreditsWorkflow}
        />
        <NewPagination
          currentPage={selectedPage}
          pageSize={pageSize}
          totalItemCount={ledgerMonth.bookings.length}
          onPageChange={onNewPageSelected}
          tracking={{
            workflow: BookingsAndCreditsWorkflow,
            context: 'bookingsAndCredits',
          }}
        />
      </div>
    </div>
  );
};

export default CurrentMonth;
