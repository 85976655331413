import { useNavigate } from 'react-router-dom';

import { ClickTrackingAction, PatientWorkflow, runFTrack } from '../../../amplitude';
import { PatientDetail } from '../../../ServiceContext/patients';
import { toShortDateString } from '../../../shared/dates/dates';
import { formatPhoneNumber } from '../../../shared/strings';
import Table, { ColumnDef } from '../../../shared/Table/Table';

type Props = {
  patientData: PatientDetail[];
  isLoadingDisplayPatients?: boolean;
  setOrderBy: (orderBy: 'firstName' | 'lastName' | undefined) => void;
  setOrderByDirection: (orderByDirection: 'ASC' | 'DESC') => void;
};

const PatientsTable: React.FC<Props> = ({
  patientData,
  isLoadingDisplayPatients,
  setOrderBy,
  setOrderByDirection,
}) => {
  const navigate = useNavigate();

  const getMostRecentDentistName = (patient: PatientDetail) => {
    if (patient.lastDentist) {
      return `${patient.lastDentist.firstName} ${patient.lastDentist.lastName}`;
    }

    return 'N/A';
  };

  const generateColumnDef = (): ColumnDef<PatientDetail>[] => {
    return [
      {
        columnKey: 'lastName',
        title: 'Last Name',
        render: (record) => <div className="pl-6">{record.lastName}</div>,
        widthProportion: 0.7,
        colClassName: 'pl-4',
        sortableRemote: {
          ascending: () => {
            setOrderBy('lastName');
            setOrderByDirection('ASC');
          },
          descending: () => {
            setOrderBy('lastName');
            setOrderByDirection('DESC');
          },
        },
      },
      {
        columnKey: 'firstName',
        title: 'First Name',
        render: (record) => record.firstName,
        widthProportion: 0.7,
        sortableRemote: {
          ascending: () => {
            setOrderBy('firstName');
            setOrderByDirection('ASC');
          },
          descending: () => {
            setOrderBy('firstName');
            setOrderByDirection('DESC');
          },
        },
      },
      {
        columnKey: 'lastDentist',
        title: 'Primary Dentist',
        render: (record) => getMostRecentDentistName(record),
        widthProportion: 1.5,
      },
      {
        columnKey: 'patientContact',
        title: 'Contact',
        widthProportion: 1,
        render: (record) => (
          <div className="text-base-content text-sm space-y-1">
            <div className="flex flex-col text-sm gap-1">
              <div>{record.email}</div>
              <div>{formatPhoneNumber(record.phone)}</div>
            </div>
          </div>
        ),
      },
      {
        columnKey: 'tpOnFile',
        title: 'TP on File?',
        render: (record) => (
          <div
            className="cursor-pointer underline"
            onClick={(event) => {
              event.stopPropagation();

              runFTrack({
                event: 'Click TP On File Link',
                workflow: PatientWorkflow,
                action: ClickTrackingAction,
                context: 'patientsTable',
                componentId: 'patientsTableTPOnFileLink',
              });

              navigate(`/patients/${record.id}/details?tab=treatment-plans`);
            }}
          >
            {record.treatmentPlans?.length > 0 ? 'Yes' : 'No'}
          </div>
        ),
        widthProportion: 1,
      },
      {
        columnKey: 'upcomingAppointmentDate',
        title: 'Upcoming Appointment',
        render: (record) =>
          record.appointments.filter((appointment) => new Date(appointment.start) > new Date())
            .length > 0 ? (
            toShortDateString(
              record.appointments.find((appointment) => new Date(appointment.start) > new Date())!
                .start
            )
          ) : (
            <div className="flex flex-col items-start text-sm">
              <div className=" text-sm text-base-content underline">Schedule an Appointment</div>
            </div>
          ),
        widthProportion: 1.5,
      },
    ];
  };

  if (isLoadingDisplayPatients) {
    return (
      <div
        id="patients-table-container"
        className="table-container w-full flex flex-column justify-start items-start"
      >
        <Table
          id="patients-table"
          columns={generateColumnDef()}
          data={patientData}
          rowKeyGenerator={(record: PatientDetail) => `${record.id}`}
          rowClassName="patient-content-row"
        />
      </div>
    );
  }

  return (
    <div
      id="patients-table-container"
      className="table-container w-full flex flex-column justify-start items-start"
    >
      <Table
        id="patients-table"
        columns={generateColumnDef()}
        data={patientData}
        rowKeyGenerator={(record: PatientDetail) => `${record.id}`}
        rowClassName="patient-content-row hover:bg-base-200 hover:font-bold cursor-pointer"
        onRowClick={(record: PatientDetail) => {
          navigate(`/patients/${record.id}/details`);
        }}
      />
    </div>
  );
};

export default PatientsTable;
