import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { ClickTrackingAction, runFTrack, Tracking } from '../../amplitude';

interface Props {
  // Pagination is 1-indexed. The first page is <1>, and the last page is <numberOfPages>
  currentPage: number;
  pageSize: number;
  totalItemCount: number;
  onPageChange: (selectedPage: number) => void;
  tracking: Tracking;
}

function NewPagination({ currentPage, pageSize, totalItemCount, onPageChange, tracking }: Props) {
  const calculateNumberOfPages = () => {
    const addOne = totalItemCount % pageSize > 0;
    let count = Math.trunc(totalItemCount / pageSize);
    if (addOne) {
      count += 1;
    }

    return count;
  };
  const numberOfPages = calculateNumberOfPages();

  const showPagination = numberOfPages > 1;

  const [pageNumber, setPageNumber] = React.useState<number | undefined>(currentPage);

  if (!showPagination) {
    return null;
  }

  const disablePreviousButton = currentPage <= 1;
  const disableNextButton = currentPage >= numberOfPages;

  const decrementPageNumber = () => {
    const newPageNumber = currentPage - 1;
    onPageChange(newPageNumber);
    setPageNumber(newPageNumber);
  };

  const incrementPageNumber = () => {
    const newPageNumber = currentPage + 1;
    onPageChange(newPageNumber);
    setPageNumber(newPageNumber);
  };

  const previousAndNextButtonStyling =
    'border border-1 text-base-content px-2.5 py-1 border-rule rounded-md hover:bg-base-200';

  function logPageChangeEvent(pageNumber: number) {
    runFTrack({
      event: 'Change Page Number',
      workflow: tracking.workflow,
      action: ClickTrackingAction,
      context: tracking.context,
      componentId: tracking.context + 'Pagination',
      extraProps: {
        newPageNumber: pageNumber,
        currentPageNumber: currentPage,
      },
    });
  }

  return (
    <nav aria-label="Pagination nav root">
      <div className="pagination flex flex-row gap-3 text-sm justify-center items-center mt-4">
        <button
          aria-label="previous button"
          className={previousAndNextButtonStyling}
          onClick={decrementPageNumber}
          disabled={disablePreviousButton}
        >
          <FontAwesomeIcon icon={faAngleLeft} style={{ color: 'slategray', opacity: '80%' }} />
        </button>
        <span>Page</span>
        <input
          title="Current Page Number"
          type="text"
          value={pageNumber}
          onClick={(e) => {
            e.currentTarget.select();
          }}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (Number.isInteger(value) && value >= 1 && value <= numberOfPages) {
              logPageChangeEvent(value);
              setPageNumber(value);
            } else if (e.target.value === '') {
              setPageNumber(undefined);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (pageNumber !== currentPage && pageNumber !== undefined) {
                logPageChangeEvent(pageNumber);
                onPageChange(pageNumber);
              } else if (pageNumber === undefined) {
                setPageNumber(currentPage);
              }
            }
          }}
          className="bg-white px-2.5 py-0.5 rounded-md w-10 text-center"
        />
        <span>of</span>
        <span>{numberOfPages}</span>
        <button
          aria-label="next button"
          className={previousAndNextButtonStyling}
          onClick={incrementPageNumber}
          disabled={disableNextButton}
        >
          <FontAwesomeIcon icon={faAngleRight} style={{ color: 'slategray', opacity: '80%' }} />
        </button>
      </div>
    </nav>
  );
}

export default NewPagination;
