import React, { useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { codeToDescriptionFromPricingTable, ConsolidatedPricingTable } from '../../API/dentist';
import { adjustPricesForInvoice } from '../../API/invoices';
import { adjustPricingQuote, createPricingQuote } from '../../API/pricingQuotes';
import { TreatmentPlanItem } from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import { reportErrorToSentry } from '../../ServiceContext/error';
import { Invoice, PricingQuote, PricingSystemItem } from '../../ServiceContext/invoices';
import { Patient } from '../../ServiceContext/patients';
import { Procedure } from '../../ServiceContext/procedures';
import { Dentist } from '../../ServiceContext/user';
import { AlertData, errorAlert } from '../../shared/Alert';
import { removeItem } from '../../shared/array';
import { isNumber } from '../../shared/math/math';
import {
  inferPricingSystemVersionFromTreatmentPlan,
  PricingSystemVersion,
} from '../../shared/pricingSystem';
import { collectInvoiceItemsIntoPricedCDTCodes } from '../Invoicing/utilities';
import PricingCDTCodeTable, { PricedCDTCode } from './PricingCDTCodeTable';
import SpeedyCDTInput from './SpeedyCDTInput';
import UndoActionPopup from './UndoActionPopup';

const codeCoreDataMatches = (code: PricedCDTCode, psi: PricingSystemItem) => {
  if (code.pricingSystemId !== null && psi.pricingSystemId !== null) {
    return code.pricingSystemId === psi.pricingSystemId;
  }

  return (
    code.cdt === psi.code &&
    code.retailPrice === psi.anchorPrice &&
    code.flossyPrice === psi.patientPrice &&
    code.adjustedPrice === psi.adjustedPatientPrice
  );
};

const useCDTCodeEntry = ({
  authProvider,
  dentist,
  patient,
  invoice,
  promoCode,
  onErrorChange,
  onInvoiceUpdated,
  consolidatedPricingTable,
}: {
  authProvider: AuthProvider;
  dentist: Dentist;
  patient: Patient;
  invoice: Invoice | null;
  promoCode: string | null;
  onErrorChange: (errData: AlertData | null) => void;
  onInvoiceUpdated: (newInvoice: Invoice) => void;
  consolidatedPricingTable: ConsolidatedPricingTable;
}): {
  input: React.ReactNode;
  table: React.ReactNode;
  selectedCodes: PricedCDTCode[];
  isPerformingNetworkCalls: boolean;
  importCDTCodesFromTreatmentPlan: (items: TreatmentPlanItem[]) => void;
  pricingQuote: PricingQuote | null;
} => {
  const codeToDescriptionMap = useMemo(
    () => codeToDescriptionFromPricingTable(consolidatedPricingTable),
    [consolidatedPricingTable]
  );

  const [selectedCodes, setSelectedCodes] = useState<PricedCDTCode[]>(
    invoice ? collectInvoiceItemsIntoPricedCDTCodes(invoice.invoiceItems, dentist.specialty) : []
  );

  const [lastRemovedItem, setLastRemovedItem] = useState<PricedCDTCode | null>(
    localStorage.getItem('lastRemovedItem')
      ? JSON.parse(localStorage.getItem('lastRemovedItem') as string)
      : null
  );

  const [showUndoPopup, setShowUndoPopup] = useState(false);

  const [pricingQuote, setPricingQuote] = useState<PricingQuote | null>(
    invoice?.pricingQuote || null
  );

  const { mutate: quoteCodes, isLoading: isQuotingCodes } = useMutation(createPricingQuote, {
    onSuccess: (data) => {
      onErrorChange(null);
      setPricingQuote(data);

      if (data.validationFailures && data.validationFailures.length > 0) {
        // const valErr = pickAndParseValidationFailures(data.validationFailures);
        // onErrorChange(valErr ? errorAlert(valErr) : null);
      }

      let existingProcedures = [...selectedCodes];
      let updatedProcedures: PricedCDTCode[] = [];
      for (let i = 0; i < data.items.length; i++) {
        // We need to check this here to make sure we preserve any additional notes that the user has entered.
        // This is a terribly inefficient piece of code that we only get away with because the number of items
        // in a pricing quote is small.
        let existingItem: PricedCDTCode | null = null;
        const existingIndex = existingProcedures.findIndex((p) =>
          codeCoreDataMatches(p, data.items[i])
        );
        if (existingIndex >= 0) {
          const { removedItem, remainingArray } = removeItem(existingProcedures, existingIndex);
          existingItem = removedItem || null;
          existingProcedures = remainingArray;
        }

        updatedProcedures.push({
          cdt: data.items[i].code,
          description: existingItem?.description || codeToDescriptionMap[data.items[i].code] || '',
          retailPrice: data.items[i].anchorPrice,
          flossyPrice: data.items[i].patientPrice,
          adjustedPrice: data.items[i].adjustedPatientPrice || null,
          additionalNotes: existingItem?.additionalNotes || '',
          pricingSystemId: data.items[i].pricingSystemId || uuidv4(),

          // V2 because price quoting only happens in the new pricing system.
          pricingVersion: PricingSystemVersion.V2,
        });
      }

      setSelectedCodes(updatedProcedures);
    },
    onError: (error) => {
      onErrorChange(errorAlert(error as any));
    },
  });

  const onCodesEntered = (procedures: Pick<Procedure, 'cdt' | 'description'>[]) => {
    let updatedBillingCodes = selectedCodes.map((p) => p.cdt);
    for (let i = 0; i < procedures.length; i++) {
      updatedBillingCodes.push(procedures[i].cdt);
    }
    let updatedPricingSystemIds = selectedCodes.map((p) => p.pricingSystemId || uuidv4());
    for (let i = 0; i < procedures.length; i++) {
      updatedPricingSystemIds.push(uuidv4());
    }

    if (updatedBillingCodes.length > 0) {
      quoteCodes({
        authProvider,
        dentistId: dentist.id,
        patientId: patient.id,
        billingCodes: updatedBillingCodes,
        pricingSystemIds: updatedPricingSystemIds,
        promoCode: promoCode || null,
      });
    }
  };

  const onCodeRemoved = (removedItem: PricedCDTCode) => {
    let updatedBillingCodes = selectedCodes.filter(
      (c) => c.pricingSystemId !== removedItem.pricingSystemId
    );
    if (updatedBillingCodes.length > 0) {
      quoteCodes({
        authProvider,
        dentistId: dentist.id,
        patientId: patient.id,
        billingCodes: updatedBillingCodes.map((c) => c.cdt),
        pricingSystemIds: updatedBillingCodes.map((c) => c.pricingSystemId || uuidv4()),
        promoCode: promoCode || null,
      });
    } else {
      setPricingQuote(null);
    }
  };

  const importCDTCodesFromTreatmentPlan = (items: TreatmentPlanItem[]) => {
    const treatmentPlanVersion = inferPricingSystemVersionFromTreatmentPlan(items);

    if (treatmentPlanVersion === PricingSystemVersion.V1) {
      const procedures: Pick<Procedure, 'cdt' | 'description'>[] = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.cdtCode && item.description) {
          procedures.push({
            cdt: item.cdtCode,
            description: item.description,
          });
        }
      }

      onCodesEntered(procedures);
    } else {
      const newCodes: PricedCDTCode[] = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.cdtCode && item.description) {
          newCodes.push({
            cdt: item.cdtCode,
            description: item.description,
            flossyPrice: isNumber(item.adjustedPrice) ? item.adjustedPrice : item.patientPrice,
            retailPrice: item.anchorPrice,
            adjustedPrice: item.adjustedPrice || null,
            additionalNotes: item.additionalNotes,
            pricingVersion: PricingSystemVersion.V2,
            pricingSystemId: item.pricingSystemId || uuidv4(),
          });
        }
      }

      setSelectedCodes([...selectedCodes, ...newCodes]);
    }
  };

  const onPriceAdjusted = async (itemId: string, newPrice: number) => {
    if (invoice) {
      const updatedInvoice = await adjustPricesForInvoice({
        authProvider: authProvider,
        invoiceId: invoice.id,
        adjustments: selectedCodes.map((item) => {
          let price: number;
          if (item.pricingSystemId === itemId) {
            price = newPrice;
          } else {
            price = isNumber(item.adjustedPrice) ? item.adjustedPrice : item.flossyPrice;
          }
          return {
            pricingSystemId: item.pricingSystemId || uuidv4(),
            price,
          };
        }),
        promoCode: promoCode || null,
      });
      onInvoiceUpdated(updatedInvoice);
    } else if (pricingQuote) {
      const updatedPricingQuote = await adjustPricingQuote({
        authProvider: authProvider,
        pricingQuoteId: pricingQuote.id,
        adjustments: selectedCodes.map((item) => {
          let price: number;
          if (item.pricingSystemId === itemId) {
            price = newPrice;
          } else {
            price = isNumber(item.adjustedPrice) ? item.adjustedPrice : item.flossyPrice;
          }
          return {
            pricingSystemId: item.pricingSystemId || uuidv4(),
            price,
          };
        }),
        promoCode: promoCode || null,
      });
      setPricingQuote(updatedPricingQuote);
    } else {
      console.error('No invoice or pricing quote to adjust');
      reportErrorToSentry({
        summary: `No invoice or pricing quote to adjust in useCDTCodeEntry for dentist ${dentist.id} and patient ${patient.id}`,
        extra: {
          dentistId: dentist.id,
          patientId: patient.id,
        },
      });
    }
  };

  const inputComponent = (
    <SpeedyCDTInput authProvider={authProvider} dentist={dentist} onCodesEntered={onCodesEntered} />
  );

  const tableComponent = (
    <div className="w-full">
      <PricingCDTCodeTable
        selectedCodes={selectedCodes}
        onSelectedCodesChange={setSelectedCodes}
        onPriceAdjusted={onPriceAdjusted}
        onRemoveItem={(procedure) => {
          setLastRemovedItem(procedure);
          localStorage.setItem('lastRemovedItem', JSON.stringify(procedure));
          setShowUndoPopup(true);
          onCodeRemoved(procedure);
        }}
        pricingQuote={pricingQuote}
      />
      {showUndoPopup && (
        <div className="-top-4 absolute flex justify-center w-full">
          <UndoActionPopup
            code={lastRemovedItem?.cdt || ''}
            onClose={() => {
              setShowUndoPopup(false);
            }}
            onUndo={() => {
              if (lastRemovedItem) {
                setSelectedCodes([...selectedCodes, lastRemovedItem]);
              }
              setShowUndoPopup(false);
            }}
          />
        </div>
      )}
    </div>
  );

  return {
    input: inputComponent,
    table: tableComponent,
    selectedCodes,
    isPerformingNetworkCalls: isQuotingCodes,
    importCDTCodesFromTreatmentPlan,
    pricingQuote,
  };
};

export default useCDTCodeEntry;
