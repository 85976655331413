import React, { useCallback, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { runFTrack, SideEffectTrackingAction, Tracking, TreatmentPlanWorkflow } from '../amplitude';
import { ConsolidatedPricingTable } from '../API/dentist';
import { convertPricingQuoteToTreatmentPlan } from '../API/treatmentPlans';
import useCDTCodeEntry from '../Appointments/CDTs/useCDTCodeEntry';
import SavingsSummary from '../Appointments/Invoicing/SavingsSummary';
import UploadTreatmentPlanForm from '../Appointments/TreatmentPlans/UploadTreatmentPlanForm';
import { AuthProvider } from '../Authentication/Authentication';
import { reportErrorToSentry } from '../ServiceContext/error';
import { patientSavingsFromPricingQuote } from '../ServiceContext/invoices';
import { Patient } from '../ServiceContext/patients';
import { Dentist } from '../ServiceContext/user';
import Alert, { AlertData, errorAlert, warningAlert } from '../shared/Alert';
import Button from '../shared/Button/Button';
import Modal from '../shared/Modal/Modal';
import DiscardTreatmentPlanModalContent from './components/DiscardTreatmentPlanModalContent';
import TotalCost from './TotalCost';

type TreatmentPlanBuilderProps = {
  authProvider: AuthProvider;
  patient: Patient;
  dentist: Dentist;
  consolidatedPricingTable: ConsolidatedPricingTable;
};

const TreatmentPlanBuilder: React.FC<TreatmentPlanBuilderProps> = ({
  authProvider,
  patient,
  dentist,
  consolidatedPricingTable,
}) => {
  const navigate = useNavigate();

  const tracking: Tracking = {
    workflow: TreatmentPlanWorkflow,
    context: 'builder',
    properties: {
      dentistId: dentist.id,
      patientId: patient.id,
    },
  };

  const [alert, setAlert] = useState<AlertData | null>(null);
  const [notesInput, setNotesInput] = useState<string>('');
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);
  const [showTpUploadModal, setShowTpUploadModal] = useState<boolean>(false);

  const {
    input: CDTInputComponent,
    table: CDTTable,
    selectedCodes,
    isPerformingNetworkCalls: isQuotingCodes,
    pricingQuote,
  } = useCDTCodeEntry({
    authProvider,
    dentist,
    patient,
    invoice: null,
    promoCode: null,
    onErrorChange: setAlert,
    onInvoiceUpdated: () => {
      // We don't care about this here
    },
    consolidatedPricingTable,
  });

  useEffect(() => {
    setAlert(null);
  }, [selectedCodes]);

  const { mutate: convertPricingQuoteToTreatmentPlanMutation, isLoading: isGeneratingProposal } =
    useMutation(convertPricingQuoteToTreatmentPlan, {
      onSuccess: (data) => {
        runFTrack({
          event: 'Convert Pricing Quote to Treatment Plan Success',
          workflow: TreatmentPlanWorkflow,
          action: SideEffectTrackingAction,
          context: 'treatmentPlanBuilder',
          componentId: 'generateProposalButton',
        });
        localStorage.removeItem('tempCodeData');
        navigate(`/patients/treatment-plans/${data.id}`);
      },
      onError: (error) => {
        setAlert(errorAlert(error as any));
        runFTrack({
          event: 'Convert Pricing Quote to Treatment Plan Error',
          workflow: TreatmentPlanWorkflow,
          action: SideEffectTrackingAction,
          context: 'treatmentPlanBuilder',
          componentId: 'generateProposalButton',
          extraProps: {
            error: error,
          },
        });
      },
    });

  const onGenerateButtonClicked = () => {
    type CodeDescriptionMap = {
      [code: string]: string;
    };

    if (
      selectedCodes.filter(
        (p) =>
          p.additionalNotes === null ||
          p.additionalNotes === undefined ||
          p.additionalNotes?.trim().length === 0
      ).length > 0
    ) {
      toast.error('Please fill out all additional notes fields');
      return;
    }

    if (pricingQuote) {
      const descriptionsByCode = (selectedCodes ?? []).reduce((map: CodeDescriptionMap, obj) => {
        map[obj.cdt] = obj.description;
        return map;
      }, {});

      convertPricingQuoteToTreatmentPlanMutation({
        authProvider,
        pricingQuoteId: pricingQuote.id,
        notes: notesInput,
        name: treatmentPlanName,
        descriptionsByCode: descriptionsByCode,
        additionalNotesOrderedByPricingQuoteItem: selectedCodes.map((p) => p.additionalNotes || ''),
      });
    } else {
      reportErrorToSentry('Error on pricing estimation form - pricing quote is not available');
      setAlert(errorAlert('Error building treatment plan - please refresh the page and try again'));
    }
  };

  const onCancelClicked = () => {
    setIsDiscardModalOpen(true);
  };

  const renderButtons = () => {
    const isReadyToGenerateDraftPricing = selectedCodes.length > 0;
    let disableDraftPricing: string | boolean = !isReadyToGenerateDraftPricing;
    return [
      <Button
        id="generate-proposal-button"
        key="generate-proposal-button"
        onClick={onGenerateButtonClicked}
        loading={isQuotingCodes || isGeneratingProposal}
        disabled={disableDraftPricing}
        className={`border-0 ${
          disableDraftPricing ? 'bg-base-content-inactive' : 'bg-primary'
        } text-white`}
        trackingLabel="Generate Button"
        {...tracking}
      >
        Generate Proposal
      </Button>,
      <Button
        id="close-button"
        key="close-button"
        onClick={onCancelClicked}
        disabled={isQuotingCodes || isGeneratingProposal}
        noFill
        trackingLabel="Close Button"
        {...tracking}
      >
        Cancel
      </Button>,
    ];
  };

  const onNotesInputChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotesInput(e.target.value);
  }, []);

  const showNotesSection = selectedCodes.length > 0;
  const [treatmentPlanName, setTreatmentPlanName] = useState<string>('');

  if (!dentist.usesNewPricingSystem) {
    reportErrorToSentry(
      `Dentist with id ${dentist.id} has entered treatment plan builder without being set up to use the new pricing system.`
    );

    return (
      <Alert
        {...warningAlert(
          'This dentist is not set up to create new treatment plans. Please contact a Flossy representative for further details and to get set up.'
        )}
      />
    );
  }

  return (
    <div
      id="generate-draft-treatment-plan-content"
      className="text-base-content flex flex-col items-start"
    >
      <h5 className="title text-left font-bold text-2xl">Treatment Plan Draft</h5>
      <div className="text-base-content text-base font-medium pb-4">
        For {patient.firstName} {patient.lastName} with Dr. {dentist.firstName} {dentist.lastName}
      </div>
      <div className="bg-white rounded-lg w-full flex flex-col items-start">
        <div className="p-4 flex flex-col items-start w-full">
          <div className="text-base-content text-base font-bold leading-tight mb-2">
            Treatment Plan Name:
          </div>
          <input
            type="text"
            title="Treatment Plan Name"
            placeholder="Enter treatment plan name here (Optional, unless generating a proposal)"
            className="w-2/3 border rounded text-sm py-2.5 px-3 mb-4 focus:outline-none focus:ring focus:ring-rule"
            value={treatmentPlanName}
            onChange={(e) => setTreatmentPlanName(e.target.value)}
          />
          <div className="text-base-content text-base font-bold leading-tight mb-2">
            Enter CDT codes to generate Treatment Plan:
          </div>
          {alert && <Alert {...alert} />}
          <div className="flex items-center w-full my-4">
            <div className="w-2/3">{CDTInputComponent}</div>
          </div>
        </div>
        {CDTTable}
        {showNotesSection && (
          <div className="pl-4 text-left mt-4 flex flex-column gap-2 w-full border-b border-rule">
            <label htmlFor="additional-notes-input" className="font-bold text-[16px]">
              Additional Notes:
            </label>
            <textarea
              id="additional-notes-input"
              className="w-2/3 border rounded-md text-left min-h-[100px] pl-3 pt-2 focus:outline-primary mb-4"
              aria-label="Additional Notes input"
              placeholder="Enter additional notes"
              value={notesInput}
              onChange={onNotesInputChange}
            />
          </div>
        )}
        {pricingQuote && (
          <div className="flex flex-col items-center w-full gap-0 my-5">
            <TotalCost cost={pricingQuote.total} />
            <SavingsSummary
              savingsAmount={patientSavingsFromPricingQuote(pricingQuote)}
              savingsPercentage={pricingQuote.realizedPatientSavings}
            />
          </div>
        )}
        <div className="w-full flex flex-row align-center justify-center gap-2 pb-4">
          {renderButtons()}
        </div>
        <Modal isOpen={isDiscardModalOpen} onRequestClose={() => {}} shape={'big-square'}>
          <DiscardTreatmentPlanModalContent
            patientId={patient.id}
            onContinueDraftingClick={() => setIsDiscardModalOpen(false)}
          />
        </Modal>
        <ReactModal
          isOpen={showTpUploadModal}
          onRequestClose={() => setShowTpUploadModal(false)}
          className="outline-none mx-auto my-10 bg-white rounded-lg p-8 w-11/12 md:w-3/4 lg:w-1/2 max-h-4/5 overflow-auto shadow-xl"
          overlayClassName="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          shouldFocusAfterRender={true}
          shouldReturnFocusAfterClose={true}
        >
          <div className="space-y-4">
            <UploadTreatmentPlanForm
              authProvider={authProvider}
              patientId={patient.id}
              dentistId={dentist.id}
              onTreatmentPlanUploaded={() => {}}
              secondaryActionText="Cancel"
              onSecondaryClick={() => setShowTpUploadModal(false)}
              doAnalysis={true}
              notifyFlossyTeam={true}
            />
          </div>
        </ReactModal>
      </div>
    </div>
  );
};

export default TreatmentPlanBuilder;
