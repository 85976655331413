import React from 'react';

import { InvoicingWorkflow } from '../../../amplitude';
import {
  Invoice,
  LegacyPayment,
  LegacyPaymentSummary,
  PaymentSplitSummary,
  PricingQuote,
} from '../../../ServiceContext/invoices';
import { Address } from '../../../ServiceContext/shared';
import logo from '../../../shared/assets/app-logo.svg';
import branding from '../../../shared/assets/app-styled-name-orange-lg.svg';
import BasicInfo from './BasicInfo';
import ItemizedInvoicePricingSheet from './ItemizedInvoicePricingSheet';

interface Props {
  pricingQuote: PricingQuote;
  appointmentTimeInUTC: string;
  appointmentTimeZone: string;
  dentistLocation: Address;
  dentistName: string;
  patientName: string;
  invoice: Invoice;
  paidDate: string | undefined;
  paymentLastFour: string | undefined;
  isInsurance: boolean;
  payment?: LegacyPayment;
  showSignatureLine?: boolean;
  legacyPayment?: LegacyPaymentSummary | null;
  paymentSplits?: PaymentSplitSummary[];
}

const InvoicePrintout = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    pricingQuote,
    appointmentTimeInUTC,
    appointmentTimeZone,
    dentistLocation,
    invoice,
    dentistName,
    patientName,
    isInsurance,
    payment,
    showSignatureLine,
    legacyPayment,
    paymentSplits,
  } = props;

  return (
    <div className="print: h-full flex flex-column">
      <div id="print-content" className="scale-[0.75] my-4 max-h-64" ref={ref}>
        <div className="branding-row flex justify-center w-full">
          <img className="w-[60px] mr-2 pb-2" src={logo} id="app-logo" alt="flossy-logo" />
          <img className="w-[150px]" src={branding} id="app-branding" alt="flossy-branding" />
        </div>
        <div className="body flex flex-column items-start">
          <BasicInfo
            patientName={patientName}
            appointmentTimeInUTC={appointmentTimeInUTC}
            appointmentTimeZone={appointmentTimeZone}
            dentistName={dentistName}
            dentistLocation={dentistLocation}
          />
          <ItemizedInvoicePricingSheet
            legacyPayment={legacyPayment}
            paymentSplits={paymentSplits}
            invoice={invoice}
            disableTableRowStriping
            payment={payment}
            isInsurance={isInsurance}
            pricingQuote={pricingQuote}
            workflow={InvoicingWorkflow}
            context="invoicePrintout"
            properties={{
              'Invoice ID': invoice.id ?? '',
              'Patient ID': invoice.userId ?? '',
              'Dentist ID': invoice.dentistId ?? '',
            }}
          />
        </div>
        {showSignatureLine && (
          <div className="flex flex-row justify-end w-full ">
            <div className="signature flex flex-col gap-4 items-start mt-16 w-1/2">
              <div className={'flex flex-row gap-3 items-center mb-16'}>
                <img className="w-[60px]" src={logo} id="app-logo" alt="flossy-logo" />
                <span className="signature-label text-xl font-bold">Please sign below:</span>
              </div>
              <div className="ml-20 w-4/5">
                <hr className="w-full border-black" />
                <span className="patient name text-lg text-start">{patientName}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default InvoicePrintout;
