import { DateTime } from 'luxon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountingWorkflow, Tracking } from '../../amplitude';
import Button from '../../shared/Button/Button';
import Modal from '../../shared/Modal/Modal';
import useLockBodyScroll from '../../shared/Modal/useLockBodyScroll';
import { stringifyMoney } from '../../shared/money/stringifyMoney';

type BalancePaymentSuccessModalProps = {
  amount: number;
  balanceAsOfDate: string;

  onDone: () => void;
} & Tracking;

const getFormattedDate = (dateStr: string) => {
  const separator = dateStr.indexOf('-') >= 0 ? '-' : '/';
  const parts = dateStr.split(separator);
  let year, month;

  if (parts[0].length === 4) {
    [year, month] = parts;
  } else {
    [month, year] = parts;
  }

  const dateTimeObj = DateTime.fromObject({ year: parseInt(year), month: parseInt(month) });
  return dateTimeObj.endOf('month').toLocaleString();
};

const BalancePaymentSuccessModal: React.FC<BalancePaymentSuccessModalProps> = ({
  amount,
  balanceAsOfDate,
  onDone,
  ...tracking
}) => {
  useLockBodyScroll(true);

  const formattedDate = getFormattedDate(balanceAsOfDate);
  const navigate = useNavigate();

  return (
    <Modal
      isOpen
      onRequestClose={onDone}
      shape="square"
      canConfirm
      canCancel={false}
      confirmText="Done"
    >
      <div className="flex flex-col text-secondary items-center justify-center gap-4 p-4">
        <h2 className="text-2xl mb-4">Payment Received Successfully</h2>
        <span className="text-xs">
          You've successfully paid{' '}
          <strong>{stringifyMoney(amount, { includeCommas: true })}</strong> for the period ending
          on <strong>{formattedDate}</strong>. Thank you for your payment!
        </span>
        <Button
          onClick={() => navigate('/accounting/bookings-and-credits')}
          className="border border-rule text-primary bg-white shadow-none mb-2"
          workflow={AccountingWorkflow}
          trackingLabel="Return to Bookings and Credits Button"
        >
          Return to Bookings and Credits
        </Button>
      </div>
    </Modal>
  );
};

export default BalancePaymentSuccessModal;
