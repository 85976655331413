import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type DownAngleProps = React.HTMLProps<HTMLDivElement> & {
  onClick: () => void;
  isShowingOverlay: boolean;
};

const Angle = React.forwardRef<HTMLDivElement, DownAngleProps>((props, ref) => {
  return (
    <div
      ref={ref}
      className={`${props.className} absolute top-2 right-3 text-[#a9afbc]`}
      style={{ display: 'inline-block' }}
      onClick={props.onClick}
    >
      <FontAwesomeIcon
        icon={faAngleDown}
        className={props.isShowingOverlay ? 'transform rotate-180' : ''}
      />
    </div>
  );
});

export default Angle;
