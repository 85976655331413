import { ExclamationIcon } from '../../HomePage/components/Icons';

export default function NoDefaultPaymentMethod() {
  return (
    <div className="mt-2 p-4 border rounded-lg flex gap-4 w-full">
      <ExclamationIcon />
      <div className="text-base-content text-start text-[12px]">
        There is no default payment method on file for this practice.
      </div>
    </div>
  );
}
