import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BookingsAndCreditsWorkflow } from '../../../../amplitude';
import { BookingCharge, BookingChargeCredit } from '../../../../API/bookingsAndCredits';
import Cookies from '../../../../shared/cookies/cookies';
import { toShortDateString } from '../../../../shared/dates/dates';
import { useURLQuery } from '../../../../shared/routing/routing';
import NewPagination from '../../../../shared/Table/NewPagination';
import Table, { ColumnDef } from '../../../../shared/Table/Table';
import LinkText from '../../../../shared/Text/LinkText';

type Props = {
  allCreditItems: BookingChargeCredit[];
  allChargeItems: BookingCharge[];
};

function isCurrentMonth(dateStringInURLFormat: string): boolean {
  const [yearStr, monthStr] = dateStringInURLFormat.split('-');
  const inputYear = parseInt(yearStr, 10);
  const inputMonth = parseInt(monthStr, 10) - 1;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  return inputYear === currentYear && inputMonth === currentMonth;
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function formatTimestamp(timestamp: string): string {
  //Takes timestamp format 2024-06-18T04:22:52.224Z
  const date = new Date(timestamp);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid timestamp format');
  }

  const month = monthNames[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${month} ${year}`;
}

function convertToURLFormat(dateString: string): string {
  //Takes dateString format "July 2024"
  const [month, year] = dateString.split(' ');

  if (!month || !year || year.length !== 4) {
    throw new Error('Invalid date format. Expected "Month YYYY".');
  }

  const monthIndex = monthNames.indexOf(month);
  if (monthIndex === -1) {
    throw new Error('Invalid month name.');
  }

  const monthNumber = (monthIndex + 1).toString().padStart(2, '0');

  return `${year}-${monthNumber}`;
}

const getChargePeriodByRedeemedCredit = (
  credit: BookingChargeCredit,
  chargeItems: BookingCharge[]
) => {
  const matchedChargeItem = chargeItems.find((charge) => charge.id === credit.settlesChargeId);

  let chargePeriod: string | null = null;
  if (matchedChargeItem) {
    chargePeriod = formatTimestamp(matchedChargeItem.appliedAt);
  }
  return chargePeriod;
};

const ReferralCreditsTable: React.FC<Props> = ({ allCreditItems, allChargeItems }) => {
  const { query, updateQuery } = useURLQuery();
  const pageSize = 6;
  const currentPage = parseInt(query.get('page') || '1', 10);

  const [selectedPage, setSelectedPage] = useState<number>(currentPage || 1);

  const navigate = useNavigate();

  const setCookies = useCallback(
    (newParam: { [p: string]: string | null | undefined }) => {
      Cookies.bookingSearchMemory.set(updateQuery(newParam));
    },
    [updateQuery]
  );

  const onNewPageSelected = useCallback(
    (page: number) => {
      setCookies({ page: page.toString() });
      setSelectedPage(page);
    },
    [setCookies]
  );

  const columns: ColumnDef<BookingChargeCredit>[] = [
    {
      columnKey: 'emptyColumn',
      title: '',
      widthProportion: 0.1,
      render: () => {
        return <></>;
      },
    },
    {
      columnKey: 'dateEarned',
      title: 'Date Earned',
      widthProportion: 1,
      render: (record: BookingChargeCredit) => {
        return <span>{toShortDateString(record.appliesAt)}</span>;
      },
    },
    {
      columnKey: 'source',
      title: 'Source',
      widthProportion: 1,
      render: (record: BookingChargeCredit) => {
        if (record.creditType === 'patient_referral') {
          return <span>Patient Referral</span>;
        } else if (record.creditType === 'grant') {
          return <span>Flossy Admin</span>;
        }
      },
    },
    {
      columnKey: 'creditsRedeemed',
      title: 'Credits Redeemed',
      widthProportion: 1,
      render: (record: BookingChargeCredit) => {
        if (record.status === 'applied') {
          return <span>Not Yet Redeemed</span>;
        }
        const chargePeriod = getChargePeriodByRedeemedCredit(record, allChargeItems);
        if (chargePeriod) {
          const urlFormat = convertToURLFormat(chargePeriod);
          let url: string;

          if (isCurrentMonth(urlFormat)) {
            url = `/accounting/bookings-and-credits?page=1&tab=current-month`;
          } else {
            url = `/accounting/bookings-and-credits?page=1&period=${urlFormat}&tab=previous-months`;
          }

          console.log('chargePeriod', chargePeriod);
          return (
            <LinkText className={'hover:opacity-75'} onClick={() => navigate(url)}>
              {`${chargePeriod} Bookings`}
            </LinkText>
          );
        }
        return '---';
      },
    },
  ];

  const startIndex = (selectedPage - 1) * pageSize;
  const paginatedCreditItems = allCreditItems.slice(startIndex, startIndex + pageSize);

  return (
    <div id={'table-and-pagination'} className="w-full overflow-x-hidden w-3/4">
      <Table
        id={'referral-credits-table'}
        columns={columns}
        data={paginatedCreditItems}
        rowKeyGenerator={(record: BookingChargeCredit) => `${record.id}`}
      />
      <NewPagination
        currentPage={selectedPage}
        pageSize={pageSize}
        totalItemCount={allCreditItems.length}
        onPageChange={onNewPageSelected}
        tracking={{
          workflow: BookingsAndCreditsWorkflow,
          context: 'bookingsAndCredits',
        }}
      />
      <div id={'padding-div'} className={'h-[200px] w-full'}></div>
    </div>
  );
};

export default ReferralCreditsTable;
