import React from 'react';

import { ShareInvoiceWorkflow } from '../../../amplitude';
import styledFlossy from '../../../shared/assets/app-styled-name-orange-lg.svg';
import Button from '../../../shared/Button/Button';
import { stringifyMoney } from '../../../shared/money/stringifyMoney';
import { formatYearMonth } from '../BookingPeriod';

type Props = {
  onClick: () => void;
  isLoading: boolean;
  practiceName: string;
  balanceDue: number;
  paymentDetailsData: {
    type: 'single-month';
    year: number;
    month: number;
  } | null;
};
const ContinueToStripe: React.FC<Props> = ({
  onClick,
  isLoading,
  practiceName,
  balanceDue,
  paymentDetailsData,
}) => {
  const HorizontalSeparator = () => <div className="h-px bg-base-300 my-4 w-[70%]"></div>;

  return (
    <div className="content-wrapper flex flex-col gap-2 items-center text-secondary bg-white w-1/3 rounded-md py-12">
      <div className="header flex flex-col gap-2 items-center">
        <img
          src={styledFlossy}
          id="app-styled-name"
          alt="flossy-name"
          className="h-[62px] w-[200px] pt-2 pl-4 opacity-75"
        />

        <span className="text-sm font-bold">{`Pay Booking Charges for ${practiceName}`}</span>
      </div>
      <HorizontalSeparator />
      <div className="amount-wrapper flex flex-col font-bold border border-rule bg-base-200 rounded-md w-[70%] py-16">
        <span className={'text-lg'}>{`${formatYearMonth(paymentDetailsData)} Balance`}</span>
        <span className="text-xl">{stringifyMoney(balanceDue, { includeCommas: true })}</span>
      </div>
      <span className="text-xs text-left mx-24 mt-3">{`By clicking the "Continue to Stripe" button below, you will start the process of paying your booking charge balance for ${formatYearMonth(
        paymentDetailsData
      )}.`}</span>

      <Button
        className="bg-primary text-white text-xs mt-3 hover:bg-opacity-75"
        onClick={onClick}
        loading={isLoading}
        workflow={ShareInvoiceWorkflow}
        trackingLabel="Continue to Stripe Button"
      >
        Continue to Stripe
      </Button>
    </div>
  );
};

export default ContinueToStripe;
