import { Fragment } from 'react';

import {
  formatPaymentType,
  isValidPaymentMethod,
  LegacyPaymentSummary,
  PaymentSplitSummary,
  TransactionPaymentTypeCreditCard,
} from '../ServiceContext/invoices';
import { stringifyMoney } from '../shared/money/stringifyMoney';

type Props = {
  legacyPayment?: LegacyPaymentSummary | null;
  paymentSplits?: PaymentSplitSummary[];
};

const PaymentMethods: React.FC<Props> = ({ legacyPayment, paymentSplits }) => {
  if (legacyPayment) {
    let paymentMethodName = '';
    if (legacyPayment.paymentMethod && isValidPaymentMethod(legacyPayment.paymentMethod)) {
      paymentMethodName = legacyPayment.paymentMethod.name;
    }
    return (
      <tr className={'text-sm'}>
        <td className={'text-left pl-6 py-1.5'}>
          {legacyPayment.paymentType === TransactionPaymentTypeCreditCard
            ? `Credit Card (${paymentMethodName})`
            : formatPaymentType(paymentMethodName)}
        </td>
        <td></td>
        <td className={'text-right pr-6'}>
          {stringifyMoney(legacyPayment.amount, { includeCommas: true })}
        </td>
      </tr>
    );
  } else if (paymentSplits) {
    return (
      <Fragment>
        {paymentSplits.map((item, index) => {
          const { paymentType, paymentMethod, splitAmount } = item;
          return (
            <tr key={index} className={'text-sm'}>
              <td colSpan={3} className={'text-left pl-6 py-1.5'}>
                {paymentType === TransactionPaymentTypeCreditCard && !!paymentMethod
                  ? `Credit Card (${paymentMethod.name})`
                  : formatPaymentType(paymentType)}
              </td>
              <td className={'text-right pr-6'}>
                {stringifyMoney(splitAmount, { includeCommas: true })}
              </td>
            </tr>
          );
        })}
      </Fragment>
    );
  }
  return null;
};

export default PaymentMethods;
