import React from 'react';

import { range } from '../math/math';

interface Props {
  // Pagination is 1-indexed. The first page is <1>, and the last page is <numberOfPages>
  currentPage: number;

  // The number of numbered page controls to show in the carousel
  carouselItemNumber?: number;

  pageSize: number;
  totalItemCount: number;
  onPageChange: (selectedPage: number) => void;
}

function Pagination({
  currentPage,
  carouselItemNumber = 3,
  pageSize,
  totalItemCount,
  onPageChange,
}: Props) {
  const calculateNumberOfPages = () => {
    const addOne = totalItemCount % pageSize > 0;
    let count = Math.trunc(totalItemCount / pageSize);
    if (addOne) {
      count += 1;
    }

    return count;
  };
  const numberOfPages = calculateNumberOfPages();

  const showPagination = numberOfPages > 1;

  if (!showPagination) {
    return null;
  }

  const buildPaginationButtonClass = (add: string) => {
    return `border m-8 border-[#e5e5e5] w-[50px] h-[50px] ${add}`;
  };

  const disableFirstButton = currentPage <= 2;
  const firstButtonClassName = disableFirstButton
    ? buildPaginationButtonClass(
        'pagination-button-disabled invisible font-bold rounded-full text-[#b2b2b2]'
      )
    : buildPaginationButtonClass('font-bold rounded-full text-[#b2b2b2]');

  const disablePreviousButton = currentPage <= 1;
  const previousButtonClassName = disablePreviousButton
    ? buildPaginationButtonClass(
        'pagination-button-disabled invisible font-bold rounded-full text-[#b2b2b2]'
      )
    : buildPaginationButtonClass('font-bold rounded-full text-[#b2b2b2]');

  const disableNextButton = currentPage >= numberOfPages;
  const nextButtonClassName = disableNextButton
    ? buildPaginationButtonClass(
        'pagination-button-disabled invisible font-bold rounded-full text-[#b2b2b2]'
      )
    : buildPaginationButtonClass('font-bold rounded-full text-[#b2b2b2]');

  const disableLastButton = currentPage >= numberOfPages;
  const lastButtonClassName = disableLastButton
    ? buildPaginationButtonClass(
        'pagination-button-disabled invisible font-bold rounded-full text-[#b2b2b2]'
      )
    : buildPaginationButtonClass('font-bold rounded-full text-[#b2b2b2]');

  const decrementPageNumber = () => {
    onPageChange(currentPage - 1);
  };

  const setToFirstPage = () => {
    onPageChange(1);
  };

  const incrementPageNumber = () => {
    onPageChange(currentPage + 1);
  };

  const setToLastPage = () => {
    onPageChange(numberOfPages);
  };

  const setPageTo = (pageNumber: number) => {
    return (e: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(pageNumber);
    };
  };

  const halfCarouselWidth = Math.floor(carouselItemNumber / 2);
  const carouselNumberRangeStart =
    currentPage - halfCarouselWidth > 0 ? currentPage - halfCarouselWidth : 1;

  return (
    <nav aria-label="Pagination nav root">
      <ul className="pagination w-screen justify-center">
        <li key="pagination-first-button" className="page-item first-button">
          <button
            aria-label="first button"
            className={firstButtonClassName}
            onClick={setToFirstPage}
            disabled={disableFirstButton}
          >
            &#60;&#60;
          </button>
        </li>
        <li key="pagination-previous-button" className="page-item">
          <button
            aria-label="previous button"
            className={previousButtonClassName}
            onClick={decrementPageNumber}
            disabled={disablePreviousButton}
          >
            &#60;
          </button>
        </li>
        {range(carouselItemNumber, carouselNumberRangeStart).map((idx) => {
          if (idx > numberOfPages) {
            return null;
          }
          const pageToRender = idx;
          const isActive = pageToRender === currentPage;
          const className = isActive ? 'page-item active' : 'page-item';
          let anchor;
          if (isActive) {
            anchor = (
              <button className="active-button font-bold rounded-full text-white border border-[#cccccc] bg-[#cccccc] m-8 border-[#e5e5e5] w-[50px] h-[50px]">
                {pageToRender}
              </button>
            );
          } else {
            anchor = (
              <button
                className="inactive-button font-bold rounded-full border text-[#b2b2b2] m-8 border-[#e5e5e5] w-[50px] h-[50px]"
                onClick={setPageTo(idx)}
              >
                {pageToRender}
              </button>
            );
          }

          return (
            <li key={`pagination-${pageToRender}-button`} className={className}>
              {anchor}
            </li>
          );
        })}
        <li key="pagination-next-button" className="page-item">
          <button
            aria-label="next button"
            className={nextButtonClassName}
            onClick={incrementPageNumber}
            disabled={disableNextButton}
          >
            &#62;
          </button>
        </li>
        <li key="pagination-last-button" className="page-item">
          <button
            aria-label="last button"
            className={lastButtonClassName}
            onClick={setToLastPage}
            disabled={disableLastButton}
          >
            &#62;&#62;
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
