import { DateTime } from 'luxon';
import { useLocation, useNavigate } from 'react-router-dom';

import { ClickTrackingAction, PayoutsWorkflow, runFTrack } from '../../amplitude';
import { AuthProvider } from '../../Authentication/Authentication';
import { useSelectedDentist } from '../../Dentists/SelectedDentistContext';
import { Payout } from '../../ServiceContext/invoices';
import { toLocaleDateTimeString } from '../../shared/dates/dates';
import { stringifyMoney } from '../../shared/money/stringifyMoney';
import NewPagination from '../../shared/Table/NewPagination';
import { payoutSortFunctions } from '../../shared/Table/SortFunction';
import Table from '../../shared/Table/Table';

interface Props {
  timeZone: string;
  selectedPage: number;
  payoutInfo: Payout[] | null;
  onNewPageSelected: (page: number) => void;
  setDateOrderByDirection?: (direction: 'ASC' | 'DESC') => void;
  authProvider: AuthProvider;
}

function PastPayoutsTable({
  timeZone,
  selectedPage,
  onNewPageSelected,
  payoutInfo,
  setDateOrderByDirection,
  authProvider,
}: Props) {
  const pageSize = 6;

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dentistId = queryParams.get('dentistId');

  const { selectedDentistId } = useSelectedDentist() ?? dentistId;

  const selectedDentist = (authProvider.managedDentists ?? []).find(
    (dentist) => dentist.id === selectedDentistId
  );

  if (!payoutInfo) {
    return (
      <span className={'p-3 bg-white rounded-md w-full'}>No payout information available.</span>
    );
  }
  let paginatedPayouts: Payout[] = [];
  const startIndex = (selectedPage - 1) * pageSize;
  paginatedPayouts = payoutInfo?.slice(startIndex, startIndex + pageSize);

  const tableColumns = [
    {
      columnKey: 'space',
      title: '',
      widthProportion: 0.03,
      render: () => {
        return '';
      },
    },
    {
      columnKey: 'date',
      title: 'Date',
      widthProportion: 1,
      render: (record: Payout) => {
        return (
          <span className="past-payouts">
            <span className="past-payout-summary-section">
              <span className="payout-date">
                {record.summary.payoutDate
                  ? toLocaleDateTimeString(record.summary.payoutDate, DateTime.DATE_MED, timeZone)
                  : 'No payout date'}
              </span>
            </span>
          </span>
        );
      },
      sortable: setDateOrderByDirection ? payoutSortFunctions : undefined,
    },
    {
      columnKey: 'total',
      title: 'Total',
      widthProportion: 1,
      render: (record: Payout) => {
        return stringifyMoney(record.summary.total, { includeCommas: true });
      },
    },
  ];

  let payoutCount: number = 0;
  if (payoutInfo.length > 0) {
    payoutCount = payoutInfo.length;
  }

  return (
    <div className="past-payouts">
      <Table
        id="past-payouts-table"
        columns={tableColumns}
        data={paginatedPayouts}
        rowKeyGenerator={(record: Payout) => `${record.payoutId}`}
        onRowClick={(record: Payout) => {
          runFTrack({
            event: 'Click on Past Payouts Table Row',
            workflow: PayoutsWorkflow,
            action: ClickTrackingAction,
            context: 'pastPayoutsTable',
            componentId: 'pastPayoutsTableRow',
            extraProps: {
              payoutId: record.payoutId,
            },
          });
          let params = new URLSearchParams('');

          if (selectedDentistId) {
            params.append('dentistId', selectedDentistId);
          }
          // Prefer the selected dentist's practice. Otherwise, fallback to using the auth user's practice.
          const practiceId =
            selectedDentist?.practice?.id || authProvider.authUser?.user.practices[0].id;
          if (practiceId) {
            params.append('practiceId', practiceId);
          }

          navigate(`/accounting/payouts/${record.payoutId}?${params}`);
        }}
        rowClassName={`past-payout-content-row hover:font-bold cursor-pointer`}
        align="start"
      />
      {payoutInfo.length > 0 && payoutCount > pageSize && (
        <NewPagination
          currentPage={selectedPage}
          pageSize={pageSize}
          totalItemCount={payoutCount}
          onPageChange={onNewPageSelected}
          tracking={{
            workflow: PayoutsWorkflow,
            context: 'pastPayoutsTable',
          }}
        />
      )}
    </div>
  );
}

export default PastPayoutsTable;
