import cx from 'classnames';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { reportErrorToSentry } from '../../ServiceContext/error';
import { Invoice, InvoiceItem } from '../../ServiceContext/invoices';
import { isNumber } from '../../shared/math/math';
import { stringifyMoney } from '../../shared/money/stringifyMoney';
import { distributeCents } from '../../shared/number/number';
import {
  inferPricingSystemVersionFromInvoice,
  inferPricingSystemVersionFromInvoiceItem,
  PricingSystemVersion,
} from '../../shared/pricingSystem';
import { extractInvoicingComponents } from './utilities';

const PricedInvoiceTable: React.FC<{
  invoice: Invoice;
  isInsurance?: boolean;
}> = ({ invoice, isInsurance }) => {
  const pricingSystemVersion = inferPricingSystemVersionFromInvoice(invoice);
  if (pricingSystemVersion === PricingSystemVersion.V1) {
    return <LegacyInvoiceTable invoice={invoice} />;
  } else {
    return <NewInvoiceTable invoice={invoice} isInsurance={isInsurance ?? false} />;
  }
};

const NewInvoiceTable: React.FC<{
  invoice: Invoice;
  isInsurance?: boolean;
}> = ({ invoice, isInsurance }) => {
  const { invoiceItems } = invoice;

  const { promoAmount, refundAmount } = extractInvoicingComponents({ invoice });

  let items: {
    pricingSystemId: string;
    code: string;
    text: string;
    retailPrice: number;
    flossyPrice: number;
    adjustedFlossyPrice?: number;
  }[] = [];
  for (let i = 0; i < invoiceItems.length; i++) {
    const item = invoiceItems[i];
    if (item.type === 'procedure') {
      // Defensive check, we should never have V1 items in here.
      if (inferPricingSystemVersionFromInvoiceItem(item) === PricingSystemVersion.V1) {
        console.error('Found V1 item in V2 invoice', item);
        reportErrorToSentry({
          summary: `Found V1 item in V2 invoice: ${invoice.id}`,
          extra: item,
        });
      }

      items.push({
        pricingSystemId: item.pricingSystemId || uuidv4(),
        code: item.code,
        text: item.text,
        retailPrice: item.anchorPrice!,
        flossyPrice: item.patientPrice!,
        adjustedFlossyPrice: isNumber(item.adjustedPatientPrice)
          ? item.adjustedPatientPrice
          : undefined,
      });
    }
  }

  const [retailTotal] = React.useState(calculateRetailTotal());

  function calculateRetailTotal() {
    let total = 0;
    items.forEach((i) => {
      total += i.retailPrice;
    });
    return total;
  }

  return (
    <table id="invoice-table" className={'w-full mb-4'}>
      <thead>
        <tr className="title-row text-base text-left text-secondary border-b border-rule">
          <th id={'cdt-code'} className="py-3 pl-6" scope="col">
            CDT Code
          </th>
          <th id={'procedure'} className="text-left py-3" scope="col">
            Procedure
          </th>
          <th id={'retail-price'} className="text-right py-3 pr-6" scope="col">
            Retail Price
          </th>
          <th id={'flossy-price'} className="text-right py-3 pr-6" scope="col">
            Flossy Price
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map(
          (
            i: {
              code: string;
              text: string;
              retailPrice: number;
              flossyPrice: number;
              adjustedFlossyPrice?: number;
            },
            idx: number
          ) => {
            const key = `${i.code}-${i.text}-${idx}`;
            return (
              <tr
                key={key}
                id={'procedure-table-content-row'}
                className={cx({
                  'bg-white': idx % 2 === 0,
                  'bg-blue-50 bg-opacity-75': idx % 2 !== 0,
                })}
              >
                <td
                  id={'procedure-code-content-data'}
                  className="font-bold text-secondary text-xs text-left pl-6 py-3"
                >
                  {i.code}
                </td>
                <td>
                  <div className="procedure-summary-content text-secondary text-xs text-left">
                    <span>{i.text}</span>
                  </div>
                </td>
                <td
                  className={`!text-secondary !pr-5 procedure-price text-right text-xs ${
                    isInsurance ? 'line-through' : ''
                  }`}
                >
                  {stringifyMoney(i.retailPrice, { includeCommas: true })}
                </td>
                <td
                  className={`!text-secondary procedure-price text-right text-xs flex items-center py-2 justify-end pr-5 ${
                    isInsurance ? 'line-through' : ''
                  }`}
                >
                  <div className="py-4">
                    {stringifyMoney(
                      isNumber(i.adjustedFlossyPrice) ? i.adjustedFlossyPrice : i.flossyPrice,
                      {
                        includeCommas: true,
                      }
                    )}
                  </div>
                </td>
              </tr>
            );
          }
        )}
        {isNumber(promoAmount) && promoAmount > 0 && (
          <tr className="text-m border-t border-t-1 border-rule">
            <td colSpan={2} className="text-left py-2 pl-6">
              Promo Discount
            </td>
            <td className="text-right py-2 pr-6" />
            <td className="text-right py-2 pr-6">
              {stringifyMoney(promoAmount * -1, {
                includeCommas: true,
              })}
            </td>
          </tr>
        )}
        {Boolean(refundAmount) && (
          <tr className="text-m border-t border-t-1 border-rule">
            <td colSpan={2} className="text-left py-2 pl-6">
              Refund
            </td>
            <td className="text-right py-2 pr-6" />
            <td className="text-right py-2 pr-6">
              {stringifyMoney(refundAmount, {
                includeCommas: true,
              })}
            </td>
          </tr>
        )}
        <tr className="total-row font-bold text-lg border-rule">
          <td colSpan={2} className="totalAmount-label text-left py-4 pl-6">
            Total Amount
          </td>
          <td className="totalAmount-number text-right py-4 pr-6 line-through">
            {stringifyMoney(retailTotal, { includeCommas: true })}
          </td>
          <td
            className={`totalAmount-number text-right py-2 pr-6 ${
              isInsurance ? 'line-through' : ''
            }`}
          >
            {invoice.total < 0
              ? `(${stringifyMoney(invoice.total * -1, {
                  includeCommas: true,
                })})`
              : `${stringifyMoney(invoice.total, { includeCommas: true })}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const LegacyInvoiceTable: React.FC<{
  invoice: Invoice;
}> = ({ invoice }) => {
  const { flossyFee } = extractInvoicingComponents({
    invoice,
  });
  let itemsPriceAdjustments: number[] = Array(invoice.invoiceItems.length).fill(0);
  itemsPriceAdjustments = distributeCents(
    flossyFee,
    invoice.invoiceItems.filter((i) => i.type === 'procedure').length
  );
  const getProcedureItems = () => {
    return invoice.invoiceItems.filter((i) => i.type === 'procedure');
  };

  const totalAmount = invoice.total;

  return (
    <table id="invoice-table" className={'w-full mb-4'}>
      <thead>
        <tr className="title-row text-base text-left text-secondary border-b border-rule">
          <th id={'cdt-code'} className="py-3 pl-6" scope="col">
            CDT Code
          </th>
          <th id={'procedure'} className="text-left py-3" scope="col">
            Procedure
          </th>
          <th id={'flossy-price'} className="text-right py-3 pr-6" scope="col">
            Flossy Price
          </th>
        </tr>
      </thead>
      <tbody>
        {getProcedureItems().map((i: InvoiceItem, idx: number) => {
          const key = `${i.code}-${i.text}-${idx}`;

          const adjustment = itemsPriceAdjustments[idx] || 0;
          return (
            <tr
              key={key}
              id={'procedure-table-content-row'}
              className={cx({
                'bg-white': idx % 2 === 0,
                'bg-blue-50 bg-opacity-75': idx % 2 !== 0,
              })}
            >
              <td
                id={'procedure-code-content-data'}
                className="font-bold text-secondary text-xs text-left pl-6 py-3"
              >
                {i.code}
              </td>
              <td>
                <div className="procedure-summary-content text-secondary text-xs text-left">
                  <span>{i.text}</span>
                </div>
              </td>
              <td className="!text-secondary !pr-5 procedure-price text-right text-xs">
                {stringifyMoney(i.amount + adjustment, { includeCommas: true })}
              </td>
            </tr>
          );
        })}
        <tr className="total-row font-bold text-lg border-t border-t-1 border-rule">
          <td colSpan={2} className="totalAmount-label text-left py-2 pl-6">
            Total Amount
          </td>
          <td className="totalAmount-number text-right py-2 pr-6">
            {totalAmount < 0
              ? `(${stringifyMoney(totalAmount * -1, {
                  includeCommas: true,
                })})`
              : `${stringifyMoney(totalAmount, { includeCommas: true })}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PricedInvoiceTable;
