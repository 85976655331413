import { AuthProvider } from '../Authentication/Authentication';
import { Appointment } from '../ServiceContext/appointments';
import { PricingQuote } from '../ServiceContext/invoices';
import { Patient } from '../ServiceContext/patients';
import { authenticatedGet, authenticatedPost, authenticatedPut } from '../ServiceContext/service';
import { apiUrl, Timestamp } from '../ServiceContext/shared';
import { Dentist, Practice } from '../ServiceContext/user';
import { ErrorResponse, handleAPIResponse } from './response';

export type TreatmentPlan = {
  id: string;
  patientId: string;
  dentistId: string;
  appointmentId: string | null;
  requiresFinancing: boolean;
  files: TreatmentPlanFile[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export type TreatmentPlanFile = {
  id: string;
  fileName: string;
  signedDownloadUrl: string;
};

export type TreatmentPlanItem = {
  id: string;
  treatmentPlanId: string;
  cdtCode?: string | null;
  description: string | null;
  visit?: string | null;
  fullFeeParsed?: number | null;
  fullFeeRaw?: string | null;
  amountPatientOwesParsed?: number | null;
  amountPatientOwesRaw?: string | null;
  teeth: string[] | null;
  surface: string | null;
  anchorPrice: number;
  patientPrice: number;
  adjustedPrice: number | null;
  additionalNotes: string | null;
  pricingSystemId: string | null;
};

export enum TreatmentPlanState {
  PROPOSED = 'proposed',
  ACCEPTED = 'accepted',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
}

export type DetailedTreatmentPlan = TreatmentPlan & {
  state: TreatmentPlanState;
  items: TreatmentPlanItem[];
  dentist: Dentist | null;
  practice: Practice | null;
  patient: Patient;
  appointment: Appointment | null;
  analyses: TreatmentPlanAnalysis[];
  notes: string | null;
  name: string | null;
  pricingQuote: PricingQuote | null;
};

export type TreatmentPlanAnalysisGptRound = {
  id: string;
  treatmentPlanAnalysisId: string;
  status: 'pending' | 'processing' | 'succeeded' | 'failed';
  startedAt: Date | null;
  finishedAt: Date | null;
  promptCloudObjectName: string | null;
  responseCloudObjectName: string | null;
  failureMessage: string | null;
  notes: string[] | null;
  createdAt: Date;
};

export type TreatmentPlanAnalysis = {
  id: string;
  treatmentPlanFileId: string;
  status: 'pending' | 'processing' | 'succeeded' | 'failed';
  textractJobId: string | null;
  textractResultsFetchedAt: Timestamp | null;
  gptInterpretationFinishedAt: Timestamp | null;
  completedAt: Timestamp | null;
  failureMessage: string | null;
  createdAt: Timestamp;
  file: TreatmentPlanFile;
  isFlagged: boolean;
  interpretationRounds: TreatmentPlanAnalysisGptRound[];
};

export async function createTreatmentPlan({
  authProvider,
  patientId,
  requiresFinancing,
  wasReferredOut,
  referredTo,
  dentistId,
  appointmentId,
  practiceId,
  file,
  fileName,
  sendInternalEmail,
  analyzeTreatmentPlan,
}: {
  authProvider: AuthProvider;
  patientId: string;
  requiresFinancing: boolean;
  wasReferredOut: boolean;
  referredTo: string | null;
  dentistId: string | null;
  appointmentId: string | null;
  practiceId: string | null;
  file: File;
  fileName: string;
  sendInternalEmail: boolean;
  analyzeTreatmentPlan: boolean;
}): Promise<DetailedTreatmentPlan> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  const formData = new FormData();
  formData.append('requiresFinancing', requiresFinancing ? 'true' : 'false');
  formData.append('wasReferredOut', wasReferredOut ? 'true' : 'false');
  if (referredTo) {
    formData.append('referredTo', referredTo);
  }
  if (appointmentId) {
    formData.append('appointmentId', appointmentId);
  }
  if (practiceId) {
    formData.append('practiceId', practiceId);
  }
  if (dentistId) {
    formData.append('dentistId', dentistId);
  }
  formData.append('file', file);
  formData.append('fileName', fileName);
  formData.append('sendInternalEmail', sendInternalEmail ? 'true' : 'false');
  formData.append('analyzeTreatmentPlan', analyzeTreatmentPlan ? 'true' : 'false');

  return handleAPIResponse(
    authenticatedPost<DetailedTreatmentPlan | ErrorResponse>(
      authProvider,
      apiUrl(`/practices/patients/${patientId}/treatment-plans`),
      formData
    )
  );
}

export async function createItem({
  authProvider,
  treatmentPlanId,
  cdtCode,
  description,
  visit,
  additionalNotes,
}: {
  authProvider: AuthProvider;
  treatmentPlanId: string;
  cdtCode: string;
  description: string;
  visit?: string;
  additionalNotes?: string;
}): Promise<TreatmentPlanItem> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedPost<TreatmentPlanItem>(
      authProvider,
      apiUrl(`/practices/treatment-plans/${treatmentPlanId}/items`),
      {
        cdtCode,
        description,
        visit,
        additionalNotes,
      }
    )
  );
}

export async function editItem({
  authProvider,
  treatmentPlanId,
  itemId,
  cdtCode,
  teeth,
  surface,
  description,
  visit,
  fullFeeParsed,
  amountPatientOwesParsed,
  adjustedPrice,
  additionalNotes,
  patientPrice,
  anchorPrice,
}: {
  authProvider: AuthProvider;
  treatmentPlanId: string;
  itemId: string;
  cdtCode: string;
  teeth: Array<string>;
  surface: string;
  description: string;
  visit: string;
  fullFeeParsed: number | null;
  amountPatientOwesParsed: number | null;
  adjustedPrice?: number | null;
  additionalNotes?: string | null;
  patientPrice?: number | null;
  anchorPrice?: number | null;
}): Promise<TreatmentPlanItem> {
  return handleAPIResponse(
    authenticatedPut<TreatmentPlanItem>(
      authProvider,
      apiUrl(`/practices/treatment-plans/${treatmentPlanId}/items/${itemId}`),
      {
        cdtCode,
        teeth,
        surface,
        description,
        visit,
        fullFeeParsed,
        amountPatientOwesParsed,
        adjustedPrice,
        additionalNotes,
        patientPrice,
        anchorPrice,
      }
    )
  );
}

export async function getTreatmentPlans({
  authProvider,
  dentistId,
  patientId,
  appointmentId,
}: {
  authProvider: AuthProvider;
  dentistId: string;
  patientId: string;
  appointmentId?: string;
}): Promise<TreatmentPlan[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  if (appointmentId) {
    return handleAPIResponse(
      authenticatedGet<TreatmentPlan[]>(
        authProvider,
        apiUrl(
          `/dentists/${dentistId}/treatmentPlans?patientId=${patientId}&appointmentId=${appointmentId}`
        )
      )
    );
  }
  return handleAPIResponse(
    authenticatedGet<TreatmentPlan[]>(
      authProvider,
      apiUrl(`/dentists/${dentistId}/treatmentPlans?patientId=${patientId}`)
    )
  );
}

export type GetDetailedTreatmentPlansResponse = {
  treatmentPlans: DetailedTreatmentPlan[];
  totalCount: number;
};

export async function getDetailedTreatmentPlans({
  authProvider,
  page,
  pageSize,
  practiceId,
  dentistId,
  patientName,
  patientId,
}: {
  authProvider: AuthProvider;
  page?: number;
  pageSize?: number;
  practiceId?: string;
  dentistId?: string;
  patientName?: string;
  patientId?: string;
}): Promise<GetDetailedTreatmentPlansResponse> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  if (page === undefined) {
    page = 1;
  }
  if (pageSize === undefined) {
    pageSize = 20;
  }

  let params = new URLSearchParams('');

  params.append('skip', (pageSize * (page - 1)).toString());
  params.append('maxResults', pageSize.toString());
  if (patientName) {
    params.append('patientName', encodeURI(patientName));
  }
  if (practiceId) {
    params.append('practiceId', practiceId);
  }
  if (dentistId) {
    params.append('dentistId', dentistId);
  }
  if (patientId) {
    params.append('patientId', patientId);
  }

  return handleAPIResponse(
    authenticatedGet<GetDetailedTreatmentPlansResponse>(
      authProvider,
      apiUrl(`/practices/treatment-plans?${params}`)
    )
  );
}

export async function getTreatmentPlansForPatient({
  authProvider,
  dentistId,
  patientId,
}: {
  authProvider: AuthProvider;
  dentistId: string;
  patientId: string;
}): Promise<GetDetailedTreatmentPlansResponse> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedGet<GetDetailedTreatmentPlansResponse>(
      authProvider,
      apiUrl(`/dentists/${dentistId}/patients/${patientId}/treatment-plans`)
    )
  );
}

export async function getDetailedTreatmentPlan({
  authProvider,
  treatmentPlanId,
}: {
  authProvider: AuthProvider;
  treatmentPlanId: string;
}): Promise<DetailedTreatmentPlan> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedGet<DetailedTreatmentPlan>(
      authProvider,
      apiUrl(`/dentists/treatment-plans/${treatmentPlanId}`)
    )
  );
}

export async function flagAnalysis({
  authProvider,
  treatmentPlanAnalysisId,
}: {
  authProvider: AuthProvider;
  treatmentPlanAnalysisId: string;
}): Promise<TreatmentPlanAnalysis> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedPut<TreatmentPlanAnalysis>(
      authProvider,
      apiUrl(`/practices/treatment-plans/analyses/${treatmentPlanAnalysisId}`),
      {
        isFlagged: true,
      }
    )
  );
}

export async function convertPricingQuoteToTreatmentPlan({
  authProvider,
  pricingQuoteId,
  notes,
  name,
  descriptionsByCode,
  additionalNotesOrderedByPricingQuoteItem,
}: {
  authProvider: AuthProvider;
  pricingQuoteId: string;
  notes?: string;
  name?: string;
  descriptionsByCode: { [key: string]: string };
  additionalNotesOrderedByPricingQuoteItem: string[];
}): Promise<TreatmentPlan> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedPost<TreatmentPlan>(
      authProvider,
      apiUrl(`/practices/pricing-quotes/${pricingQuoteId}/treatment-plan-conversions`),
      {
        notes,
        name,
        descriptionsByCode: descriptionsByCode,
        additionalNotesOrderedByPricingQuoteItem,
      }
    )
  );
}

async function updateTreatmentPlanState({
  authProvider,
  treatmentPlanId,
  state,
}: {
  authProvider: AuthProvider;
  treatmentPlanId: string;
  state: TreatmentPlanState;
}): Promise<DetailedTreatmentPlan> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedPut<DetailedTreatmentPlan>(
      authProvider,
      apiUrl(`/practices/treatment-plans/${treatmentPlanId}/state`),
      { state }
    )
  );
}

export async function acceptTreatmentPlan({
  authProvider,
  treatmentPlanId,
}: {
  authProvider: AuthProvider;
  treatmentPlanId: string;
}): Promise<DetailedTreatmentPlan> {
  return updateTreatmentPlanState({
    authProvider,
    treatmentPlanId,
    state: TreatmentPlanState.ACCEPTED,
  });
}

export async function rejectTreatmentPlan({
  authProvider,
  treatmentPlanId,
}: {
  authProvider: AuthProvider;
  treatmentPlanId: string;
}): Promise<DetailedTreatmentPlan> {
  return updateTreatmentPlanState({
    authProvider,
    treatmentPlanId,
    state: TreatmentPlanState.REJECTED,
  });
}

export async function completeTreatmentPlan({
  authProvider,
  treatmentPlanId,
}: {
  authProvider: AuthProvider;
  treatmentPlanId: string;
}): Promise<DetailedTreatmentPlan> {
  return updateTreatmentPlanState({
    authProvider,
    treatmentPlanId,
    state: TreatmentPlanState.COMPLETED,
  });
}

export async function cancelTreatmentPlan({
  authProvider,
  treatmentPlanId,
}: {
  authProvider: AuthProvider;
  treatmentPlanId: string;
}): Promise<DetailedTreatmentPlan> {
  return updateTreatmentPlanState({
    authProvider,
    treatmentPlanId,
    state: TreatmentPlanState.CANCELLED,
  });
}

export async function markTreatmentPlanInProgress({
  authProvider,
  treatmentPlanId,
}: {
  authProvider: AuthProvider;
  treatmentPlanId: string;
}): Promise<DetailedTreatmentPlan> {
  return updateTreatmentPlanState({
    authProvider,
    treatmentPlanId,
    state: TreatmentPlanState.IN_PROGRESS,
  });
}

export async function updateTreatmentPlanName({
  authProvider,
  treatmentPlanId,
  name,
}: {
  authProvider: AuthProvider;
  treatmentPlanId: string;
  name: string;
}): Promise<DetailedTreatmentPlan> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedPut<DetailedTreatmentPlan>(
      authProvider,
      apiUrl(`/practices/treatment-plans/${treatmentPlanId}`),
      {
        name,
      }
    )
  );
}

export async function editTreatmentPlanItem({
  authProvider,
  treatmentPlanId,
  adjustments,
  itemId,
}: {
  authProvider: AuthProvider;
  treatmentPlanId: string;
  adjustments: { description: string; additionalNotes?: string; visit?: string };
  itemId: string;
}): Promise<TreatmentPlanItem> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedPut<TreatmentPlanItem>(
      authProvider,
      apiUrl(`/practices/treatment-plans/${treatmentPlanId}/items/${itemId}`),
      adjustments
    )
  );
}

export function getStatusTextFromState(state: TreatmentPlanState): string {
  switch (state) {
    case TreatmentPlanState.PROPOSED:
      return 'Proposed';
    case TreatmentPlanState.ACCEPTED:
      return 'Accepted';
    case TreatmentPlanState.IN_PROGRESS:
      return 'In Progress';
    case TreatmentPlanState.COMPLETED:
      return 'Completed';
    case TreatmentPlanState.CANCELLED:
      return 'Cancelled';
    case TreatmentPlanState.REJECTED:
      return 'Rejected';
  }
}
