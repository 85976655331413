import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';

import { BookingsAndCreditsWorkflow } from '../../../amplitude';
import { LedgerMonth, PaymentHistoryRecord } from '../../../API/bookingsAndCredits';
import Cookies from '../../../shared/cookies/cookies';
import { stringifyMoney } from '../../../shared/money/stringifyMoney';
import { useURLQuery } from '../../../shared/routing/routing';
import NewPagination from '../../../shared/Table/NewPagination';
import Table, { ColumnDef } from '../../../shared/Table/Table';
import { parseBookingPeriodKey } from '../BookingPeriod';
import {
  getLedgerPaymentStatus,
  getMonthlyLedgersByPaymentStatus,
  getRedeemedCreditsAmount,
} from './bookingLedgerManipulation';
import PastMonthsLedgerColumnFilter from './PastMonthsLedgerColumnFilter';

export type MonthlyLedger = {
  monthYear: string;
  ledgerMonth: LedgerMonth;
};

type Props = {
  tableData: MonthlyLedger[];
  paymentHistoryRecords: PaymentHistoryRecord[];
};

const PastMonthsLedgerTable: React.FC<Props> = ({ tableData, paymentHistoryRecords }) => {
  const { query, updateQuery } = useURLQuery();
  const pageSize = 6;
  const currentPage = parseInt(query.get('page') || '1', 10);

  const initialAppliedFilter = query.get('paymentStatus');
  let defaultFilteredTableData: MonthlyLedger[] = tableData;

  if (initialAppliedFilter) {
    defaultFilteredTableData = getMonthlyLedgersByPaymentStatus({
      selectedStatus: initialAppliedFilter.charAt(0).toUpperCase() + initialAppliedFilter.slice(1),
      monthlyLedgerData: tableData,
      paymentHistoryRecords: paymentHistoryRecords,
    });
  }

  const [selectedPage, setSelectedPage] = useState<number>(currentPage || 1);
  const [filteredTableData, setFilteredTableData] =
    useState<MonthlyLedger[]>(defaultFilteredTableData);

  const setCookies = useCallback(
    (newParam: { [p: string]: string | null | undefined }) => {
      Cookies.bookingSearchMemory.set(updateQuery(newParam));
    },
    [updateQuery]
  );

  const paymentStatusFilterFromQuery = query.get('paymentStatus');

  const onNewPageSelected = useCallback(
    (page: number) => {
      setCookies({ page: page.toString() });
      setSelectedPage(page);
    },
    [setCookies]
  );

  const onPaymentStatusFilter = useCallback(
    (selected: string | null) => {
      setSelectedPage(1);
      if (selected === 'Payment Due') {
        updateQuery({ page: '1', paymentStatus: 'payment-due' });
      } else if (selected === 'Processing') {
        updateQuery({ page: '1', paymentStatus: 'processing' });
      } else if (selected === 'Paid') {
        updateQuery({ page: '1', paymentStatus: 'paid' });
      } else if (selected === null) {
        updateQuery({ paymentStatus: undefined });
      }
      setFilteredTableData(
        getMonthlyLedgersByPaymentStatus({
          selectedStatus: selected,
          monthlyLedgerData: tableData,
          paymentHistoryRecords: paymentHistoryRecords,
        })
      );
    },
    [updateQuery, paymentHistoryRecords, tableData]
  );

  const columns: ColumnDef<MonthlyLedger>[] = [
    {
      columnKey: 'emptyColumn',
      title: '',
      widthProportion: 0.1,
      render: () => {
        return <></>;
      },
    },
    {
      columnKey: 'status',
      title: 'Status',
      filterOptions: {
        filterOverlayXOffset: -80,
        filterOverlayYOffset: 30,
        contentRender: () => {
          let appliedFilterValue: string | undefined = undefined;
          if (paymentStatusFilterFromQuery === 'payment-due') {
            appliedFilterValue = 'Payment Due';
          } else if (paymentStatusFilterFromQuery === 'processing') {
            appliedFilterValue = 'Processing';
          } else if (paymentStatusFilterFromQuery === 'paid') {
            appliedFilterValue = 'Paid';
          }
          return (
            <PastMonthsLedgerColumnFilter
              options={['Payment Due', 'Processing', 'Paid']}
              initialFilter={appliedFilterValue}
              onFilter={onPaymentStatusFilter}
            />
          );
        },
        filterIconRender: () => {
          return (
            <FontAwesomeIcon
              icon={faFilter}
              className={
                paymentStatusFilterFromQuery !== null ? 'text-accent' : 'text-base-content'
              }
              style={{ marginLeft: '8px', cursor: 'pointer' }}
            />
          );
        },
      },
      widthProportion: 0.6,
      render: (record: MonthlyLedger) => {
        const { paymentStatus } = getLedgerPaymentStatus(record, paymentHistoryRecords);
        let backgroundColor = '';
        if (paymentStatus === 'Payment Due') {
          backgroundColor = 'bg-error-content';
        } else if (paymentStatus === 'Paid') {
          backgroundColor = 'bg-primary';
        } else if (paymentStatus === 'Processing') {
          backgroundColor = 'bg-secondary-content';
        }
        return (
          <span className={`p-1.5 rounded-2xl text-white font-semibold ${backgroundColor}`}>
            {paymentStatus}
          </span>
        );
      },
    },
    {
      columnKey: 'month',
      title: 'Month',
      widthProportion: 1,
      render: (record: MonthlyLedger) => {
        return (
          <span className={'font-semibold'}>
            {parseBookingPeriodKey(record.monthYear).displayForm}
          </span>
        );
      },
    },
    {
      columnKey: 'bookingCharges',
      title: 'Booking Charges',
      widthProportion: 1,
      render: (record: MonthlyLedger) => {
        return (
          <span>{stringifyMoney(record.ledgerMonth.charges.sum, { includeCommas: true })}</span>
        );
      },
    },
    {
      columnKey: 'creditsRedeemed',
      title: 'Credits Redeemed',
      widthProportion: 1,
      render: (record: MonthlyLedger) => {
        const redeemedCreditsAmount = getRedeemedCreditsAmount(record.ledgerMonth);
        if (redeemedCreditsAmount) {
          return <span>{stringifyMoney(redeemedCreditsAmount, { includeCommas: true })}</span>;
        }
        return <span>---</span>;
      },
    },
    {
      columnKey: 'totalBalance',
      title: 'Total Balance',
      widthProportion: 1,
      render: (record: MonthlyLedger) => {
        const totalBalance = record.ledgerMonth.balance;
        return (
          <span className={'font-semibold'}>
            {stringifyMoney(totalBalance, { includeCommas: true })}
          </span>
        );
      },
    },
  ];

  const startIndex = (selectedPage - 1) * pageSize;
  const paginatedLedgers = filteredTableData.slice(startIndex, startIndex + pageSize);

  return (
    <div id={'table-and-pagination'} className="w-full overflow-x-hidden">
      <Table
        id={'previous-months-table'}
        columns={columns}
        data={paginatedLedgers}
        rowKeyGenerator={(record: MonthlyLedger) => `${record.monthYear}`}
        onRowClick={(record: MonthlyLedger) => {
          setCookies({ period: record.monthYear });
        }}
        rowClassName="hover:font-bold cursor-pointer"
      />
      <NewPagination
        currentPage={selectedPage}
        pageSize={pageSize}
        totalItemCount={filteredTableData.length}
        onPageChange={onNewPageSelected}
        tracking={{
          workflow: BookingsAndCreditsWorkflow,
          context: 'bookingsAndCredits',
        }}
      />
      <div id={'padding-div'} className={'h-[200px] w-full'}></div>
    </div>
  );
};

export default PastMonthsLedgerTable;
