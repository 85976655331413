import { faCircleExclamation, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { cardLastFourFromPaymentMethod, PaymentMethod } from '../../../ServiceContext/invoices';
import { formatCardTypeName } from '../utilities';

type Props = {
  selectedPaymentMethod: PaymentMethod | null;
  hasNoAvailablePaymentMethods: boolean;
  patientTotalCardsOnFileCount: number;
  paymentMethodFailureMessage: string | null;
  renderAsSuccessfulPayment: boolean;
};

const PaymentEntryCardDetails: React.FC<Props> = ({
  selectedPaymentMethod,
  hasNoAvailablePaymentMethods,
  patientTotalCardsOnFileCount,
  paymentMethodFailureMessage,
  renderAsSuccessfulPayment,
}) => {
  const baseClasses = 'flex flex-row gap-2 items-center rounded-md p-2.5';
  let content;

  if (selectedPaymentMethod) {
    if (paymentMethodFailureMessage) {
      content = (
        <div
          id={`card-details-${selectedPaymentMethod.id}`}
          className={'flex flex-row gap-2 items-center'}
        >
          <div className="w-8 h-8 bg-error-content rounded-full text-base-100 font-bold text-2xl text-center flex-none">
            !
          </div>
          <FontAwesomeIcon icon={faCreditCard} style={{ color: 'lightslategray' }} />
          <span>
            <span className={'font-semibold'}>
              {formatCardTypeName(selectedPaymentMethod.type)}
            </span>
            &nbsp; ending in&nbsp;
            <span className={'font-semibold'}>
              {cardLastFourFromPaymentMethod(selectedPaymentMethod)}
            </span>
          </span>
          <span className="text-error-content font-bold">{paymentMethodFailureMessage}</span>
        </div>
      );
    } else if (renderAsSuccessfulPayment) {
      content = (
        <div
          id={`card-details-${selectedPaymentMethod.id}`}
          className={'flex flex-row gap-2 items-center'}
        >
          <div className="w-8 h-8 bg-success rounded-full text-base-100 font-bold text-2xl text-center flex-none">
            ✓
          </div>
          <FontAwesomeIcon icon={faCreditCard} style={{ color: 'lightslategray' }} />
          <span>
            <span className={'font-semibold'}>
              {formatCardTypeName(selectedPaymentMethod.type)}
            </span>
            &nbsp; ending in&nbsp;
            <span className={'font-semibold'}>
              {cardLastFourFromPaymentMethod(selectedPaymentMethod)}
            </span>
          </span>
          <span className="text-primary font-bold">
            Payment successful. No further action can be taken on this payment.
          </span>
        </div>
      );
    } else {
      content = (
        <div
          id={`card-details-${selectedPaymentMethod.id}`}
          className={'flex flex-row gap-2 items-center'}
        >
          <FontAwesomeIcon icon={faCreditCard} style={{ color: 'lightslategray' }} />
          <span>
            <span className={'font-semibold'}>
              {formatCardTypeName(selectedPaymentMethod.type)}
            </span>
            &nbsp; ending in&nbsp;
            <span className={'font-semibold'}>
              {cardLastFourFromPaymentMethod(selectedPaymentMethod)}
            </span>
          </span>
        </div>
      );
    }
  } else if (hasNoAvailablePaymentMethods) {
    let message;
    if (patientTotalCardsOnFileCount > 0) {
      message =
        'Patient does not have enough unused cards on file. Add a new card to pay with Credit Card.';
    } else {
      message =
        'No credit card on file. Add a new card to pay with Credit Card or select a different payment method.';
    }
    content = (
      <div>
        <FontAwesomeIcon
          icon={faCircleExclamation}
          style={{ color: 'lightcoral', scale: '140%' }}
        />
        &nbsp; &nbsp;
        {message}
      </div>
    );
  } else {
    content = (
      <div className="flex flex-row items-center gap-2">
        <FontAwesomeIcon
          icon={faCircleExclamation}
          style={{ color: 'lightcoral', scale: '140%' }}
        />
        Please select one of your available payment methods.
      </div>
    );
  }

  return (
    <div className={`${baseClasses} ${paymentMethodFailureMessage ? 'bg-error' : 'bg-highlight'}`}>
      {content}
    </div>
  );
};

export default PaymentEntryCardDetails;
