import { useRef, useState } from 'react';
import { useMutation } from 'react-query';

import { TreatmentPlanWorkflow } from '../../amplitude';
import { QueryContext } from '../../API/context';
import {
  DetailedTreatmentPlan,
  editTreatmentPlanItem,
  TreatmentPlanItem,
} from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import { Patient } from '../../ServiceContext/patients';
import Alert, { AlertData, errorAlert, successAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import ClearButton from '../../shared/ClearButton';

type Props = {
  authProvider: AuthProvider;
  onCloseClick: () => void;
  patient: Patient;
  treatmentPlanId: string;
  itemToEdit: TreatmentPlanItem;
  queryContext: QueryContext;
};

const EditForm: React.FC<Props> = ({
  authProvider,
  onCloseClick,
  patient,
  treatmentPlanId,
  itemToEdit,
  queryContext,
}) => {
  const cdtCodeClearButtonRef = useRef<SVGSVGElement>(null);
  const visitClearButtonRef = useRef<SVGSVGElement>(null);
  const descriptionClearButtonRef = useRef<SVGSVGElement>(null);
  const notesClearButtonRef = useRef<SVGSVGElement>(null);
  /* const teethClearButtonRef = useRef<SVGSVGElement>(null);
  const surfaceClearButtonRef = useRef<SVGSVGElement>(null); */

  const [cdtCode, setCdtCode] = useState<string>(itemToEdit.cdtCode || '');
  /* const [teeth, setTeeth] = useState<string[]>(itemToEdit.teeth || []);
  const [surface, setSurface] = useState(itemToEdit.surface || ''); */
  const [visit, setVisit] = useState(itemToEdit.visit || '');
  const [description, setDescription] = useState(itemToEdit.description || '');
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [editedItemId, setEditedItemId] = useState<string | null>(null);
  const [notes, setNotes] = useState(itemToEdit.additionalNotes || '');

  const mutation = useMutation(editTreatmentPlanItem, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      setAlert(
        successAlert(
          `You have successfully edited the treatment plan for ${patient.firstName} ${patient.lastName}.`
        )
      );
      let currentData = queryContext.client.getQueryData<DetailedTreatmentPlan>(queryContext.key);

      if (currentData) {
        currentData.items = currentData.items.map((item) => {
          if (item.id === data.id) {
            return data;
          }
          return item;
        });
        queryContext.client.setQueryData(queryContext.key, currentData);
      }

      setEditedItemId(data.id);
    },
    onError: (error) => {
      setIsLoading(false);
      setAlert(errorAlert(error as any));
    },
  });

  const onCdtCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCdtCode(e.target.value);
  };

  /* const onTeethInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeeth([e.target.value]);
  };

  const onSurfaceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSurface(e.target.value);
  }; */

  const onVisitInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVisit(e.target.value);
  };

  const onDescriptionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const onNotesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  const onCdtCodeClearButtonClick = () => {
    setCdtCode('');
  };

  /* const onTeethClearButtonClick = () => {
    setTeeth([]);
  };

  const onSurfaceClearButtonClick = () => {
    setSurface('');
  };

  const onFlossyPriceInputBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    valueInCents: number | undefined
  ) => {
    setFlossyPrice(valueInCents || null);
  }; */

  const onDescriptionClearButtonClick = () => {
    setDescription('');
  };

  const onNotesClearButtonClick = () => {
    setNotes('');
  };

  const onVisitClearButtonClick = () => {
    setVisit('');
  };

  const onSaveItemButtonClick = (e: React.FormEvent) => {
    e.preventDefault();

    mutation.mutate({
      authProvider,
      adjustments: {
        description,
        visit: visit,
        additionalNotes: `${notes}`,
      },
      treatmentPlanId,
      itemId: itemToEdit.id,
    });
  };

  return (
    <form className="add-row-form flex flex-col gap-3 rounded-lg text-secondary text-left py-8">
      {alert && <Alert {...alert} />}
      {!editedItemId && (
        <>
          <div className="input-group flex flex-col items-start mt-2">
            <label htmlFor="cdt-code-input" className="cdt-code-label font-semibold mb-1">
              CDT Code (required)
            </label>
            <div className="cdt-code-input relative">
              <input
                title="CDT Code (required)"
                onChange={onCdtCodeChange}
                value={cdtCode}
                className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
                autoComplete="off"
                disabled={isLoading}
              />
              <div className="cdt-code-clear-button absolute top-[1px] right-[2px]">
                <ClearButton
                  ref={cdtCodeClearButtonRef}
                  noDisplay={!cdtCode}
                  onClick={onCdtCodeClearButtonClick}
                />
              </div>
            </div>
          </div>
          {/* <div className="input-group flex flex-col items-start">
            <label htmlFor="teeth-input" className="teeth-label font-semibold mb-1">
              Teeth
            </label>
            <div className="teeth-input relative">
              <input
                title="Teeth"
                onChange={onTeethInputChange}
                value={teeth}
                className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
                autoComplete="off"
                disabled={isLoading}
              />
              <div className="teeth-clear-button absolute top-[1px] right-[2px]">
                <ClearButton
                  ref={teethClearButtonRef}
                  noDisplay={teeth.length === 0}
                  onClick={onTeethClearButtonClick}
                />
              </div>
            </div>
          </div>
          <div className="input-group flex flex-col items-start">
            <label htmlFor="surface-input" className="surface-label font-semibold mb-1">
              Surface
            </label>
            <div className="surface-input relative">
              <input
                title="Surface"
                onChange={onSurfaceInputChange}
                value={surface}
                className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
                autoComplete="off"
                disabled={isLoading}
              />
              <div className="surface-clear-button absolute top-[1px] right-[2px]">
                <ClearButton
                  ref={surfaceClearButtonRef}
                  noDisplay={!surface}
                  onClick={onSurfaceClearButtonClick}
                />
              </div>
            </div>
          </div> */}
          <div className="input-group flex flex-col items-start">
            <label htmlFor="description-input" className="description-label font-semibold mb-1">
              Description (required)
            </label>
            <div className="description-input relative">
              <input
                title="Description (required)"
                onChange={onDescriptionInputChange}
                value={description}
                className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
                autoComplete="off"
                disabled={isLoading}
              />
              <div className="description-clear-button absolute top-[1px] right-[2px]">
                <ClearButton
                  ref={descriptionClearButtonRef}
                  noDisplay={!description}
                  onClick={onDescriptionClearButtonClick}
                />
              </div>
            </div>
          </div>
          <div className="input-group flex flex-col items-start">
            <label htmlFor="notes-input" className="notes-label font-semibold mb-1">
              Additional Notes
            </label>
            <div className="notes-input relative">
              <input
                title="Additional Notes"
                onChange={onNotesInputChange}
                value={notes}
                className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
                autoComplete="off"
                disabled={isLoading}
              />
              <div className="notes-clear-button absolute top-[1px] right-[2px]">
                <ClearButton
                  ref={notesClearButtonRef}
                  noDisplay={!notes}
                  onClick={onNotesClearButtonClick}
                />
              </div>
            </div>
          </div>
          <div className="input-group flex flex-col items-start">
            <label htmlFor="visit-input" className="visit-label font-semibold mb-1">
              Visit
            </label>
            <div className="visit-input relative">
              <input
                title="Visit"
                onChange={onVisitInputChange}
                value={visit}
                className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
                autoComplete="off"
                disabled={isLoading}
              />
              <div className="visit-clear-button absolute top-[1px] right-[2px]">
                <ClearButton
                  ref={visitClearButtonRef}
                  noDisplay={!visit}
                  onClick={onVisitClearButtonClick}
                />
              </div>
            </div>
          </div>
          {/* <div className="input-group flex flex-col items-start">
            <label htmlFor="flossy-price-input" className="flossy-price-label font-semibold mb-1">
              Flossy Price
            </label>
            <MoneyInput
              id="flossy-price-input"
              name="flossy-price-input"
              onBlur={onFlossyPriceInputBlur}
              defaultValueInCents={flossyPrice || 0}
              showErrorFn={(val) => val !== undefined && val < 0}
              placeholder={stringifyMoney(flossyPrice || 0, { includeCommas: true })}
              className="border rounded-md p-1.5 focus:outline-blue-200"
            />
          </div> */}
        </>
      )}
      <div className="action-buttons justify-center flex flex-row gap-4 mt-8">
        {!editedItemId && (
          <Button
            onClick={onSaveItemButtonClick}
            loading={isLoading}
            disabled={!cdtCode || !description}
            workflow={TreatmentPlanWorkflow}
            trackingLabel="Save Item Button"
            context="editForm"
          >
            Save item
          </Button>
        )}
        <Button
          onClick={onCloseClick}
          noFill
          disabled={isLoading}
          workflow={TreatmentPlanWorkflow}
          trackingLabel="Cancel Edit Item Button"
          context="editForm"
        >
          Close
        </Button>
      </div>
    </form>
  );
};

export default EditForm;
