import { DateTime } from 'luxon';
import React, { useState } from 'react';

type Props = {
  id?: string;
  className?: string;
  placeholder?: string;
  onChange?: (date: string, isValid: boolean) => void;
  onBlur?: (date: string, isValid: boolean) => void;
};

const DateInput: React.FC<Props> = ({
  placeholder = 'MM-DD-YYYY',
  id,
  className,
  onChange,
  onBlur,
}) => {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validateAndFormatDate = (
    inputValue: string
  ): {
    formattedValue: string;
    isValid: boolean;
  } => {
    const numericValue = inputValue.replace(/\D/g, '');

    if (numericValue.length < 6) {
      return {
        formattedValue: inputValue,
        isValid: false,
      };
    }

    let parsedDate = DateTime.fromFormat(numericValue, 'MMddyyyy');
    if (!parsedDate.isValid && numericValue.length === 6) {
      // Try to prepend zeros for MM and DD if omitted
      parsedDate = DateTime.fromFormat(
        `0${numericValue.slice(0, 1)}0${numericValue.slice(1, 2)}${numericValue.slice(2)}`,
        'MMddyyyy'
      );
    }

    if (!parsedDate.isValid && numericValue.length === 7) {
      // Try to prepend a zero for MM if omitted
      parsedDate = DateTime.fromFormat(
        `0${numericValue.slice(0, 2)}${numericValue.slice(2)}`,
        'MMddyyyy'
      );
    }

    if (parsedDate.isValid) {
      return {
        formattedValue: parsedDate.toFormat('MM-dd-yyyy'),
        isValid: true,
      };
    } else {
      return {
        formattedValue: inputValue,
        isValid: false,
      };
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const result = validateAndFormatDate(event.target.value);
    setValue(result.formattedValue);
    onBlur && onBlur(result.formattedValue, result.isValid);
    if (!result.isValid) {
      setErrorMessage('Please enter a valid birth date in MM-DD-YYYY format');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setValue(event.target.value);
    onChange && onChange(event.target.value, true);
  };

  return (
    <div>
      <input
        id={id}
        className={`${className} rounded-md p-2.5 text-sm focus:outline-blue-100 border`}
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        maxLength={10}
      />
      {errorMessage && <span className="text-xs text-red-600">{errorMessage}</span>}
    </div>
  );
};

export default DateInput;
