import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { runFTrack, SideEffectTrackingAction, TreatmentPlanWorkflow } from '../../amplitude';
import { searchProcedures } from '../../API/procedures';
import { AuthProvider } from '../../Authentication/Authentication';
import { SearchIcon } from '../../HomePage/components/Icons';
import { Procedure } from '../../ServiceContext/procedures';
import { Dentist } from '../../ServiceContext/user';
import { splitOnCommaAndSpace } from '../../shared/strings';

type Props = {
  authProvider: AuthProvider;
  dentist: Dentist;
  onCodesEntered: (procedures: Procedure[]) => void;
  searchButtonText?: string;
};

const SpeedyCDTInput: React.FC<Props> = ({
  authProvider,
  dentist,
  onCodesEntered,
  searchButtonText,
}) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const getCodeAsList = () => {
    const uppercasedInputCode = code.toUpperCase();
    return splitOnCommaAndSpace(uppercasedInputCode);
  };

  const { mutate: search, isLoading: isSearchingProcedures } = useMutation(searchProcedures, {
    onSuccess: (procedures) => {
      runFTrack({
        event: 'Search For Procedures Success',
        workflow: TreatmentPlanWorkflow,
        action: SideEffectTrackingAction,
        context: 'speedyCdtInput',
        componentId: 'speedyCdtInputSearch',
        extraProps: {
          codes: getCodeAsList(),
        },
      });
      // This code normalizes input codes to match 'D' prefixed codes in the backend, for the convenience of
      // user input.
      const inputCodes = getCodeAsList().map((c) =>
        c.trim().startsWith('D') ? c.trim() : `D${c.trim()}`
      );

      const proceduresMatchingInputAndSpecialty = procedures.filter(
        (p) => p.specialty === dentist.specialty && inputCodes.includes(p.cdt.toUpperCase())
      );
      let proceduresMapping: { [key: string]: Procedure } = {};
      procedures.forEach((p) => {
        proceduresMapping[p.cdt] = p;
      });

      const matchedCodes = inputCodes.filter((c) =>
        proceduresMatchingInputAndSpecialty.map((p) => p.cdt.toUpperCase()).includes(c)
      );

      // Removes matched codes from input for the convenience of the user.
      const remainingCodes = inputCodes
        .filter((c) => !matchedCodes.includes(c))
        .map((c) => (c.startsWith('D') ? c.substring(1) : c))
        .join(', ');

      if (proceduresMatchingInputAndSpecialty.length > 0) {
        onCodesEntered(matchedCodes.map((c) => proceduresMapping[c]));
        setCode(remainingCodes);
        setError('');
      } else {
        setError('Some codes were not found');
      }

      if (remainingCodes) {
        toast.error(`Codes not found or not in your specialty: ${remainingCodes}`, {
          position: 'top-center',
        });
      }
    },
    onError: () => {
      setError('An error occurred while searching');
      toast.error('An error occurred while searching for procedures.', { position: 'top-center' });
      runFTrack({
        event: 'Search For Procedures Error',
        workflow: TreatmentPlanWorkflow,
        action: SideEffectTrackingAction,
        context: 'speedyCdtInput',
        componentId: 'speedyCdtInputSearch',
        extraProps: {
          error: 'Please enter a code',
        },
      });
    },
  });

  // We have this here to ensure that focus remains in the field when a code is punched in successfully.
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [code]);

  // We have this here to ensure that focus remains in the field when an error occurs.
  useEffect(() => {
    if (error && inputRef.current) {
      inputRef.current.focus();
      const valueLength = inputRef.current.value.length;
      inputRef.current.setSelectionRange(valueLength, valueLength);
    }
  }, [error]);

  const performSearch = () => {
    if (code.trim() === '') {
      setError('Please enter a code');
    } else {
      // We do this for the convenience of the user -- they can either separate the codes with commas or spaces.
      const codeArg = getCodeAsList().join(',');
      search({ authProvider, dentistId: dentist.id, code: codeArg });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      performSearch();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    if (error) {
      setError('');
    }
  };

  const disableSearchButton = isSearchingProcedures || code.trim() === '';
  return (
    <div className={`flex flex-col ${isSearchingProcedures ? 'bg-base-100' : ''}`}>
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={code}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          className={`flex items-center py-2.5 pl-10 border rounded-md text-sm w-full ${
            error ? 'border-error' : 'border-rule'
          } focus:outline-none focus:ring focus:ring-rule gap-2`}
          placeholder="Enter CDT codes separated by commas"
          disabled={isSearchingProcedures}
        />
        <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
          <SearchIcon />
        </div>
      </div>
      {error && <span className="text-error text-sm mt-2 text-left">{error}</span>}
      {searchButtonText && (
        <button
          onClick={performSearch}
          className={`mt-2 bg-[#f6e4e0] text-gray-800 p-2 rounded-md w-[256px] px-4 transition-colors duration-150 ${
            disableSearchButton
              ? 'opacity-50 cursor-not-allowed text-gray-400 bg-[#fceded]'
              : 'hover:bg-[#f8dada]'
          }`}
          disabled={disableSearchButton}
        >
          {searchButtonText}
        </button>
      )}
    </div>
  );
};

export default SpeedyCDTInput;
