export function range(count: number, start?: number) {
  if (count <= 0) {
    return [];
  }

  const startIndex = start || 0;
  const numbers: number[] = [];

  for (let i = startIndex; i < startIndex + count; ++i) {
    numbers.push(i);
  }

  return numbers;
}

export function isNumber(value: any): value is number {
  return !isNaN(value) && typeof value === 'number';
}
