import { AuthProvider } from '../Authentication/Authentication';
import { PayoutInvoiceSummary, PayoutSummary } from '../ServiceContext/invoices';
import { authenticatedGet } from '../ServiceContext/service';
import { apiUrl } from '../ServiceContext/shared';
import { handleAPIResponse } from './response';

export interface PayoutCluster {
  dentistId: string;
  summary: {
    name: string;
    total: number;
    paymentDay: 1;
    payoutDate: string;
    cutoffDate: string;
    invoiceSummaries: PayoutInvoiceSummary[];
  };
}

export interface Payout {
  payoutId: string;
  summary: PayoutSummary;
}
export async function getPayableInvoicesForDentist({
  authProvider,
  dentistId,
}: {
  authProvider: AuthProvider;
  dentistId: string;
}): Promise<PayoutCluster[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedGet<PayoutCluster[]>(
      authProvider,
      apiUrl(`/dentists/invoices/payable?dentistId=${dentistId}`)
    )
  );
}

export async function getUpcomingPayoutsForPractice({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
}): Promise<PayoutCluster[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedGet<PayoutCluster[]>(
      authProvider,
      apiUrl(`/practices/${practiceId}/upcoming-payout`)
    )
  );
}

export async function getAllPayoutsForDentist({
  authProvider,
  dentistId,
}: {
  authProvider: AuthProvider;
  dentistId: string;
}): Promise<Payout[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedGet<Payout[]>(authProvider, apiUrl(`/dentists/${dentistId}/invoices/payouts`))
  );
}

export async function getAllPayoutsForPractice({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
}): Promise<Payout[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedGet<Payout[]>(authProvider, apiUrl(`/practices/${practiceId}/payouts`))
  );
}

export async function getSinglePayoutByPayoutId({
  authProvider,
  practiceId,
  payoutId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
  payoutId: string;
}): Promise<Payout | null> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedGet<Payout>(authProvider, apiUrl(`/practices/${practiceId}/payouts/${payoutId}`))
  );
}
