export const emailRegex: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email: string): boolean {
  return emailRegex.test(email.toLowerCase());
}

export const percentageRegex: RegExp =
  /(^100(\.0{1,2})?(%)?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?(%)?$)/;

export function isNumberBetweenMinMax({
  input,
  min,
  max,
}: {
  input: string;
  min: number;
  max: number;
}): boolean {
  const parsedNumber: number = parseFloat(input);
  return !isNaN(parsedNumber) && parsedNumber >= min && parsedNumber <= max;
}
