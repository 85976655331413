import React, { PropsWithChildren } from 'react';

type Props = {
  badgeContent: string;
  className?: string;
};

const WithBadge: React.FC<PropsWithChildren<Props>> = ({
  children,
  badgeContent,
  className: propsClassName,
}) => {
  let className = 'with-badge-wrapper relative inline-block';
  if (propsClassName) {
    className = `${propsClassName} ${className}`;
  }

  return (
    <div className={className}>
      {badgeContent && (
        <span className="notify-badge rounded-full absolute top-1 -right-2 flex justify-center items-center w-5 h-5 text-white font-bold text-xs bg-[#ff0000] z-10">
          {badgeContent}
        </span>
      )}
      {children}
    </div>
  );
};

export default WithBadge;
