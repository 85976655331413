import React, { createContext, PropsWithChildren, useContext } from 'react';

export type Routing = {
  buildPatientWebAppRoute: (route: string) => string;
};

const routingContext = createContext<Routing>({
  buildPatientWebAppRoute: () => {
    console.log('default routing context set');
    return '';
  },
});

const RoutingContext: React.FC<PropsWithChildren> = ({ children }) => {
  const buildPatientWebAppRoute = (route: string) => {
    return `${process.env.REACT_APP_PATIENT_APP_HOSTNAME}${route}`;
  };

  const routing = {
    buildPatientWebAppRoute: buildPatientWebAppRoute,
  };
  return <routingContext.Provider value={routing}>{children}</routingContext.Provider>;
};

export default RoutingContext;

export function useRouting() {
  return useContext(routingContext);
}
