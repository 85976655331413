import React, { PropsWithChildren, useRef } from 'react';

import Button, { ButtonProps } from './Button/Button';

type Props = {
  onFilesUploaded: (files: File[]) => void;
  multiple?: boolean;
} & Omit<ButtonProps, 'onClick'>;

const FileUploadButton: React.FC<PropsWithChildren<Props>> = ({
  onFilesUploaded,
  multiple = false,
  children,
  ...buttonProps
}) => {
  const fileInputRef: React.Ref<HTMLInputElement> = useRef(null);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files) {
      onFilesUploaded(Array.from(e.target.files));
    }
  };

  const onButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Button {...buttonProps} onClick={onButtonClick}>
        {children}
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={onChange}
        multiple={multiple}
        aria-label="file-upload-input"
      />
    </>
  );
};

export default FileUploadButton;
