export function handleAPIResponse<T>(p: Promise<T | ErrorResponse>): Promise<T> {
  return p.then((response) => {
    if (isErrorResponse(response)) {
      return Promise.reject(response);
    }
    return response;
  });
}

export function handleAPIResponseNullify404<T>(p: Promise<T | ErrorResponse>): Promise<T | null> {
  return p.then((response) => {
    if (isErrorResponse(response)) {
      if (response.statusCode === 404) {
        return null;
      }

      return Promise.reject(response);
    }
    return response;
  });
}

export type ApiErrorDetailsObject = {
  errors: ApiError[];
};

export function isApiErrorDetailsObject<T>(
  er: T | ApiErrorDetailsObject
): er is ApiErrorDetailsObject {
  return (
    er !== undefined &&
    er !== null &&
    (er as ApiErrorDetailsObject).errors !== undefined &&
    (er as ApiErrorDetailsObject).errors !== null
  );
}

export type ApiError = {
  message: string;
  path: string;
};
export type ErrorResponseBody = string | ApiErrorDetailsObject;

export interface ErrorResponse {
  statusCode: number;
  errorResponse: ErrorResponseBody;
}

export function isErrorResponse<T>(er: T | ErrorResponse): er is ErrorResponse {
  return (
    er !== undefined &&
    er !== null &&
    (er as ErrorResponse).statusCode !== undefined &&
    (er as ErrorResponse).statusCode !== null &&
    (er as ErrorResponse).errorResponse !== undefined &&
    (er as ErrorResponse).errorResponse !== null
  );
}

export function isAbortedRequest(er: unknown): boolean {
  if (er instanceof Error) {
    return er.message.includes('user aborted a request');
  }

  return false;
}

export const abortedRequestErrorResponse: ErrorResponse = {
  errorResponse: 'Request aborted',
  statusCode: 0,
};

export function isAbortedRequestErrorResponse(er: ErrorResponse): boolean {
  return er.errorResponse === 'Request aborted' && er.statusCode === 0;
}

export interface StripeError {
  code: string;
  doc_url: string;
  request_log_url: string;
  message: string;
  type: string;
}

export function isStripeError<T>(er: T | StripeError): er is StripeError {
  return (
    er !== undefined &&
    er !== null &&
    (er as StripeError).message !== undefined &&
    (er as StripeError).message !== null &&
    (er as StripeError).type !== undefined &&
    (er as StripeError).type !== null &&
    (er as StripeError).doc_url !== undefined &&
    (er as StripeError).doc_url !== null &&
    (er as StripeError).request_log_url !== undefined &&
    (er as StripeError).request_log_url !== null
  );
}

export class SentryCompositeError extends Error {
  summary: string;
  extra: object;

  constructor(args: { summary: string; extra: object }) {
    super(args.summary); // This sets the message property of the Error object
    this.summary = args.summary;
    this.extra = args.extra;
  }
}

export function isSentryCompositeError(obj: object): obj is SentryCompositeError {
  return (
    obj !== undefined &&
    obj !== null &&
    (obj as SentryCompositeError).extra !== undefined &&
    (obj as SentryCompositeError).extra !== null &&
    (obj as SentryCompositeError).summary !== undefined &&
    (obj as SentryCompositeError).summary !== null
  );
}
