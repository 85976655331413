import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AuthProvider } from '../Authentication/Authentication';
import LogoutButton from '../LogoutButton/LogoutButton';
import MessageCenter from '../Messaging/MessageCenter';
import SettingsFrameIcon from '../Settings/components/SettingsFrameIcon';
import AnchorButton from '../shared/AnchorButton';

type Props = {
  authProvider: AuthProvider;
};

const AuthedControlPanel: React.FC<Props> = ({ authProvider }) => {
  const location = useLocation();

  if (!authProvider.isLoggedIn) {
    return null;
  }
  let controls = [
    {
      key: 'home',
      path: '/home',
      text: 'Home',
      type: 'link',
    },
  ];

  controls.push({
    key: 'appointments',
    path: '/appointments',
    text: 'Appointments',
    type: 'link',
  });

  controls.push({
    key: 'patients',
    path: '/patients',
    text: 'Patient Directory',
    type: 'link',
  });

  controls.push({
    key: 'accounting',
    path: '/accounting',
    text: 'Accounting',
    type: 'link',
  });

  controls.push({
    key: 'availability',
    path: '/availability',
    text: 'Availability',
    type: 'link',
  });

  controls.push({
    key: 'referral-program',
    path: '/referral-program',
    text: 'Referral Program',
    type: 'link',
  });

  return (
    <div id="navbar-controls" className="navbar-controls flex flex-row grow justify-between">
      <ul className="navbar-nav flex flex-row w-full gap-4 items-center">
        {controls.map((c) => {
          const className = location.pathname.startsWith(c.path)
            ? 'active text-base-300 font-bold text-base'
            : 'text-sm text-secondary-content';
          if (c.type === 'link') {
            return (
              <Link key={c.key} to={c.path} className="nav-link">
                <li className={className}>{c.text}</li>
              </Link>
            );
          }

          return (
            <AnchorButton id={c.key} key={c.key} className="profile-link-button" href={c.path}>
              <span>{c.text}</span>
            </AnchorButton>
          );
        })}
      </ul>
      <div className="right-side-controls flex flex-row-reverse items-center gap-2">
        <LogoutButton authentication={authProvider} />
        <SettingsFrameIcon authentication={authProvider} />
        <MessageCenter authentication={authProvider} />
      </div>
    </div>
  );
};

export default AuthedControlPanel;
