import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethod, PaymentMethodResult, StripePaymentElementOptions } from '@stripe/stripe-js';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { GeneralWorkflow } from '../../amplitude';
import { addPaymentMethod } from '../../API/practice';
import { isErrorResponse, isStripeError } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import { Practice } from '../../ServiceContext/user';
import Button from '../../shared/Button/Button';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { createPaymentMethodModel } from '../../shared/paymentMethods';

export default function NewPaymentMethodModal({
  onClose,
  clientSecret,
  authProvider,
  practice,
}: {
  onClose: () => void;
  clientSecret: string;
  authProvider: AuthProvider;
  practice: Practice;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const [isDefault, setIsDefault] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [cardError, setCardError] = useState<string | null>(null);

  const createPaymentMethod = useMutation({
    mutationFn: async () => {
      setLoading(true);
      if (!elements || !stripe) {
        throw new Error('Stripe not initialized');
      }

      await elements.submit();

      return stripe.createPaymentMethod({
        elements,
      });
    },
    onSuccess: (data) => {
      console.log(data);
      if (data.error) {
        setError('', data.error.message ?? 'Error creating payment method');
        return;
      }

      confirmCardSetup.mutate({ clientSecret, paymentMethod: data.paymentMethod });
    },
    onError: (error) => {
      setError(error, 'Error creating payment method');
    },
  });

  const confirmCardSetup = useMutation({
    mutationFn: ({
      clientSecret,
      paymentMethod,
    }: {
      clientSecret: string;
      paymentMethod: PaymentMethod;
    }) => {
      if (!stripe) {
        throw new Error('Stripe not initialized');
      }

      return stripe.confirmCardSetup(clientSecret, {
        payment_method: paymentMethod!.id,
      });
    },
    onSuccess: (data) => {
      if (data.error) {
        setError('', data.error.message ?? 'Error confirming card setup');
        return;
      }

      addFlossyPaymentMethod.mutate();
    },
    onError: (error) => {
      console.error(error);
      setError(error, 'Error confirming card setup');
    },
  });

  const addFlossyPaymentMethod = useMutation({
    mutationFn: async () => {
      const paymentMethod = (createPaymentMethod.data as PaymentMethodResult).paymentMethod;
      if (!paymentMethod || paymentMethod.card === undefined) {
        throw new Error('Payment method not created');
      }

      const flossyPaymentMethod = createPaymentMethodModel({
        stripePaymentMethodId: paymentMethod.id,
        type: `cc.${paymentMethod.card!.brand}`,
        cardBrand: paymentMethod.card!.brand,
        cardLast4: paymentMethod.card.last4,
        stripeExpirationMonth: paymentMethod.card.exp_month,
        stripeExpirationYear: paymentMethod.card.exp_year,
      });

      return addPaymentMethod({
        authProvider,
        practiceId: practice.id,
        paymentMethod: flossyPaymentMethod,
        isDefault: practice.defaultPaymentMethod === null || isDefault,
      });
    },
    onSuccess: (data) => {
      setLoading(false);
      queryClient.setQueryData(['practice', practice?.id], data);
      onClose();
    },
    onError: (error) => {
      setError(error, 'Error adding payment method');
    },
  });

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="flex flex-col bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Add new payment method</h2>
        <form>
          <PaymentElement id="payment-element" options={paymentElementOptions} />
        </form>
        <div className="mb-2">
          <p className="text-[12px] text-info-content mt-4">
            <strong>Note:</strong> Flossy processes cards with Stripe. We cannot accept debit cards,
            international credit cards, or cards with pin codes.
          </p>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              className="form-checkbox"
              onChange={(e) => setIsDefault(e.target.checked)}
              checked={isDefault}
            />
            <span className="ml-2 text-sm text-gray-700">Set as your default payment account</span>
          </label>
        </div>
        {cardError && <p className="text-red-500">{cardError}</p>}
        <div className="flex justify-end space-x-4">
          <Button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg"
            id="add-new-payment-method-modal-button"
            onClick={async () => {
              try {
                await createPaymentMethod.mutateAsync();
              } catch (error) {
                setError(error, 'Error creating payment method');
              }
            }}
            omitBorder
            workflow={GeneralWorkflow}
            context="addNewPaymentCardModal"
            trackingLabel="Add New Card Method Modal"
          >
            Add New Card
          </Button>
          <Button
            noFill
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg"
            id="close-new-payment-method-modal-button"
            onClick={onClose}
            workflow={GeneralWorkflow}
            context="closeNewPaymentMethodModal"
            trackingLabel="Close New Payment Method Modal"
          >
            Cancel
          </Button>
          {loading && <LoadingSpinner size="xs" />}
        </div>
      </div>
    </div>
  );

  function setError(error: any, message: string): void {
    if (isStripeError(error)) {
      setCardError(error.message);
    } else if (isErrorResponse(error)) {
      setCardError(error.errorResponse.toString());
    } else {
      setCardError(message);
    }
    setLoading(false);
  }
}
