import React from 'react';

import { PatientWorkflow } from '../../../amplitude';
import FileUploadButton from '../../../shared/FileUploadButton';

type Props = {
  onFilesUploaded: (args: { file: File; fileName: string }) => void;
  isLoading: boolean;
};

const UploadPatientFileButton: React.FC<Props> = ({ onFilesUploaded, isLoading }) => {
  return (
    <FileUploadButton
      id="upload-patient-file-button"
      onFilesUploaded={async (files: File[]) => {
        if (files.length > 0) {
          await onFilesUploaded({ file: files[0], fileName: files[0].name });
        }
      }}
      loading={isLoading}
      multiple={false}
      workflow={PatientWorkflow}
      context="uploadPatientFileButton"
      trackingLabel="Upload Additional File Button"
    >
      Upload Additional File
    </FileUploadButton>
  );
};

export default UploadPatientFileButton;
