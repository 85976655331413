import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ClearIcon from '../ClearIcon';
import { PhoneNumberMachine, PhoneNumberMachineOutput } from './PhoneNumberMachine';

type Props = {
  onChange: (value: string) => void;
  disabled?: boolean;
};

const PhoneNumberInput: React.FC<Props> = ({ onChange, disabled = false }) => {
  const [error, setError] = useState<string | null>(null);
  const phoneNumberMachine = useMemo(() => new PhoneNumberMachine(), []);
  const [output, setOutput] = useState<PhoneNumberMachineOutput>({
    value: '',
    displayString: '(___) ___-___',
  });
  const phoneClearButtonRef = useRef<SVGSVGElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const lastDigit = output.value.slice(-1);
      const position = output.displayString.lastIndexOf(lastDigit) + 1;
      inputRef.current.setSelectionRange(position, position);
    }
  }, [output]);

  const onPhoneClearButtonClick = () => {
    setOutput({ value: '', displayString: '(___) ___-___' });
    setError(null);
  };

  const phoneNumberCheck = (value: string) => {
    if (value.length < 10) {
      setError('Please enter a valid US phone number');
    } else {
      setError(null);
    }
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value;
      const newOutput = phoneNumberMachine.set(input);
      setOutput(newOutput);
      phoneNumberCheck(newOutput.value);
      onChange(newOutput.value);
    },
    [onChange, phoneNumberMachine]
  );

  return (
    <div className="phone-input-container relative">
      <div className="w-full relative">
        <input
          ref={inputRef}
          type="text"
          value={output.displayString}
          disabled={disabled}
          onChange={handleChange}
          className={`border h-10 w-full rounded-lg pl-3 ${
            output.value === '' ? 'text-base-content' : 'text-neutral'
          } focus:outline-blue-200`}
        />
        <div className="phone-clear-button absolute right-0 -translate-y-1/2 top-1/2">
          <ClearIcon
            ref={phoneClearButtonRef}
            noDisplay={!output.value}
            onClick={onPhoneClearButtonClick}
            color="grey"
          />
        </div>
      </div>
      {error && <span className="input-error-message italic text-[#bf2e3c]">{error}</span>}
    </div>
  );
};

export default PhoneNumberInput;
