import React, { useState } from 'react';

type Props = {
  options: string[];
  initialFilter?: string;
  onFilter: (selectedOption: string | null) => void;
};

const AppointmentTableColumnFilter: React.FC<Props> = ({ options, initialFilter, onFilter }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(initialFilter || null);

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
    onFilter(option);
  };

  const onClearFilterClick = () => {
    setSelectedOption(null);
    onFilter(null);
  };

  return (
    <div className="radio-container flex flex-col gap-2.5 p-4">
      {options.map((option) => (
        <label
          key={option}
          className="radio-label flex flex-row gap-2 items-center font-normal text-sm"
        >
          <input
            type="checkbox"
            value={option}
            checked={selectedOption === option}
            onChange={() => handleOptionChange(option)}
            style={{
              accentColor: '#EF6C52',
            }}
            // TailwindCSS is not picking this up for some reason, so we use "style" above.
            className="accent-red-500"
          />
          <span className={''}>{option}</span>
        </label>
      ))}
      <button
        className={'font-semibold text-start underline text-nowrap mt-2 hover:opacity-75'}
        onClick={onClearFilterClick}
      >
        Clear Filters
      </button>
    </div>
  );
};
export default AppointmentTableColumnFilter;
