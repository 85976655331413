import React from 'react';
import { useNavigate } from 'react-router-dom';

import { InvoicingWorkflow } from '../../../amplitude';
import { Appointment } from '../../../ServiceContext/appointments';
import Button from '../../../shared/Button/Button';

type Props = {
  onBookFollowUp: () => void;
  appointment: Appointment;
};

const buttonStyling = 'bg-primary text-white hover:bg-opacity-[75%]';

const PaymentCompleteNextSteps: React.FC<Props> = ({ onBookFollowUp, appointment }) => {
  const navigate = useNavigate();
  return (
    <div id={'next-steps'} className={'flex flex-col gap-3 text-left bg-white p-3 rounded-lg'}>
      <span className={'text-lg font-semibold'}>Next Steps</span>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col gap-2'}>
          <span className={'text-xs'}>
            Let Flossy know when the patient's next visit at your practice is happening.
          </span>
          <Button
            onClick={onBookFollowUp}
            className={`${buttonStyling} max-w-fit`}
            workflow={InvoicingWorkflow}
            trackingLabel="Book Follow-up Appointment Button"
            context="paymentComplete"
          >
            Book a Follow-up Appointment
          </Button>
        </div>
        <div className={'flex flex-col gap-2'}>
          <span className={'text-xs'}>
            Upload a treatment plan by selecting images or PDFs from your computer.
          </span>
          <Button
            onClick={() => navigate(`/patients/${appointment.userId}/treatment-plans/new`)}
            className={`${buttonStyling} max-w-fit`}
            workflow={InvoicingWorkflow}
            trackingLabel="Add Treatment Plan Button"
            context="paymentComplete"
          >
            Add Treatment Plan
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentCompleteNextSteps;
