import { useQuery } from 'react-query';

import { getPatientInsuranceForAppointment, PatientInsurance } from '../../../API/insurance';

/**
 * Custom hook to fetch and return patient insurance data for a given appointment.
 *
 * @param {string | null | undefined} appointmentId - The appointment ID to fetch insurance data for.
 * @param {any} authentication - The authentication object to use for the API call.
 * @returns {object} An object containing:
 * - isLoading: A boolean indicating if the query is still loading.
 * - isError: A boolean indicating if the query encountered an error.
 * - data: The patient insurance data, or null if it's not available.
 * - error: The error object, or null if there was no error.
 */

// TODO would like this to only require the insurance ID
export function useAppointmentInsurance(
  appointmentId: string | null | undefined,
  authentication: any
): {
  isLoading: boolean;
  isError: boolean;
  data: PatientInsurance | null | undefined;
  error: Error | null | unknown;
} {
  const { isLoading, isError, data, error } = useQuery<PatientInsurance | null>(
    `insurance_${appointmentId}`,
    () =>
      getPatientInsuranceForAppointment({
        appointmentId: appointmentId!,
        authProvider: authentication,
      }),
    {
      enabled: !!appointmentId,
    }
  );

  if (isError) {
    console.log('Error fetching patient insurance: ', error);
  }

  return { isLoading, isError, data, error };
}
