import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { ErrorResponse } from '../../API/response';
import {
  DetailedTreatmentPlan,
  getDetailedTreatmentPlan,
  getStatusTextFromState,
} from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import { RootRedirect } from '../../Routing/Router';
import Alert, { errorAlert } from '../../shared/Alert';
import PortalContainer from '../../shared/Container/PortalContainer';
import { isDentistInManagedList } from '../../shared/dentist';
import LoadingSpinner from '../../shared/LoadingSpinner';
import TreatmentPlanBanner from './TreatmentPlanBanner';
import TreatmentPlanButtonSection from './TreatmentPlanButtonSection';
import TreatmentPlanHeader from './TreatmentPlanHeader';
import TreatmentPlanItemsTable from './TreatmentPlanItemsTable';
import TreatmentPlanNotes from './TreatmentPlanNotes';
import TreatmentPlanPatientSection from './TreatmentPlanPatientSection';
import TreatmentPlanStatusSection from './TreatmentPlanStatusSection';

type Props = {
  authProvider: AuthProvider;
};

const TreatmentPlanDetails: React.FC<Props> = ({ authProvider }) => {
  const { treatmentPlanId } = useParams<{ treatmentPlanId: string }>();

  const queryClient = useQueryClient();

  const {
    isLoading: isFetchingDetailedTreatmentPlan,
    error: responseError,
    data: detailedTreatmentPlan,
    refetch,
  } = useQuery<DetailedTreatmentPlan, ErrorResponse>(
    ['getDetailedTreatmentPlan', treatmentPlanId],
    () => {
      return getDetailedTreatmentPlan({
        authProvider: authProvider,
        treatmentPlanId: treatmentPlanId || '',
      });
    }
  );
  if (!treatmentPlanId) {
    return <RootRedirect authentication={authProvider} />;
  }

  if (responseError) {
    // setAlert(errorAlert(responseError));
  }

  if (isFetchingDetailedTreatmentPlan) {
    return <LoadingSpinner />;
  }

  if (!detailedTreatmentPlan) {
    return <RootRedirect authentication={authProvider} />;
  }

  let isAllowedToSeePage = true;
  if (!authProvider.isFlossyAdmin && detailedTreatmentPlan) {
    let allowedByPractice: boolean = false;
    allowedByPractice =
      authProvider.authUser?.user.practices[0].id === detailedTreatmentPlan.practice?.id;

    const allowedByDentist = isDentistInManagedList({
      authProvider,
      dentistId: detailedTreatmentPlan.dentist?.id || '',
    });

    isAllowedToSeePage = allowedByPractice || allowedByDentist;
  }

  if (!isAllowedToSeePage) {
    return (
      <div className="flex flex-col gap-4">
        <div className="title text-3xl text-left text-secondary">
          Treatment Plan Details for {detailedTreatmentPlan?.patient.firstName}{' '}
          {detailedTreatmentPlan?.patient.lastName}
        </div>
        <div className="separator w-full my-8" />
        <Alert
          {...errorAlert(
            'You do not have permission to view this page. Please contact us if you believe this is an error.'
          )}
        />
      </div>
    );
  }

  const { patient } = detailedTreatmentPlan;

  return (
    <div id="treatment-plan-details-page" className="flex flex-col items-start">
      <TreatmentPlanHeader
        treatmentPlan={detailedTreatmentPlan}
        authProvider={authProvider}
        onNameUpdated={refetch}
      />
      <div className="text-base-content text-xl font-medium pb-4">
        {getStatusTextFromState(detailedTreatmentPlan.state)}
      </div>
      <TreatmentPlanBanner treatmentPlan={detailedTreatmentPlan} />
      <div
        id="tp-details-info-and-control-panel"
        className="bg-base-100 flex flex-row w-full divide-x divide-rule p-4"
      >
        <TreatmentPlanPatientSection treatmentPlan={detailedTreatmentPlan} />
        <TreatmentPlanStatusSection
          treatmentPlan={detailedTreatmentPlan}
          authProvider={authProvider}
        />
        <TreatmentPlanButtonSection
          treatmentPlan={detailedTreatmentPlan}
          authProvider={authProvider}
          patientId={patient.id}
        />
      </div>
      <div className="my-4 w-full">
        <PortalContainer padding="p-0">
          <TreatmentPlanItemsTable
            authentication={authProvider}
            items={detailedTreatmentPlan.items}
            patient={patient}
            treatmentPlanId={treatmentPlanId}
            queryContext={{
              client: queryClient,
              key: 'getDetailedTreatmentPlan',
            }}
          />
        </PortalContainer>
      </div>
      <TreatmentPlanNotes notes={detailedTreatmentPlan.notes} />
    </div>
  );
};

export default TreatmentPlanDetails;
