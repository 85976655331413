import {
  Booking,
  BookingCharge,
  BookingChargeState,
  LedgerMonth,
  PaymentHistoryRecord,
} from '../../../API/bookingsAndCredits';
import { Timestamp } from '../../../ServiceContext/shared';
import { MonthlyLedger } from './PastMonthsLedgerTable';

export const findBookingChargesSettledByCredits = (ledgerMonth: LedgerMonth): BookingCharge[] => {
  const { charges, credits } = ledgerMonth;

  const settledChargeIds = new Set(
    credits.items.map((creditItem) => creditItem.settlesChargeId).filter(Boolean)
  );

  return charges.items.filter((chargeItem) => settledChargeIds.has(chargeItem.id));
};

export const getRedeemedCreditsAmount = (ledgerMonth: LedgerMonth): number => {
  const chargesSettledByCredits: BookingCharge[] = findBookingChargesSettledByCredits(ledgerMonth);
  return chargesSettledByCredits.reduce((total, chargeItem) => total + chargeItem.amount, 0);
};

const isChargeNotPending = (booking: Booking): boolean => {
  const chargeState = booking.charge?.state;
  return chargeState !== BookingChargeState.PENDING;
};

const areAllChargesNotPending = (bookings: Booking[]) => {
  return bookings.every(isChargeNotPending);
};

const getRecordMonthYear = (record: PaymentHistoryRecord): string | null => {
  if (record.details?.data.type !== 'single-month') {
    return null;
  }
  const { year, month } = record.details?.data || {};
  if (year && month !== undefined) {
    const monthString = month.toString().padStart(2, '0');
    return `${year}-${monthString}`;
  }
  return null;
};

const getMatchingRecords = (
  paymentHistoryRecords: PaymentHistoryRecord[],
  monthYear: string
): PaymentHistoryRecord[] => {
  return paymentHistoryRecords.filter((record) => {
    const recordMonthYear = getRecordMonthYear(record);
    return recordMonthYear === monthYear;
  });
};

const compareRecordsByStartedProcessingAt = (
  a: PaymentHistoryRecord,
  b: PaymentHistoryRecord
): number => {
  const timestampA = a.startedProcessingAt ? new Date(a.startedProcessingAt).getTime() : 0;
  const timestampB = b.startedProcessingAt ? new Date(b.startedProcessingAt).getTime() : 0;
  return timestampB - timestampA;
};

const getLatestRecord = (records: PaymentHistoryRecord[]): PaymentHistoryRecord | undefined => {
  const sortedRecords = records.sort(compareRecordsByStartedProcessingAt);
  return sortedRecords[0];
};

export const getLedgerPaymentStatus = (
  monthlyLedger: MonthlyLedger,
  paymentHistoryRecords: PaymentHistoryRecord[]
): {
  paymentStatus: 'Payment Due' | 'Processing' | 'Paid';
  paidDate: Timestamp | null;
  relatedPayments: PaymentHistoryRecord[] | null;
} => {
  const { bookings } = monthlyLedger.ledgerMonth;

  const matchingRecords = getMatchingRecords(paymentHistoryRecords, monthlyLedger.monthYear);
  const latestRecord = getLatestRecord(matchingRecords);

  if (areAllChargesNotPending(bookings)) {
    return {
      paymentStatus: 'Paid',
      paidDate: latestRecord?.startedProcessingAt || latestRecord?.createdAt || null,
      relatedPayments: matchingRecords,
    };
  }

  if (latestRecord && latestRecord.status === 'processing') {
    return {
      paymentStatus: 'Processing',
      paidDate: null,
      relatedPayments: matchingRecords,
    };
  }

  return {
    paymentStatus: 'Payment Due',
    paidDate: null,
    relatedPayments: matchingRecords,
  };
};

export const getMonthlyLedgersByPaymentStatus = ({
  selectedStatus,
  monthlyLedgerData,
  paymentHistoryRecords,
}: {
  selectedStatus: string | null;
  monthlyLedgerData: MonthlyLedger[];
  paymentHistoryRecords: PaymentHistoryRecord[];
}): MonthlyLedger[] => {
  return monthlyLedgerData.filter((monthlyLedger) => {
    const { bookings } = monthlyLedger.ledgerMonth;
    const matchingRecords = getMatchingRecords(paymentHistoryRecords, monthlyLedger.monthYear);
    const latestRecord = getLatestRecord(matchingRecords);
    const isPaid = areAllChargesNotPending(bookings);
    const isProcessing = latestRecord && latestRecord.status === 'processing';

    switch (selectedStatus) {
      case 'Paid':
        return isPaid;
      case 'Processing':
        return isProcessing;
      case 'Payment Due':
        return !isPaid && !isProcessing;
      default:
        return true;
    }
  });
};
