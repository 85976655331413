import { PatientInsurance } from '../API/insurance';
import { ErrorResponse } from '../API/response';
import { TreatmentPlan } from '../API/treatmentPlans';
import { AuthProvider } from '../Authentication/Authentication';
import { Appointment } from './appointments';
import { PaymentMethod } from './invoices';
import { authenticatedGet, authenticatedPost } from './service';
import { apiUrl } from './shared';
import { Dentist } from './user';

export interface Patient {
  id: string;
  googleId: string;
  stripeCustomerId: string;
  username: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  birthDate: string;
  allowsPush: boolean;
  fcmTokens: string[];
  paymentMethods: PaymentMethod[] | null;
  status: string;
  suffix: string | null;
  prefix: string | null;
  gender: string | null;
  photoUrl: string | null;
  deletedEmail: string | null;
  preferredContactMethod?: 'text' | 'phone' | 'email' | null;
  hasActiveTreatmentPlan: boolean;
}

export interface PatientDirectorySearchResult {
  results: PatientDetail[];
  totalCount: number;
}

export interface PatientDetail {
  id: string;
  firstName: string;
  lastName: string;
  joinDate: string;
  birthDate: string;
  phone: string;
  email: string;
  appointments: Appointment[];
  lastDentist?: Dentist;
  paymentMethods: PaymentMethod[] | null;
  insuranceInformation: PatientInsurance[] | null;
  treatmentPlans: TreatmentPlan[];
}

export interface StripeCardSetupIntent {
  id: string;
  clientSecret: string;
}

export interface PatientsService {
  getPatientById: (patientId: string) => Promise<PatientDetail | ErrorResponse>;
  createCardSetupIntent: (patientId: string) => Promise<StripeCardSetupIntent | ErrorResponse>;
  addPaymentMethod: (
    patientId: string,
    newPaymentMethod: PaymentMethod
  ) => Promise<Patient | ErrorResponse>;
}

const getPatientById = (authProvider: AuthProvider) => {
  return async (patientId: string) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedGet<PatientDetail>(authProvider, apiUrl(`/dentists/patients/${patientId}`));
  };
};

const createCardSetupIntent = (authProvider: AuthProvider) => {
  return async (patientId: string) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedPost<StripeCardSetupIntent | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/patients/${patientId}/setupIntents`),
      {}
    );
  };
};

const addPaymentMethod = (authProvider: AuthProvider) => {
  return async (patientId: string, newPaymentMethod: PaymentMethod) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedPost<Patient | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/patients/${patientId}/paymentMethods`),
      newPaymentMethod
    );
  };
};

const makeService = (auth: AuthProvider) => {
  return {
    getPatientById: getPatientById(auth),
    createCardSetupIntent: createCardSetupIntent(auth),
    addPaymentMethod: addPaymentMethod(auth),
  };
};

export default makeService;
