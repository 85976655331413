import { TreatmentPlanItem } from '../API/treatmentPlans';
import { Invoice, InvoiceItem } from '../ServiceContext/invoices';
import { isNumber } from './math/math';

export enum PricingSystemVersion {
  // What we call the "old" pricing system
  V1 = 'V1',

  // What we call the "new" pricing system
  V2 = 'V2',
}

export function inferPricingSystemVersionFromInvoice(invoice: Invoice): PricingSystemVersion {
  // Explicitly stating: if any item in the invoice is V1, the whole invoice is V1. Every item must conform to V2 in order
  // for the invoice to be considered V2.
  const procedureItems = invoice.invoiceItems.filter((i) => i.type === 'procedure');
  for (let i = 0; i < procedureItems.length; i++) {
    const item = procedureItems[i];

    const version = inferPricingSystemVersionFromInvoiceItem(item);
    if (version === PricingSystemVersion.V1) {
      return PricingSystemVersion.V1;
    }
  }

  return PricingSystemVersion.V2;
}

export function inferPricingSystemVersionFromInvoiceItem(i: InvoiceItem): PricingSystemVersion {
  if (isNumber(i.anchorPrice) && isNumber(i.patientPrice)) {
    return PricingSystemVersion.V2;
  }

  return PricingSystemVersion.V1;
}

export function inferPricingSystemVersionFromTreatmentPlanItem(
  i: TreatmentPlanItem
): PricingSystemVersion {
  if (isNumber(i.anchorPrice) && isNumber(i.patientPrice)) {
    return PricingSystemVersion.V2;
  }

  return PricingSystemVersion.V1;
}

export function inferPricingSystemVersionFromTreatmentPlan(
  items: TreatmentPlanItem[]
): PricingSystemVersion {
  // Explicitly stating: if any item in the plan is V1, the whole plan is V1. Every item must conform to V2 in order
  // for the plan to be considered V2.
  for (let i = 0; i < items.length; i++) {
    const item = items[i];

    const version = inferPricingSystemVersionFromTreatmentPlanItem(item);
    if (version === PricingSystemVersion.V1) {
      return PricingSystemVersion.V1;
    }
  }

  return PricingSystemVersion.V2;
}
