import React, { RefObject, useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';

type PrintDropdownButtonOption = {
  id: string;
  label: string;
  onBeforePrint: () => void;
  ref: RefObject<HTMLDivElement>;
};

type PrintDropdownButtonProps = {
  label: string;
  options: PrintDropdownButtonOption[];
};

const PrintDropdownButton: React.FC<PrintDropdownButtonProps> = ({ label, options }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div className="inline-flex items-center">
      <div className="relative text-left" ref={dropdownRef}>
        <div>
          <button
            type="button"
            className="inline-flex justify-between items-center w-full p-2.5 text-sm font-medium shadow-md hover:bg-[#F6EAE0] text-base-content-flossy bg-[#f6e4e0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-base-200 focus:ring-[#d47766] rounded-3xl"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <span>{label}</span>
            <svg
              className="h-3 w-3 text-base-content ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
        </div>

        {showDropdown && (
          <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-primary ring-opacity-5 z-50">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map((option, index) => (
                <ReactToPrint
                  key={index}
                  onBeforePrint={() => {
                    setShowDropdown(false);
                    option.onBeforePrint();
                  }}
                  trigger={() => {
                    return (
                      <button
                        id={`${option.id}-print-button`}
                        aria-label={`${option.id}-print-button`}
                        className="block w-full text-left px-4 py-2 text-sm text-base-content hover:bg-base-100"
                        onClick={() => {
                          // This gets overridden by the ReactToPrint component.
                        }}
                      >
                        {option.label}
                      </button>
                    );
                  }}
                  content={() => option.ref.current}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrintDropdownButton;
