import { AuthProvider } from '../../../Authentication/Authentication';
import PortalContainer from '../../../shared/Container/PortalContainer';
import { PortalHeader } from '../../../shared/Text/PortalHeaders';
import CreditCards from './CreditCards';
import InsuranceInformation from './InsuranceInformation';

export default function PaymentMethods({ authProvider }: { authProvider: AuthProvider }) {
  return (
    <div className="w-full flex flex-col items-start gap-3">
      <PortalHeader>Payment Methods</PortalHeader>
      <PortalContainer>
        <div className="flex gap-4">
          <CreditCards authProvider={authProvider} />
          <div className="border-r border-rule"></div>
          <InsuranceInformation authProvider={authProvider} />
        </div>
      </PortalContainer>
    </div>
  );
}
