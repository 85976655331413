import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wizard, WizardValues } from 'react-use-wizard';

import { ConsolidatedPricingTable } from '../../API/dentist';
import { AuthProvider } from '../../Authentication/Authentication';
import { Appointment } from '../../ServiceContext/appointments';
import { reportErrorToSentry } from '../../ServiceContext/error';
import { Invoice, isLegacyPayment, isSplitPayment, Payment } from '../../ServiceContext/invoices';
import { Patient } from '../../ServiceContext/patients';
import { Dentist } from '../../ServiceContext/user';
import Alert, { warningAlert } from '../../shared/Alert';
import CreateOrEditInvoiceStep from './CreateOrEditInvoiceStep';
import PaymentComplete from './PaymentComplete/PaymentComplete';
import ReviewInvoiceStep from './ReviewInvoiceStep';

type Props = {
  authProvider: AuthProvider;
  appointment: Appointment;
  patient: Patient;
  dentist: Dentist;
  consolidatedPricingTable: ConsolidatedPricingTable;
  refetchAppointment: () => void;
};

const InvoicingWizard: React.FC<Props> = ({
  authProvider,
  appointment: propsAppointment,
  patient,
  dentist,
  consolidatedPricingTable,
  refetchAppointment,
}) => {
  const navigate = useNavigate();
  const authedUser = authProvider.authUser;
  const authedUserName =
    authedUser && authedUser.user ? `${authedUser.user.firstName} ${authedUser.user.lastName}` : '';

  let initialPayment: Payment | null = null;
  if (isLegacyPayment(propsAppointment.payment) || isSplitPayment(propsAppointment.payment)) {
    initialPayment = propsAppointment.payment;
  }

  let initialInvoice: Invoice | null = propsAppointment.invoice || null;

  const [appointment, setAppointment] = useState<Appointment>(propsAppointment);
  const [processorName, setProcessorName] = useState<string>(authedUserName);
  const [invoice, setInvoice] = useState<Invoice | null>(initialInvoice);
  const [payment, setPayment] = useState<Payment | null>(initialPayment);

  const goToCreateInvoice = useCallback((w: WizardValues) => w.goToStep(0), []);
  const goToReviewInvoice = useCallback((w: WizardValues) => w.goToStep(1), []);
  const goToPaymentComplete = useCallback((w: WizardValues) => w.goToStep(2), []);

  const returnToAppointments = () => {
    navigate('/appointments');
  };

  // If the pricing system is not set up for this dentist, they cannot invoice.
  let blockForPricingSystemSetup = !dentist.usesNewPricingSystem;

  let initialStep = 0;
  if (payment) {
    if (payment.status === 'processed') {
      initialStep = 2;

      // Even without the pricing system set up, completed invoices are visible.
      // Without this, legacy invoices would not be visible to dentists who used Flossy
      // before we updated the pricing system.
      blockForPricingSystemSetup = false;
    } else if (payment.status === 'denied' || payment.status === 'partially_processed') {
      initialStep = 1;
    }
  } else if (invoice && invoice.isDraft) {
    initialStep = 1;
  }

  if (blockForPricingSystemSetup) {
    reportErrorToSentry(
      `Dentist with id ${dentist.id} has entered invoicing flow without being set up to use the new pricing system.`
    );

    return (
      <Alert
        {...warningAlert(
          'This dentist is not set up to invoice patients. Please contact a Flossy representative for further details and to get set up.'
        )}
      />
    );
  }

  return (
    <Wizard startIndex={initialStep}>
      <CreateOrEditInvoiceStep
        authProvider={authProvider}
        appointment={appointment}
        invoice={invoice}
        patient={patient}
        dentist={dentist}
        consolidatedPricingTable={consolidatedPricingTable}
        processorName={processorName}
        onProcessorNameChange={setProcessorName}
        goToReviewInvoice={goToReviewInvoice}
        onCancel={returnToAppointments}
        onDraftInvoiceCreatedOrUpdated={setInvoice}
      />
      <ReviewInvoiceStep
        authProvider={authProvider}
        appointment={appointment}
        patient={patient}
        dentist={dentist}
        invoice={invoice}
        processorName={processorName}
        onAppointmentUpdated={setAppointment}
        onPaymentUpdated={setPayment}
        goToCreateInvoice={goToCreateInvoice}
        goToPaymentComplete={goToPaymentComplete}
        refetchAppointment={refetchAppointment}
        onInvoiceUpdated={setInvoice}
      />
      <PaymentComplete
        authProvider={authProvider}
        appointment={appointment}
        invoice={invoice}
        payment={payment}
      />
    </Wizard>
  );
};

export default InvoicingWizard;
