import React from 'react';

import {
  BirthdayIcon,
  ClockIcon,
  EmailIcon,
  MapIcon,
  PhoneIcon,
  Tooth,
} from '../../HomePage/components/Icons';
import { Appointment } from '../../ServiceContext/appointments';
import { toShortDateString, toShortDateTimeString } from '../../shared/dates/dates';
import { formatPhoneNumber } from '../../shared/strings';

type Props = {
  appointment: Appointment;
  hideAppointmentsDetails?: boolean;
};
const AppointmentAndPatientDetails: React.FC<Props> = ({
  appointment,
  hideAppointmentsDetails,
}) => {
  const renderAppointmentDetails = () => {
    return (
      <div id={'appointment-details'} className="flex flex-col gap-2 w-[80%]">
        <span className="font-semibold mb-2">Appointment Details</span>
        <div className={'flex flex-row gap-2'}>
          <ClockIcon />{' '}
          <span className={'text-xs'}>
            {toShortDateTimeString(appointment.start, appointment.timeZone)}
          </span>
        </div>
        <div className={'flex flex-row gap-2'}>
          <Tooth />
          <span className={'text-xs'}>
            {appointment.dentist.firstName} {appointment.dentist.lastName}
          </span>
        </div>
        <div className={'flex flex-row gap-2'}>
          <MapIcon />
          <span className={'text-xs'}>
            {appointment.location.address1} {appointment.location.address2},{' '}
            {appointment.location.city}, {appointment.location.state}, {appointment.location.zip}
          </span>
        </div>
        <span className={'text-xs'}>
          <span className={'font-bold'}>Patient Notes: </span>
          {appointment.notes}
        </span>
      </div>
    );
  };

  const renderPatientDetails = () => {
    return (
      <div id={'patient-details'} className="flex flex-col gap-3 w-[80%]">
        <span className="font-semibold mb-1">Patient Information</span>
        <div className={'flex flex-col gap-2'}>
          <span className={'font-bold text-xs'}>Birthdate</span>
          <div className={'flex flex-row gap-2'}>
            <BirthdayIcon />
            {appointment.user?.birthDate && (
              <span className={'text-xs'}>{toShortDateString(appointment.user?.birthDate)}</span>
            )}
          </div>
        </div>
        <div className={'flex flex-col gap-2'}>
          <span className={'font-bold text-xs'}>Contact Information</span>
          <div className={'flex flex-row gap-2'}>
            <EmailIcon />
            <span className={'text-xs'}>{appointment.user?.email}</span>
          </div>
          <div className={'flex flex-row gap-2'}>
            <PhoneIcon />
            <span className={'text-xs'}>{formatPhoneNumber(appointment.user?.phone)}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id={'details'} className={'flex flex-col gap-5 ml-6 mb-6 text-left w-[80%]'}>
      {!hideAppointmentsDetails && renderAppointmentDetails()}
      {renderPatientDetails()}
    </div>
  );
};

export default AppointmentAndPatientDetails;
