import { AuthProvider } from '../../Authentication/Authentication';

/**
 * Custom hook to get the list of managed dentists from the provided authProvider.
 *
 * @param {AuthProvider} authProvider - The authentication provider.
 * @returns {Array} The list of managed dentists.
 */
export function useManagedDentists(authProvider: AuthProvider | null) {
  return authProvider?.managedDentists || [];
}
