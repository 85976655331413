import { PropsWithChildren, useEffect } from 'react';

type Props = {
  onClick: (e: MouseEvent) => void;
};
const WithDocumentClickListener: React.FC<PropsWithChildren<Props>> = ({ onClick, children }) => {
  useEffect(() => {
    document.addEventListener('click', onClick);

    return () => {
      document.removeEventListener('click', onClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default WithDocumentClickListener;
