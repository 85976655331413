import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppointmentWorkflow, BookingWorkflow, InvoicingWorkflow } from '../../../amplitude';
import { Address } from '../../../ServiceContext/shared';
import { ReactComponent as LocationIcon } from '../../../shared/assets/location.svg';
import { ReactComponent as TimeIcon } from '../../../shared/assets/time.svg';
import { ReactComponent as ToothIcon } from '../../../shared/assets/tooth.svg';
import Button from '../../../shared/Button/Button';
import { toShortDateTimeString } from '../../../shared/dates/dates';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { getColorFromStatus, getStatusFromAppointment } from '../../AppointmentUtils';
import { AppointmentContext } from '../contexts/appointmentContext';
import { useAcknowledgeAppointmentMutation } from '../hooks/useAcknowledgeAppointmentMutation';
import AcknowledgeButton from './AcknowledgeButton';
import RescheduleButton from './RescheduleButton';

export default function DetailsTile() {
  var navigate = useNavigate();

  const appointmentContext = useContext(AppointmentContext);
  const appointment = appointmentContext?.appointment;

  const { acknowledgeAppointment, isLoading: acknowledgingAppointment } =
    useAcknowledgeAppointmentMutation();

  if (!appointment || !appointment.user || !appointment.dentist) {
    return <LoadingSpinner />;
  }

  const status = getStatusFromAppointment(appointment);
  const isCancelled = status === 'Cancelled';
  const isCompleted = status === 'Completed';
  const isUnprocessed = status === 'Unprocessed';
  const isScheduled = status === 'Scheduled';

  let dentistLocation: Address | null = null;
  if (appointment.dentist.locations && appointment.dentist.locations.length > 0) {
    dentistLocation = appointment.dentist.locations[0];
  }

  return (
    <div className="bg-white rounded-lg w-full p-8 items-start text-secondary">
      <div className="flex flex-row justify-between pb-4">
        <div className="flex items-center space-x-3">
          <div
            className={`rounded-2xl font-semibold text-white text-xs px-2 py-1 ${getColorFromStatus(
              appointment
            )}`}
          >
            {status}
          </div>
          <div className="text-xl font-bold">
            {appointment.user.firstName} {appointment.user.lastName}
          </div>
        </div>
        <div className="flex gap-2">
          <AcknowledgeButton
            appointment={appointment}
            onMarkAppointmentAcknowledged={acknowledgeAppointment}
            disabled={acknowledgingAppointment}
            isLoading={acknowledgingAppointment}
          />
          {(isUnprocessed || isScheduled) && (
            <Button
              id={'create-invoice'}
              color="tertiary"
              onClick={() => navigate(`/appointments/${appointment.id}/invoicing`)}
              workflow={InvoicingWorkflow}
              trackingLabel="Create Invoice Button"
            >
              Create Invoice
            </Button>
          )}
          {!isCompleted && <RescheduleButton appointment={appointment} />}
          {isCompleted && (
            <Button
              id={'schedule-next-appointment'}
              className={`rounded-full hover:opacity-75 text-white font-medium text-sm px-3 py-2 bg-tertiary`}
              onClick={() => navigate(`/appointments/${appointment.id}/book-follow-up`)}
              workflow={BookingWorkflow}
              trackingLabel="Schedule Next Appointment Button"
            >
              Schedule Next Appointment
            </Button>
          )}
          {!isCancelled && !isCompleted && (
            <Button
              id={'cancel-button'}
              onClick={() => {
                navigate(`/appointments/${appointment.id}/cancel`);
              }}
              workflow={AppointmentWorkflow}
              noFill
              trackingLabel="Cancel Appointment Button"
            >
              Cancel
            </Button>
          )}
          {isCompleted && (
            <Button
              id={'view-invoice-button'}
              onClick={() => {
                navigate(`/appointments/${appointment.id}/invoicing`);
              }}
              workflow={InvoicingWorkflow}
              trackingLabel="View Invoice Button"
            >
              View Invoice
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-row justify-start">
        <div className="space-y-2 items-start flex flex-col text-sm w-1/3">
          <div className="flex flex-row space-x-1 items-center">
            <TimeIcon height={12} />
            <div>{toShortDateTimeString(appointment.start, appointment.timeZone)}</div>
          </div>
          <div className="flex flex-row space-x-1 items-center">
            <ToothIcon height={12} />
            <div>
              {appointment.dentist.firstName} {appointment.dentist.lastName}
            </div>
          </div>
          <div className="flex flex-row space-x-1 items-center">
            <LocationIcon height={12} />
            {dentistLocation ? (
              <div className="flex text-start">
                {dentistLocation.address1} {dentistLocation.city}, {dentistLocation.state}{' '}
                {dentistLocation.zip}
              </div>
            ) : (
              <div>Location not available</div>
            )}
          </div>
        </div>
        <div className="space-y-2 items-start flex flex-col text-sm w-1/3">
          <div>
            <span className="font-semibold">Reason: </span>
            {appointment.appointmentReason}
          </div>
          <div>
            <span className="font-semibold">Preferred Contact Method: </span>
            {appointment.user.preferredContactMethod}
          </div>
          <div className="flex-col items-start flex">
            <span className="font-semibold">Flossy Promo Code: </span>
            {appointment.promoCode || 'N/A'}
          </div>
        </div>
        <div className="space-y-2 items-start flex flex-col text-sm w-1/3">
          <div>
            <span className="font-semibold">Patient Notes: </span>
            {appointment.notes}
          </div>
        </div>
      </div>
    </div>
  );
}
