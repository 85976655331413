import { AuthProvider } from '../Authentication/Authentication';
import { authenticatedGet, authenticatedPost, authenticatedPut } from '../ServiceContext/service';
import { apiUrl, Timestamp } from '../ServiceContext/shared';
import { ErrorResponse, handleAPIResponse } from './response';

export enum ReferralStatus {
  OPEN = 'open',
  USED = 'used',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export type PatientReferral = {
  id: string;
  practiceId: string;
  firstName: string | null;
  lastName: string | null;
  requestEmail: string;
  phoneNumber: string | null;
  referralCode: string;
  status: ReferralStatus;
  resultingPatientId: string;
  usedAt: Timestamp | null;
  credit: PatientReferralCredit | null;
  createdAt: Timestamp;
};

export type PatientReferralStats = {
  currentMonth: {
    sentCount: number;
    redeemedCount: number;
    creditsEarned: number;
  };
  cumulative: {
    sentCount: number;
    redeemedCount: number;
    creditsEarned: number;
  };
};

export enum ReferralCreditStatus {
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  VOID = 'void',
  APPLIED = 'applied',
  CONSUMED = 'consumed',
}

export type PatientReferralCredit = {
  id: string;
  practiceId: string;
  appointmentId: string;
  patientReferralId: string;
  status: ReferralCreditStatus;
  amount: number;
  updateReason: string | null;
  appliesAt: Timestamp;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export async function getPatientReferrals({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
}): Promise<PatientReferral[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  if (authUser.user.isFlossyAdmin) {
    return handleAPIResponse(
      authenticatedGet<PatientReferral[]>(
        authProvider,
        apiUrl(`/managers/${authUser.user.id}/referrals`)
      )
    );
  }

  return handleAPIResponse(
    authenticatedGet<PatientReferral[]>(authProvider, apiUrl(`/practices/${practiceId}/referrals`))
  );
}

export async function getPatientReferralStats({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
}): Promise<PatientReferralStats> {
  return await handleAPIResponse(
    authenticatedGet<PatientReferralStats>(
      authProvider,
      apiUrl(`/practices/${practiceId}/patient-referral-stats`)
    )
  );
}

export async function referPatient({
  authProvider,
  practiceId,
  firstName,
  lastName,
  email,
  phoneNumber,
}: {
  authProvider: AuthProvider;
  practiceId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
}): Promise<PatientReferral> {
  return await handleAPIResponse(
    authenticatedPost<PatientReferral | ErrorResponse>(
      authProvider,
      apiUrl(`/practices/${practiceId}/referrals`),
      {
        firstName: firstName,
        lastName: lastName,
        requestEmail: email,
        phoneNumber: phoneNumber,
      }
    )
  );
}

export async function updateReferral({
  authProvider,
  practiceId,
  referralId,
  newEmail,
}: {
  authProvider: AuthProvider;
  practiceId: string;
  referralId: string;
  newEmail: string;
}): Promise<PatientReferral> {
  return await handleAPIResponse(
    authenticatedPut<PatientReferral | ErrorResponse>(
      authProvider,
      apiUrl(`/practices/${practiceId}/referrals/${referralId}`),
      {
        email: newEmail,
      }
    )
  );
}
