import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useMemo, useState } from 'react';
import Modal from 'react-modal';

import { InvoicingWorkflow, Tracking } from '../../amplitude';
import { ErrorResponse, isErrorResponse, isStripeError } from '../../API/response';
import { Appointment, AppointmentsService } from '../../ServiceContext/appointments';
import { reportErrorToSentry } from '../../ServiceContext/error';
import { PatientsService, StripeCardSetupIntent } from '../../ServiceContext/patients';
import Alert, { errorAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import useLockBodyScroll from '../../shared/Modal/useLockBodyScroll';
import { createPaymentMethodModel } from '../../shared/paymentMethods';
import CloseIcon from './CloseIcon';

type Props = {
  appointmentId: string;
  patientId: string;
  patientName: string;

  onCancel: () => void;
  onNewPaymentCardAddedToAppointment: (a: Appointment) => void;

  appointmentsService: AppointmentsService;
  patientsService: PatientsService;
} & Tracking;

const AddNewPaymentCardModal: React.FC<Props> = ({
  appointmentId,
  patientId,
  patientName,
  onCancel,
  onNewPaymentCardAddedToAppointment,
  appointmentsService,
  patientsService,
  ...tracking
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [numberComplete, setNumberComplete] = useState(false);
  const [expiryComplete, setExpiryComplete] = useState(false);
  const [cvcComplete, setCvcComplete] = useState(false);

  const [numberError, setNumberError] = useState('');
  const [expiryError, setExpiryError] = useState('');
  const [cvcError, setCvcError] = useState('');
  const [isSettingUpNewPaymentMethod, setIsSettingUpNewPaymentMethod] = useState(false);

  useLockBodyScroll(true);

  const stripeCardElementStyle = useMemo(
    () => ({
      base: {
        fontSize: '16px',
        color: '#495057',
        fontSmoothing: 'antialiased',
        fontFamily:
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        '::placeholder': {
          color: '#868e96',
        },
        backgroundColor: '#ffffff',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    }),
    []
  );

  const onAddPaymentMethodClicked = async () => {
    if (!elements || !stripe) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      setErrorMessage('Failed to create stripe element');
      return;
    }
    setIsSettingUpNewPaymentMethod(true);
    const createPaymentMethodRes = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: patientName,
      },
    });

    const createPaymentErr = createPaymentMethodRes.error;
    if (isStripeError(createPaymentErr)) {
      setErrorMessage(createPaymentErr.message);
      setIsSettingUpNewPaymentMethod(false);
      reportErrorToSentry(createPaymentErr);
      return;
    }
    if (!createPaymentMethodRes.paymentMethod || !createPaymentMethodRes.paymentMethod.card) {
      setErrorMessage('Error occurred creating your new payment method. Please try another card.');
      setIsSettingUpNewPaymentMethod(false);
      return;
    }
    let cardSetupIntentRes: StripeCardSetupIntent | ErrorResponse =
      await patientsService.createCardSetupIntent(patientId);
    if (isErrorResponse(cardSetupIntentRes)) {
      setErrorMessage(
        'Error occurred setting up a session for your new payment method. Please try another card.'
      );
      setIsSettingUpNewPaymentMethod(false);
      return;
    }
    const cardSetupRes = await stripe.confirmCardSetup(cardSetupIntentRes.clientSecret, {
      payment_method: createPaymentMethodRes.paymentMethod.id,
    });
    const cardSetupErr = cardSetupRes.error;
    if (isStripeError(cardSetupErr)) {
      setErrorMessage(cardSetupErr.message);
      setIsSettingUpNewPaymentMethod(false);
      reportErrorToSentry(cardSetupErr);
      return;
    }
    if (!cardSetupRes.setupIntent && cardSetupRes.error) {
      setErrorMessage('Card declined. Please try another card.');
      setIsSettingUpNewPaymentMethod(false);
      reportErrorToSentry(`Unrecognized error that was reported as card declined: ${cardSetupErr}`);
      return;
    }

    const flossyPaymentMethod = createPaymentMethodModel({
      stripePaymentMethodId: createPaymentMethodRes.paymentMethod.id,
      type: `cc.${createPaymentMethodRes.paymentMethod.card.brand}`,
      cardBrand: createPaymentMethodRes.paymentMethod.card.brand,
      cardLast4: createPaymentMethodRes.paymentMethod.card.last4,
      stripeExpirationMonth: createPaymentMethodRes.paymentMethod.card.exp_month,
      stripeExpirationYear: createPaymentMethodRes.paymentMethod.card.exp_year,
    });
    let registerPaymentMethodRes = await patientsService.addPaymentMethod(
      patientId,
      flossyPaymentMethod
    );
    if (isErrorResponse(registerPaymentMethodRes)) {
      setErrorMessage(
        'Error occurred registering your new payment method. Please try another card.'
      );
      setIsSettingUpNewPaymentMethod(false);
      return;
    }

    let flossyAssociationRes: Appointment | ErrorResponse =
      await appointmentsService.associatePaymentMethod(flossyPaymentMethod, appointmentId);

    if (isErrorResponse(flossyAssociationRes)) {
      setErrorMessage(
        'Error occurred associating new payment method with appointment, but card was successfully added. You may have to select your payment method in the app to continue.'
      );
      setIsSettingUpNewPaymentMethod(false);
      return;
    }

    setIsSettingUpNewPaymentMethod(false);
    onNewPaymentCardAddedToAppointment(flossyAssociationRes);
  };

  return (
    <Modal
      isOpen
      onRequestClose={onCancel}
      contentLabel="Add New Payment Card"
      className="outline-none mx-auto my-10 bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 max-h-4/5 overflow-auto text-secondary"
      overlayClassName="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
    >
      <div className="flex justify-between items-center border-b p-4">
        <h3 className="font-bold">Add New Payment Card</h3>
        <Button
          onClick={onCancel}
          className="focus:outline-none transition-all duration-200 ease-in-out transform hover:scale-105"
          workflow={InvoicingWorkflow}
          trackingLabel="Close Add New Payment Card Modal Button"
          context="addNewPaymentCardModal"
        >
          <CloseIcon strokeWidth={2} color="hover:text-base-content text-base-content" />
        </Button>
      </div>
      <div id="add-new-credit-card-form" className="p-4">
        {errorMessage && <Alert {...errorAlert(errorMessage)} />}
        <div className="body">
          <div id="card-number-element-container">
            <div
              className="card-data-field rounded-md mb-2 mt-3 border-2 border-rule p-2 text-2xl"
              aria-label="card number"
            >
              <CardNumberElement
                options={{
                  placeholder: 'Card',
                  style: stripeCardElementStyle,
                  disabled: isSettingUpNewPaymentMethod,
                }}
                onChange={(event) => {
                  setNumberComplete(event.complete);
                  setNumberError(event.error ? event.error.message : '');
                }}
              />
            </div>
            <div
              className="card-data-field expiry rounded-md mb-2 border-2 border-rule p-2"
              aria-label="card expiry"
            >
              <CardExpiryElement
                options={{
                  placeholder: 'Expiration',
                  style: stripeCardElementStyle,
                  disabled: isSettingUpNewPaymentMethod,
                }}
                onChange={(event) => {
                  setExpiryComplete(event.complete);
                  setExpiryError(event.error ? event.error.message : '');
                }}
              />
            </div>
            <div
              className="card-data-field cvc rounded-md mb-2 border-2 border-rule p-2"
              aria-label="card CVC"
            >
              <CardCvcElement
                options={{
                  placeholder: 'CVC',
                  style: stripeCardElementStyle,
                  disabled: isSettingUpNewPaymentMethod,
                }}
                onChange={(event) => {
                  setCvcComplete(event.complete);
                  setCvcError(event.error ? event.error.message : '');
                }}
              />
            </div>
          </div>
          <Button
            id="add-payment-method-button"
            className="add-payment-method-button mt-4"
            onClick={onAddPaymentMethodClicked}
            loading={isSettingUpNewPaymentMethod}
            disabled={
              !numberComplete ||
              !expiryComplete ||
              !cvcComplete ||
              numberError ||
              expiryError ||
              cvcError
            }
            omitBorder
            trackingLabel="Add Payment Card Button"
            {...tracking}
          >
            Add Payment Card
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNewPaymentCardModal;
