import React, { useEffect, useState } from 'react';

import { isErrorResponse } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import { ManagerNotificationSetupExplanation } from '../../ServiceContext/managers';
import { useServices } from '../../ServiceContext/ServiceContext';
import Alert, { AlertData, errorAlert } from '../../shared/Alert';
import ExplainManagerNotificationsSetup from './ExplainManagerNotificationsSetup';

type Props = {
  authentication: AuthProvider;
};

const NotificationsExplain: React.FC<Props> = ({ authentication }) => {
  const services = useServices();

  const [alert, setAlert] = useState<AlertData | null>(null);
  const [managerExplanation, setManagerExplanation] =
    useState<ManagerNotificationSetupExplanation | null>(null);

  useEffect(() => {
    if (!authentication.authUser) {
      return;
    }
    const { authUser } = authentication;
    (async () => {
      const res = await services.managersService.getNotificationSetupExplanation({
        managerId: authUser.user.id,
      });
      if (isErrorResponse(res)) {
        setAlert(errorAlert(res.errorResponse));
        return;
      }
      setManagerExplanation(res);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication.authUser]);

  return (
    <div className="notifications-explain">
      {alert && <Alert {...alert} />}
      {managerExplanation && <ExplainManagerNotificationsSetup explanation={managerExplanation} />}
    </div>
  );
};

export default NotificationsExplain;
