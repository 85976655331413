import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BookingsAndCreditsWorkflow } from '../../../amplitude';
import { LedgerMonth, PaymentHistoryRecord } from '../../../API/bookingsAndCredits';
import { AuthProvider } from '../../../Authentication/Authentication';
import { Timestamp } from '../../../ServiceContext/shared';
import Button from '../../../shared/Button/Button';
import { toShortDateString } from '../../../shared/dates/dates';
import { stringifyMoney } from '../../../shared/money/stringifyMoney';
import { useURLQuery } from '../../../shared/routing/routing';
import BalancePaymentSuccessModal from '../BalancePaymentSuccessModal';
import { parseBookingPeriodKey } from '../BookingPeriod';
import { MonthlyLedger } from './PastMonthsLedgerTable';
import PayBalanceModal from './PayBalanceModal';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  selectedMonthlyLedger: MonthlyLedger;
  ledgerMonth: LedgerMonth;
  selectedLedgerPaymentStatus: {
    paymentStatus: 'Payment Due' | 'Processing' | 'Paid';
    paidDate: Timestamp | null;
    relatedPayments: PaymentHistoryRecord[] | null;
  };
};

const PastMonthBalanceSummary: React.FC<Props> = ({
  authProvider,
  selectedMonthlyLedger,
  ledgerMonth,
  practiceId,
  selectedLedgerPaymentStatus,
}) => {
  const { query, clearQuery } = useURLQuery();
  const amount = parseInt(query.get('amount') || '');
  const balanceAsOfDate = query.get('balanceAsOfDate');

  const [showPayBalanceModal, setShowPayBalanceModal] = useState(false);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(
    Boolean(amount && balanceAsOfDate)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (amount && balanceAsOfDate) {
      setShowPaymentSuccessModal(true);
    }
  }, [amount, balanceAsOfDate]);

  const { balance } = ledgerMonth;
  const { relatedPayments } = selectedLedgerPaymentStatus;

  let displayAmount: number = balance;
  if (selectedLedgerPaymentStatus.paymentStatus === 'Paid' && relatedPayments) {
    displayAmount = relatedPayments.reduce((acc, payment) => acc + payment.amount, 0);
  }

  const bookingPeriodToDisplay = parseBookingPeriodKey(selectedMonthlyLedger.monthYear).displayForm;

  const HorizontalSeparator = () => <div className="h-px bg-rule my-4 w-[80%] translate-x-2"></div>;

  let statusBackgroundColor = '';
  if (selectedLedgerPaymentStatus.paymentStatus === 'Payment Due') {
    statusBackgroundColor = 'bg-error-content';
  } else if (selectedLedgerPaymentStatus.paymentStatus === 'Paid') {
    statusBackgroundColor = 'bg-primary';
  } else if (selectedLedgerPaymentStatus.paymentStatus === 'Processing') {
    statusBackgroundColor = 'bg-secondary-content';
  }

  return (
    <div
      id={'past-month-balance-summary'}
      className={'flex flex-col gap-1 text-left bg-white rounded-md px-6 py-4 relative'}
    >
      <div className={'flex flex-row gap-3 items-start'}>
        <span className={'text-lg font-bold mb-2'}>{bookingPeriodToDisplay}</span>
        <span
          className={`py-1 px-2.5 rounded-2xl text-white text-xs font-semibold ${statusBackgroundColor}`}
        >
          {selectedLedgerPaymentStatus.paymentStatus}
        </span>
      </div>
      {Boolean(displayAmount) && selectedLedgerPaymentStatus.paymentStatus === 'Payment Due' && (
        <span className={'text-xs'}>You have an outstanding balance for this month.</span>
      )}
      <span id={'balance'} className={'text-2xl font-bold'}>
        {stringifyMoney(displayAmount, { includeCommas: true })}
      </span>
      <HorizontalSeparator />
      {selectedLedgerPaymentStatus.paymentStatus === 'Processing' && (
        <span className={'text-xs'}>
          <span className={'font-semibold'}>Payment Status: </span>
          {selectedLedgerPaymentStatus.paymentStatus}
        </span>
      )}
      {selectedLedgerPaymentStatus.paymentStatus === 'Paid' &&
        Boolean(selectedLedgerPaymentStatus.paidDate) && (
          <span className={'text-xs'}>
            Paid on {toShortDateString(selectedLedgerPaymentStatus.paidDate || '')}
          </span>
        )}
      {Boolean(displayAmount) && selectedLedgerPaymentStatus.paymentStatus === 'Payment Due' && (
        <div id={'action-buttons'} className={'flex flex-row gap-2 mt-6'}>
          <Button
            onClick={() => setShowPayBalanceModal(true)}
            id={'pay-balance-button'}
            className={`px-4 border-0 hover:opacity-75`}
            trackingLabel="Pay Balance Button"
            workflow={BookingsAndCreditsWorkflow}
          >
            Pay Balance
          </Button>
          <Button
            className={`justify-center`}
            onClick={() =>
              navigate(
                `/accounting/bookings-and-credits/${practiceId}/share?period=${selectedMonthlyLedger.monthYear}`
              )
            }
            color="tertiary"
            trackingLabel="Share Invoice Button"
            workflow={BookingsAndCreditsWorkflow}
          >
            Share Invoice
          </Button>
          {showPayBalanceModal && (
            <PayBalanceModal
              authProvider={authProvider}
              practiceId={practiceId}
              bookingPeriodToDisplay={bookingPeriodToDisplay}
              balanceToDisplay={stringifyMoney(balance, { includeCommas: true })}
              selectedMonthlyLedger={selectedMonthlyLedger}
              onCancel={() => setShowPayBalanceModal(false)}
              workflow={BookingsAndCreditsWorkflow}
            />
          )}
          {showPaymentSuccessModal && balanceAsOfDate && (
            <BalancePaymentSuccessModal
              amount={amount}
              balanceAsOfDate={balanceAsOfDate}
              workflow={BookingsAndCreditsWorkflow}
              onDone={() => {
                clearQuery();
                setShowPaymentSuccessModal(false);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PastMonthBalanceSummary;
