import { BookingWorkflow, ClickTrackingAction, runFTrack } from '../../amplitude';
import { RemoteImages } from '../remoteImages';
import { FirebaseNotificationData } from './notificationDataTypes';

export async function requestPermission(): Promise<boolean> {
  if (!('Notification' in window)) {
    return Promise.resolve(false);
  }

  const permission = await Notification.requestPermission();
  return permission === 'granted';
}

export function getNotificationPermission(): NotificationPermission | null {
  if (!('Notification' in window)) {
    return null;
  }

  return Notification.permission;
}

export function createNotification({
  data,
  navigateToAppointmentDetails,
  navigateToPatientReferral,
}: {
  data: FirebaseNotificationData;
  navigateToAppointmentDetails: (appointmentId: string) => void;
  navigateToPatientReferral: () => void;
}) {
  if (!('Notification' in window)) {
    return;
  }
  if (Notification.permission === 'granted') {
    let notification: Notification;

    console.log('received notification with data', data);

    if (data.type === 'appointment_reschedule') {
      notification = new Notification('Flossy - Appointment Reschedule', {
        body: `An appointment for ${data.dentistName} with ${data.patientName} has been rescheduled.`,
        icon: RemoteImages.appLogo,
        requireInteraction: true,
      });
      notification.onclick = () => {
        runFTrack({
          event: 'Click Reschedule Appointment Notification',
          workflow: BookingWorkflow,
          action: ClickTrackingAction,
          context: 'pushNotification',
          componentId: 'appointmentRescheduleNotification',
          extraProps: {
            appointmentId: data.appointmentId,
          },
        });
        navigateToAppointmentDetails(data.appointmentId);
      };
    } else if (data.type === 'appointment_cancellation') {
      notification = new Notification('Flossy - Appointment Cancellation', {
        body: `An appointment for ${data.dentistName} with ${data.patientName} has been cancelled.`,
        icon: RemoteImages.appLogo,
        requireInteraction: true,
      });
      notification.onclick = () => {
        runFTrack({
          event: 'Click Cancel Appointment Notification',
          workflow: BookingWorkflow,
          action: ClickTrackingAction,
          context: 'pushNotification',
          componentId: 'appointmentCancellationNotification',
          extraProps: {
            appointmentId: data.appointmentId,
          },
        });
        navigateToAppointmentDetails(data.appointmentId);
      };
    } else if (data.type === 'new_booked_appointment') {
      notification = new Notification('Flossy - New Booking', {
        body: `${data.dentistName} has received a new booking with ${data.patientName}! 🎉`,
        icon: RemoteImages.appLogo,
        requireInteraction: true,
      });
      notification.onclick = () => {
        runFTrack({
          event: 'Click New Booking Notification',
          workflow: BookingWorkflow,
          action: ClickTrackingAction,
          context: 'pushNotification',
          componentId: 'appointmentBookingNotification',
          extraProps: {
            appointmentId: data.appointmentId,
          },
        });
        navigateToAppointmentDetails(data.appointmentId);
      };
    } else if (data.type === 'referral_credit_applied') {
      notification = new Notification('Flossy - Referral Credit Applied', {
        body: `Your practice ${data.practiceName} has been granted ${data.amount} for referring ${data.patientName} to Flossy! 💰`,
        icon: RemoteImages.appLogo,
        requireInteraction: true,
      });
      notification.onclick = () => {
        runFTrack({
          event: 'Click Referral Credit Applied Notification',
          workflow: BookingWorkflow,
          action: ClickTrackingAction,
          context: 'pushNotification',
          componentId: 'referralCreditAppliedNotification',
          extraProps: {
            referralId: data.referralId,
            referralCreditId: data.referralCreditId,
            practiceId: data.practiceId,
          },
        });
        navigateToPatientReferral();
      };
    } else {
      notification = new Notification(data.title, {
        body: data.message,
        icon: RemoteImages.appLogo,
        requireInteraction: true,
      });
    }

    console.log('created notification', notification);
  }
}
