export function clamp(number: number, boundOne: number, boundTwo?: number) {
  if (!boundTwo) {
    return Math.max(number, boundOne) === boundOne ? number : boundOne;
  } else if (Math.min(number, boundOne) === number) {
    return boundOne;
  } else if (Math.max(number, boundTwo) === number) {
    return boundTwo;
  }
  return number;
}

export function distributeCents(value: number, divisor: number): number[] {
  if (divisor <= 0) {
    return [];
  }

  if (value === 0) {
    return Array(divisor).fill(0);
  }
  if (value < 0) {
    return [];
  }

  if (divisor > value) {
    return [value];
  }

  const baseAmount = Math.floor(value / divisor);

  const remainder = value % divisor;

  const result: number[] = Array(divisor).fill(baseAmount);

  for (let i = 0; i < remainder; i++) {
    result[i] += 1;
  }

  return result;
}
