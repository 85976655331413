import { useContext } from 'react';

import { AuthProvider } from '../../../Authentication/Authentication';
import { PatientDetail } from '../../../ServiceContext/patients';
import { toShortDateString } from '../../../shared/dates/dates';
import { formatMemberId } from '../../../shared/strings';
import { PatientContext } from '../PatientDetailPage';

export default function InsuranceInformation({ authProvider }: { authProvider: AuthProvider }) {
  const patient = useContext(PatientContext) as PatientDetail;

  if (patient.insuranceInformation && patient.insuranceInformation.length > 0) {
    const insurance = patient.insuranceInformation[0];

    return (
      <div className="flex flex-col items-start gap-1 w-1/2">
        <div className="text-base-content text-lg font-bold mb-2">Insurance</div>
        <div className="flex w-full justify-start gap-4">
          <div className="w-1/2">
            <span className="text-base-content text-sm font-bold leading-relaxed">Insurer:</span>
            {insurance.insuranceCarrier && insurance.insuranceCarrier.name && (
              <span className="text-base-content text-sm font-normal leading-relaxed">
                {' '}
                {insurance.insuranceCarrier.name}
                <br />
              </span>
            )}
            <span className="text-base-content text-sm font-bold leading-relaxed">Plan Type: </span>
            <span className="text-base-content text-sm font-normal leading-relaxed">
              {insurance.planType}
              <br />
            </span>
            <span className="text-base-content text-sm font-bold leading-relaxed">
              Insurance Carrier Phone Number:{' '}
            </span>
            <span className="text-base-content text-sm font-normal leading-relaxed">
              {insurance.insuranceCarrierPhone}
            </span>
          </div>
          <div className="w-1/2">
            <span className="text-base-content text-sm font-bold leading-relaxed">Name:</span>
            <span className="text-base-content text-sm font-normal leading-relaxed">
              {' '}
              {insurance.memberFirstName} {insurance.memberLastName}
              <br />
            </span>
            <span className="text-base-content text-sm font-bold leading-relaxed">
              Date of Birth:{' '}
            </span>
            <span className="text-base-content text-sm font-normal leading-relaxed">
              {toShortDateString(insurance.dateOfBirth) || 'N/A'}
              <br />
            </span>
            <span className="text-base-content text-sm font-bold leading-relaxed">Member ID:</span>
            {insurance.memberId && (
              <span className="text-base-content text-sm font-normal leading-relaxed">
                {formatMemberId(insurance.memberId, false)}
              </span>
            )}
          </div>
        </div>
        <div className="grow"></div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-start gap-1 w-1/2">
        <div className="text-base-content text-lg font-bold">Insurance</div>
        <div className="justify-center text-center text-base-content text-sm font-normal mb-6">
          Patient has no insurance information on fie.
        </div>
      </div>
    );
  }
}
