import { useNavigate } from 'react-router-dom';

import { AuthenticationWorkflow } from '../amplitude';
import { AuthProvider } from '../Authentication/Authentication';
import Button from '../shared/Button/Button';

type Props = {
  authentication: AuthProvider;
};

const LogoutButton: React.FC<Props> = ({ authentication }) => {
  const navigate = useNavigate();

  const onClick = async () => {
    await authentication.logOut();
    navigate('/login');
  };

  return (
    <Button
      aria-label="logout-button"
      id="logout-button"
      onClick={onClick}
      noFill
      omitBorder
      color="tertiary"
      className="rounded-md text-xs text-base-content-flossy  border-base-content-flossy border-[1px] font-normal ml-8"
      workflow={AuthenticationWorkflow}
      trackingLabel="Logout Button"
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
