import { useNavigate } from 'react-router-dom';

import { AuthProvider } from '../../Authentication/Authentication';
import { SettingsIcon } from '../../HomePage/components/Icons';

type Props = {
  authentication: AuthProvider;
};

export default function SettingsFrameIcon({ authentication }: Props) {
  const navigate = useNavigate();

  return (
    <button
      className="pt-2 ml-2"
      title="Settings"
      onClick={() => {
        navigate('/settings');
      }}
    >
      <SettingsIcon />
    </button>
  );
}
