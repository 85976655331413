import Calendar from './Calendar/Calendar';
import { Dentist } from './ServiceContext/user';

type Props = {
  dentist: Dentist;
  onClearDentist: (() => void) | null;
};

const Availability: React.FC<Props> = ({ dentist, onClearDentist }) => {
  return <Calendar dentist={dentist} onClearDentist={onClearDentist} />;
};

export default Availability;
