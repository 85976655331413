import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type Props = {
  onClick: (id: string) => void;
  splitPaymentMethodId: string;
  disabled: boolean;
};

const PaymentRemoveButton: React.FC<Props> = ({ onClick, splitPaymentMethodId, disabled }) => {
  return (
    <button
      title="Remove Payment"
      onClick={() => {
        if (!disabled) {
          onClick(splitPaymentMethodId);
        }
      }}
      className={`pr-4 ${disabled ? 'opacity-0 cursor-default' : 'hover:opacity-75'}`}
      disabled={disabled}
      style={{ pointerEvents: disabled ? 'none' : 'auto' }}
    >
      <FontAwesomeIcon
        icon={faXmark}
        style={{
          color: 'lightslategray',
          scale: '120%',
          opacity: disabled ? '0' : '70%',
        }}
      />
    </button>
  );
};

export default PaymentRemoveButton;
