import { BookingChargeCredit } from '../../../../API/bookingsAndCredits';

type Props = {
  allCreditItems: BookingChargeCredit[];
};

const HorizontalSeparator = () => <div className="h-px bg-rule my-4 w-[90%] translate-x-2"></div>;

const CreditsSummary: React.FC<Props> = ({ allCreditItems }) => {
  const totalCreditsRedeemed = allCreditItems.filter((c) => c.status === 'consumed');

  return (
    <div className="flex flex-col gap-2 text-left bg-white rounded-md p-4">
      <div className={'flex flex-col gap-1'}>
        <span className={'text-lg font-bold'}>Total Credits Earned</span>
        <span className={'text-2xl font-bold'}>{allCreditItems.length}</span>
      </div>
      <HorizontalSeparator />
      <div className={'flex flex-col gap-1'}>
        <span className={'text-lg font-bold'}>Total Credits Redeemed</span>
        <span className={'text-2xl font-bold'}>{totalCreditsRedeemed.length}</span>
      </div>
    </div>
  );
};

export default CreditsSummary;
