import { sendPasswordResetEmail } from 'firebase/auth';
import { useCallback, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { AuthenticationWorkflow } from './amplitude';
import { AuthProvider } from './Authentication/Authentication';
import { firebaseAuth } from './firebase';
import Button from './shared/Button/Button';
import { emailRegex } from './shared/strings/stringValidation';
import ValidatedInput from './shared/ValidatedInput/ValidatedInput';

type Props = {
  authentication: AuthProvider;
};

const ResetPassword: React.FC<Props> = ({ authentication }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [showError, setShowError] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isLogInEmailValid, setIsLogInEmailValid] = useState(false);

  const sendResetPasswordInstructions = useCallback(async (email: string) => {
    try {
      await sendPasswordResetEmail(firebaseAuth, email);
    } catch (err) {
      return Promise.resolve(false);
    }
    return Promise.resolve(true);
  }, []);

  if (authentication.isLoggedIn) {
    return <Navigate to="/appointments" replace />;
  }

  const onSendInstructionsClicked = async () => {
    if (isResettingPassword) {
      return;
    }

    try {
      setIsResettingPassword(true);
      const didSend = await sendResetPasswordInstructions(email);
      setIsResettingPassword(false);
      setShowError(!didSend);
    } catch (err) {
      setShowError(true);
      setIsResettingPassword(false);
      return;
    }
    navigate('/login', { state: { didResetPassword: true, sentInstructionsTo: email } });
  };

  const onEmailChange = (newValue: string, isValid: boolean) => {
    setEmail(newValue);
    setIsLogInEmailValid(isValid);
  };

  const renderMessage = () => {
    if (showError) {
      return (
        <div className="alert alert-danger" role="alert">
          Failed to send password reset instructions. Please try again later.
        </div>
      );
    }

    return null;
  };

  return (
    <div id="reset-password" className="flex flex-col items-start w-full">
      {renderMessage()}

      <label
        className="reset-password-email-label text-[#5a5a5a]"
        htmlFor="reset-password-page-email-input"
      >
        Email Address
      </label>
      <div className="email-input-field relative w-1/3 pb-4 mt-3">
        <ValidatedInput
          id="reset-password-page-email-input"
          className="w-full"
          validations={[
            {
              type: 'regex',
              regex: emailRegex,
              errorMessage: 'Please enter a valid email',
            },
          ]}
          onChange={onEmailChange}
          allowClear
        />
      </div>
      <Button
        id="send-instructions-button"
        onClick={onSendInstructionsClicked}
        loading={isResettingPassword}
        omitBorder
        disabled={!email || !isLogInEmailValid}
        workflow={AuthenticationWorkflow}
        context="resetPassword"
        trackingLabel="Send Instructions Button"
      >
        Send Instructions
      </Button>
    </div>
  );
};

export default ResetPassword;
