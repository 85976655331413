/* eslint-disable react/jsx-pascal-case */

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Accounting from '../Accounting/Accounting';
import NewBookingsAndCreditsExperience from '../Accounting/BookingsAndCredits/NewBookingsAndCreditsExperience/NewBookingsAndCreditsExperience';
import ShareInvoice from '../Accounting/BookingsAndCredits/NewBookingsAndCreditsExperience/ShareInvoiceFlow/ShareInvoice';
import PaymentLinkContentPage from '../Accounting/BookingsAndCredits/ShareInvoiceFlow/PaymentLinkContentPage';
import PayoutDetail from '../Accounting/Payouts/PayoutDetail';
import PayoutInvoiceDetails from '../Accounting/Payouts/PayoutInvoiceDetails';
import Payouts from '../Accounting/Payouts/Payouts';
import AppointmentDashboard from '../Appointments/AppointmentDashboard';
import AppointmentDetail from '../Appointments/AppointmentDetail/AppointmentDetail';
import AppointmentsRollUp from '../Appointments/AppointmentsRollUp/AppointmentsRollUp';
import ScheduleNextAppointment from '../Appointments/BookFollowUp/ScheduleNextAppointment';
import CancelAppointment from '../Appointments/CancelAppointment/CancelAppointment';
import Invoicing from '../Appointments/Invoicing/Invoicing';
import RescheduleAppointment from '../Appointments/RescheduleAppointment/RescheduleAppointment';
import { AuthProvider } from '../Authentication/Authentication';
import Availability from '../Availability';
import ProvideAppointment from '../DataProviders/ProvideAppointment';
import DentistSelectionWrapper from '../DataProviders/SelectionWrappers/DentistSelectionWrapper';
import PracticeSelectionWrapper from '../DataProviders/SelectionWrappers/PracticeSelectionWrapper';
import Home from '../HomePage/Home';
import InvoiceManager from '../Invoices/InvoiceManager';
import ViewInvoices from '../Invoices/ViewInvoices';
import Login from '../Login/Login';
import Logout from '../Logout';
import NotificationsExplain from '../Notifications/Explain/NotificationsExplain';
import PatientReferralDashboard from '../PatientReferral/Dashboard';
import PatientDetails from '../Patients/PatientDetails/PatientDetailPage';
import PatientDirectory from '../Patients/PatientDirectory/PatientDirectory';
import TreatmentPlanDetails from '../Patients/TreatmentPlans/TreatmentPlanDetails';
import ResetPassword from '../ResetPassword';
import PracticeReviews from '../Reviews/PracticeReviews';
import SettingsPage from '../Settings/SettingsPage';
import CreateTreatmentPlanPage from '../TreatmentPlans/CreateTreatmentPlanPage';

type Props = {
  authentication: AuthProvider;
};

export const RootRedirect: React.FC<Props> = ({ authentication }) => {
  if (authentication.isLoggedIn) {
    const managedDentists = authentication.managedDentists;
    if (!managedDentists || managedDentists.length === 0) {
      return <Navigate to="/logout" replace />;
    }
    return (
      <Routes>
        <Route
          key="log-in-catch-all-redirect"
          path="*"
          element={<Navigate to={'/home'} replace />}
        />
      </Routes>
    );
  }
  return <Navigate to="/login" replace />;
};

type RouterProps = {
  authentication: AuthProvider;
};

const Router: React.FC<RouterProps> = ({ authentication }) => {
  let practiceId: string = '';
  let practiceName: string = '';
  let managerHavingMultiplePractices: boolean = false;

  if (authentication.isLoggedIn) {
    const managedDentists = authentication.managedDentists;
    if (authentication.authUser) {
      practiceId = authentication.authUser?.user.practices[0].id;
      practiceName = authentication.authUser.user.practices[0].name;
      managerHavingMultiplePractices = authentication.authUser.user.practices.length > 1;
    }
    if (!managedDentists || managedDentists.length === 0) {
      return <Navigate to="/home" replace />;
    }

    return (
      <Routes>
        <Route key="home" path="/home" element={<Home authProvider={authentication} />} />
        <Route
          key="roll-up-view"
          path="/appointments"
          element={<AppointmentsRollUp authentication={authentication} />}
        />
        <Route
          key="/invoices"
          path="/invoices"
          element={<ViewInvoices authentication={authentication} />}
        />
        <Route
          key="/invoices/:appointmentId"
          path="/invoices/:appointmentId"
          element={
            <ProvideAppointment authProvider={authentication}>
              {({ appointment }) => <InvoiceManager appointment={appointment} />}
            </ProvideAppointment>
          }
        />
        <Route
          key="/availability"
          path="/availability"
          element={
            <DentistSelectionWrapper
              dentists={managedDentists}
              flavorText="Select a dentist to view their availability"
            >
              {({ selectedDentist, onClearDentist }) => (
                <Availability dentist={selectedDentist} onClearDentist={onClearDentist} />
              )}
            </DentistSelectionWrapper>
          }
        />
        <Route key="/accounting" path="/accounting" element={<Accounting />} />
        <Route
          key="/referral-program"
          path="/referral-program"
          element={
            <PatientReferralDashboard authProvider={authentication} practiceId={practiceId} />
          }
        />
        <Route
          path="/accounting/payouts"
          element={<Payouts authProvider={authentication} practiceId={practiceId} />}
        />
        <Route
          path="/accounting/payouts/:payoutId"
          element={<PayoutDetail authProvider={authentication} />}
        />
        <Route
          path={'/accounting/payouts/appointments/:appointmentId'}
          element={
            <ProvideAppointment authProvider={authentication}>
              {({ appointment }) => (
                <PayoutInvoiceDetails authProvider={authentication} appointment={appointment} />
              )}
            </ProvideAppointment>
          }
        />
        <Route
          path="/accounting/bookings-and-credits"
          element={
            authentication.isFlossyAdmin || managerHavingMultiplePractices ? (
              <PracticeSelectionWrapper
                authProvider={authentication}
                flavorText="Select a practice to view their bookings and credits"
              >
                {({ selectedPractice, onClearPractice }) => (
                  <NewBookingsAndCreditsExperience
                    authProvider={authentication}
                    practiceId={selectedPractice.id}
                    practiceName={selectedPractice.name}
                    onClearPractice={onClearPractice}
                  />
                )}
              </PracticeSelectionWrapper>
            ) : (
              <NewBookingsAndCreditsExperience
                authProvider={authentication}
                practiceId={practiceId}
                practiceName={practiceName}
              />
            )
          }
        />
        <Route
          key={'/accounting/bookings-and-credits/share'}
          path={'/accounting/bookings-and-credits/:practiceId/share'}
          element={<ShareInvoice authProvider={authentication} />}
        />
        <Route
          key={'/bookings-and-credits/pay-invoice'}
          path={'/bookings-and-credits/pay-invoice'}
          element={<PaymentLinkContentPage />}
        />
        <Route
          key={'/patients'}
          path={'/patients'}
          element={<PatientDirectory authProvider={authentication} />}
        />
        <Route
          path={'/patients/:patientId'}
          element={<PatientDetails authProvider={authentication} />}
        />
        <Route
          path={'/patients/:patientId/details/*'}
          element={<PatientDetails authProvider={authentication} />}
        />
        {/* This is a debug route, we may be able to just delete this. */}
        <Route
          key={'/notifications-explain'}
          path={'/notifications-explain'}
          element={<NotificationsExplain authentication={authentication} />}
        />
        <Route
          key="/patients/:patientId/treatment-plans/new"
          path="/patients/:patientId/treatment-plans/new"
          element={
            <CreateTreatmentPlanPage authProvider={authentication} dentists={managedDentists} />
          }
        />
        <Route
          path={'/patients/treatment-plans/:treatmentPlanId'}
          element={<TreatmentPlanDetails authProvider={authentication} />}
        />
        <Route
          path="/appointments/:appointmentId"
          element={
            <ProvideAppointment authProvider={authentication}>
              {({ appointment }) => (
                <AppointmentDashboard authProvider={authentication} appointment={appointment} />
              )}
            </ProvideAppointment>
          }
        />
        <Route
          path="/appointments/:appointmentId/invoicing"
          element={
            <ProvideAppointment authProvider={authentication}>
              {({ appointment, refetchAppointment }) => (
                <Invoicing
                  authProvider={authentication}
                  appointment={appointment}
                  refetchAppointment={refetchAppointment}
                />
              )}
            </ProvideAppointment>
          }
        />
        <Route
          path="/appointments/:appointmentId/details"
          element={<AppointmentDetail authentication={authentication} />}
        />
        <Route
          path="/appointments/:appointmentId/reschedule"
          element={
            <ProvideAppointment authProvider={authentication}>
              {({ appointment }) => (
                <RescheduleAppointment
                  authentication={authentication}
                  appointment={appointment}
                  dentists={authentication.managedDentists || []}
                />
              )}
            </ProvideAppointment>
          }
        />
        <Route
          path="/appointments/:appointmentId/book-follow-up"
          element={
            <ProvideAppointment authProvider={authentication}>
              {({ appointment }) => (
                <ScheduleNextAppointment
                  authProvider={authentication}
                  originalAppointment={appointment}
                  dentists={authentication.managedDentists || []}
                />
              )}
            </ProvideAppointment>
          }
        />
        <Route
          path="/appointments/:appointmentId/cancel"
          element={
            <ProvideAppointment authProvider={authentication}>
              {({ appointment }) => (
                <CancelAppointment authentication={authentication} appointment={appointment} />
              )}
            </ProvideAppointment>
          }
        />
        <Route key="/logout" element={<Logout authentication={authentication} />} path="/logout" />
        {process.env.REACT_APP_ENABLE_REVIEWS === 'true' && (
          <Route
            key="/reviews"
            path="/reviews"
            element={
              authentication.isFlossyAdmin ? (
                <PracticeSelectionWrapper
                  authProvider={authentication}
                  flavorText="Select a practice to view their reviews"
                >
                  {({ selectedPractice, onClearPractice }) => (
                    <PracticeReviews
                      authProvider={authentication}
                      practiceId={selectedPractice.id}
                      practiceName={selectedPractice.name}
                      onClearPractice={onClearPractice}
                    />
                  )}
                </PracticeSelectionWrapper>
              ) : (
                <PracticeReviews
                  authProvider={authentication}
                  practiceId={practiceId}
                  practiceName={practiceName}
                />
              )
            }
          />
        )}
        <Route
          key="everything-else-while-logged-in"
          element={<RootRedirect authentication={authentication} />}
          path="*"
        />
        <Route path={'/settings'} element={<SettingsPage authProvider={authentication} />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<Login authentication={authentication} />} />
      <Route path="/reset-password" element={<ResetPassword authentication={authentication} />} />
      <Route path="/" element={<RootRedirect authentication={authentication} />} />
      <Route
        key={'/bookings-and-credits/pay-invoice'}
        path={'/bookings-and-credits/pay-invoice'}
        element={<PaymentLinkContentPage />}
      />
      <Route path="/logout" element={<Logout authentication={authentication} />} />
      <Route
        key="logged-out-catch-all-redirect"
        path="*"
        element={<Login authentication={authentication} />}
      />
    </Routes>
  );
};

export default Router;
