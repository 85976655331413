import React from 'react';

import RadioButtonList from '../RadioButtonList/RadioButtonList';

type Props = {
  id?: string;
  value: 'yes' | 'no' | null;
  onYesOrNoSelected: (yesOrNo: 'yes' | 'no') => void;

  // Allows this to be used as a Formik form field
  name?: string;
  optionBackground?: boolean;
};

const YesNoRadioGroup: React.FC<Props> = ({
  id,
  value,
  onYesOrNoSelected,
  name = 'yes-or-no',
  optionBackground,
}) => {
  const onOptionSelected = (s: string) => {
    if (s === 'yes' || s === 'no') {
      onYesOrNoSelected(s);
    }
  };

  return (
    <RadioButtonList
      id={id}
      name={name}
      options={['yes', 'no'].map((yn) => {
        return { key: yn, displayValue: yn === 'yes' ? 'Yes' : 'No' };
      })}
      onButtonSelected={onOptionSelected}
      selectedKey={value || ''}
      orientation="horizontal"
      optionBackground={optionBackground}
    />
  );
};

export default YesNoRadioGroup;
