import ReactModal from 'react-modal';

import { GeneralWorkflow } from '../../amplitude';
import Button from '../Button/Button';
import { toTitleCase } from '../strings';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm?: () => void;
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  canConfirm?: boolean;
  canCancel?: boolean;
  withCloseButton?: boolean;
  shape: 'square' | 'round' | 'big-square';
  properties?: Record<string, any>;
};

const Modal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  onConfirm,
  children,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  canConfirm,
  canCancel,
  withCloseButton,
  shape,
  properties,
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
      onRequestClose();
    }
    if (onRequestClose) {
      onRequestClose();
    }
    return;
  };

  let shapeStyling = '';
  let contentStyle = '';
  if (shape === 'square') {
    shapeStyling += 'w-3/4 md:w-1/2 lg:w-1/3 p-4 rounded-lg bg-white';
    contentStyle = 'pl-2 h-3/4';
  } else if (shape === 'round') {
    shapeStyling += 'w-128 h-128 p-4 bg-[#f6e4e0] rounded-full';
    contentStyle = 'overflow-hidden';
  } else if (shape === 'big-square') {
    shapeStyling += 'w-full md:w-3/4 lg:w-1/2 p-4 rounded-lg bg-white max-h-[75%]';
    contentStyle = 'pl-2';
  }

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      className={`flex items-center justify-center fixed inset-0 z-50 outline-none focus:outline-none`}
      overlayClassName="fixed inset-0 bg-black bg-opacity-25 z-40"
    >
      <div className={`modal-wrapper overflow-auto ${shapeStyling}`}>
        {withCloseButton && (
          <button
            aria-label="Close modal"
            onClick={onRequestClose}
            style={{
              color: 'white',
              backgroundColor: 'transparent',
              border: 'none',
              fontSize: '32px',
              position: 'absolute',
              right: '20px',
              top: '10px',
            }}
          >
            &times;
          </button>
        )}
        <div className={`${contentStyle} h-[80%]`}>{children}</div>
        {(canConfirm || canCancel) && (
          <div className="modal-buttons flex justify-center space-x-4 mt-4 mb-3">
            {canConfirm && (
              <Button
                onClick={handleConfirm}
                className={'bg-primary text-white font-semibold hover:opacity-75'}
                workflow={GeneralWorkflow}
                trackingLabel={toTitleCase(confirmText)}
                properties={properties}
              >
                {confirmText}
              </Button>
            )}
            {canCancel && (
              <Button
                onClick={onRequestClose}
                className={'border border-rule text-primary shadow-none bg-white hover:opacity-75'}
                workflow={GeneralWorkflow}
                trackingLabel={toTitleCase(cancelText)}
              >
                {cancelText}
              </Button>
            )}
          </div>
        )}
      </div>
    </ReactModal>
  );
};

export default Modal;
