import React, { MouseEventHandler, PropsWithChildren } from 'react';

import { ClickTrackingAction, runFTrack, TrackedComponent } from '../../amplitude';
import { toCamelCase } from '../strings';
import NewTooltip from '../Tooltip/NewTooltip';

export type ButtonProps = {
  id?: string;
  onClick?: MouseEventHandler;
  loading?: boolean;
  className?: string;
  omitBorder?: boolean;
  noFill?: boolean;
  customText?: boolean;
  disabled?: boolean | string;
  color?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  htmlType?: 'button' | 'submit';
  ariaLabel?: string;
} & TrackedComponent;

/**
 * Button component
 * @param trackingLabel - Should be Title Case label for button, including "Button" at the end
 */
const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  id,
  onClick,
  loading,
  className,
  omitBorder,
  noFill,
  disabled,
  customText,
  htmlType = 'button',
  ariaLabel = 'button',
  color = 'primary',
  children,
  ...tracking
}) => {
  const disableButton = Boolean(disabled) || loading;

  let tooltipText = '';

  if (typeof disabled === 'string') {
    tooltipText = disabled;
  }

  const border =
    color === 'primary'
      ? 'border-primary'
      : color === 'secondary'
      ? 'border-secondary'
      : 'border-tertiary';

  let backgroundColor = 'bg-tertiary';
  switch (color) {
    case 'primary':
      backgroundColor = 'bg-primary';
      break;
    case 'secondary':
      backgroundColor = 'bg-secondary';
      break;
    case 'tertiary':
      backgroundColor = 'bg-tertiary';
      break;
    case 'danger':
      backgroundColor = 'bg-error-content';
      break;
    default:
      break;
  }

  const backgroundColorInactive =
    color === 'primary'
      ? 'bg-primary/50'
      : color === 'secondary'
      ? 'bg-secondary/50'
      : 'bg-tertiary';
  let textColor =
    color === 'primary'
      ? 'text-primary'
      : color === 'secondary'
      ? 'text-secondary'
      : 'text-tertiary';
  const onTextColor =
    color === 'primary'
      ? 'text-primary-content'
      : color === 'secondary'
      ? 'text-secondary-content'
      : 'text-tertiary-content';

  textColor = noFill ? textColor : onTextColor;
  backgroundColor = noFill ? '' : disableButton ? backgroundColorInactive : backgroundColor;
  let buttonComponent = (
    <button
      id={id}
      onClick={(e) => {
        if (tracking !== undefined && tracking.workflow !== undefined) {
          runFTrack({
            event: `Clicked ${tracking.trackingLabel}`,
            workflow: tracking.workflow,
            action: ClickTrackingAction,
            context: tracking.context,
            componentId: toCamelCase(tracking.trackingLabel),
            extraProps: tracking.properties,
          });
        }
        (() => {
          onClick && onClick(e);
        })();
      }}
      type={htmlType}
      className={`${className} ${backgroundColor} ${textColor} text-sm font-bold p-2.5 rounded-3xl shadow-md flex flex-row items-center
      ${disableButton ? 'cursor-not-allowed' : 'cursor-pointer hover:opacity-75'}
      ${omitBorder ? '' : `border ${border}`}`}
      disabled={disableButton}
      aria-label={ariaLabel}
    >
      {children}
      {loading && (
        <span
          className="spinner-border spinner-border-sm ml-2"
          role="status"
          aria-hidden="true"
          aria-label="loading"
        />
      )}
    </button>
  );

  if (tooltipText) {
    return (
      <NewTooltip text={tooltipText}>
        <div
        /* Do not remove this div, else the tooltip wrapping component won't operate correctly */
        >
          {buttonComponent}
        </div>
      </NewTooltip>
    );
  }

  return buttonComponent;
};

export default Button;
