import React, { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { BookingsAndCreditsWorkflow, ClickTrackingAction, runFTrack } from '../../../amplitude';
import {
  BookingLedger,
  getBookingLedger,
  getPaymentHistory,
  PaymentHistoryRecord,
} from '../../../API/bookingsAndCredits';
import { ErrorResponse } from '../../../API/response';
import { AuthProvider } from '../../../Authentication/Authentication';
import Alert, { errorAlert } from '../../../shared/Alert';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import { useURLQuery } from '../../../shared/routing/routing';
import Tabs from '../../../shared/Tabs/Tabs';
import { utcTimestampToBookingPeriod } from '../BookingPeriod';
import CurrentMonth from './CurrentMonth';
import PastMonths from './PastMonths';
import { MonthlyLedger } from './PastMonthsLedgerTable';
import PaymentHistory from './PaymentHistory';
import ReferralCredits from './ReferralCreditsTab/ReferralCredits';
import useTestOnlyClearBookingsAndCreditsPaymentHistory from './TestOnlyClearBookingsAndCreditsPaymentHistory';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  practiceName: string;
  onClearPractice?: (() => void) | null;
};

type TabKey = 'current-month' | 'previous-months' | 'payment-history' | 'referral-credits';

const NewBookingsAndCreditsExperience: React.FC<Props> = ({ authProvider, practiceId }) => {
  const { query, updateQuery } = useURLQuery();
  let activeTabKey: string;
  let tabFromQuery = query.get('tab');

  if (tabFromQuery) {
    activeTabKey = tabFromQuery;
  } else {
    if (localStorage.getItem('bookingsAndCreditsTab') === 'current-month') {
      activeTabKey = 'current-month' as TabKey;
    } else if (localStorage.getItem('bookingsAndCreditsTab') === 'previous-months') {
      activeTabKey = 'previous-months';
    } else if (localStorage.getItem('bookingsAndCreditsTab') === 'payment-history') {
      activeTabKey = 'payment-history';
    } else if (localStorage.getItem('bookingsAndCreditsTab') === 'referral-credits') {
      activeTabKey = 'referral-credits';
    } else {
      activeTabKey = 'current-month';
    }
  }

  const queryClient = useQueryClient();

  const { component: TestOnlyClearBookingsAndCreditsPaymentHistoryComponent } =
    useTestOnlyClearBookingsAndCreditsPaymentHistory({
      authProvider,
      practiceId,
    });

  const getBookingLedgerKey = useMemo(() => ['getBookingLedger', practiceId], [practiceId]);

  const {
    isLoading: isLoadingBookingLedger,
    error: fetchBookingLedgerError,
    data: bookingLedger,
  } = useQuery<BookingLedger, ErrorResponse>(
    getBookingLedgerKey,
    () => {
      return getBookingLedger({
        authProvider,
        practiceId,
      });
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  const getPaymentHistoryKey = useMemo(() => ['getPaymentHistory', practiceId], [practiceId]);

  const {
    isLoading: isLoadingPaymentHistory,
    error: fetchPaymentHistoryError,
    data: paymentHistoryRecords,
  } = useQuery<PaymentHistoryRecord[], ErrorResponse>(
    getPaymentHistoryKey,
    () => {
      return getPaymentHistory({
        authProvider,
        practiceId,
      });
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  if (!bookingLedger) {
    return null;
  }
  const currentDate = new Date();
  const currentBookingPeriodAPIFriendly = utcTimestampToBookingPeriod(
    currentDate.toISOString()
  ).apiFriendlyForm;
  const currentLedgerMonth = bookingLedger?.monthlyBreakdown[currentBookingPeriodAPIFriendly];

  const { monthlyBreakdown } = bookingLedger || {};

  const allLedgerMonths = Object.values(monthlyBreakdown);
  const allCreditItems = allLedgerMonths.flatMap((ledgerMonth) => ledgerMonth.credits.items);
  const allChargeItems = allLedgerMonths.flatMap((ledgerMonth) => ledgerMonth.charges.items);

  const monthlyBreakdownEntries = Object.entries(monthlyBreakdown);
  const pastLedgerMonthEntries = monthlyBreakdownEntries.filter(
    ([monthYear]) => monthYear !== currentBookingPeriodAPIFriendly
  );
  const pastMonthlyLedgerData: MonthlyLedger[] = pastLedgerMonthEntries
    .map(([monthYear, ledgerMonth]) => ({ monthYear, ledgerMonth }))
    .sort((a, b) => {
      const aDate: Date = new Date(a.monthYear);
      const bDate: Date = new Date(b.monthYear);
      return bDate.getTime() > aDate.getTime() ? 1 : -1;
    });

  if (isLoadingBookingLedger) {
    return <LoadingSpinner />;
  }

  if (fetchBookingLedgerError) {
    return <Alert {...errorAlert(fetchBookingLedgerError.errorResponse)} />;
  }

  const onTabChanged = (newKey: string) => {
    runFTrack({
      event: 'Select Bookings and Credit Tab',
      workflow: BookingsAndCreditsWorkflow,
      action: ClickTrackingAction,
      context: 'bookingsAndCredits',
      componentId: 'bookingsAndCreditsTabs',
      extraProps: {
        tab: newKey,
      },
    });
    localStorage.setItem('bookingsAndCreditsTab', newKey);

    // This is here to allow the user to clear their past month choice when zeroed in on a specific one.
    updateQuery({
      period: null,
      page: '1',
      tab: newKey,
    });
  };

  const renderTabs = () => {
    return (
      <Tabs activeTabKey={activeTabKey} onTabChanged={onTabChanged}>
        <Tabs.Tab tabKey="current-month" title="Current Month">
          <CurrentMonth
            authProvider={authProvider}
            ledgerMonth={currentLedgerMonth}
            allCreditItems={allCreditItems}
            practiceId={practiceId}
            queryContext={{
              client: queryClient,
              key: getBookingLedgerKey,
            }}
            monthYearString={currentBookingPeriodAPIFriendly}
          />
        </Tabs.Tab>
        <Tabs.Tab tabKey="previous-months" title="Previous Months">
          <PastMonths
            authProvider={authProvider}
            practiceId={practiceId}
            monthlyLedgerData={pastMonthlyLedgerData}
            queryContext={{
              client: queryClient,
              key: getBookingLedgerKey,
            }}
            paymentHistoryRecords={paymentHistoryRecords || []}
            allCreditItems={allCreditItems}
          />
        </Tabs.Tab>
        <Tabs.Tab tabKey="payment-history" title="Payment History">
          <PaymentHistory
            paymentHistoryRecords={paymentHistoryRecords}
            fetchPaymentHistoryError={fetchPaymentHistoryError}
            isLoadingPaymentHistory={isLoadingPaymentHistory}
          />
        </Tabs.Tab>
        <Tabs.Tab tabKey="referral-credits" title={'Referral Credits'}>
          <ReferralCredits allCreditItems={allCreditItems} allChargeItems={allChargeItems} />
        </Tabs.Tab>
      </Tabs>
    );
  };

  return (
    <div id={'bookings-and-credits-page flex flex-col gap-3'}>
      <div className="header text-2xl font-semibold text-left">Bookings and Credits</div>
      {Boolean(TestOnlyClearBookingsAndCreditsPaymentHistoryComponent) && (
        <div className="w-[200px]">{TestOnlyClearBookingsAndCreditsPaymentHistoryComponent}</div>
      )}
      {renderTabs()}
    </div>
  );
};

export default NewBookingsAndCreditsExperience;
