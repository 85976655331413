import { Patient } from '../../ServiceContext/patients';
import { Dentist } from '../../ServiceContext/user';

export function deleteFromString(str: string, startIndex: number, endIndex: number) {
  if (startIndex >= endIndex) {
    return str;
  }
  return str.substring(0, startIndex) + str.substring(endIndex);
}

export const BACKSPACE_KEY = 8;
export const DELETE_KEY = 46;

export function applyKeyPress(
  str: string,
  keyCode: number,
  key: string,
  selectionStart: number,
  selectionEnd: number
): { newValue: string; newSelectionStart: number; newSelectionEnd: number } {
  let newValue: string;
  let newSelectionStart: number = selectionStart;
  let newSelectionEnd: number = selectionEnd;

  // Handle deletions (Backspace and Delete keys)
  if (keyCode === BACKSPACE_KEY) {
    if (selectionStart === selectionEnd) {
      // No selection: delete the character before the cursor
      newValue =
        selectionStart > 0
          ? str.substring(0, selectionStart - 1) + str.substring(selectionStart)
          : str;
      newSelectionStart = newSelectionEnd = Math.max(0, selectionStart - 1);
    } else {
      // With selection: delete the selected text
      newValue = str.substring(0, selectionStart) + str.substring(selectionEnd);
      newSelectionStart = newSelectionEnd = selectionStart;
    }
  } else if (keyCode === DELETE_KEY) {
    if (selectionStart === selectionEnd) {
      // No selection: delete the character after the cursor
      newValue =
        selectionStart < str.length
          ? str.substring(0, selectionStart) + str.substring(selectionStart + 1)
          : str;
      newSelectionStart = newSelectionEnd = selectionStart;
    } else {
      // With selection: delete the selected text
      newValue = str.substring(0, selectionStart) + str.substring(selectionEnd);
      newSelectionStart = newSelectionEnd = selectionStart;
    }
  } else {
    // Handle character insertion
    if (selectionStart === selectionEnd) {
      // No selection: insert the character at the cursor
      newValue = str.substring(0, selectionStart) + key + str.substring(selectionEnd);
      newSelectionStart = newSelectionEnd = selectionStart + 1;
    } else {
      // With selection: replace the selected text with the character
      newValue = str.substring(0, selectionStart) + key + str.substring(selectionEnd);
      newSelectionStart = newSelectionEnd = selectionStart + 1;
    }
  }

  return {
    newValue,
    newSelectionStart,
    newSelectionEnd,
  };
}

export function digitsString(n: number, padToLength: number) {
  if (!n) {
    n = 0;
  }
  if (!padToLength) {
    return '';
  }
  let zeros = '';
  for (let i = 0; i < padToLength; i++) {
    zeros += '0';
  }
  return (zeros + n).slice(padToLength * -1);
}

export function usernameFromDentist(d: Dentist): string {
  return `${d.firstName} ${d.lastName} (${d.username})`;
}

export function usernameFromPatient(p: Patient): string {
  return `${p.firstName} ${p.lastName} (${p.email})`;
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const uppercaseRx = /[\p{Lu}]/u;
const lowercaseRx = /[\p{Ll}]/u;
const leadingCapitalRx = /^[\p{Lu}](?![\p{Lu}])/gu;
const identifierRx = /([\p{Alpha}\p{N}_]|$)/u;
const separatorsRx = /[_.\- ]+/;

const leadingSeparatorsRx = new RegExp('^' + separatorsRx.source);
const separatorsAndIdentifierRx = new RegExp(separatorsRx.source + identifierRx.source, 'gu');
const numbersAndIdentifierRx = new RegExp('\\d+' + identifierRx.source, 'gu');

const preserveCamelCase = (
  str: string,
  toLowerCase: (s: string) => string,
  toUpperCase: (s: string) => string,
  preserveConsecutiveUppercase: boolean
) => {
  let isLastCharLower = false;
  let isLastCharUpper = false;
  let isLastLastCharUpper = false;
  let isLastLastCharPreserved = false;

  for (let index = 0; index < str.length; index++) {
    const character = str[index];
    isLastLastCharPreserved = index > 2 ? str[index - 3] === '-' : true;

    if (isLastCharLower && uppercaseRx.test(character)) {
      str = str.slice(0, index) + '-' + str.slice(index);
      isLastCharLower = false;
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper = true;
      index++;
    } else if (
      isLastCharUpper &&
      isLastLastCharUpper &&
      lowercaseRx.test(character) &&
      (!isLastLastCharPreserved || preserveConsecutiveUppercase)
    ) {
      str = str.slice(0, index - 1) + '-' + str.slice(index - 1);
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper = false;
      isLastCharLower = true;
    } else {
      isLastCharLower =
        toLowerCase(character) === character && toUpperCase(character) !== character;
      isLastLastCharUpper = isLastCharUpper;
      isLastCharUpper =
        toUpperCase(character) === character && toLowerCase(character) !== character;
    }
  }

  return str;
};

const preserveConsecutiveUppercase = (input: string, toLowerCase: (s: string) => string) => {
  leadingCapitalRx.lastIndex = 0;

  return input.replace(leadingCapitalRx, (m1) => toLowerCase(m1));
};

const postProcess = (input: string, toUpperCase: (s: string) => string) => {
  separatorsAndIdentifierRx.lastIndex = 0;
  numbersAndIdentifierRx.lastIndex = 0;

  return input
    .replace(separatorsAndIdentifierRx, (_, identifier) => toUpperCase(identifier))
    .replace(numbersAndIdentifierRx, (m) => toUpperCase(m));
};

export function toTitleCase(input: string | string[]) {
  if (!(typeof input === 'string' || Array.isArray(input))) {
    throw new TypeError('Expected the input to be `string | string[]`');
  }

  if (Array.isArray(input)) {
    input = input
      .map((x) => x.trim())
      .filter((x) => x.length)
      .join(' ');
  } else {
    input = input.trim();
  }

  if (input.length === 0) {
    return '';
  }

  return input
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
}

export function toCamelCase(
  input: string | string[],
  optionsArg?: {
    pascalCase?: boolean;
    preserveConsecutiveUppercase?: boolean;
    locale?: false | string;
  }
) {
  if (!(typeof input === 'string' || Array.isArray(input))) {
    throw new TypeError('Expected the input to be `string | string[]`');
  }

  const options = {
    pascalCase: false,
    preserveConsecutiveUppercase: false,
    ...optionsArg,
  };

  if (Array.isArray(input)) {
    input = input
      .map((x) => x.trim())
      .filter((x) => x.length)
      .join('-');
  } else {
    input = input.trim();
  }

  if (input.length === 0) {
    return '';
  }

  let toLowerCase;
  if (options.locale === false) {
    toLowerCase = (str: string) => str.toLowerCase();
  } else {
    toLowerCase = (str: string) =>
      str.toLocaleLowerCase(options.locale as string | string[] | undefined);
  }

  let toUpperCase;
  if (options.locale === false) {
    toUpperCase = (str: string) => str.toUpperCase();
  } else {
    toUpperCase = (str: string) =>
      str.toLocaleUpperCase(options.locale as string | string[] | undefined);
  }

  if (input.length === 1) {
    if (separatorsRx.test(input)) {
      return '';
    }

    return options.pascalCase ? toUpperCase(input) : toLowerCase(input);
  }

  const hasUpperCase = input !== toLowerCase(input);

  if (hasUpperCase) {
    input = preserveCamelCase(
      input,
      toLowerCase,
      toUpperCase,
      options.preserveConsecutiveUppercase || false
    );
  }

  input = input.replace(leadingSeparatorsRx, '');
  input = options.preserveConsecutiveUppercase
    ? preserveConsecutiveUppercase(input, toLowerCase)
    : toLowerCase(input);

  if (options.pascalCase) {
    input = toUpperCase(input.charAt(0)) + input.slice(1);
  }

  return postProcess(input, toUpperCase);
}

export function formatPhoneNumber(phone: string | null | undefined): string {
  if (!phone) {
    return '';
  }

  // Remove all non-numeric characters
  let cleaned = phone.replace(/\D/g, '');

  // Normalize the number to ensure it has a country code
  if (!cleaned.startsWith('1') && cleaned.length === 10) {
    cleaned = '1' + cleaned;
  }

  // Match the US phone number format
  let match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Correctly format the phone number
    return `1 (${match[1]}) ${match[2]}-${match[3]}`;
  } else {
    // Return original for non-standard formats or incorrect lengths
    return phone;
  }
}

export function looksLikeSSN(str: string): boolean {
  const traditionallyFormatted = /^\d{3}-\d{2}-\d{4}$/;
  const conservativeMasking = /^\d{9}$/;
  return traditionallyFormatted.test(str) || conservativeMasking.test(str);
}

export function formatMemberId(
  memberId: string | null | undefined,
  hideAll: boolean | null = true
): string {
  if (!memberId) {
    return 'Not provided';
  }
  if (looksLikeSSN(memberId)) {
    if (hideAll) {
      return 'XXX-XX-XXXX';
    } else {
      return `XXX-XX${memberId.slice(6)}`;
    }
  }
  return memberId;
}

export function formatNumberAsPercentage(number: number): string {
  return number.toLocaleString('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export function splitOnCommaAndSpace(str: string): string[] {
  const splitOnCommas = str.split(',').filter((s) => s.trim().length > 0);
  return splitOnCommas.flatMap((s) => s.split(' ')).filter((s) => s.trim().length > 0);
}
