import React, { useCallback, useState } from 'react';

import { BookingsAndCreditsWorkflow } from '../../../amplitude';
import { PaymentHistoryRecord } from '../../../API/bookingsAndCredits';
import { Timestamp } from '../../../ServiceContext/shared';
import Cookies from '../../../shared/cookies/cookies';
import { toLongDateString, toShortDateString } from '../../../shared/dates/dates';
import { stringifyMoney } from '../../../shared/money/stringifyMoney';
import { useURLQuery } from '../../../shared/routing/routing';
import NewPagination from '../../../shared/Table/NewPagination';
import Table, { ColumnDef } from '../../../shared/Table/Table';
import { formatYearMonth } from '../BookingPeriod';

type Props = {
  data: PaymentHistoryRecord[];
};

function formatDateForPaymentPeriod(dateString: string) {
  const [month, year] = dateString.split('/');
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const monthIndex = parseInt(month, 10) - 1;
  const monthName = monthNames[monthIndex];
  return `${monthName} ${year}`;
}

const PaymentHistoryTable: React.FC<Props> = ({ data }) => {
  const { query, updateQuery } = useURLQuery();
  const pageSize = 6;
  const currentPage = parseInt(query.get('page') || '1', 10);
  const [selectedPage, setSelectedPage] = useState<number>(currentPage || 1);

  const setCookies = useCallback(
    (newParam: { [p: string]: string | null | undefined }) => {
      Cookies.bookingSearchMemory.set(updateQuery(newParam));
    },
    [updateQuery]
  );
  const onNewPageSelected = useCallback(
    (page: number) => {
      setCookies({ page: page.toString() });
      setSelectedPage(page);
    },
    [setCookies]
  );

  const columns: ColumnDef<PaymentHistoryRecord>[] = [
    {
      columnKey: 'emptyColumn',
      title: '',
      widthProportion: 0.1,
      render: () => {
        return <></>;
      },
    },
    {
      columnKey: 'datePaid',
      title: 'Date Paid',
      widthProportion: 1,
      render: (record: PaymentHistoryRecord) => {
        if (record.status === 'succeeded') {
          let datePaid: Timestamp = record.createdAt;
          if (record.startedProcessingAt) {
            datePaid = record.startedProcessingAt;
          }
          return <span className={'font-bold'}>{toShortDateString(datePaid)}</span>;
        }
        return <span>---</span>;
      },
    },
    {
      columnKey: 'paymentPeriod',
      title: 'Payment Period',
      widthProportion: 1,
      render: (record: PaymentHistoryRecord) => {
        if (record.details) {
          if (record.details.data.type === 'as-of-date') {
            return (
              <span className={'underline'}>
                {formatDateForPaymentPeriod(record.details.data.asOfDate)}
              </span>
            );
          }
          return <span className={'underline'}>{formatYearMonth(record.details.data)}</span>;
        } else if (record.paymentBalanceDate) {
          return <span className={'underline'}>{toLongDateString(record.paymentBalanceDate)}</span>;
        }
        return <span>---</span>;
      },
    },
    {
      columnKey: 'status',
      title: 'Status',
      widthProportion: 1,
      render: (record: PaymentHistoryRecord) => {
        let stateColor = '';
        if (record.status === 'cancelled' || record.status === 'failed') {
          stateColor = 'text-error-content';
        } else if (record.status === 'succeeded') {
          stateColor = 'text-booking-charge-settled';
        }
        return (
          <span className={stateColor}>
            {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
          </span>
        );
      },
    },
    {
      columnKey: 'amountPaid',
      title: 'Amount Paid',
      widthProportion: 1,
      render: (record: PaymentHistoryRecord) => {
        return (
          <span className={'font-bold'}>
            {stringifyMoney(record.amount, { includeCommas: true })}
          </span>
        );
      },
    },
  ];

  const startIndex = (selectedPage - 1) * pageSize;
  const paginatedRecords = data.slice(startIndex, startIndex + pageSize);

  return (
    <div id={'table-and-pagination'} className="w-full overflow-x-hidden">
      <Table
        id={'payment-history-table'}
        columns={columns}
        data={paginatedRecords}
        rowKeyGenerator={(record: PaymentHistoryRecord) => `${record.id}`}
        tableClassNameExt="bg-white"
      />
      <NewPagination
        currentPage={selectedPage}
        pageSize={pageSize}
        totalItemCount={data.length}
        onPageChange={onNewPageSelected}
        tracking={{
          workflow: BookingsAndCreditsWorkflow,
          context: 'bookingsAndCredits',
        }}
      />
    </div>
  );
};

export default PaymentHistoryTable;
