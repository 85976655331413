import { DateTime } from 'luxon';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AppointmentWorkflow, ClickTrackingAction, runFTrack } from '../../amplitude';
import { getAppointments } from '../../API/appointments';
import { ErrorResponse } from '../../API/response';
import { getStatusFromAppointment } from '../../Appointments/AppointmentUtils';
import { AuthProvider } from '../../Authentication/Authentication';
import { useSelectedDentist } from '../../Dentists/SelectedDentistContext';
import { GetAppointmentsResponse } from '../../ServiceContext/appointments';
import Alert, { errorAlert } from '../../shared/Alert';
import { isInFuture, toShortTimeString } from '../../shared/dates/dates';
import DropdownButton, { SelectOption } from '../../shared/DropdownButton';
import LinkText from '../../shared/Text/LinkText';
import AppointmentTileDetails from './AppointmentTileDetails';
import AppointmentTileNotes from './AppointmentTileNotes';
import { BoxArrowRight, CheckMark, XMark } from './Icons';

type Props = {
  authProvider: AuthProvider;
};
const TodaysAppointments: React.FC<Props> = ({ authProvider }) => {
  const navigate = useNavigate();
  const { selectedDentistId } = useSelectedDentist();

  const queryKey = `getAppointments_${selectedDentistId}`;
  const {
    isLoading: isLoadingDisplayAppointments,
    error: responseError,
    data: appointmentsResponse,
  } = useQuery<GetAppointmentsResponse, ErrorResponse>(queryKey, () => {
    return getAppointments({
      authProvider,
      page: 1,
      pageSize: 20,
      timeMin: DateTime.local().startOf('day').toUTC().toString(),
      timeMax: DateTime.local().endOf('day').toUTC().toString(),
      dentistId: selectedDentistId ?? undefined,
      statuses: ['open', 'requested', 'completed'],
    });
  });

  if (responseError) {
    return (
      <div className="no-print">
        <Alert {...errorAlert(responseError)} />
      </div>
    );
  }

  const renderTodaysAppointmentsData = () => {
    if (!appointmentsResponse || isLoadingDisplayAppointments) {
      return <div>Loading today's appointments...</div>;
    }

    if (appointmentsResponse && appointmentsResponse.appointments.length === 0) {
      return (
        <div>
          <center className="italic bg-info rounded-md py-4 text-base">
            You have no Flossy Appointments today.
          </center>
        </div>
      );
    }

    appointmentsResponse.appointments.sort((a, b) => {
      return a.start.localeCompare(b.start);
    });
    let actionOptions: SelectOption[] = [];
    return appointmentsResponse.appointments.map((appointment) => {
      if (appointment.status === 'completed') {
        actionOptions = [{ id: 'View Invoice', label: 'View Invoice' }];
      } else if (appointment.status === 'cancelled') {
        actionOptions = [{ id: 'Reschedule Appointment', label: 'Reschedule Appointment' }];
      } else if (
        appointment.status === 'requested' &&
        isInFuture(appointment.start, appointment.timeZone)
      ) {
        actionOptions = [
          { id: 'Create Invoice', label: 'Create Invoice' },
          { id: 'Add Treatment Plan', label: 'Add Treatment Plan' },
          { id: 'Reschedule Appointment', label: 'Reschedule Appointment' },
          { id: 'Cancel Appointment', label: 'Cancel Appointment' },
        ];
      } else {
        actionOptions = [
          { id: 'Add Treatment Plan', label: 'Add Treatment Plan' },
          { id: 'Reschedule Appointment', label: 'Reschedule Appointment' },
          { id: 'Cancel Appointment', label: 'Cancel Appointment' },
        ];

        if (
          !appointment.invoice ||
          appointment.invoice.status === 'overdue' ||
          appointment.invoice.status === 'due' ||
          appointment.invoice.status === 'partially_paid'
        ) {
          actionOptions = [{ id: 'Create Invoice', label: 'Create Invoice' }, ...actionOptions];
        }
      }
      const onOptionSelect = (selectedOption: SelectOption) => {
        if (selectedOption.id === 'View Invoice') {
          navigate(`/appointments/${appointment.id}`);
        } else if (selectedOption.id === 'Reschedule Appointment') {
          navigate(`/appointments/${appointment.id}/reschedule`);
        } else if (selectedOption.id === 'Cancel Appointment') {
          navigate(`/appointments/${appointment.id}/cancel`);
        } else if (selectedOption.id === 'Create Invoice') {
          navigate(`/appointments/${appointment.id}/invoicing`);
        } else if (selectedOption.id === 'Add Treatment Plan') {
          navigate(`/patients/${appointment.userId}/treatment-plans/new`);
        }
      };

      return (
        <div
          key={appointment.id}
          className={`flex flex-row text-left justify-between mb-2 p-2 items-center rounded-lg h-20 ${
            appointment.status === 'completed' || appointment.status === 'cancelled'
              ? 'bg-[#F8F8F8]'
              : 'bg-white'
          }`}
        >
          <div className="appointment-info flex flex-row gap-4 items-center w-3/4">
            <div className="symbol w-[5%]">
              {appointment.status === 'completed' ? (
                <div className="px-4">
                  <CheckMark />
                </div>
              ) : appointment.status === 'cancelled' ? (
                <div className="px-4">
                  <XMark />
                </div>
              ) : (
                <div className="px-4">
                  <XMark color="bg-white" />
                </div>
              )}
            </div>
            <div className="time-column flex flex-col gap-2 mr-12 w-24">
              <span className="start-time font-bold text-sm">
                {toShortTimeString(appointment.start, appointment.timeZone)}
              </span>
              <span className="status text-xs">{getStatusFromAppointment(appointment)}</span>
            </div>
            <AppointmentTileDetails appointment={appointment} />
            <AppointmentTileNotes appointment={appointment} />
          </div>
          <div className="appointment-actions mr-4">
            {actionOptions.length > 0 && (
              <DropdownButton
                label="Manage Appointment"
                options={actionOptions}
                onSelect={onOptionSelect}
                classNameExt="w-full bg-white"
              />
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div id={'today-appointments-wrapper'} className="flex flex-col mt-8 w-full">
      <div className="label flex flex-row items-center gap-3 mb-3">
        <span className="font-semibold text-lg">Today's Appointments</span>
        {appointmentsResponse && (
          <span className="requests-count text-sm px-2.5 py-0.5 bg-accent font-semibold text-white rounded-lg">
            {appointmentsResponse.totalCount}
          </span>
        )}
        <LinkText
          className="view-all !text-base-content !text-xs !pl-0 hover:opacity-75"
          onClick={() => {
            runFTrack({
              event: 'Click View All Scheduled Appointments',
              workflow: AppointmentWorkflow,
              action: ClickTrackingAction,
              context: 'todaysAppointments',
              componentId: 'viewAllScheduledAppointmentsButton',
              extraProps: {
                count: appointmentsResponse?.totalCount,
              },
            });
            navigate(`/appointments?page=1&category=Scheduled`);
          }}
        >
          <div className="flex gap-2 items-center">
            <div>View All Scheduled Appointments</div>
            <BoxArrowRight />
          </div>
        </LinkText>
      </div>
      {renderTodaysAppointmentsData()}
    </div>
  );
};

export default TodaysAppointments;
