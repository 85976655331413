import { useRef, useState } from 'react';
import { useMutation } from 'react-query';

import { TreatmentPlanWorkflow } from '../../amplitude';
import { QueryContext } from '../../API/context';
import { createItem, DetailedTreatmentPlan } from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import { Patient } from '../../ServiceContext/patients';
import Alert, { AlertData, errorAlert, successAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import ClearButton from '../../shared/ClearButton';

type Props = {
  authProvider: AuthProvider;
  onCloseClick: () => void;
  patient: Patient;
  treatmentPlanId: string;
  queryContext: QueryContext;
};

const AddRowForm: React.FC<Props> = ({
  authProvider,
  onCloseClick,
  patient,
  treatmentPlanId,
  queryContext,
}) => {
  const cdtCodeClearButtonRef = useRef<SVGSVGElement>(null);
  const visitClearButtonRef = useRef<SVGSVGElement>(null);
  const descriptionClearButtonRef = useRef<SVGSVGElement>(null);
  const notesClearButtonRef = useRef<SVGSVGElement>(null);

  const [cdtCode, setCdtCode] = useState('');
  const [description, setDescription] = useState('');
  const [visit, setVisit] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [createdItemId, setCreatedItemId] = useState<string | null>(null);

  const mutation = useMutation(createItem, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      setAlert(
        successAlert(
          `You have successfully added a new item to the treatment plan for ${patient.firstName} ${patient.lastName}.`
        )
      );

      const currentData = queryContext.client.getQueryData<DetailedTreatmentPlan>(queryContext.key);

      if (currentData) {
        currentData.items = [...currentData.items, data];

        queryContext.client.setQueryData(queryContext.key, currentData);
      }

      setCreatedItemId(data.id);
    },
    onError: (error) => {
      setIsLoading(false);
      setAlert(errorAlert(error as any));
    },
  });

  const onCdtCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCdtCode(e.target.value);
  };

  const onVisitInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVisit(e.target.value);
  };

  const onDescriptionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const onNotesInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAdditionalNotes(e.target.value);
  };

  const onCdtCodeClearButtonClick = () => {
    setCdtCode('');
  };

  const onDescriptionClearButtonClick = () => {
    setDescription('');
  };

  const onNotesClearButtonClick = () => {
    setAdditionalNotes('');
  };

  const onVisitClearButtonClick = () => {
    setVisit('');
  };

  const onAddItemButtonClick = (e: React.FormEvent) => {
    e.preventDefault();

    const itemData = {
      authProvider,
      treatmentPlanId,
      cdtCode,
      visit,
      description,
      additionalNotes: `${additionalNotes}`,
    };
    mutation.mutate(itemData);
  };

  return (
    <form className="add-row-form flex flex-col gap-3 rounded-lg text-secondary text-left py-8">
      {alert && <Alert {...alert} />}
      {!createdItemId && (
        <>
          <div className="input-group flex flex-col items-start mt-2">
            <label htmlFor="cdt-code-input" className="cdt-code-label font-semibold mb-1">
              CDT Code (required)
            </label>
            <div className="cdt-code-input relative">
              <input
                title="CDT Code (required)"
                onChange={onCdtCodeChange}
                value={cdtCode}
                className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
                autoComplete="off"
                disabled={isLoading}
              />
              <div className="cdt-code-clear-button absolute top-[1px] right-[2px]">
                <ClearButton
                  ref={cdtCodeClearButtonRef}
                  noDisplay={!cdtCode}
                  onClick={onCdtCodeClearButtonClick}
                />
              </div>
            </div>
          </div>
          <div className="input-group flex flex-col items-start">
            <label htmlFor="description-input" className="description-label font-semibold mb-1">
              Description (required)
            </label>
            <div className="description-input relative">
              <input
                title="Description (required)"
                onChange={onDescriptionInputChange}
                value={description}
                className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
                autoComplete="off"
                disabled={isLoading}
              />
              <div className="description-clear-button absolute top-[1px] right-[2px]">
                <ClearButton
                  ref={descriptionClearButtonRef}
                  noDisplay={!description}
                  onClick={onDescriptionClearButtonClick}
                />
              </div>
            </div>
          </div>
          <div className="input-group flex flex-col items-start">
            <label htmlFor="notes-input" className="notes-label font-semibold mb-1">
              Additional Notes (required)
            </label>
            <div className="notes-input relative">
              <input
                title="Additional Notes"
                onChange={onNotesInputChange}
                value={additionalNotes}
                className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
                autoComplete="off"
                placeholder="Enter specific areas of mouth, teeth codes, etc."
                disabled={isLoading}
              />
              <div className="notes-clear-button absolute top-[1px] right-[2px]">
                <ClearButton
                  ref={notesClearButtonRef}
                  noDisplay={!additionalNotes}
                  onClick={onNotesClearButtonClick}
                />
              </div>
            </div>
          </div>
          <div className="input-group flex flex-col items-start">
            <label htmlFor="visit-input" className="visit-label font-semibold mb-1">
              Visit
            </label>
            <div className="visit-input relative">
              <input
                title="Visit"
                onChange={onVisitInputChange}
                value={visit}
                className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
                autoComplete="off"
                disabled={isLoading}
              />
              <div className="visit-clear-button absolute top-[1px] right-[2px]">
                <ClearButton
                  ref={visitClearButtonRef}
                  noDisplay={!visit}
                  onClick={onVisitClearButtonClick}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <div className="action-buttons justify-center flex flex-row gap-4 mt-8">
        {!createdItemId && (
          <Button
            onClick={onAddItemButtonClick}
            loading={isLoading}
            omitBorder
            disabled={!cdtCode || !description || !additionalNotes}
            workflow={TreatmentPlanWorkflow}
            trackingLabel="Add Item Button"
            context="addRowForm"
          >
            Add Item
          </Button>
        )}
        <Button
          onClick={onCloseClick}
          noFill
          disabled={isLoading}
          workflow={TreatmentPlanWorkflow}
          trackingLabel="Close Add Item Button"
          context="addRowForm"
        >
          Close
        </Button>
      </div>
    </form>
  );
};

export default AddRowForm;
