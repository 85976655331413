import { DateTime } from 'luxon';
import { UseMutateFunction } from 'react-query';

import { AppointmentWorkflow } from '../../../amplitude';
import { ErrorResponse } from '../../../API/response';
import { Appointment } from '../../../ServiceContext/appointments';
import Button from '../../../shared/Button/Button';
import LoadingSpinner from '../../../shared/LoadingSpinner';

export default function AcknowledgeButton({
  appointment,
  disabled,
  isLoading,
  onMarkAppointmentAcknowledged,
}: {
  appointment: Appointment | null;
  disabled?: boolean;
  isLoading: boolean;
  onMarkAppointmentAcknowledged: UseMutateFunction<
    Appointment | ErrorResponse,
    ErrorResponse,
    string,
    unknown
  >;
}) {
  if (!appointment) return null;

  const isInFuture = appointment.start > DateTime.local().toUTC().toString();

  if (!appointment.acknowledgedByDentistAt && appointment.status !== 'cancelled' && isInFuture) {
    return (
      <Button
        workflow={AppointmentWorkflow}
        trackingLabel="Accept Appointment Button"
        onClick={
          disabled || appointment?.id == null
            ? () => {}
            : () => onMarkAppointmentAcknowledged(appointment!.id)
        }
      >
        {isLoading ? (
          <div className="px-2">
            <LoadingSpinner size="sm" />
          </div>
        ) : (
          'Accept'
        )}
      </Button>
    );
  } else {
    return null;
  }
}
