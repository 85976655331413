import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler } from 'react';

import { BookingsAndCreditsWorkflow } from '../../../amplitude';
import { Booking, BookingChargeState } from '../../../API/bookingsAndCredits';
import Button from '../../../shared/Button/Button';

type Props = {
  bookingCharge: Booking['charge'];
  onClick: MouseEventHandler;
};
const RefundButton: React.FC<Props> = ({ bookingCharge, onClick }) => {
  if (!bookingCharge) {
    return <span>'---'</span>;
  }
  const isDisabledStyling = 'disabled:opacity-50';
  const isEnabledStyling = 'hover:opacity-[75%]';

  let isRefundDisabled: string | boolean = true;
  if (bookingCharge.state === BookingChargeState.PENDING) {
    isRefundDisabled = false;
  } else if (bookingCharge.state === BookingChargeState.REFUNDED) {
    return (
      <div className="flex flex-row gap-1 items-center">
        <FontAwesomeIcon icon={faRotateLeft} className={'text-base-content-inactive'} />
        <span className="font-bold underline text-xs text-base-content-inactive">Refunded</span>
      </div>
    );
  } else if (bookingCharge.state === BookingChargeState.SETTLED) {
    isRefundDisabled = 'Cannot refund a settled charge';
  } else if (bookingCharge.state === BookingChargeState.CANCELLED) {
    isRefundDisabled = 'Cannot refund a canceled charge';
  } else if (bookingCharge.state === BookingChargeState.VOID) {
    isRefundDisabled = 'Cannot refund a void charge';
  }

  return (
    <Button
      className={`text-white text-xs font-semibold bg-primary ${
        isRefundDisabled ? isDisabledStyling : isEnabledStyling
      }`}
      onClick={onClick}
      workflow={BookingsAndCreditsWorkflow}
      trackingLabel="Refund Button"
      disabled={isRefundDisabled}
    >
      Refund
    </Button>
  );
};

export default RefundButton;
