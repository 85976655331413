import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getPatient } from '../API/patients';
import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { Patient } from '../ServiceContext/patients';
import StandardizedLoadingPage from '../shared/StandardizedLoadingPage';

type ProvidePatientChildProps = {
  authProvider?: AuthProvider;
  patient: Patient;
};

type ProvidePatientProps = {
  authProvider: AuthProvider;
  children: React.FC<ProvidePatientChildProps>;
};

const ProvidePatient: React.FC<ProvidePatientProps> = ({ authProvider, children }) => {
  const { patientId } = useParams<{ patientId: string }>();

  const getPatientKey = ['getPatient', patientId];
  const {
    isLoading: isLoadingPatient,
    error: fetchPatientError,
    data: patient,
  } = useQuery<Patient | null, ErrorResponse>(
    getPatientKey,
    () => {
      if (!patientId) {
        return null;
      }
      return getPatient({ authProvider, patientId });
    },
    {
      enabled: Boolean(patientId),
      refetchOnWindowFocus: false,
    }
  );

  if (isLoadingPatient || fetchPatientError) {
    return <StandardizedLoadingPage isLoading={isLoadingPatient} error={fetchPatientError} />;
  }

  if (!patient) {
    return (
      <StandardizedLoadingPage
        isLoading={false}
        error={{
          statusCode: 500,
          errorResponse: `No patient found with id ${patientId}`,
        }}
      />
    );
  }

  return children({
    authProvider,
    patient,
  });
};

export default ProvidePatient;
