import { DateTime } from 'luxon';

import { reportErrorToSentry } from '../../ServiceContext/error';
import { Timestamp } from '../../ServiceContext/shared';
import { digitsString } from '../strings';

export function toLocaleDateTimeString(
  dateArg: Timestamp | DateTime,
  format: Intl.DateTimeFormatOptions,
  timezone?: string
) {
  let dateArgAsDayJs: DateTime;
  if (typeof dateArg === 'string') {
    dateArgAsDayJs = DateTime.fromISO(dateArg);
  } else {
    dateArgAsDayJs = dateArg;
  }
  if (timezone) {
    dateArgAsDayJs = dateArgAsDayJs.setZone(timezone);
  }
  return dateArgAsDayJs.toLocaleString(format);
}

export function toShortDateTimeString(dateArg: Timestamp | DateTime, timezone?: string) {
  let dateArgAsDayJs: DateTime;
  if (typeof dateArg === 'string') {
    dateArgAsDayJs = DateTime.fromISO(dateArg);
  } else {
    dateArgAsDayJs = dateArg;
  }
  if (timezone) {
    dateArgAsDayJs = dateArgAsDayJs.setZone(timezone);
  }
  return dateArgAsDayJs.toFormat('LLL d, yyyy, h:mma');
}

export function toShortTimeString(dateArg: Timestamp | DateTime, timezone?: string) {
  let dateArgAsDayJs: DateTime;
  if (typeof dateArg === 'string') {
    dateArgAsDayJs = DateTime.fromISO(dateArg);
  } else {
    dateArgAsDayJs = dateArg;
  }
  if (timezone) {
    dateArgAsDayJs = dateArgAsDayJs.setZone(timezone);
  }
  return dateArgAsDayJs.toFormat('h:mma');
}

export function toShortDateString(dateArg: Timestamp | DateTime, timezone?: string) {
  let dateArgAsDayJs: DateTime;
  if (typeof dateArg === 'string') {
    dateArgAsDayJs = DateTime.fromISO(dateArg);
  } else {
    dateArgAsDayJs = dateArg;
  }
  if (timezone) {
    dateArgAsDayJs = dateArgAsDayJs.setZone(timezone);
  }
  if (dateArgAsDayJs) {
    return dateArgAsDayJs.toFormat('LLL d, yyyy');
  }

  reportErrorToSentry({
    summary: `Invalid DateTime value: ${dateArg} (timezone: ${timezone})`,
  });
  return 'Invalid DateTime';
}

export function toLongDateString(dateArg: Timestamp | DateTime, timezone?: string) {
  let dateArgAsDayJs: DateTime;
  if (typeof dateArg === 'string') {
    dateArgAsDayJs = DateTime.fromISO(dateArg);
  } else {
    dateArgAsDayJs = dateArg;
  }
  dateArgAsDayJs = dateArgAsDayJs.setZone(timezone);
  return dateArgAsDayJs.toLocaleString(DateTime.DATETIME_MED);
}

export function isInPast(dateStr: Timestamp, timezone: string) {
  let timezonedDate = DateTime.fromISO(dateStr);
  timezonedDate = timezonedDate.setZone(timezone);
  let checkTime = DateTime.now();
  checkTime = checkTime.setZone(timezone);
  return timezonedDate < checkTime;
}

export function isInFuture(dateStr: Timestamp, timezone: string, bufferInSeconds: number = 0) {
  let timezonedDate = DateTime.fromISO(dateStr);
  timezonedDate = timezonedDate.setZone(timezone);
  if (bufferInSeconds) {
    timezonedDate = timezonedDate.plus({ seconds: bufferInSeconds });
  }
  let checkTime = DateTime.now();
  checkTime = checkTime.setZone(timezone);
  return timezonedDate > checkTime;
}

export const toApiDateFormat = (d: DateTime) => {
  return `${d.toISODate()}`;
};

export const toApiDateTimeFormat = (d: DateTime) => {
  return `${d.toISO()}`;
};

export const dateStringWithoutTimezone = (d: Date) => {
  const month = digitsString(d.getMonth() + 1, 2);
  const date = digitsString(d.getDate(), 2);
  const hours = digitsString(d.getHours(), 2);
  const minutes = digitsString(d.getMinutes(), 2);
  return `${d.getFullYear()}-${month}-${date}T${hours}:${minutes}`;
};
