import React from 'react';
import Modal from 'react-modal';

import { Tracking } from '../../amplitude';
import { TreatmentPlan } from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import useLockBodyScroll from '../../shared/Modal/useLockBodyScroll';
import UploadTreatmentPlanForm from './UploadTreatmentPlanForm';

type Props = {
  authProvider: AuthProvider;
  patientId: string;
  dentistId?: string;
  appointmentId?: string;
  onTreatmentPlanUploaded: (treatmentPlan: TreatmentPlan) => void;
  onCancel: () => void;
} & Tracking;

const UploadTreatmentPlanModal: React.FC<Props> = ({
  authProvider,
  patientId,
  dentistId,
  appointmentId,
  onTreatmentPlanUploaded,
  onCancel,
  ...tracking
}) => {
  useLockBodyScroll(true);

  return (
    <Modal
      isOpen
      onRequestClose={onCancel}
      className="outline-none mx-auto my-10 bg-white rounded-lg p-8 w-11/12 md:w-3/4 lg:w-1/2 max-h-4/5 overflow-auto shadow-xl"
      overlayClassName="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
    >
      <div className="space-y-4">
        <UploadTreatmentPlanForm
          authProvider={authProvider}
          patientId={patientId}
          dentistId={dentistId}
          appointmentId={appointmentId}
          onTreatmentPlanUploaded={onTreatmentPlanUploaded}
          secondaryActionText="Cancel"
          onSecondaryClick={onCancel}
          doAnalysis={true}
          notifyFlossyTeam={true}
        />
      </div>
    </Modal>
  );
};

export default UploadTreatmentPlanModal;
