import cx from 'classnames';

type RadioButtonListProps = {
  id?: string;
  name: string;
  options: { key: string; displayValue: string }[];
  onButtonSelected: (s: string) => void;
  selectedKey: string;
  orientation: 'vertical' | 'horizontal';
  optionBackground?: boolean;
};

const RadioButtonList: React.FC<RadioButtonListProps> = ({
  id,
  name,
  options,
  onButtonSelected,
  selectedKey,
  orientation,
  optionBackground,
}) => {
  const className = cx({
    'list-group': true,
    'text-left': true,
    flex: true,
    'flex-col': orientation === 'vertical',
    'flex-row': orientation === 'horizontal',
    'gap-2': true,
  });

  let optionListClassname = 'border-0 mx-2 ';
  if (optionBackground) {
    optionListClassname += 'bg-[#f9f9f9]';
  }

  return (
    <ul id={id} className={className}>
      {options.map((o, idx) => {
        const isChecked = o.key === selectedKey;
        let label = o.displayValue;
        return (
          <li key={`${o}-${idx}`} className={optionListClassname}>
            <input
              className="form-check-input me-1"
              type="radio"
              name={name}
              value={o.key}
              aria-label={o.displayValue}
              onChange={(e) => {
                onButtonSelected(e.target.value);
              }}
              checked={isChecked}
            />
            <label>{label}</label>
          </li>
        );
      })}
    </ul>
  );
};

export default RadioButtonList;
