import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getAppointment } from '../API/appointments';
import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { Appointment } from '../ServiceContext/appointments';
import StandardizedLoadingPage from '../shared/StandardizedLoadingPage';

type ProvideAppointmentChildProps = {
  authProvider?: AuthProvider;
  appointment: Appointment;
  refetchAppointment: () => void;
};

type ProvideAppointmentProps = {
  authProvider: AuthProvider;
  children: React.FC<ProvideAppointmentChildProps>;
};

const ProvideAppointment: React.FC<ProvideAppointmentProps> = ({ authProvider, children }) => {
  const { appointmentId } = useParams<{ appointmentId: string }>();

  const getAppointmentKey = ['getAppointment', appointmentId];
  const {
    isLoading: isLoadingAppointment,
    error: fetchAppointmentError,
    data: appointment,
    refetch,
  } = useQuery<Appointment | null, ErrorResponse>(
    getAppointmentKey,
    () => {
      if (!appointmentId) {
        return null;
      }
      return getAppointment({ authProvider, appointmentId });
    },
    {
      enabled: Boolean(appointmentId),
      refetchOnWindowFocus: false,
    }
  );

  if (isLoadingAppointment || fetchAppointmentError) {
    return (
      <StandardizedLoadingPage isLoading={isLoadingAppointment} error={fetchAppointmentError} />
    );
  }

  if (!appointment) {
    return (
      <StandardizedLoadingPage
        isLoading={false}
        error={{
          statusCode: 500,
          errorResponse: `No appointment found with id ${appointmentId}`,
        }}
      />
    );
  }

  return children({
    authProvider,
    appointment,
    refetchAppointment: refetch,
  });
};

export default ProvideAppointment;
