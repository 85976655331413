import { AuthProvider } from '../Authentication/Authentication';
import {
  authenticatedPostToRedirect,
  RedirectResponse,
  unauthenticatedPostToRedirect,
} from '../ServiceContext/service';
import { apiUrl } from '../ServiceContext/shared';
import { handleAPIResponse } from './response';

export type CreateCheckoutSessionInput =
  | CreateCheckoutSessionInputSingleMonth
  | CreateCheckoutSessionInputBalanceDue
  | CreateCheckoutSessionInputTotalBalance;

export type CreateCheckoutSessionInputSingleMonth = {
  type: 'single-month';
  month: number; // 1 = January, 2 = February, ..., 12 = December
  year: number;
};

export type CreateCheckoutSessionInputBalanceDue = {
  type: 'balance-due';
  balanceAsOfDate: string;
};

export type CreateCheckoutSessionInputTotalBalance = {
  type: 'total-balance';
  balanceAsOfDate: string;
};

export async function createCheckoutSession({
  authProvider,
  practiceId,
  input,
}: {
  authProvider: AuthProvider;
  practiceId: string;
  input: CreateCheckoutSessionInput;
}): Promise<RedirectResponse> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return await handleAPIResponse(
    authenticatedPostToRedirect(
      authProvider,
      apiUrl(`/practices/${practiceId}/checkout-sessions`),
      input
    )
  );
}

export async function createCheckoutSessionUnauthenticated({
  paymentLinkId,
}: {
  paymentLinkId: string;
}): Promise<RedirectResponse> {
  return await handleAPIResponse(
    unauthenticatedPostToRedirect(
      apiUrl(`/practices/bookings-and-credits-payment-links/${paymentLinkId}/checkout-sessions`),
      {}
    )
  );
}
