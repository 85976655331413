import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { AreaOfMouthValue } from './invoices';
import { authenticatedGet } from './service';
import { apiUrl } from './shared';

export interface Procedure {
  description: string;
  cdt: string;
  fee: number;
  specialty: string;
  oralCavityAreaEntire?: boolean | null;
  oralCavityAreaArch?: boolean | null;
  oralCavityAreaQuadrant?: boolean | null;
  toothAnatomyAllowSingle?: boolean | null;
  toothAnatomyAllowRange?: boolean | null;
  toothAnatomyAllowSurface?: boolean | null;
}

export interface ProcedureWithExtras {
  procedure: Procedure;
  adjustment?: number;
  areaOfMouthValue?: AreaOfMouthValue;
  toothNumberCode?: string;
  toothSurfaceCode?: string;
  additionalNotes?: string;
}

export interface ProceduresService {
  getProcedures: (
    query: string,
    dentistId: string,
    signal?: AbortSignal
  ) => Promise<Procedure[] | ErrorResponse>;
}

const getProcedures = (authProvider: AuthProvider) => {
  return async (query: string, dentistId: string, signal?: AbortSignal) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedGet<Procedure[]>(
      authProvider,
      apiUrl(`/dentists/procedures?query=${encodeURIComponent(query)}&dentistId=${dentistId}`),
      signal
    );
  };
};

const makeService = (auth: AuthProvider) => {
  return {
    getProcedures: getProcedures(auth),
  };
};

export default makeService;
