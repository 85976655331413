import { DetailedTreatmentPlan } from '../../API/treatmentPlans';
import { Tooth } from '../../HomePage/components/Icons';
import PatientProfileButton from '../PatientDirectory/components/PatientProfileButton';

export default function TreatmentPlanPatientSection({
  treatmentPlan,
}: {
  treatmentPlan: DetailedTreatmentPlan;
}) {
  const { patient, dentist } = treatmentPlan;

  return (
    <div className="flex flex-column text-left w-1/3 gap-1">
      <h5 className="title text-left font-bold text-2xl">
        {patient.firstName} {patient.lastName}
      </h5>
      {dentist && (
        <div className="mb-2 text-base flex items-center gap-2">
          <Tooth />
          <span className="font-bold">Dentist:</span>
          <div>
            {dentist.firstName} {dentist.lastName}
          </div>
        </div>
      )}
      <div>
        <PatientProfileButton userId={patient.id} />
      </div>
    </div>
  );
}
