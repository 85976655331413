const ReferralsRedeemedTooltip = () => {
  return (
    <div
      className={
        'absolute z-20 flex flex-col gap-2 text-left top-12 left-6 mt-2 p-4 bg-white rounded-md shadow-lg w-64 -translate-x-[40px]'
      }
    >
      <span className={'text-sm font-semibold'}>Referrals Redeemed</span>
      <span className={'text-xs'}>
        Referrals are only considered redeemed when a patient signs up with Flossy and
        <span className={'font-semibold'}> books their first appointment</span>.
      </span>
    </div>
  );
};

export default ReferralsRedeemedTooltip;
