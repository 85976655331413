import { PaymentAdded, PaymentMissing } from '../../HomePage/components/Icons';
import { Appointment } from '../../ServiceContext/appointments';
import { toShortDateString, toShortTimeString } from '../../shared/dates/dates';
import { ColumnDef } from '../../shared/Table/Table';

const commonAppointmentColumns: ColumnDef<Appointment>[] = [
  {
    columnKey: 'date',
    title: 'Date',
    widthProportion: 0.5,
    render: (record: Appointment) => {
      return (
        <div className="datetime-column flex flex-col gap-1 text-base-content">
          <span>{toShortDateString(record.start, record.timeZone)}</span>
          <span className="time text-xs">{toShortTimeString(record.start, record.timeZone)}</span>
        </div>
      );
    },
  },
  {
    columnKey: 'patient',
    title: 'Patient',
    widthProportion: 0.6,
    colClassName: (record: Appointment) => {
      if (record.user && !!record.user.deletedEmail) {
        return 'disabled';
      }

      return '';
    },
    render: (record: Appointment) => {
      if (!record.user) {
        return '';
      }
      return `${record.user.firstName} ${record.user.lastName}`;
    },
  },
  {
    columnKey: 'services',
    title: 'Services',
    widthProportion: 0.8,
    render: (record: Appointment) => {
      if (record.services) {
        return record.appointmentReason;
      }

      return '---';
    },
  },
  {
    columnKey: 'dentist',
    title: 'Dentist',
    widthProportion: 0.8,
    render: (record: Appointment) => {
      if (!record.dentist) {
        return '';
      }
      return `${record.dentist.firstName} ${record.dentist.lastName}`;
    },
  },
  {
    columnKey: 'payment',
    title: 'Payment',
    widthProportion: 0.7,
    render: (record: Appointment) => {
      return (
        <div className="flex space-x-1">
          <div> {record.paymentMethod ? <PaymentAdded /> : <PaymentMissing />}</div>
          {record.patientInsuranceId ? (
            <span>Insurance</span>
          ) : (
            <span className="text-base-content">Flossy</span>
          )}
        </div>
      );
    },
  },
];

export default commonAppointmentColumns;
