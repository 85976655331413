import { getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, MessagePayload, onMessage } from 'firebase/messaging';

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);
firebaseAuth.tenantId = process.env.REACT_APP_FIREBASE_TENANT_ID || '';
if (isUsingEmulation()) {
  if (!process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL) {
    console.log('ERROR: Missing REACT_APP_FIREBASE_AUTH_EMULATOR_URL');
  }
  connectAuthEmulator(firebaseAuth, process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL!);
}

export async function firebaseAnalytics() {
  if (isUsingEmulation()) {
    return null;
  }

  const analyticsSupported = await isSupported();
  if (analyticsSupported) {
    return getAnalytics(firebaseApp);
  }
  return null;
}

export const firebaseMessaging = isUsingEmulation() ? null : getMessaging(firebaseApp);

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (!firebaseMessaging) {
      return;
    }
    onMessage(firebaseMessaging, (payload) => {
      resolve(payload);
    });
  });

export const firebaseFirestore = getFirestore(firebaseApp);
if (isUsingEmulation()) {
  if (!process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL) {
    console.log('ERROR: Missing REACT_APP_FIREBASE_AUTH_EMULATOR_URL');
  }
  const [host, port] = process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_URL!.split(':');
  connectFirestoreEmulator(firebaseFirestore, host, parseInt(port));
}

export async function requestForToken({
  onFetchedTokenCallback,
  onMessageCallback,
}: {
  onFetchedTokenCallback: (fetchedToken: string) => void;
  onMessageCallback: (payload: MessagePayload) => void;
}) {
  if (!firebaseMessaging) {
    return '';
  }
  return getToken(firebaseMessaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_PUBLIC_KEY,
  })
    .then((token) => {
      if (token) {
        onFetchedTokenCallback(token);
        onMessage(firebaseMessaging, onMessageCallback);
      } else {
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
}

export function isUsingEmulation() {
  return process.env.REACT_APP_USE_FIREBASE_EMULATORS === 'true';
}
