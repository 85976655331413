import React, { Fragment } from 'react';

import { extractInvoicingComponents } from '../../Appointments/Invoicing/utilities';
import { Invoice, InvoiceItem } from '../../ServiceContext/invoices';
import { stringifyMoney } from '../../shared/money/stringifyMoney';
import { distributeCents } from '../../shared/number/number';

type OldPricingDataProps = {
  procedureInvoiceItems: InvoiceItem[];
  invoice: Invoice;
  alternateRowStyle?: boolean;
  patientHasInsurance: boolean;
};

const getRowStyle = (index: number, alternateRowStyle: boolean | undefined) =>
  alternateRowStyle
    ? index % 2 === 0
      ? 'bg-white'
      : 'bg-blue-50 bg-opacity-75'
    : 'border-t border-rule';

const PriceCell: React.FC<{ value: number; patientHasInsurance: boolean }> = ({
  value,
  patientHasInsurance,
}) => (
  <td className="text-right py-3 pr-6">
    {patientHasInsurance ? '---' : stringifyMoney(value, { includeCommas: true })}
  </td>
);

const OldPricingData: React.FC<OldPricingDataProps> = ({
  invoice,
  procedureInvoiceItems,
  alternateRowStyle,
  patientHasInsurance,
}) => {
  const { flossyFee } = extractInvoicingComponents({ invoice });
  const itemsPriceAdjustments: number[] = distributeCents(
    flossyFee,
    invoice.invoiceItems.filter((i) => i.type === 'procedure').length
  );

  return (
    <Fragment>
      {procedureInvoiceItems.map((item, index) => (
        <tr key={index} className={`text-sm ${getRowStyle(index, alternateRowStyle)}`}>
          <td className="text-left py-3 pl-6 font-semibold">{item.code}</td>
          <td className="text-left py-3 pl-6">{item.text}</td>
          <PriceCell
            value={item.amount + itemsPriceAdjustments[index]}
            patientHasInsurance={patientHasInsurance}
          />
          <PriceCell
            value={itemsPriceAdjustments[index]}
            patientHasInsurance={patientHasInsurance}
          />
          <PriceCell value={item.amount} patientHasInsurance={patientHasInsurance} />
        </tr>
      ))}
    </Fragment>
  );
};

export default OldPricingData;
