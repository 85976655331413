import { Fragment } from 'react';

import { extractInvoicingComponents } from '../Appointments/Invoicing/utilities';
import { Appointment } from '../ServiceContext/appointments';
import { Invoice, LegacyPaymentSummary, PaymentSplitSummary } from '../ServiceContext/invoices';
import { isNumber } from '../shared/math/math';
import { stringifyMoney } from '../shared/money/stringifyMoney';
import {
  inferPricingSystemVersionFromInvoice,
  PricingSystemVersion,
} from '../shared/pricingSystem';
import PaymentMethods from './PaymentMethods';

type Props = {
  invoice: Invoice;
  appointment: Appointment;
  paymentSplits: PaymentSplitSummary[];
  legacyPayment: LegacyPaymentSummary | null;
  alternateRowStyle?: boolean;
  anchorPricingTotal: number;
  patientPricingTotal: number;
  usingInsurance?: boolean;
};
const InvoiceItemsTable: React.FC<Props> = ({
  appointment,
  paymentSplits,
  legacyPayment,
  invoice,
  alternateRowStyle,
  anchorPricingTotal,
  patientPricingTotal,
  usingInsurance,
}) => {
  const { promoAmount, refundAmount, savingsAmount } = extractInvoicingComponents({ invoice });

  const positiveSavings = savingsAmount > 0;

  const invoiceVersion = inferPricingSystemVersionFromInvoice(invoice);

  const isLegacyPricing = invoiceVersion === PricingSystemVersion.V1;

  const procedureInvoiceItems =
    invoice.invoiceItems.filter((item) => item.type === 'procedure') || [];

  const renderData = () => {
    return procedureInvoiceItems.map((item, index) => {
      const { code, text, additionalNotes } = item;

      let retailPrice: number | null = item.anchorPrice || null;
      let patientPrice = isNumber(item.patientPrice) ? item.patientPrice : item.amount;

      return (
        <Fragment key={index}>
          <tr
            className={`text-sm ${
              alternateRowStyle
                ? index % 2 === 0
                  ? 'bg-white'
                  : 'bg-primary-subtle bg-opacity-50'
                : 'border-t border-rule'
            }`}
          >
            <td className="text-left pt-3 pl-6 font-semibold">{code}</td>
            <td className="text-left pl-6" colSpan={positiveSavings ? 1 : 2}>
              {text}
            </td>
            {isNumber(retailPrice) && positiveSavings && (
              <td className="text-right pr-6 text-base-content opacity-60 line-through">
                {stringifyMoney(retailPrice, { includeCommas: true })}
              </td>
            )}
            <td className={`text-right pr-6 ${usingInsurance ? 'line-through' : ''}`}>
              {isNumber(patientPrice)
                ? stringifyMoney(patientPrice, { includeCommas: true })
                : '---'}
            </td>
          </tr>
          {additionalNotes && (
            <tr
              className={`text-xs ${
                alternateRowStyle
                  ? index % 2 === 0
                    ? 'bg-white'
                    : 'bg-primary-subtle bg-opacity-50'
                  : 'border-t border-rule'
              }`}
            >
              <td></td>
              <td className={'text-left pl-6 pb-4'}>{additionalNotes}</td>
              <td></td>
              <td></td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  const shouldRenderPaymentMethods = !appointment.invoice?.isDraft && !usingInsurance;

  return (
    <table id={'invoice-items-table'} className={'w-full'}>
      <thead>
        <tr className={'border-b border-rule'}>
          <th className={'text-left py-3 pl-6'}>CDT Code</th>
          <th className={'text-left py-3 pl-6'} colSpan={positiveSavings ? 1 : 2}>
            Procedure
          </th>
          {!isLegacyPricing && positiveSavings && (
            <th className="text-right opacity-60 py-3 pr-6">Retail Price</th>
          )}
          <th className="text-right py-3 pr-6">Flossy Price</th>
        </tr>
      </thead>
      <tbody>
        {renderData()}
        {isNumber(promoAmount) && promoAmount > 0 && (
          <tr className="text-sm border-t border-rule">
            <td className="text-left py-3 pl-6">Promo Discount</td>
            <td></td>
            <td className="text-right py-3 pr-6 text-base-content">
              {stringifyMoney(promoAmount, { includeCommas: true })}
            </td>
          </tr>
        )}
        {isNumber(refundAmount) && refundAmount > 0 && (
          <tr className="text-sm border-t border-rule">
            <td className="text-left py-3 pl-6">Refund</td>
            <td></td>
            <td className="text-right py-3 pr-6 text-base-content">
              {stringifyMoney(refundAmount, { includeCommas: true })}
            </td>
          </tr>
        )}
        {shouldRenderPaymentMethods && (
          <tr className={'text-sm border-t border-rule'}>
            <td className={'text-left font-bold pl-6 py-2'}>Patient Payment Method</td>
          </tr>
        )}
        {shouldRenderPaymentMethods && (
          <PaymentMethods legacyPayment={legacyPayment} paymentSplits={paymentSplits} />
        )}
        <tr id={'total-row'} className="font-bold text-xl border-t border-t-1 border-rule">
          <td id={'total-amount-label'} className="text-left py-4 pl-6">
            Total Amount
          </td>
          <td colSpan={positiveSavings ? 1 : 2}></td>
          {!isLegacyPricing && isNumber(anchorPricingTotal) && positiveSavings && (
            <td
              id={'total-anchor'}
              className={`text-right py-3 pr-6 text-base-content opacity-60 line-through`}
            >
              {stringifyMoney(anchorPricingTotal, { includeCommas: true })}
            </td>
          )}
          <td
            id={'total-flossy-price'}
            className={`text-right py-3 pr-6 ${usingInsurance ? 'line-through' : ''}`}
          >
            {stringifyMoney(patientPricingTotal, { includeCommas: true })}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InvoiceItemsTable;
