import React from 'react';

import { ErrorResponse, ErrorResponseBody, isErrorResponse } from '../API/response';
import Bulletin from './Bulletin';
import { extractApiErrorMessage } from './errors/errorMessage';

export interface AlertData {
  type: 'success' | 'warning' | 'error';
  content: React.ReactNode;
}

export function errorAlert(error: ErrorResponse | ErrorResponseBody | string) {
  let errorString: ErrorResponseBody;
  if (isErrorResponse(error)) {
    errorString = error.errorResponse;
  } else {
    errorString = error;
  }
  return { content: extractApiErrorMessage(errorString), type: 'error' } as AlertData;
}

export function successAlert(content: React.ReactNode) {
  return { content, type: 'success' } as AlertData;
}

export function warningAlert(content: React.ReactNode) {
  return { content, type: 'warning' } as AlertData;
}

const Alert: React.FC<AlertData> = ({ type, content }) => {
  return (
    <div className="alert-container flex flex-col justify-center w-full items-center no-print">
      <Bulletin type={type}>{content}</Bulletin>
    </div>
  );
};

export default Alert;
