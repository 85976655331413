import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { Message, MessageType } from './messages';
import { authenticatedGet, authenticatedNoContentPut } from './service';
import { apiUrl } from './shared';

export type ManagerNotificationSetupExplanation = {
  registeredEvents: MessageType[];
  trackedDentists: string[];
  recentNotifications: Message[];
};

export interface ManagersService {
  getNotificationSetupExplanation: ({
    managerId,
  }: {
    managerId: string;
  }) => Promise<ManagerNotificationSetupExplanation | ErrorResponse>;
  updateManagerFcmClientToken: ({
    managerId,
    token,
  }: {
    managerId: string;
    token: string;
  }) => Promise<null | ErrorResponse>;
}

const getNotificationSetupExplanation = (authProvider: AuthProvider) => {
  return async ({ managerId }: { managerId: string }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }

    return authenticatedGet<ManagerNotificationSetupExplanation | ErrorResponse>(
      authProvider,
      apiUrl(`/managers/${managerId}/notification-explanation`)
    );
  };
};

const updateManagerFcmClientToken = (authProvider: AuthProvider) => {
  return async ({ token, managerId }: { token: string; managerId: string }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }

    return authenticatedNoContentPut(
      authProvider,
      apiUrl(`/managers/${managerId}/fcmClientToken`),
      {
        token,
      }
    );
  };
};

const makeService = (auth: AuthProvider) => {
  return {
    getNotificationSetupExplanation: getNotificationSetupExplanation(auth),
    updateManagerFcmClientToken: updateManagerFcmClientToken(auth),
  };
};

export default makeService;
