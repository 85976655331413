import React, { Fragment, useState } from 'react';

import { Tracking } from '../../../amplitude';
import {
  Booking,
  BookingCharge,
  BookingChargeCredit,
  BookingChargeState,
} from '../../../API/bookingsAndCredits';
import { AuthProvider } from '../../../Authentication/Authentication';
import { toShortDateString, toShortTimeString } from '../../../shared/dates/dates';
import { stringifyMoney } from '../../../shared/money/stringifyMoney';
import { capitalize } from '../../../shared/strings';
import Table, { ColumnDef } from '../../../shared/Table/Table';
import RefundButton from '../Refund/RefundButton';
import RefundChargeModal from '../Refund/RefundChargeModal';

type Props = {
  authProvider: AuthProvider;
  bookingData: Booking[];
  creditItems: BookingChargeCredit[];
  practiceId: string;
  setDateOrderByDirection?: (direction: 'ASC' | 'DESC') => void;
  hideSupportColumn?: boolean;
  onRefundCompleted?: (refundedCharge: BookingCharge) => void;
} & Tracking;

const BCTable: React.FC<Props> = ({
  authProvider,
  setDateOrderByDirection,
  hideSupportColumn,
  bookingData,
  creditItems,
  practiceId,
  onRefundCompleted,
  ...tracking
}) => {
  const [bookingChargeBeingRefunded, setBookingChargeBeingRefunded] =
    useState<BookingCharge | null>(null);

  const isCreditRedeemed = (booking: Booking) => {
    const { charge } = booking;
    if (!charge) {
      return false;
    }

    const isSettledByCredit = creditItems.some((item) => item.settlesChargeId === charge.id);

    return charge.state === BookingChargeState.SETTLED && isSettledByCredit;
  };

  const columns: ColumnDef<Booking>[] = [
    {
      columnKey: 'emptyColumn',
      title: '',
      widthProportion: 0.1,
      render: () => {
        return <></>;
      },
    },
    {
      columnKey: 'status',
      title: 'Status',
      widthProportion: 1,
      render: (record: Booking) => {
        if (!record.charge) {
          return '';
        }
        const chargeState = record.charge.state;

        let statusBackgroundColor = '';
        if (chargeState === BookingChargeState.PENDING) {
          statusBackgroundColor = 'bg-error-content';
        } else if (
          chargeState === BookingChargeState.SETTLED ||
          chargeState === BookingChargeState.REFUNDED
        ) {
          statusBackgroundColor = 'bg-primary';
        } else {
          statusBackgroundColor = 'bg-secondary-content';
        }

        return (
          <span
            className={`py-1 px-2.5 rounded-2xl text-white text-xs font-semibold ${statusBackgroundColor}`}
          >
            {capitalize(chargeState)}
          </span>
        );
      },
    },
    {
      columnKey: 'apptDate',
      title: 'Appt. Date',
      widthProportion: 1,
      render: (record: Booking) => {
        return (
          <div className="appointment-start-column flex flex-col">
            <span>
              {toShortDateString(record.appointmentStartTime, record.appointmentTimeZone)}
            </span>
            <span className="appointment-start-time text-xs mt-1">
              {toShortTimeString(record.appointmentStartTime, record.appointmentTimeZone)}
            </span>
          </div>
        );
      },
      sortableRemote: setDateOrderByDirection
        ? {
            ascending: () => {
              setDateOrderByDirection('ASC');
            },
            descending: () => {
              setDateOrderByDirection('DESC');
            },
          }
        : undefined,
    },
    {
      columnKey: 'patient',
      title: 'Patient',
      widthProportion: 1,
      render: (record: Booking) => {
        if (!record.patient) {
          return '';
        }
        return <span className="font-semibold">{record.patient.name}</span>;
      },
    },
    {
      columnKey: 'dentist',
      title: 'Dentist',
      widthProportion: 1,
      render: (record: Booking) => {
        return record.dentist.name;
      },
    },
    {
      columnKey: 'creditsRedeemed',
      title: 'Credits Redeemed',
      widthProportion: 1,
      render: (record: Booking) => {
        if (isCreditRedeemed(record)) {
          return <span className={'text-booking-charge-settled'}>Yes</span>;
        }
        return '---';
      },
    },
    {
      columnKey: 'amount',
      title: 'Amount',
      widthProportion: 0.7,
      render: (record: Booking) => {
        if (record.charge?.state === BookingChargeState.REFUNDED) {
          return <span className="text-green-800">--</span>;
        } else if (isCreditRedeemed(record)) {
          return (
            <div className={'flex flex-col'}>
              <span className={'text-base-content-inactive line-through'}>
                {record.charge && stringifyMoney(record.charge?.amount, { roundCents: true })}
              </span>
              <span>$0</span>
            </div>
          );
        }
        return (
          <span>
            {record.charge && stringifyMoney(record.charge?.amount, { roundCents: true })}
          </span>
        );
      },
    },
    {
      columnKey: 'refund',
      title: 'Refund',
      widthProportion: 1,
      render: (record: Booking) => {
        const charge = record.charge;
        if (!charge) {
          return '---';
        }
        return (
          <RefundButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setBookingChargeBeingRefunded(charge);
            }}
            bookingCharge={record.charge}
          />
        );
      },
    },
  ];

  if (hideSupportColumn || !authProvider.authUser?.user.isFlossyAdmin) {
    columns.pop();
  }

  return (
    <Fragment>
      <Table
        id="bookings-and-credits-table"
        columns={columns}
        data={bookingData}
        rowKeyGenerator={(record: Booking) => `${record.appointmentId}`}
        tableClassNameExt="bg-white"
      />
      {bookingChargeBeingRefunded && (
        <RefundChargeModal
          authProvider={authProvider}
          practiceId={practiceId}
          bookingCharge={bookingChargeBeingRefunded}
          onRefundCompleted={(refundedCharge) => {
            setBookingChargeBeingRefunded(refundedCharge);
            onRefundCompleted && onRefundCompleted(refundedCharge);
          }}
          onCancel={() => setBookingChargeBeingRefunded(null)}
          {...tracking}
        />
      )}
    </Fragment>
  );
};

export default BCTable;
