import React, { useCallback } from 'react';

import { DetailedTreatmentPlan } from '../../../API/treatmentPlans';
import { Address } from '../../../ServiceContext/shared';
import { getDentistAddress } from '../../../ServiceContext/user';
import { formatPhoneNumber } from '../../../shared/strings';

type Props = {
  treatmentPlan: DetailedTreatmentPlan;
};
const BasicInfo: React.FC<Props> = ({ treatmentPlan }) => {
  let dentistLocation: Address | null = null;
  const { dentist } = treatmentPlan;
  if (dentist) {
    dentistLocation = getDentistAddress(dentist);
  }

  const renderPracticeAddress = useCallback(() => {
    return (
      <>
        <div>{dentistLocation?.name}</div>
        <div>{dentistLocation?.address1}</div>
        <div>{`${dentistLocation?.city}, ${dentistLocation?.state} ${dentistLocation?.zip}`}</div>
        <div>{`${formatPhoneNumber(dentistLocation?.phone)}`}</div>
      </>
    );
  }, [dentistLocation]);

  return (
    <div className="flex w-full mt-16">
      <div className="flex flex-col w-2/3">
        <div className="text-base-content text-[14px] font-bold text-start">
          Treatment Plan for {treatmentPlan.patient.firstName} {treatmentPlan.patient.lastName}
          <br />
        </div>
        <div className="text-base-content text-[12px] font-normal text-start">
          Dentist: Dr. {treatmentPlan.dentist?.firstName} {treatmentPlan.dentist?.lastName}
        </div>
      </div>
      <div className="w-1/3 flex flex-col items-start text-[12px]">{renderPracticeAddress()}</div>
    </div>
  );
};

export default BasicInfo;
