import React from 'react';

import { stringifyMoney, StringifyMoneyFlags } from './stringifyMoney';

type Props = {
  cents: number;
  renderOptions?: StringifyMoneyFlags;
  size?: 'sm' | 'md' | 'lg';
};

const Money: React.FC<Props> = ({ cents, renderOptions, size = 'sm' }) => {
  let className = 'text-[#5a5a5a]';

  switch (size) {
    case 'sm':
      className += ' text-sm';
      break;
    case 'md':
      className += ' text-base';
      break;
    case 'lg':
      className += ' text-lg';
      break;
  }

  if (cents < 0) {
    className += ' text-error-content';
  }

  return <span className={className}>{stringifyMoney(cents, renderOptions)}</span>;
};

export default Money;
