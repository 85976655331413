import React from 'react';
import Modal from 'react-modal';

import { GeneralWorkflow } from '../amplitude';
import Button from './Button/Button';

type Props = {
  onRemindMeLaterClicked: () => void;
};

const RequireRefreshModal: React.FC<Props> = ({ onRemindMeLaterClicked }) => {
  return (
    <Modal
      isOpen
      contentLabel="New Dentist Portal Version Available"
      className="outline-none mx-auto my-10 bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 max-h-4/5 overflow-auto shadow-lg"
      overlayClassName="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center p-8">
        <svg
          className="w-16 h-16 mb-4 text-green-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span className="text-center mb-4 font-bold text-xl text-base-content">
          Portal Update {`V${process.env.REACT_APP_VERSION}`}
        </span>
        <p className="text-start text-base-content mb-6">
          Please click the "Refresh" button below to make sure your version is up to date. You can
          also perform a hard refresh of this page with the following key commands:
          <br />
          <br />
          Windows: Ctrl + F5
          <br />
          Mac: Command + Shift + R
        </p>
        <div className="flex space-x-4">
          <Button
            onClick={() => window.location.reload()}
            omitBorder
            className="px-6 py-2 bg-green-500 text-white rounded font-medium hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            workflow={GeneralWorkflow}
            trackingLabel="Refresh Portal Button"
          >
            Refresh
          </Button>
          <Button
            onClick={onRemindMeLaterClicked}
            omitBorder
            className="px-6 py-2 bg-blue-500 text-white rounded font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            workflow={GeneralWorkflow}
            trackingLabel="Remind Me Later Button"
          >
            Remind Me Later
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RequireRefreshModal;
