import { useContext } from 'react';

import { BirthdayIcon, EmailIcon, PhoneIcon } from '../../../HomePage/components/Icons';
import PatientProfileButton from '../../../Patients/PatientDirectory/components/PatientProfileButton';
import { toShortDateString } from '../../../shared/dates/dates';
import { formatPhoneNumber } from '../../../shared/strings';
import {
  getTimeZoneFromAppointment,
  getTimeZoneOfDentist,
} from '../../../shared/timezone/timeZone';
import { AppointmentContext } from '../contexts/appointmentContext';

export default function PatientInformationSection() {
  const appointmentContext = useContext(AppointmentContext);
  const appointment = appointmentContext?.appointment;

  if (!appointment || !appointment.user) {
    return null; // Todo show loading animation
  }

  let timeZone: string | null = null;
  timeZone = getTimeZoneFromAppointment(appointment);
  if (!timeZone) {
    timeZone = getTimeZoneOfDentist(appointment.dentist);
  }

  return (
    <div className="items-start flex flex-col text-sm font-light w-1/3 text-secondary">
      <div className="text-lg font-semibold pb-4">Patient Information</div>
      <div className="space-y-2 flex flex-col items-start">
        <span className="font-medium">
          {appointment?.user.firstName} {appointment?.user.lastName}
        </span>
        <div className="flex flex-row space-x-1 items-center">
          <BirthdayIcon />
          <span>{toShortDateString(appointment?.user.birthDate, timeZone)}</span>
        </div>
        <span className="font-medium pt-2">Contact Information</span>
        <div className="flex flex-row space-x-1 items-center">
          <EmailIcon />
          <span>{appointment.user.email}</span>
        </div>
        <div className="flex flex-row space-x-1 items-center">
          <PhoneIcon />
          <span>{formatPhoneNumber(appointment.user.phone)}</span>
        </div>
        <div className="h-9"></div>
        <PatientProfileButton userId={appointment.user.id} />
      </div>
    </div>
  );
}
