import { Booking } from '../../API/bookingsAndCredits';
import { PatientReferral } from '../../API/referrals';
import { Appointment } from '../../ServiceContext/appointments';
import { Payout, PayoutInvoiceSummary } from '../../ServiceContext/invoices';
import { PatientDetail } from '../../ServiceContext/patients';

export type SortFunction<T> = (record1: T, record2: T) => number;

export const createSortFunctions = <T,>(
  getTime: (record: T) => string | null
): { ascending: SortFunction<T>; descending: SortFunction<T> } => {
  const ascending: SortFunction<T> = (record1, record2) => {
    const time1 = getTime(record1);
    const time2 = getTime(record2);
    if (!time1) {
      return -1;
    }
    if (!time2) {
      return 1;
    }
    const timeStamp1 = new Date(time1).getTime();
    const timeStamp2 = new Date(time2).getTime();
    return timeStamp1 - timeStamp2;
  };

  const descending: SortFunction<T> = (record1, record2) => {
    const time1 = getTime(record1);
    const time2 = getTime(record2);
    if (!time1) {
      return 1;
    }
    if (!time2) {
      return -1;
    }
    const timeStamp1 = new Date(time1).getTime();
    const timeStamp2 = new Date(time2).getTime();
    return timeStamp2 - timeStamp1;
  };

  return { ascending, descending };
};

export const bookingSortFunctions = createSortFunctions<Booking>(
  (booking) => booking.appointmentStartTime
);
export const payoutInvoiceSortFunctions = createSortFunctions<PayoutInvoiceSummary>(
  (payoutInvoice) => payoutInvoice.appointmentDate
);

export const payoutSortFunctions = createSortFunctions<Payout>(
  (payout) => payout.summary.payoutDate
);
export const appointmentSortFunctions = createSortFunctions<Appointment>(
  (appointment) => appointment.start
);
export const patientJoinSortFunctions = createSortFunctions<PatientDetail>(
  (patient) => patient.joinDate
);
export const patientReferralSortFunctions = createSortFunctions<PatientReferral>(
  (referral) => referral.createdAt
);
