export const cancellationReasonsMap: { [key: string]: string } = {
  user_requested_schedule_change: 'Patient rescheduled',
  user_requested: 'Patient cancelled',
  dentist_cancelled: 'Office cancelled',
  no_show: 'No show',
  user_insufficient_funds: 'Patient has insufficient funds',
  admin_cancel: 'Flossy Admin error',
  insurance_changed: 'Insurance changed',
  medical_intervention: 'Medical intervention',
};

export function getCancellationKeyToReason(key: string): string {
  return cancellationReasonsMap[key];
}

export function getReasonToCancellationKey(reason: string): string {
  return (
    Object.keys(cancellationReasonsMap).find((k) => cancellationReasonsMap[k] === reason) || ''
  );
}
