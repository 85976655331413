import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AppointmentWorkflow, ClickTrackingAction, runFTrack, Tracking } from '../../amplitude';
import { getAppointments } from '../../API/appointments';
import { ErrorResponse } from '../../API/response';
import commonAppointmentColumns from '../../Appointments/Tables/common_columns';
import { AuthProvider } from '../../Authentication/Authentication';
import { useSelectedDentist } from '../../Dentists/SelectedDentistContext';
import { Appointment, GetAppointmentsResponse } from '../../ServiceContext/appointments';
import Alert, { errorAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import { pollingIntervalSeconds } from '../../shared/realTime/polling';
import { useURLQuery } from '../../shared/routing/routing';
import { appointmentSortFunctions } from '../../shared/Table/SortFunction';
import Table, { ColumnDef } from '../../shared/Table/Table';
import LinkText from '../../shared/Text/LinkText';
import { BoxArrowRight } from './Icons';

type Props = {
  authProvider: AuthProvider;
};
const NeedsAttention: React.FC<Props> = ({ authProvider }) => {
  const pageSize = 5;
  const { query } = useURLQuery();
  const currentPage = parseInt(query.get('page') || '1');

  const { selectedDentistId } = useSelectedDentist();
  const [dateOrderByDirection, setDateOrderByDirection] = useState<'DESC' | 'ASC'>('DESC');
  const [selectedPage] = useState<number>(currentPage || 1);
  const navigate = useNavigate();
  const queryKey = [
    'getAppointments',
    selectedPage,
    pageSize,
    dateOrderByDirection,
    selectedDentistId,
  ];
  const tracking: Tracking = {
    workflow: AppointmentWorkflow,
    context: 'needsAttention',
    properties: {
      dentistId: selectedDentistId ?? '',
    },
  };
  const { error: responseError, data: appointmentsResponse } = useQuery<
    GetAppointmentsResponse,
    ErrorResponse
  >(
    queryKey,
    () => {
      const noncancelledStatuses = ['open', 'requested', 'completed'];
      return getAppointments({
        authProvider,
        page: selectedPage,
        pageSize: pageSize,
        timeMax: DateTime.local().startOf('day').toUTC().toString(),
        confirmStatus: 'confirmed',
        isProcessed: false,
        statuses: noncancelledStatuses,
        orderBy: 'start',
        dentistId: selectedDentistId ?? undefined,
        orderByDirection: dateOrderByDirection,
      });
    },
    {
      refetchInterval: pollingIntervalSeconds * 1000,
    }
  );

  if (responseError) {
    return (
      <div className="no-print">
        <Alert {...errorAlert(responseError)} />
      </div>
    );
  }

  const renderInvoiceAction = (appointment: Appointment) => {
    let buttonText: string = 'Create Invoice';

    return (
      <Button
        id={`create-appointment-button-${appointment.id}`}
        className="create-invoice-button"
        onClick={() => {
          navigate(`/appointments/${appointment.id}/invoicing`);
        }}
        disabled={!appointment.user}
        trackingLabel="Create Invoice Button"
        {...tracking}
      >
        {buttonText}
      </Button>
    );
  };

  const renderRescheduleAction = (record: Appointment) => {
    return (
      <Button
        id={`reschedule-appointment-button-${record.id}`}
        className="reschedule-appointment-button"
        onClick={() => {
          navigate(`/appointments/${record.id}/reschedule`);
        }}
        noFill
        disabled={!record.user}
        trackingLabel="Reschedule Button"
        {...tracking}
      >
        Reschedule
      </Button>
    );
  };

  const renderCancelAction = (record: Appointment) => {
    return (
      <Button
        id={`cancel-appointment-button-${record.id}`}
        className="cancel-appointment-button"
        noFill
        onClick={() => {
          navigate(`/appointments/${record.id}/cancel`);
        }}
        disabled={!record.user}
        trackingLabel="Cancel Button"
        {...tracking}
      >
        Cancel
      </Button>
    );
  };
  const generateColumnDef = () => {
    let tableColumns: ColumnDef<Appointment>[];
    let baseColumns = commonAppointmentColumns.map((c) => {
      if (c.columnKey === 'date') {
        return {
          ...c,
          sortable: appointmentSortFunctions,
          sortableRemote: {
            ascending: () => {
              setDateOrderByDirection('ASC');
            },
            descending: () => {
              setDateOrderByDirection('DESC');
            },
          },
        };
      }
      if (c.columnKey === 'patient') {
        return {
          ...c,
          render: (record: Appointment) => {
            if (!record.user) {
              return '';
            }
            return (
              <LinkText
                onClick={() => {
                  navigate(`/appointments/${record.id}/details`);
                }}
                className="patient-name !text-base-content !text-sm font-bold !pl-0 hover:opacity-75 text-start"
              >
                {record.user.firstName} {record.user.lastName}
              </LinkText>
            );
          },
        };
      }

      return c;
    });
    tableColumns = [
      {
        columnKey: 'emptyColumn',
        title: '',
        widthProportion: 0.1,
        render: () => {
          return <></>;
        },
      },
      ...baseColumns,
      {
        columnKey: 'action-buttons',
        title: '',
        widthProportion: 1.5,
        render: (record: Appointment) => {
          return (
            <div className="appointment-table-row-actions flex flex-row justify-end items-center text-sm mr-4 space-x-2">
              {renderInvoiceAction(record)}
              {renderRescheduleAction(record)}
              {renderCancelAction(record)}
            </div>
          );
        },
      },
    ];
    return tableColumns;
  };
  let tableData: Appointment[] = appointmentsResponse?.appointments || [];

  return (
    <div className="needs-attention-wrapper flex flex-col mt-8 w-full">
      <div className="label flex flex-row items-center gap-3 w-full">
        <span className="font-semibold text-lg flex-shrink-0 text-start">Needs Attention</span>
        {appointmentsResponse && (
          <span className="requests-count text-sm px-2.5 py-0.5 bg-accent font-semibold text-accent-content rounded-lg">
            {appointmentsResponse.totalCount}
          </span>
        )}
        <LinkText
          className="view-all !text-xs !pl-0 hover:opacity-75 flex-none"
          onClick={() => {
            runFTrack({
              event: 'Click View All Unprocessed Appointments',
              workflow: AppointmentWorkflow,
              action: ClickTrackingAction,
              context: 'needsAttention',
              componentId: 'viewAllUnprocessedAppointmentsButton',
              extraProps: {
                count: appointmentsResponse?.totalCount,
              },
            });
            navigate(`/appointments?page=1&category=Unprocessed`);
          }}
        >
          <div className="flex gap-2 items-center text-start">
            <div>View All Unprocessed Appointments</div>
            <BoxArrowRight />
          </div>
        </LinkText>
      </div>
      <div className="text-xs text-start flex-initial mt-2 mb-3">
        These appointments require your attention to be fully processed. Reschedule, cancel or
        generate an invoice for these outstanding appointments.
      </div>
      {appointmentsResponse && appointmentsResponse.appointments.length === 0 && (
        <div className="w-full">
          <center className="italic bg-info rounded-md py-4 text-base">
            All appointments have been completed.
          </center>
        </div>
      )}
      {appointmentsResponse && appointmentsResponse.appointments.length > 0 && (
        <Table
          id="needs-attention-table"
          columns={generateColumnDef()}
          data={tableData}
          tableClassNameExt={'bg-white !border-0'}
          rowKeyGenerator={(record: Appointment) => `${record.id}`}
        />
      )}
    </div>
  );
};

export default NeedsAttention;
