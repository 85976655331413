import React from 'react';

import { AuthProvider } from '../Authentication/Authentication';
import ProvideConsolidatedDentistPricingTable from '../DataProviders/ProvideConsolidatedDentistPricingTable';
import ProvidePatient from '../DataProviders/ProvidePatient';
import DentistSelectionWrapper from '../DataProviders/SelectionWrappers/DentistSelectionWrapper';
import { Dentist } from '../ServiceContext/user';
import TreatmentPlanBuilder from './TreatmentPlanBuilder';

type TreatmentPlanBuilderProps = {
  authProvider: AuthProvider;
  dentists: Dentist[];
  onClearPractice?: (() => void) | null;
};

const CreateTreatmentPlanPage: React.FC<TreatmentPlanBuilderProps> = ({
  dentists,
  authProvider,
}) => {
  return (
    <ProvidePatient authProvider={authProvider}>
      {({ patient }) => (
        <div id="create-treatment-plan-page">
          {patient && (
            <DentistSelectionWrapper
              dentists={dentists}
              flavorText="Select a dentist to create a treatment plan"
            >
              {({ selectedDentist }) => (
                <ProvideConsolidatedDentistPricingTable
                  authProvider={authProvider}
                  dentistId={selectedDentist.id}
                >
                  {({ consolidatedPricingTable }) => (
                    <TreatmentPlanBuilder
                      authProvider={authProvider}
                      patient={patient}
                      dentist={selectedDentist}
                      consolidatedPricingTable={consolidatedPricingTable}
                    />
                  )}
                </ProvideConsolidatedDentistPricingTable>
              )}
            </DentistSelectionWrapper>
          )}
        </div>
      )}
    </ProvidePatient>
  );
};

export default CreateTreatmentPlanPage;
