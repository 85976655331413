import { Appointment } from '../../ServiceContext/appointments';
import { isLegacyPayment } from '../../ServiceContext/invoices';
import {
  inferPricingSystemVersionFromInvoice,
  PricingSystemVersion,
} from '../../shared/pricingSystem';
import FlossyFeeSummaryRows from './FlossyFeeSummaryRows';
import NewPricingData from './NewPricingData';
import NewPricingTotalsRow from './NewPricingTotalsRow';
import OldPricingData from './OldPricingData';
import OldPricingTotalsRow from './OldPricingTotalsRow';

type Props = {
  appointment: Appointment;
  alternateRowStyle?: boolean;
};
const PayoutInvoiceItemsTable: React.FC<Props> = ({ appointment, alternateRowStyle }) => {
  const invoice = appointment.invoice;
  if (!invoice || !invoice.invoiceItems.length) {
    return <span className={'text-sm p-3 border rounded-md'}>No invoice items available.</span>;
  }

  const pricingSystemVersion = inferPricingSystemVersionFromInvoice(invoice);
  const usesNewPricingSystem = pricingSystemVersion === PricingSystemVersion.V2;

  //Insurance payments are considered legacy payments
  const payment = appointment.payment;
  let patientHasInsurance: boolean = false;
  if (isLegacyPayment(payment)) {
    patientHasInsurance = payment.paymentType === 'insurance';
  }

  const procedureInvoiceItems =
    invoice.invoiceItems.filter((item) => item.type === 'procedure') || [];

  return (
    <table id={'payout-invoice-items-table'} className={'w-full'}>
      <thead>
        <tr className={'border-b border-rule'}>
          <th className={'text-left py-3 pl-6'}>CDT Code</th>
          <th className={'text-left py-3 pl-6'}>Procedure</th>
          {/*anchor price*/}
          {usesNewPricingSystem && (
            <th className="text-right py-3 pr-6 text-base-content-inactive">Retail Price</th>
          )}

          {/*patient price*/}
          <th className="text-right py-3 pr-6">Flossy Price</th>

          {/*(Flossy price) - (dentist payout)*/}
          <th className="text-right py-3 pr-6">Flossy Fee</th>

          {/*dentist payout*/}
          <th className="text-right py-3 pr-6">Dentist Payout</th>
        </tr>
      </thead>
      <tbody>
        {usesNewPricingSystem ? (
          <NewPricingData
            procedureInvoiceItems={procedureInvoiceItems}
            invoice={invoice}
            alternateRowStyle={alternateRowStyle}
            patientHasInsurance={patientHasInsurance}
          />
        ) : (
          <OldPricingData
            procedureInvoiceItems={procedureInvoiceItems}
            invoice={invoice}
            patientHasInsurance={patientHasInsurance}
          />
        )}
        {usesNewPricingSystem ? (
          <NewPricingTotalsRow invoice={invoice} patientHasInsurance={patientHasInsurance} />
        ) : (
          <OldPricingTotalsRow
            invoice={invoice}
            procedureInvoiceItems={procedureInvoiceItems}
            patientHasInsurance={patientHasInsurance}
          />
        )}
        <FlossyFeeSummaryRows
          usesNewPricingSystem={usesNewPricingSystem}
          invoice={invoice}
          patientHasInsurance={patientHasInsurance}
        />
      </tbody>
    </table>
  );
};

export default PayoutInvoiceItemsTable;
