import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { LedgerMonth } from '../../../API/bookingsAndCredits';
import { stringifyMoney } from '../../../shared/money/stringifyMoney';
import { utcTimestampToBookingPeriod } from '../BookingPeriod';

type Props = {
  ledgerMonth: LedgerMonth;
};

const CurrentMonthBalanceSummary: React.FC<Props> = ({ ledgerMonth }) => {
  const [isHovered, setIsHovered] = useState(false);

  const currentDate = new Date();
  const currentBookingPeriod = utcTimestampToBookingPeriod(currentDate.toISOString()).displayForm;
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const firstDayOfCurrentMonth = new Date(year, month, 1);
  const formattedFirstDayOfCurrentMonth = DateTime.fromISO(
    firstDayOfCurrentMonth.toISOString()
  ).toLocaleString(DateTime.DATE_MED);
  const firstDayOfNextMonth = new Date(year, month + 1, 1);
  const formattedFirstDayOfNextMonth = DateTime.fromISO(
    firstDayOfNextMonth.toISOString()
  ).toLocaleString(DateTime.DATE_MED);

  const currentBalance = stringifyMoney(ledgerMonth.balance, {
    includeCommas: true,
  });

  return (
    <div
      id={'current-month-balance-summary'}
      className="flex flex-col gap-1 text-left bg-white rounded-md pl-6 py-4 relative"
    >
      <span className={'text-lg font-bold mb-2'}>{currentBookingPeriod}</span>
      <div className={'flex flex-row gap-1.5 items-center'}>
        <span className={'text-sm font-semibold'}>Current Balance</span>
        <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="cursor-pointer"
            style={{ color: 'lightslategray', opacity: '75%', scale: '0.8' }}
          />
        </div>
      </div>
      {isHovered && (
        <div
          className={
            'absolute z-20 rounded-md text-xs text-white p-2 bg-base-content shadow-md left-1/2 translate-x-[10px] translate-y-[16px]'
          }
        >{`This balance will be payable on ${formattedFirstDayOfNextMonth}`}</div>
      )}
      <span id={'current-month-balance'} className={'text-2xl font-bold'}>
        {currentBalance}
      </span>
      <span
        className={'text-xs mt-2.5'}
      >{`Balance from ${formattedFirstDayOfCurrentMonth} - Today`}</span>
    </div>
  );
};

export default CurrentMonthBalanceSummary;
