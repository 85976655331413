import React, { useCallback } from 'react';

import { Address } from '../../../ServiceContext/shared';
import { toLongDateString } from '../../../shared/dates/dates';
import { formatPhoneNumber } from '../../../shared/strings';

type Props = {
  patientName: string;
  appointmentTimeInUTC: string;
  appointmentTimeZone: string;
  dentistName: string;
  dentistLocation: Address;
};
const BasicInfo: React.FC<Props> = ({
  patientName,
  appointmentTimeZone,
  appointmentTimeInUTC,
  dentistName,
  dentistLocation,
}) => {
  const renderPracticeAddress = useCallback(() => {
    return (
      <>
        <div>{dentistLocation.name}</div>
        <div>{dentistLocation.address1}</div>
        <div>{`${dentistLocation.city}, ${dentistLocation.state} ${dentistLocation.zip}`}</div>
        <div>{`${formatPhoneNumber(dentistLocation.phone)}`}</div>
      </>
    );
  }, [dentistLocation]);
  return (
    <div id="payment-appointment-info" className="flex flex-row justify-between w-full my-10">
      <div id="patient-info" className="flex flex-column items-start">
        <div id="payment-appointment-name" className="font-bold text-xl">
          {patientName}
        </div>
        <div id="payment-appointment-date" className="font-medium text-base">
          {toLongDateString(appointmentTimeInUTC, appointmentTimeZone)}
        </div>
      </div>
      <div id="practice-info-container" className="flex flex-column items-end">
        <div id="practice-info" className="items-start">
          <div id="dentist-name-label" className="font-bold text-xl">{`Dr. ${dentistName}`}</div>
          <div id="practice-address">{renderPracticeAddress()}</div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
