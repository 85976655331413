import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppointmentWorkflow } from '../../../amplitude';
import { CalendarIcon } from '../../../HomePage/components/Icons';
import { Appointment } from '../../../ServiceContext/appointments';
import Button from '../../../shared/Button/Button';

export default function RescheduleButton({
  appointment,
  disabled,
}: {
  appointment: Appointment | null;
  disabled?: boolean;
}) {
  const navigate = useNavigate();

  const onClick = useCallback(async () => {
    if (appointment) {
      navigate(`/appointments/${appointment.id}/reschedule`);
    }
  }, [appointment, navigate]);

  return (
    <Button
      workflow={AppointmentWorkflow}
      trackingLabel="Reschedule Button"
      color="tertiary"
      onClick={
        disabled
          ? onClick
          : () => {
              navigate(`/appointments/${appointment?.id}/reschedule`);
            }
      }
    >
      <div className="flex flex-row items-center gap-2">
        <CalendarIcon />
        <div>Reschedule</div>
      </div>
    </Button>
  );
}
