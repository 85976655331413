import React from 'react';
import Modal from 'react-modal';

import LogoutButton from '../LogoutButton/LogoutButton';
import { AuthProvider } from './Authentication';

const AuthenticationExpiredModal: React.FC<{ authProvider: AuthProvider }> = ({ authProvider }) => (
  <Modal
    isOpen
    contentLabel="Authentication Expired"
    className="outline-none mx-auto my-10 bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 max-h-4/5 overflow-auto shadow-lg"
    overlayClassName="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div className="flex flex-col items-center p-8">
      <svg
        className="w-16 h-16 mb-4 text-yellow-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 10V3L4 14h7v7l9-11h-7z"
        />
      </svg>
      <span className="text-center mb-4 font-semibold text-lg text-base-content">
        Authentication Expired
      </span>
      <span className="text-center text-base-content mb-6">
        Your authentication has expired. Please log out and log back in to continue using the
        application.
      </span>
      <LogoutButton authentication={authProvider} />
    </div>
  </Modal>
);

export default AuthenticationExpiredModal;
