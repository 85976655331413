import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { AuthProvider } from '../../Authentication/Authentication';
import PatientTreatmentPlansTable from '../../Patients/PatientDetails/components/PatientTreatmentPlansTable';
import { usePatient } from '../../Patients/PatientDetails/hooks/usePatient';
import { PatientContext } from '../../Patients/PatientDetails/PatientDetailPage';
import { RootRedirect } from '../../Routing/Router';
import Alert, { AlertData, errorAlert } from '../../shared/Alert';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { useURLQuery } from '../../shared/routing/routing';
import AppointmentDetailsHeader from './components/DetailsHeader';
import DetailsTile from './components/DetailsTile';
import InformationTile from './components/InformationTile';
import { AppointmentContext } from './contexts/appointmentContext';
import { useAppointment } from './hooks/useAppointment';
import { useAppointmentInsurance } from './hooks/useAppointmentInsurance';

type Props = {
  authentication: AuthProvider;
};

const AppointmentDetail: React.FC<Props> = ({ authentication }) => {
  const { getQuery } = useURLQuery();
  const { appointmentId } = useParams<{ appointmentId: string }>();

  const [alert, setAlert] = useState<AlertData | null>(null);

  const {
    isLoading: isLoadingAppointment,
    isError,
    data: appointment,
    error,
    refetch,
  } = useAppointment(appointmentId || null);
  const patientId = appointment?.userId;
  const { isLoading: isLoadingPatient, data: patient } = usePatient(patientId || null);

  const { data: insurance } = useAppointmentInsurance(appointment?.id, authentication);

  const isRescheduleSuccessful = getQuery('successfulReschedule');
  const isCancelSuccessful = getQuery('successfulCancel');

  if (isLoadingAppointment || isLoadingPatient || (!appointment && !alert) || !appointment) {
    return <LoadingSpinner />;
  }

  if (isError && error instanceof Error) {
    setAlert(errorAlert(error.message));
    return <span>Error: {error.message}</span>;
  } else if (isError) {
    return <span>Error fetching appointment</span>;
  }

  if (!isLoadingPatient && !patient) {
    return <RootRedirect authentication={authentication} />;
  }

  if (!appointmentId) {
    return <RootRedirect authentication={authentication} />;
  }

  return (
    <div className="appointment-details-page font-sans flex flex-column gap-3 pr-4 items-start">
      {alert && <Alert {...alert} />}
      <AppointmentDetailsHeader />
      {isRescheduleSuccessful && (
        <span
          id={'reschedule-success-notification'}
          className={'text-xs text-left font-semibold rounded-lg bg-base-200 p-3 w-full'}
        >
          Appointment has been successfully rescheduled.
        </span>
      )}
      {isCancelSuccessful && (
        <span
          id={'reschedule-success-notification'}
          className={'text-xs text-left rounded-lg bg-base-200 p-3 w-full'}
        >
          <span className={'font-semibold'}>Appointment has been successfully cancelled.</span> You
          can reschedule this appointment at any time.
        </span>
      )}
      <AppointmentContext.Provider value={{ appointment, refetchAppointment: refetch }}>
        <DetailsTile />
        <InformationTile insuranceDetails={insurance} />
        <span className={'font-semibold text-xl mt-6'}>Treatment Plans</span>
        {patient && (
          <PatientContext.Provider value={patient}>
            <PatientTreatmentPlansTable authProvider={authentication} />
          </PatientContext.Provider>
        )}
      </AppointmentContext.Provider>
    </div>
  );
};

export default AppointmentDetail;
