import {
  PatientReferral,
  ReferralCreditStatus,
  ReferralStatus as PatientReferralStatus,
} from '../API/referrals';

export const isReferralUnused = (referral: PatientReferral) => {
  if (referral.status === PatientReferralStatus.OPEN) {
    return true;
  }
  return !!(
    referral.status === PatientReferralStatus.USED &&
    referral.credit &&
    referral.credit.status === ReferralCreditStatus.PENDING
  );
};
