import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { AppointmentWorkflow, ClickTrackingAction, runFTrack } from '../../amplitude';
import { Appointment } from '../../ServiceContext/appointments';
import LinkText from '../../shared/Text/LinkText';
import { PaymentAdded, PaymentMissing, Tooth } from './Icons';

export default function AppointmentTileDetails({ appointment }: { appointment: Appointment }) {
  const navigate = useNavigate();

  return (
    <div className="appointment-details-column flex flex-col gap-2 w-1/2">
      <LinkText
        onClick={() => {
          runFTrack({
            event: 'Press View Appointment Link',
            workflow: AppointmentWorkflow,
            action: ClickTrackingAction,
            context: 'appointmentTileDetails',
            componentId: 'appointmentTileDetailsViewAppointmentLink',
          });
          navigate(`/appointments/${appointment.id}/details`);
        }}
        className="patient-name !text-base-content !text-base !font-semibold !pl-0 hover:opacity-75 text-start"
      >
        {`${appointment.user?.firstName} ${appointment.user?.lastName}`}
      </LinkText>
      <div className="dentist-and-payment flex flex-row items-center gap-3">
        <div className="dentist flex flex-row gap-1.5 ">
          <Tooth neutral />
          <span className="dentist-name text-xs">{`${appointment.dentist.firstName} ${appointment.dentist.lastName}`}</span>
        </div>
        <div className="payment flex flex-row gap-1.5">
          {appointment.paymentMethod ? (
            <div
              data-tooltip-id="payment-added-tooltip"
              data-tooltip-content="This patient has a payment method on file."
              data-tooltip-place="bottom"
            >
              <PaymentAdded />
            </div>
          ) : (
            <div
              data-tooltip-id="payment-missing-tooltip"
              data-tooltip-content="This patient has no payment methods on file."
              data-tooltip-place="bottom"
            >
              <PaymentMissing />
            </div>
          )}
          <span className="payment-option text-xs">
            Paying using
            <span className="font-bold">
              {' '}
              {appointment.patientInsuranceId ? 'Insurance' : 'Flossy'}
            </span>
          </span>
        </div>
      </div>
      <Tooltip id="payment-added-tooltip" />
      <Tooltip id="payment-missing-tooltip" />
    </div>
  );
}
