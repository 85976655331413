import { AuthProvider } from '../Authentication/Authentication';
import { Appointment, GetAppointmentsResponse } from '../ServiceContext/appointments';
import {
  authenticatedGet,
  authenticatedNoContentPost,
  authenticatedPost,
} from '../ServiceContext/service';
import { apiUrl } from '../ServiceContext/shared';
import { ErrorResponse, handleAPIResponse } from './response';

export async function getAppointment({
  authProvider,
  appointmentId,
}: {
  authProvider: AuthProvider;
  appointmentId: string;
}): Promise<Appointment> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedGet<Appointment | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/appointments/${appointmentId}`)
    )
  );
}

export async function createAppointment({
  authProvider,
  dentistId,
  patientId,
  startTimeInUTC,
  notes,
  promoCode,
  patientInsuranceId,
}: {
  authProvider: AuthProvider;
  dentistId: string;
  patientId: string;
  startTimeInUTC: string;
  notes?: string;
  promoCode?: string;
  patientInsuranceId?: string;
}): Promise<Appointment> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedPost<Appointment>(authProvider, apiUrl(`/practices/appointments`), {
      dentistId,
      patientId,
      startTimeInUTC,
      notes,
      promoCode,
      patientInsuranceId,
    })
  );
}

export async function cancelAppointment({
  authProvider,
  appointmentId,
  reason,
  cancellationNotes,
}: {
  authProvider: AuthProvider;
  appointmentId: string;
  reason: string;
  cancellationNotes: string;
}) {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedNoContentPost(
      authProvider,
      apiUrl(`/dentists/appointments/${appointmentId}/cancel?reason=${reason}`),
      {
        cancellationNotes,
      }
    )
  );
}

export async function getNewBookings({
  authProvider,
  page,
  pageSize,
  orderBy,
  orderByDirection,
  isAcknowledged,
  dentistId,
  isInsurance,
  hasInvoice,
  hasTreatmentPlan,
}: {
  authProvider: AuthProvider;
  page: number;
  pageSize: number;
  orderBy?: string;
  orderByDirection?: 'ASC' | 'DESC';
  isAcknowledged?: boolean;
  dentistId?: string;
  isInsurance?: boolean;
  hasInvoice?: boolean;
  hasTreatmentPlan?: boolean;
}): Promise<GetAppointmentsResponse> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  let params = new URLSearchParams('');

  params.append('skip', (pageSize * (page - 1)).toString());
  params.append('maxResults', pageSize.toString());

  if (orderBy) {
    params.append('orderBy', orderBy);
  }

  if (orderByDirection) {
    params.append('orderByDirection', orderByDirection);
  }

  if (isAcknowledged) {
    params.append('isAcknowledged', 'true');
  } else if (isAcknowledged !== undefined) {
    params.append('isAcknowledged', 'false');
  }

  if (isInsurance) {
    params.append('isInsurance', 'true');
  } else if (isInsurance === false) {
    params.append('isInsurance', 'false');
  }

  if (hasInvoice) {
    params.append('hasInvoice', 'true');
  } else if (hasInvoice === false) {
    params.append('hasInvoice', 'false');
  }

  if (hasTreatmentPlan) {
    params.append('hasTreatmentPlan', 'true');
  } else if (hasTreatmentPlan === false) {
    params.append('hasTreatmentPlan', 'false');
  }

  if (dentistId) {
    params.append('dentistId', dentistId);
  }

  const url = `/managers/${authUser.user.id}/new-bookings?${params}`;

  return handleAPIResponse(
    authenticatedGet<GetAppointmentsResponse | ErrorResponse>(authProvider, apiUrl(url.toString()))
  );
}

export async function getAppointments({
  authProvider,
  page,
  pageSize,
  timeMin,
  timeMax,
  dentistId,
  confirmStatus,
  patientName,
  orderBy,
  orderByDirection,
  statuses,
  isProcessed,
  isAcknowledged,
  isInsurance,
  hasInvoice,
  hasTreatmentPlan,
}: {
  authProvider: AuthProvider;
  page: number;
  pageSize: number;
  timeMin?: string;
  timeMax?: string;
  dentistId?: string;
  confirmStatus?: string;
  patientName?: string;
  orderBy?: string;
  orderByDirection?: 'ASC' | 'DESC';
  statuses?: string[];
  isProcessed?: boolean;
  isAcknowledged?: boolean;
  isInsurance?: boolean;
  hasInvoice?: boolean;
  hasTreatmentPlan?: boolean;
}): Promise<GetAppointmentsResponse> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  let params = new URLSearchParams('');

  params.append('skip', (pageSize * (page - 1)).toString());
  params.append('maxResults', pageSize.toString());
  if (patientName) {
    params.append('patientName', encodeURI(patientName));
  }
  if (dentistId) {
    params.append('dentistId', dentistId);
  }

  if (confirmStatus === 'confirmed') {
    params.append('confirmStatus', 'confirmed');
  } else if (confirmStatus === 'unconfirmed') {
    params.append('confirmStatus', 'unconfirmed');
  }

  if (timeMin) {
    params.append('timeMin', timeMin);
  }
  if (timeMax) {
    params.append('timeMax', timeMax);
  }

  if (isProcessed) {
    params.append('isProcessed', 'true');
  } else if (isProcessed === false) {
    params.append('isProcessed', 'false');
  }

  if (isAcknowledged) {
    params.append('isAcknowledged', 'true');
  } else if (isAcknowledged === false) {
    params.append('isAcknowledged', 'false');
  }

  if (isInsurance) {
    params.append('isInsurance', 'true');
  } else if (isInsurance === false) {
    params.append('isInsurance', 'false');
  }

  if (hasInvoice) {
    params.append('hasInvoice', 'true');
  } else if (hasInvoice === false) {
    params.append('hasInvoice', 'false');
  }

  if (hasTreatmentPlan) {
    params.append('hasTreatmentPlan', 'true');
  } else if (hasTreatmentPlan === false) {
    params.append('hasTreatmentPlan', 'false');
  }

  if (orderBy) {
    params.append('orderBy', orderBy);
  }

  if (orderByDirection) {
    params.append('orderByDirection', orderByDirection);
  }
  if (statuses) {
    params.append('statuses', statuses.join(','));
  }
  const url = `/managers/${authUser.user.id}/appointments?${params}`;

  return handleAPIResponse(
    authenticatedGet<GetAppointmentsResponse | ErrorResponse>(authProvider, apiUrl(url.toString()))
  );
}
