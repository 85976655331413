import cx from 'classnames';
import React from 'react';

type Props = {
  noDisplay?: Boolean;
  onClick: () => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
};

const ClearButton = React.forwardRef<SVGSVGElement, Props>(
  ({ noDisplay, onClick, onMouseEnter, onMouseLeave }, ref) => {
    return (
      <svg
        role="button"
        ref={ref}
        aria-label="Input clear button"
        className={cx({
          'search-input-clear-button outline-0 -ml-7 mt-1.5 cursor-pointer': true,
          'no-display hidden': noDisplay,
        })}
        width="24px"
        height="24px"
        strokeWidth="1.0"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#000000"
        pointerEvents="bounding-box"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <rect width="100%" height="100%" />
        <path
          d="M9.172 14.828L12.001 12m2.828-2.828L12.001 12m0 0L9.172 9.172M12.001 12l2.828 2.828M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
          stroke="#9494a0"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default ClearButton;
