import React, { createContext, useContext, useState } from 'react';

import { useURLQuery } from '../shared/routing/routing';

type SelectedDentistContextType = {
  selectedDentistId: string | null;
  setSelectedDentistId: React.Dispatch<React.SetStateAction<string | null>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

const SelectedDentistContext = createContext<SelectedDentistContextType | undefined>(undefined);

// Create the provider component
export const SelectedDentistProvider = ({ children }: { children: React.ReactNode }) => {
  const { query } = useURLQuery();
  const dentistId = query.get('dentistId');

  const [selectedDentistId, setSelectedDentistId] = useState<string | null>(dentistId);
  const [search, setSearch] = useState('');

  return (
    <SelectedDentistContext.Provider
      value={{ selectedDentistId, setSelectedDentistId, search, setSearch }}
    >
      {children}
    </SelectedDentistContext.Provider>
  );
};

// Create the custom hook
export const useSelectedDentist = () => {
  const context = useContext(SelectedDentistContext);
  if (context === undefined) {
    throw new Error('useSelectedDentist must be used within a SelectedDentistProvider');
  }
  return context;
};
