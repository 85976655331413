import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getPayableInvoicesForDentist, getUpcomingPayoutsForPractice } from '../../API/payouts';
import { ErrorResponse } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import { useSelectedDentist } from '../../Dentists/SelectedDentistContext';
import { PayoutCluster } from '../../ServiceContext/invoices';

export const useAPI_upcomingPayout = ({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
}): {
  upcomingPayout: PayoutCluster[] | undefined;
  isLoading: boolean;
  error: ErrorResponse | undefined;
} => {
  const { selectedDentistId } = useSelectedDentist();

  const getUpcomingPayoutsForPracticeKey = useMemo(
    () => ['getUpcomingPayoutsForPractice', selectedDentistId],
    [selectedDentistId]
  );

  const isViewingAllDentists = !selectedDentistId;

  const {
    isLoading: isLoadingPayoutInfoForPractice,
    error: fetchUpcomingPayoutsForPracticeError,
    data: payableInvoicesForPractice,
  } = useQuery<PayoutCluster[], ErrorResponse>(
    getUpcomingPayoutsForPracticeKey,
    () => {
      return getUpcomingPayoutsForPractice({
        authProvider,
        practiceId,
      });
    },
    {
      enabled: isViewingAllDentists,
    }
  );

  const getPayableInvoicesForDentistKey = useMemo(
    () => ['getPayableInvoicesForDentist', selectedDentistId],
    [selectedDentistId]
  );

  const {
    isLoading: isLoadingPayoutInfoForDentist,
    error: fetchPayableInvoicesForDentistError,
    data: payableInvoicesForDentist,
  } = useQuery<PayoutCluster[], ErrorResponse>(
    getPayableInvoicesForDentistKey,
    () => {
      if (!selectedDentistId) {
        return [];
      }
      return getPayableInvoicesForDentist({
        authProvider,
        dentistId: selectedDentistId,
      });
    },
    {
      enabled: !isViewingAllDentists,
    }
  );

  if (isLoadingPayoutInfoForPractice || isLoadingPayoutInfoForDentist) {
    return {
      upcomingPayout: undefined,
      isLoading: true,
      error: undefined,
    };
  }

  if (fetchUpcomingPayoutsForPracticeError) {
    return {
      upcomingPayout: undefined,
      isLoading: false,
      error: fetchUpcomingPayoutsForPracticeError,
    };
  }

  if (fetchPayableInvoicesForDentistError) {
    return {
      upcomingPayout: undefined,
      isLoading: false,
      error: fetchPayableInvoicesForDentistError,
    };
  }

  if (payableInvoicesForPractice) {
    return {
      upcomingPayout: payableInvoicesForPractice,
      isLoading: false,
      error: undefined,
    };
  }

  if (payableInvoicesForDentist) {
    return {
      upcomingPayout: payableInvoicesForDentist,
      isLoading: false,
      error: undefined,
    };
  }

  return {
    upcomingPayout: [],
    isLoading: false,
    error: undefined,
  };
};
