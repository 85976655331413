import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { authenticatedGet, authenticatedNoContentDelete, authenticatedPost } from './service';
import { apiUrl, Timestamp } from './shared';

export interface AvailabilityEvent {
  id: string;
  dentistId: string;
  start: Timestamp;
  end: Timestamp;
  calendarId: string;
  eventId: string;
  title: string;
  status: 'free' | 'busy';
}

export type CreateAvailabilityEventResponse =
  | AvailabilityEvent[]
  | {
      deletedEvents: AvailabilityEvent[];
      createdEvents: AvailabilityEvent[];
    };

export interface CalendarService {
  getAvailabilityEventsForDentist: (
    dentistId: string
  ) => Promise<AvailabilityEvent[] | ErrorResponse>;
  createAvailabilityEventForDentist: (args: {
    dentistId: string;
    startUTCTimestamp: string;
    endUTCTimestamp: string;
    availabilityType: 'available' | 'unavailable';
    eventType: 'one_time' | 'recurring';
    recurrenceSchedule: string;
  }) => Promise<CreateAvailabilityEventResponse | ErrorResponse>;
  deleteEvent: (dentistId: string, eventId: string) => Promise<null | ErrorResponse>;
}

const getAvailabilityEventsForDentist = (authProvider: AuthProvider) => {
  return async (dentistId: string) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedGet<AvailabilityEvent[]>(
      authProvider,
      apiUrl(`/dentists/availability/events?dentistId=${dentistId}`)
    );
  };
};

type CreateAvailabilityEventRequest = {
  title: string;
  start: string;
  end: string;
  dentistId: string;
  eventType?: 'one_time' | 'recurring';
  services?: string[];
  unavailable?: boolean;

  // Must be RFC 5545 RRULE as found here: https://icalendar.org/iCalendar-RFC-5545/3-8-5-3-recurrence-rule.html
  recurrenceSchedule?: string;
};

const createAvailabilityEventForDentist = (authProvider: AuthProvider) => {
  return async ({
    dentistId,
    startUTCTimestamp,
    endUTCTimestamp,
    availabilityType,
    eventType,
    recurrenceSchedule,
  }: {
    dentistId: string;
    startUTCTimestamp: string;
    endUTCTimestamp: string;
    availabilityType: 'available' | 'unavailable';
    eventType: 'one_time' | 'recurring';
    recurrenceSchedule: string;
  }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    let title: string;
    let isUnavailable = false;
    if (availabilityType === 'available') {
      title = 'Available';
    } else {
      title = 'Unavailable';
      isUnavailable = true;
    }
    const request: CreateAvailabilityEventRequest = {
      title,
      start: startUTCTimestamp,
      end: endUTCTimestamp,
      dentistId,
      unavailable: isUnavailable,
      eventType,
    };
    if (recurrenceSchedule) {
      request.recurrenceSchedule = recurrenceSchedule;
    }
    return authenticatedPost<CreateAvailabilityEventResponse>(
      authProvider,
      apiUrl(`/dentists/availability/events`),
      request
    );
  };
};

const deleteEvent = (authProvider: AuthProvider) => {
  return async (dentistId: string, eventId: string) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedNoContentDelete(
      authProvider,
      apiUrl(`/dentists/availability/events?dentistId=${dentistId}&availabilityEventId=${eventId}`)
    );
  };
};

const makeService = (auth: AuthProvider) => {
  return {
    getAvailabilityEventsForDentist: getAvailabilityEventsForDentist(auth),
    createAvailabilityEventForDentist: createAvailabilityEventForDentist(auth),
    deleteEvent: deleteEvent(auth),
  };
};

export default makeService;
