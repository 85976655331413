import React from 'react';

import { Appointment } from '../ServiceContext/appointments';
import { toLongDateString } from '../shared/dates/dates';

type AppointmentSummaryProps = {
  appointment: Appointment;
  dentistName?: string;
  patientName: string;
};

const AppointmentSummary: React.FC<AppointmentSummaryProps> = ({ appointment, patientName }) => {
  return (
    <div className="appointment-summary flex flex-col items-start mt-4 pl-6">
      <div className="patient-name font-bold text-lg">{patientName}</div>
      <div id="date" className="date font-sans text-sm mb-4">
        {toLongDateString(appointment.start, appointment.timeZone)}
      </div>
      {appointment.promoCode !== null && (
        <div id="promo-code" className="promo-code font-sans font-medium text-lg pb-3">
          Promo Code: {appointment.promoCode}
        </div>
      )}
    </div>
  );
};

export default AppointmentSummary;
