import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { BookingsBreakdown } from '../../../../API/bookingsAndCredits';

type Props = {
  data: BookingsBreakdown;
};

const FlossyBookings: React.FC<Props> = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="relative flex flex-col items-start p-4">
      <div className="bookings-with-info flex flex-row gap-1.5 items-center">
        <span className="text-base-content text-sm font-semibold">Flossy Bookings</span>
        <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="cursor-pointer"
            style={{ color: 'lightslategray', opacity: '75%', scale: '0.8' }}
          />
        </div>
      </div>
      <span className="text-xl font-bold text-start">{data.totalCount}</span>

      {isHovered && (
        <div className="absolute z-20 flex flex-col gap-2 text-left top-1/2 left-1/2 mt-2 p-4 bg-white rounded-md shadow-lg w-64 transform -translate-x-1/2">
          <span className="text-base-content text-sm font-bold mb-2">Flossy Booking Details</span>
          <div className="insurance-bookings flex flex-row justify-between gap-1 text-xs">
            <span className="font-semibold">Insurance Bookings</span>
            <span>{data.insuranceBookings}</span>
          </div>
          <div className="non-insurance-bookings flex flex-row justify-between gap-1 text-xs">
            <span className="font-semibold">Non-Insurance Bookings</span>
            <span>{data.nonInsuranceBookings}</span>
          </div>
          <div className="paid-with-insurance flex flex-row justify-between gap-1 text-xs">
            <span className="font-semibold">Paid with Insurance</span>
            <span>{data.paidWithInsurance}</span>
          </div>
          <div className="paid-without-insurance flex flex-row justify-between gap-1 text-xs">
            <span className="font-semibold">Paid without Insurance</span>
            <span>{data.paidWithoutInsurance}</span>
          </div>
          <div className="pending-payment flex flex-row justify-between gap-1 text-xs">
            <span className="font-semibold">Pending Payment</span>
            <span>{data.pendingPayment}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlossyBookings;
