import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { ChargesBreakdown } from '../../../../API/bookingsAndCredits';
import { stringifyMoney } from '../../../../shared/money/stringifyMoney';

type Props = {
  count: number;
  sum: number;
  breakdown: ChargesBreakdown;
};

const BookingCharges: React.FC<Props> = ({ count, sum, breakdown }) => {
  const [isHovered, setIsHovered] = useState(false);

  const countUndisputedBookings = count - breakdown.disputed;
  const amountPerCharge = sum / countUndisputedBookings;

  return (
    <div className="relative flex flex-col items-start p-4">
      <div className="charges-with-info flex flex-row gap-1.5 items-center">
        <span className="text-base-content text-sm font-semibold">Booking Charges</span>
        <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="cursor-pointer"
            style={{ color: 'lightslategray', opacity: '75%', scale: '0.8' }}
          />
        </div>
      </div>
      <span className="text-xl font-bold">{stringifyMoney(sum, { includeCommas: true })}</span>
      {isHovered && (
        <div className="absolute z-20 flex flex-col gap-2 text-left top-1/2 left-1/2 mt-2 p-4 bg-white rounded-md shadow-lg w-64 transform -translate-x-1/2">
          <span className="text-base-content text-sm font-bold mb-2">Booking Charge Details</span>
          <div className="bookings flex flex-row justify-between gap-1 text-xs">
            <div className="flex flex-row gap-1 font-semibold">
              <span>{count}</span>
              {count > 1 ? <span>Bookings</span> : <span>Booking</span>}
            </div>
            <span>{stringifyMoney(count * amountPerCharge, { includeCommas: true })}</span>
          </div>
          <div className="rejected-dispute flex flex-row justify-between gap-1 text-xs">
            <div className="flex flex-row gap-1 font-semibold">
              <span>{breakdown.disputeRejected}</span>
              {breakdown.disputeRejected > 1 ? (
                <span>Rejected Disputes</span>
              ) : (
                <span>Rejected Dispute</span>
              )}
            </div>
            <span>
              {stringifyMoney(breakdown.disputeRejected * amountPerCharge, { includeCommas: true })}
            </span>
          </div>
          <div className="refunded-dispute flex flex-row justify-between gap-1 text-xs">
            <div className="flex flex-row gap-1 font-semibold">
              <span>{breakdown.disputeRefunded}</span>
              {breakdown.disputeRefunded > 1 ? (
                <span>Refunded Disputes</span>
              ) : (
                <span>Refunded Dispute</span>
              )}
            </div>
            <span>
              {stringifyMoney(breakdown.disputeRefunded * amountPerCharge, { includeCommas: true })}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingCharges;
