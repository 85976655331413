import React from 'react';

import { Booking, BookingChargeCredit } from '../../../API/bookingsAndCredits';
import logo from '../InvoiceExport/flossy-branding-bw.svg';
import BookingsTable from '../NewBookingsAndCreditsExperience/ShareInvoiceFlow/BookingsTable';

interface Props {
  practiceName: string;
  balanceDueDate: string;
  bookingsWithPendingCharges: Booking[];
  appliedCredits: BookingChargeCredit[];
}

const PrintedInvoice = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { practiceName, balanceDueDate, bookingsWithPendingCharges, appliedCredits } = props;

  return (
    <div className="content-to-export scale-[0.80]" ref={ref}>
      <div className="branding-row flex justify-start w-full mb-4">
        <img className="w-[150px]" src={logo} id="app-branding" alt="flossy-logo" />
      </div>
      <div className="header flex flex-row gap-16">
        <div className="practice-info flex flex-col gap-2">
          <span className="font-semibold">{practiceName}</span>
          {/*<span>{authProvider.authUser?.user.locations[0]}</span>*/}
        </div>
        <div className="bookings-charges-info flex flex-col gap-2">
          <span className="font-semibold">Bookings & Charges</span>
          <div className="flex flex-row text-sm gap-2 mb-12">
            <span className="font-semibold">Due Date: </span>
            <span className="due-date">{balanceDueDate}</span>
          </div>
        </div>
      </div>
      <div className="bookings-table">
        <BookingsTable
          bookingData={bookingsWithPendingCharges}
          appliedCredits={appliedCredits}
          alternateRowStyle={false}
        />
      </div>
    </div>
  );
});

export default PrintedInvoice;
