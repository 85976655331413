import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { PatientWorkflow, runFTrack, SideEffectTrackingAction } from '../../amplitude';
import { getPatients } from '../../API/patients';
import { ErrorResponse } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import { PatientDirectorySearchResult } from '../../ServiceContext/patients';
import Alert, { errorAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import Modal from '../../shared/Modal/Modal';
import PatientFilterInput from '../../shared/PatientFilterInput/PatientFilterInput';
import { useURLQuery } from '../../shared/routing/routing';
import NewPagination from '../../shared/Table/NewPagination';
import CreatePatientForm from './components/CreatePatientForm';
import PatientsTable from './components/PatientsTable';

type Props = {
  authProvider: AuthProvider;
};

const PatientDirectory: React.FC<Props> = ({ authProvider }) => {
  const pageSize = 5;

  const practiceId = authProvider.authUser?.user?.practices[0].id;

  const { query, updateQuery, getQuery, setQuery } = useURLQuery();
  const [patientNameDisplay, setPatientNameDisplay] = useState<string>('');
  // const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentPage = parseInt(query.get('page') || '1');
  const [selectedPage, setSelectedPage] = useState<number>(currentPage || 1);
  const [orderBy, setOrderBy] = useState<'firstName' | 'lastName' | undefined>();
  const [orderByDirection, setOrderByDirection] = useState<'ASC' | 'DESC'>('ASC');

  const patientName = getQuery('patientName') || '';

  const getPatientsKey = ['getPatients', patientName, selectedPage, orderBy, orderByDirection];
  const {
    isLoading: isLoadingPatients,
    error: fetchPatientsError,
    data: resPatients,
  } = useQuery<PatientDirectorySearchResult, ErrorResponse>({
    queryKey: getPatientsKey,
    queryFn: () =>
      getPatients({
        authProvider: authProvider,
        patientName: patientName.trim(),
        page: selectedPage,
        pageSize: pageSize,
        orderBy: orderBy,
        orderByDirection: orderByDirection,
      }),
    refetchOnWindowFocus: false,
  });

  const onNewPageSelected = (page: number) => {
    setSelectedPage(page);
    updateQuery({ page: page.toString() });
  };

  const onPatientKeyDown = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const currentName = e.currentTarget.value;
        setSelectedPage(1);
        runFTrack({
          event: 'Search By Patient Name',
          workflow: PatientWorkflow,
          action: SideEffectTrackingAction,
          context: 'patientDirectory',
          componentId: 'patientDirectorySearchInput',
        });
        updateQuery({ patientName: currentName });
        // setHasSearched(true);
      }
    },
    [updateQuery]
  );

  const onSearchClick = useCallback(async () => {
    setSelectedPage(1);
    updateQuery({ patientName: patientNameDisplay });
    // setHasSearched(true);
  }, [updateQuery, patientNameDisplay]);

  const onPatientInputChange = useCallback((newValue: string | null) => {
    if (newValue === null) {
      setPatientNameDisplay('');
    } else {
      setPatientNameDisplay(newValue);
    }
  }, []);

  const onClear = () => {
    // setHasSearched(false);
    setQuery({});
  };

  const buttonStyling = 'bg-primary text-white hover:bg-opacity-[75%]';

  return (
    <>
      {fetchPatientsError && <Alert {...errorAlert(fetchPatientsError)} />}
      <div id="header" className="flex flex-row w-full">
        <div className="font-semibold text-2xl grow text-start">Patient Directory</div>
      </div>
      <div className="flex flex-row gap-2 py-4">
        <div className="patient-search-wrapper flex flex-row gap-10 text-sm bg-white rounded-lg w-1/3 h-12">
          <PatientFilterInput
            onKeyDown={onPatientKeyDown}
            customStyling="border border-1 rounded-lg border-rule w-full h-12 items-center"
            onChange={onPatientInputChange}
            patientNameInputValue={patientNameDisplay}
            placeholder="Search by patient name"
            isSearchBox
            allowClear={true}
            onClear={onClear}
          />
        </div>
        <div className="h-10 flex flex-row items-center gap-2 py-4">
          <Button
            onClick={onSearchClick}
            disabled={patientNameDisplay.trim() === ''}
            className={`${buttonStyling} text-white items-center`}
            workflow={PatientWorkflow}
            trackingLabel="Search Patients Button"
            context="patientDirectory"
          >
            Search Patients
          </Button>
          <div
            onClick={() => setIsModalOpen(true)}
            className="text-base-content-gray ml-4 cursor-pointer underline"
          >
            <FontAwesomeIcon
              icon={faUserPlus}
              className="mr-2"
              style={{
                color: '#AAAAAA',
              }}
            />
            Add New Patient
          </div>
        </div>
      </div>
      <div className="rounded-lg">
        <PatientsTable
          patientData={resPatients?.results || []}
          isLoadingDisplayPatients={isLoadingPatients}
          setOrderBy={setOrderBy}
          setOrderByDirection={setOrderByDirection}
        />
        {practiceId && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            shape="big-square"
          >
            <CreatePatientForm
              authProvider={authProvider}
              practiceId={practiceId}
              onCloseClick={() => setIsModalOpen(false)}
            />
          </Modal>
        )}
      </div>
      {resPatients && (
        <NewPagination
          currentPage={selectedPage}
          pageSize={pageSize}
          totalItemCount={resPatients.totalCount / pageSize}
          onPageChange={onNewPageSelected}
          tracking={{
            workflow: PatientWorkflow,
            context: 'patientDirectory',
          }}
        />
      )}
    </>
  );
};

export default PatientDirectory;
