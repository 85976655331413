import React, { forwardRef, useCallback, useState } from 'react';

import WithOverlay from '../WithOverlay';

export type LinkTextProps = {
  loading?: boolean;
  disabled?: boolean | string;
  noUnderline?: boolean;
} & Omit<React.HTMLProps<HTMLElement>, 'disabled'>;

const LinkText = forwardRef<HTMLElement, LinkTextProps>(
  ({ children, className, loading, disabled, noUnderline, onClick, ...props }, ref) => {
    const [isHovering, setIsHovering] = useState(false);

    let isDisabled: boolean;
    let disabledReason = '';
    if (typeof disabled === 'string') {
      isDisabled = Boolean(disabled);
      disabledReason = disabled;
    } else {
      isDisabled = Boolean(disabled);
    }

    const onMouseEnter = useCallback(() => {
      setIsHovering(true);
    }, []);

    const onMouseLeave = useCallback(() => {
      setIsHovering(false);
    }, []);

    if (isDisabled) {
      return (
        <WithOverlay
          showing={isHovering}
          xOffset={-60}
          yOffset={40}
          overlayComponent={<span className="w-full">{disabledReason}</span>}
          componentContainerCustom="min-w-10"
        >
          <div
            className="link-text-container flex-row  m-2"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <span className={`${className}`} ref={ref} {...props}>
              {children}
            </span>
          </div>
        </WithOverlay>
      );
    }

    return (
      <div className="link-text-container flex-row text-left">
        <span
          className={`${noUnderline ? '' : 'underline'} ${className} cursor-pointer`}
          ref={ref}
          onClick={onClick}
          {...props}
        >
          {children}
        </span>
        {loading && (
          <span
            className="spinner-border spinner-border-sm ml-2"
            role="status"
            aria-hidden="true"
            aria-label="loading"
          />
        )}
      </div>
    );
  }
);

export default LinkText;
