import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Tooltip } from 'react-tooltip';

import { InvoicingWorkflow, runFTrack, SideEffectTrackingAction } from '../../amplitude';
import { createInvoice, legacyPayInvoice, splitPayment } from '../../API/invoices';
import { AuthProvider } from '../../Authentication/Authentication';
import { Appointment } from '../../ServiceContext/appointments';
import {
  Invoice,
  isSplitPayment,
  LegacyPayment,
  SplitPayment,
  TransactionPaymentTypeInsurance,
} from '../../ServiceContext/invoices';
import Alert, { AlertData, errorAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import LoadingSpinner from '../../shared/LoadingSpinner';
import { isNumber } from '../../shared/math/math';
import { stringifyMoney } from '../../shared/money/stringifyMoney';
import {
  inferPricingSystemVersionFromInvoice,
  PricingSystemVersion,
} from '../../shared/pricingSystem';
import ReviewPatientInsuranceModal from '../ReviewPatientInsuranceModal/ReviewPatientInsuranceModal';
import PaymentEntry, {
  isSplitPaymentMethodValid,
  SplitPaymentMethod,
} from './PaymentEntry/PaymentEntry';
import {
  createEmptyPaymentAllocationEntry,
  getDefaultPaymentSplits,
  getUnusedPatientPaymentMethods,
  PaymentAllocationEntry,
} from './paymentUtils';
import { isInvoicePaidEntirelyByPromos } from './utilities';

type Props = {
  appointment: Appointment;
  onAppointmentUpdated: (newAppointment: Appointment) => void;
  onPaymentCompleted: (payment: SplitPayment | LegacyPayment) => void;
  authProvider: AuthProvider;
  invoice: Invoice;
  processorName: string;
  refetchAppointment: () => void;
  setUsingInsurance: (usingInsurance: boolean) => void;
};

const buttonStyling = 'bg-primary text-white hover:opacity-75 disabled:opacity-50';

const EnterPaymentAmount: React.FC<Props> = ({
  appointment,
  onAppointmentUpdated,
  onPaymentCompleted,
  invoice,
  authProvider,
  processorName,
  refetchAppointment,
  setUsingInsurance,
}) => {
  const { user: patient } = appointment;

  const invoiceRemainingTotal = invoice.isDraft
    ? invoice.total
    : isNumber(invoice.balance)
    ? invoice.balance
    : invoice.total;

  const defaultPaymentSplits = getDefaultPaymentSplits(appointment, invoice);

  const [paymentAllocations, setPaymentAllocations] = useState<PaymentAllocationEntry[]>(
    defaultPaymentSplits || []
  );
  const [remainingBalance, setRemainingBalance] = useState<number>(invoiceRemainingTotal);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [splitCount, setSplitCount] = useState<number>(1);
  const [isShowingReviewInsuranceModal, setIsShowingReviewInsuranceModal] = useState(false);

  function isInsurancePayment() {
    return paymentAllocations.some((pa) => pa.paymentInfo.methodType === 'insurance');
  }

  const doInvoiceProcess = () => {
    if (isInsurancePayment()) {
      legacyProcessInvoiceMutation.mutate({
        authProvider,
        appointmentId: appointment.id,
        processorName: processorName,
        paymentType: TransactionPaymentTypeInsurance,
      });
    } else {
      const unprocessedPaymentAllocations = paymentAllocations.filter(
        (pa) => pa.previousStatus !== 'processed'
      );

      processInvoiceMutation.mutate({
        authProvider,
        appointmentId: appointment.id,
        paymentSplits: unprocessedPaymentAllocations.map((allocationEntry) => {
          let paymentMethodId: string = '';
          if (allocationEntry.paymentInfo.methodType === 'credit-card') {
            paymentMethodId = allocationEntry.paymentInfo.stripePaymentMethod?.id || '';
          }

          return {
            splitAmount: allocationEntry.paymentInfo.amount,
            paymentMethodId,
            paymentType: allocationEntry.paymentInfo.methodType,
            otherFinancingDetails: '',
          };
        }),
      });
    }
  };

  const createInvoiceMutation = useMutation(createInvoice, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: doInvoiceProcess,
    onError: (error) => {
      setIsLoading(false);
      setAlert(errorAlert(error as any));
    },
  });

  const legacyProcessInvoiceMutation = useMutation(legacyPayInvoice, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (res) => {
      onPaymentCompleted(res);
    },
    onError: (error) => {
      setIsLoading(false);
      setAlert(errorAlert(error as any));
    },
  });

  const processInvoiceMutation = useMutation(splitPayment, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (res) => {
      if (res.status === 'denied') {
        setIsLoading(false);
        logPaymentFailure({ error: 'Payment was denied.' });
        setAlert(
          errorAlert('Payment was denied. Please review split payment allocations and try again.')
        );
        onAppointmentUpdated({
          ...appointment,
          payment: res,
        });
      } else if (res.status === 'partially_processed') {
        setIsLoading(false);
        logPaymentFailure({ error: 'Payment only partially succeeded.' });
        setAlert(
          errorAlert(
            'Payment only partially succeeded. Please review split payment allocations and try again.'
          )
        );
        onAppointmentUpdated({
          ...appointment,
          payment: res,
        });
        refetchAppointment();

        const paymentMethodStatusMap: { [key: string]: string } = {};
        res.paymentSplits.forEach((split) => {
          if (split.paymentMethod) {
            if (paymentMethodStatusMap[split.paymentMethod.id] === 'processed') {
              return;
            }
            paymentMethodStatusMap[split.paymentMethod.id] = split.status;
          }
        });

        const updatedPaymentAllocations = paymentAllocations.map((pa) => {
          if (paymentMethodStatusMap[pa.paymentInfo.id] === 'processed') {
            return {
              paymentInfo: pa.paymentInfo,
              previousStatus: 'processed',
            } as PaymentAllocationEntry;
          }
          return pa;
        });

        setPaymentAllocations(updatedPaymentAllocations);
      } else {
        logPaymentSuccess();
        onPaymentCompleted(res);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      logPaymentFailure({ error: 'Payment failed: ' + error });
      setAlert(errorAlert(error as any));
    },
  });

  // Function to log a successful payment
  const logPaymentSuccess = () => {
    runFTrack({
      workflow: InvoicingWorkflow,
      event: 'Payment Success',
      action: SideEffectTrackingAction,
      componentId: 'processTransactionButton',
      context: 'invoicing',
    });
  };

  const logPaymentFailure = ({ error }: { error: string }) => {
    runFTrack({
      workflow: InvoicingWorkflow,
      event: 'Payment Failure',
      action: SideEffectTrackingAction,
      context: 'invoicing',
      componentId: 'processTransactionButton',
      extraProps: { error },
    });
  };

  const procedureItems = invoice.invoiceItems.filter((i) => i.type === 'procedure');
  const billingCodes = procedureItems.map((item) => item.code);

  const onProcessTransactionButtonClick = () => {
    if (
      paymentAllocations.filter(
        (pa) => pa.paymentInfo.amount === 0 && pa.paymentInfo.methodType !== 'insurance'
      ).length > 0
    ) {
      setAlert(errorAlert('Please remove any payments with $0.'));
      return;
    }
    if (inferPricingSystemVersionFromInvoice(invoice) === PricingSystemVersion.V1) {
      createInvoiceMutation.mutate({
        authProvider,
        appointmentId: appointment.id,
        billingCodes,
      });
    } else {
      setAlert(null);

      // In the case of pricing > v1, the invoice we have here will be a draft invoice, and thus we
      // don't need to start the processing chain with a creation call, and can instead simply
      // go straight to processing the invoice.
      doInvoiceProcess();
    }
  };

  const changePaymentMethod = (updatedPaymentMethod: SplitPaymentMethod) => {
    setAlert(null);
    setPaymentAllocations(
      paymentAllocations.map((p) =>
        p.paymentInfo.id === updatedPaymentMethod.id
          ? {
              paymentInfo: {
                ...updatedPaymentMethod,
                amount:
                  updatedPaymentMethod.methodType === 'insurance'
                    ? 0
                    : p.paymentInfo.amount || invoice.total,
              },
              previousStatus: null,
            }
          : p
      )
    );
  };

  const addPayment = () => {
    setSplitCount(splitCount + 1);
    setAlert(null);
    setPaymentAllocations([
      ...paymentAllocations,
      createEmptyPaymentAllocationEntry(remainingBalance),
    ]);
  };

  const removePayment = (id: string) => {
    setAlert(null);
    setSplitCount(splitCount - 1);
    const updatedPaymentAllocations = paymentAllocations.filter(
      (payment) => payment.paymentInfo.id !== id
    );
    setPaymentAllocations(updatedPaymentAllocations);
  };

  function checkForInsurance() {
    if (paymentAllocations.filter((pa) => pa.paymentInfo.methodType === 'insurance').length > 0) {
      setUsingInsurance(true);
    } else {
      setUsingInsurance(false);
    }
  }

  useEffect(() => {
    checkForInsurance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAllocations]);

  const changePaymentAmount = (id: string, amountInCents: number | undefined) => {
    setAlert(null);

    setPaymentAllocations((prevPayments) =>
      prevPayments.map((payment) =>
        payment.paymentInfo.id === id
          ? {
              paymentInfo: { ...payment.paymentInfo, amount: amountInCents || 0 },
              previousStatus: payment.previousStatus,
            }
          : payment
      )
    );
  };

  useEffect(() => {
    const isInsurancePayment = paymentAllocations.some(
      (pa) => pa.paymentInfo.methodType === 'insurance'
    );

    if (isInsurancePayment) {
      setRemainingBalance(0);
    } else {
      const totalPaid = paymentAllocations
        .filter((pa) => pa.previousStatus !== 'processed')
        .map((payment) => payment.paymentInfo.amount)
        .reduce((sum, amount) => sum + (amount || 0), 0);
      const balance = invoiceRemainingTotal - totalPaid;

      setRemainingBalance(balance);
    }
  }, [paymentAllocations, invoiceRemainingTotal]);

  const unusedPatientPaymentMethods = getUnusedPatientPaymentMethods(patient, paymentAllocations);

  const paymentEntries = paymentAllocations.map((pm, idx) => (
    <PaymentEntry
      key={pm.paymentInfo.id}
      idx={idx}
      appointment={appointment}
      splitPaymentMethod={pm.paymentInfo}
      unusedPaymentMethods={unusedPatientPaymentMethods}
      patientTotalCardsOnFileCount={(patient.paymentMethods || []).length}
      handleMoneyInputBlur={(
        e: React.FocusEvent<HTMLInputElement>,
        valueInCents: number | undefined
      ) => {
        changePaymentAmount(pm.paymentInfo.id, valueInCents);
      }}
      addPayment={addPayment}
      removePayment={removePayment}
      onPaymentMethodChange={changePaymentMethod}
      onAppointmentUpdated={onAppointmentUpdated}
      payment={isSplitPayment(appointment.payment) ? appointment.payment : null}
      totalSplits={splitCount}
    />
  ));

  const paymentsMatchBalance = remainingBalance === 0;
  const allPaymentsValid = paymentAllocations.every((pa) =>
    isSplitPaymentMethodValid(pa.paymentInfo)
  );

  let hasValidPaymentAllocationCount = paymentAllocations.length > 0;
  if (!hasValidPaymentAllocationCount && remainingBalance === 0) {
    // Special case here where promo code may wipe out entire balance.
    hasValidPaymentAllocationCount = isInvoicePaidEntirelyByPromos(invoice);
  }

  const isProcessTransactionDisabled =
    !isInsurancePayment() &&
    (!paymentsMatchBalance || !hasValidPaymentAllocationCount || !allPaymentsValid);

  const renderAlert = () => {
    if (alert) {
      return (
        <div className="px-2 w-full">
          <Alert {...alert} />
        </div>
      );
    }

    return null;
  };

  return (
    <div id={'payments'} className={'bg-white rounded-md flex flex-col gap-2 items-start '}>
      <span className={'font-bold text-lg mt-4 pl-6 '}>Enter Payment Amount</span>
      {renderAlert()}
      <div
        id={'headers'}
        className={
          'flex flex-row justify-between font-bold text-sm border-b border-rule py-2 w-full'
        }
      >
        <span className={'pl-6'}>Payments</span>
        <span className={'text-left w-1/2'}>Payment Method</span>
        <span className={'pr-12'}>Amount</span>
      </div>
      {paymentEntries}
      {!isInsurancePayment() && (
        <div className={'w-full p-3'}>
          <button
            onClick={addPayment}
            id={'split-payment-button'}
            className={
              'bg-highlight text-xs font-semibold text-primary rounded-md p-2.5 hover:opacity-75 w-full'
            }
          >
            + Split this Payment
          </button>
        </div>
      )}
      <div className="separator w-full my-6 border border-rule border-b" />
      <div className="w-full px-4">
        <div
          id={'remaining-balance'}
          className={`flex flex-row justify-between font-semibold text-base mb-4 w-full px-4 py-2 ${
            remainingBalance > 0 ? 'bg-error' : 'bg-success'
          }`}
        >
          <div
            className={`${remainingBalance > 0 ? 'text-error-content' : 'text-success-content'}`}
          >
            Remaining Balance
          </div>
          <div
            className={`${remainingBalance > 0 ? 'text-error-content' : 'text-success-content'}`}
          >
            {stringifyMoney(remainingBalance, { includeCommas: true })}
          </div>
        </div>
      </div>
      <div id={'process-transaction-button'} className={'flex flex-row w-full justify-center my-4'}>
        {isInsurancePayment() ? (
          <Button
            onClick={() => setIsShowingReviewInsuranceModal(true)}
            className={buttonStyling}
            workflow={InvoicingWorkflow}
            trackingLabel="Review Insurance Button"
          >
            Review Insurance
          </Button>
        ) : isProcessTransactionDisabled ? (
          <div
            data-tooltip-id="process-transaction-tooltip"
            data-tooltip-content={
              remainingBalance !== 0
                ? `Remaining balance is not $0. Add the remaining balance to your split payments in order to process transaction.`
                : `Please select a payment method to process transaction.`
            }
            data-tooltip-place="bottom"
          >
            <Button
              className="!opacity-50"
              trackingLabel="Process Transaction Button"
              workflow={InvoicingWorkflow}
            >
              Process Transaction
            </Button>
          </div>
        ) : (
          <Button
            onClick={onProcessTransactionButtonClick}
            className={buttonStyling}
            disabled={isProcessTransactionDisabled}
            workflow={InvoicingWorkflow}
            trackingLabel="Process Transaction Button"
          >
            {isLoading && <LoadingSpinner size={'sm'} className={'mr-2'} />}
            Process Transaction
          </Button>
        )}
      </div>
      <Tooltip id="process-transaction-tooltip" />
      {isShowingReviewInsuranceModal && (
        <ReviewPatientInsuranceModal
          authProvider={authProvider}
          appointment={appointment}
          onAppointmentUpdated={onAppointmentUpdated}
          onCancel={() => setIsShowingReviewInsuranceModal(false)}
          workflow={InvoicingWorkflow}
          onProcessTransactionClicked={() => {
            setIsShowingReviewInsuranceModal(false);
            onProcessTransactionButtonClick();
          }}
        />
      )}
    </div>
  );
};

export default EnterPaymentAmount;
