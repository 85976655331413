import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { PatientWorkflow } from '../../../amplitude';
import { ErrorResponse } from '../../../API/response';
import {
  DetailedTreatmentPlan,
  getDetailedTreatmentPlans,
  GetDetailedTreatmentPlansResponse,
} from '../../../API/treatmentPlans';
import UploadTreatmentPlanForm from '../../../Appointments/TreatmentPlans/UploadTreatmentPlanForm';
import UploadTreatmentPlanModal from '../../../Appointments/TreatmentPlans/UploadTreatmentPlanModal';
import { AuthProvider } from '../../../Authentication/Authentication';
import { Documents } from '../../../HomePage/components/Icons';
import { PatientDetail } from '../../../ServiceContext/patients';
import Alert, { AlertData, errorAlert, successAlert } from '../../../shared/Alert';
import Button from '../../../shared/Button/Button';
import PortalContainer from '../../../shared/Container/PortalContainer';
import { toShortDateString, toShortTimeString } from '../../../shared/dates/dates';
import Modal from '../../../shared/Modal/Modal';
import Table, { ColumnDef } from '../../../shared/Table/Table';
import { PatientContext } from '../PatientDetailPage';

type Props = {
  authProvider: AuthProvider;
};

const PatientTreatmentPlansTable: React.FC<Props> = ({ authProvider }) => {
  const patient = useContext(PatientContext) as PatientDetail;
  const patientId = patient?.id;
  const navigate = useNavigate();
  const [isCreateTreatmentPlanModalOpen, setIsCreateTreatmentPlanModalOpen] = useState(false);
  //ts-ignore
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [isShowingUploadTreatmentPlanModal, setIsShowingUploadTreatmentPlanModal] = useState(false);

  const treatmentPlansQueryKey = ['getDetailedTreatmentPlans', patientId];

  const {
    error: responseError,
    data: detailedTreatmentPlansResponse,
    refetch: refetchTreatmentPlans,
  } = useQuery<GetDetailedTreatmentPlansResponse, ErrorResponse>(treatmentPlansQueryKey, () => {
    let practiceId: string | undefined;
    if (!authProvider.isFlossyAdmin) {
      practiceId = authProvider.authUser?.user?.practices[0].id;
    }
    return getDetailedTreatmentPlans({
      authProvider,
      patientId: patientId,
      practiceId,
    });
  });

  if (responseError) {
    setAlert(errorAlert(responseError));
  }

  if (alert) {
    return <Alert {...alert} />;
  }

  const statusStyling = 'py-1 px-1.5 rounded-2xl bg-primary text-white font-semibold text-xs';

  const generateColumnDef = (): ColumnDef<DetailedTreatmentPlan>[] => {
    return [
      {
        columnKey: 'emptyColumn',
        title: '',
        render: () => {
          return <></>;
        },
        widthProportion: 0.1,
      },
      {
        columnKey: 'tpStatus',
        title: 'Status',
        render: (record: DetailedTreatmentPlan) => {
          return <span className={`tp-status-column ${statusStyling}`}>{record.state}</span>;
        },
        widthProportion: 1,
      },
      {
        columnKey: 'tpName',
        title: 'Name',
        render: (record: DetailedTreatmentPlan) => {
          return <span className={`tp-name-column`}>{record.name ?? 'N/A'}</span>;
        },
        widthProportion: 2,
      },
      {
        columnKey: 'dateCreated',
        title: 'Date Created',
        render: (record: DetailedTreatmentPlan) => {
          const timeZone = record.appointment ? record.appointment.timeZone : undefined;

          return (
            <div className="uploaded-datetime-column">
              <div className="flex items-center gap-2">
                <Documents />
                <div className="flex flex-col items-start gap-1">
                  <span>{toShortDateString(record.createdAt, timeZone)}</span>
                  <span className="time text-xs">
                    {toShortTimeString(record.createdAt, timeZone)}
                  </span>
                </div>
              </div>
            </div>
          );
        },
        widthProportion: 2,
      },
      {
        columnKey: 'dentistName',
        title: 'Dentist Name',
        render: (record: DetailedTreatmentPlan) => {
          return (
            <div>
              {record.dentist?.firstName} {record.dentist?.lastName}
            </div>
          );
        },
        widthProportion: 2,
      },
    ];
  };
  return (
    <div className="w-full">
      <PortalContainer id="patient-treatment-plans-table-container" padding="p-0">
        <Table
          id="patient-treatment-plans-table"
          columns={generateColumnDef()}
          data={detailedTreatmentPlansResponse?.treatmentPlans || []}
          rowKeyGenerator={(record: DetailedTreatmentPlan) => `${record.id}`}
          rowClassName="treatment-plan-row hover:bg-[#f8f9fa] hover:font-bold cursor-pointer"
          onRowClick={(record: DetailedTreatmentPlan) => {
            navigate(`/patients/treatment-plans/${record.id}`);
          }}
        />
        <Modal
          isOpen={isCreateTreatmentPlanModalOpen}
          onRequestClose={() => setIsCreateTreatmentPlanModalOpen(false)}
          shape="big-square"
        >
          <UploadTreatmentPlanForm
            authProvider={authProvider}
            patientId={patientId}
            onTreatmentPlanUploaded={async (d) => {
              setIsCreateTreatmentPlanModalOpen(false);
              setAlert(
                successAlert(
                  `You have successfully created a treatment plan for ${patient?.firstName} ${patient?.lastName}`
                )
              );
              await refetchTreatmentPlans();
            }}
            secondaryActionText="Cancel"
            onSecondaryClick={() => setIsCreateTreatmentPlanModalOpen(false)}
            doAnalysis={true}
            notifyFlossyTeam={false}
          />
        </Modal>
      </PortalContainer>
      <div className="flex justify-end mt-4 items-center gap-2">
        <Button
          workflow={PatientWorkflow}
          context="patientTreatmentPlansTable"
          trackingLabel="Create Treatment Plan Button"
          onClick={() => {
            navigate(`/patients/${patientId}/treatment-plans/new`);
          }}
          className="flex flex-row gap-1.5 items-center hover:bg-opacity-[75%]"
        >
          Create Treatment Plan
        </Button>
        {/*<div>*/}
        {/*  <span className="text-black text-xs font-normal">or </span>*/}
        {/*  <span*/}
        {/*    className="text-base-content text-xs font-normal underline cursor-pointer"*/}
        {/*    onClick={() => {*/}
        {/*      setIsShowingUploadTreatmentPlanModal(true);*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Upload Treatment Plan*/}
        {/*  </span>*/}
        {/*</div>*/}
      </div>
      {isShowingUploadTreatmentPlanModal && (
        <UploadTreatmentPlanModal
          authProvider={authProvider}
          patientId={patientId}
          onCancel={() => setIsShowingUploadTreatmentPlanModal(false)}
          workflow={PatientWorkflow}
          context="patientTreatmentPlansTable"
          onTreatmentPlanUploaded={() => {
            setIsShowingUploadTreatmentPlanModal(false);
            refetchTreatmentPlans().then();
          }}
        />
      )}
    </div>
  );
};

export default PatientTreatmentPlansTable;
