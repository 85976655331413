import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import { ClickTrackingAction, GeneralWorkflow, runFTrack } from '../../amplitude';
import { useSelectedDentist } from '../../Dentists/SelectedDentistContext';
import { Dentist, Practice } from '../../ServiceContext/user';
import ClearButton from '../../shared/ClearButton';
import { usernameFromDentist } from '../../shared/strings';
import { ArrowDown, CrowdIcon } from './Icons';

/**
 * A searchable dropdown select component for selecting a dentist.
 * @param options - The list of dentists to select from.
 * @param onChange - The function to call when a dentist is selected.
 *                   This component will handle updating the selected dentist in the context.
 * @param showSearchField - Whether to show the search field or not.
 * @param disableViewAll
 */
const DentistSearchSelect = ({
  dentists,
  onChange,
  showSearchField,
  disableViewAll,
}: {
  dentists: Dentist[];
  onChange: (option: Dentist | null) => void;
  showSearchField?: boolean;
  disableViewAll?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { selectedDentistId, setSelectedDentistId, search, setSearch } = useSelectedDentist();
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const debouncedSetSearch = useMemo(() => {
    if (search.length < 3) return debounce(() => setDebouncedSearch(search), 0);
    const debounced = debounce(() => setDebouncedSearch(search), 1000);
    return debounced;
  }, [search]);

  useEffect(() => {
    debouncedSetSearch();
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [search, debouncedSetSearch]);

  const practices: Practice[] = dentists
    .map((dentist) => dentist.practice)
    .filter(
      (practice, index, self) =>
        practice &&
        index ===
          self.findIndex((p) => p && practice && p.id && practice.id && p.id === practice.id)
    )
    .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

  const dentistsByPractice = useMemo(() => {
    return dentists.reduce((map, dentist) => {
      if (dentist.practice === null) return map;
      if (!map[dentist.practice.id]) {
        map[dentist.practice.id] = [];
      }
      map[dentist.practice.id].push(dentist);
      return map;
    }, {} as Record<string, typeof dentists>);
  }, [dentists]);

  const [filteredDentistsByPractice, setFilteredDentistsByPractice] = useState(dentistsByPractice);

  useEffect(() => {
    setTimeout(() => {
      if (dentists.length > 50 && (!debouncedSearch || debouncedSearch.length < 3)) {
      } else {
        const filtered = { ...dentistsByPractice };
        for (const practiceId in filtered) {
          filtered[practiceId] = filtered[practiceId].filter((dentist) =>
            usernameFromDentist(dentist).toLowerCase().includes(search.toLowerCase())
          );
          if (filtered[practiceId].length === 0) {
            delete filtered[practiceId];
          }
        }
        setFilteredDentistsByPractice(filtered);
      }
    }, 0);
  }, [dentistsByPractice, debouncedSearch, search, dentists]);

  useEffect(() => {
    const handleGlobalKeyDown = (e: KeyboardEvent) => {
      if (e.key === '/') {
        e.preventDefault();
        setIsOpen(true);
        inputRef.current?.focus();
      }
    };

    window.addEventListener('keydown', handleGlobalKeyDown);

    return () => {
      window.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleOptionClick = (dentist: Dentist | null) => {
    setIsOpen(false);
    if (onChange) {
      onChange(dentist ?? null);
    }
    runFTrack({
      event: 'Select Dentist',
      workflow: GeneralWorkflow,
      action: ClickTrackingAction,
      context: 'dentistSearchSelect',
      componentId: 'dentistSearchSelectDropdown',
      extraProps: {
        dentistId: dentist?.id,
        dentistName: dentist !== null ? usernameFromDentist(dentist) : null,
      },
    });
    setSelectedDentistId(dentist?.id ?? null);
  };

  function getSelectedDentist(): Dentist | undefined {
    return dentists.find((dentist) => dentist.id === selectedDentistId);
  }

  return (
    <div ref={dropdownRef}>
      <button
        type="button"
        className={`w-[350px] bg-secondary text-white inline-flex 
        items-center font-semibold px-4 py-3 text-sm border border-rule rounded-lg`}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={() => setIsOpen(true)}
      >
        <div className="flex flex-row gap-2 items-center grow">
          <div className="h-6 w-6 place-content-center">
            <CrowdIcon />
          </div>
          <div className="line-clamp-1 grow text-start">
            {selectedDentistId
              ? `Viewing: ${
                  getSelectedDentist() !== undefined
                    ? usernameFromDentist(getSelectedDentist()!)
                    : ''
                }`
              : disableViewAll
              ? 'Search for a dentist'
              : 'Viewing: All Dentists'}
          </div>
        </div>
        <ArrowDown />
      </button>

      {isOpen && (
        <div className="origin-top-right absolute mt-2 py-1 w-[350px] rounded-md shadow-lg bg-white z-40 items-start flex flex-col">
          <div className="flex flex-col items-stretch w-full">
            {showSearchField && (
              <div className="flex items-center mx-3 my-1 relative">
                <input
                  autoFocus
                  ref={inputRef}
                  type="text"
                  className="py-2 pl-2 pr-8 grow border-rule rounded-md border focus:outline-base-content-navigation"
                  value={search}
                  onChange={(e) => {
                    if (e.target.value.length >= 3) {
                      setSearch(e.target.value);
                    } else {
                      setSearch(e.target.value);
                      setDebouncedSearch('');
                    }
                  }}
                  placeholder="Search for a dentist..."
                />
                {search && (
                  <div className="absolute top-[1px] right-[2px]">
                    <ClearButton
                      onClick={() => {
                        setFilteredDentistsByPractice(dentistsByPractice);
                        setDebouncedSearch('');
                        setSearch('');
                        if (onChange) {
                          onChange(null);
                        }
                        if (inputRef.current !== null) {
                          inputRef.current.focus();
                        }
                        setHighlightedIndex(0);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {dentists.length < 50 && !disableViewAll && (
              <button
                className={`block w-full text-left px-4 py-2 text-sm text-base-content hover:bg-base-200`}
                key={'All'}
                onClick={() => handleOptionClick(null)}
              >
                View All
              </button>
            )}
            {((dentists.length > 50 && debouncedSearch.length >= 3) || dentists.length <= 50) && (
              <div className="items-start justify-start flex flex-col">
                {practices.map((practice) => {
                  const noResults =
                    !filteredDentistsByPractice[practice.id] ||
                    filteredDentistsByPractice[practice.id].length === 0;

                  const searchMatchesPractice = practice.name
                    ? practice.name.toLowerCase().includes(search.toLowerCase())
                    : false;

                  const practiceDentists = searchMatchesPractice
                    ? dentistsByPractice[practice.id]
                    : filteredDentistsByPractice[practice.id];
                  if (search && !practice.name && noResults) {
                    return null;
                  }
                  if (
                    noResults &&
                    practice.name &&
                    !practice.name.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return null;
                  } else {
                    return (
                      <div key={practice.id}>
                        <div className="flex flex-row items-center gap-2 mt-3 mx-4">
                          <div className="h-4 w-4 place-content-center">
                            <CrowdIcon color="black" />
                          </div>
                          <div className="text-start font-semibold text-sm">
                            {practice.name ?? 'No  Practice Name'}
                          </div>
                        </div>

                        {practiceDentists &&
                          practiceDentists.map((dentist, index) => (
                            <button
                              className={`block w-full text-left px-4 py-2 text-sm text-base-content hover:bg-base-200 ${
                                index === highlightedIndex ? 'bg-base-100' : ''
                              }`}
                              key={dentist.id}
                              onClick={() => handleOptionClick(dentist)}
                            >
                              {usernameFromDentist(dentist)}
                            </button>
                          ))}
                      </div>
                    );
                  }
                })}
              </div>
            )}
            {dentists.length > 50 && debouncedSearch.length < 3 && (
              <div className="text-start px-4 py-2 text-sm">
                {dentists.length} Results - Enter at least 3 characters to search
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DentistSearchSelect;
