import { useCallback, useState } from 'react';

import { BookingsAndCreditsWorkflow } from '../../../amplitude';
import {
  Booking,
  BookingCharge,
  BookingChargeCredit,
  BookingLedger,
  PaymentHistoryRecord,
} from '../../../API/bookingsAndCredits';
import { QueryContext } from '../../../API/context';
import { AuthProvider } from '../../../Authentication/Authentication';
import Cookies from '../../../shared/cookies/cookies';
import { useURLQuery } from '../../../shared/routing/routing';
import NewPagination from '../../../shared/Table/NewPagination';
import { bookingSortFunctions } from '../../../shared/Table/SortFunction';
import BookingsAndCreditStats from './B&CStats/BookingsAndCreditsStats';
import BCTable from './BCTable';
import { getLedgerPaymentStatus } from './bookingLedgerManipulation';
import PastMonthBalanceSummary from './PastMonthBalanceSummary';
import { MonthlyLedger } from './PastMonthsLedgerTable';
import useTestOnlyTriggerMonthlyBookingsAndCreditsNotifications from './TestOnlyTriggerMonthlyBookingsAndCreditsNotifications';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  selectedPeriod: string;
  data: MonthlyLedger[];
  dateOrderByDirection: 'ASC' | 'DESC';
  setDateOrderByDirection: React.Dispatch<React.SetStateAction<'ASC' | 'DESC'>>;
  queryContext: QueryContext;
  paymentHistoryRecords: PaymentHistoryRecord[];
  allCreditItems: BookingChargeCredit[];
};

const SinglePastMonthView: React.FC<Props> = ({
  authProvider,
  practiceId,
  data,
  selectedPeriod,
  dateOrderByDirection,
  setDateOrderByDirection,
  queryContext,
  paymentHistoryRecords,
  allCreditItems,
}) => {
  const pageSize = 6;
  const { query, updateQuery } = useURLQuery();
  const currentPage = parseInt(query.get('page') || '1', 10);

  const [selectedPage, setSelectedPage] = useState<number>(currentPage || 1);

  const setCookies = useCallback(
    (newParam: { [p: string]: string | null | undefined }) => {
      Cookies.bookingSearchMemory.set(updateQuery(newParam));
    },
    [updateQuery]
  );
  const selectedMonthlyLedger = data.find(
    (monthlyLedger) => monthlyLedger.monthYear === selectedPeriod
  );

  const onNewPageSelected = useCallback(
    (page: number) => {
      setCookies({ page: page.toString() });
      setSelectedPage(page);
    },
    [setCookies]
  );

  const applyRefundedCharge = useCallback(
    (updatedCharge: BookingCharge) => {
      const { client, key } = queryContext;

      const currentData = client.getQueryData<BookingLedger>(key);

      if (currentData && currentData.monthlyBreakdown[selectedPeriod]) {
        const index = currentData.monthlyBreakdown[selectedPeriod].bookings.findIndex(
          (booking) => booking.charge?.id === updatedCharge.id
        );
        if (index === -1) {
          return;
        }

        currentData.monthlyBreakdown[selectedPeriod].bookings[index] = {
          ...currentData.monthlyBreakdown[selectedPeriod].bookings[index],
          charge: updatedCharge,
        };

        client.setQueryData(key, currentData);
      }
    },
    [selectedPeriod, queryContext]
  );

  const { component: TestOnlyTriggerMonthlyBookingsAndCredits } =
    useTestOnlyTriggerMonthlyBookingsAndCreditsNotifications({
      authProvider,
      practiceId,
      period: selectedPeriod,
    });

  if (!selectedMonthlyLedger) {
    return null;
  }

  const selectedLedgerPaymentStatus = getLedgerPaymentStatus(
    selectedMonthlyLedger,
    paymentHistoryRecords
  );

  let paginatedBookings: Booking[] = [];
  if (selectedMonthlyLedger.ledgerMonth.bookings) {
    const startIndex = (selectedPage - 1) * pageSize;
    let sortedBookings: Booking[];
    if (dateOrderByDirection === 'ASC') {
      sortedBookings = selectedMonthlyLedger.ledgerMonth.bookings.sort(
        bookingSortFunctions.ascending
      );
    } else {
      sortedBookings = selectedMonthlyLedger.ledgerMonth.bookings.sort(
        bookingSortFunctions.descending
      );
    }
    paginatedBookings = sortedBookings.slice(startIndex, startIndex + pageSize);
  }

  return (
    <div id={'single-past-month-view'} className={'flex flex-row gap-4'}>
      <div id={'stats-and-summary'} className={'flex flex-col gap-3 w-1/3'}>
        {TestOnlyTriggerMonthlyBookingsAndCredits}
        <PastMonthBalanceSummary
          authProvider={authProvider}
          practiceId={practiceId}
          selectedMonthlyLedger={selectedMonthlyLedger}
          ledgerMonth={selectedMonthlyLedger.ledgerMonth}
          selectedLedgerPaymentStatus={selectedLedgerPaymentStatus}
        />
        <BookingsAndCreditStats
          breakdown={selectedMonthlyLedger.ledgerMonth.breakdown}
          totalCredits={selectedMonthlyLedger.ledgerMonth.credits.sum}
          totalCreditsCount={selectedMonthlyLedger.ledgerMonth.credits.count}
          charges={selectedMonthlyLedger.ledgerMonth.charges}
        />
      </div>
      <div id={'table-and-pagination'} className="w-full overflow-x-hidden w-2/3">
        <BCTable
          authProvider={authProvider}
          bookingData={paginatedBookings}
          practiceId={practiceId}
          onRefundCompleted={applyRefundedCharge}
          setDateOrderByDirection={setDateOrderByDirection}
          workflow={BookingsAndCreditsWorkflow}
          creditItems={allCreditItems}
        />
        <NewPagination
          currentPage={currentPage}
          pageSize={pageSize}
          totalItemCount={selectedMonthlyLedger.ledgerMonth.bookings.length}
          onPageChange={onNewPageSelected}
          tracking={{
            workflow: BookingsAndCreditsWorkflow,
            context: 'bookingsAndCredits',
          }}
        />
      </div>
    </div>
  );
};

export default SinglePastMonthView;
