import { faArrowDown, faArrowUp, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { useMemo, useState } from 'react';

import { isNumber } from '../math/math';
import WithOverlay from '../WithOverlay';
import Pagination from './Pagination';

type TableSorting = {
  columnIdx: number;
  direction: 'asc' | 'desc';
};

export interface ColumnDef<RecordType> {
  columnKey: string;
  title: string | React.ReactNode;
  render: (record: RecordType, idx: number) => React.ReactNode | string;
  colClassName?: string | ((record: RecordType) => string);
  onFilterIconClick?: (columnId: number) => void;
  filterOptions?: {
    filterIconRender: () => React.ReactNode;
    contentRender: () => React.ReactNode;
    filterOverlayXOffset?: number;
    filterOverlayYOffset?: number;
  };
  // The proportion of the table's total width taken by this table.
  // If all columns are the same number, they will all share an equal
  // part of the table.
  widthProportion: number;
  // Method to sort the local data.
  // Does not refetch data from the server.
  // Column will be sortable if sortable or sortableRemote is defined.
  sortable?: {
    ascending: (a: RecordType, b: RecordType) => number;
    descending: (a: RecordType, b: RecordType) => number;
  };
  // Callbacks to refetch data from the server.
  sortableRemote?: {
    ascending: () => void;
    descending: () => void;
  };
}

export interface PaginationDef {
  pageSize: number;
  totalItemCount?: number;
  onPageChange?: (newPage: number) => void;
  // Must be 1-indexed
  initialPage?: number;
}

interface Props<RecordType> {
  id: string;
  columns: ColumnDef<RecordType>[];
  data: RecordType[];
  rowKeyGenerator: (record: RecordType) => string;
  pagination?: PaginationDef;
  onRowClick?: (record: RecordType) => void;
  tableClassNameExt?: string;
  rowClassName?: string;
  hideTitleRow?: boolean;
  align?: 'start' | 'end' | 'center';
  activeRow?: string;
}

// The number of page changers on the pagination carousel, not including "Previous" and "Next"
// buttons. For obvious reasons, this must never be below 1.
const numCarouselPageSelects = 3;

// Exported only to test
export function calculateCarouselAnchor(carouselSize: number, currentPage: number): number {
  return Math.trunc(currentPage / carouselSize) * carouselSize;
}

function Table<RecordType extends object = any>({
  id,
  columns: propsColumns,
  data,
  rowKeyGenerator,
  pagination,
  onRowClick = () => {},
  tableClassNameExt,
  rowClassName = '',
  hideTitleRow = false,
  align = 'center',
  activeRow,
}: Props<RecordType>) {
  const [selectedPage, setSelectedPage] = useState<number>(pagination?.initialPage || 1);

  const [sorting, setSorting] = useState<TableSorting | null>(null);
  const [openFilters, setOpenFilters] = useState<{ [colIdx: number]: boolean }>({});

  const pageSize = pagination ? pagination.pageSize : data.length;

  const sortedData = useMemo(() => {
    if (!sorting) {
      return data;
    }

    const columnDefinition = propsColumns[sorting.columnIdx];
    if (!columnDefinition) {
      return data;
    }

    if (columnDefinition.sortableRemote) {
      if (sorting.direction === 'asc') {
        columnDefinition.sortableRemote.ascending();
      } else {
        columnDefinition.sortableRemote.descending();
      }
    }

    if (columnDefinition.sortable) {
      if (sorting.direction === 'asc') {
        return data.sort(columnDefinition.sortable.ascending);
      } else {
        return data.sort(columnDefinition.sortable.descending);
      }
    }

    return data;
  }, [data, sorting, propsColumns]);

  const startOfRenderingSlice = (selectedPage - 1) * pageSize;
  // The Math.min ensures we don't skate past the length of the table's to data on the last page.
  const endOfRenderingSlice = Math.min(startOfRenderingSlice + pageSize, data.length);
  const dataToRender = sortedData.slice(startOfRenderingSlice, endOfRenderingSlice);

  const toggleSorting = ({ columnIndex }: { columnIndex: number }) => {
    let newSorting: TableSorting | null = null;
    if (sorting && sorting.columnIdx === columnIndex) {
      if (sorting.direction === 'asc') {
        newSorting = {
          columnIdx: columnIndex,
          direction: 'desc',
        };
      } else {
        newSorting = {
          columnIdx: columnIndex,
          direction: 'asc',
        };
      }
    } else {
      newSorting = {
        columnIdx: columnIndex,
        direction: 'desc',
      };
    }

    setSorting(newSorting);
  };

  const toggleFilter = (columnIdx: number) => {
    setOpenFilters({
      ...openFilters,
      [columnIdx]: !openFilters[columnIdx],
    });
  };

  let tableClassName: string;
  if (tableClassNameExt) {
    tableClassName = `${tableClassNameExt} rounded-lg table-fixed table`;
  } else {
    tableClassName = ' rounded-lg table-fixed table';
  }

  let columns: ColumnDef<RecordType>[];
  columns = propsColumns;

  const onPageChange = (newPage: number) => {
    setSelectedPage(newPage);
    if (pagination && pagination.onPageChange) {
      pagination.onPageChange(newPage);
    }
  };

  const renderColGroups = () => {
    const totalProportionNumber = columns
      .map((c) => c.widthProportion)
      .reduce((running, current) => running + current, 0);
    const invalidColumnProportions = columns.some((c) => c.widthProportion === 0);
    if (invalidColumnProportions) {
      console.error('columns in a table cannot have a widthProportion value of 0');

      return (
        <colgroup>
          {columns.map((c, idx) => {
            let style: { width: string } = { width: '0px' };
            style.width = `${Math.round((1 / columns.length) * 10000) / 100}%`;
            return <col key={`col-${idx}`} style={style} />;
          })}
        </colgroup>
      );
    }

    return (
      <colgroup>
        {columns.map((c, idx) => {
          let style: { width: string } = { width: '0px' };

          // This trick with Math.round ensures we clamp the number to just a limited number of decimal places
          style.width = `${Math.round((c.widthProportion / totalProportionNumber) * 10000) / 100}%`;
          return <col key={`col-${idx}`} style={style} />;
        })}
      </colgroup>
    );
  };

  const renderTable = () => {
    return (
      <table id={id} className={tableClassName}>
        {renderColGroups()}
        {!hideTitleRow && (
          <thead className="text-sm text-base-content font-semibold">
            <tr className="h-[52px] align-middle">
              {columns.map((c, idx) => {
                let additionalClasses = '';
                if (idx === 0) {
                  additionalClasses = 'px-4 rounded-tl-lg';
                } else if (idx === columns.length - 1) {
                  additionalClasses = 'rounded-tr-lg';
                }
                return (
                  <th
                    key={`${id}-${c.columnKey}-${idx}-table-header`}
                    className={`text-left ${additionalClasses} border-b border-rule`}
                    scope="col"
                    onClick={() => {
                      const isColumnSortable = true;
                      if (isColumnSortable) {
                        return toggleSorting({
                          columnIndex: idx,
                        });
                      }
                    }}
                  >
                    <div className={'flex flex-row items-center'}>
                      {c.title}
                      {(c.sortable || c.sortableRemote) && idx !== sorting?.columnIdx && (
                        <FontAwesomeIcon
                          icon={faSort}
                          style={{ marginLeft: '16px', cursor: 'pointer' }}
                        />
                      )}
                      {(c.sortable || c.sortableRemote) &&
                        idx === sorting?.columnIdx &&
                        (sorting.direction === 'asc' ? (
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            style={{ marginLeft: '16px', cursor: 'pointer' }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faArrowDown}
                            style={{ marginLeft: '16px', cursor: 'pointer' }}
                          />
                        ))}
                      {c.filterOptions && (
                        <WithOverlay
                          showing={openFilters[idx]}
                          xOffset={
                            isNumber(c.filterOptions.filterOverlayXOffset)
                              ? c.filterOptions.filterOverlayXOffset
                              : -28
                          }
                          yOffset={
                            isNumber(c.filterOptions.filterOverlayYOffset)
                              ? c.filterOptions.filterOverlayYOffset
                              : 40
                          }
                          overlayComponent={c.filterOptions.contentRender()}
                          componentContainerCustom={''}
                          overlayPlatformCustom={''}
                        >
                          <button
                            title="Toggle Filter"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleFilter(idx);
                            }}
                          >
                            {c.filterOptions.filterIconRender()}
                          </button>
                        </WithOverlay>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
        )}
        <tbody>
          {dataToRender.map((d, idx) => {
            return (
              <React.Fragment key={`table-fragment-${rowKeyGenerator(d)}`}>
                <tr
                  key={rowKeyGenerator(d)}
                  className={cx({
                    [rowClassName]: true,
                    'bg-base-content-flossy bg-opacity-[15%]': activeRow === rowKeyGenerator(d),
                    'h-[75px]': true,
                    'align-middle': true,
                    'border-b border-rule': idx < 4,
                  })}
                  onClick={() => onRowClick(d)}
                  aria-label="table row"
                >
                  {columns.map((c) => {
                    let colClassName: string | undefined = undefined;
                    if (typeof c.colClassName === 'string') {
                      colClassName = c.colClassName;
                    } else if (typeof c.colClassName === 'function') {
                      colClassName = c.colClassName(d);
                    }
                    return (
                      <td
                        key={`${id}-${rowKeyGenerator(d)}-${c.title}-${c.columnKey}-cell`}
                        className={`${colClassName} text-left text-sm !text-base-content`}
                      >
                        {c.render(d, idx)}
                      </td>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };

  let totalItems: number;
  if (!pagination?.totalItemCount) {
    totalItems = data.length;
  } else {
    totalItems = pagination?.totalItemCount;
  }

  const renderPagination = () => {
    return (
      <Pagination
        currentPage={selectedPage}
        carouselItemNumber={numCarouselPageSelects}
        pageSize={pageSize}
        totalItemCount={totalItems}
        onPageChange={onPageChange}
      />
    );
  };

  return (
    <div
      id={`table-${id}`}
      className={`table-wrapper flex flex-col justify-between w-full items-${align}`}
    >
      <div className="table-content-wrapper w-full">{renderTable()}</div>
      {renderPagination()}
    </div>
  );
}

export default Table;
