import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ClickTrackingAction, PayoutsWorkflow, runFTrack } from '../../amplitude';
import { PayoutCluster, PayoutInvoiceSummary } from '../../ServiceContext/invoices';
import { toLocaleDateTimeString } from '../../shared/dates/dates';
import Money from '../../shared/money/Money';
import { stringifyMoney } from '../../shared/money/stringifyMoney';
import NewPagination from '../../shared/Table/NewPagination';
import { payoutInvoiceSortFunctions } from '../../shared/Table/SortFunction';
import Table from '../../shared/Table/Table';

type Props = {
  timeZone: string;
  selectedPage: number;
  payoutInfo: PayoutCluster[] | null;
  onNewPageSelected: (page: number) => void;
  setDateOrderByDirection?: (direction: 'ASC' | 'DESC') => void;
};

const UpcomingPayoutTable: React.FC<Props> = ({
  timeZone,
  selectedPage,
  payoutInfo,
  onNewPageSelected,
  setDateOrderByDirection,
}) => {
  const [hoverRef, setHoverRef] = useState<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const handleMouseEnter = () => {
    if (hoverRef) {
      hoverRef.style.display = 'block';
    }
  };

  const handleMouseLeave = () => {
    if (hoverRef) {
      hoverRef.style.display = 'none';
    }
  };

  if (!payoutInfo) {
    return (
      <span className={'p-3 bg-white rounded-md w-full'}>No payout information available.</span>
    );
  }
  let allPayoutAmounts: number[] = [];

  if (payoutInfo.length === 1) {
    allPayoutAmounts = payoutInfo[0].summary.invoiceSummaries.map((item) => item.payoutAmount);
  } else if (payoutInfo.length > 1) {
    const allPayoutSummaries = payoutInfo.map((item) => item.summary);
    allPayoutAmounts = allPayoutSummaries
      .flatMap((ps) => ps.invoiceSummaries)
      .flatMap((is) => is.payoutAmount);
  }

  const flossyRecoupAmounts = allPayoutAmounts.filter((amt) => amt < 0);
  const payoutAmountsOwedToDentist = allPayoutAmounts.filter((amt) => amt > 0);
  const flossyRecoupTotal = flossyRecoupAmounts.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const payoutOwedToDentistTotal = payoutAmountsOwedToDentist.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const grossPayoutAmounts = payoutOwedToDentistTotal + flossyRecoupTotal;

  const pageSize = 6;
  let paginatedInvoices: PayoutInvoiceSummary[] = [];
  const startIndex = (selectedPage - 1) * pageSize;
  paginatedInvoices = payoutInfo.flatMap((item) =>
    item.summary.invoiceSummaries.slice(startIndex, startIndex + pageSize)
  );

  const netPayoutAmount = payoutInfo
    .map((item) => item.summary.total)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const renderInfoBubbleContent = () => {
    return (
      <div
        id={'info-bubble-content'}
        className={
          'absolute z-20 p-4 bg-white flex flex-col text-left left-1/4 transform -translate-x-[80%] w-[22%] rounded-md shadow-lg'
        }
        ref={(element) => setHoverRef(element)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ display: 'none' }}
      >
        <span className={'flex font-semibold mb-3'}>Payout Details</span>
        <div id={'gross-payout-amount'} className={'flex text-xs justify-between w-full mb-2'}>
          <span className={'font-semibold'}>Gross Payout Amount</span>
          <span>{stringifyMoney(grossPayoutAmounts, { includeCommas: true })}</span>
        </div>
        <div id={'flossy-recoup'} className={'flex text-xs justify-between w-full mb-2'}>
          <span className={'font-semibold'}>Flossy Fee Recoup from Cash & Financing</span>
          <span>{stringifyMoney(flossyRecoupTotal, { includeCommas: true })}</span>
        </div>
        <div
          id={'net-payout-amount'}
          className={'flex text-xs justify-between w-full mt-2 pt-2 border-t border-t-rule'}
        >
          <span className={'font-semibold'}>Net Payout Amount</span>
          <span className={'font-semibold'}>
            {stringifyMoney(netPayoutAmount, { includeCommas: true })}
          </span>
        </div>
      </div>
    );
  };

  const tableColumns = [
    {
      columnKey: 'space',
      title: '',
      widthProportion: 0.1,
      render: () => {
        return '';
      },
    },
    {
      columnKey: 'appointmentDate',
      title: 'Appointment Date',
      widthProportion: 1,
      render: (record: PayoutInvoiceSummary) => {
        if (!record.appointmentDate) {
          return '---';
        }
        return toLocaleDateTimeString(record.appointmentDate, DateTime.DATE_MED, timeZone);
      },
      sortable: setDateOrderByDirection ? payoutInvoiceSortFunctions : undefined,
    },
    {
      columnKey: 'paidDate',
      title: 'Payment Date',
      widthProportion: 1,
      render: (record: PayoutInvoiceSummary) => {
        if (!record.paidDate) {
          return '---';
        }
        return toLocaleDateTimeString(record.paidDate, DateTime.DATE_MED, timeZone);
      },
    },
    {
      columnKey: 'patientName',
      title: 'Patient Name',
      widthProportion: 1,
      render: (record: PayoutInvoiceSummary) => {
        return record.patientName;
      },
    },
    {
      columnKey: 'paymentType',
      title: 'Payment Type',
      widthProportion: 1,
      render: (record: PayoutInvoiceSummary) => {
        switch (record.paymentType) {
          case 'credit-card':
            return 'Credit Card';
          case 'cash':
            return 'Cash';
          case 'sunbit':
            return 'Sunbit';
          case 'care-credit':
            return 'Care Credit';
          case 'other-financing':
            return `Other Financing`;
          case 'insurance':
            return 'Insurance';
        }
        return '---';
      },
    },
    {
      columnKey: 'total',
      title: 'Payout Amount',
      widthProportion: 1,
      render: (record: PayoutInvoiceSummary) => {
        if (!record.appointmentId) {
          return <Money cents={record.payoutAmount} renderOptions={{ includeCommas: true }} />;
        }

        return <Money cents={record.payoutAmount} renderOptions={{ includeCommas: true }} />;
      },
    },
  ];

  const renderPayoutTotal = () => {
    if (!payoutInfo) {
      return null;
    }

    return (
      <span className="text-[21px] font-bold">
        {stringifyMoney(netPayoutAmount, { includeCommas: true })}
      </span>
    );
  };

  let invoiceCount: number = 0;
  if (payoutInfo) {
    invoiceCount = payoutInfo.flatMap((item) => item.summary.invoiceSummaries).length;
  }

  return (
    <div id={'upcoming-payouts'} className="flex flex-col text-base-content">
      {Boolean(netPayoutAmount) && (
        <div className={'relative'}>
          <div className="upcoming-payout-summary-section flex flex-column items-start bg-white rounded-lg">
            <div id={'pending-payout-summary'} className="flex flex-row gap-2 items-center p-3">
              <label className="font-normal text-[21px] mx-2">Pending Payout: </label>
              {renderPayoutTotal()}
              <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="cursor-pointer"
                  style={{ color: 'lightslategray', opacity: '75%', scale: '0.8' }}
                />
                {renderInfoBubbleContent()}
              </div>
            </div>
          </div>
        </div>
      )}
      {Boolean(netPayoutAmount) && <div className="separator h-[25px]"></div>}
      <Table
        id="upcoming-payouts-table"
        columns={tableColumns}
        data={paginatedInvoices}
        onRowClick={(record: PayoutInvoiceSummary) => {
          runFTrack({
            event: 'Click on Upcoming Payouts Table Row',
            workflow: PayoutsWorkflow,
            action: ClickTrackingAction,
            context: 'upcomingPayoutsTable',
            componentId: 'upcomingPayoutsTableRow',
            extraProps: {
              appointmentId: record.appointmentId,
              invoiceId: record.invoiceId,
            },
          });
          navigate(`/accounting/payouts/appointments/${record.appointmentId}`);
        }}
        rowKeyGenerator={(record: PayoutInvoiceSummary) => `${record.invoiceId}`}
        rowClassName="upcoming-payout-content-row hover:font-bold cursor-pointer"
        align="start"
      />
      {payoutInfo && invoiceCount > pageSize && (
        <NewPagination
          currentPage={selectedPage}
          pageSize={pageSize}
          totalItemCount={invoiceCount}
          onPageChange={onNewPageSelected}
          tracking={{
            workflow: PayoutsWorkflow,
            context: 'upcomingPayoutsTable',
          }}
        />
      )}
    </div>
  );
};

export default UpcomingPayoutTable;
