const ReferralsSentTooltip = () => {
  return (
    <div
      className={
        'absolute z-20 flex flex-col gap-2 text-left top-12 left-6 mt-2 p-4 bg-white rounded-md shadow-lg w-64 -translate-x-[40px]'
      }
    >
      <span className={'text-sm font-semibold'}>Referrals Sent</span>
      <span className={'text-xs'}>
        Referrals Sent are the number of patients you have referred to Flossy overall. Each patient
        can only be sent a referral once -- you can resend their referral email at any time.
      </span>
    </div>
  );
};

export default ReferralsSentTooltip;
