import React, { PropsWithChildren } from 'react';

import { AuthProvider } from '../Authentication/Authentication';
import FirebaseNotificationManagement from '../shared/firebase/FirebaseNotificationManagement';
import AuthedControlPanel from './AuthedControlPanel';
import ProductIdentifiers from './ProductIdentifiers';

interface Props {
  authProvider?: AuthProvider;
}

const Frame: React.FC<PropsWithChildren<Props>> = ({ authProvider, children }) => {
  if (authProvider) {
    return (
      <div id="application-frame" className="">
        <nav
          id="application-navbar"
          className="navbar navbar-dark justify-between bg-secondary min-h-[53px] px-3 !sticky top-0 z-30"
        >
          <ProductIdentifiers />
          {authProvider && <AuthedControlPanel authProvider={authProvider} />}
          <FirebaseNotificationManagement authentication={authProvider} />
        </nav>
        <div id="under-the-nav-bar" className="px-[72px] py-16">
          {children}
        </div>
      </div>
    );
  }
  return null;
};

export default Frame;
