import 'react-datepicker/dist/react-datepicker.css';

import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { isErrorResponse } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import PatientProfileButton from '../../Patients/PatientDirectory/components/PatientProfileButton';
import { RootRedirect } from '../../Routing/Router';
import { Appointment } from '../../ServiceContext/appointments';
import { useServices } from '../../ServiceContext/ServiceContext';
import { Timestamp } from '../../ServiceContext/shared';
import { Dentist } from '../../ServiceContext/user';
import Alert, { AlertData, errorAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import { toApiDateTimeFormat } from '../../shared/dates/dates';
import Modal from '../../shared/Modal/Modal';
import { navigateToAppointments } from '../../shared/routing/routing';
import { SearchSelectOption } from '../../shared/SearchSelect/SearchSelect';
import { usernameFromDentist } from '../../shared/strings';
import { getOfficeTimeZoneForAppointmentBooking } from '../../shared/timezone/timeZone';
import { getColorFromStatus } from '../AppointmentUtils';
import AppointmentAndPatientDetails from './AppointmentAndPatientDetails';
import DentistAndAppointmentSelectors from './DentistAndAppointmentSelectors';
import RescheduleConfirmationModalContent from './RescheduleConfirmationModalContent';

type RescheduleAppointmentProps = {
  dentists: Dentist[];
  authentication: AuthProvider;
  appointment: Appointment;
};

interface EditAppointmentArgs {
  appointmentId: string;
  dentistId: string;
  startTimeInUTC?: string;
  requiresTreatmentPlan?: boolean;
  missingTreatmentPlanReason?: string;
  status?: string;
}

const VerticalSeparator = () => <div className="w-px bg-base-300 mx-4 -translate-y-8"></div>;

const RescheduleAppointment: React.FC<RescheduleAppointmentProps> = ({
  dentists,
  authentication,
  appointment,
}) => {
  const navigate = useNavigate();
  const services = useServices();

  const [alert, setAlert] = useState<AlertData | null>(null);
  const [selectedLocalDateTime, setSelectedLocalDateTime] = useState<Date | null>(null);
  const [isEditingAppointment, setIsEditingAppointment] = useState(false);

  const defaultSelectedDentist = {
    id: appointment.dentist.id,
    label: usernameFromDentist(appointment.dentist),
    renderContent: (
      <div className="dentist-search-option py-2 px-2 text-left">
        {appointment.dentist.firstName} {appointment.dentist.lastName}{' '}
        <span className="dentist-search-option-username font-semibold">
          ({appointment.dentist.username})
        </span>
      </div>
    ),
  };
  const [selectedDentistId, setSelectedDentistId] = useState<string | null>(
    defaultSelectedDentist.id
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rescheduleTime, setRescheduleTime] = useState<Timestamp>('');

  const { appointmentId } = useParams<{ appointmentId: string }>();

  const onNewDentistSelected = useCallback((o: SearchSelectOption) => {
    setSelectedDentistId(o.id);
  }, []);

  const returnToAppointments = useCallback(() => {
    if (!appointment) {
      return null;
    }
    navigateToAppointments({ navigate });
  }, [navigate, appointment]);

  if (!appointmentId) {
    return <RootRedirect authentication={authentication} />;
  }

  if (!appointment || !appointment.user) {
    return null;
  }

  const renderControlButtons = () => {
    return (
      <div id={'control-buttons'} className="flex flex-row gap-2 w-full mb-8">
        <>
          <Button
            id="save-appointment-button"
            onClick={onRescheduleAppointmentClick}
            className="bg-primary text-white font-semibold hover:opacity-75 disabled:opacity-50"
            loading={isEditingAppointment}
            disabled={!selectedLocalDateTime || !selectedDentistId}
            workflow="Reschedule Appointment"
            trackingLabel="Reschedule Appointment Button"
            context="rescheduleAppointment"
          >
            Reschedule Appointment
          </Button>
          <Button
            id="cancel-button"
            onClick={returnToAppointments}
            color="tertiary"
            noFill
            className="shadow-none hover:opacity-75"
            aria-label="Cancel"
            workflow="Reschedule Appointment"
            trackingLabel="Cancel Reschedule Appointment Button"
            context="rescheduleAppointment"
          >
            Cancel
          </Button>
        </>
      </div>
    );
  };

  const renderPatientProfileButton = () => {
    return (
      <div id={'patient-profile-button'} className={'ml-6 mb-8 self-start'}>
        <PatientProfileButton userId={appointment.user!.id} />
      </div>
    );
  };

  const onDateChange = (dateValue: Date | null) => {
    if (typeof dateValue === 'object') {
      setSelectedLocalDateTime(dateValue as Date);
    }
  };

  const onTimeChange = (timeValue: Date | null) => {
    if (typeof timeValue === 'object') {
      setSelectedLocalDateTime(timeValue as Date);
    }
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const dateTimeInOfficeTz = getOfficeTimeZoneForAppointmentBooking({
    selectedLocalDateTime,
    selectedDentist: null,
    selectedDentistId,
    practiceDentists: dentists,
    appointment,
  });

  const dateTimeInUTC = dateTimeInOfficeTz.toUTC();

  const onRescheduleAppointmentClick = () => {
    if (selectedLocalDateTime) {
      setRescheduleTime(dateTimeInOfficeTz.toString());
    }
    setIsModalOpen(true);
  };

  const onConfirmClicked = async () => {
    if (!selectedLocalDateTime || !selectedDentistId) {
      return;
    }

    setIsEditingAppointment(true);

    let editAppointmentArgs: EditAppointmentArgs = {
      appointmentId,
      dentistId: selectedDentistId || appointment.dentistId,
      startTimeInUTC: toApiDateTimeFormat(dateTimeInUTC),
    };
    if (appointment.status === 'cancelled') {
      editAppointmentArgs.status = 'requested';
    }
    const editAppointmentRes = await services.appointmentsService.editAppointment(
      editAppointmentArgs
    );

    setIsEditingAppointment(false);

    if (isErrorResponse(editAppointmentRes)) {
      setAlert(errorAlert('Failed to edit the appointment. Please try again.'));
      return;
    } else {
      setAlert(null);
    }

    navigate(`/appointments/${appointment.id}/details?successfulReschedule=true`);
  };

  return (
    <div id={'reschedule-appointment-page'} className="flex flex-col text-secondary gap-2.5">
      <h5 id={'title'} className="font-sans font-semibold text-2xl text-left">
        Reschedule Appointment
      </h5>
      <div id={'body'} className={'flex flex-col gap-2 bg-white rounded-md'}>
        <div id={'patient-name-and-status'} className={'flex items-center space-x-3 ml-6 my-6'}>
          <span
            className={` rounded-2xl font-semibold text-white text-xs px-2 py-1 ${getColorFromStatus(
              appointment
            )}`}
          >
            {appointment.status}
          </span>
          <span className="text-xl font-bold">
            {appointment.user.firstName} {appointment.user.lastName}
          </span>
        </div>
        <div id={'details-and-selectors-wrapper'} className={'flex flex-row gap-4'}>
          <div className={'flex flex-col gap-3 w-full justify-between'}>
            <AppointmentAndPatientDetails appointment={appointment} />
            {renderPatientProfileButton()}
          </div>
          <VerticalSeparator />
          <div className={'flex flex-col gap-3 w-full justify-between'}>
            <DentistAndAppointmentSelectors
              dentists={dentists}
              onNewDentistSelected={onNewDentistSelected}
              defaultSelectedDentist={defaultSelectedDentist}
              selectedLocalDateTime={selectedLocalDateTime}
              onDateChange={onDateChange}
              onTimeChange={onTimeChange}
            />
            {renderControlButtons()}
          </div>
        </div>
      </div>
      {alert && <Alert {...alert} />}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        onConfirm={onConfirmClicked}
        canConfirm
        confirmText={'Confirm Updated Appointment'}
        canCancel
        shape="square"
      >
        <RescheduleConfirmationModalContent
          appointment={appointment}
          rescheduleTime={rescheduleTime}
        />
      </Modal>
    </div>
  );
};

export default RescheduleAppointment;
