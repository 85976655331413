import { AuthProvider } from '../Authentication/Authentication';
import { Procedure } from '../ServiceContext/procedures';
import { authenticatedGet } from '../ServiceContext/service';
import { apiUrl } from '../ServiceContext/shared';
import { handleAPIResponse } from './response';

export async function searchProcedures({
  authProvider,
  dentistId,
  code,
}: {
  authProvider: AuthProvider;
  dentistId: string;
  code: string;
}): Promise<Procedure[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  code = code.trim();
  return handleAPIResponse(
    authenticatedGet<Procedure[]>(
      authProvider,
      apiUrl(`/practices/procedures?dentistId=${dentistId}&code=${encodeURIComponent(code)}`)
    )
  );
}
