import * as Sentry from '@sentry/react';

import {
  ErrorResponse,
  ErrorResponseBody,
  isErrorResponse,
  isSentryCompositeError,
  isStripeError,
  SentryCompositeError,
  StripeError,
} from '../API/response';

export function reportErrorToSentry(
  e: ErrorResponse | StripeError | string | SentryCompositeError | Error | object
) {
  if (process.env.REACT_APP_SENTRY_FIRE_HOSE === 'true') {
    let errorSummary: string;
    let fullError: string;

    if (isStripeError(e)) {
      errorSummary = e.message;
      fullError = JSON.stringify(e);
    } else if (isErrorResponse(e)) {
      let errorDetails: string;

      if (typeof e.errorResponse === 'string') {
        errorDetails = e.errorResponse;
      } else {
        errorDetails = e.errorResponse.errors.map((apiErr) => apiErr.message).join(',');
      }
      errorSummary = errorDetails;
      fullError = `{"status": ${e.statusCode}, "details": ${errorDetails}`;
    } else if (typeof e === 'string') {
      fullError = `Unexpected error string: ${e}`;
      errorSummary = e;
    } else if (isSentryCompositeError(e)) {
      let fullObjectString: string = '';
      try {
        fullObjectString = JSON.stringify(e.extra);
      } catch {
      } finally {
      }
      if (!fullObjectString) {
        fullObjectString = `${e}`;
      }
      fullError = `${fullObjectString}`;
      errorSummary = e.summary;
    } else if (e instanceof Error) {
      fullError = `Unexpected error occurred: ${e}`;
      errorSummary = e.message;
    } else {
      let tryErrorString: string = '';
      try {
        tryErrorString = JSON.stringify(e);
      } catch {
      } finally {
      }
      if (!tryErrorString) {
        tryErrorString = `${e}`;
      }
      fullError = `${tryErrorString}`;
      errorSummary = 'Unrecognized error';
    }
    Sentry.captureException(new Error(errorSummary), {
      extra: {
        fullError,
      },
    });
  }
}

export async function convertToErrorResponse(res: Response): Promise<ErrorResponse> {
  let errBody: ErrorResponseBody;
  try {
    errBody = await res.clone().json();
  } catch (jsonErr) {
    errBody = await res.text();
  }
  return {
    statusCode: res.status,
    errorResponse: errBody,
  };
}
