import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ClickTrackingAction, PayoutsWorkflow, runFTrack } from '../../amplitude';
import { getSinglePayoutByPayoutId } from '../../API/payouts';
import { getPractice } from '../../API/practice';
import { ErrorResponse } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import { useSelectedDentist } from '../../Dentists/SelectedDentistContext';
import { Payout, PayoutInvoiceSummary } from '../../ServiceContext/invoices';
import { Practice } from '../../ServiceContext/user';
import { toLocaleDateTimeString } from '../../shared/dates/dates';
import { stringifyMoney } from '../../shared/money/stringifyMoney';
import NewPagination from '../../shared/Table/NewPagination';
import Table from '../../shared/Table/Table';
import { getTimeZoneOfDentist } from '../../shared/timezone/timeZone';

interface Props {
  authProvider: AuthProvider;
}

function PayoutDetail({ authProvider }: Props) {
  const { payoutId } = useParams<{ payoutId?: string }>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dentistId = queryParams.get('dentistId');
  const practiceId = queryParams.get('practiceId');

  const { selectedDentistId } = useSelectedDentist() ?? dentistId;
  const dentist = (authProvider.managedDentists ?? []).find(
    (dentist) => dentist.id === selectedDentistId
  );

  const { data: practice } = useQuery<Practice, ErrorResponse>(
    'practice',
    () =>
      getPractice({
        authProvider,
        practiceId: practiceId!,
      }),
    {
      enabled: !!practiceId,
    }
  );

  const getSinglePayoutByIdKey = useMemo(() => ['getSinglePayoutById', payoutId], [payoutId]);

  const { data: payoutById } = useQuery<Payout | null, ErrorResponse>(
    getSinglePayoutByIdKey,
    () => {
      if (!payoutId || !practiceId) {
        return null;
      }
      return getSinglePayoutByPayoutId({
        authProvider,
        practiceId: practiceId,
        payoutId,
      });
    },
    {
      enabled: Boolean(practiceId) && Boolean(payoutId),
    }
  );

  const pageSize = 6;
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(1);

  const onNewPageSelected = (page: number) => {
    setSelectedPage(page);
  };

  if ((!dentist && !practiceId) || !payoutById) {
    return null;
  }

  let paginatedInvoiceSummaries: PayoutInvoiceSummary[] = [];
  const startIndex = (selectedPage - 1) * pageSize;
  paginatedInvoiceSummaries = payoutById.summary.invoiceSummaries.slice(
    startIndex,
    startIndex + pageSize
  );

  const tableColumns = [
    {
      columnKey: 'space',
      title: '',
      widthProportion: 0.1,
      render: () => {
        return '';
      },
    },
    {
      columnKey: 'appointmentDate',
      title: 'Appointment Date',
      widthProportion: 1,
      render: (record: PayoutInvoiceSummary) => {
        if (!record.appointmentDate) {
          return '---';
        }
        if (!dentist && !practice) {
          return '---';
        }
        return toLocaleDateTimeString(
          record.appointmentDate,
          DateTime.DATE_MED,
          getTimeZoneOfDentist(dentist ?? practice!.dentists[0])
        );
      },
    },
    {
      columnKey: 'paidDate',
      title: 'Payment Date',
      widthProportion: 1,
      render: (record: PayoutInvoiceSummary) => {
        if (!record.paidDate) {
          return '---';
        }
        if (!dentist && !practice) {
          return '---';
        }
        return toLocaleDateTimeString(
          record.paidDate,
          DateTime.DATE_MED,
          getTimeZoneOfDentist(dentist ?? practice!.dentists[0])
        );
      },
    },
    {
      columnKey: 'patientName',
      title: 'Patient Name',
      widthProportion: 1,
      render: (record: PayoutInvoiceSummary) => {
        return record.patientName;
      },
    },
    {
      columnKey: 'total',
      title: 'Payout Amount',
      widthProportion: 1,
      render: (record: PayoutInvoiceSummary) => {
        return stringifyMoney(record.payoutAmount, { includeCommas: true });
      },
    },
    {
      columnKey: '',
      title: '',
      widthProportion: 1,
      render: (record: PayoutInvoiceSummary) => {
        return '';
      },
    },
  ];

  const moneyPaidString = stringifyMoney(payoutById.summary.total, { includeCommas: true });

  function convertDateFormat(paidDateString: string, timeZone?: string): string {
    const dateObj = new Date(paidDateString);

    const formattedDate = dateObj.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: timeZone,
    });

    return formattedDate;
  }

  let paidDateString = '';
  if (dentist || practice) {
    const timeZone = getTimeZoneOfDentist(dentist ?? practice!.dentists[0]);
    paidDateString = convertDateFormat(payoutById.summary.payoutDate, timeZone);
  }

  const invoiceSummariesCount = payoutById.summary.invoiceSummaries.length;

  return (
    <div className="payout-detail flex flex-col gap-3 mt-4">
      <div id={'header'} className={'flex flex-row justify-between w-full'}>
        <span className="title text-2xl font-semibold pb-2">{`Payout Details`}</span>
      </div>
      <div
        id="payout-detail-header"
        className="flex flex-col items-start bg-white rounded-md pt-3 pl-6"
      >
        <div className="title pb-6">
          <span className={'text-sm'}>
            <span className={'font-bold text-xl mr-1'}>{moneyPaidString}</span>
          </span>{' '}
          paid on <span className="ml-1 text-sm">{paidDateString}</span>
        </div>
      </div>
      <Table
        id="payout-detail-table"
        columns={tableColumns}
        data={paginatedInvoiceSummaries}
        rowKeyGenerator={(record: PayoutInvoiceSummary) => `${record.invoiceId}`}
        onRowClick={(record: PayoutInvoiceSummary) => {
          runFTrack({
            event: 'Click Payout Detail Tile',
            workflow: PayoutsWorkflow,
            action: ClickTrackingAction,
            context: 'payoutDetail',
            componentId: 'payoutsDetailTile',
          });
          navigate(`/accounting/payouts/appointments/${record.appointmentId}`);
        }}
        rowClassName="past-payout-content-row hover:bg-[#f8f9fa] hover:font-bold cursor-pointer"
        align="start"
      />
      {invoiceSummariesCount > 0 && invoiceSummariesCount > pageSize && (
        <NewPagination
          currentPage={selectedPage}
          pageSize={pageSize}
          totalItemCount={invoiceSummariesCount}
          onPageChange={onNewPageSelected}
          tracking={{
            workflow: PayoutsWorkflow,
            context: 'payoutDetail',
          }}
        />
      )}
    </div>
  );
}

export default PayoutDetail;
