import React, { useCallback, useRef, useState } from 'react';

import ClearIcon from '../ClearIcon';

export type ValidationRule = RegexValidationRule | ExactValueValidationRule | FunctionRule;

type RegexValidationRule = {
  type: 'regex';
  regex: RegExp;
  errorMessage: string | ((value: string) => string);
};

type ExactValueValidationRule = {
  type: 'exact-value';
  value: string;
  errorMessage: string | ((value: string) => string);
};

type FunctionRule = {
  type: 'function';
  fn: (value: string) => boolean;
  errorMessage: string | ((value: string) => string);
};

export type ValidatedInputProps = {
  id?: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  validations: ValidationRule[];
  onChange: (newValue: string, isValid: boolean) => void;
  onBlur?: (newValue: string, isValid: boolean) => void;
  onBlurTransform?: (currentValue: string) => string;
  supplementaryText?: string;
  allowClear?: boolean;
  defaultInputValue?: string;
};

const ValidatedInput: React.FC<ValidatedInputProps> = ({
  id,
  className,
  disabled = false,
  placeholder = '',
  validations,
  onChange,
  onBlur,
  onBlurTransform,
  supplementaryText,
  allowClear,
  defaultInputValue,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>(defaultInputValue || '');
  const inputClearButtonRef = useRef<SVGSVGElement>(null);

  const runValidations = useCallback(
    (value: string): boolean => {
      let failedValidation = false;
      for (let i = 0; i < validations.length; ++i) {
        if (failedValidation) {
          continue;
        }
        const validation = validations[i];
        if (validation.type === 'regex') {
          if (!validation.regex.test(value)) {
            failedValidation = true;
            setError(
              typeof validation.errorMessage === 'string'
                ? validation.errorMessage
                : validation.errorMessage(value)
            );
          }
        } else if (validation.type === 'exact-value') {
          if (validation.value !== value) {
            failedValidation = true;
            setError(
              typeof validation.errorMessage === 'string'
                ? validation.errorMessage
                : validation.errorMessage(value)
            );
          }
        } else {
          if (!validation.fn(value)) {
            failedValidation = true;
            setError(
              typeof validation.errorMessage === 'string'
                ? validation.errorMessage
                : validation.errorMessage(value)
            );
          }
        }
      }
      if (!failedValidation) {
        setError(null);
      }
      return !failedValidation;
    },
    [validations]
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      onChange(e.target.value, runValidations(e.target.value));
    },
    [onChange, runValidations]
  );

  let inputClassName =
    'border h-10 rounded-lg text-base pl-3 focus:outline-none focus:ring-2 focus:ring-blue-200 text-base-content';

  if (error) {
    inputClassName += ' focus:ring-red-300';
  }

  if (className) {
    inputClassName = `${className} ${inputClassName}`;
  }

  let containerClassName =
    'validated-input-container flex flex-column gap-2 justify-start items-start relative';

  const onInputClearButtonClick = () => {
    setInputValue('');
    setError(null);
    onChange('', false);
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (onBlurTransform) {
      const transformedValue = onBlurTransform(value);
      setInputValue(transformedValue);
    }
    onBlur && onBlur(value, runValidations(value));
  };

  return (
    <div className={containerClassName}>
      <div className="relative w-full">
        <input
          id={id}
          className={inputClassName}
          onChange={onInputChange}
          onBlur={onInputBlur}
          disabled={disabled}
          placeholder={placeholder}
          value={inputValue}
          autoComplete="off"
        />
        {allowClear && (
          <div className="email-clear-button absolute right-0 top-[24%]">
            <ClearIcon
              ref={inputClearButtonRef}
              noDisplay={!inputValue}
              onClick={onInputClearButtonClick}
              color="grey"
            />
          </div>
        )}
      </div>
      {supplementaryText && (
        <span className="input-supplementary-text italic text-sm font-light">
          {supplementaryText}
        </span>
      )}
      {error && <span className="input-error-message italic text-[#bf2e3c]">{error}</span>}
    </div>
  );
};

export default ValidatedInput;
