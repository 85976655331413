import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useQuery } from 'react-query';

import { InvoicingWorkflow } from '../amplitude';
import { getAppointments } from '../API/appointments';
import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { useSelectedDentist } from '../Dentists/SelectedDentistContext';
import DentistSearchSelect from '../HomePage/components/DentistSearchSelect';
import { useManagedDentists } from '../HomePage/hooks/useManagedDentists';
import { GetAppointmentsResponse } from '../ServiceContext/appointments';
import { Dentist } from '../ServiceContext/user';
import Alert, { errorAlert } from '../shared/Alert';
import ClearIcon from '../shared/ClearIcon';
import Cookies from '../shared/cookies/cookies';
import PatientFilterInput from '../shared/PatientFilterInput/PatientFilterInput';
import { pollingIntervalSeconds } from '../shared/realTime/polling';
import { useURLQuery } from '../shared/routing/routing';
import { SearchSelectOption } from '../shared/SearchSelect/SearchSelect';
import NewPagination from '../shared/Table/NewPagination';
import {
  activeFilterStyling,
  basicFilterStyling,
  inactiveFilterStyling,
} from '../style/globalStyles';
import InvoicesTable from './InvoicesTable';

type Props = {
  authentication: AuthProvider;
};
const ViewInvoices: React.FC<Props> = ({ authentication }) => {
  const pageSize = 5;

  const { query, updateQuery } = useURLQuery();

  const setCookies = useCallback(
    (newParam: { [p: string]: string | null | undefined }) => {
      Cookies.invoiceSearchMemory.set(updateQuery(newParam));
    },
    [updateQuery]
  );

  const invoiceCategoryFromQuery = query.get('category');
  let defaultSelectedCategory: SearchSelectOption | null = null;
  if (invoiceCategoryFromQuery) {
    let label = 'N/A';
    switch (invoiceCategoryFromQuery) {
      case 'All Invoices':
        label = 'All Invoices';
        break;
      case 'Open':
        label = 'Open';
        break;
      case 'Paid':
        label = 'Paid';
        break;
    }
    defaultSelectedCategory = {
      id: invoiceCategoryFromQuery,
      label,
    };
  }

  const onNewDentistSelected = useCallback(
    (selected: Dentist | null) => {
      setSelectedPage(1);
      if (selected) {
        setCookies({ page: '1', dentistId: selected.id });
      } else {
        setCookies({ page: '1', dentistId: null });
      }
    },
    [setCookies]
  );

  const managedDentists = useManagedDentists(authentication);

  const { selectedDentistId } = useSelectedDentist();

  const patientNameFromQuery = query.get('patientName');
  const currentPage = parseInt(query.get('page') || '1');

  const [invoiceCategory, setInvoiceCategory] = useState<SearchSelectOption>(
    defaultSelectedCategory || { id: 'All Invoices', label: 'All Invoices' }
  );
  const [activeCategoryFilter, setActiveCategoryFilter] = useState('All Invoices');
  const [selectedPage, setSelectedPage] = useState<number>(currentPage || 1);
  const [patientName, setPatientName] = useState<string>(patientNameFromQuery || '');
  const [patientNameDisplay, setPatientNameDisplay] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [dateOrderByDirection, setDateOrderByDirection] = useState<'DESC' | 'ASC'>('DESC');

  const timeMin = selectedDate
    ? DateTime.fromJSDate(selectedDate).startOf('day').toUTC().toString()
    : undefined;
  const timeMax = selectedDate
    ? DateTime.fromJSDate(selectedDate).endOf('day').toUTC().toISO()
    : undefined;

  const today = new Date().toISOString();

  const {
    isLoading: isLoadingDisplayAppointments,
    error: responseError,
    data: appointmentsResponse,
  } = useQuery<GetAppointmentsResponse, ErrorResponse>(
    [
      'getAppointments',
      invoiceCategory.id,
      selectedPage,
      pageSize,
      patientName,
      selectedDate,
      dateOrderByDirection,
      selectedDentistId,
    ],
    () => {
      if (invoiceCategory.id === 'Open') {
        return getAppointments({
          authProvider: authentication,
          page: selectedPage,
          pageSize,
          timeMin: timeMin ? timeMin : undefined,
          timeMax: timeMax ? timeMax : today,
          patientName: patientName.trim(),
          orderBy: 'start',
          orderByDirection: dateOrderByDirection,
          isProcessed: false,
          dentistId: selectedDentistId ?? undefined,
        });
      } else if (invoiceCategory.id === 'Paid') {
        return getAppointments({
          authProvider: authentication,
          page: selectedPage,
          pageSize,
          timeMin: timeMin ? timeMin : undefined,
          timeMax: timeMax ? timeMax : today,
          patientName: patientName.trim(),
          orderBy: 'start',
          orderByDirection: dateOrderByDirection,
          isProcessed: true,
          dentistId: selectedDentistId ?? undefined,
        });
      } else {
        return getAppointments({
          authProvider: authentication,
          page: selectedPage,
          pageSize,
          timeMin: timeMin ? timeMin : undefined,
          timeMax: timeMax ? timeMax : today,
          patientName: patientName.trim(),
          orderBy: 'start',
          orderByDirection: dateOrderByDirection,
          dentistId: selectedDentistId ?? undefined,
        });
      }
    },
    {
      refetchInterval: pollingIntervalSeconds * 1000,
    }
  );

  const onNewCategorySelected = useCallback(
    (category: string) => {
      setSelectedPage(1);
      setInvoiceCategory({ id: category, label: category });
      setCookies({ page: '1', category: category });
    },
    [setCookies, setInvoiceCategory, setSelectedPage]
  );

  const onNewPageSelected = useCallback(
    (page: number) => {
      setCookies({ page: page.toString() });
      setSelectedPage(page);
    },
    [setCookies]
  );

  const onPatientInputEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setPatientName(e.currentTarget.value);
        setSelectedPage(1);
        setCookies({ page: '1', patientName: e.currentTarget.value });
      }
    },
    [setCookies]
  );

  const onPatientInputChange = useCallback(
    (newValue: string | null) => {
      if (newValue === null) {
        setPatientNameDisplay('');
        setCookies({ page: '1', patientName: null });
      } else {
        setPatientNameDisplay(newValue);
      }
    },
    [setCookies]
  );

  const onPatientInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setPatientName(e.target.value);
      setSelectedPage(1);
      setCookies({ page: '1', patientName: e.target.value });
    },
    [setCookies]
  );

  const onClearDateClick = useCallback(() => {
    setSelectedDate(null);
    setSelectedPage(1);
    setCookies({ page: '1', timeMin: null, timeMax: null });
  }, [setCookies]);

  if (responseError) {
    return <Alert {...errorAlert(responseError)} />;
  }

  return (
    <div id="invoices-page" className="flex flex-col justify-start items-start text-secondary">
      <div id="header" className="flex flex-row justify-between w-full mb-4">
        <span className="font-semibold text-2xl">Invoices</span>
        <DentistSearchSelect
          onChange={onNewDentistSelected}
          dentists={managedDentists}
          showSearchField={true}
        />
      </div>
      <div
        id="invoices-search-and-filter"
        className="flex flex-row justify-between bg-white w-full py-4 px-4 rounded-t-md border-b border-rule"
      >
        <div id={'invoice-categories'} className={'flex flex-row gap-2'}>
          <button
            className={`${basicFilterStyling} ${
              activeCategoryFilter === 'All Invoices' ? activeFilterStyling : inactiveFilterStyling
            }`}
            onClick={() => {
              setActiveCategoryFilter('All Invoices');
              onNewCategorySelected('All Invoices');
            }}
          >
            All Invoices
          </button>
          <button
            className={`${basicFilterStyling} ${
              activeCategoryFilter === 'Open' ? activeFilterStyling : inactiveFilterStyling
            }`}
            onClick={() => {
              setActiveCategoryFilter('Open');
              onNewCategorySelected('Open');
            }}
          >
            Open
          </button>
          <button
            className={`${basicFilterStyling} ${
              activeCategoryFilter === 'Paid' ? activeFilterStyling : inactiveFilterStyling
            }`}
            onClick={() => {
              setActiveCategoryFilter('Paid');
              onNewCategorySelected('Paid');
            }}
          >
            Paid
          </button>
        </div>
        <div
          id={'search-by-patient-name-and-date'}
          className={'text-sm flex flex-row justify-end gap-2 w-[40%]'}
        >
          <PatientFilterInput
            onKeyDown={onPatientInputEnter}
            customStyling="border border-1 rounded-md w-1/2 border-rule bg-white"
            onChange={onPatientInputChange}
            patientNameInputValue={patientNameDisplay}
            placeholder="Search by patient name"
            isSearchBox
            onBlur={onPatientInputBlur}
            allowClear={true}
          />
          <div className="flex flex-row relative">
            <DatePicker
              id="appointment-date-picker"
              className="appointment-time-picker border border-1 border-rule rounded-md py-2.5 pl-10 focus:outline-0 w-full"
              autoComplete="off"
              maxDate={new Date()}
              placeholderText="Select date"
              selected={selectedDate}
              onChange={(dateValue) => {
                if (typeof dateValue === 'object') {
                  const date = dateValue as Date;
                  setSelectedDate(date);
                }
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-base-content"
            />
            <div className="absolute right-0 -translate-y-1/2 top-1/2">
              <ClearIcon noDisplay={!selectedDate} onClick={onClearDateClick} />
            </div>
          </div>
        </div>
      </div>

      <div id={'table-and-pagination'} className={'w-full'}>
        <InvoicesTable
          isLoadingDisplayInvoices={isLoadingDisplayAppointments}
          authentication={authentication}
          appointmentData={appointmentsResponse ? appointmentsResponse.appointments : []}
          setDateOrderByDirection={setDateOrderByDirection}
        />
        {appointmentsResponse && (
          <NewPagination
            currentPage={selectedPage}
            pageSize={pageSize}
            totalItemCount={appointmentsResponse.totalCount}
            onPageChange={onNewPageSelected}
            tracking={{
              workflow: InvoicingWorkflow,
              context: 'viewInvoices',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ViewInvoices;
