import React from 'react';
import ReactModal from 'react-modal';

import { Tracking } from '../../../amplitude';
import { BookingCharge } from '../../../API/bookingsAndCredits';
import { AuthProvider } from '../../../Authentication/Authentication';
import useLockBodyScroll from '../../../shared/Modal/useLockBodyScroll';
import RefundChargeForm from './RefundChargeForm';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  bookingCharge: BookingCharge;
  onRefundCompleted: (refundedCharge: BookingCharge) => void;
  onCancel: () => void;
} & Tracking;
const RefundChargeModal: React.FC<Props> = ({
  authProvider,
  practiceId,
  bookingCharge,
  onRefundCompleted,
  onCancel,
  ...tracking
}) => {
  useLockBodyScroll(true);

  return (
    <ReactModal
      isOpen
      contentLabel="Refund Charge"
      className="outline-none mx-auto my-10 bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 max-h-4/5 overflow-auto text-secondary"
      overlayClassName="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
    >
      <RefundChargeForm
        authProvider={authProvider}
        onRefundCompleted={onRefundCompleted}
        practiceId={practiceId}
        charge={bookingCharge}
        onCancel={onCancel}
        {...tracking}
      />
    </ReactModal>
  );
};

export default RefundChargeModal;
