import './animation.css';

type FlashingMessageProps = {
  message: string;
  isItalic?: boolean;
  isBold?: boolean;
};

const FlashingMessage: React.FC<FlashingMessageProps> = ({ message, isItalic, isBold }) => {
  const baseClasses = 'text-sm animate-slow-flash';
  const italicClass = isItalic ? 'italic' : '';
  const boldClass = isBold ? 'font-bold' : '';

  return <span className={`${baseClasses} ${italicClass} ${boldClass}`}>{message}</span>;
};

export default FlashingMessage;
