import React from 'react';

export default function PortalContainer({
  children,
  className,
  id,
  padding = 'p-4',
}: {
  children: React.ReactNode;
  className?: string;
  id?: string;
  padding?: 'p-0' | 'p-1' | 'p-2' | 'p-3' | 'p-4';
}) {
  return (
    <div id={id} className={`bg-white rounded-lg ${padding} text-left w-full ${className}`}>
      {children}
    </div>
  );
}
