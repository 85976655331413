import { AuthProvider } from '../../Authentication/Authentication';
import { useManagedDentists } from '../hooks/useManagedDentists';
import DentistSearchSelect from './DentistSearchSelect';

export default function HomeHeader({ authProvider }: { authProvider: AuthProvider }) {
  const managedDentists = useManagedDentists(authProvider);

  return (
    <div className="header flex flex-row justify-between w-full">
      <span className="font-semibold text-3xl text-base-content-flossy">
        {authProvider.authUser?.user.practices[0].name}
      </span>
      <div>
        <DentistSearchSelect
          onChange={(dentistId) => {}}
          dentists={managedDentists}
          showSearchField={true}
        />
      </div>
    </div>
  );
}
