import { Practice } from '../../ServiceContext/user';
import { stringifyMoney } from '../../shared/money/stringifyMoney';

type Props = {
  practice: Practice;
};

const CreditsEarnedTooltip: React.FC<Props> = ({ practice }) => {
  return (
    <div
      className={
        'absolute z-20 flex flex-col gap-2 text-left top-12 left-6 mt-2 p-4 bg-white rounded-md shadow-lg w-64 -translate-x-[40px]'
      }
    >
      <span className={'text-sm font-semibold'}>What are "Credits Earned"?</span>
      {practice.referralCreditAmount && (
        <span className={'text-xs'}>
          {`For every patient you refer that books an appointment through Flossy, you will be rewarded ${stringifyMoney(
            practice.referralCreditAmount,
            { includeCommas: true }
          )} worth of Flossy Credits. Credits you earn will be subtracted from your Booking Fees
          each month.`}
        </span>
      )}
    </div>
  );
};

export default CreditsEarnedTooltip;
