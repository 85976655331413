import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import { XMark } from '../../HomePage/components/Icons';
import { PricingQuote } from '../../ServiceContext/invoices';
import { isNumber } from '../../shared/math/math';
import { stringifyMoney } from '../../shared/money/stringifyMoney';
import MoneyInput from '../../shared/MoneyInput/MoneyInput';
import { PricingSystemVersion } from '../../shared/pricingSystem';
import AdditionalProcedureDetails from './AdditionalProcedureDetails';

export type PricedCDTCode = {
  cdt: string;
  description: string;
  flossyPrice: number;
  adjustedPrice: number | null;
  additionalNotes: string | null;
  retailPrice: number | null; // null when pricing version < 2
  pricingSystemId: string;

  // Front-end only fields, has no significance to actual data model from backend.
  pricingVersion: PricingSystemVersion;
};

type Props = {
  selectedCodes: PricedCDTCode[];
  onSelectedCodesChange: (codes: PricedCDTCode[]) => void;
  onRemoveItem: (procedure: PricedCDTCode) => void;
  onPriceAdjusted: (itemId: string, newPrice: number) => void;
  pricingQuote: PricingQuote | null;
};

const PricingCDTCodeTable: React.FC<Props> = ({
  selectedCodes,
  onSelectedCodesChange,
  onRemoveItem,
  onPriceAdjusted,
  pricingQuote,
}) => {
  const [adjustedPriceInput, setAdjustedPriceInput] = React.useState(0);

  const onRemoveItemClick = useCallback(
    (procedureToRemoveIndex: number) => {
      return () => {
        if (selectedCodes.length < procedureToRemoveIndex) {
          console.error('Invalid index to remove', procedureToRemoveIndex);
          return;
        }
        let newSelectedProcedures: PricedCDTCode[];
        const removedProcedure = selectedCodes[procedureToRemoveIndex];
        onRemoveItem(removedProcedure);
        newSelectedProcedures = selectedCodes.filter((p, idx) => idx !== procedureToRemoveIndex);
        onSelectedCodesChange(newSelectedProcedures);
      };
    },

    [onSelectedCodesChange, selectedCodes, onRemoveItem]
  );

  const onAdditionalProcedureDetailsChange = useCallback(
    (procedureIndex: number) => {
      return (newValue: string | null) => {
        if (selectedCodes.length < procedureIndex) {
          return;
        }
        let newSelectedProcedures: PricedCDTCode[];
        newSelectedProcedures = selectedCodes.map((p, idx) => {
          if (idx === procedureIndex) {
            return {
              ...p,
              additionalNotes: newValue || '',
            };
          }
          return p;
        });
        onSelectedCodesChange(newSelectedProcedures);
      };
    },
    [onSelectedCodesChange, selectedCodes]
  );

  function inferPriceAfterPromo(p: PricedCDTCode, pricingQuote: PricingQuote | null): number {
    const unalteredPrice = isNumber(p.adjustedPrice) ? p.adjustedPrice : p.flossyPrice;
    if (!pricingQuote) {
      return unalteredPrice;
    }
    const { promoSummary } = pricingQuote;
    if (!promoSummary) {
      return unalteredPrice;
    }

    if (promoSummary.type === 'lockedAmount' || promoSummary.type === 'percent') {
      if (
        isNumber(promoSummary.discountInCentsByPricingSystemID[p.pricingSystemId]) &&
        promoSummary.discountInCentsByPricingSystemID[p.pricingSystemId] > 0
      ) {
        return Math.max(
          unalteredPrice - promoSummary.discountInCentsByPricingSystemID[p.pricingSystemId],
          0
        );
      }
    }

    return unalteredPrice;
  }

  function renderFlossyPriceInput(p: PricedCDTCode, idx: number) {
    const priceAfterPromo = inferPriceAfterPromo(p, pricingQuote);

    const unalteredPrice = isNumber(p.adjustedPrice) ? p.adjustedPrice : p.flossyPrice;
    let alteredPriceDisplay: React.ReactNode = null;
    if (priceAfterPromo !== unalteredPrice) {
      alteredPriceDisplay = (
        <span className="text-xs text-general-gray italic">
          Price after promo: {stringifyMoney(priceAfterPromo, { includeCommas: true })}
        </span>
      );
    }

    return (
      <div className="flex flex-column">
        <MoneyInput
          id={`invoice-input-${p.cdt}-${idx}`}
          name={`invoice-input-name-${p.cdt}-${idx}`}
          onBlur={async (e) => {
            const previousPrice = unalteredPrice;

            try {
              p.adjustedPrice = parseFloat(e.target.value) * 100;

              if (p.adjustedPrice === previousPrice || !isNumber(p.adjustedPrice)) {
                p.adjustedPrice = previousPrice;
                setAdjustedPriceInput((prev) => prev + 1);
              }
              onPriceAdjusted(p.pricingSystemId, p.adjustedPrice);
            } catch (error) {
              const errorMessage =
                (p.adjustedPrice ?? 0) > p.flossyPrice
                  ? `Price must be lower than ${stringifyMoney(p.flossyPrice)}`
                  : 'Error adjusting prices for pricing quote.';
              p.adjustedPrice = previousPrice;
              toast.error(errorMessage);
            }
            setAdjustedPriceInput((prev) => prev + 1);
          }}
          placeholder={stringifyMoney(unalteredPrice, {
            includeCommas: true,
          })}
          defaultValueInCents={unalteredPrice}
          update={adjustedPriceInput}
          showErrorFn={() => false}
          className={'text-xs border rounded-md text-right p-2 focus:outline-info w-20'}
          // Stripe cannot process payments less than 50 cents
          min={50}
        />
        {alteredPriceDisplay}
      </div>
    );
  }

  return (
    <table
      id="selected-cdt-table"
      className="table border-separate border-spacing-0 mt-4 text-left border-x-0 border-t"
    >
      <thead className={'text-general-gray text-sm'}>
        <tr>
          <th className={'!pl-6 self-center'} scope="col">
            CDT Code
          </th>
          <th scope="col">Procedure</th>
          <th scope="col">Retail Price</th>
          <th scope="col">Flossy Price</th>
          <th scope="col">Additional Procedure Details</th>
          <th scope="col" />
        </tr>
      </thead>

      <tbody>
        {selectedCodes.map((p, idx) => {
          const key = `${p.cdt}-${p.description}-${p.pricingSystemId}-${idx}`;
          return (
            <tr key={key} className="cdt-table-content-row font-normal text-xs">
              <td className="cdt-code-content font-sans font-bold w-[10%] pt-4 !pl-6">{p.cdt}</td>
              <td className="cdt-description-content w-[25%] text-wrap pt-3">{p.description}</td>
              <td className="pt-3 w-[8%]">
                {p.retailPrice && isNumber(p.retailPrice) ? stringifyMoney(p.retailPrice) : '--'}
              </td>
              <td className="cdt-adjusted-content w-[8%]">{renderFlossyPriceInput(p, idx)}</td>
              <td id={'additional-procedure-details'}>
                <AdditionalProcedureDetails
                  inputId={`additional-procedure-details-${idx}`}
                  procedure={p}
                  onAdditionalProcedureDetailsChange={onAdditionalProcedureDetailsChange(idx)}
                />
              </td>
              <td className={'w-[5%] h-24'}>
                <div
                  id={`cancel-cdt-selection-${idx}`}
                  className="py-4 px-2 scale-125 cursor-pointer text-primary"
                  onClick={onRemoveItemClick(idx)}
                >
                  <XMark />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PricingCDTCodeTable;
