import { XMarkThin } from '../../HomePage/components/Icons';

export default function UndoActionPopup({
  code,
  onClose,
  onUndo,
}: {
  code: string;
  onClose: () => void;
  onUndo: () => void;
}) {
  return (
    <div className="w-[400px] h-[41px] px-4 bg-secondary rounded-lg shadow flex items-center">
      <span className="text-base-100 text-sm font-bold pr-2">CDT Code {code} </span>
      <span className="text-base-100 text-sm font-normal pr-4">removed from Invoice.</span>
      <div
        className="grow text-base-content-flossy text-sm font-bold cursor-pointer"
        onClick={onUndo}
      >
        Undo
      </div>
      <div className="pl-4 cursor-pointer" onClick={onClose}>
        <XMarkThin />
      </div>
    </div>
  );
}
