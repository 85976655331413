import React, { PropsWithChildren, ReactElement } from 'react';

interface TabProps {
  tabKey: string;
  title: string;
}

function Tab({ children }: PropsWithChildren<TabProps>) {
  return <div className="tab">{children}</div>;
}

interface TabsProps {
  activeTabKey: string;
  children: ReactElement<TabProps> | Array<ReactElement<TabProps>>;
  onTabChanged: (newKey: string) => void;
  className?: string;
}

function Tabs({ children, activeTabKey, onTabChanged, className }: TabsProps) {
  let tabsClassName = 'tabs flex flex-col';
  if (className) {
    tabsClassName += ` ${className}`;
  }

  if (!children) {
    return null;
  }

  const getTabProps = () => {
    if (Array.isArray(children)) {
      return children.map((c) => c.props);
    }

    return [children.props];
  };

  const getShownTab = () => {
    let showingTab: ReactElement | undefined;

    if (Array.isArray(children)) {
      showingTab = children.find((c) => c.props.tabKey === activeTabKey);
    } else {
      showingTab = children;
    }

    return showingTab;
  };

  return (
    <div className={tabsClassName}>
      <div
        id={'tab-titles-list'}
        className="flex flex-row space-x-8 mt-4 border-b border-b-base-content-inactive"
      >
        {getTabProps().map((p) => {
          let tabClassName = 'tab-title text-base cursor-pointer pb-2';
          if (p.tabKey === activeTabKey) {
            tabClassName += ' selected font-semibold border-b-2 border-b-primary';
          }
          return (
            <div
              key={p.tabKey}
              className={tabClassName}
              onClick={() => {
                onTabChanged(p.tabKey);
              }}
            >
              {p.title}
            </div>
          );
        })}
      </div>
      <div className="tab-content-display-area w-full mt-8">{getShownTab()}</div>
    </div>
  );
}

Tabs.Tab = Tab;

export default Tabs;
