import { stringifyMoney } from '../shared/money/stringifyMoney';

function TotalCost({ cost }: { cost: number }) {
  return (
    <div className="text-2xl font-bold">
      {`Total Amount: ${stringifyMoney(cost, {
        includeCommas: true,
      })}`}
    </div>
  );
}

export default TotalCost;
