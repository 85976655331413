import { useMutation } from 'react-query';

import { BookingsAndCreditsWorkflow } from '../../../amplitude';
import { testOnly_triggerMonthlyBookingsAndCreditsNotifications } from '../../../API/practice';
import { AuthProvider } from '../../../Authentication/Authentication';
import Button from '../../../shared/Button/Button';
import { Environment } from '../../../shared/environment';

export default function useTestOnlyTriggerMonthlyBookingsAndCreditsNotifications({
  authProvider,
  practiceId,
  period,
}: {
  authProvider: AuthProvider;
  practiceId: string;
  period: string;
}): {
  component: React.ReactNode;
} {
  const mutation = useMutation(testOnly_triggerMonthlyBookingsAndCreditsNotifications, {
    onError: (error) => {
      console.log('Error triggering B&C notifications', error);
    },
  });

  if (!Environment.allowTestOnlyFeatures()) {
    return {
      component: null,
    };
  }

  return {
    component: (
      <Button
        onClick={() => mutation.mutate({ authProvider, practiceId, period })}
        color="danger"
        className="w-full"
        workflow={BookingsAndCreditsWorkflow}
        context="TestOnlyTriggerMonthlyBookingsAndCreditsNotifications"
        trackingLabel="Trigger Monthly B&C Notifications Button"
      >
        TEST ONLY - Trigger Monthly B&C Notifications
      </Button>
    ),
  };
}
