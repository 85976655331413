import {
  abortedRequestErrorResponse,
  ErrorResponse,
  isAbortedRequest,
  SentryCompositeError,
} from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { convertToErrorResponse, reportErrorToSentry } from './error';

async function catchUnexpectedErrors<R>(
  fn: () => Promise<R | ErrorResponse>
): Promise<R | ErrorResponse> {
  try {
    return await fn();
  } catch (e) {
    if (isAbortedRequest(e)) {
      return abortedRequestErrorResponse;
    }

    reportErrorToSentry(e as any);
    console.error(e);

    return {
      statusCode: 500,
      errorResponse: 'An unexpected error occurred. Please try again later.',
    };
  }
}

async function performRequest<R>({
  authProvider,
  method,
  url,
  bodyOrFormData,
  signal,
}: {
  authProvider: AuthProvider;
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  url: string;
  bodyOrFormData?: any;
  signal?: AbortSignal;
}): Promise<R | ErrorResponse> {
  const firebaseAuthInfo = await authProvider.getFirebaseAuthInfo();
  if (!firebaseAuthInfo) {
    await authProvider.notifyUnauthorizedFromAPI();
    throw new SentryCompositeError({
      summary: 'Missing authorization info. Please log in again.',
      extra: { url },
    });
  }
  const fetchOptions: RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${firebaseAuthInfo.token}`,
      'flossy-dentist-google-id': firebaseAuthInfo.firebaseUserId,
    },
  };
  if (bodyOrFormData) {
    let body: string | FormData;
    if (bodyOrFormData instanceof FormData) {
      // The browser's native fetch implementations will set the form data header on our behalf automatically,
      // so we should not do it here. If we do, it will error and not work for some odd reason.
      body = bodyOrFormData as FormData;

      // @ts-ignore
      fetchOptions.headers && delete fetchOptions.headers['Content-Type'];
    } else {
      body = JSON.stringify(bodyOrFormData);
    }

    fetchOptions.body = body;
  }
  if (signal) {
    fetchOptions.signal = signal;
  }
  const res = await fetch(url, fetchOptions);

  if (res.status >= 200 && res.status <= 399) {
    let responseBodyText = '';
    try {
      responseBodyText = await res.text();
      return JSON.parse(responseBodyText);
    } catch (e) {
      throw new SentryCompositeError({
        summary: 'Failed to parse JSON response from API',
        extra: { url, status: res.status, body: responseBodyText, error: e },
      });
    }
  }

  if (res.status === 401) {
    await authProvider.notifyUnauthorizedFromAPI();
    throw new SentryCompositeError({
      summary: 'Authorization failed. Please log in again.',
      extra: { url },
    });
  }

  const errRes = await convertToErrorResponse(res);

  if (res.status !== 401) {
    reportErrorToSentry(errRes);
  }

  return errRes;
}

export async function performUnauthenticatedRequest<R>({
  method,
  url,
  bodyOrFormData,
  signal,
}: {
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  url: string;
  bodyOrFormData?: any;
  signal?: AbortSignal;
}): Promise<R | ErrorResponse> {
  const fetchOptions: RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (bodyOrFormData) {
    let body: string | FormData;
    if (bodyOrFormData instanceof FormData) {
      // The browser's native fetch implementations will set the form data header on our behalf automatically,
      // so we should not do it here. If we do, it will error and not work for some odd reason.
      body = bodyOrFormData as FormData;

      // @ts-ignore
      fetchOptions.headers && delete fetchOptions.headers['Content-Type'];
    } else {
      body = JSON.stringify(bodyOrFormData);
    }

    fetchOptions.body = body;
  }
  if (signal) {
    fetchOptions.signal = signal;
  }
  const res = await fetch(url, fetchOptions);

  if (res.status >= 200 && res.status <= 399) {
    let responseBodyText = '';
    try {
      responseBodyText = await res.text();
      return JSON.parse(responseBodyText);
    } catch (e) {
      throw new SentryCompositeError({
        summary: 'Failed to parse JSON response from API',
        extra: { url, status: res.status, body: responseBodyText, error: e },
      });
    }
  }

  const errRes = await convertToErrorResponse(res);

  if (res.status !== 401) {
    reportErrorToSentry(errRes);
  }

  return errRes;
}

async function performNullableRequest<R>({
  authProvider,
  method,
  url,
  bodyOrFormData,
  signal,
}: {
  authProvider: AuthProvider;
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  url: string;
  bodyOrFormData?: any;
  signal?: AbortSignal;
}): Promise<R | null | ErrorResponse> {
  const firebaseAuthInfo = await authProvider.getFirebaseAuthInfo();
  if (!firebaseAuthInfo) {
    await authProvider.notifyUnauthorizedFromAPI();
    throw new SentryCompositeError({
      summary: 'Missing authorization info. Please log in again.',
      extra: { url },
    });
  }
  const fetchOptions: RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${firebaseAuthInfo.token}`,
      'flossy-dentist-google-id': firebaseAuthInfo.firebaseUserId,
    },
  };
  if (bodyOrFormData) {
    let body: string | FormData;
    if (bodyOrFormData instanceof FormData) {
      // The browser's native fetch implementations will set the form data header on our behalf automatically,
      // so we should not do it here. If we do, it will error and not work for some odd reason.
      body = bodyOrFormData as FormData;

      // @ts-ignore
      fetchOptions.headers && delete fetchOptions.headers['Content-Type'];
    } else {
      body = JSON.stringify(bodyOrFormData);
    }

    fetchOptions.body = body;
  }
  if (signal) {
    fetchOptions.signal = signal;
  }
  const res = await fetch(url, fetchOptions);

  if (res.status === 204) {
    return null;
  }

  if (res.status >= 200 && res.status <= 399) {
    let responseBodyText = '';
    try {
      responseBodyText = await res.text();
      return JSON.parse(responseBodyText);
    } catch (e) {
      throw new SentryCompositeError({
        summary: 'Failed to parse JSON response from API',
        extra: { url, status: res.status, body: responseBodyText, error: e },
      });
    }
  }

  if (res.status === 401) {
    await authProvider.notifyUnauthorizedFromAPI();
    throw new SentryCompositeError({
      summary: 'Authorization failed. Please log in again.',
      extra: { url },
    });
  }
  if (res.status === 404) {
    return null;
  }

  const errRes = await convertToErrorResponse(res);

  if (res.status !== 401) {
    reportErrorToSentry(errRes);
  }

  return errRes;
}

async function performRequestNoContentResponse({
  authProvider,
  method,
  url,
  bodyOrFormData,
  signal,
}: {
  authProvider: AuthProvider;
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  url: string;
  bodyOrFormData?: any;
  signal?: AbortSignal;
}): Promise<null | ErrorResponse> {
  const firebaseAuthInfo = await authProvider.getFirebaseAuthInfo();
  if (!firebaseAuthInfo) {
    await authProvider.notifyUnauthorizedFromAPI();
    throw new SentryCompositeError({
      summary: 'Missing authorization info. Please log in again.',
      extra: { url },
    });
  }
  const fetchOptions: RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${firebaseAuthInfo.token}`,
      'flossy-dentist-google-id': firebaseAuthInfo.firebaseUserId,
    },
  };
  if (bodyOrFormData) {
    let body: string | FormData;
    if (bodyOrFormData instanceof FormData) {
      // The browser's native fetch implementations will set the form data header on our behalf automatically,
      // so we should not do it here. If we do, it will error and not work for some odd reason.
      body = bodyOrFormData as FormData;

      // @ts-ignore
      fetchOptions.headers && delete fetchOptions.headers['Content-Type'];
    } else {
      body = JSON.stringify(bodyOrFormData);
    }

    fetchOptions.body = body;
  }
  if (signal) {
    fetchOptions.signal = signal;
  }
  const res = await fetch(url, fetchOptions);

  if (res.status >= 200 && res.status <= 399) {
    return null;
  }

  if (res.status === 401) {
    await authProvider.notifyUnauthorizedFromAPI();
    throw new SentryCompositeError({
      summary: 'Authorization failed. Please log in again.',
      extra: { url },
    });
  }

  const errRes = await convertToErrorResponse(res);

  if (res.status !== 401) {
    reportErrorToSentry(errRes);
  }

  return errRes;
}

export type RedirectResponse = {
  redirectUrl: string;
};

export async function authenticatedGet<R>(
  authProvider: AuthProvider,
  url: string,
  signal?: AbortSignal
): Promise<R | ErrorResponse> {
  return await catchUnexpectedErrors(async () => {
    return performRequest({
      authProvider,
      method: 'GET',
      url,
      signal,
    });
  });
}

export async function authenticatedNullableGet<R>(
  authProvider: AuthProvider,
  url: string,
  signal?: AbortSignal
): Promise<R | null | ErrorResponse> {
  return await catchUnexpectedErrors(async () => {
    return performNullableRequest({
      authProvider,
      method: 'GET',
      url,
      signal,
    });
  });
}

export async function authenticatedPost<R>(
  authProvider: AuthProvider,
  url: string,
  bodyOrFormData: any
): Promise<R | ErrorResponse> {
  return await catchUnexpectedErrors(async () => {
    return performRequest({
      authProvider,
      method: 'POST',
      url,
      bodyOrFormData,
    });
  });
}

export async function authenticatedPostToRedirect(
  authProvider: AuthProvider,
  url: string,
  bodyOrFormData: any
): Promise<RedirectResponse | ErrorResponse> {
  return await catchUnexpectedErrors(async () => {
    return performRequest<RedirectResponse>({
      authProvider,
      method: 'POST',
      url,
      bodyOrFormData,
    });
  });
}

export async function unauthenticatedPostToRedirect(
  url: string,
  bodyOrFormData: any
): Promise<RedirectResponse | ErrorResponse> {
  return await catchUnexpectedErrors(async () => {
    return performUnauthenticatedRequest<RedirectResponse>({
      method: 'POST',
      url,
      bodyOrFormData,
    });
  });
}

export async function authenticatedPut<R>(
  authProvider: AuthProvider,
  url: string,
  bodyOrFormData: any
): Promise<R | ErrorResponse> {
  return await catchUnexpectedErrors(async () => {
    return performRequest({
      authProvider,
      method: 'PUT',
      url,
      bodyOrFormData,
    });
  });
}

export async function authenticatedPatch<R>(
  authProvider: AuthProvider,
  url: string,
  bodyOrFormData: any
): Promise<R | ErrorResponse> {
  return await catchUnexpectedErrors(async () => {
    return performRequest({
      authProvider,
      method: 'PATCH',
      url,
      bodyOrFormData,
    });
  });
}

export async function authenticatedDelete<R>(
  authProvider: AuthProvider,
  url: string
): Promise<R | ErrorResponse> {
  return await catchUnexpectedErrors(async () => {
    return performRequest({
      authProvider,
      method: 'DELETE',
      url,
    });
  });
}

export async function authenticatedNoContentPost(
  authProvider: AuthProvider,
  url: string,
  bodyOrFormData: any
): Promise<null | ErrorResponse> {
  return await catchUnexpectedErrors(async () => {
    return performRequestNoContentResponse({
      authProvider,
      method: 'POST',
      url,
      bodyOrFormData,
    });
  });
}

export async function authenticatedNoContentPut(
  authProvider: AuthProvider,
  url: string,
  bodyOrFormData: any
): Promise<null | ErrorResponse> {
  return await catchUnexpectedErrors(async () => {
    return performRequestNoContentResponse({
      authProvider,
      method: 'PUT',
      url,
      bodyOrFormData,
    });
  });
}

export async function authenticatedNoContentDelete(
  authProvider: AuthProvider,
  url: string
): Promise<null | ErrorResponse> {
  return await catchUnexpectedErrors(async () => {
    return performRequestNoContentResponse({
      authProvider,
      method: 'DELETE',
      url,
    });
  });
}
