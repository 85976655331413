import React from 'react';

import { PaymentHistoryRecord } from '../../../API/bookingsAndCredits';
import { ErrorResponse } from '../../../API/response';
import Alert, { errorAlert } from '../../../shared/Alert';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import PaymentHistoryTable from './PaymentHistoryTable';

type Props = {
  paymentHistoryRecords: PaymentHistoryRecord[] | undefined;
  fetchPaymentHistoryError: ErrorResponse | null;
  isLoadingPaymentHistory: boolean;
};

const PaymentHistory: React.FC<Props> = ({
  paymentHistoryRecords,
  fetchPaymentHistoryError,
  isLoadingPaymentHistory,
}) => {
  if (fetchPaymentHistoryError) {
    return <Alert {...errorAlert(fetchPaymentHistoryError.errorResponse)} />;
  }

  if (paymentHistoryRecords === undefined) {
    return null;
  }

  if (isLoadingPaymentHistory) {
    return <LoadingSpinner />;
  }

  return <PaymentHistoryTable data={paymentHistoryRecords} />;
};

export default PaymentHistory;
