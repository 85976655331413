import { useStripe } from '@stripe/react-stripe-js';
import { PaymentMethodResult, StripeCardNumberElement } from '@stripe/stripe-js';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { isErrorResponse, isStripeError } from '../../../API/response';
import { Appointment } from '../../../ServiceContext/appointments';
import { isValidPaymentMethod, PaymentMethod } from '../../../ServiceContext/invoices';
import { Patient, StripeCardSetupIntent } from '../../../ServiceContext/patients';
import { useServices } from '../../../ServiceContext/ServiceContext';
import { createPaymentMethodModel } from '../../../shared/paymentMethods';

export function isStripeCardSetupIntent(object: any): object is StripeCardSetupIntent {
  return 'id' in object && 'clientSecret' in object;
}

/**
 * This hook adds a new payment method.
 * @param {Appointment} [appointment] - The appointment to which the new payment method will be attached if it is successful. This parameter is optional.
 * @param {Patient} patient - The patient for whom the payment method is being added.
 * @param {Function} onPatientPaymentMethodsUpdated - A callback function that is called when the patient's payment methods are updated.
 * @param {Function} onSuccess - A callback function that is called when the payment method is successfully added.
 */
export function useAddPaymentMethod({
  appointment,
  onPatientPaymentMethodsUpdated,
  onSuccess,
  firstName,
  lastName,
  patientId,
}: {
  appointment?: Appointment;
  firstName: string;
  lastName: string;
  patientId: string;
  onPatientPaymentMethodsUpdated: (updatedPatient: Patient) => void;
  onSuccess: (createdPaymentMethod: PaymentMethod) => void;
}) {
  const stripe = useStripe();
  const services = useServices();
  const [cardError, setCardError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const createPaymentMethod = useMutation({
    mutationFn: ({ cardNumberElement }: { cardNumberElement: StripeCardNumberElement }) => {
      setLoading(true);
      if (!cardNumberElement || !stripe) {
        throw new Error('Stripe not initialized');
      }

      return stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement!,
        billing_details: {
          name: `${firstName} ${lastName}`,
        },
      });
    },
    onSuccess: (data) => {
      if (data.error) {
        setError('', data.error.message ?? 'Error creating payment method');
        return;
      }

      createCardSetupIntent.mutate();
    },
    onError: (error) => {
      setError(error, 'Error creating payment method');
    },
  });

  const createCardSetupIntent = useMutation({
    mutationFn: () => {
      return services.patientsService.createCardSetupIntent(patientId);
    },
    onSuccess: (data) => {
      if (isErrorResponse(data)) {
        setError('', data.errorResponse.toString());
        return;
      }
      if (isStripeCardSetupIntent(data)) {
        confirmCardSetup.mutate({ clientSecret: (data as StripeCardSetupIntent).clientSecret });
      } else {
        setError('', 'Error creating card setup intent');
      }
    },
    onError: (error) => {
      setError(error, 'Error creating card setup intent');
    },
  });

  const confirmCardSetup = useMutation({
    mutationFn: ({ clientSecret }: { clientSecret: string }) => {
      if (!stripe) {
        throw new Error('Stripe not initialized');
      }

      return stripe.confirmCardSetup(clientSecret, {
        payment_method: (createPaymentMethod.data as PaymentMethodResult).paymentMethod!.id,
      });
    },
    onSuccess: (data) => {
      if (data.error) {
        setError('', data.error.message ?? 'Error confirming card setup');
        return;
      }

      addFlossyPaymentMethod.mutate();
    },
    onError: (error) => {
      setError(error, 'Error confirming card setup');
    },
  });

  const addFlossyPaymentMethod = useMutation({
    mutationFn: () => {
      const paymentMethod = createPaymentMethod.data?.paymentMethod;

      if (!paymentMethod || paymentMethod.card === undefined) {
        throw new Error('Payment method not created');
      }

      const flossyPaymentMethod = createPaymentMethodModel({
        stripePaymentMethodId: paymentMethod.id,
        type: `cc.${paymentMethod.card!.brand}`,
        cardBrand: paymentMethod.card!.brand,
        cardLast4: paymentMethod.card.last4,
        stripeExpirationMonth: paymentMethod.card.exp_month,
        stripeExpirationYear: paymentMethod.card.exp_year,
      });

      return services.patientsService.addPaymentMethod(patientId, flossyPaymentMethod);
    },
    onSuccess: (data) => {
      if (!isErrorResponse(data)) {
        onPatientPaymentMethodsUpdated(data);
      }
      if (((data as Patient).paymentMethods ?? []).length > 0 && appointment) {
        attachPaymentMethod.mutate({
          method: (data as Patient).paymentMethods![0],
        });
      } else if (((data as Patient).paymentMethods ?? []).length > 0) {
      }
      if (isValidPaymentMethod((data as Patient).paymentMethods![0])) {
        onSuccess((data as Patient).paymentMethods![0]);
      } else {
        onSuccess((data as Patient).paymentMethods![0]);

        setLoading(false);
      }
    },
    onError: (error) => {
      setError(error, 'Error adding payment method');
    },
  });

  const attachPaymentMethod = useMutation({
    mutationFn: ({ method }: { method: PaymentMethod }) => {
      return services.appointmentsService.associatePaymentMethod(method, appointment!.id!);
    },
    onSuccess: (data) => {
      if (isErrorResponse(data)) {
        setError('', data.errorResponse.toString());
        return;
      }

      const updatedAppointment = data;
      appointment!.paymentMethod = updatedAppointment.paymentMethod;
      queryClient.setQueryData('appointment_' + appointment!.id, appointment);

      if (isValidPaymentMethod(updatedAppointment.paymentMethod)) {
        onSuccess(updatedAppointment.paymentMethod);
      }
      setLoading(false);
    },
    onError: (error) => {
      setError(error, 'Error attaching payment method to appointment');
    },
  });

  return {
    createPaymentMethod,
    cardError,
    setCardError,
    loading,
  };

  function setError(error: any, message: string): void {
    if (isStripeError(error)) {
      setCardError(error.message);
    } else if (isErrorResponse(error)) {
      setCardError(error.errorResponse.toString());
    } else {
      setCardError(message);
    }
    setLoading(false);
  }
}
