import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import Button from '../shared/Button/Button';
import Alert, { AlertData, errorAlert } from '../shared/Alert';
import { Dentist } from '../ServiceContext/user';
import { useServices } from '../ServiceContext/ServiceContext';
import { toApiDateTimeFormat } from '../shared/dates/dates';
import ValidatedInput from '../shared/ValidatedInput/ValidatedInput';
import {
  buildDateString,
  createRRuleStringForDate,
  isEarlierOrEqual,
  isLaterOrEqual,
  isOneHourApart,
  validateClockTime,
} from '../shared/time/time';
import { AvailabilityEvent } from '../ServiceContext/calendar';
import { isErrorResponse } from '../API/response';
import { AvailabilityWorkflow } from '../amplitude';
import { getTimeZoneOfDentist } from '../shared/timezone/timeZone';

interface Props {
  dentist: Dentist;
  onCancelled: () => void;
  onAvailabilityAdded: (args: {
    newAvailabilities: AvailabilityEvent[];
    deletedAvailabilities: AvailabilityEvent[];
    availabilityDate: Date;
  }) => void;
}

const AddAvailability: React.FC<Props> = ({ dentist, onCancelled, onAvailabilityAdded }) => {
  const services = useServices();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedStartTime, setSelectedStartTime] = useState<string>('');
  const [startTimeValid, setStartTimeValid] = useState(false);
  const [selectedEndTime, setSelectedEndTime] = useState<string>('');
  const [endTimeValid, setEndTimeValid] = useState(false);
  const [availabilityType, setAvailabilityType] = useState<'available' | 'unavailable'>(
    'available'
  );
  const [eventType, setEventType] = useState<'one_time' | 'recurring'>('one_time');
  const [recurrenceSchedule, setRecurrenceSchedule] = useState('');
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const onAvailabilityStartChange = useCallback((value: string, isValid: boolean) => {
    setSelectedStartTime(value);
    setStartTimeValid(isValid);
  }, []);

  const onAvailabilityEndChange = useCallback((value: string, isValid: boolean) => {
    setSelectedEndTime(value);
    setEndTimeValid(isValid);
  }, []);

  const onCreateClicked = async () => {
    if (!selectedDate || !selectedStartTime || !selectedEndTime) {
      return;
    }

    const timeZone = getTimeZoneOfDentist(dentist);

    const startTimeDateString = buildDateString(selectedDate.toISOString(), selectedStartTime);
    let startTimeInOfficeTz = DateTime.fromISO(startTimeDateString);
    startTimeInOfficeTz = startTimeInOfficeTz.setZone(timeZone, { keepLocalTime: true });

    const endTimeDateString = buildDateString(selectedDate.toISOString(), selectedEndTime);
    let endTimeInOfficeTz = DateTime.fromISO(endTimeDateString);
    endTimeInOfficeTz = endTimeInOfficeTz.setZone(timeZone, { keepLocalTime: true });

    setIsCreating(true);
    const res = await services.calendarService.createAvailabilityEventForDentist({
      dentistId: dentist.id,
      startUTCTimestamp: toApiDateTimeFormat(startTimeInOfficeTz.toUTC()),
      endUTCTimestamp: toApiDateTimeFormat(endTimeInOfficeTz.toUTC()),
      recurrenceSchedule,
      availabilityType,
      eventType,
    });
    setIsCreating(false);

    if (isErrorResponse(res)) {
      setAlert(errorAlert(res.errorResponse));
      return;
    }

    let newAvailabilities: AvailabilityEvent[];
    let deletedAvailabilities: AvailabilityEvent[] = [];
    if (Array.isArray(res)) {
      newAvailabilities = [...res];
    } else {
      newAvailabilities = [...res.createdEvents];
      deletedAvailabilities = [...res.deletedEvents];
    }

    onAvailabilityAdded({
      newAvailabilities,
      deletedAvailabilities,
      availabilityDate: startTimeInOfficeTz.toJSDate(),
    });
  };

  const renderControlButtons = () => {
    const disableCreate =
      isCreating ||
      selectedStartTime === '' ||
      selectedEndTime === '' ||
      !startTimeValid ||
      !endTimeValid;
    return (
      <div className="availability-modal-control-buttons flex flex-row gap-4 mt-4">
        <Button
          id="create-button"
          onClick={onCreateClicked}
          loading={isCreating}
          disabled={disableCreate}
          omitBorder
          workflow={AvailabilityWorkflow}
          context="addAvailability"
          trackingLabel="Create Availability Button"
        >
          Create
        </Button>
        <Button
          id="cancel-button"
          onClick={onCancelled}
          noFill
          workflow={AvailabilityWorkflow}
          context="addAvailability"
          trackingLabel="Cancel Create Availability Button"
        >
          Cancel
        </Button>
      </div>
    );
  };

  const renderAlert = () => {
    if (alert) {
      return <Alert {...alert} />;
    }

    return null;
  };

  const renderDateSelect = () => {
    return (
      <DatePicker
        id="availability-date-picker"
        className="availability-time-picker border rounded-lg p-1.5 pl-3 focus:outline-blue-200 w-full"
        popperPlacement="bottom"
        autoComplete="off"
        placeholderText="Enter availability date"
        selected={selectedDate}
        dateFormat="MMMM d, yyyy"
        onChange={(dateValue) => {
          if (typeof dateValue === 'object') {
            const date = dateValue as Date;
            setSelectedDate(date);
          }
          setSelectedStartTime('');
          setSelectedEndTime('');
        }}
      />
    );
  };

  const renderStartInput = () => {
    return (
      <ValidatedInput
        className="time-input w-full !text-[16px]"
        disabled={!selectedDate}
        validations={[
          {
            type: 'function',
            fn: (s) => validateClockTime(s),
            errorMessage: 'Please enter a valid time (12-hour clock, ending with AM/PM)',
          },
          {
            type: 'function',
            fn: (s) => isLaterOrEqual(s, '06:00'),
            errorMessage: 'Time cannot be earlier than 6AM',
          },
          {
            type: 'function',
            fn: (s) => isEarlierOrEqual(s, '20:00'),
            errorMessage: 'Time cannot be later than 8PM',
          },
          {
            type: 'function',
            fn: (s) => !Boolean(selectedEndTime) || isOneHourApart(s, selectedEndTime),
            errorMessage: 'End time must be at least one hour after start time',
          },
        ]}
        onChange={onAvailabilityStartChange}
        placeholder="Enter availability start"
      />
    );
  };

  const renderEndInput = () => {
    return (
      <ValidatedInput
        className="time-input w-full !text-[16px]"
        disabled={!selectedDate}
        validations={[
          {
            type: 'function',
            fn: (s) => validateClockTime(s),
            errorMessage: 'Please enter a valid time (12-hour clock, ending with AM/PM)',
          },
          {
            type: 'function',
            fn: (s) => isLaterOrEqual(s, '06:00'),
            errorMessage: 'Time cannot be earlier than 6AM',
          },
          {
            type: 'function',
            fn: (s) => isEarlierOrEqual(s, '20:00'),
            errorMessage: 'Time cannot be later than 8PM',
          },
          {
            type: 'function',
            fn: (s) => isLaterOrEqual(s, selectedStartTime),
            errorMessage: 'Availability end time must be after its start time',
          },
          {
            type: 'function',
            fn: (s) => isOneHourApart(selectedStartTime, s),
            errorMessage: 'End time must be at least one hour after start time',
          },
        ]}
        onChange={onAvailabilityEndChange}
        placeholder="Enter availability end"
      />
    );
  };

  const disableSelects = !selectedDate;

  const selectClasses = `text-xs border rounded-md p-2 focus:outline-blue-100 ${
    disableSelects
      ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
      : 'bg-white text-black cursor-pointer'
  }`;

  return (
    <div className="mark-availability-modal-content flex flex-col justify-start items-start gap-3 text-[#5a5a5a] my-2 h-full">
      <h5 className="title text-2xl">Add Availability</h5>
      {renderAlert()}
      <div className="date-picker-container flex items-start justify-start w-[80%]">
        {renderDateSelect()}
      </div>
      <div className="start-input-container w-[80%]">{renderStartInput()}</div>
      <div className="end-input-container w-[80%]">{renderEndInput()}</div>
      <div className="availability-container w-[80%]">
        <select
          className={selectClasses}
          aria-label="availability type select"
          disabled={!selectedDate}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e.target.value === 'available' || e.target.value === 'unavailable') {
              setAvailabilityType(e.target.value);
            }
          }}
        >
          <option value="available">Available</option>
          <option value="unavailable">Unavailable</option>
        </select>
      </div>
      <div className="settings-select-container w-[80%]">
        <select
          className={selectClasses}
          aria-label="event type select"
          disabled={!selectedDate}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (!selectedDate) {
              return;
            }
            const luxonDate = DateTime.fromJSDate(selectedDate);
            if (e.target.value === 'one_time') {
              setEventType(e.target.value);
            } else if (e.target.value === 'recurring') {
              setEventType(e.target.value);
              setRecurrenceSchedule(
                createRRuleStringForDate({
                  date: luxonDate.toFormat('yyyy-MM-dd'),
                  recurrenceSetting: 'weekly',
                })
              );
            }
          }}
        >
          <option value="one_time">One-time</option>
          <option value="recurring">Recurring</option>
        </select>
      </div>
      {eventType === 'recurring' && (
        <div className="recurring-settings-select-container w-[80%]">
          <select
            className={selectClasses}
            aria-label="recurrence schedule"
            disabled={!selectedDate || !eventType}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (!selectedDate) {
                return;
              }
              const luxonDate = DateTime.fromJSDate(selectedDate);
              if (e.target.value === 'weekly') {
                setRecurrenceSchedule(
                  createRRuleStringForDate({
                    date: luxonDate.toFormat('yyyy-MM-dd'),
                    recurrenceSetting: 'weekly',
                  })
                );
              } else if (e.target.value === 'every-weekday') {
                setRecurrenceSchedule(
                  createRRuleStringForDate({
                    date: luxonDate.toFormat('yyyy-MM-dd'),
                    recurrenceSetting: 'every-weekday',
                  })
                );
              }
            }}
          >
            <option value="weekly">Weekly</option>
            <option value="every-weekday">Every Weekday</option>
          </select>

          <span className="recurring-disclaimer italic text-xs">
            * Recurring availability blocks expire after 3 months. Please make adjustments
            accordingly.
          </span>
        </div>
      )}
      <div className="control-buttons-container">{renderControlButtons()}</div>
    </div>
  );
};

export default AddAvailability;
