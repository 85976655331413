import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { authenticatedGet, authenticatedPatch } from './service';
import { apiUrl } from './shared';
import { Practice } from './user';

export type TermsOfServiceConsentStatus = {
  practiceId: string;
  hasAgreedToLatestTermsOfService: boolean;
  termsOfServiceUrl: string;
  termsOfServiceTitle: string;
  termsOfServiceId: string;
};

export interface PracticesService {
  getPracticeById: (practiceId: string) => Promise<Practice | ErrorResponse>;
  getTermsOfServiceConsentStatus: ({
    practiceId,
  }: {
    practiceId: string;
  }) => Promise<TermsOfServiceConsentStatus | ErrorResponse>;
  agreeToTermsOfService: ({
    practiceId,
    termsOfServiceId,
  }: {
    practiceId: string;
    termsOfServiceId: string;
  }) => Promise<TermsOfServiceConsentStatus | ErrorResponse>;
}

const getPracticeById = (authProvider: AuthProvider) => {
  return async (practiceId: string) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedGet<Practice>(authProvider, apiUrl(`/practices/${practiceId}`));
  };
};

const getTermsOfServiceConsentStatus = (authProvider: AuthProvider) => {
  return async ({ practiceId }: { practiceId: string }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }

    return authenticatedGet<TermsOfServiceConsentStatus>(
      authProvider,
      apiUrl(`/practices/${practiceId}/terms-of-service/latest`)
    );
  };
};

const agreeToTermsOfService = (authProvider: AuthProvider) => {
  return async ({
    practiceId,
    termsOfServiceId,
  }: {
    practiceId: string;
    termsOfServiceId: string;
  }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedPatch<TermsOfServiceConsentStatus>(
      authProvider,
      apiUrl(`/practices/${practiceId}/terms-of-service/${termsOfServiceId}`),
      { agreesToTermsOfService: true }
    );
  };
};

const makeService = (auth: AuthProvider) => {
  return {
    getPracticeById: getPracticeById(auth),
    getTermsOfServiceConsentStatus: getTermsOfServiceConsentStatus(auth),
    agreeToTermsOfService: agreeToTermsOfService(auth),
  };
};

export default makeService;
