import React, { useState } from 'react';

import { AuthProvider } from '../../../Authentication/Authentication';
import { Appointment } from '../../../ServiceContext/appointments';
import { reportErrorToSentry } from '../../../ServiceContext/error';
import {
  Invoice,
  isLegacyPayment,
  isSplitPayment,
  Payment,
} from '../../../ServiceContext/invoices';
import Modal from '../../../shared/Modal/Modal';
import AppointmentSummary from '../../AppointmentSummary';
import BookFollowUpAppointmentModalContent from '../BookFollowUp/BookFollowUpAppointmentModalContent';
import PricedInvoiceTable from '../PricedInvoiceTable';
import { useInvoicePrintout } from '../PrintComponents/usePrint';
import SavingsSummary from '../SavingsSummary';
import { extractInvoicingComponents } from '../utilities';
import PaymentCompleteNextSteps from './PaymentCompleteNextSteps';

type Props = {
  authProvider: AuthProvider;
  appointment: Appointment;
  invoice: Invoice | null;
  payment: Payment | null;
};

const PaymentComplete: React.FC<Props> = ({ authProvider, appointment, invoice, payment }) => {
  const [isBookFollowUpModalOpen, setIsBookFollowUpModalOpen] = useState(false);
  const { printButton, printComponent } = useInvoicePrintout({
    appointmentTimeInUTC: appointment.start,
    appointmentTimeZone: appointment.timeZone,
    dentistLocation: appointment.location,
    invoice,
    dentistName: `${appointment.dentist.firstName} ${appointment.dentist.lastName}`,
    patientName: `${appointment.user?.firstName} ${appointment.user?.lastName}`,
    paymentLastFour: '',
    patientOnly: true,
    isInsurance: Boolean(appointment.patientInsuranceId),
    legacyPayment: isLegacyPayment(payment) ? payment : null,
    paymentSplits: isSplitPayment(payment) ? payment.paymentSplits : undefined,
  });

  const insurancePayment =
    (isSplitPayment(payment) && payment.paymentSplits.some((s) => s.paymentType === 'insurance')) ||
    (isLegacyPayment(payment) && payment.paymentType === 'insurance');

  if (!invoice) {
    reportErrorToSentry({
      summary: `Payment complete page for appointment ${appointment.id} has no invoice`,
      extra: {
        appointmentId: appointment.id,
      },
    });
    return null;
  }
  if (!payment || (!isLegacyPayment(payment) && !isSplitPayment(payment))) {
    reportErrorToSentry({
      summary: `Payment complete page for appointment ${appointment.id} has no payment`,
      extra: {
        appointmentId: appointment.id,
        invoiceId: invoice ? invoice.id : null,
      },
    });
    return null;
  }

  const { savingsAmount } = extractInvoicingComponents({
    invoice,
  });

  let flossyFeeRecoupMessage = '';
  if (isLegacyPayment(payment)) {
    if (payment.paymentType !== 'credit-card' && payment.paymentType !== 'insurance') {
      flossyFeeRecoupMessage = 'The Flossy Fee will be recouped in your next payout.';
    }
  } else if (isSplitPayment(payment)) {
    if (
      payment.paymentSplits.some(
        (s) => s.paymentType !== 'credit-card' && s.paymentType !== 'insurance'
      )
    ) {
      flossyFeeRecoupMessage = 'The Flossy Fee will be recouped in your next payout.';
    }
  }

  return (
    <div className="h-full flex flex-col gap-3 text-secondary">
      <span id={'title'} className={'font-semibold text-2xl text-left'}>
        Payment Complete
      </span>
      <span
        id={'payment-success-notification'}
        className={'flex flex-col gap-2 text-xs text-left rounded-lg bg-success p-3 w-full'}
      >
        <span className={'font-bold text-lg'}>Thank you for your payment!</span>
        {flossyFeeRecoupMessage && `\n${flossyFeeRecoupMessage}`}
      </span>
      <PaymentCompleteNextSteps
        onBookFollowUp={() => setIsBookFollowUpModalOpen(true)}
        appointment={appointment}
      />
      <div className={'bg-white rounded-md flex flex-col gap-2 items-start pb-4'}>
        <div className={'flex flex-row justify-between w-full items-center pr-4'}>
          <AppointmentSummary
            appointment={appointment}
            patientName={`${appointment.user?.firstName} ${appointment.user?.lastName}`}
          />
          {printButton}
        </div>
        <PricedInvoiceTable invoice={invoice} isInsurance={insurancePayment} />
        {insurancePayment && <div className="p-4">* This appointment was paid with insurance.</div>}
        {savingsAmount >= invoice.total / 10 && !insurancePayment && (
          <SavingsSummary savingsAmount={savingsAmount} />
        )}
      </div>
      <Modal
        isOpen={isBookFollowUpModalOpen}
        onRequestClose={() => setIsBookFollowUpModalOpen(false)}
        shape={'big-square'}
      >
        {appointment.user && (
          <BookFollowUpAppointmentModalContent
            authProvider={authProvider}
            patientId={appointment.user.id}
            includeActionButtons={true}
            onCloseClick={() => setIsBookFollowUpModalOpen(false)}
            appointment={appointment}
          />
        )}
      </Modal>
      {printComponent}
    </div>
  );
};

export default PaymentComplete;
