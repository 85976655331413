import { useState } from 'react';

import { BookingChargeCredit, PaymentHistoryRecord } from '../../../API/bookingsAndCredits';
import { QueryContext } from '../../../API/context';
import { AuthProvider } from '../../../Authentication/Authentication';
import { useURLQuery } from '../../../shared/routing/routing';
import PastMonthsLedgerTable, { MonthlyLedger } from './PastMonthsLedgerTable';
import SinglePastMonthView from './SinglePastMonthView';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  monthlyLedgerData: MonthlyLedger[];
  queryContext: QueryContext;
  paymentHistoryRecords: PaymentHistoryRecord[];
  allCreditItems: BookingChargeCredit[];
};

const PastMonths: React.FC<Props> = ({
  authProvider,
  practiceId,
  monthlyLedgerData,
  queryContext,
  paymentHistoryRecords,
  allCreditItems,
}) => {
  const [dateOrderByDirection, setDateOrderByDirection] = useState<'ASC' | 'DESC'>('DESC');
  const { query } = useURLQuery();

  const selectedPeriod = query.get('period');

  if (selectedPeriod) {
    return (
      <SinglePastMonthView
        authProvider={authProvider}
        practiceId={practiceId}
        data={monthlyLedgerData}
        selectedPeriod={selectedPeriod}
        dateOrderByDirection={dateOrderByDirection}
        setDateOrderByDirection={setDateOrderByDirection}
        queryContext={queryContext}
        paymentHistoryRecords={paymentHistoryRecords}
        allCreditItems={allCreditItems}
      />
    );
  }
  return (
    <PastMonthsLedgerTable
      tableData={monthlyLedgerData}
      paymentHistoryRecords={paymentHistoryRecords}
    />
  );
};

export default PastMonths;
