import React from 'react';
import { useNavigate } from 'react-router-dom';

import commonAppointmentColumns from '../Appointments/Tables/common_columns';
import { AuthProvider } from '../Authentication/Authentication';
import { Appointment } from '../ServiceContext/appointments';
import { stringifyMoney } from '../shared/money/stringifyMoney';
import { appointmentSortFunctions } from '../shared/Table/SortFunction';
import Table, { ColumnDef } from '../shared/Table/Table';
import LinkText from '../shared/Text/LinkText';
import InvoiceTableRowActionButton from './InvoiceTableRowActionButton';

type Props = {
  isLoadingDisplayInvoices: boolean;
  authentication: AuthProvider;
  appointmentData: Appointment[];
  setDateOrderByDirection: (direction: 'ASC' | 'DESC') => void;
};

const InvoicesTable: React.FC<Props> = ({
  appointmentData,
  isLoadingDisplayInvoices,
  authentication,
  setDateOrderByDirection,
}) => {
  const navigate = useNavigate();

  const generateColumnDef = () => {
    let tableColumns: ColumnDef<Appointment>[];
    let baseColumns = commonAppointmentColumns.map((c) => {
      if (c.columnKey === 'patient') {
        return {
          ...c,
          render: (record: Appointment) => {
            if (!record.user) {
              return '';
            }
            return (
              <LinkText
                onClick={() => {
                  navigate(`/appointments/${record.id}/details`);
                }}
                className="patient-name !text-base-content !text-sm !font-semibold !pl-0 hover:opacity-75 text-start"
              >
                {record.user.firstName} {record.user.lastName}
              </LinkText>
            );
          },
        };
      } else if (c.columnKey === 'date') {
        return {
          ...c,
          sortable: appointmentSortFunctions,
          sortableRemote: {
            ascending: () => {
              setDateOrderByDirection('ASC');
            },
            descending: () => {
              setDateOrderByDirection('DESC');
            },
          },
        };
      }
      return c;
    });

    const statusStyling = 'py-1 px-1.5 rounded-2xl bg-primary text-white font-semibold text-xs';

    const statusColumn = {
      columnKey: 'status',
      title: 'Status',
      widthProportion: 0.7,
      render: (record: Appointment) => {
        if (!record.invoice) {
          return <span className={statusStyling}>Open</span>;
        } else {
          if (record.invoice.isDraft) {
            return <span className={statusStyling}>Draft</span>;
          }
          return <span className={statusStyling}>Paid</span>;
        }
      },
    };

    const amountColumn = {
      columnKey: 'amount',
      title: 'Amount',
      widthProportion: 1,
      render: (record: Appointment) => {
        return (
          <span className={'text-xs'}>
            {record.invoice?.total
              ? stringifyMoney(record.invoice.total, { includeCommas: true })
              : '---'}
          </span>
        );
      },
    };

    const actionButtonColumn = {
      columnKey: 'actionButton',
      title: '',
      widthProportion: 1,
      render: (record: Appointment) => {
        return <InvoiceTableRowActionButton appointment={record} />;
      },
    };

    const emptyColumn = {
      columnKey: 'space',
      title: '',
      widthProportion: 0.1,
      render: () => {
        return '';
      },
    };

    tableColumns = [emptyColumn, statusColumn, ...baseColumns, amountColumn, actionButtonColumn];

    return tableColumns;
  };

  let tableData: Appointment[] = appointmentData;

  if (isLoadingDisplayInvoices) {
    return (
      <div
        id={'invoices-table-container'}
        className={'w-full flex flex-column justify-start items-start'}
      >
        <Table
          id={'invoices-table'}
          columns={generateColumnDef()}
          data={tableData}
          rowKeyGenerator={(record: Appointment) => `${record.id}`}
        />
      </div>
    );
  }

  return (
    <div
      id="appointments-table-container"
      className="table-container w-full flex flex-column justify-start items-start"
    >
      <Table
        id={'invoices-table'}
        columns={generateColumnDef()}
        data={tableData}
        rowKeyGenerator={(record: Appointment) => `${record.id}`}
        tableClassNameExt={'bg-white !border-0 rounded-t-none'}
      />
    </div>
  );
};

export default InvoicesTable;
