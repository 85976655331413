import { useRef } from 'react';
import ReactToPrint from 'react-to-print';

import { ShareInvoiceWorkflow } from '../../../amplitude';
import { Booking, BookingChargeCredit } from '../../../API/bookingsAndCredits';
import { AuthProvider } from '../../../Authentication/Authentication';
import Button from '../../../shared/Button/Button';
import PrintedInvoice from './PrintedInvoice';

export const usePrintedInvoice = ({
  authProvider,
  balanceDueDate,
  practiceName,
  bookingsWithPendingCharges,
  appliedCredits,
}: {
  authProvider: AuthProvider;
  balanceDueDate: string;
  practiceName: string;
  bookingsWithPendingCharges: Booking[];
  appliedCredits: BookingChargeCredit[];
}) => {
  const bookingsInvoicePrintedContentRef = useRef<HTMLDivElement>(null);

  return {
    printButton: (
      <ReactToPrint
        trigger={() => {
          return (
            <Button
              onClick={() => {}}
              color="tertiary"
              trackingLabel="Print or Download Invoice Button"
              workflow={ShareInvoiceWorkflow}
            >
              Print / Download Invoice
            </Button>
          );
        }}
        content={() => bookingsInvoicePrintedContentRef.current}
      />
    ),
    printComponent: (
      <div className="content-to-print hidden">
        <PrintedInvoice
          practiceName={practiceName}
          balanceDueDate={balanceDueDate}
          bookingsWithPendingCharges={bookingsWithPendingCharges}
          appliedCredits={appliedCredits}
          ref={bookingsInvoicePrintedContentRef}
        />
      </div>
    ),
  };
};
