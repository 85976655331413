import { ApiErrorDetailsObject, isApiErrorDetailsObject } from '../../API/response';

export function beautifyErrorMessage(errorMessage: string) {
  if (errorMessage.includes('This value must be greater than or equal to 0')) {
    return 'You cannot process invoices with a total less than to $0.';
  }
  if (
    errorMessage.includes('Firebase: Error (auth/user-not-found).') ||
    errorMessage.includes('Firebase: Error (auth/wrong-password).')
  ) {
    return 'The username you entered is not found or your password is incorrect.';
  }
  if (errorMessage.includes('(auth/too-many-requests).')) {
    return 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.';
  }
  return errorMessage;
}

export function extractApiErrorMessage(error: string | ApiErrorDetailsObject): string {
  let msg: string;
  if (typeof error === 'string') {
    msg = error;
  } else if (isApiErrorDetailsObject(error) && error.errors.length > 0) {
    msg = error.errors[0].message;
  } else {
    msg = `Unexpected error received: ${error}`;
  }
  return msg;
}
