import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { Message } from './messages';
import { authenticatedGet, authenticatedNoContentPut } from './service';
import { apiUrl } from './shared';

export type DentistNotificationSetupExplanation = {
  registeredEvents: string[];
  recipientEmails: string[];
  recentNotifications: Message[];
};

export interface DentistsService {
  updateDentistFcmClientToken: ({
    dentistId,
    token,
  }: {
    dentistId: string;
    token: string;
  }) => Promise<null | ErrorResponse>;
  getNotificationSetupExplanation: ({
    dentistId,
  }: {
    dentistId: string;
  }) => Promise<DentistNotificationSetupExplanation | ErrorResponse>;
}

const updateDentistFcmClientToken = (authProvider: AuthProvider) => {
  return async ({ token, dentistId }: { token: string; dentistId: string }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }

    return authenticatedNoContentPut(
      authProvider,
      apiUrl(`/dentists/${dentistId}/fcmClientToken`),
      {
        token,
      }
    );
  };
};

const getNotificationSetupExplanation = (authProvider: AuthProvider) => {
  return async ({ dentistId }: { dentistId: string }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }

    return authenticatedGet<DentistNotificationSetupExplanation | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/${dentistId}/notification-explanation`)
    );
  };
};

const makeService = (auth: AuthProvider) => {
  return {
    updateDentistFcmClientToken: updateDentistFcmClientToken(auth),
    getNotificationSetupExplanation: getNotificationSetupExplanation(auth),
  };
};

export default makeService;
