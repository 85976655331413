import { BookingCharge, BookingChargeCredit } from '../../../../API/bookingsAndCredits';
import AvailableCredits from './AvailableCredits';
import CreditsSummary from './CreditsSummary';
import ReferralCreditsTable from './ReferralCreditsTable';

type Props = {
  allCreditItems: BookingChargeCredit[];
  allChargeItems: BookingCharge[];
};

const ReferralCredits: React.FC<Props> = ({ allCreditItems, allChargeItems }) => {
  return (
    <div id={'referral-credits-tab-content'} className="flex flex-row gap-4">
      <div id={'credits-summary'} className={'flex flex-col gap-3 w-1/4'}>
        <AvailableCredits allCreditItems={allCreditItems} />
        <CreditsSummary allCreditItems={allCreditItems} />
      </div>
      <ReferralCreditsTable allChargeItems={allChargeItems} allCreditItems={allCreditItems} />
    </div>
  );
};

export default ReferralCredits;
