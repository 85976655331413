import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { isInFuture, isInPast } from '../shared/dates/dates';
import { Invoice, Payment, PaymentMethod } from './invoices';
import { Patient } from './patients';
import {
  authenticatedGet,
  authenticatedNoContentPost,
  authenticatedPost,
  authenticatedPut,
} from './service';
import { Address, apiUrl, GoogleCalendarEvent, Service, Timestamp } from './shared';
import { Dentist } from './user';

export interface AppointmentsService {
  getAppointments: ({
    page,
    pageSize,
    timeMin,
    timeMax,
    dentistId,
    confirmStatus,
    patientName,
  }: {
    page: number;
    pageSize: number;
    timeMin?: string;
    timeMax?: string;
    dentistId?: string;
    confirmStatus?: string;
    patientName?: string;
  }) => Promise<GetAppointmentsResponse | ErrorResponse>;
  getAppointment: (appointmentId: string) => Promise<Appointment | ErrorResponse>;
  getNewBookings: ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => Promise<GetAppointmentsResponse | ErrorResponse>;
  markAppointmentAcknowledged: ({
    appointmentId,
  }: {
    appointmentId: string;
  }) => Promise<Appointment | ErrorResponse>;
  associatePaymentMethod: (
    newPaymentMethod: PaymentMethod,
    appointmentId: string
  ) => Promise<Appointment | ErrorResponse>;
  bookFollowUp: ({
    previousAppointmentId,
    startTimeInUTC,
    notes,
    billingCodes,
    carryOverInsurance,
    allowOverlapWithExistingAppointments,
  }: {
    previousAppointmentId: string;
    startTimeInUTC: string;
    notes: string;
    billingCodes: string[];
    carryOverInsurance: boolean;
    allowOverlapWithExistingAppointments: boolean;
  }) => Promise<Appointment | ErrorResponse>;
  cancelAppointment: (
    appointmentId: string,
    reason: string,
    cancellationNotes: string
  ) => Promise<null | ErrorResponse>;
  editAppointment: ({
    appointmentId,
    dentistId,
    startTimeInUTC,
    requiresTreatmentPlan,
    missingTreatmentPlanReason,
    status,
  }: {
    appointmentId: string;
    dentistId: string;
    startTimeInUTC?: string;
    requiresTreatmentPlan?: boolean;
    missingTreatmentPlanReason?: string;
    status?: string;
  }) => Promise<Appointment | ErrorResponse>;
}

export interface Appointment {
  id: string;
  userId: string;
  cancellationReason: string | null;
  createdAt: Timestamp;
  cancelledAt?: Timestamp;
  cancellationNotes?: string;
  start: Timestamp;
  end: Timestamp;
  preferredDate?: Timestamp;
  timeZone: string;
  googleCalendarEvent: GoogleCalendarEvent;
  dentistId: string;
  dentist: Dentist;
  payment: Payment | null;
  location: Address;
  status: 'open' | 'requested' | 'confirmed' | 'completed' | 'cancelled';
  services: Service[] | null;
  paymentMethod: PaymentMethod | {} | null;
  paymentMethods?: PaymentMethod[] | null;
  notes: string;
  stripeSetupIntentId: string;
  invoice: Invoice | null;
  user: Patient;
  scheduleStatus: 'scheduled';
  promoCode: string | null;
  billingCodes?: string[];
  confirmStatus: 'confirmed' | 'unconfirmed';
  requiresTreatmentPlan?: boolean;
  missingTreatmentPlanReason?: string;
  appointmentReason?: string;
  acknowledgedByDentistAt: Timestamp | null;
  patientInsuranceId: string | null;
}

export function appointmentHasInsurance(a: Appointment) {
  return !!a.patientInsuranceId;
}

export const isUpcomingAppointment = (apt: Appointment) => {
  // OK, so technically, upcoming appointments will never be processed first, and processed appointments
  // should always be in the past. But for the sake of smoke testing this MVP, we're going to allow
  // future processed payments appear in the processed appointments list, and OMIT them from the upcoming
  // list.
  // return isInFuture(apt.start, apt.timeZone);

  // Once you're at 15 minutes prior to the start time of an appointment, we no longer consider it
  // upcoming. This should have the effect of moving it into the past appointments list.
  const fifteenMinuteBuffer = -1 * 60 * 15;
  return !isProcessedAppointment(apt) && isInFuture(apt.start, apt.timeZone, fifteenMinuteBuffer);
};

export const isCompletedAppointment = (apt: Appointment) => {
  return isInPast(apt.end, apt.timeZone);
};

export const isProcessedAppointment = (apt: Appointment) => {
  return !!apt.invoice && (apt.invoice.status === 'paid' || apt.invoice.status === 'settled');
};

export const isCancelledAppointment = (apt: Appointment) => {
  return apt.status === 'cancelled';
};

export type GetAppointmentsResponse = { appointments: Appointment[]; totalCount: number };

const getAppointments = (authProvider: AuthProvider) => {
  return async ({
    page,
    pageSize,
    timeMin,
    timeMax,
    dentistId,
    confirmStatus,
    patientName,
  }: {
    page: number;
    pageSize: number;
    timeMin?: string;
    timeMax?: string;
    dentistId?: string;
    confirmStatus?: string;
    patientName?: string;
  }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }

    let params = new URLSearchParams('');

    params.append('skip', (pageSize * (page - 1)).toString());
    params.append('maxResults', pageSize.toString());
    params.append('excludeCancelled', 'true');
    if (patientName) {
      params.append('patientName', encodeURI(patientName));
    }
    if (dentistId) {
      params.append('dentistId', dentistId);
    }

    if (confirmStatus === 'confirmed') {
      params.append('confirmStatus', 'confirmed');
    } else if (confirmStatus === 'unconfirmed') {
      params.append('confirmStatus', 'unconfirmed');
    }

    if (timeMin) {
      params.append('timeMin', timeMin);
      params.append('isProcessed', 'false');
    } else if (timeMax) {
      params.append('timeMax', timeMax);
      params.append('isProcessed', 'false');
    } else {
      params.append('isProcessed', 'true');
    }
    const url = `/managers/${authUser.user.id}/appointments?${params}`;

    return authenticatedGet<GetAppointmentsResponse | ErrorResponse>(
      authProvider,
      apiUrl(url.toString())
    );
  };
};

const getNewBookings = (authProvider: AuthProvider) => {
  return async ({ page, pageSize }: { page: number; pageSize: number }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }

    let params = new URLSearchParams('');

    params.append('skip', (pageSize * (page - 1)).toString());
    params.append('maxResults', pageSize.toString());
    const url = `/managers/${authUser.user.id}/new-bookings?${params}`;

    return authenticatedGet<GetAppointmentsResponse | ErrorResponse>(
      authProvider,
      apiUrl(url.toString())
    );
  };
};

const markAppointmentAcknowledged = (authProvider: AuthProvider) => {
  return async ({ appointmentId }: { appointmentId: string }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }

    return authenticatedPost<Appointment | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/appointments/${appointmentId}/acknowledgements`),
      {}
    );
  };
};

const getAppointment = (authProvider: AuthProvider) => {
  return async (appointmentId: string) => {
    return authenticatedGet<Appointment | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/appointments/${appointmentId}`)
    );
  };
};

const associatePaymentMethod = (authProvider: AuthProvider) => {
  return async (newPaymentMethod: PaymentMethod, appointmentId: string) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedPost<Appointment | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/appointments/${appointmentId}/paymentMethod`),
      newPaymentMethod
    );
  };
};

const bookFollowUp = (authProvider: AuthProvider) => {
  return async ({
    previousAppointmentId,
    startTimeInUTC,
    notes,
    billingCodes,
    carryOverInsurance,
    allowOverlapWithExistingAppointments,
  }: {
    previousAppointmentId: string;
    startTimeInUTC: string;
    notes: string;
    billingCodes: string[];
    carryOverInsurance: boolean;
    allowOverlapWithExistingAppointments: boolean;
  }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }
    return authenticatedPost<Appointment | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/appointments/${previousAppointmentId}/followUp`),
      {
        start: startTimeInUTC,
        notes: notes,
        billingCodes: billingCodes,
        carryOverInsurance,
        allowOverlapWithExistingAppointments,
      }
    );
  };
};

const cancelAppointment = (authProvider: AuthProvider) => {
  return async (appointmentId: string, reason: string, cancellationNotes: string) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }

    return authenticatedNoContentPost(
      authProvider,
      apiUrl(`/dentists/appointments/${appointmentId}/cancel?reason=${reason}`),
      { cancellationNotes }
    );
  };
};

const editAppointment = (authProvider: AuthProvider) => {
  return async ({
    appointmentId,
    dentistId,
    startTimeInUTC,
    requiresTreatmentPlan,
    missingTreatmentPlanReason,
    status,
  }: {
    appointmentId: string;
    dentistId: string;
    startTimeInUTC?: string;
    requiresTreatmentPlan?: boolean;
    missingTreatmentPlanReason?: string;
    status?: string;
  }) => {
    const authUser = authProvider.authUser;
    if (!authUser) {
      return Promise.reject();
    }

    interface bodyOrFormData {
      start?: string;
      dentistId: string;
      requiresTreatmentPlan?: boolean;
      missingTreatmentPlanReason?: string;
      status?: string;
    }

    const appointmentBodyOrFormData: bodyOrFormData = { dentistId };

    if (startTimeInUTC) {
      // This is a rescheduling of this appointment.
      appointmentBodyOrFormData.start = startTimeInUTC;
    }
    // We're specifically checking for the presence of either boolean value here.
    if (requiresTreatmentPlan === true || requiresTreatmentPlan === false) {
      appointmentBodyOrFormData.requiresTreatmentPlan = requiresTreatmentPlan;
    }
    if (missingTreatmentPlanReason) {
      appointmentBodyOrFormData.missingTreatmentPlanReason = missingTreatmentPlanReason;
    }
    if (status) {
      appointmentBodyOrFormData.status = status;
    }
    appointmentBodyOrFormData.dentistId = dentistId;

    return authenticatedPut<Appointment | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/${dentistId}/appointments/${appointmentId}`),
      appointmentBodyOrFormData
    );
  };
};

const makeService = (auth: AuthProvider): AppointmentsService => {
  return {
    getAppointments: getAppointments(auth),
    getAppointment: getAppointment(auth),
    getNewBookings: getNewBookings(auth),
    markAppointmentAcknowledged: markAppointmentAcknowledged(auth),
    associatePaymentMethod: associatePaymentMethod(auth),
    bookFollowUp: bookFollowUp(auth),
    cancelAppointment: cancelAppointment(auth),
    editAppointment: editAppointment(auth),
  };
};

export default makeService;
