import React from 'react';

import { Message, MessageTarget, MessageType } from '../../ServiceContext/messages';
import { toShortDateTimeString } from '../../shared/dates/dates';

export function renderMessageType(type: MessageType) {
  switch (type) {
    case 'new_booked_appointment':
      return 'New Booked Appointment';
    case 'appointment_cancellation':
      return 'Appointment Cancellation';
    default:
      return null;
  }
}

export function renderMessageTarget(target: MessageTarget) {
  switch (target) {
    case 'portal-app':
      return 'Portal Bell';
    case 'portal-web-push':
      return 'Portal Desktop';
    case 'email':
      return 'Email';
    case 'sms':
      return 'SMS';
  }
}

export const NotificationMessageRow: React.FC<{ message: Message }> = ({ message }) => {
  const renderMessageDetails = () => {
    switch (message.type) {
      case 'new_booked_appointment':
        return (
          <>
            <td>{message.payload.patientName}</td>
            <td>{message.payload.dentistName}</td>
            <td>{toShortDateTimeString(message.payload.start, message.payload.timeZone)}</td>
            <td>{message.payload.appointmentId}</td>
            <td>{toShortDateTimeString(message.sentAt, message.payload.timeZone)}</td>
          </>
        );
      case 'appointment_cancellation':
        return (
          <>
            <td>{message.payload.patientName}</td>
            <td>{message.payload.dentistName}</td>
            <td>{toShortDateTimeString(message.payload.start, message.payload.timeZone)}</td>
            <td>{message.payload.appointmentId}</td>
            <td>{toShortDateTimeString(message.sentAt, message.payload.timeZone)}</td>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <tr className="border-t border-rule">
      <td>{renderMessageType(message.type)}</td>
      <td>{renderMessageTarget(message.target)}</td>
      {renderMessageDetails()}
    </tr>
  );
};
