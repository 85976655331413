import React from 'react';
import { useNavigate } from 'react-router-dom';

const ReturnToAppointments = () => {
  const navigate = useNavigate();
  return (
    <button
      className={'text-base-content font-semibold hover:opacity-75 text-sm'}
      onClick={() => navigate('/appointments')}
    >
      <span>&larr; </span>
      Return to Appointments
    </button>
  );
};

export default ReturnToAppointments;
