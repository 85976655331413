import React from 'react';
import { useQuery } from 'react-query';

import { ConsolidatedPricingTable, getConsolidatedPricingTableForDentist } from '../API/dentist';
import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import StandardizedLoadingPage from '../shared/StandardizedLoadingPage';

type ProvideConsolidatedDentistPricingTableChildProps = {
  authProvider?: AuthProvider;
  consolidatedPricingTable: ConsolidatedPricingTable;
};

type ProvideConsolidatedDentistPricingTableProps = {
  authProvider: AuthProvider;
  dentistId: string;
  children: React.FC<ProvideConsolidatedDentistPricingTableChildProps>;
};

const ProvideConsolidatedDentistPricingTable: React.FC<
  ProvideConsolidatedDentistPricingTableProps
> = ({ authProvider, children, dentistId }) => {
  const getConsolidatedPricingTableKey = ['getConsolidatedPricingTable', dentistId];
  const {
    isLoading: isLoadingConsolidatedPricingTable,
    error: fetchConsolidatedPricingTableError,
    data: consolidatedPricingTable,
  } = useQuery<ConsolidatedPricingTable | null, ErrorResponse>(
    getConsolidatedPricingTableKey,
    () => {
      if (!dentistId) {
        return null;
      }
      return getConsolidatedPricingTableForDentist({ authProvider, dentistId });
    },
    {
      enabled: Boolean(dentistId),
      refetchOnWindowFocus: false,
    }
  );

  if (isLoadingConsolidatedPricingTable || fetchConsolidatedPricingTableError) {
    return (
      <StandardizedLoadingPage
        isLoading={isLoadingConsolidatedPricingTable}
        error={fetchConsolidatedPricingTableError}
      />
    );
  }

  if (!consolidatedPricingTable) {
    return (
      <StandardizedLoadingPage
        isLoading={false}
        error={{
          statusCode: 500,
          errorResponse: `No consolidated pricing table found with id ${dentistId}`,
        }}
      />
    );
  }

  return children({
    authProvider,
    consolidatedPricingTable,
  });
};

export default ProvideConsolidatedDentistPricingTable;
