import { PatientReferralStats } from '../../API/referrals';
import { Practice } from '../../ServiceContext/user';
import CreditsEarned from './CreditsEarned';
import ReferralsRedeemed from './ReferralsRedeemed';
import ReferralsSent from './ReferralsSent';

type Props = {
  practice: Practice;
  statsData: PatientReferralStats;
};
const PatientReferralStatsView: React.FC<Props> = ({ statsData, practice }) => {
  return (
    <div id={'patient-referral-stats'} className={'flex flex-row gap-3 w-full z-10'}>
      <ReferralsSent statsData={statsData} />
      <ReferralsRedeemed statsData={statsData} />
      <CreditsEarned statsData={statsData} practice={practice} />
    </div>
  );
};

export default PatientReferralStatsView;
