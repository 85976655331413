import { AuthProvider } from '../Authentication/Authentication';
import {
  Invoice,
  InvoicePricingAdjustment,
  LegacyPayment,
  SplitPayment,
  TransactionPaymentType,
} from '../ServiceContext/invoices';
import { authenticatedPost, authenticatedPut } from '../ServiceContext/service';
import { apiUrl } from '../ServiceContext/shared';
import { handleAPIResponse } from './response';

export async function createDraftInvoice({
  authProvider,
  appointmentId,
  billingCodes,
  paymentType,
  additionalNotes,
  pricingSystemIds,
}: {
  authProvider: AuthProvider;
  appointmentId: string;
  billingCodes: string[];
  paymentType?: TransactionPaymentType;
  additionalNotes?: string[];
  pricingSystemIds: string[];
}): Promise<Invoice> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedPost<Invoice>(authProvider, apiUrl(`/practices/invoices`), {
      appointmentId,
      billingCodes,
      paymentType,
      additionalNotes,
      pricingSystemIds,
    })
  );
}

export async function updateDraftInvoice({
  authProvider,
  appointmentId,
  billingCodes,
  paymentType,
  additionalNotes,
  pricingSystemIds,
}: {
  authProvider: AuthProvider;
  appointmentId: string;
  billingCodes: string[];
  paymentType?: TransactionPaymentType;
  additionalNotes?: string[];
  pricingSystemIds?: string[];
}): Promise<Invoice> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedPut<Invoice>(
      authProvider,
      apiUrl(`/practices/appointments/${appointmentId}/invoice`),
      {
        billingCodes,
        paymentType,
        additionalNotes,
        pricingSystemIds,
      }
    )
  );
}

export type PaymentBreakdown = {
  splitAmount: number;
  paymentMethodId: string;
  paymentType:
    | 'credit-card'
    | 'cash'
    | 'sunbit'
    | 'care-credit'
    | 'other-financing'
    | 'office-payment-plan'
    | 'insurance';
  otherFinancingDetails: string;
};

export async function splitPayment({
  authProvider,
  appointmentId,
  processedByUserGoogleId,
  processedByUserName,
  paymentSplits,
}: {
  authProvider: AuthProvider;
  appointmentId: string;
  processedByUserGoogleId?: string;
  processedByUserName?: string;
  paymentSplits: PaymentBreakdown[];
}): Promise<SplitPayment> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedPost(
      authProvider,
      apiUrl(`/practices/appointments/${appointmentId}/invoice/split-payment`),
      {
        processedByUserGoogleId,
        processedByUserName,
        paymentSplits,
      }
    )
  );
}

export async function legacyPayInvoice({
  authProvider,
  appointmentId,
  processorName,
  paymentType,
  otherFinancingDetails,
}: {
  authProvider: AuthProvider;
  appointmentId: string;
  processorName: string;
  paymentType: TransactionPaymentType;
  otherFinancingDetails?: string | null;
}): Promise<LegacyPayment> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  let params = new URLSearchParams('');
  params.append('onSession', 'false');
  params.append('processedByUserGoogleId', authUser.firebaseUserId);
  params.append('processedByUserName', encodeURIComponent(processorName));
  params.append('paymentType', paymentType);

  if (otherFinancingDetails) {
    params.append('otherFinancingDetails', encodeURIComponent(otherFinancingDetails));
  }
  return handleAPIResponse(
    authenticatedPost(
      authProvider,
      apiUrl(`/dentists/appointments/${appointmentId}/payment?${params}`),
      {}
    )
  );
}

export async function createInvoice({
  authProvider,
  appointmentId,
  billingCodes,
  fees,
  toothNumberCodes,
  toothSurfaceCodes,
  areaOfMouthCodes,
  financingPercentage,
  paymentType,
}: {
  authProvider: AuthProvider;
  appointmentId: string;
  billingCodes: string[];
  fees?: number[];
  toothNumberCodes?: string[];
  toothSurfaceCodes?: string[];
  areaOfMouthCodes?: string[];
  financingPercentage?: number;
  paymentType?: TransactionPaymentType;
}): Promise<{}> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedPost(authProvider, apiUrl(`/users/invoices`), {
      appointmentId,
      billingCodes,
      fees,
      toothNumberCodes,
      toothSurfaceCodes,
      areaOfMouthCodes,
      financingPercentage,
      paymentType,
    })
  );
}

export async function adjustPricesForInvoice({
  authProvider,
  invoiceId,
  adjustments,
  promoCode,
}: {
  authProvider: AuthProvider;
  invoiceId: string;
  adjustments: InvoicePricingAdjustment[];
  promoCode: string | null;
}): Promise<Invoice> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedPost<Invoice>(
      authProvider,
      apiUrl(`/practices/invoices/${invoiceId}/adjustments`),
      {
        adjustments,
        promoCode: promoCode || undefined,
      }
    )
  );
}
