import React from 'react';

import { AuthProvider } from '../../Authentication/Authentication';
import Alert, { errorAlert } from '../../shared/Alert';
import LoadingSpinner from '../../shared/LoadingSpinner';
import PastPayoutsTable from './PastPayoutsTable';
import { useAPI_pastPayouts } from './useAPI_pastPayouts';
import { usePayoutQuery } from './usePayoutQuery';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  timeZone: string;
};

const PastPayouts: React.FC<Props> = ({ authProvider, practiceId, timeZone }) => {
  const { pastPayouts, isLoading, error } = useAPI_pastPayouts({
    authProvider,
    practiceId,
  });
  const { currentPage, onNewPageSelected } = usePayoutQuery();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Alert {...errorAlert(error)} />;
  }

  return (
    <PastPayoutsTable
      timeZone={timeZone}
      selectedPage={currentPage}
      payoutInfo={pastPayouts || []}
      onNewPageSelected={onNewPageSelected}
      authProvider={authProvider}
    />
  );
};

export default PastPayouts;
