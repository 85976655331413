import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TreatmentPlanWorkflow } from '../../amplitude';
import Button from '../../shared/Button/Button';

type Props = {
  patientId: string;
  onContinueDraftingClick: () => void;
};
const DiscardTreatmentPlanModalContent: React.FC<Props> = ({
  patientId,
  onContinueDraftingClick,
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center gap-4">
      <div className="text-base-content text-2xl font-bold ">Discard Treatment Plan Draft?</div>
      <div className="text-base-content text-sm font-normal leading-normal">
        If you navigate away from this page, this draft will be discarded. Save this draft as a
        Proposal if you’d like to return to it later.
      </div>
      <div className="flex gap-3">
        <Button
          id={'save-treatment-plan-proposal'}
          onClick={onContinueDraftingClick}
          workflow={TreatmentPlanWorkflow}
          trackingLabel="Continue Drafting Button"
        >
          Continue Drafting
        </Button>
        <Button
          id={'save-treatment-plan-proposal'}
          noFill
          onClick={() => navigate(`/patients/${patientId}`)}
          workflow={TreatmentPlanWorkflow}
          trackingLabel="Discard Draft Button"
        >
          Discard Draft
        </Button>
      </div>
    </div>
  );
};

export default DiscardTreatmentPlanModalContent;
