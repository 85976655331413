import { Fragment } from 'react';

import { Booking, BookingChargeCredit } from '../../../../API/bookingsAndCredits';
import { toShortDateString } from '../../../../shared/dates/dates';
import { stringifyMoney } from '../../../../shared/money/stringifyMoney';

type Props = {
  bookingData: Booking[];
  appliedCredits: BookingChargeCredit[];
  alternateRowStyle?: boolean;
};

const BookingsTable: React.FC<Props> = ({ bookingData, appliedCredits, alternateRowStyle }) => {
  const chargesAmount = bookingData.reduce((accumulator, booking) => {
    return accumulator + (booking.charge ? booking.charge.amount : 0);
  }, 0);

  // This is not a thing anymore because credits are now consumed immediately upon application.
  // const totalCredits = appliedCredits.reduce((accumulator, credit) => {
  //   return accumulator + (credit ? credit.amount : 0);
  // }, 0);
  const totalCredits = 0;

  // const totalAmount = subtotal - totalCredits;
  const totalAmount = chargesAmount;

  const renderData = () => {
    return bookingData.map((booking, index) => (
      <Fragment key={index}>
        <tr
          className={`text-sm ${
            alternateRowStyle
              ? index % 2 === 0
                ? 'bg-white'
                : 'bg-highlight bg-opacity-75'
              : 'border-t border-t-1 border-rule'
          }`}
        >
          <td className="text-left py-3 pl-6">
            {`${booking.patient.name} - ${toShortDateString(
              booking.appointmentStartTime,
              booking.appointmentTimeZone
            )}`}
          </td>
          <td className="text-right py-3 pr-6">
            {booking.charge ? stringifyMoney(booking.charge.amount, { includeCommas: true }) : '--'}
          </td>
        </tr>
      </Fragment>
    ));
  };

  return (
    <table className="bookings-table w-full">
      <thead>
        <tr className="border-b border-rule">
          <th className="text-left py-3 pl-6">Appointment</th>
          <th className="text-right py-3 pr-6">Booking Charges</th>
        </tr>
      </thead>
      <tbody>
        {renderData()}
        <tr className="subtotal-row font-semibold border-t border-t-1 border-rule">
          <td className="subtotal-label text-left py-3 pl-6">Subtotal</td>
          <td className="subtotal-number text-right py-3 pr-6">
            {stringifyMoney(chargesAmount, { includeCommas: true })}
          </td>
        </tr>
        <tr className="credit-row">
          <td className="credits-label text-left py-3 pl-6">Credits</td>
          <td className="credits-number text-right py-3 pr-6">{`(${stringifyMoney(totalCredits, {
            includeCommas: true,
          })})`}</td>
        </tr>
        <tr className="total-row font-bold text-xl border-t border-t-1 border-rule">
          <td className="totalAmount-label text-left py-4 pl-6">Total Amount</td>
          <td className="totalAmount-number text-right py-4 pr-6">
            {totalAmount < 0
              ? `(${stringifyMoney(totalAmount * -1, {
                  includeCommas: true,
                })})`
              : `${stringifyMoney(totalAmount, { includeCommas: true })}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default BookingsTable;
