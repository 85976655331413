import { DateTime } from 'luxon';

const APPOINTMENT_SEARCH_MEMORY = 'flsy-appt-search';
const INVOICE_SEARCH_MEMORY = 'flsy-invoice-search';
const PAYOUT_SEARCH_MEMORY = 'flsy-payout-search';
const NEW_APP_VERSION_REMINDER_SLEEP = 'flsy-new-app-version-reminder-sleep';
const TREATMENT_PLAN_SEARCH_MEMORY = 'flsy-tp-search';
const BOOKING_SEARCH_MEMORY = 'flsy-booking-search';
const NOTIFICATION_BANNER_COUNT = 'flsy-notification-banner-count';
const REFERRAL_SEARCH_MEMORY = 'flsy-referral-search';

const Cookies = {
  appointmentSearchMemory: {
    get: (): { pathname: string; search: string } | null => {
      const valueString = localStorage[APPOINTMENT_SEARCH_MEMORY];
      try {
        return JSON.parse(valueString);
      } catch {
        return null;
      }
    },
    set: (value: { pathname: string; search: string }) => {
      localStorage[APPOINTMENT_SEARCH_MEMORY] = JSON.stringify(value);
    },
    remove: () => {
      localStorage.removeItem(APPOINTMENT_SEARCH_MEMORY);
    },
  },

  referralSearchMemory: {
    get: (): { pathname: string; search: string } | null => {
      const valueString = localStorage[REFERRAL_SEARCH_MEMORY];
      try {
        return JSON.parse(valueString);
      } catch {
        return null;
      }
    },
    set: (value: { pathname: string; search: string }) => {
      localStorage[REFERRAL_SEARCH_MEMORY] = JSON.stringify(value);
    },
    remove: () => {
      localStorage.removeItem(REFERRAL_SEARCH_MEMORY);
    },
  },

  invoiceSearchMemory: {
    get: (): { pathname: string; search: string } | null => {
      const valueString = localStorage[INVOICE_SEARCH_MEMORY];
      try {
        return JSON.parse(valueString);
      } catch {
        return null;
      }
    },
    set: (value: { pathname: string; search: string }) => {
      localStorage[INVOICE_SEARCH_MEMORY] = JSON.stringify(value);
    },
    remove: () => {
      localStorage.removeItem(INVOICE_SEARCH_MEMORY);
    },
  },

  payoutSearchMemory: {
    get: (): { pathname: string; search: string } | null => {
      const valueString = localStorage[PAYOUT_SEARCH_MEMORY];
      try {
        return JSON.parse(valueString);
      } catch {
        return null;
      }
    },
    set: (value: { pathname: string; search: string }) => {
      localStorage[PAYOUT_SEARCH_MEMORY] = JSON.stringify(value);
    },
    remove: () => {
      localStorage.removeItem(PAYOUT_SEARCH_MEMORY);
    },
  },

  bookingSearchMemory: {
    get: (): { pathname: string; search: string } | null => {
      const valueString = localStorage[BOOKING_SEARCH_MEMORY];
      try {
        return JSON.parse(valueString);
      } catch {
        return null;
      }
    },
    set: (value: { pathname: string; search: string }) => {
      localStorage[BOOKING_SEARCH_MEMORY] = JSON.stringify(value);
    },
    remove: () => {
      localStorage.removeItem(BOOKING_SEARCH_MEMORY);
    },
  },

  newAppVersionReminderSleep: {
    get: (): DateTime | null => {
      const dateTimeString = localStorage[NEW_APP_VERSION_REMINDER_SLEEP];
      return DateTime.fromISO(dateTimeString) || null;
    },
    set: (value: DateTime) => {
      localStorage[NEW_APP_VERSION_REMINDER_SLEEP] = value.toISO();
    },
    remove: () => {
      localStorage.removeItem(NEW_APP_VERSION_REMINDER_SLEEP);
    },
  },

  treatmentPlanSearchMemory: {
    get: (): { pathname: string; search: string } | null => {
      const valueString = localStorage[TREATMENT_PLAN_SEARCH_MEMORY];
      try {
        return JSON.parse(valueString);
      } catch {
        return null;
      }
    },
    set: (value: { pathname: string; search: string }) => {
      localStorage[TREATMENT_PLAN_SEARCH_MEMORY] = JSON.stringify(value);
    },
    remove: () => {
      localStorage.removeItem(TREATMENT_PLAN_SEARCH_MEMORY);
    },
  },

  notificationBannerCount: {
    get: (): number => {
      return parseInt(localStorage.getItem(NOTIFICATION_BANNER_COUNT) ?? '0');
    },
    set: (value: number) => {
      localStorage.setItem(NOTIFICATION_BANNER_COUNT, value.toString());
    },
    remove: () => {
      localStorage.removeItem(NOTIFICATION_BANNER_COUNT);
    },
  },
};

export default Cookies;
