import { useCallback, useEffect, useState } from 'react';

import { ClickTrackingAction, PayoutsWorkflow, runFTrack } from '../../amplitude';
import { AuthProvider } from '../../Authentication/Authentication';
import { useSelectedDentist } from '../../Dentists/SelectedDentistContext';
import DentistSearchSelect from '../../HomePage/components/DentistSearchSelect';
import { useManagedDentists } from '../../HomePage/hooks/useManagedDentists';
import { Dentist } from '../../ServiceContext/user';
import Tabs from '../../shared/Tabs/Tabs';
import { getTimeZoneOfDentist } from '../../shared/timezone/timeZone';
import PastPayouts from './PastPayouts';
import UpcomingPayout from './UpcomingPayout';
import { usePayoutQuery } from './usePayoutQuery';

interface Props {
  authProvider: AuthProvider;
  practiceId: string;
}

type TabKey = 'upcoming' | 'completed';

function Payouts({ authProvider, practiceId }: Props) {
  const { updateQuery } = usePayoutQuery();

  const { selectedDentistId } = useSelectedDentist();

  const managedDentists = useManagedDentists(authProvider);
  const selectedDentist = managedDentists.find((dentist) => dentist.id === selectedDentistId);

  const [activeTabKey, setActiveTabKey] = useState<TabKey>(
    localStorage.getItem('payoutsTab') === 'completed' ? 'completed' : 'upcoming'
  );

  useEffect(() => {
    if (!selectedDentistId && managedDentists.length > 0) {
      const dentist = managedDentists[0];
      onNewDentistSelected(dentist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabChanged = (newKey: string) => {
    runFTrack({
      event: 'Select Payout Tab',
      workflow: PayoutsWorkflow,
      action: ClickTrackingAction,
      context: 'payouts',
      componentId: 'payoutsTabs',
      extraProps: {
        tab: newKey,
      },
    });
    setActiveTabKey(newKey as TabKey);
    localStorage.setItem('payoutsTab', newKey);
  };

  const onNewDentistSelected = useCallback(
    (selected: Dentist | null) => {
      if (selected) {
        updateQuery({ page: '1', dentistId: selected.id });
      } else {
        updateQuery({ page: '1', dentistId: null });
      }
    },
    [updateQuery]
  );

  let appliedTimeZone = 'America/Los_Angeles';
  if (selectedDentistId && selectedDentist) {
    appliedTimeZone = getTimeZoneOfDentist(selectedDentist);
  } else if (authProvider.authUser && authProvider.authUser.user.practices[0].timeZone) {
    appliedTimeZone = authProvider.authUser?.user.practices[0].timeZone;
  }

  const renderTabs = () => {
    return (
      <Tabs activeTabKey={activeTabKey} onTabChanged={onTabChanged}>
        <Tabs.Tab tabKey="upcoming" title="Upcoming Payout">
          <UpcomingPayout
            authProvider={authProvider}
            practiceId={practiceId}
            timeZone={appliedTimeZone}
          />
        </Tabs.Tab>
        <Tabs.Tab tabKey="completed" title="Past Payouts">
          <PastPayouts
            authProvider={authProvider}
            practiceId={practiceId}
            timeZone={appliedTimeZone}
          />
        </Tabs.Tab>
      </Tabs>
    );
  };

  return (
    <div
      id="payouts-page"
      className="payouts-page flex flex-col justify-start items-start text-general-gray w-full"
    >
      <div id={'header'} className={'flex flex-row justify-between w-full'}>
        <span className="title text-2xl font-semibold pb-2">Payouts</span>
        <DentistSearchSelect
          dentists={managedDentists}
          onChange={onNewDentistSelected}
          showSearchField={true}
        />
      </div>
      <div className={'w-full mr-12'}>{renderTabs()}</div>
    </div>
  );
}

export default Payouts;
