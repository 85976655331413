import { CardNumberElement, useElements } from '@stripe/react-stripe-js';
import { useContext, useState } from 'react';
import Modal from 'react-modal';
import { useQueryClient } from 'react-query';

import { PatientWorkflow } from '../../../amplitude';
import { useAddPaymentMethod } from '../../../Appointments/AppointmentDetail/hooks/useAddPaymentMethod';
import AddCreditCardModalContent from '../../../Appointments/Invoicing/PaymentEntry/AddCreditCardModalContent';
import { AuthProvider } from '../../../Authentication/Authentication';
import { PaymentMethodIcon } from '../../../HomePage/components/Icons';
import {
  cardExpirationFromPaymentMethod,
  cardLastFourFromPaymentMethod,
  cardNameFromPaymentMethod,
  PaymentMethod,
} from '../../../ServiceContext/invoices';
import { Patient, PatientDetail } from '../../../ServiceContext/patients';
import Button from '../../../shared/Button/Button';
import { PatientContext } from '../PatientDetailPage';

export default function CreditCards({ authProvider }: { authProvider: AuthProvider }) {
  const patient = useContext(PatientContext) as PatientDetail;
  const [isAddNewCardModalOpen, setIsAddNewCardModalOpen] = useState(false);
  const elements = useElements();
  const queryClient = useQueryClient();

  const { createPaymentMethod, cardError, setCardError, loading } = useAddPaymentMethod({
    firstName: patient.firstName,
    lastName: patient.lastName,
    patientId: patient.id,
    onPatientPaymentMethodsUpdated: (updatedPatient: Patient) => {
      queryClient.setQueryData(['patient', patient.id], updatedPatient);
    },
    onSuccess: (newPaymentMethod: PaymentMethod) => {
      setIsAddNewCardModalOpen(false);
    },
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    createPaymentMethod.mutate({
      cardNumberElement: elements!.getElement(CardNumberElement)!,
    });
  };

  return (
    <div className="w-1/2">
      {patient.paymentMethods && patient.paymentMethods.length > 0 && (
        <div className="flex w-full">
          <div className="w-full">
            <div className="flex flex-col gap-2">
              <div className="text-base-content text-lg font-bold mb-2">Credit Cards</div>
              {(patient.paymentMethods ?? []).map((paymentMethod) => {
                return (
                  <div
                    key={paymentMethod.id}
                    className="h-10 bg-highlight rounded-md w-full flex items-center justify-between px-4"
                  >
                    <div className="flex items-center">
                      <PaymentMethodIcon />
                      <div className="ml-3">
                        <span className="text-base-content text-sm font-bold">
                          {cardNameFromPaymentMethod(paymentMethod)}
                        </span>
                        <span className="text-base-content text-sm"> ending in </span>
                        <span className="text-base-content text-sm font-bold">
                          {cardLastFourFromPaymentMethod(paymentMethod)}
                        </span>
                      </div>
                    </div>
                    <div className="text-base-content text-sm ml-6">
                      {patient.firstName} {patient.lastName}
                    </div>
                    <div className="text-base-content text-sm ml-6">
                      Exp: {cardExpirationFromPaymentMethod(paymentMethod)}
                    </div>
                  </div>
                );
              })}
            </div>
            <Button
              onClick={() => setIsAddNewCardModalOpen(true)}
              className="flex flex-row gap-1.5 items-center hover:bg-opacity-75 mt-4"
              workflow={PatientWorkflow}
              context="creditCards"
              trackingLabel="Add New Card Button"
            >
              Add New Card
            </Button>{' '}
          </div>
        </div>
      )}
      {(!patient.paymentMethods || patient.paymentMethods.length === 0) && (
        <div className="flex w-full">
          <div className="text-center flex w-full justify-start items-start gap-1 flex-col">
            <div className="text-base-content text-lg font-bold">Credit Cards</div>
            <div className="flex gap-1 w-full items-start">
              <span className="text-base-content text-sm font-normal leading-normal">
                Patient has no credit card information on file.
              </span>
              <span
                className="text-base-content text-sm font-normal underline leading-normal cursor-pointer"
                onClick={() => {
                  setIsAddNewCardModalOpen(true);
                }}
              >
                Add a payment method.
              </span>
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={isAddNewCardModalOpen}
        className="fixed inset-0 flex items-center justify-center bg-secondary/40"
      >
        <AddCreditCardModalContent
          loading={loading}
          setCardError={setCardError}
          cardError={cardError}
          handleSubmit={handleSubmit}
          closeModal={() => setIsAddNewCardModalOpen(false)}
        />
      </Modal>
    </div>
  );
}
