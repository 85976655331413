import React, { useState } from 'react';
import { useWizard, WizardValues } from 'react-use-wizard';

import { InvoicingWorkflow, Tracking } from '../../amplitude';
import { AuthProvider } from '../../Authentication/Authentication';
import { Appointment } from '../../ServiceContext/appointments';
import {
  cardLastFourFromPayment,
  Invoice,
  isLegacyPayment,
  isPaymentMethod,
  isSplitPayment,
  LegacyPayment,
  SplitPayment,
} from '../../ServiceContext/invoices';
import { Patient } from '../../ServiceContext/patients';
import { useServices } from '../../ServiceContext/ServiceContext';
import { Dentist } from '../../ServiceContext/user';
import Alert, { AlertData } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import AppointmentSummary from '../AppointmentSummary';
import AddNewPaymentCardModal from './AddNewPaymentCardModal';
import ChangePaymentCardModal from './ChangePaymentCardModal';
import EnterPaymentAmount from './EnterPaymentAmount';
import PricedInvoiceTable from './PricedInvoiceTable';
import { useInvoicePrintout } from './PrintComponents/usePrint';
import SavingsSummary from './SavingsSummary';
import { extractInvoicingComponents } from './utilities';

export type Props = {
  authProvider: AuthProvider;
  appointment: Appointment;
  patient: Patient;
  dentist: Dentist;
  invoice: Invoice | null;
  processorName: string;
  onAppointmentUpdated: (a: Appointment) => void;
  onPaymentUpdated: (p: SplitPayment | LegacyPayment) => void;
  refetchAppointment: () => void;

  goToCreateInvoice: (w: WizardValues) => void;
  goToPaymentComplete: (w: WizardValues) => void;
  onInvoiceUpdated: (invoice: Invoice) => void;
};

const ReviewInvoiceStep: React.FC<Props> = ({
  authProvider,
  appointment,
  patient,
  dentist,
  invoice,
  onAppointmentUpdated,
  onPaymentUpdated,
  goToCreateInvoice,
  goToPaymentComplete,
  processorName,
  refetchAppointment,
  onInvoiceUpdated,
}) => {
  const tracking: Tracking = {
    workflow: InvoicingWorkflow,
    context: 'reviewInvoice',
    properties: {
      appointmentId: appointment.id,
      patientId: patient.id,
      dentistId: dentist.id,
      invoicingType: 'standard',
    },
  };

  const w = useWizard();
  const services = useServices();

  const [isProcessingTransaction] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRefreshingInvoice, setIsRefreshingInvoice] = useState(false);
  const [isShowingAddCreditCardModal, setIsShowingAddCreditCardModal] = useState(false);
  const [isShowingChangeCreditCardModal, setIsShowingChangeCreditCardModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [usingInsurance, setUsingInsurance] = useState(false);

  const patientName = `${patient.firstName} ${patient.lastName}`;
  const dentistName = `${dentist.firstName} ${dentist.lastName}`;

  let paymentLastFour: string | undefined = undefined;

  // TODO: We'll need to change this to account for however the invoice printout changes in a split payment world.
  if (appointment.payment) {
    if (isLegacyPayment(appointment.payment)) {
      paymentLastFour = cardLastFourFromPayment(appointment.payment);
    } else if (isSplitPayment(appointment.payment)) {
      // TODO: This is arbitrarily picking one of the cards from the splits. We'll need to change this once the printout changes
      //       to accommodate multiple payment splits.
      const cardSplit = appointment.payment.paymentSplits.find(
        (s) => s.paymentType === 'credit-card'
      );
      if (cardSplit) {
        paymentLastFour = cardLastFourFromPayment(cardSplit);
      }
    }
  }

  const { printButton, printComponent } = useInvoicePrintout({
    appointmentTimeInUTC: appointment.start,
    appointmentTimeZone: appointment.timeZone,
    dentistLocation: appointment.location,
    invoice,
    dentistName,
    patientName,
    paymentLastFour: paymentLastFour || '',
    patientOnly: true,
    isInsurance: Boolean(appointment.patientInsuranceId),
  });

  if (!invoice) {
    return null;
  }

  const renderTopControlButtons = () => {
    return (
      <div className="control-buttons flex flex-row gap-2 items-start align-items-start mr-6">
        <Button
          id="review-invoice-edit-button"
          onClick={() => goToCreateInvoice(w)}
          className={'bg-primary text-white hover:opacity-75'}
          disabled={isProcessingTransaction || isRefreshingInvoice}
          trackingLabel="Edit Invoice Button"
          {...tracking}
        >
          Edit Invoice
        </Button>
        {printButton}
      </div>
    );
  };

  const renderAlert = () => {
    if (alert) {
      return <Alert {...alert} />;
    }

    return null;
  };

  const { savingsAmount } = extractInvoicingComponents({
    invoice,
  });

  return (
    <>
      <div id="review-invoice-step-content" className="h-full flex flex-col gap-3 text-secondary">
        <span id={'title'} className={'font-semibold text-2xl text-left'}>
          Review Invoice
        </span>
        <div id={'content'} className="bg-white rounded-md flex flex-col gap-2 items-start pb-4">
          <div className={'flex flex-row justify-between w-full items-center'}>
            <AppointmentSummary appointment={appointment} patientName={patientName} />
            {renderTopControlButtons()}
          </div>
          <PricedInvoiceTable invoice={invoice} isInsurance={usingInsurance} />
          {savingsAmount >= invoice.total / 10 && !usingInsurance && (
            <SavingsSummary savingsAmount={savingsAmount} />
          )}
        </div>
        <EnterPaymentAmount
          authProvider={authProvider}
          appointment={appointment}
          onAppointmentUpdated={onAppointmentUpdated}
          onPaymentCompleted={(p) => {
            onPaymentUpdated(p);
            goToPaymentComplete(w);
          }}
          invoice={invoice}
          processorName={processorName}
          refetchAppointment={refetchAppointment}
          setUsingInsurance={setUsingInsurance}
        />

        {renderAlert()}
      </div>
      {printComponent}
      {isShowingAddCreditCardModal && (
        <AddNewPaymentCardModal
          appointmentId={appointment.id}
          patientId={patient.id}
          patientName={patientName}
          onCancel={() => setIsShowingAddCreditCardModal(false)}
          onNewPaymentCardAddedToAppointment={(a: Appointment) => {
            onAppointmentUpdated({
              ...a,
              dentist: appointment.dentist,
            });
            setIsShowingAddCreditCardModal(false);
          }}
          appointmentsService={services.appointmentsService}
          patientsService={services.patientsService}
          {...tracking}
        />
      )}
      {isShowingChangeCreditCardModal && (
        <ChangePaymentCardModal
          appointmentId={appointment.id}
          paymentMethods={appointment.user ? appointment.user.paymentMethods || [] : []}
          selectedPaymentMethod={
            isPaymentMethod(appointment.paymentMethod) ? appointment.paymentMethod : null
          }
          onCancel={() => setIsShowingChangeCreditCardModal(false)}
          onPaymentCardChangedOnAppointment={(a: Appointment) => {
            onAppointmentUpdated({
              ...a,
              dentist: appointment.dentist,
            });
            setIsShowingAddCreditCardModal(false);
          }}
          appointmentsService={services.appointmentsService}
          {...tracking}
        />
      )}
    </>
  );
};

export default ReviewInvoiceStep;
