// import './GatherBrowserNotificationPermission.scss';

import React, { useCallback, useState } from 'react';

import {
  NotificationsWorkflow,
  runFTrack,
  SideEffectTrackingAction,
  Tracking,
} from '../../amplitude';
import Bulletin from '../Bulletin';
import Button from '../Button/Button';
import Cookies from './../cookies/cookies';
import { getNotificationPermission, requestPermission } from './browserNotification';

type Setup = 'already-agreed' | 'already-denied' | 'undecided' | 'agreed-now' | 'denied-now';

const GatherBrowserNotificationPermission: React.FC = () => {
  const tracking: Tracking = {
    workflow: NotificationsWorkflow,
    context: 'gatherBrowserNotificationPermission',
  };

  const getSetupStatus = (): Setup => {
    let count = Cookies.notificationBannerCount.get();

    if (count >= 3) {
      return 'already-denied';
    }

    const permission = getNotificationPermission();
    if (permission === 'granted') {
      return 'already-agreed';
    } else if (permission === 'denied') {
      return 'already-denied';
    }

    return 'undecided';
  };

  const [setupStatus, setSetupStatus] = useState<Setup>(getSetupStatus());

  const onAllowClicked = useCallback(async () => {
    const isGranted = await requestPermission();
    if (isGranted) {
      runFTrack({
        event: 'Allow Notifications',
        workflow: tracking.workflow ?? NotificationsWorkflow,
        action: SideEffectTrackingAction,
        context: tracking.context,
        componentId: 'agreedToBrowserPermissionRequest',
      });
      // save to local storage to prevent showing the banner again
      Cookies.notificationBannerCount.set(3);
      setSetupStatus('agreed-now');
    } else {
      runFTrack({
        event: 'Deny Notifications',
        workflow: tracking.workflow ?? NotificationsWorkflow,
        action: SideEffectTrackingAction,
        context: tracking.context,
        componentId: 'deniedBrowserPermissionRequest',
      });
      setSetupStatus('denied-now');
    }
  }, [tracking.context, tracking.workflow]);

  const onNotNowClicked = useCallback(() => {
    let count = Cookies.notificationBannerCount.get() ?? 0;
    count++;

    if (count >= 3) {
      Cookies.notificationBannerCount.set(count);
      setSetupStatus('already-denied');
    } else {
      setSetupStatus('denied-now');
      Cookies.notificationBannerCount.set(count);
    }
  }, []);

  if (setupStatus === 'denied-now' || setupStatus === 'already-agreed') {
    return null;
  }

  if (setupStatus === 'agreed-now') {
    return (
      <Bulletin type="success" className="confirm-notifications-bulletin mt-8">
        <div className="header-wrapper flex flex-col gap-1 pt-3">
          <span className="text-base font-bold">Thank you!</span>
          <div className="call-to-action">
            <p className="text-sm">You will be notified instantly of booked appointments.</p>
          </div>
        </div>
      </Bulletin>
    );
  }

  if (setupStatus !== 'already-denied') {
    return (
      <Bulletin
        type="success"
        className="no-print confirm-notifications-bulletin flex flex-row justify-between rounded-lg mt-8"
      >
        <div className="header-wrapper flex flex-col gap-1 pt-3">
          <span className="text-base font-bold">Get Appointments Confirmed Faster</span>
          <div className="call-to-action">
            <p className="text-sm font-normal">
              Allow Flossy to send notifications to your desktop in order to be notified instantly
              of booked appointments.
            </p>
          </div>
        </div>
        <div className="call-to-action-buttons flex flex-row gap-2 self-center pr-6">
          <Button
            id="allow-notifications-button"
            onClick={onAllowClicked}
            trackingLabel="Allow Notifications Button"
            {...tracking}
          >
            Allow Notifications
          </Button>
          <Button
            id="not-now-button"
            onClick={onNotNowClicked}
            trackingLabel="Not Now Button"
            noFill
            className="bg-base-100"
            {...tracking}
          >
            Not Now
          </Button>
        </div>
      </Bulletin>
    );
  } else {
    return null;
  }
};

export default GatherBrowserNotificationPermission;
