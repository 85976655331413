import React, { useCallback, useState } from 'react';
import Iframe from 'react-iframe';
import { useNavigate } from 'react-router-dom';

import { AuthenticationWorkflow, GeneralWorkflow } from '../amplitude';
import { isErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import makeService, { TermsOfServiceConsentStatus } from '../ServiceContext/practices';
import Alert, { AlertData, errorAlert, warningAlert } from '../shared/Alert';
import Button from '../shared/Button/Button';
import Modal from '../shared/Modal/Modal';

type Props = {
  termsOfServiceStatus: TermsOfServiceConsentStatus;
  onAgreedToTermsOfService: () => void;
  authentication: AuthProvider;
};

const TermsOfServiceModal: React.FC<Props> = ({
  termsOfServiceStatus,
  onAgreedToTermsOfService,
  authentication,
}) => {
  const navigate = useNavigate();

  const [isLoadingIframeContent, setIsLoadingIframeContent] = useState(true);
  const [isRegisteringAgreement, setIsRegisteringAgreement] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [alert, setAlert] = useState<AlertData | null>(null);

  const onAgree = useCallback(async () => {
    const practicesService = makeService(authentication);
    setIsRegisteringAgreement(true);
    const res = await practicesService.agreeToTermsOfService({
      practiceId: termsOfServiceStatus.practiceId,
      termsOfServiceId: termsOfServiceStatus.termsOfServiceId,
    });
    setIsRegisteringAgreement(false);
    if (isErrorResponse(res)) {
      setAlert(errorAlert(res.errorResponse));
      return;
    }
    onAgreedToTermsOfService();
  }, [
    authentication,
    onAgreedToTermsOfService,
    termsOfServiceStatus.practiceId,
    termsOfServiceStatus.termsOfServiceId,
  ]);

  const onDisagree = useCallback(() => {
    setAlert(
      warningAlert(
        'If you have further questions or concerns please contact Flossy at (323) 763-8890 or network@flossy.com.  Please note that you will be unable to use the dental portal until you Agree to the updated Terms of Service'
      )
    );
  }, []);

  if (termsOfServiceStatus.hasAgreedToLatestTermsOfService) {
    return null;
  }

  let content: React.ReactNode;

  if (alert) {
    content = (
      <div className="disagreement-content flex flex-col items-center gap-3">
        <Alert {...alert} />
        <Button
          id="disagreed-to-tos-logout-button"
          onClick={async () => {
            setIsLoggingOut(true);
            await authentication.logOut();
            navigate('/login');
          }}
          loading={isLoggingOut}
          noFill
          workflow={AuthenticationWorkflow}
          trackingLabel="Log Out Button"
        >
          Log Out
        </Button>
      </div>
    );
  } else {
    content = (
      <div className="terms-of-service-agreement-page flex flex-col h-full">
        <p className="italic text-base">
          Please review our updated Terms of Service in order to proceed. If you have any questions
          or concerns, please contact your account manager directly!
        </p>
        <div className="buttons flex flex-row gap-3 mb-4">
          <Button
            id="agree-button"
            onClick={onAgree}
            loading={isRegisteringAgreement}
            disabled={isLoadingIframeContent || isRegisteringAgreement}
            omitBorder
            workflow={GeneralWorkflow}
            trackingLabel="Agree To TOS Button"
          >
            Agree
          </Button>
          <Button
            id="disagree-button"
            className="hover:underline"
            onClick={onDisagree}
            disabled={isLoadingIframeContent || isRegisteringAgreement}
            workflow={GeneralWorkflow}
            trackingLabel="Agree To TOS Button"
          >
            Disagree
          </Button>
        </div>
        <Iframe
          url={termsOfServiceStatus.termsOfServiceUrl}
          id=""
          className="terms-of-service-content-iframe grow rounded-lg"
          display="block"
          position="relative"
          styles={{ width: '100%', height: '100%' }}
          onLoad={() => {
            setIsLoadingIframeContent(false);
          }}
        />
      </div>
    );
  }

  return (
    <Modal isOpen={true} onRequestClose={() => {}} shape="big-square">
      {content}
    </Modal>
  );
};

export default TermsOfServiceModal;
