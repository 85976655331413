export function PortalHeader({
  children,
  trailing,
}: {
  children: string;
  trailing?: React.ReactNode;
}) {
  return (
    <div className="flex w-full justify-between">
      <div className="text-base-content text-2xl font-medium">{children}</div>
      {trailing && <div>{trailing}</div>}
    </div>
  );
}

export function PortalSubHeader({ children }: { children: string }) {
  return <div className="text-base-content text-lg font-bold ">{children}</div>;
}
