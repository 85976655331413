import * as Sentry from '@sentry/react';
import { useQuery } from 'react-query';

import { isErrorResponse } from '../../../API/response';
import { PatientDetail } from '../../../ServiceContext/patients';
import { useServices } from '../../../ServiceContext/ServiceContext';

export function usePatient(patientId: string | null): {
  isLoading: boolean;
  isError: boolean;
  data: PatientDetail | null | undefined;
  error: Error | null | unknown;
} {
  const services = useServices();

  const fetchPatient = async (id: string) => {
    const res = await services.patientsService.getPatientById(id);
    if (isErrorResponse(res)) {
      throw new Error(res.errorResponse.toString());
    }
    return res;
  };

  const { isLoading, isError, data, error } = useQuery<PatientDetail | null>(
    ['patient', patientId],
    () => {
      if (!patientId) {
        return null;
      }
      return fetchPatient(patientId);
    }
  );

  if (isError) {
    Sentry.captureException(error);
  }

  return { isLoading, isError, data, error };
}
