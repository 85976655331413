import React, { useCallback, useRef } from 'react';

import { SearchIcon } from '../../HomePage/components/Icons';
import ClearIcon from '../ClearIcon';

type PatientFilterProps = {
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (newValue: string | null) => void;
  patientNameInputValue: string;
  allowClear?: boolean;
  customStyling?: string;
  placeholder?: string;
  isSearchBox?: boolean;
  onClear?: () => void;
};

const PatientFilterInput: React.FC<PatientFilterProps> = ({
  onBlur,
  onKeyDown,
  onChange,
  patientNameInputValue,
  allowClear,
  customStyling,
  placeholder,
  isSearchBox,
  onClear,
}) => {
  const clearButtonRef = useRef<SVGSVGElement>(null);

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  const onPatientInputClearButtonClick = useCallback(() => {
    onChange(null);
    if (onClear) {
      onClear();
    }
  }, [onChange, onClear]);

  let leftPadding = '';
  if (isSearchBox) {
    leftPadding += 'pl-0';
  } else {
    leftPadding += 'pl-3';
  }

  return (
    <div
      className={`patient-filter-container flex flex-row relative items-center ${customStyling}`}
    >
      {isSearchBox && (
        <div className="px-3">
          <SearchIcon />
        </div>
      )}
      <input
        className={`patient-filter h-9 text-base-content w-full rounded-lg focus:outline-0 ${leftPadding} pr-12`}
        id="patient-filter"
        placeholder={placeholder}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        value={patientNameInputValue}
        onChange={onInputChange}
        autoComplete="off"
        aria-label="patient-filter"
      />
      {allowClear && (
        <ClearIcon
          ref={clearButtonRef}
          noDisplay={!patientNameInputValue}
          onClick={onPatientInputClearButtonClick}
        />
      )}
    </div>
  );
};

export default PatientFilterInput;
