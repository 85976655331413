import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';

export type SelectOption = {
  id: string;
  label: string;
};

interface DropdownButtonProps {
  label: string;
  options: SelectOption[];
  onSelect: (option: SelectOption) => void;
  showDentistsIcon?: boolean;
  classNameExt?: string;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  label,
  options,
  onSelect,
  classNameExt,
  showDentistsIcon,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div className="inline-flex items-center">
      <div className="relative text-left w-full" ref={dropdownRef}>
        <div>
          <button
            type="button"
            className={`${classNameExt} inline-flex justify-between items-center text-primary font-medium px-4 py-2 text-sm border border-rule rounded-md`}
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {showDentistsIcon ? (
              <div className="flex flex-row gap-2 items-center justify-start">
                <FontAwesomeIcon icon={faUsers} style={{ color: 'white' }} />
                <span className="line-clamp-1 grow text-start">{label}</span>
              </div>
            ) : (
              <span>{label}</span>
            )}

            <svg
              className="h-3 w-3 text-base-content ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
        </div>

        {showDropdown && (
          <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md bg-white ring-1 ring-primary z-40">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map((option, index) => (
                <button
                  key={index}
                  className="block w-full text-left px-4 py-2 text-sm text-base-content hover:bg-base-content-navigation hover:bg-opacity-20"
                  onClick={() => {
                    onSelect(option);
                    setShowDropdown(false);
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownButton;
