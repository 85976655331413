export type PhoneNumberMachineOutput = {
  value: string;
  displayString: string;
};

export class PhoneNumberMachine {
  private value: string = '';

  append(input: string): PhoneNumberMachineOutput {
    // Remove non-digit characters and truncate input to ensure that length of value is at most 10
    const sanitizedInput = input.replace(/\D/g, '').substring(0, 10 - this.value.length);

    this.value += sanitizedInput;

    let displayString = this.formatDisplayString(this.value);

    return { value: this.value, displayString };
  }

  clear(): PhoneNumberMachineOutput {
    this.value = '';
    let displayString = this.formatDisplayString(this.value);
    return { value: this.value, displayString };
  }

  set(input: string): PhoneNumberMachineOutput {
    this.clear();

    const digits = input.replace(/\D/g, '');
    return this.append(digits);
  }

  private formatDisplayString(value: string): string {
    // Placeholder for unfilled characters
    const placeholder = '_';

    // Pad value with placeholders to reach length 10
    const paddedValue = value.padEnd(10, placeholder);

    // Insert formatting characters
    return `(${paddedValue.substring(0, 3)}) ${paddedValue.substring(3, 6)}-${paddedValue.substring(
      6
    )}`;
  }
}
