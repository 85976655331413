import React from 'react';

import { InsuranceWorkflow } from '../../amplitude';
import Button from '../../shared/Button/Button';
import ActionButton from './ActionButton';

type Props = {
  onContinue: () => void;
  onCancel: () => void;
};

const InsuranceCreationAcknowledgement: React.FC<Props> = ({ onContinue, onCancel }) => {
  return (
    <div className="pl-4 pr-4 pb-20 bg-white rounded-md relative">
      <div className="flex flex-col items-start justify-center gap-8">
        <span className="text-left">
          This appointment has no insurance information associated with it yet. You can add it using
          this process.
        </span>
        <span>
          NOTE: Filling out insurance information on the following form automatically attempts to
          validate it. If it passes validation, it will be added to the appointment.{' '}
          <strong>Once this is done, the appointment can only be processed with insurance.</strong>
        </span>
      </div>
      <div
        aria-label="add patient insurance acknowledgement buttons"
        className="flex flex-col items-end space-y-4 absolute bottom-4 right-4"
      >
        <div className="flex space-x-4">
          <Button
            onClick={onCancel}
            noFill
            workflow={InsuranceWorkflow}
            trackingLabel="Cancel Add Insurance Button"
            context="insuranceCreationAcknowledgement"
          >
            Cancel
          </Button>
          <ActionButton onClick={onContinue} className="flex items-center">
            Continue
          </ActionButton>
        </div>
      </div>
    </div>
  );
};

export default InsuranceCreationAcknowledgement;
