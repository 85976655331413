import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { ErrorResponse } from '../../../API/response';
import { useServices } from '../../../ServiceContext/ServiceContext';

interface UseAcknowledgeAppointmentMutationOptions {
  onSuccess?: (data: any, appointmentId?: String) => void;
  onError?: (error: ErrorResponse) => void;
}

export const useAcknowledgeAppointmentMutation = ({
  onSuccess,
  onError,
}: UseAcknowledgeAppointmentMutationOptions = {}) => {
  const [error, setError] = useState<ErrorResponse | null>(null);
  const services = useServices();
  const queryClient = useQueryClient();
  const [appointmentId, setAppointmentId] = useState<string | null>(null);

  const mutation = useMutation(
    (appointmentId: string) => {
      setAppointmentId(appointmentId);
      return services.appointmentsService.markAppointmentAcknowledged({ appointmentId });
    },
    {
      onSuccess: (data, appointmentId) => {
        queryClient.setQueryData(`appointment_${appointmentId}`, data);
        if (onSuccess) {
          onSuccess(data, appointmentId);
        }
      },
      onError: (error: ErrorResponse) => {
        console.error(error);
        setError(error);
        if (onError) {
          onError(error);
        }
      },
      onSettled: () => {
        setError(null);
      },
    }
  );

  return {
    acknowledgeAppointment: mutation.mutate,
    isLoading: mutation.isLoading,
    status: mutation.status,
    error,
    appointmentId: appointmentId,
  };
};
