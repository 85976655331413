import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { BookingWorkflow } from '../../amplitude';
import { createAppointment } from '../../API/appointments';
import { AuthProvider } from '../../Authentication/Authentication';
import { Appointment } from '../../ServiceContext/appointments';
import { Dentist } from '../../ServiceContext/user';
import Alert, { AlertData, errorAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import { toApiDateTimeFormat, toShortDateTimeString } from '../../shared/dates/dates';
import Modal from '../../shared/Modal/Modal';
import { navigateToAppointments } from '../../shared/routing/routing';
import { SearchSelectOption } from '../../shared/SearchSelect/SearchSelect';
import { usernameFromDentist } from '../../shared/strings';
import { getOfficeTimeZoneForAppointmentBooking } from '../../shared/timezone/timeZone';
import { getColorFromStatus, getStatusFromAppointment } from '../AppointmentUtils';
import AppointmentAndPatientDetails from '../RescheduleAppointment/AppointmentAndPatientDetails';
import DentistAndAppointmentSelectors from '../RescheduleAppointment/DentistAndAppointmentSelectors';

type Props = {
  authProvider: AuthProvider;
  originalAppointment: Appointment;
  dentists: Dentist[];
};
const VerticalSeparator = () => <div className="w-px bg-base-300 mx-4 -translate-y-8"></div>;

const ScheduleNextAppointment: React.FC<Props> = ({
  originalAppointment,
  dentists,
  authProvider,
}) => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState<AlertData | null>(null);
  const defaultSelectedDentist = {
    id: originalAppointment.dentist.id,
    label: usernameFromDentist(originalAppointment.dentist),
    renderContent: (
      <div className="dentist-search-option py-2 px-2 text-left">
        {originalAppointment.dentist.firstName} {originalAppointment.dentist.lastName}{' '}
        <span className="dentist-search-option-username font-semibold">
          ({originalAppointment.dentist.username})
        </span>
      </div>
    ),
  };
  const [selectedDentistId, setSelectedDentistId] = useState<string | null>(
    defaultSelectedDentist.id
  );
  const [selectedLocalDateTime, setSelectedLocalDateTime] = useState<Date | null>(null);
  const [isCreatingAppointment, setIsCreatingAppointment] = useState(false);
  const [createdAppointmentId, setCreatedAppointmentId] = useState<string | null>(null);
  const [isSchedulingSuccessModalOpen, setIsSchedulingSuccessModalOpen] = useState(false);

  const dateTimeInOfficeTz = getOfficeTimeZoneForAppointmentBooking({
    selectedLocalDateTime,
    selectedDentist: null,
    selectedDentistId,
    practiceDentists: dentists,
    appointment: originalAppointment,
  });

  const buttonStyle =
    'bg-primary text-white font-semibold shadow-none cursor-pointer hover:bg-primary hover:bg-opacity-[75%]';

  const mutation = useMutation(createAppointment, {
    onMutate: () => {
      setIsCreatingAppointment(true);
    },
    onSuccess: (data) => {
      setIsCreatingAppointment(false);
      setCreatedAppointmentId(data.id);
      setIsSchedulingSuccessModalOpen(true);
    },
    onError: (error) => {
      setIsCreatingAppointment(false);
      setAlert(errorAlert(error as any));
    },
  });

  const dateTimeInUTC = dateTimeInOfficeTz.toUTC();
  const onBookAppointmentClick = (e: React.FormEvent) => {
    if (!selectedDentistId) {
      return;
    }
    e.preventDefault();
    const appointmentData = {
      authProvider,
      dentistId: selectedDentistId,
      patientId: originalAppointment.userId,
      startTimeInUTC: toApiDateTimeFormat(dateTimeInUTC),
      patientInsuranceId: originalAppointment.patientInsuranceId || undefined,
    };
    mutation.mutate(appointmentData);
  };

  const onNewDentistSelected = useCallback((o: SearchSelectOption) => {
    setSelectedDentistId(o.id);
  }, []);

  const onDateChange = (dateValue: Date | null) => {
    if (typeof dateValue === 'object') {
      setSelectedLocalDateTime(dateValue as Date);
    }
  };

  const onTimeChange = (timeValue: Date | null) => {
    if (typeof timeValue === 'object') {
      setSelectedLocalDateTime(timeValue as Date);
    }
  };

  const renderPatientProfileButton = () => {
    const isDisabled = !originalAppointment.user;
    return (
      <div id={'patient-profile-button'} className={'ml-6 mb-8'}>
        <Button
          className="border border-primary rounded-md text-primary font-bold bg-white shadow-none hover:opacity-75"
          onClick={() => {
            if (originalAppointment?.user?.id) {
              navigate(`/patients/${originalAppointment.user.id}`);
            }
          }}
          workflow={BookingWorkflow}
          trackingLabel="View Patient Profile Button"
          context="scheduleNextAppointment"
          disabled={isDisabled}
        >
          View Patient Profile <span>&rarr;</span>
        </Button>
      </div>
    );
  };

  const returnToAppointments = useCallback(() => {
    if (!originalAppointment) {
      return null;
    }
    navigateToAppointments({ navigate });
  }, [navigate, originalAppointment]);

  if (!originalAppointment.user) {
    const errorId = uuidv4();
    setAlert(
      errorAlert(
        `An error has occurred. Please notify a Flossy customer service rep with the following identifier: ${errorId}`
      )
    );
    return null;
  }

  const renderControlButtons = () => {
    return (
      <div id={'control-buttons'} className="flex flex-row gap-2 w-full mb-8">
        <>
          <Button
            id="save-appointment-button"
            onClick={onBookAppointmentClick}
            className="bg-primary text-white font-semibold hover:opacity-75 disabled:opacity-50"
            loading={isCreatingAppointment}
            disabled={!selectedLocalDateTime || !selectedDentistId}
            workflow={BookingWorkflow}
            trackingLabel="Book Appointment Button"
            context="scheduleNextAppointment"
          >
            Book Appointment
          </Button>
          <Button
            id="cancel-button"
            onClick={returnToAppointments}
            className="border border-rule text-primary bg-white shadow-none hover:opacity-75"
            aria-label="Cancel"
            workflow={BookingWorkflow}
            trackingLabel="Cancel Button"
            context="scheduleNextAppointment"
          >
            Cancel
          </Button>
        </>
      </div>
    );
  };

  return (
    <div id={'schedule-next-appointment-page'} className={'flex flex-col text-secondary gap-3'}>
      <span id={'title'} className="font-semibold text-2xl text-left">
        Schedule Next Appointment
      </span>
      <div id={'body'} className={'flex flex-col gap-2 bg-white rounded-md'}>
        {alert && <Alert {...alert} />}
        <div id={'patient-name-and-status'} className={'flex items-center space-x-3 ml-6 my-6'}>
          <span
            className={`${getColorFromStatus(
              originalAppointment
            )} rounded-2xl font-semibold text-white text-xs px-2 py-1`}
          >
            {getStatusFromAppointment(originalAppointment)}
          </span>
          <span className="text-xl font-bold">
            {originalAppointment.user.firstName} {originalAppointment.user.lastName}
          </span>
        </div>
        <div id={'details-and-selectors-wrapper'} className={'flex flex-row gap-4'}>
          <div className={'flex flex-col gap-3 w-full justify-between'}>
            <AppointmentAndPatientDetails
              appointment={originalAppointment}
              hideAppointmentsDetails={true}
            />
            {renderPatientProfileButton()}
          </div>
          <VerticalSeparator />
          <div className={'flex flex-col gap-3 w-full justify-between'}>
            <DentistAndAppointmentSelectors
              dentists={dentists}
              onNewDentistSelected={onNewDentistSelected}
              selectedLocalDateTime={selectedLocalDateTime}
              onDateChange={onDateChange}
              onTimeChange={onTimeChange}
              defaultSelectedDentist={defaultSelectedDentist}
            />
            {renderControlButtons()}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isSchedulingSuccessModalOpen}
        onRequestClose={() => setIsSchedulingSuccessModalOpen(false)}
        shape={'square'}
      >
        <div className={'flex flex-col gap-8 px-4 pt-4'}>
          <span
            className={'rounded-md font-semibold text-xs bg-info p-2'}
          >{`You have successfully booked an appointment for ${
            originalAppointment.user.firstName
          } ${originalAppointment.user.lastName} on ${toShortDateTimeString(
            dateTimeInOfficeTz
          )}.`}</span>
          <div id={'buttons'} className={'flex flex-row gap-2 justify-center'}>
            <Button
              omitBorder
              className={`flex flex-row gap-1.5 items-center ${buttonStyle}`}
              onClick={() => navigate(`/appointments/${createdAppointmentId}/details`)}
              workflow={BookingWorkflow}
              trackingLabel="Go to Appointment Button"
            >
              <FontAwesomeIcon
                icon={faCalendarCheck}
                style={{
                  color: 'white',
                }}
              />
              Go to Appointment
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScheduleNextAppointment;
