import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ShareInvoiceWorkflow } from '../../../amplitude';
import { createPaymentLinks } from '../../../API/bookingsAndCredits';
import { AuthProvider } from '../../../Authentication/Authentication';
import Alert, { AlertData, errorAlert } from '../../../shared/Alert';
import Button from '../../../shared/Button/Button';
import { stringifyMoney } from '../../../shared/money/stringifyMoney';
import { generateFullURL } from '../../../shared/routing/routing';
import { emailRegex } from '../../../shared/strings/stringValidation';
import ValidatedInput from '../../../shared/ValidatedInput/ValidatedInput';
import { parseBookingPeriodKey } from '../BookingPeriod';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  selectedPeriod: string;
  onCancelClick: () => void;
  balanceDue: number;
};
const EmailInvoiceModalContent: React.FC<Props> = ({
  authProvider,
  practiceId,
  selectedPeriod,
  onCancelClick,
  balanceDue,
}) => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendInvoiceSuccessful, setIsSendInvoiceSuccessful] = useState(false);
  const [paymentLinkId, setPaymentLinkId] = useState('');

  const navigate = useNavigate();
  const sendInvoiceMutation = useMutation(createPaymentLinks, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (res) => {
      const newLink = res[0];
      setPaymentLinkId(newLink.id);
      setIsSendInvoiceSuccessful(true);
    },
    onError: (error) => {
      setIsLoading(false);
      setAlert(errorAlert(error as any));
    },
  });

  const onEmailChange = (value: string, isValid: boolean) => {
    setEmail(value);
    setIsEmailValid(isValid);
  };

  const [year, month] = selectedPeriod.split('-');

  const onSendInvoiceButtonClick = () => {
    sendInvoiceMutation.mutate({
      authProvider,
      practiceId,
      emailAddresses: [email],
      paymentDetails: {
        type: 'single-month',
        year: parseInt(year),
        month: parseInt(month),
      },
    });
  };

  const copyPaymentLinkToClipboard = () => {
    const input = document.getElementById('paymentLinkInput') as HTMLInputElement;
    if (input) {
      input.select();
      input.setSelectionRange(0, 99999);
      navigator.clipboard
        .writeText(input.value)
        .then(() => {
          console.log('Text copied to clipboard');
        })
        .catch((err) => {
          console.error('Error in copying text: ', err);
        });
    }
    return;
  };

  return (
    <>
      {!isSendInvoiceSuccessful && (
        <div className="send-invoice-content flex flex-col gap-4 text-secondary mx-4">
          <span className="title text-2xl font-bold">Send Invoice to Accountant</span>
          {alert && <Alert {...alert} />}
          <span className="text-xs">
            Enter your accountant's email address below. They will be given a link to pay the
            balance due.
          </span>
          <div className="balance-due flex flex-col gap-1.5 justify-center items-center rounded-md border border-rule bg-base-200 font-bold w-full py-4">
            <span className={'text-lg'}>{`${
              parseBookingPeriodKey(selectedPeriod).displayForm
            } Balance`}</span>
            <span className="text-3xl">{stringifyMoney(balanceDue, { includeCommas: true })}</span>
          </div>
          <div className="email flex flex-col gap-2 justify-start">
            <span className="font-bold text-sm">Accountant Email</span>
            <ValidatedInput
              className="border border-rule w-full rounded-md py-1.5 pl-2 placeholder:text-sm"
              placeholder="Email Address"
              validations={[
                {
                  type: 'regex',
                  regex: emailRegex,
                  errorMessage: 'Please enter a valid email',
                },
              ]}
              onChange={onEmailChange}
              allowClear
            />
          </div>
          <div className="flex flex-row gap-3 justify-center mb-4">
            <Button
              className="bg-primary text-white hover:bg-opacity-75"
              onClick={onSendInvoiceButtonClick}
              disabled={!email || !isEmailValid || isLoading}
              workflow={ShareInvoiceWorkflow}
              trackingLabel="Send Invoice Button"
            >
              Send Invoice
            </Button>
            <Button
              className="border border-rule text-primary bg-white shadow-none hover:opacity-75"
              onClick={onCancelClick}
              workflow={ShareInvoiceWorkflow}
              trackingLabel="Cancel Send Invoice Button"
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
      {isSendInvoiceSuccessful && (
        <div className="successful flex flex-col gap-4 text-secondary mx-4">
          <span className="title text-xl font-bold">Invoice Sent Successfully</span>
          <span className="text-xs">
            Your invoice was emailed to
            <span className="font-bold"> {`${email}.`}</span>
            They will be given the following link to pay the balance due.
          </span>
          <div className="flex flex-row relative">
            <input
              title="Payment Link"
              type="text"
              value={generateFullURL(
                `/bookings-and-credits/pay-invoice?identifier=${paymentLinkId}`
              )}
              readOnly
              id="paymentLinkInput"
              className="mr-2 p-2 text-[11px] border rounded-md w-full focus:outline-blue-100"
            />
            <button
              onClick={copyPaymentLinkToClipboard}
              className="rounded-lg bg-primary text-white text-[9px] font-semibold py-0.5 px-1 absolute bottom-2 right-4 hover:opacity-75"
            >
              Copy
            </button>
          </div>
          <Button
            className="border border-rule bg-white text-primary text-xs mx-4 self-center justify-center w-1/2 shadow-none hover:opacity-75"
            onClick={() => navigate('/accounting/bookings-and-credits')}
            workflow={ShareInvoiceWorkflow}
            trackingLabel="Return to Bookings and Credits Button"
          >
            Return to Bookings and Credits
          </Button>
        </div>
      )}
    </>
  );
};

export default EmailInvoiceModalContent;
