import React from 'react';

type CloseIconProps = {
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
  strokeWidth?: number;
  color?: string;
};

const sizeMap = {
  xs: 'h-3 w-3',
  s: 'h-4 w-4',
  m: 'h-5 w-5',
  l: 'h-6 w-6',
  xl: 'h-8 w-8',
};

const CloseIcon: React.FC<CloseIconProps> = ({
  size = 'm',
  strokeWidth = 2,
  color = 'text-black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className={`${sizeMap[size]} ${color}`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

export default CloseIcon;
