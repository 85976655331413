import React from 'react';

import logo from '../shared/assets/app-logo.svg';
import styledFlossy from '../shared/assets/app-styled-name.svg';

const ProductIdentifiers: React.FC = () => {
  return (
    <div id="identifiers" className="identifiers pr-32">
      <a className="logo-wrapper flex flex-row" href="/">
        <img src={logo} id="app-logo" alt="flossy-logo" />
        <img src={styledFlossy} id="app-styled-name" alt="flossy-name" className="pt-2 pl-4" />
      </a>
    </div>
  );
};

export default ProductIdentifiers;
