import { Elements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useQuery } from 'react-query';

import { createSetupIntent, getPractice } from '../API/practice';
import { ErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { Practice } from '../ServiceContext/user';
import AddPaymentMethodButton from './components/AddPaymentMethodButton';
import DefaultPaymentMethod from './components/DefaultPaymentMethod';
import NewPaymentMethodModal from './components/NewPaymentMethodModal';
import NoDefaultPaymentMethod from './components/NoDefaultPaymentMethod';
import PaymentMethodTile from './components/PaymentMethodTile';
import PracticeDropDown from './components/PracticeDropDown';

type Props = {
  authProvider: AuthProvider;
};

const SettingsPage: React.FC<Props> = ({ authProvider }) => {
  const [isNewCardModalOpen, setIsNewCardModalOpen] = React.useState(false);
  const stripe = useStripe();
  const [clientSecret, setClientSecret] = useState<string>();
  const [selectedPractice, setSelectedPractice] = useState<Practice>();

  const practices = authProvider.authUser?.user.practices;

  useEffect(() => {
    if (practices && practices.length > 0) {
      setSelectedPractice(practices[0]);
    }
  }, [setSelectedPractice, practices]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    createSetupIntent({
      authProvider,
      practiceId: authProvider.authUser?.user.practices[0].id!,
    }).then((data) => {
      setClientSecret(data.clientSecret);
    });
  }, [authProvider]);

  const { data: practice } = useQuery<Practice, ErrorResponse>(
    ['practice', selectedPractice?.id],
    () =>
      getPractice({
        authProvider,
        practiceId: selectedPractice!.id,
      }),
    {
      enabled: !!selectedPractice?.id,
    }
  );

  if (!practice || !clientSecret) {
    return <div>Loading...</div>;
  }

  const additionalPaymentMethods = practice.paymentMethods.filter(
    (method) => method.id !== practice.defaultPaymentMethod?.id
  );

  const defaultPaymentMethod = practice.defaultPaymentMethod;

  return (
    <Elements
      stripe={stripe}
      options={{
        mode: 'setup',
        currency: 'usd',
        paymentMethodCreation: 'manual',
      }}
    >
      <div className="p-8 min-h-screen flex flex-col">
        {practices && practices.length > 1 && (
          <div className="w-full justify-end flex">
            <PracticeDropDown
              authProvider={authProvider}
              setSelectedPractice={setSelectedPractice}
              selectedPractice={selectedPractice}
            />
          </div>
        )}
        <div className="bg-white shadow-md rounded-lg p-6">
          <div className="w-full">
            <div className="flex flex-row gap-8 w-full">
              <div className="flex flex-col items-start w-5/12 ">
                <h2 className="text-xl font-semibold mb-2 text-start">Payment Methods</h2>
                <div className="mb-6 text-start">
                  Payments methods on file for automatic and scheduled payments.
                </div>
                <div className="text-base font-semibold mb-1 text-start ">
                  Default Payment Method
                </div>
                <div className="text-start text-[12px]">
                  The card set as the ‘Default Payment Method’ will be used for all automatic
                  payments.
                </div>
              </div>
              <div className="mb-6 flex flex-col justify-center items-stretch grow w-full">
                <div className="font-semibold text-start text-[12px]">Default Payment Method</div>
                {defaultPaymentMethod ? (
                  <DefaultPaymentMethod
                    paymentMethod={defaultPaymentMethod}
                    authProvider={authProvider}
                    practice={practice}
                  />
                ) : (
                  <NoDefaultPaymentMethod />
                )}
                {defaultPaymentMethod === null && (
                  <AddPaymentMethodButton setIsNewCardModalOpen={setIsNewCardModalOpen} />
                )}
              </div>
            </div>
            <div className="border-b my-8 border-base-content-inactive"></div>
            <div className="flex flex-row gap-8 w-full">
              <div className="flex flex-col items-start w-5/12 ">
                <div className="text-base font-semibold mb-1 text-start">
                  Additional Payment Methods
                </div>
                <div className="text-start text-[12px]">
                  Additional payment methods can be selected for scheduled payments, and will only
                  be used for automatic payments if there’s an issue with your default payment
                  method.
                </div>
              </div>
              <div className="flex flex-col items-start grow w-full">
                <h3 className="font-semibold text-start text-[12px]">Additional Payment Methods</h3>
                {additionalPaymentMethods.length > 0 ? (
                  additionalPaymentMethods.map((method) => (
                    <PaymentMethodTile
                      key={method.id} // Assuming each method has a unique 'id'
                      paymentMethod={method}
                      authProvider={authProvider}
                      practiceId={practice.id}
                    />
                  ))
                ) : (
                  <div className="mt-2 p-4 border rounded-lg flex flex-row items-center w-full">
                    <div className="text-base-content text-start text-[12px]">
                      There are no additional payment methods on file for this practice. Please add
                      a default payment method first.
                    </div>
                  </div>
                )}
                {defaultPaymentMethod !== undefined && (
                  <AddPaymentMethodButton setIsNewCardModalOpen={setIsNewCardModalOpen} />
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={isNewCardModalOpen}
          className="fixed inset-0 flex items-center justify-center bg-secondary/40"
        >
          <NewPaymentMethodModal
            onClose={() => setIsNewCardModalOpen(false)}
            clientSecret={clientSecret}
            authProvider={authProvider}
            practice={practice}
          />
        </Modal>
      </div>
    </Elements>
  );
};

export default SettingsPage;
