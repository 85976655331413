import { AuthProvider } from '../Authentication/Authentication';
import {
  authenticatedGet,
  authenticatedNullableGet,
  authenticatedPost,
} from '../ServiceContext/service';
import { apiUrl } from '../ServiceContext/shared';
import { ErrorResponse, handleAPIResponse } from './response';

export enum InsurancePlanType {
  HMO = 'hmo',
  PPO = 'ppo',
  EPO = 'epo',
}

export type InsuranceCarrier = {
  id: string;
  name: string;
};

export type PatientInsurance = {
  id: string;
  patientId: string;
  insuranceCarrierId: string;
  insuranceCarrier: InsuranceCarrier;
  planType: InsurancePlanType | null;
  memberFirstName: string;
  memberLastName: string;
  dateOfBirth: string;
  memberId: string | null;
  groupId: string | null;
  cardFrontImageUrl: string | null;
  cardBackImageUrl: string | null;
  cardFrontUploadedAt: string | null;
  cardBackUploadedAt: string | null;
  insuranceCarrierPhone: string | null;
};

export async function getPatientInsuranceForAppointment({
  authProvider,
  appointmentId,
}: {
  authProvider: AuthProvider;
  appointmentId: string;
}): Promise<PatientInsurance | null> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  if (!appointmentId) {
    return null;
  }
  return handleAPIResponse(
    authenticatedNullableGet<PatientInsurance | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/appointments/${appointmentId}/patient-insurance`)
    )
  );
}

export async function createPatientInsuranceForAppointment({
  authProvider,
  appointmentId,
  insuranceCarrierId,
  patientFirstName,
  patientLastName,
  memberId,
  dateOfBirth,
}: {
  authProvider: AuthProvider;
  appointmentId: string;
  insuranceCarrierId: string;
  patientFirstName: string;
  patientLastName: string;
  memberId: string;
  dateOfBirth: string;
}): Promise<PatientInsurance> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedPost<PatientInsurance | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/appointments/${appointmentId}/patient-insurance`),
      {
        insuranceCarrierId,
        memberFirstName: patientFirstName,
        memberLastName: patientLastName,
        memberId,
        dateOfBirth,
      }
    )
  );
}

export async function getInsuranceCarriers({
  authProvider,
}: {
  authProvider: AuthProvider;
}): Promise<InsuranceCarrier[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedGet<InsuranceCarrier[] | ErrorResponse>(
      authProvider,
      apiUrl(`/dentists/insurance-carriers`)
    )
  );
}
