import React, { useCallback, useEffect, useState } from 'react';

import { ClickTrackingAction, GeneralWorkflow, runFTrack } from '../amplitude';
import { isErrorResponse } from '../API/response';
import { AuthProvider } from '../Authentication/Authentication';
import { Message } from '../ServiceContext/messages';
import { useServices } from '../ServiceContext/ServiceContext';
import { isManager } from '../ServiceContext/user';
import { pollingIntervalSeconds } from '../shared/realTime/polling';
import WithBadge from '../shared/WithBadge';
import WithOverlay from '../shared/WithOverlay';
import BellIcon from './components/BellIcon';
import MessageList from './MessageList';

type Props = {
  authentication: AuthProvider;
};

const MessageCenter: React.FC<Props> = ({ authentication }) => {
  const services = useServices();

  const [showingMessages, setShowingMessages] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  useEffect(() => {
    setUnreadMessagesCount(messages.filter((message) => !message.readAt).length);
  }, [messages]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (isManager(authentication.authUser?.user)) {
        const res = await services.messagesService.getMessages({
          managerId: authentication.authUser!.user.id,
        });
        if (!isErrorResponse(res)) {
          setMessages(res);
        }
      }
    };

    // Fetch messages immediately so we have something to show
    fetchMessages().then();

    // Then fetch messages every 15 seconds so that users get notification updates without having to refresh
    const intervalId = setInterval(() => {
      fetchMessages().then();
    }, pollingIntervalSeconds * 1000);

    // This cleans up the interval when the component is unmounted so that we do not continue to make calls
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication.authUser]);

  const onMessageRead = useCallback(
    async (readMessage: Message) => {
      setMessages(
        messages.map((m) => {
          if (m.id === readMessage.id) {
            return {
              ...m,
              readAt: new Date().toISOString(),
            };
          }
          return m;
        })
      );
      setUnreadMessagesCount((count) => count - 1);
      const res = await services.messagesService.markMessageAsRead({
        notificationId: readMessage.id,
      });

      if (isErrorResponse(res)) {
        setMessages(
          messages.map((m) => {
            if (m.id === readMessage.id) {
              return {
                ...m,
                readAt: null,
              };
            }
            return m;
          })
        );
        setUnreadMessagesCount((count) => count + 1);
      }
    },
    [messages, services.messagesService]
  );

  const onNotificationBellClicked = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      runFTrack({
        event: 'Click Notifications Icon',
        workflow: GeneralWorkflow,
        action: ClickTrackingAction,
        context: 'messageCenter',
        componentId: 'bellIcon',
        extraProps: {
          unread: unreadMessagesCount,
        },
      });
      setShowingMessages(!showingMessages);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showingMessages]
  );

  const onOverlayMaskClick = useCallback(() => {
    setShowingMessages(false);
  }, []);

  const onClearAllButtonClick = async () => {
    if (authentication && authentication.authUser?.user.id) {
      const res = await services.messagesService.markAllMessagesAsCleared({
        id: authentication.authUser?.user.id,
      });

      if (isErrorResponse(res)) {
        return;
      }
      setMessages([]);
    }
  };

  return (
    <WithOverlay
      showing={showingMessages}
      xOffset={-150}
      yOffset={60}
      onMaskClick={onOverlayMaskClick}
      overlayComponent={
        <MessageList
          messages={messages}
          onMarkedRead={onMessageRead}
          onClearAll={onClearAllButtonClick}
          displayClearAll={messages.length !== 0}
        />
      }
      componentContainerCustom="min-w-[240px]"
    >
      <WithBadge
        className="message-center-bell"
        badgeContent={unreadMessagesCount === 0 ? '' : unreadMessagesCount.toString()}
      >
        <div onClick={onNotificationBellClicked} className="pt-2 cursor-pointer">
          <BellIcon />
        </div>
      </WithBadge>
    </WithOverlay>
  );
};

export default MessageCenter;
