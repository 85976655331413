import { useContext } from 'react';

import { PatientInsurance } from '../../../API/insurance';
import { formatMemberId, formatPhoneNumber } from '../../../shared/strings';
import { AppointmentContext } from '../contexts/appointmentContext';

export default function InsuranceInformationSection({
  insuranceDetails,
}: {
  insuranceDetails: PatientInsurance | null | undefined;
}) {
  const appointmentContext = useContext(AppointmentContext);
  const appointment = appointmentContext?.appointment;
  if (!appointment || !appointment.user) {
    return null; // Todo show loading animation
  }

  if (!insuranceDetails) {
    return (
      <div className="items-start flex flex-col text-sm font-light w-1/3 text-secondary">
        <div className="text-lg font-semibold pb-4">Insurance Information</div>
        <div className="text-start">Patient has no insurance information on file.</div>
      </div>
    );
  }

  return (
    <div className="items-start flex flex-col text-sm font-light w-1/3 text-secondary">
      <div className="text-lg font-semibold pb-4">Insurance Information</div>
      <div className="space-y-2 flex flex-col items-start">
        <div>
          <span className="font-semibold">Insurer:</span>{' '}
          <span>
            {insuranceDetails && insuranceDetails.insuranceCarrier
              ? insuranceDetails.insuranceCarrier.name
              : ''}
          </span>
        </div>
        <div>
          <span className="font-semibold">Plan Type:</span>{' '}
          <span>
            {insuranceDetails && insuranceDetails.planType
              ? insuranceDetails.planType.toUpperCase()
              : ''}
          </span>
        </div>
        <div>
          <span className="font-semibold">Name:</span>{' '}
          <span>
            {typeof insuranceDetails !== 'undefined' &&
            insuranceDetails &&
            insuranceDetails.memberFirstName &&
            insuranceDetails.memberLastName
              ? `${insuranceDetails.memberFirstName} ${insuranceDetails.memberLastName}`
              : ''}
          </span>
        </div>
        <div>
          <span className="font-semibold">Date of Birth:</span>{' '}
          <span>
            {insuranceDetails && insuranceDetails.dateOfBirth
              ? new Date(insuranceDetails.dateOfBirth).toLocaleDateString('en-US')
              : ''}
          </span>
        </div>
        <div>
          <span className="font-semibold">Member ID:</span>{' '}
          {insuranceDetails && insuranceDetails.memberId && (
            <span>{formatMemberId(insuranceDetails.memberId, false)}</span>
          )}
        </div>
        <div className="text-start">
          <span className="font-semibold">Insurance Carrier Phone Number:</span>{' '}
          <span>
            {insuranceDetails && insuranceDetails.insuranceCarrierPhone
              ? formatPhoneNumber(insuranceDetails.insuranceCarrierPhone)
              : ''}
          </span>
        </div>
        {/*<button className="rounded-md border text-sm  border-rule text-primary font-medium py-2  px-3 mt-4">*/}
        {/*  Insurance Breakdown <span>&rarr;</span>*/}
        {/*</button>*/}
      </div>
    </div>
  );
}
