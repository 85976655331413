import { extractInvoicingComponents } from '../../Appointments/Invoicing/utilities';
import { Invoice, InvoiceItem } from '../../ServiceContext/invoices';
import { stringifyMoney } from '../../shared/money/stringifyMoney';

type Props = {
  invoice: Invoice;
  procedureInvoiceItems: InvoiceItem[];
  patientHasInsurance: boolean;
};
const OldPricingTotalsRow: React.FC<Props> = ({
  invoice,
  procedureInvoiceItems,
  patientHasInsurance,
}) => {
  const { flossyFee } = extractInvoicingComponents({ invoice });
  const negotiatedPriceTotal = procedureInvoiceItems
    .map((item: InvoiceItem) => item.amount)
    .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);

  if (patientHasInsurance) {
    return (
      <tr id={'totals-row'} className="text-sm font-semibold text-right py-3 pr-6 border-top">
        <td id={'label'} className="text-left py-3 pl-6 font-semibold">
          Totals
        </td>
        <td id={'empty'} className="text-left py-3 pl-6"></td>
        <td id={'flossy-price-total'} className="text-right py-3 pr-6">
          ---
        </td>
        <td id={'flossy-fee-total'} className="text-right py-3 pr-6">
          ---
        </td>
        <td id={'negotiated-price-total'} className="text-right py-3 pr-6">
          ---
        </td>
      </tr>
    );
  }

  return (
    <tr id={'totals-row'} className="text-sm font-semibold text-right py-3 pr-6 border-top">
      <td id={'label'} className="text-left py-3 pl-6 font-semibold">
        Totals
      </td>
      <td id={'empty'} className="text-left py-3 pl-6"></td>
      <td id={'flossy-price-total'} className="text-right py-3 pr-6">
        {stringifyMoney(invoice.total, { includeCommas: true })}
      </td>
      <td id={'flossy-fee-total'} className="text-right py-3 pr-6">
        {stringifyMoney(flossyFee, { includeCommas: true })}
      </td>
      <td id={'negotiated-price-total'} className="text-right py-3 pr-6">
        {stringifyMoney(negotiatedPriceTotal, { includeCommas: true })}
      </td>
    </tr>
  );
};

export default OldPricingTotalsRow;
