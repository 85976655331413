import React from 'react';

import { DetailedTreatmentPlan } from '../../../API/treatmentPlans';

type Props = {
  treatmentPlan: DetailedTreatmentPlan;
};
const TermsConditionsSignature: React.FC<Props> = ({ treatmentPlan }) => {
  return (
    <div id={'T&C-and-signature'}>
      <div className="text-base-content text-[12px] font-bold mb-2">
        Please review the terms & sign below:
      </div>
      <div className="w-full text-base-content text-[10px] font-normal text-start">
        I understand this is an estimate for proposed treatment services as diagnosed by Dr.
        (placeholder). I understand that treatment may change, and price changes may occur based on
        necessity at the time of service. By signing below I understand these charges are my
        financial responsibility and are to be paid in full on the date of service unless otherwise
        arranged in advance.
      </div>
      <div className="w-full flex justify-end">
        <div className="flex flex-col items-start">
          <div className="border-b border-rule w-72 mt-12 mb-2"></div>
          <div className="text-[12px]">
            {treatmentPlan.patient.firstName} {treatmentPlan.patient.lastName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditionsSignature;
