import { doc, onSnapshot } from 'firebase/firestore';
import { DateTime } from 'luxon';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { firebaseFirestore } from '../firebase';
import { useReminderCookie } from './cookies/useReminderCookie';
import { Environment } from './environment';
import RequireRefreshModal from './RequireRefreshModal';

const AppVersionWatcher: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [latestVersion, setLatestVersion] = useState(null);
  const [newAppVersionAvailable, setNewAppVersionAvailable] = useState(false);

  const currentVersion = process.env.REACT_APP_RELEASE_TAG;
  console.info('Using dentist portal version', currentVersion);

  const { setReminderCookie, isTimeToRemind } = useReminderCookie();

  const configRequiresAppUpdates = process.env.REACT_APP_REQUIRE_APP_UPDATES === 'true';

  useEffect(() => {
    if (configRequiresAppUpdates) {
      const unsubscribe = onSnapshot(
        doc(firebaseFirestore, 'dentist_portal_version', Environment.appVersionDocumentName()),
        (doc) => {
          const data = doc.data();
          if (data) {
            setLatestVersion(data.version);
          }
        }
      );

      return () => unsubscribe();
    }
  }, [configRequiresAppUpdates]);

  useEffect(() => {
    if (latestVersion && currentVersion && currentVersion !== latestVersion) {
      setNewAppVersionAvailable(true);
    } else if (newAppVersionAvailable) {
      setNewAppVersionAvailable(false);
    }
  }, [latestVersion, currentVersion, newAppVersionAvailable]);

  const onRemindMeLaterClicked = useCallback(() => {
    const timestampOneHourLater = DateTime.now().plus({ hours: 1 });
    setReminderCookie(timestampOneHourLater);
  }, [setReminderCookie]);

  return (
    <>
      {configRequiresAppUpdates && newAppVersionAvailable && isTimeToRemind && (
        <RequireRefreshModal onRemindMeLaterClicked={onRemindMeLaterClicked} />
      )}
      {children}
    </>
  );
};

export default AppVersionWatcher;
