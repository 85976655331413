import { reportErrorToSentry } from '../../../ServiceContext/error';
import { PaymentSplitMetadata, SplitPayment } from '../../../ServiceContext/invoices';
import { CardSplitPaymentMethod, NonCardSplitPaymentMethod } from './PaymentEntry';

export type checkPreviousEventsForPaymentMethod_PaymentMethod =
  | Pick<NonCardSplitPaymentMethod, 'methodType' | 'id'>
  | Pick<CardSplitPaymentMethod, 'methodType' | 'id' | 'stripePaymentMethod'>;
export type checkPreviousEventsForPaymentMethod_Payment = Pick<
  SplitPayment,
  'status' | 'paymentSplits'
> | null;

export function checkPreviousEventsForPaymentMethod({
  paymentMethod,
  payment,
  appointmentId,
}: {
  paymentMethod: checkPreviousEventsForPaymentMethod_PaymentMethod;
  payment: checkPreviousEventsForPaymentMethod_Payment;
  appointmentId: string;
}): {
  paymentMethodFailureMessage: string | null;
  didPaymentSucceed: boolean;
} {
  let paymentMethodFailureMessage: string | null = null;
  let didPaymentSucceed: boolean = false;
  if (payment && (payment.status === 'denied' || payment.status === 'partially_processed')) {
    let matchingPreviousAttempt: PaymentSplitMetadata | null = null;
    for (let i = 0; i < payment.paymentSplits.length; i++) {
      const ps = payment.paymentSplits[i];
      // There can be many failures, but only one success -- a success always overtakes a failure.
      if (!matchingPreviousAttempt || matchingPreviousAttempt.status !== 'processed') {
        if (paymentMethod.methodType === 'credit-card') {
          if (ps.paymentMethod?.id === paymentMethod.stripePaymentMethod?.id) {
            matchingPreviousAttempt = ps;
          }
        } else {
          if (ps.paymentMethod?.id === paymentMethod.id) {
            matchingPreviousAttempt = ps;
          }
        }
      }
    }
    if (matchingPreviousAttempt && matchingPreviousAttempt.status === 'denied') {
      if (matchingPreviousAttempt.errorMessage) {
        paymentMethodFailureMessage = `Payment method declined the last time payment was attempted: ${matchingPreviousAttempt.errorMessage}`;
      } else {
        reportErrorToSentry({
          summary: 'Payment failed without an error message',
          extra: {
            appointmentId,
            paymentMethodId: paymentMethod.id,
            errorCode: matchingPreviousAttempt.errorCode,
          },
        });
        paymentMethodFailureMessage = `Payment method declined the last time payment was attempted. Please try again or change payment method.`;
      }
    } else if (matchingPreviousAttempt && matchingPreviousAttempt.status === 'processed') {
      didPaymentSucceed = true;
    }
  }
  return { paymentMethodFailureMessage, didPaymentSucceed };
}
