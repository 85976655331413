import { faFileInvoiceDollar, faFileMedical, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PaymentAdded, PaymentMissing } from '../../HomePage/components/Icons';
import { Appointment } from '../../ServiceContext/appointments';
import DropdownButton, { SelectOption } from '../../shared/DropdownButton';
import Table, { ColumnDef } from '../../shared/Table/Table';
import LinkText from '../../shared/Text/LinkText';
import { useAcknowledgeAppointmentMutation } from '../AppointmentDetail/hooks/useAcknowledgeAppointmentMutation';
import { getColorFromStatus, getStatusFromAppointment } from '../AppointmentUtils';
import { AppointmentTableProps } from '../Tables';
import commonAppointmentColumns from '../Tables/common_columns';
import AppointmentTableColumnFilter from './AppointmentTableColumnFilter';

type Props = Omit<AppointmentTableProps, 'appointments'> & {
  loadingErrorMessage: string | null;
  appointmentData: Appointment[];
  setDateOrderByDirection: (direction: 'ASC' | 'DESC') => void;
  setUpdater: (updater: (prev: number) => number) => void;
  onInvoiceFilterChange: (selected: string | null) => void;
  initialInvoiceFilter?: string;
  onPaymentFilterChange: (selected: string | null) => void;
  initialPaymentFilter?: string;
  onTreatmentPlanFilterChange: (selected: string | null) => void;
  initialTreatmentPlanFilter?: string;
};

const AppointmentsTable: React.FC<Props> = ({
  loadingErrorMessage,
  appointmentData,
  setDateOrderByDirection,
  setUpdater,
  onInvoiceFilterChange,
  initialInvoiceFilter,
  onPaymentFilterChange,
  initialPaymentFilter,
  onTreatmentPlanFilterChange,
  initialTreatmentPlanFilter,
}) => {
  const navigate = useNavigate();

  const { acknowledgeAppointment } = useAcknowledgeAppointmentMutation({
    onSuccess: (data, appointmentId) => {
      setUpdater((prev) => prev + 1);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const generateColumnDef = () => {
    let tableColumns: ColumnDef<Appointment>[];
    let baseColumns = commonAppointmentColumns.map((c) => {
      if (c.columnKey === 'patient') {
        return {
          ...c,
          render: (record: Appointment) => {
            if (!record.user) {
              return '';
            }
            return (
              <LinkText
                onClick={() => {
                  navigate(`/appointments/${record.id}/details`);
                }}
                className="patient-name !text-base-content !text-sm !font-semibold !pl-0 hover:opacity-75 text-start"
              >
                {record.user.firstName} {record.user.lastName}
              </LinkText>
            );
          },
        };
      } else if (c.columnKey === 'date') {
        return {
          ...c,
          sortableRemote: {
            ascending: () => {
              setDateOrderByDirection('ASC');
            },
            descending: () => {
              setDateOrderByDirection('DESC');
            },
          },
        };
      }
      return c;
    });

    //remove payment column
    baseColumns.pop();

    const statusStyling = 'py-1 px-1.5 rounded-2xl text-white font-semibold text-xs';

    const statusColumn = {
      columnKey: 'status',
      title: 'Status',
      widthProportion: 0.5,
      render: (record: Appointment) => {
        return (
          <span className={statusStyling + ' ' + getColorFromStatus(record)}>
            {getStatusFromAppointment(record)}
          </span>
        );
      },
    };

    const paymentColumn = {
      columnKey: 'payment',
      title: 'Payment',
      filterOptions: {
        contentRender: () => {
          let appliedFilterValue: string | undefined = undefined;
          if (initialPaymentFilter === 'true') {
            appliedFilterValue = 'Insurance';
          } else if (initialPaymentFilter === 'false') {
            appliedFilterValue = 'Flossy';
          }
          return (
            <AppointmentTableColumnFilter
              options={['Flossy', 'Insurance']}
              initialFilter={appliedFilterValue}
              onFilter={onPaymentFilterChange}
            />
          );
        },
        filterIconRender: () => {
          return (
            <FontAwesomeIcon
              icon={faFilter}
              className={initialPaymentFilter !== undefined ? 'text-accent' : 'text-base-content'}
              style={{ marginLeft: '8px', cursor: 'pointer' }}
            />
          );
        },
      },
      widthProportion: 0.6,
      render: (record: Appointment) => {
        return (
          <div className="flex space-x-1">
            <div> {record.paymentMethod ? <PaymentAdded /> : <PaymentMissing />}</div>
            {record.patientInsuranceId ? (
              <span>Insurance</span>
            ) : (
              <span className="text-base-content">Flossy</span>
            )}
          </div>
        );
      },
    };

    const invoiceColumn: ColumnDef<Appointment> = {
      columnKey: 'invoice',
      title: 'Invoice',
      filterOptions: {
        contentRender: () => {
          let appliedFilterValue: string | undefined = undefined;
          if (initialInvoiceFilter === 'true') {
            appliedFilterValue = 'Invoice';
          } else if (initialInvoiceFilter === 'false') {
            appliedFilterValue = 'Without Invoice';
          }
          return (
            <AppointmentTableColumnFilter
              options={['Invoice', 'Without Invoice']}
              initialFilter={appliedFilterValue}
              onFilter={onInvoiceFilterChange}
            />
          );
        },
        filterIconRender: () => {
          return (
            <FontAwesomeIcon
              icon={faFilter}
              className={initialInvoiceFilter !== undefined ? 'text-accent' : 'text-base-content'}
              style={{ marginLeft: '8px', cursor: 'pointer' }}
            />
          );
        },
      },
      widthProportion: 0.4,
      render: (record: Appointment) => {
        let invoiceOpacity: string;
        if (record.invoice) {
          invoiceOpacity = '80%';
        } else {
          invoiceOpacity = '40%';
        }
        return (
          <button
            title="View Invoice"
            onClick={() => navigate(`/invoices/${record.id}`)}
            disabled={!record.invoice}
            className={`bg-none border-0 ml-4 ${
              record.invoice ? 'cursor-pointer hover:opacity-60' : ''
            }`}
          >
            <FontAwesomeIcon
              icon={faFileInvoiceDollar}
              className="text-icon"
              style={{ opacity: `${invoiceOpacity}`, scale: '170%' }}
            />
          </button>
        );
      },
    };

    const treatmentPlanColumn = {
      columnKey: 'treatmentPlan',
      title: 'Treatment Plan',
      filterOptions: {
        contentRender: () => {
          let appliedFilterValue: string | undefined = undefined;
          if (initialTreatmentPlanFilter === 'true') {
            appliedFilterValue = 'With TP';
          } else if (initialTreatmentPlanFilter === 'false') {
            appliedFilterValue = 'Without TP';
          }
          return (
            <AppointmentTableColumnFilter
              options={['With TP', 'Without TP']}
              initialFilter={appliedFilterValue}
              onFilter={onTreatmentPlanFilterChange}
            />
          );
        },
        filterIconRender: () => {
          return (
            <FontAwesomeIcon
              icon={faFilter}
              className={
                initialTreatmentPlanFilter !== undefined ? 'text-accent' : 'text-base-content'
              }
              style={{ marginLeft: '8px', cursor: 'pointer' }}
            />
          );
        },
      },
      widthProportion: 0.5,
      render: (record: Appointment) => {
        let treatmentPlanOpacity: string;
        if (record.user.hasActiveTreatmentPlan) {
          treatmentPlanOpacity = '80%';
        } else {
          treatmentPlanOpacity = '40%';
        }
        return (
          <button
            title="View Treatment Plan"
            onClick={() =>
              record.user.hasActiveTreatmentPlan && navigate(`/patients/${record.userId}`)
            }
            disabled={!record.user.hasActiveTreatmentPlan}
            className={`bg-none border-0 ml-8 ${
              record.user.hasActiveTreatmentPlan ? 'cursor-pointer hover:opacity-60' : ''
            }`}
          >
            <FontAwesomeIcon
              icon={faFileMedical}
              className="text-icon"
              style={{ opacity: `${treatmentPlanOpacity}`, scale: '170%' }}
            />
          </button>
        );
      },
    };

    const actionButtonStyling =
      'border border-rule px-4 py-2 font-medium text-primary rounded-md hover:bg-base-200';

    const actionButtonsColumn = {
      columnKey: 'actionButtons',
      title: '',
      widthProportion: 1.1,
      render: (record: Appointment) => {
        if (record.status === 'cancelled') {
          return (
            <button
              id={`reschedule-appointment-button-${record.id}`}
              className={`reschedule-appointment-button ${actionButtonStyling} min-w-[100px] max-w-[250px]`}
              onClick={() => {
                navigate(`/appointments/${record.id}/book-follow-up`);
              }}
              disabled={!record.user}
            >
              Schedule Next Appointment
            </button>
          );
        }

        const onOptionSelect = (selectedOption: SelectOption) => {
          if (selectedOption.id === 'reschedule-appointment') {
            navigate(`/appointments/${record.id}/reschedule`);
          } else if (selectedOption.id === 'cancel-appointment') {
            navigate(`/appointments/${record.id}/cancel`);
          } else if (selectedOption.id === 'schedule-next-appointment') {
            navigate(`/appointments/${record.id}/book-follow-up`);
          } else if (selectedOption.id === 'create-an-invoice') {
            navigate(`/appointments/${record.id}`);
          } else if (selectedOption.id === 'continue-invoicing') {
            navigate(`/invoices/${record.id}`);
          } else if (selectedOption.id === 'add-treatment-plan') {
            navigate(`/patients/${record.userId}/treatment-plans/new`);
          } else if (selectedOption.id === 'acknowledge') {
            acknowledgeAppointment(record.id);
          }
        };

        const isInFuture = record.start > DateTime.local().toUTC().toString();

        if (isInFuture) {
          if (record.status === 'requested' && !record.acknowledgedByDentistAt) {
            return (
              <DropdownButton
                classNameExt="min-w-[100px] max-w-[250px]"
                label="Manage Appointment"
                options={[
                  { id: 'acknowledge', label: 'Accept' },
                  { id: 'reschedule-appointment', label: 'Reschedule Appointment' },
                  { id: 'cancel-appointment', label: 'Cancel Appointment' },
                ]}
                onSelect={onOptionSelect}
              />
            );
          }

          // Scheduled with draft invoice
          if (record.status === 'requested' && record.invoice?.isDraft) {
            return (
              <DropdownButton
                classNameExt="min-w-[100px] max-w-[250px]"
                label="Manage Appointment"
                options={[
                  { id: 'continue-invoicing', label: 'Continue Invoicing' },
                  { id: 'add-treatment-plan', label: 'Add Treatment Plan' },
                  { id: 'reschedule-appointment', label: 'Reschedule Appointment' },
                  { id: 'cancel-appointment', label: 'Cancel Appointment' },
                ]}
                onSelect={onOptionSelect}
              />
            );
          }

          // Scheduled
          return (
            <DropdownButton
              classNameExt="min-w-[100px] max-w-[250px]"
              label="Manage Appointment"
              options={[
                { id: 'create-an-invoice', label: 'Create Invoice' },
                { id: 'add-treatment-plan', label: 'Add Treatment Plan' },
                { id: 'reschedule-appointment', label: 'Reschedule Appointment' },
                { id: 'cancel-appointment', label: 'Cancel Appointment' },
              ]}
              onSelect={onOptionSelect}
            />
          );
        } else {
          if (
            !record.invoice ||
            record.invoice.status === 'overdue' ||
            record.invoice.status === 'due' ||
            record.invoice.status === 'partially_paid'
          ) {
            // Unprocessed
            let createButtonText: string = 'Create an Invoice';

            if (record.status === 'completed' && record.invoice && record.user) {
              createButtonText = 'View Invoice';
            } else if (record.invoice) {
              createButtonText = 'Continue Invoicing';
            }
            return (
              <DropdownButton
                label="Manage Appointment"
                options={[
                  { id: 'create-an-invoice', label: createButtonText },
                  { id: 'add-treatment-plan', label: 'Add Treatment Plan' },
                  { id: 'schedule-next-appointment', label: 'Schedule Next Appointment' },
                  { id: 'cancel-appointment', label: 'Cancel Appointment' },
                ]}
                onSelect={onOptionSelect}
                classNameExt="bg-white min-w-[100px] max-w-[250px]"
              />
            );
          }
          return (
            //Past
            <button
              id={`book-follow-up-appointment-button-${record.id}`}
              className={`book-follow-up-appointment-button ${actionButtonStyling} min-w-[100px] max-w-[250px]`}
              onClick={() => {
                navigate(`/appointments/${record.id}/book-follow-up`);
              }}
              disabled={!record.user}
            >
              Schedule Next Appointment
            </button>
          );
        }
      },
    };

    const emptyColumn = {
      columnKey: 'space',
      title: '',
      widthProportion: 0.1,
      render: () => {
        return '';
      },
    };

    tableColumns = [
      emptyColumn,
      statusColumn,
      ...baseColumns,
      paymentColumn,
      invoiceColumn,
      treatmentPlanColumn,
      actionButtonsColumn,
    ];

    return tableColumns;
  };

  let tableData: Appointment[] = appointmentData;

  if (loadingErrorMessage) {
    return (
      <div id="loading-error-alert">
        <div className="alert alert-danger" role="alert">
          {loadingErrorMessage}
        </div>
      </div>
    );
  }

  return (
    <div
      id="appointments-table-container"
      className="table-container w-full flex flex-column justify-start items-start min-h-[700px]"
    >
      <Table
        id="appointments-table"
        columns={generateColumnDef()}
        data={tableData}
        rowKeyGenerator={(record: Appointment) => `${record.id}`}
        rowClassName="appointment-content-row"
        tableClassNameExt={'bg-white !border-0 rounded-t-none '}
      />
    </div>
  );
};

export default AppointmentsTable;
