import { DateTime } from 'luxon';

import { Dentist } from '../../ServiceContext/user';
import { digitsString } from '../strings';

type OrdinalResult = -1 | 0 | 1;

export const twelveHourClockTimeRegex: RegExp = /^(((0?[1-9])|(1[0-2])):([0-5])([0-9]))$/;
export const twelveHourAmPmClockTimeRegex: RegExp =
  /^((1[0-2]|0?[1-9]):([0-5][0-9])\s?([AaPp][Mm]))$/;
export const twelveHourAmPmNoMinutesClockTimeRegex: RegExp = /^((1[0-2]|0?[1-9])\s?([AaPp][Mm]))$/;
export const twentyFourHourClockTimeRegex: RegExp = /^([01]\d|2[0-3]):?([0-5]\d)$/;

export function compareClockTime(t1: string, t2: string): OrdinalResult {
  const t124HourTime = convertTo24HourClockTime(t1);
  const t224HourTime = convertTo24HourClockTime(t2);

  return t124HourTime.localeCompare(t224HourTime) as OrdinalResult;
}

export function timeToMinutes(time: string): number {
  // Remove 'am' or 'pm' and store whether it's pm
  const period = time.slice(-2);
  const isPM = period.toLowerCase() === 'pm';

  // Remove the period to parse hours and possibly minutes
  const timeWithoutPeriod = time.slice(0, -2).trim();

  // Split by ':' to separate hours and minutes
  const parts = timeWithoutPeriod.split(':');
  const hoursStr = parts[0];
  const minutesStr = parts.length > 1 ? parts[1] : '0'; // Default minutes to "0" if not provided

  let hours = Number(hoursStr);
  const minutes = Number(minutesStr);

  // Convert to 24-hour time if 'pm'
  if (isPM && hours < 12) hours += 12;
  if (!isPM && hours === 12) hours = 0; // Adjust 12am to 0 hours

  return hours * 60 + minutes;
}

export function isOneHourApart(start: string, end: string) {
  const startTimeInMinutes = timeToMinutes(start);
  const endTimeInMinutes = timeToMinutes(end);
  return endTimeInMinutes - startTimeInMinutes >= 60;
}

export function isLaterOrEqual(t: string, targetTime: string): boolean {
  const ord: OrdinalResult = compareClockTime(t, targetTime);
  return ord === 1 || ord === 0;
}

export function isEarlierOrEqual(t: string, targetTime: string): boolean {
  const ord: OrdinalResult = compareClockTime(t, targetTime);
  return ord === -1 || ord === 0;
}

export function convertTo24HourClockTime(s: string): string {
  let trimmedInput = s.trim().toLowerCase();

  if (twentyFourHourClockTimeRegex.test(s.toLowerCase())) {
    return s;
  } else if (twelveHourAmPmClockTimeRegex.test(s.toLowerCase())) {
    let adjustment = 0;
    let isAM = false;
    if (trimmedInput.endsWith('am')) {
      isAM = true;
      trimmedInput = trimmedInput.replace('am', '').trim();
    } else if (trimmedInput.endsWith('pm')) {
      trimmedInput = trimmedInput.replace('pm', '').trim();
      adjustment = 12;
    }

    const splits = trimmedInput.split(':');
    let hour = parseInt(splits[0]);
    const minute = parseInt(splits[1]);

    if (isAM) {
      if (hour === 12) {
        adjustment = 0;
        hour = 0;
      }
    } else {
      if (hour === 12) {
        adjustment = 0;
        hour = 12;
      }
    }

    return `${digitsString(hour + adjustment, 2)}:${digitsString(minute, 2)}`;
  } else if (twelveHourClockTimeRegex.test(s.toLowerCase())) {
    const splits = trimmedInput.split(':');
    let hour = parseInt(splits[0]);
    const minute = parseInt(splits[1]);

    if (hour === 12) {
      hour = 0;
    }

    return `${digitsString(hour, 2)}:${digitsString(minute, 2)}`;
  } else if (twelveHourAmPmNoMinutesClockTimeRegex.test(s.toLowerCase())) {
    let adjustment = 0;
    let isAM = false;
    if (trimmedInput.endsWith('am')) {
      isAM = true;
      trimmedInput = trimmedInput.replace('am', '').trim();
    } else if (trimmedInput.endsWith('pm')) {
      trimmedInput = trimmedInput.replace('pm', '').trim();
      adjustment = 12;
    }

    let hour = parseInt(trimmedInput);
    const minute = 0;

    if (isAM) {
      if (hour === 12) {
        adjustment = 0;
        hour = 0;
      }
    } else {
      if (hour === 12) {
        adjustment = 0;
        hour = 12;
      }
    }

    return `${digitsString(hour + adjustment, 2)}:${digitsString(minute, 2)}`;
  }

  return '';
}

export function buildDateString(date: string, clockTime: string): string {
  const dateComponent = DateTime.fromISO(date).toFormat('yyyy-MM-dd');
  if (dateComponent === 'Invalid DateTime') {
    return '';
  }
  const time24Hour = convertTo24HourClockTime(clockTime);
  const parsedDateTime = DateTime.fromISO(`${dateComponent}T${time24Hour}`);
  const timeComponent = parsedDateTime.toFormat('TT');
  if (timeComponent === 'Invalid DateTime') {
    return '';
  }

  return `${dateComponent}T${timeComponent}`;
}

export function validateClockTime(s: string): boolean {
  return (
    twelveHourAmPmClockTimeRegex.test(s.toLowerCase()) ||
    twelveHourAmPmNoMinutesClockTimeRegex.test(s.toLowerCase())
  );
}

const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

export function createRRuleStringForDate({
  date,
  recurrenceSetting,
}: {
  date: string;
  recurrenceSetting: 'weekly' | 'every-weekday';
}): string {
  let parsedDate = DateTime.fromFormat(date, 'yyyy-MM-dd');
  if (!parsedDate.isValid) {
    parsedDate = DateTime.fromISO(date);
    if (!parsedDate.isValid) {
      return '';
    }
  }

  if (recurrenceSetting === 'weekly') {
    const dayString = days[parsedDate.weekday % 7];
    return `RRULE:FREQ=WEEKLY;WKST=SU;BYDAY=${dayString}`;
  }

  return 'RRULE:FREQ=DAILY;WKST=SU;BYDAY=MO,TU,WE,TH,FR';
}

export function getTimezoneFromDentist(d: Dentist): string | null {
  if (d.practice && d.practice.timeZone) {
    return d.practice.timeZone;
  }

  if (d.locations && d.locations.length > 0) {
    return d.locations[0].timeZone;
  }

  return null;
}

// export function getTimezoneFromAppointment(a: Appointment): string | null {
//   if (a.timeZone) {
//     return a.timeZone;
//   }
//
//   if (a.dentist) {
//     const timeZoneFromDentist = getTimeZoneOfDentist(a.dentist);
//     if (timeZoneFromDentist) {
//       return timeZoneFromDentist;
//     }
//   }
//
//   if (a.location && a.location.timeZone) {
//     return a.location.timeZone;
//   }
//
//   return null;
// }
