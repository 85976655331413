import React from 'react';

export enum PatientTabs {
  pastAppointments,
  treatmentPlans,
  additionalFiles,
}

export const patientTabQueries: Record<string, PatientTabs> = {
  appointments: PatientTabs.pastAppointments,
  'treatment-plans': PatientTabs.treatmentPlans,
  files: PatientTabs.additionalFiles,
};

interface TabProps {
  tabName: string;
  tabQueryName: string;
  patientTab: PatientTabs;
  selectedTab: PatientTabs;
  setTabQuery: (tabName: string) => void;
  setSelectedTab: (tabName: PatientTabs) => void;
}

const PatientTab: React.FC<TabProps> = ({
  tabName,
  selectedTab,
  setSelectedTab,
  patientTab,
  setTabQuery,
  tabQueryName,
}) => {
  return (
    <div
      className="relative cursor-pointer"
      onClick={() => {
        setTabQuery(tabQueryName);
        setSelectedTab(patientTab);
      }}
    >
      <button
        className={`${
          selectedTab === patientTab ? 'text-base-content' : 'text-base-content/50'
        } text-lg font-medium`}
      >
        {tabName}
      </button>
      {selectedTab === patientTab && (
        <div className="w-full h-0.5 bg-primary rounded-sm -bottom-2 absolute" />
      )}
    </div>
  );
};

export default PatientTab;
