import React, { useRef, useState } from 'react';
import { useMutation } from 'react-query';

import { ReferralWorkflow, Tracking } from '../amplitude';
import { QueryContext } from '../API/context';
import { PatientReferral, referPatient } from '../API/referrals';
import { AuthProvider } from '../Authentication/Authentication';
import Alert, { AlertData, errorAlert } from '../shared/Alert';
import Button from '../shared/Button/Button';
import ClearIcon from '../shared/ClearIcon';
import PhoneNumberInput from '../shared/phoneNumbers/PhoneNumberInput';
import EmailInput from '../shared/ValidatedInput/EmailInput';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  onReferralCreated: (newReferral: PatientReferral) => void;
  onCancel: () => void;
  queryContext: QueryContext;
} & Tracking;

const ReferPatientForm: React.FC<Props> = ({
  authProvider,
  practiceId,
  onReferralCreated,
  onCancel,
  queryContext,
  ...tracking
}) => {
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const firstNameClearButtonRef = useRef<SVGSVGElement>(null);
  const lastNameClearButtonRef = useRef<SVGSVGElement>(null);

  const isFormFilled: boolean = Boolean(email);
  const apiFormattedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');

  const referPatientMutation = useMutation(referPatient, {
    onMutate: () => {},
    onSuccess: (newReferral) => {
      onReferralCreated(newReferral);

      const currentData = queryContext.client.getQueryData<PatientReferral[]>(queryContext.key);

      if (currentData) {
        queryContext.client.setQueryData(queryContext.key, [newReferral, ...currentData]);
      }
    },
    onError: (error) => {
      setAlert(errorAlert(error as any));
    },
  });

  const { isLoading: isSendingReferral } = referPatientMutation;

  const onSubmit = (e: React.FormEvent) => {
    if (!isFormFilled) {
      return;
    }
    setAlert(null);

    e.preventDefault();

    referPatientMutation.mutate({
      authProvider,
      practiceId,
      email,
      phoneNumber: apiFormattedPhoneNumber,
      firstName,
      lastName,
    });
  };

  const onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    setAlert(null);
  };

  const onLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    setAlert(null);
  };

  const onFirstNameClearButtonClick = () => {
    setFirstName('');
  };

  const onLastNameClearButtonClick = () => {
    setLastName('');
  };

  const onPhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
  };

  const buttonStyling =
    'bg-primary text-white items-center font-semibold text-xs hover:bg-opacity-[75%] cursor-pointer';

  return (
    <form onSubmit={onSubmit} className="pt-4 pl-4 pr-4 pb-20 bg-white rounded-md relative">
      {alert && <Alert {...alert} />}
      <div className="p-4 bg-white rounded-md">
        <div className="first-name-input-wrapper mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="first-name">
            First Name
          </label>
          <div className="first-name-input-field relative">
            <input
              title="Password Clear Button"
              id="first-name-input"
              onChange={onFirstNameChange}
              className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
              value={firstName}
              disabled={isSendingReferral}
              tabIndex={1}
            />
            <div className="first-name-clear-button absolute right-0 -translate-y-1/2 top-1/2">
              <ClearIcon
                ref={firstNameClearButtonRef}
                noDisplay={!firstName}
                onClick={onFirstNameClearButtonClick}
                color="grey"
              />
            </div>
          </div>
        </div>
        <div className="last-name-input-wrapper mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="last-name">
            Last Name
          </label>
          <div className="last-name-input-field relative">
            <input
              title="Password Clear Button"
              id="last-name-input"
              onChange={onLastNameChange}
              className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
              value={lastName}
              disabled={isSendingReferral}
              tabIndex={2}
            />
            <div className="last-name-clear-button absolute right-0 -translate-y-1/2 top-1/2">
              <ClearIcon
                ref={lastNameClearButtonRef}
                noDisplay={!lastName}
                onClick={onLastNameClearButtonClick}
                color="grey"
              />
            </div>
          </div>
        </div>
        <div className="email-input-wrapper mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <EmailInput
            className={`appearance-none border rounded w-full py-2 px-3 ${
              isSendingReferral ? 'bg-base-200 cursor-not-allowed' : ''
            }`}
            id="email"
            onChange={(s) => setEmail(s)}
            placeholder="Please enter the email of the patient you would like to refer."
            disabled={isSendingReferral}
            allowClear
          />
        </div>
        <div id={'phoneNumber-input-wrapper mb-4'}>
          <label className="block text-sm font-bold mb-2" htmlFor={'phoneNumber'}>
            Phone number
          </label>
          <PhoneNumberInput onChange={onPhoneNumberChange} disabled={isSendingReferral} />
        </div>
      </div>

      <div className="flex flex-col items-end space-y-4 absolute bottom-4 right-4">
        <div className="flex space-x-4">
          <Button
            className="border border-rule text-xs text-primary bg-white hover:opacity-75 shadow-none"
            onClick={onCancel}
            disabled={isSendingReferral}
            workflow={ReferralWorkflow}
            context="referPatientForm"
            trackingLabel="Cancel Button"
          >
            Cancel
          </Button>
          <Button
            className={`${buttonStyling}`}
            htmlType="submit"
            disabled={!isFormFilled || isSendingReferral}
            loading={isSendingReferral}
            workflow={ReferralWorkflow}
            context="referPatientForm"
            trackingLabel="Submit Referral Button"
          >
            Submit Referral
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ReferPatientForm;
