import { Field, FieldAttributes, getIn } from 'formik';
import React, { ReactNode } from 'react';

import YesNoRadioGroup from './YesNoRadioGroup/YesNoRadioGroup';

const FieldWithError: React.FC<FieldAttributes<any>> = ({ name, type, ...otherProps }) => {
  return (
    <Field name={name} {...otherProps}>
      {/*@ts-ignore - This is extraordinarily difficult to type correctly due to the meta aspect of it*/}
      {({ field, form: { errors, touched, setFieldValue, values } }) => {
        const error = getIn(errors, name);
        const touch = getIn(touched, name);

        const hasError = error && touch;

        let inputClassName = `${otherProps.className} form-input text-sm w-full min-w-[256px]`;
        if (hasError) {
          inputClassName += ' error border border-error';
        }

        let component: ReactNode;

        if (type === 'yesNoGroup') {
          component = (
            <YesNoRadioGroup
              name={name}
              value={values[name]}
              onYesOrNoSelected={(yesOrNo) => setFieldValue(name, yesOrNo)}
              {...otherProps}
              {...field}
            />
          );
        } else {
          component = (
            <input {...otherProps} {...field} className={inputClassName} autoComplete="off" />
          );
        }

        if (hasError) {
          return (
            <div className="form-field-with-error flex flex-col gap-3 justify-start items-start">
              {component}
              <span className="form-error-message text-[#bf2e3c]">{error}</span>
            </div>
          );
        }

        return (
          <div className="form-field-with-error flex flex-col gap-3 justify-start items-start">
            {component}
          </div>
        );
      }}
    </Field>
  );
};

export default FieldWithError;
