import React, { useState } from 'react';
import Modal from 'react-modal';

import { Appointment } from '../../../ServiceContext/appointments';
import { Patient } from '../../../ServiceContext/patients';
import InsuranceReviewModalContent from './InsuranceReviewModalContent';
import {
  CardSplitPaymentMethod,
  NonCardSplitPaymentMethod,
  SplitPaymentMethod,
  SplitPaymentMethodType,
} from './PaymentEntry';

type Props = {
  splitPaymentMethod: SplitPaymentMethod;
  onPaymentMethodChange: (updatedPaymentMethod: SplitPaymentMethod) => void;
  disabled?: boolean;
  totalSplits: number;
  patient: Patient;
  appointment: Appointment;
};

const PaymentMethodDropdown: React.FC<Props> = ({
  splitPaymentMethod,
  onPaymentMethodChange,
  disabled,
  totalSplits,
  patient,
  appointment,
}) => {
  const isInsuranceOnFile = Boolean(appointment.patientInsuranceId);

  const [isShowingVerifyInsuranceModal, setShowingVerifyInsuranceModal] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newMethodType = e.target.value as SplitPaymentMethodType;

    if (disabled) {
      return;
    }

    if (newMethodType === 'credit-card') {
      // Changing to a credit card payment split type, so we need to start tracking the Stripe payment
      // method information.
      onPaymentMethodChange({
        ...splitPaymentMethod,
        methodType: newMethodType,
        stripePaymentMethod: null,
      } as CardSplitPaymentMethod);
    } else {
      // Transitioning from a card payment type to a non-card payment type.
      onPaymentMethodChange({
        id: splitPaymentMethod.id,
        amount: splitPaymentMethod.amount,
        methodType: newMethodType,
      } as NonCardSplitPaymentMethod);
    }
  };

  const selectClasses = `text-xs border rounded-md p-2 focus:outline-blue-100 ${
    disabled ? 'bg-gray-100 text-gray-500 cursor-not-allowed' : 'bg-white text-black cursor-pointer'
  }`;

  return (
    <div id={'payment-method-dropdown'} className={'ml-7'}>
      <select
        title="Payment Method"
        value={splitPaymentMethod.methodType}
        disabled={disabled}
        onChange={(e) => {
          if (e.target.value === 'insurance' && !isInsuranceOnFile) {
            setShowingVerifyInsuranceModal(true);
          } else {
            handleChange(e);
          }
        }}
        className={selectClasses}
      >
        <option value="credit-card">Process with Credit Card</option>
        <option value="cash">Process with Cash</option>
        <option value="sunbit">Process with Sunbit</option>
        <option value="care-credit">Process with Care Credit</option>
        <option value="other-financing">Process with Other Financing</option>
        <option value="office-payment-plan">Process with Office Payment Plan</option>
        <option value="insurance" disabled={totalSplits > 1}>
          Process with Insurance
        </option>
      </select>
      <Modal
        isOpen={isShowingVerifyInsuranceModal}
        className="fixed inset-0 flex items-center justify-center bg-secondary/40"
      >
        <InsuranceReviewModalContent
          closeModal={() => setShowingVerifyInsuranceModal(false)}
          onVerified={() => {
            setShowingVerifyInsuranceModal(false);
            onPaymentMethodChange({
              id: splitPaymentMethod.id,
              amount: splitPaymentMethod.amount,
              methodType: 'insurance',
            } as NonCardSplitPaymentMethod);
          }}
          patient={patient}
          key={'verify-insurance'}
        />
      </Modal>
    </div>
  );
};

export default PaymentMethodDropdown;
