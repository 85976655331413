import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PatientWorkflow } from '../../../amplitude';
import { AuthProvider } from '../../../Authentication/Authentication';
import { Appointment } from '../../../ServiceContext/appointments';
import { PatientDetail } from '../../../ServiceContext/patients';
import Button from '../../../shared/Button/Button';
import PortalContainer from '../../../shared/Container/PortalContainer';
import { toLongDateString } from '../../../shared/dates/dates';
import Modal from '../../../shared/Modal/Modal';
import { PortalSubHeader } from '../../../shared/Text/PortalHeaders';
import BookAppointmentForm from '../../BookAppointmentForm';
import { PatientContext } from '../PatientDetailPage';

export default function UpcomingAppointment({
  appointment,
  authProvider,
}: {
  appointment: Appointment | null;
  authProvider: AuthProvider;
}) {
  const patient = useContext(PatientContext) as PatientDetail;
  const patientId = patient?.id;
  const navigate = useNavigate();
  const [isBookAppointmentModalOpen, setIsBookAppointmentModalOpen] = useState(false);

  return (
    <PortalContainer className="details-wrapper">
      <PortalSubHeader>Upcoming Appointment</PortalSubHeader>
      {appointment ? (
        <div className="pt-3 pb-4">
          <span
            className="text-base-content text-sm font-bold underline cursor-pointer"
            onClick={() => navigate(`/appointments/${appointment.id}`)}
          >
            {toLongDateString(appointment.start)}
          </span>
          <span className="text-base-content text-sm font-bold"> / </span>
          <span className="text-base-content text-sm font-normal">
            {appointment.services?.map((service) => service.name).join(', ') || 'N/A'} with{' '}
            {appointment.dentist.firstName} {appointment.dentist.lastName}
          </span>
        </div>
      ) : (
        <div className="py-4 text-sm">This patient has no upcoming appointments scheduled.</div>
      )}
      <Button
        onClick={() => {
          setIsBookAppointmentModalOpen(true);
        }}
        className="flex flex-row gap-1.5 items-center hover:bg-opacity-[75%]"
        workflow={PatientWorkflow}
        trackingLabel="Book New Appointment Button"
      >
        Book New Appointment
      </Button>

      <Modal
        isOpen={isBookAppointmentModalOpen}
        onRequestClose={() => setIsBookAppointmentModalOpen(false)}
        shape="big-square"
      >
        <BookAppointmentForm
          authProvider={authProvider}
          onCloseClick={() => setIsBookAppointmentModalOpen(false)}
          patientId={patientId}
          includeActionButtons={true}
        />
      </Modal>
    </PortalContainer>
  );
}
