import { PatientInsurance } from '../../../API/insurance';
import InsuranceInformationSection from './InsuranceInformationSection';
import PatientInformationSection from './PatientInformationSection';
import PaymentMethodSection from './PaymentMethodSection';

export default function InformationTile({
  insuranceDetails,
}: {
  insuranceDetails: PatientInsurance | null | undefined;
}) {
  return (
    <div className="bg-white rounded-lg w-full p-8 items-start flex flex-row justify-start">
      <PatientInformationSection />
      <div className="border-l mx-8 h-72 w-1 border-rule"></div>
      <InsuranceInformationSection insuranceDetails={insuranceDetails} />
      <div className="border-l mx-8 h-72 w-1 border-rule"></div>
      <PaymentMethodSection />
    </div>
  );
}
