import cx from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ClearButton from '../ClearButton';
import Angle from '../icons/angle';
import { clamp } from '../number/number';
import WithOverlay from '../WithOverlay';

export type SearchSelectOption = {
  id: string;
  label: string;
  renderContent?: React.ReactNode;
};

type SearchSelectBaseProps = {
  options: SearchSelectOption[];
  id?: string;
  disabled?: boolean;
  wrapperCustomClass?: string;
  componentContainerCustomClass?: string;
  overlayPlatformCustomClass?: string;
  inputCustomClass?: string;
  loading?: boolean;
  includeAngle?: boolean;
};

type ClearableSearchSelectProps = {
  onChange: (o: SearchSelectOption | null) => void;
  allowClear: boolean;
  placeholder: string;
  defaultValue?: SearchSelectOption | null;
} & SearchSelectBaseProps;

type NonclearableSearchSelectProps =
  | DefaultValueNonclearableSearchSelectProps
  | DefaultEmptyNonclearableSearchSelectProps;

type NonclearableSearchSelectBaseProps = {
  onChange: (o: SearchSelectOption) => void;
  allowClear: false;
} & SearchSelectBaseProps;

type DefaultValueNonclearableSearchSelectProps = {
  defaultValue: SearchSelectOption;
} & NonclearableSearchSelectBaseProps;

type DefaultEmptyNonclearableSearchSelectProps = {
  placeholder: string;
  defaultValue?: null;
} & NonclearableSearchSelectBaseProps;

type SearchSelectProps = ClearableSearchSelectProps | NonclearableSearchSelectProps;

const SearchSelect: React.FC<SearchSelectProps> = (props) => {
  let defaultPlaceholder: string = '';
  if ((props as { placeholder: string }).placeholder) {
    defaultPlaceholder = (props as { placeholder: string }).placeholder;
  }

  const inputRef = useRef<HTMLInputElement>(null);
  const clearButtonRef = useRef<SVGSVGElement>(null);

  const [isShowingOverlay, setIsShowingOverlay] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>(
    props.defaultValue ? props.defaultValue.label : ''
  );
  const [selectedOption, setSelectedOption] = useState<SearchSelectOption | null>(
    props.defaultValue || null
  );
  const [placeholder, setPlaceholder] = useState<string | undefined>(defaultPlaceholder);
  const [isHoveringInput, setIsHoveringInput] = useState(false);
  const [isHoveringSVG, setIsHoveringSVG] = useState(false);

  const filteredOptions = useMemo(() => {
    return props.options.filter((o) =>
      inputValue ? o.label.toLowerCase().includes(inputValue.toLowerCase()) : true
    );
  }, [inputValue, props.options]);

  const clear = useCallback(() => {
    setInputValue('');
    setPlaceholder(defaultPlaceholder);
  }, [defaultPlaceholder]);

  useEffect(() => {
    if (inputRef.current && document.activeElement === inputRef.current) {
      inputRef.current.blur();
    } else if (!selectedOption) {
      clear();
    }
  }, [clear, selectedOption]);

  const onOptionSelected = useCallback(
    (o: SearchSelectOption | null) => {
      if (o) {
        if (o.id !== selectedOption?.id) {
          props.onChange(o);
          setInputValue(o.label);
        }
      } else if (props.allowClear) {
        props.onChange(null);
      }
      setSelectedOption(o);
      setIsShowingOverlay(false);
    },
    [props, selectedOption?.id]
  );

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (filteredOptions.length === 0) {
        return;
      }
      if (e.key === 'ArrowDown') {
        setHighlightedIndex(clamp(highlightedIndex + 1, 0, filteredOptions.length - 1));
      } else if (e.key === 'ArrowUp') {
        setHighlightedIndex(clamp(highlightedIndex - 1, 0, filteredOptions.length - 1));
      } else if (e.key === 'Enter') {
        onOptionSelected(filteredOptions[highlightedIndex]);
      }
    },
    [filteredOptions, highlightedIndex, onOptionSelected]
  );

  const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, []);

  const onMouseEnterInput = useCallback(() => {
    setIsHoveringInput(true);
  }, []);

  const onMouseLeaveInput = useCallback(() => {
    setIsHoveringInput(false);
  }, []);

  const onMouseEnterSVG = useCallback(() => {
    setIsHoveringSVG(true);
  }, []);

  const onMouseLeaveSVG = useCallback(() => {
    setIsHoveringSVG(false);
  }, []);

  const onClearButtonClick = useCallback(() => {
    onOptionSelected(null);
  }, [onOptionSelected]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const onFocus = useCallback(() => {
    setIsShowingOverlay(true);
    if (selectedOption) {
      setPlaceholder(selectedOption.label);
      setInputValue('');
      const index = filteredOptions.findIndex((o) => o.id === selectedOption.id);
      setHighlightedIndex(index);
    } else {
      setHighlightedIndex(0);
    }
  }, [filteredOptions, selectedOption]);

  const onBlur = useCallback(() => {
    // Delay the onBlur event to allow the click event on the SVG icon to be processed first
    setTimeout(() => {
      setIsShowingOverlay(false);
      if (selectedOption) {
        setInputValue(selectedOption.label);
        setPlaceholder(defaultPlaceholder);
      } else {
        clear();
      }
    }, 200);
  }, [clear, defaultPlaceholder, selectedOption]);

  const onOptionHovered = useCallback((o: SearchSelectOption, idx: number) => {
    setHighlightedIndex(idx);
  }, []);

  const onAngleClick = useCallback(() => {
    setIsShowingOverlay((prevShowingOverlay) => !prevShowingOverlay);
    if (selectedOption) {
      setPlaceholder(selectedOption.label);
      setInputValue('');
    }
  }, [selectedOption]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsShowingOverlay(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  let inputClassName =
    'search-select-input h-9 text-xs border text-base-content w-full rounded-lg pl-3 focus:outline-blue-200';
  if (props.inputCustomClass) {
    inputClassName += ` ${props.inputCustomClass}`;
  }

  return (
    <div ref={wrapperRef} className={props.wrapperCustomClass}>
      <WithOverlay
        showing={isShowingOverlay}
        xOffset={0}
        yOffset={44}
        overlayComponent={
          <SelectList
            highlightedIndex={
              filteredOptions.length > 1 ? clamp(highlightedIndex, 0, filteredOptions.length) : 0
            }
            options={filteredOptions}
            onOptionHovered={onOptionHovered}
            onOptionClicked={onOptionSelected}
          />
        }
        componentContainerCustom={props.componentContainerCustomClass}
        overlayPlatformCustom={props.overlayPlatformCustomClass}
      >
        <div className="search-select-container flex flex-row relative">
          <input
            id={props.id}
            ref={inputRef}
            className={inputClassName}
            aria-label="Searchable select input"
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onChange={onInputChange}
            value={inputValue}
            placeholder={placeholder}
            onMouseEnter={onMouseEnterInput}
            onMouseLeave={onMouseLeaveInput}
            disabled={props.disabled}
            aria-disabled={props.disabled}
            autoComplete="off"
          />
          {props.includeAngle && !props.loading && !(props.allowClear && inputValue) && (
            <Angle
              disabled={props.disabled}
              onClick={onAngleClick}
              isShowingOverlay={isShowingOverlay}
            />
          )}
          {props.loading && (
            <span
              className="spinner-border spinner-border-sm -ml-7 mt-2"
              role="status"
              aria-hidden="true"
              aria-label="loading"
            />
          )}
          {!props.loading && props.allowClear && (
            <ClearButton
              ref={clearButtonRef}
              noDisplay={(!isHoveringInput && !isHoveringSVG && !isShowingOverlay) || !inputValue}
              onClick={onClearButtonClick}
              onMouseEnter={onMouseEnterSVG}
              onMouseLeave={onMouseLeaveSVG}
            />
          )}
        </div>
      </WithOverlay>
    </div>
  );
};

export default SearchSelect;

type SelectListProps = {
  highlightedIndex: number;
  options: SearchSelectOption[];
  onOptionHovered: (o: SearchSelectOption, idx: number) => void;
  onOptionClicked: (o: SearchSelectOption) => void;
  selectListClassnameCustom?: string;
};

const SelectList: React.FC<SelectListProps> = ({
  highlightedIndex,
  options,
  onOptionHovered,
  onOptionClicked,
  selectListClassnameCustom,
}) => {
  let selectListClassname = 'select-list overflow-y-auto overflow-x-hidden text-xs';
  if (selectListClassnameCustom) {
    selectListClassname += selectListClassnameCustom;
  }

  if (options.length === 0) {
    return (
      <div className={selectListClassname} aria-label="Searchable select options">
        <div className="no-content text-center text-base-content py-4 px-0">
          No matching options
        </div>
      </div>
    );
  }

  return (
    <div className={`${selectListClassname} max-h-[30vh]`} aria-label="Searchable select options">
      {options.map((o, idx) => (
        <div
          key={o.id}
          className={cx({
            'select-list-option text-base-content pl-2.5 text-left cursor-pointer': true,
            'selection: bg-[#f3f3f3] highlighted': idx === highlightedIndex,
          })}
          onMouseEnter={() => {
            onOptionHovered(o, idx);
          }}
          onMouseDown={() => {
            onOptionClicked(o);
          }}
        >
          {o.renderContent ? o.renderContent : o.label}
        </div>
      ))}
    </div>
  );
};
