import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Appointment } from '../ServiceContext/appointments';

type Props = {
  appointment: Appointment;
};

const InvoiceTableRowActionButton: React.FC<Props> = ({ appointment }) => {
  const actionButtonStyling =
    'bg-primary text-white hover:bg-opacity-[75%] min-w-[120px] text-sm font-medium p-2.5 rounded-3xl shadow-md';
  const navigate = useNavigate();

  const { invoice } = appointment;

  if (invoice) {
    if (invoice.isDraft) {
      return (
        <button
          id={`view-draft-button-${appointment.id}`}
          className={`${actionButtonStyling} text-center`}
          onClick={() => navigate(`/invoices/${appointment.id}`)}
        >
          View Draft
        </button>
      );
    }
    return (
      <button
        id={`view-invoice-button-${appointment.id}`}
        className={actionButtonStyling}
        onClick={() => navigate(`/invoices/${appointment.id}`)}
      >
        View Invoice
      </button>
    );
  } else {
    return (
      <button
        id={`create-invoice-button-${appointment.id}`}
        className={actionButtonStyling}
        onClick={() => navigate(`/appointments/${appointment.id}/invoicing`)}
      >
        Create Invoice
      </button>
    );
  }
};

export default InvoiceTableRowActionButton;
