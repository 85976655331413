import React from 'react';
import ReactModal from 'react-modal';

import { Tracking } from '../amplitude';
import { QueryContext } from '../API/context';
import { PatientReferral } from '../API/referrals';
import { AuthProvider } from '../Authentication/Authentication';
import useLockBodyScroll from '../shared/Modal/useLockBodyScroll';
import ReferPatientForm from './ReferPatientForm';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  onReferralCreated: (newReferral: PatientReferral) => void;
  onCancel: () => void;
  queryContext: QueryContext;
} & Tracking;

const ReferPatientModal: React.FC<Props> = ({
  authProvider,
  practiceId,
  onReferralCreated,
  onCancel,
  queryContext,
  ...tracking
}) => {
  useLockBodyScroll(true);

  let modalContents: React.ReactNode = (
    <ReferPatientForm
      authProvider={authProvider}
      practiceId={practiceId}
      onReferralCreated={onReferralCreated}
      onCancel={onCancel}
      queryContext={queryContext}
      {...tracking}
    />
  );

  return (
    <ReactModal
      isOpen
      contentLabel="Refer New Patient"
      className=" outline-none mx-auto my-10 bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 overflow-auto text-secondary"
      overlayClassName="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30"
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
    >
      <h2 className="text-2xl font-bold mb-4 pt-8 pl-6 pb-4 border-rule border-b-2">
        Refer New Patient
      </h2>
      {modalContents}
    </ReactModal>
  );
};

export default ReferPatientModal;
