import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ZeroPaymentWarning = () => (
  <div className="flex flex-row items-center gap-3 bg-error mx-2 p-2 rounded-lg">
    <FontAwesomeIcon icon={faCircleExclamation} style={{ color: 'lightcoral', scale: '140%' }} />
    <div className="text-error-content font-bold">
      Edit or remove payments with $0 in order to process transaction.
    </div>
  </div>
);
