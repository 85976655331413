import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useCallback, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { InvoicingWorkflow } from '../../../amplitude';
import { createAppointment } from '../../../API/appointments';
import { AuthProvider } from '../../../Authentication/Authentication';
import { Appointment } from '../../../ServiceContext/appointments';
import Alert, { AlertData, errorAlert, successAlert } from '../../../shared/Alert';
import Button from '../../../shared/Button/Button';
import ClearButton from '../../../shared/ClearButton';
import { toApiDateTimeFormat, toShortDateTimeString } from '../../../shared/dates/dates';
import SearchSelect, { SearchSelectOption } from '../../../shared/SearchSelect/SearchSelect';
import { usernameFromDentist } from '../../../shared/strings';
import { getOfficeTimeZoneForAppointmentBooking } from '../../../shared/timezone/timeZone';

type Props = {
  authProvider: AuthProvider;
  patientId: string;
  onCloseClick?: () => void;
  includeActionButtons?: boolean;
  appointment: Appointment;
};
const BookFollowUpAppointmentModalContent: React.FC<Props> = ({
  authProvider,
  patientId,
  includeActionButtons,
  onCloseClick,
  appointment,
}) => {
  const navigate = useNavigate();
  const promoCodeClearButtonRef = useRef<SVGSVGElement>(null);

  const [alert, setAlert] = useState<AlertData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [createdAppointmentId, setCreatedAppointmentId] = useState<string | null>(null);
  const [selectedDentist, setSelectedDentist] = useState<SearchSelectOption | null>(null);
  const [selectedLocalDateTime, setSelectedDateTime] = useState<Date | null>(null);
  const [notes, setNotes] = useState<string>('');
  const [promoCode, setPromoCode] = useState<string>('');

  const practiceDentists = authProvider.managedDentists || [];

  const dateTimeInOfficeTz = getOfficeTimeZoneForAppointmentBooking({
    selectedLocalDateTime,
    selectedDentist,
    selectedDentistId: null,
    practiceDentists,
  });

  const mutation = useMutation(createAppointment, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      setAlert(
        successAlert(
          `You have successfully booked an appointment for ${data.user?.firstName} ${
            data.user?.lastName
          } on ${toShortDateTimeString(dateTimeInOfficeTz)}.`
        )
      );
      setCreatedAppointmentId(data.id);
    },
    onError: (error) => {
      setIsLoading(false);
      setAlert(errorAlert(error as any));
    },
  });

  const getDentistOptions = useCallback(() => {
    if (!authProvider.managedDentists) {
      return [];
    }
    return authProvider.managedDentists.map((d) => {
      return {
        id: d.id,
        label: usernameFromDentist(d),
        renderContent: (
          <div className="dentist-search-option py-2 px-2 text-left">
            {d.firstName} {d.lastName}{' '}
            <span className="dentist-search-option-username font-semibold">({d.username})</span>
          </div>
        ),
      };
    });
  }, [authProvider.managedDentists]);

  const onNewDentistSelected = (selected: SearchSelectOption | null) => {
    if (selected) {
      setSelectedDentist(selected);
    } else {
      setSelectedDentist(null);
    }
  };

  const onPromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value);
  };

  const filterDate = (d: Date) => {
    return (
      DateTime.fromJSDate(d) > DateTime.now() ||
      (DateTime.fromJSDate(d).hasSame(DateTime.now(), 'day') &&
        DateTime.fromJSDate(d).hasSame(DateTime.now(), 'month'))
    );
  };

  const filterTime = (d: Date) => {
    return DateTime.fromJSDate(d) > DateTime.now();
  };

  const dateTimeInUTC = dateTimeInOfficeTz.toUTC();

  const buttonStyle = 'bg-slate-400 text-white font-semibold shadow-none';
  const cancelButtonStyle =
    'border border-slate-400 bg-white font-semibold shadow-none hover:opacity-75 text-black';

  const onScheduleAppointmentButtonClick = (e: React.FormEvent) => {
    if (!selectedDentist) {
      return;
    }
    e.preventDefault();
    const appointmentData = {
      authProvider,
      dentistId: selectedDentist.id,
      patientId,
      startTimeInUTC: toApiDateTimeFormat(dateTimeInUTC),
      notes,
      promoCode,
      patientInsuranceId: appointment.patientInsuranceId || undefined,
    };
    mutation.mutate(appointmentData);
  };

  const onPromoCodeClearButtonClick = () => {
    setPromoCode('');
  };

  const isScheduleAppointmentDisabled = !selectedDentist || !selectedLocalDateTime;

  return (
    <form className="book-appointment-form flex flex-col gap-3 rounded-lg text-general-gray text-left">
      {alert && <Alert {...alert} />}
      {!createdAppointmentId && (
        <>
          <div className="input-group flex flex-col items-start mb-2">
            <div className="text-neutral-700 text-2xl font-bold mb-4">
              Book a Follow-Up Appointment
            </div>
            <label htmlFor="dentist-dropdown" className="dentist-label font-semibold mb-1">
              Select a dentist for the appointment
            </label>
            <SearchSelect
              id="dentist-dropdown"
              options={getDentistOptions()}
              onChange={onNewDentistSelected}
              placeholder="Choose a Dentist"
              allowClear={true}
              includeAngle
              disabled={isLoading}
              inputCustomClass="border"
              componentContainerCustomClass="w-full"
            />
          </div>
          <div className="input-group flex flex-col items-start">
            <label
              htmlFor="appointment-time-date-picker"
              className="appointment-time-label font-semibold mb-1"
            >
              Pick a time for the appointment
            </label>
            <div className="flex gap-4">
              <div className="flossy-picker">
                <DatePicker
                  inline
                  id="appointment-date-picker"
                  selected={selectedLocalDateTime}
                  onChange={(dateValue) => {
                    if (typeof dateValue === 'object') {
                      setSelectedDateTime(dateValue as Date);
                    }
                  }}
                  dateFormat="MMMM d, yyyy"
                  filterDate={filterDate}
                  minDate={new Date()}
                  fixedHeight={true}
                  disabled={isLoading}
                />
              </div>
              <div className="flossy-picker">
                <DatePicker
                  inline
                  id="appointment-time-picker"
                  selected={selectedLocalDateTime}
                  onChange={(dateValue) => {
                    if (typeof dateValue === 'object') {
                      setSelectedDateTime(dateValue as Date);
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  filterTime={filterTime}
                  fixedHeight={true}
                  dateFormat="h:mm aa"
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
          <div className="input-group flex flex-col items-start">
            <label htmlFor="notes" className="notes-label font-semibold mb-1">
              {`Enter notes (Optional)`}
            </label>
            <textarea
              id="notes"
              className="border w-full pl-3 pt-2 focus:outline-blue-200"
              rows={3}
              placeholder="..."
              value={notes}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value)}
              disabled={isLoading}
              style={{ borderRadius: '8px' }}
            />
          </div>
          <div className="input-group flex flex-col items-start">
            <label htmlFor="promo-code" className="promo-code-label font-semibold mb-1">
              {`Enter promo code (Optional)`}
            </label>
            <div className="promo-code-input relative">
              <input
                onChange={onPromoCodeChange}
                value={promoCode}
                className="border h-10 pl-3 text-[#3d414b] focus:outline-blue-200 w-full"
                autoComplete="off"
                placeholder="E.g. FLOSSY123"
                disabled={isLoading}
                style={{ borderRadius: '8px' }}
              />
            </div>
            <div className="promo-code-clear-button absolute top-[28px] right-[2px]">
              <ClearButton
                ref={promoCodeClearButtonRef}
                noDisplay={!promoCode}
                onClick={onPromoCodeClearButtonClick}
              />
            </div>
          </div>
        </>
      )}
      {includeActionButtons && (
        <div className="action-buttons justify-center flex flex-row gap-3 mt-4">
          {!createdAppointmentId && (
            <Button
              className={`${buttonStyle} ${
                isScheduleAppointmentDisabled
                  ? 'disabled:opacity-50'
                  : 'cursor-pointer hover:bg-slate-400 hover:bg-opacity-[75%]'
              }`}
              onClick={onScheduleAppointmentButtonClick}
              loading={isLoading}
              disabled={isScheduleAppointmentDisabled}
              trackingLabel="Schedule Appointment Button"
              workflow={InvoicingWorkflow}
              context="bookFollowUpAppointmentModalContent"
            >
              Schedule Appointment
            </Button>
          )}
          {createdAppointmentId && (
            <Button
              className={`flex flex-row gap-1.5 items-center ${buttonStyle} hover:opacity-75`}
              onClick={() => navigate(`/appointments/${createdAppointmentId}/details`)}
              trackingLabel="Go To Appointment Button"
              workflow={InvoicingWorkflow}
              context="bookFollowUpAppointmentModalContent"
            >
              <FontAwesomeIcon
                icon={faCalendarCheck}
                style={{
                  color: 'white',
                }}
              />
              Go to Appointment
            </Button>
          )}
          <Button
            onClick={onCloseClick}
            disabled={isLoading}
            className={cancelButtonStyle}
            trackingLabel="Close Button"
            workflow={InvoicingWorkflow}
            context="bookFollowUpAppointmentModalContent"
          >
            Close
          </Button>
        </div>
      )}
    </form>
  );
};

export default BookFollowUpAppointmentModalContent;
