import React from 'react';

import { XIcon } from '../HomePage/components/Icons';

type Props = {
  noDisplay?: Boolean;
  onClick: () => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  color?: 'red' | 'grey';
};

const ClearIcon = React.forwardRef<SVGSVGElement, Props>(
  ({ noDisplay, onClick, onMouseEnter, onMouseLeave, color }, ref) => {
    return (
      <div
        className={`search-input-clear-button outline-0 cursor-pointer my-auto mr-3 text-red-300 hover:text-red-400 ${
          noDisplay ? 'no-display hidden' : ''
        }`}
        aria-label="Input clear button"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        role="button"
      >
        <XIcon color={color ?? 'red'} />
      </div>
    );
  }
);

export default ClearIcon;
