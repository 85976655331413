import React from 'react';

import { BookingWorkflow } from '../../amplitude';
import { PatientInsurance } from '../../API/insurance';
import Button from '../../shared/Button/Button';
import { formatMemberId } from '../../shared/strings';

type Props = {
  patientInsurance: PatientInsurance;
  onCancel: () => void;
  onProcessTransaction: () => void;
};

const buttonStyling = ' bg-primary text-white hover:opacity-75 disabled:opacity-50 ';

const InsuranceInformation: React.FC<Props> = ({
  patientInsurance,
  onCancel,
  onProcessTransaction,
}) => {
  return (
    <div className="pl-8 pb-4 pr-8 bg-white rounded-md shadow-md space-y-4">
      <span className="text-xs pb-2">Please verify that the following information is correct</span>
      <div id={'separator'} className="h-px bg-base-300 my-4 w-full"></div>
      <div className={'flex flex-col gap-2 text-xs w-full'}>
        <div className={'flex justify-between'}>
          <span>Insurance Carrier:</span>
          <span>{patientInsurance.insuranceCarrier.name}</span>
        </div>
        {patientInsurance.planType && (
          <div className={'flex justify-between'}>
            <span>Plan Type: </span>
            <span>{patientInsurance.planType}</span>
          </div>
        )}
        <div className={'flex justify-between'}>
          <span>Member First Name:</span>
          <span>{patientInsurance.memberFirstName}</span>
        </div>
        <div className={'flex justify-between'}>
          <span>Member Last Name:</span>
          <span>{patientInsurance.memberLastName}</span>
        </div>
        <div className={'flex justify-between'}>
          <span>Date of Birth:</span>
          <span>{patientInsurance.dateOfBirth}</span>
        </div>
        {patientInsurance.memberId && (
          <div className={'flex justify-between'}>
            <span>Member ID:</span>
            <span>{formatMemberId(patientInsurance.memberId, false)}</span>
          </div>
        )}
      </div>
      <div className="flex flex-row gap-2 justify-center pt-8">
        <Button
          className={buttonStyling}
          onClick={onProcessTransaction}
          workflow={BookingWorkflow}
          trackingLabel="Process Transaction Button"
          context="insuranceInformation"
        >
          Process Transaction
        </Button>
        <Button
          id="cancel-button"
          onClick={onCancel}
          className={'border border-rule bg-white text-primary hover:opacity-75 shadow-none'}
          workflow={BookingWorkflow}
          trackingLabel="Cancel Process Transaction Button"
          context="insuranceInformation"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default InsuranceInformation;
