import { formatDistanceToNow } from 'date-fns';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ClickTrackingAction, GeneralWorkflow, runFTrack } from '../amplitude';
import { Message } from '../ServiceContext/messages';
import { toLocaleDateTimeString } from '../shared/dates/dates';
import { stringifyMoney } from '../shared/money/stringifyMoney';

type MessageListProps = {
  messages: Message[];
  onMarkedRead: (m: Message) => void;
  onClearAll: () => void;
  displayClearAll: boolean;
};

const MessageList: React.FC<MessageListProps> = ({
  messages,
  onMarkedRead,
  onClearAll,
  displayClearAll,
}) => {
  let className = 'messages-list min-w-[360px] items-start flex flex-col max-h-[50vh]';

  return (
    <div className={className}>
      <div className="text-base-content text-2xl font-medium px-6 pt-3">Notifications</div>
      <div className="list-content overflow-y-auto overflow-x-hidden min-h-[160px] h-5/6">
        {messages.length === 0 ? (
          <div className="no-content text-base italic text-left pt-3 pr-0 pb-0 pl-6">
            No messages
          </div>
        ) : (
          messages.map((m) => <MessageDetail key={m.id} message={m} onMarkedRead={onMarkedRead} />)
        )}
      </div>
      {displayClearAll && (
        <div className="w-full flex justify-center py-3 border-t border-rule">
          <button
            id="clear-all-button"
            className={`rounded-full text-white font-medium text-sm px-3 py-2 bg-primary hover:opacity-75`}
            onClick={onClearAll}
          >
            Clear All Notifications
          </button>
        </div>
      )}
    </div>
  );
};

type MessageDetailProps = {
  message: Message;
  onMarkedRead: (m: Message) => void;
};

const MessageDetail: React.FC<MessageDetailProps> = ({
  message,
  onMarkedRead,
}: {
  message: Message;
  onMarkedRead: (m: Message) => void;
}) => {
  switch (message.type) {
    case 'new_booked_appointment':
      return <NewBookedAppointmentMessageDetail message={message} onMarkedRead={onMarkedRead} />;
    case 'appointment_reschedule':
      return <AppointmentRescheduleMessageDetail message={message} onMarkedRead={onMarkedRead} />;
    case 'appointment_cancellation':
      return <AppointmentCancellationMessageDetail message={message} onMarkedRead={onMarkedRead} />;
    case 'referral_credit_applied':
      return <ReferralCreditAppliedMessageDetail message={message} onMarkedRead={onMarkedRead} />;
    case 'new_bookings_credits_bill':
      return (
        <NewBookingsAndCreditsBillMessageDetail message={message} onMarkedRead={onMarkedRead} />
      );
  }
};

function logViewDetailsEvent({ type }: { type: string }) {
  runFTrack({
    event: 'Click Notification View Details',
    workflow: GeneralWorkflow,
    action: ClickTrackingAction,
    context: 'messageCenter',
    componentId: 'notificationTile',
    extraProps: {
      type: type,
    },
  });
}

const getLocalizedStartTime = (start: string, timeZone: string): string => {
  return toLocaleDateTimeString(start, DateTime.DATE_MED, timeZone);
};

const withMessageDetail = (WrappedComponent: React.ComponentType<{ message: Message }>) => {
  return (props: { message: Message; onMarkedRead: (m: Message) => void }) => {
    const { message, onMarkedRead } = props;
    const [localMessage, setLocalMessage] = useState<Message>(message);
    const onClick = useCallback(() => {
      setLocalMessage({
        ...localMessage,
        readAt: DateTime.now().toString(),
      });
      onMarkedRead(localMessage);
    }, [localMessage, onMarkedRead]);
    return (
      <div
        className="booking-message-detail flex flex-row px-3 py-4 items-start border-b border-[#d0d0d0] border-solid hover:bg-base-200"
        onClick={onClick}
      >
        <div className="new-message-marker-container flex p-1.5">
          {!message.readAt && <div className="w-2 h-2 bg-icon-success rounded-full" />}
        </div>
        <WrappedComponent message={localMessage} />
      </div>
    );
  };
};

const NewBookingsAndCreditsBillMessageDetail = withMessageDetail(
  ({ message }: { message: Message }) => {
    if (message.type !== 'new_bookings_credits_bill') {
      return null;
    }
    const messageTitle = useMemo(() => 'New Bookings and Credits Bill', []);

    const messageBody = useMemo(
      () =>
        `Your Bookings and Credits Bill is ready for payment for the month ${message.payload.period}`,
      [message.payload.period]
    );

    const navigate = useNavigate();

    return (
      <div
        className={`new-message-content ${
          message.readAt ? 'opacity-60' : ''
        } cursor-pointer flex flex-column gap-2 items-start w-11/12 mx-1.5`}
        onClick={() => {
          navigate(`/accounting/bookings-and-credits`);
        }}
      >
        <div className="message-title text-sm font-semibold text-left">{messageTitle}</div>
        <div className="message-body text-sm text-left w-72 text-base-content font-normal leading-normal">
          {messageBody}
        </div>
        <div className="message-time text-xs mt-1">
          {formatDistanceToNow(new Date(message.createdAt))} ago
        </div>
        <button
          className={'text-xs mt-2 underline hover:opacity-75'}
          onClick={() => {
            logViewDetailsEvent({
              type: 'New Bookings And Credits Bill',
            });
            navigate(`/accounting/bookings-and-credits`);
          }}
        >
          View Details
        </button>
      </div>
    );
  }
);

const NewBookedAppointmentMessageDetail = withMessageDetail(({ message }: { message: Message }) => {
  if (message.type !== 'new_booked_appointment') {
    return null;
  }
  const messageTitle = useMemo(() => 'New Booked Appointment', []);
  const localizedStartTime = getLocalizedStartTime(message.payload.start, message.payload.timeZone);
  const messageBody = useMemo(
    () =>
      `${message.payload.patientName} has booked an appointment with Dr. ${message.payload.dentistName} on ${localizedStartTime}`,
    [localizedStartTime, message.payload.dentistName, message.payload.patientName]
  );

  const navigate = useNavigate();

  return (
    <div
      className={`new-message-content ${
        message.readAt ? 'opacity-60' : ''
      } cursor-pointer flex flex-column gap-2 items-start w-11/12 mx-1.5`}
      onClick={() => {
        navigate(`/appointments/${message.payload.appointmentId}/details`);
      }}
    >
      <div className="message-title text-sm font-semibold text-left">{messageTitle}</div>
      <div className="message-body text-sm text-left w-72 text-base-content font-normal leading-normal">
        {messageBody}
      </div>
      <div className="message-time text-xs mt-1">
        {formatDistanceToNow(new Date(message.createdAt))} ago
      </div>
      <button
        className={'text-xs mt-2 underline hover:opacity-75'}
        onClick={() => {
          logViewDetailsEvent({
            type: 'New Booked Appointment',
          });
          navigate(`/appointments/${message.payload.appointmentId}/details`);
        }}
      >
        View Details
      </button>
    </div>
  );
});

const AppointmentRescheduleMessageDetail = withMessageDetail(
  ({ message }: { message: Message }) => {
    const navigate = useNavigate();
    if (message.type !== 'appointment_reschedule') {
      return null;
    }

    const localizedPreviousStartTime = getLocalizedStartTime(
      message.payload.previousStart,
      message.payload.timeZone
    );
    const localizedUpdatedStartTime = getLocalizedStartTime(
      message.payload.updatedStart,
      message.payload.timeZone
    );

    const messageTitle = useMemo(() => 'Appointment Reschedule', []);
    const messageBody = useMemo(
      () =>
        `An appointment with Dr. ${message.payload.dentistName} with ${message.payload.patientName} has been rescheduled from ${localizedPreviousStartTime} to ${localizedUpdatedStartTime}`,
      [
        message.payload.dentistName,
        message.payload.patientName,
        localizedPreviousStartTime,
        localizedUpdatedStartTime,
      ]
    );

    return (
      <div
        className={`new-message-content ${
          message.readAt ? 'opacity-60' : ''
        } cursor-pointer flex flex-column gap-2 items-start w-11/12 mx-1.5`}
      >
        <div className="message-title text-sm font-semibold text-left">{messageTitle}</div>
        <div className="message-body text-sm text-left">{messageBody}</div>
        <div className="message-time text-xs mt-1">
          {formatDistanceToNow(new Date(message.createdAt))} ago
        </div>
        <button
          className={'text-xs mt-2 underline hover:opacity-75'}
          onClick={() => {
            logViewDetailsEvent({
              type: 'Appointment Rescheduled',
            });
            navigate(`/appointments/${message.payload.appointmentId}/details`);
          }}
        >
          View Details
        </button>
      </div>
    );
  }
);

const AppointmentCancellationMessageDetail = withMessageDetail(
  ({ message }: { message: Message }) => {
    if (message.type !== 'appointment_cancellation') {
      return null;
    }
    const navigate = useNavigate();

    const messageTitle = useMemo(() => 'Appointment Cancellation', []);
    const messageBody = useMemo(
      () =>
        `${message.payload.patientName} has canceled an appointment with Dr. ${message.payload.dentistName}`,
      [message.payload.patientName, message.payload.dentistName]
    );

    return (
      <div
        className={`new-message-content ${
          message.readAt ? 'opacity-60' : ''
        } cursor-pointer flex flex-column gap-2 items-start w-11/12 mx-1.5`}
        onClick={() => {
          navigate(`/appointments/${message.payload.appointmentId}/details`);
        }}
      >
        <div className="message-title text-sm font-semibold text-left">{messageTitle}</div>
        <div className="message-body text-sm text-left">{messageBody}</div>
        <div className="message-time text-xs mt-1">
          {formatDistanceToNow(new Date(message.createdAt))} ago
        </div>
        <button
          className={'text-xs mt-2 underline hover:opacity-75'}
          onClick={() => {
            logViewDetailsEvent({
              type: 'Appointment Canceled',
            });
            navigate(`/appointments/${message.payload.appointmentId}/details`);
          }}
        >
          View Details
        </button>
      </div>
    );
  }
);

const ReferralCreditAppliedMessageDetail = withMessageDetail(
  ({ message }: { message: Message }) => {
    if (message.type !== 'referral_credit_applied') {
      return null;
    }

    const navigate = useNavigate();
    const messageTitle = useMemo(() => 'Referral Credit Applied', []);
    const messageBody = useMemo(
      () =>
        `Your practice has been granted ${stringifyMoney(
          message.payload.creditAmount
        )} for referring ${message.payload.patientName} to Flossy`,
      [message.payload.creditAmount, message.payload.patientName]
    );

    return (
      <div
        className={`new-message-content ${
          message.readAt ? 'opacity-60' : ''
        } cursor-pointer flex flex-column gap-2 items-start w-11/12 mx-1.5`}
        onClick={() => navigate('/patient-referral')}
      >
        <div className="message-title text-sm font-semibold text-left">{messageTitle}</div>
        <div className="message-body text-sm text-left">{messageBody}</div>
        <div className="message-time text-xs mt-1">
          {formatDistanceToNow(new Date(message.createdAt))} ago
        </div>
        <button
          className={'text-xs mt-2 underline hover:opacity-75'}
          onClick={() => {
            logViewDetailsEvent({
              type: 'Referral Credit Applied',
            });
            navigate('/patient-referral');
          }}
        >
          View Details
        </button>
      </div>
    );
  }
);

export default MessageList;
