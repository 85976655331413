import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { GeneralWorkflow } from '../../amplitude';
import { addPaymentMethod, deletePaymentMethod } from '../../API/practice';
import { formatCardTypeName } from '../../Appointments/Invoicing/utilities';
import { AuthProvider } from '../../Authentication/Authentication';
import {
  cardExpirationFromPaymentMethod,
  cardLastFourFromPaymentMethod,
  PaymentMethod,
} from '../../ServiceContext/invoices';
import Button from '../../shared/Button/Button';

export default function PaymentMethodTile({
  paymentMethod,
  authProvider,
  practiceId,
}: {
  paymentMethod: PaymentMethod;
  authProvider: AuthProvider;
  practiceId: string;
}) {
  const queryClient = useQueryClient();

  return (
    <div className="mt-2 p-4 border rounded-lg flex justify-between items-center w-full">
      <div className="text-base-content text-start">
        <span className="font-semibold">{`${formatCardTypeName(paymentMethod.type)}`}</span>
        {` ending in `}
        <span className="font-semibold">{`${
          cardLastFourFromPaymentMethod(paymentMethod) || ''
        }`}</span>
      </div>
      <div className="text-base-content text-start">
        Exp: {cardExpirationFromPaymentMethod(paymentMethod)}
      </div>
      <div className="flex space-x-2">
        <Button
          className="px-4 py-2 bg-blue-600 text-white rounded-lg"
          id="make-default-payment-method"
          onClick={async () => {
            try {
              const updatedPractice = await addPaymentMethod({
                authProvider,
                practiceId: practiceId,
                paymentMethod: paymentMethod,
                isDefault: true,
              });

              queryClient.setQueryData(['practice', practiceId], updatedPractice);
              toast.success('Default payment method updated');
            } catch (error) {
              console.error(error);
              toast.error('Failed to update default payment method');
            }
          }}
          omitBorder
          workflow={GeneralWorkflow}
          context="makeDefaultPaymentMethod"
          trackingLabel="Make Default Payment Method"
        >
          Make Default
        </Button>

        <Button
          className="px-4 py-2 bg-blue-600 rounded-lg"
          id="remove-payment-method-button"
          onClick={async () => {
            try {
              const updatedPractice = await deletePaymentMethod({
                paymentMethodId: paymentMethod.id,
                authProvider: authProvider,
                practiceId: practiceId,
              });

              queryClient.setQueryData(['practice', practiceId], updatedPractice);
              toast.success('Payment method successfully deleted.');
            } catch (error) {
              console.error(error);
              toast.error('Failed to delete payment method.');
            }
          }}
          noFill
          workflow={GeneralWorkflow}
          context="removeDefaultPaymentMethod"
          trackingLabel="Remove Default Payment Method"
        >
          Remove
        </Button>
      </div>
    </div>
  );
}
