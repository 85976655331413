import { useEffect, useRef, useState } from 'react';

import {
  acceptTreatmentPlan,
  cancelTreatmentPlan,
  completeTreatmentPlan,
  DetailedTreatmentPlan,
  getStatusTextFromState,
  markTreatmentPlanInProgress,
} from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import { ThinCheckMark } from '../../HomePage/components/Icons';

export default function TreatmentPlanStatusDropdown({
  treatmentPlan,
  authProvider,
}: {
  treatmentPlan: DetailedTreatmentPlan;
  authProvider: AuthProvider;
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const options = ['Accepted', 'In Progress', 'Completed', 'Canceled'];

  useEffect(() => {
    setSelectedOption(getStatusTextFromState(treatmentPlan.state));
  }, [treatmentPlan]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  const onSelect = (status: string) => {
    setSelectedOption(status);
    if (status === 'Accepted') {
      acceptTreatmentPlan({
        authProvider,
        treatmentPlanId: treatmentPlan.id,
      });
    } else if (status === 'In Progress') {
      markTreatmentPlanInProgress({
        authProvider,
        treatmentPlanId: treatmentPlan.id,
      });
    } else if (status === 'Completed') {
      completeTreatmentPlan({
        authProvider,
        treatmentPlanId: treatmentPlan.id,
      });
    } else if (status === 'Canceled') {
      cancelTreatmentPlan({
        authProvider,
        treatmentPlanId: treatmentPlan.id,
      });
    }
  };

  return (
    <div className="inline-flex items-center">
      <div className="relative text-left w-full" ref={dropdownRef}>
        <div className="text-sm border rounded-full">
          <button
            title="Change Status"
            type="button"
            className={`inline-flex justify-between items-center text-primary font-bold px-2 py-1 text-sm rounded-md`}
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {selectedOption}
            <svg
              className="h-3 w-3 text-base-content ml-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </button>
        </div>

        {showDropdown && (
          <div className="origin-top-right absolute -right-12 mt-2 rounded-md bg-white ring-1 ring-primary z-40 w-52 py-2">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {options.map((option, index) => (
                <button
                  key={index}
                  className="block w-full text-left px-4 py-2 text-sm text-base-content hover:bg-highlight"
                  onClick={() => {
                    onSelect(option);
                    setShowDropdown(false);
                  }}
                >
                  <div className="flex justify-between">
                    <div>{option}</div>
                    {selectedOption === option && <ThinCheckMark />}
                  </div>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
