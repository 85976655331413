import { MessagePayload } from 'firebase/messaging';
import React, { useCallback, useEffect, useState } from 'react';

import { ErrorResponse, isErrorResponse, SentryCompositeError } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import { requestForToken } from '../../firebase';
import { reportErrorToSentry } from '../../ServiceContext/error';
import { useServices } from '../../ServiceContext/ServiceContext';
import { createNotification, getNotificationPermission } from './browserNotification';
import { FirebaseNotificationData } from './notificationDataTypes';

type Props = {
  authentication: AuthProvider;
};

const FirebaseNotificationManagement: React.FC<Props> = ({ authentication }) => {
  const services = useServices();
  const [notification, setNotification] = useState<FirebaseNotificationData | null>(null);

  const navigateToAppointmentDetails = useCallback((appointmentId: string) => {
    window.open(`/appointments/${appointmentId}/details`);
  }, []);

  const navigateToPatientReferral = useCallback(() => {
    window.open(`/patient-referral`);
  }, []);

  const notify = useCallback(
    () =>
      notification
        ? createNotification({
            data: notification,
            navigateToAppointmentDetails,
            navigateToPatientReferral,
          })
        : null,
    [notification, navigateToAppointmentDetails, navigateToPatientReferral]
  );

  const notificationPermission = getNotificationPermission();

  useEffect(() => {
    if (notificationPermission === 'granted') {
      const onFetchedToken = async (fetchedToken: string) => {
        const authUser = authentication.authUser;
        if (authUser) {
          let res: null | ErrorResponse = null;
          res = await services.managersService.updateManagerFcmClientToken({
            managerId: authUser.user.id,
            token: fetchedToken,
          });
          if (isErrorResponse(res)) {
            reportErrorToSentry(
              new SentryCompositeError({
                summary: `Failed to update FCM client token: ${res!.errorResponse}`,
                extra: { res },
              })
            );
          }
        }
      };
      const onMessage = (payload: MessagePayload) => {
        console.log(`FirebaseNotificationManagement.onMessage:: ${payload}`);
        if (payload.data) {
          setNotification(payload.data as FirebaseNotificationData);
        } else {
          setNotification(null);
        }
      };
      requestForToken({
        onFetchedTokenCallback: onFetchedToken,
        onMessageCallback: onMessage,
      }).then();
    }
  }, [authentication, services.dentistsService, notificationPermission, services.managersService]);

  useEffect(() => {
    if (notification) {
      notify();
    }
  }, [notification, notify]);

  return <div />;
};

export default FirebaseNotificationManagement;
