import { InvoicingWorkflow } from '../../../amplitude';
import { Patient } from '../../../ServiceContext/patients';
import Button from '../../../shared/Button/Button';

export default function InsuranceReviewModalContent({
  onVerified,
  closeModal,
  patient,
}: {
  onVerified: () => void;
  closeModal: () => void;
  patient: Patient;
}) {
  return (
    <div className={'bg-white p-4 rounded-lg text-secondary w-[30%]'}>
      <span className="text-xl font-bold mb-4 block">Review Insurance</span>
      <div>
        {`${patient.firstName} ${patient.lastName} does not have insurance on file. Please verify insurance benefits before
        processing the appointment`}
      </div>
      <div id={'insurance-review-options-wrapper'} className={'flex flex-col gap-2'}>
        <div id={'action-buttons'} className={'flex flex-row justify-center gap-2 mt-4 mb-2'}>
          <Button
            onClick={onVerified}
            workflow={InvoicingWorkflow}
            trackingLabel="Verify Insurance Button"
            context="reviewInvoiceModalContent"
          >
            Verified
          </Button>
          <Button
            onClick={closeModal}
            className={'border border-rule bg-white text-primary shadow-none hover:opacity-75'}
            workflow={InvoicingWorkflow}
            trackingLabel="Go Back Button"
            context="reviewInvoiceModalContent"
          >
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
}
