import React, { useCallback, useState } from 'react';

type Props = {
  inputId: string;
  procedure: {
    additionalNotes?: string | null;
  };
  onAdditionalProcedureDetailsChange: (newValue: string | null) => void;
};
const AdditionalProcedureDetails: React.FC<Props> = ({
  inputId,
  procedure,
  onAdditionalProcedureDetailsChange,
}) => {
  const [value, setValue] = useState<string>(procedure.additionalNotes || '');

  const onDetailsInputChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      onAdditionalProcedureDetailsChange(event.target.value);
    },
    [onAdditionalProcedureDetailsChange]
  );

  return (
    <textarea
      id={inputId}
      placeholder={'Enter specific areas of mouth, teeth codes, etc.'}
      className={'border w-full rounded-md focus:outline-primary p-3'}
      rows={2}
      value={value}
      onChange={onDetailsInputChange}
    />
  );
};

export default AdditionalProcedureDetails;
