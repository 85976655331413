import { faMoneyBillTrendUp, faPiggyBank } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { ClickTrackingAction, PayoutsWorkflow, runFTrack } from '../amplitude';

const Accounting: React.FC = () => {
  return (
    <div className="accounting-options flex flex-row justify-center gap-24">
      <Link
        to="/accounting/payouts"
        className="no-underline hover:no-underline w-1/3"
        onClick={() => {
          runFTrack({
            event: 'Click Bookings And Credits Tile',
            workflow: PayoutsWorkflow,
            action: ClickTrackingAction,
            context: 'accounting',
            componentId: 'bookingsAndCreditsTile',
          });
        }}
      >
        <div className="payouts flex flex-row gap-10 border p-12 rounded-lg cursor-pointer flex-grow flex-shrink text-secondary hover:bg-base-content-flossy hover:bg-opacity-[10%] shadow-md">
          <FontAwesomeIcon
            icon={faMoneyBillTrendUp}
            size="5x"
            style={{
              color: '#d47766',
              opacity: '75%',
            }}
          />
          <span className="payouts-label text-3xl self-center">Payouts</span>
        </div>
      </Link>
      <Link
        to="/accounting/bookings-and-credits"
        onClick={() => {
          runFTrack({
            event: 'Click Payouts Tile',
            workflow: PayoutsWorkflow,
            action: ClickTrackingAction,
            context: 'accounting',
            componentId: 'payoutsTile',
          });
        }}
        className="no-underline hover:no-underline w-1/3"
      >
        <div className="bookings-and-credits flex flex-row gap-10 border p-12 rounded-lg cursor-pointer flex-grow flex-shrink text-secondary hover:bg-base-content-flossy hover:bg-opacity-[10%] shadow-md">
          <FontAwesomeIcon
            icon={faPiggyBank}
            size="5x"
            style={{
              color: '#d47766',
              opacity: '75%',
            }}
          />
          <span className="bookings-and-credits-label text-3xl self-center">
            Bookings and Credits
          </span>
        </div>
      </Link>
    </div>
  );
};

export default Accounting;
