import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ClickTrackingAction, PatientWorkflow, runFTrack } from '../../../amplitude';
import { createPatient } from '../../../API/patients';
import { AuthProvider } from '../../../Authentication/Authentication';
import { DocumentIcon } from '../../../HomePage/components/Icons';
import Alert, { AlertData, errorAlert } from '../../../shared/Alert';
import Button from '../../../shared/Button/Button';
import ClearIcon from '../../../shared/ClearIcon';
import { toApiDateFormat } from '../../../shared/dates/dates';
import DropdownButton, { SelectOption } from '../../../shared/DropdownButton';
import PhoneNumberInput from '../../../shared/phoneNumbers/PhoneNumberInput';
import { emailRegex } from '../../../shared/strings/stringValidation';
import DateInput from '../../../shared/ValidatedInput/DateInput';
import ValidatedInput from '../../../shared/ValidatedInput/ValidatedInput';
import BookAppointmentForm from '../../BookAppointmentForm';

type Props = {
  authProvider: AuthProvider;
  onCloseClick: () => void;
  practiceId: string;
};
const CreatePatientForm: React.FC<Props> = ({ authProvider, onCloseClick, practiceId }) => {
  const navigate = useNavigate();

  const FormState = {
    INITIAL: 'INITIAL',
    SUCCESS: 'SUCCESS',
    SCHEDULE: 'SCHEDULE',
  };

  const [formState, setFormState] = useState(FormState.INITIAL);

  const firstNameClearButtonRef = useRef<SVGSVGElement>(null);
  const lastNameClearButtonRef = useRef<SVGSVGElement>(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [phone, setPhone] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [isBirthDateValid, setIsBirthDateValid] = useState(false);
  const [preferredContactMethod, setPreferredContactMethod] = useState<SelectOption | null>(null);
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [createdPatientId, setCreatedPatientId] = useState<string | null>(null);

  const onFirstNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const onLastNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const onEmailChange = (newValue: string, isValid: boolean) => {
    setEmail(newValue);
    setIsEmailValid(isValid);
  };

  const onPhoneChange = (value: string) => {
    setPhone(value);
  };

  const onBirthDateChange = (newValue: string, isValid: boolean) => {
    setBirthDate(newValue);
  };

  const onBirthDateBlur = (newValue: string, isValid: boolean) => {
    setBirthDate(newValue);
    setIsBirthDateValid(isValid);
  };

  const mutation = useMutation(createPatient, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      setAlert(null);
      setIsLoading(false);
      setFormState(FormState.SUCCESS);
      setCreatedPatientId(data.id);
    },
    onError: (error) => {
      setIsLoading(false);
      setFormState(FormState.INITIAL);
      setAlert(errorAlert(error as any));
    },
  });

  const formatString = 'MM-dd-yyyy';
  const luxonFormattedBirthDate = DateTime.fromFormat(birthDate, formatString);
  const apiFormattedPhoneNumber = phone.replace(/[^\d]/g, '');

  const onAddPatientButtonClick = (e: React.FormEvent) => {
    e.preventDefault();
    let apiPreferredContactMethod = '';
    if (preferredContactMethod) {
      apiPreferredContactMethod = preferredContactMethod.id;
    }

    const patientData = {
      authProvider,
      practiceId,
      firstName,
      lastName,
      email,
      phone: apiFormattedPhoneNumber,
      birthDate: toApiDateFormat(luxonFormattedBirthDate),
      preferredContactMethod: apiPreferredContactMethod,
    };
    mutation.mutate(patientData);
  };

  const onFirstNameClearButtonClick = () => {
    setFirstName('');
  };

  const onLastNameClearButtonClick = () => {
    setLastName('');
  };

  const renderSuccessUI = () => {
    return (
      <div className="flex flex-col gap-2 w-full items-center">
        <span className="font-bold text-lg">Patient Added Successfully</span>
        <span className="text-xs">
          <span
            className="font-bold underline cursor-pointer"
            onClick={() => {
              runFTrack({
                event: 'Click Patient Name Link',
                workflow: PatientWorkflow,
                action: ClickTrackingAction,
                context: 'accounting',
                componentId: 'newPatientModalNameLink',
              });
              navigate(`/patients/${createdPatientId}`);
            }}
          >{`${firstName} ${lastName} `}</span>
          has been added to the Patient Directory.
        </span>
        <div className="action-buttons justify-center flex flex-row gap-3 mt-8">
          <Button
            className={`flex flex-row gap-2 ${buttonStyle} items-center`}
            onClick={() => setFormState(FormState.SCHEDULE)}
            workflow={PatientWorkflow}
            context="createPatientForm"
            trackingLabel="Schedule Appointment Button"
          >
            <FontAwesomeIcon icon={faCalendar} style={{ color: 'white' }} />
            <span>Schedule Appointment</span>
          </Button>
          <Button
            className={buttonStyle}
            onClick={() => navigate(`/patients/${createdPatientId}/treatment-plans/new`)}
            workflow={PatientWorkflow}
            context="createPatientForm"
            trackingLabel="Build a Treatment Plan Button"
          >
            <div className="flex items-center gap-2">
              <DocumentIcon />
              <div>Build a Treatment Plan</div>
            </div>
          </Button>
          <Button
            className={cancelButtonStyle}
            onClick={onCloseClick}
            disabled={isLoading}
            workflow={PatientWorkflow}
            context="createPatientForm"
            trackingLabel="Close Create Patient Form Button"
          >
            Close
          </Button>
        </div>
      </div>
    );
  };

  const renderScheduleAppointment = () => {
    if (createdPatientId) {
      return (
        <BookAppointmentForm
          authProvider={authProvider}
          patientId={createdPatientId}
          onCloseClick={onCloseClick}
          includeActionButtons={true}
        />
      );
    }
    return;
  };

  const onOptionSelect = (selectedOption: SelectOption) => {
    if (!selectedOption) {
      setPreferredContactMethod(null);
    } else {
      setPreferredContactMethod(selectedOption);
    }
  };

  const renderCreatePatientFormFields = () => {
    return (
      <div className="flex flex-col gap-4 w-full">
        <div className="input-group flex flex-col items-start mt-2">
          <label htmlFor="first-name-input" className="first-name-label font-semibold mb-1">
            First name
          </label>
          <div className="first-name-input relative">
            <input
              title="first-name-input"
              tabIndex={1}
              onChange={onFirstNameInputChange}
              value={firstName}
              className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
              autoComplete="off"
              disabled={isLoading}
            />
            <div className="first-name-clear-button absolute right-0 -translate-y-1/2 top-1/2">
              <ClearIcon
                ref={firstNameClearButtonRef}
                noDisplay={!firstName}
                onClick={onFirstNameClearButtonClick}
                color="grey"
              />
            </div>
          </div>
        </div>
        <div className="input-group flex flex-col items-start">
          <label htmlFor="last-name-input" className="last-name-label font-semibold mb-1">
            Last name
          </label>
          <div className="last-name-input relative">
            <input
              title="last-name-input"
              tabIndex={2}
              onChange={onLastNameInputChange}
              value={lastName}
              className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
              autoComplete="off"
              disabled={isLoading}
            />
            <div className="last-name-clear-button absolute right-0 -translate-y-1/2 top-1/2">
              <ClearIcon
                ref={lastNameClearButtonRef}
                noDisplay={!lastName}
                onClick={onLastNameClearButtonClick}
                color="grey"
              />
            </div>
          </div>
        </div>
        <div className="input-group flex flex-col items-start">
          <label htmlFor="email-input" className="email-label font-semibold mb-1">
            Email
          </label>
          <div className="email-input relative">
            <ValidatedInput
              id="email-input"
              className="w-full"
              validations={[
                {
                  type: 'regex',
                  regex: emailRegex,
                  errorMessage: 'Please enter a valid email',
                },
              ]}
              onChange={onEmailChange}
              disabled={isLoading}
              allowClear
            />
          </div>
        </div>
        <div className="input-group flex flex-col items-start">
          <label htmlFor="phone-input" className="phone-label font-semibold mb-1">
            Phone number
          </label>
          <div className="phone-input relative">
            <PhoneNumberInput onChange={onPhoneChange} disabled={isLoading} />
          </div>
        </div>
        <div className="input-group flex flex-col items-start">
          <label htmlFor="birth-date-input" className="birth-date-label font-semibold mb-1">
            Birth date
          </label>
          <div className="birth-date-input relative">
            <DateInput className={'w-full'} onBlur={onBirthDateBlur} onChange={onBirthDateChange} />
          </div>
        </div>
        <div className="input-group flex flex-col items-start">
          <label className="preferred-contact-method-label font-semibold mb-1">
            Preferred contact method
          </label>
          <DropdownButton
            label={preferredContactMethod ? preferredContactMethod.label : 'Select a method'}
            options={[
              { id: 'email', label: 'Email' },
              { id: 'phone', label: 'Phone' },
              { id: 'text', label: 'Text' },
            ]}
            classNameExt={'w-full'}
            onSelect={onOptionSelect}
          />
        </div>
        <div id={'action-buttons'} className="justify-center flex flex-row gap-3 mt-8">
          <Button
            className={buttonStyle}
            onClick={onAddPatientButtonClick}
            loading={isLoading}
            disabled={isAddPatientDisabled}
            workflow={PatientWorkflow}
            context="createPatientForm"
            trackingLabel="Add Patient Button"
          >
            Add Patient
          </Button>
          <Button
            className={cancelButtonStyle}
            onClick={onCloseClick}
            disabled={isLoading}
            workflow={PatientWorkflow}
            context="createPatientForm"
            trackingLabel="Cancel Add Patient Button"
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (formState === FormState.SUCCESS) {
      return renderSuccessUI();
    }
    if (formState === FormState.SCHEDULE) {
      return renderScheduleAppointment();
    }
    return renderCreatePatientFormFields();
  };

  const isAddPatientDisabled =
    !firstName ||
    !lastName ||
    !email ||
    !phone ||
    !birthDate ||
    !preferredContactMethod ||
    !isEmailValid ||
    !isBirthDateValid;

  const buttonStyle = `bg-primary text-white font-semibold shadow-none ${
    isAddPatientDisabled ? 'cursor-default opacity-50' : 'hover:opacity-75'
  }`;

  const cancelButtonStyle =
    'border border-rule bg-white text-primary font-semibold shadow-none hover:opacity-75';

  const renderAlert = () => {
    if (alert) {
      return <Alert {...alert} />;
    }

    return null;
  };

  return (
    <form className="create-patient-form flex flex-col gap-3 rounded-lg text-secondary text-left py-8 px-3 h-full">
      {renderAlert()}
      {renderContent()}
    </form>
  );
};

export default CreatePatientForm;
