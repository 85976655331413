import React from 'react';

import { PatientReferral } from '../API/referrals';
import { toShortDateString } from '../shared/dates/dates';
import { formatPhoneNumber } from '../shared/strings';
import ReferralStatus from './ReferralStatus';

export const referralsTableColumns = [
  {
    columnKey: 'emptyColumn',
    title: '',
    widthProportion: 0.1,
    render: () => {
      return <></>;
    },
  },
  {
    columnKey: 'dateReferred',
    title: 'Date Referred',
    widthProportion: 1,
    render: (record: PatientReferral) => {
      return toShortDateString(record.createdAt);
    },
  },
  {
    columnKey: 'patient',
    title: 'Patient',
    widthProportion: 1,
    render: (record: PatientReferral) => {
      return <span>{`${record.firstName} ${record.lastName}`}</span>;
    },
  },
  {
    columnKey: 'email',
    title: 'Email',
    widthProportion: 1,
    render: (record: PatientReferral) => {
      return <div className="email-column">{record.requestEmail}</div>;
    },
  },
  {
    columnKey: 'phone',
    title: 'Phone',
    widthProportion: 1,
    render: (record: PatientReferral) => {
      return <span>{formatPhoneNumber(record.phoneNumber)}</span>;
    },
  },
  {
    columnKey: 'status',
    title: 'Status',
    widthProportion: 1,
    render: (record: PatientReferral) => {
      return <ReferralStatus referral={record} />;
    },
  },
];
