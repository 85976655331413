import { v4 as uuidv4 } from 'uuid';

import {
  Invoice,
  InvoiceItem,
  patientSavingsFromPricingQuote,
} from '../../ServiceContext/invoices';
import { isNumber } from '../../shared/math/math';
import {
  inferPricingSystemVersionFromInvoice,
  inferPricingSystemVersionFromInvoiceItem,
  PricingSystemVersion,
} from '../../shared/pricingSystem';
import { PricedCDTCode } from '../CDTs/PricingCDTCodeTable';

export function collectInvoiceItemsIntoPricedCDTCodes(
  invoiceItems: InvoiceItem[],
  dentistSpecialty: string
): PricedCDTCode[] {
  return invoiceItems.map((i) => {
    return {
      id: uuidv4(),
      cdt: i.code,
      retailPrice: i.anchorPrice || null,
      flossyPrice: i.patientPrice || i.amount,
      adjustedPrice: i.adjustedPatientPrice || null,
      description: i.text,
      specialty: dentistSpecialty,
      additionalNotes: i.additionalNotes || null,
      pricingVersion: inferPricingSystemVersionFromInvoiceItem(i),
      pricingSystemId: i.pricingSystemId || uuidv4(),
    };
  });
}

export function createTeethSubtextForInvoiceItem(i: InvoiceItem): string {
  let procedureSubtextElements: string[] = [];
  if (i.areaOfMouthCode) {
    procedureSubtextElements.push(`Area of mouth: ${i.areaOfMouthCode}`);
  }

  if (i.toothNumberCode) {
    procedureSubtextElements.push(`Tooth #: ${i.toothNumberCode}`);
  }
  if (i.toothSurfaceCode) {
    procedureSubtextElements.push(`Tooth Surface: ${i.toothSurfaceCode}`);
  }
  return procedureSubtextElements.join('; ');
}

export function extractInvoicingComponents({ invoice }: { invoice: Invoice }): {
  financingFee: number;
  flossyFee: number;
  promoAmount: number;
  refundAmount: number;
  savingsAmount: number;
  dentistPayoutTotal: number;
} {
  if (inferPricingSystemVersionFromInvoice(invoice) === PricingSystemVersion.V2) {
    return extractInvoicingComponents_v2({ invoice });
  }

  return extractInvoicingComponents_v1({ invoice });
}

function extractInvoicingComponents_v1({ invoice }: { invoice: Invoice }): {
  financingFee: number;
  flossyFee: number;
  promoAmount: number;
  refundAmount: number;
  savingsAmount: number;
  dentistPayoutTotal: number;
} {
  const { invoiceItems, total, subtotal } = invoice;

  const getPromoDiscount = () => {
    return invoiceItems
      .filter((i) => i.type === 'promo')
      .map((i) => i.amount)
      .reduce((running, current) => running + current, 0);
  };

  const getFinancingFee = () => {
    return invoiceItems
      .filter((i) => i.type === 'financingFee')
      .map((i) => i.amount)
      .reduce((running, current) => running + current, 0);
  };

  const getRefundAmount = () => {
    return invoiceItems
      .filter((i) => i.type === 'refund')
      .map((i) => i.amount)
      .reduce((running, current) => running + current, 0);
  };

  const getSavingsAmount = () => {
    return invoiceItems
      .filter((i) => i.type === 'savings')
      .map((i) => i.amount)
      .reduce((running, current) => running + current, 0);
  };

  const dentistPayoutTotal =
    invoice.invoiceItems
      .map((item) => item.dentistPayout)
      .reduce((accumulator, currentValue) => (accumulator || 0) + (currentValue || 0), 0) || 0;

  return {
    financingFee: getFinancingFee(),
    flossyFee: total - subtotal,
    promoAmount: getPromoDiscount(),
    refundAmount: getRefundAmount(),
    savingsAmount: getSavingsAmount(),
    dentistPayoutTotal,
  };
}

function extractInvoicingComponents_v2({ invoice }: { invoice: Invoice }): {
  financingFee: number;
  flossyFee: number;
  promoAmount: number;
  refundAmount: number;
  savingsAmount: number; // In cents
  dentistPayoutTotal: number;
} {
  const { invoiceItems, pricingQuote, total } = invoice;

  let promoAmount: number = 0;
  let savingsAmount: number = 0;

  if (pricingQuote) {
    if (pricingQuote.promoSummary) {
      promoAmount = pricingQuote.promoSummary.discountInCents || 0;
    }
    savingsAmount = patientSavingsFromPricingQuote(pricingQuote);
  }

  const refundAmount = invoiceItems
    .filter((i) => i.type === 'refund')
    .map((i) => i.amount)
    .reduce((running, current) => running + current, 0);

  const dentistPayoutTotal = invoiceItems
    .filter((i) => i.type === 'procedure')
    .map((i) => {
      return isNumber(i.adjustedDentistPayout) ? i.adjustedDentistPayout : i.dentistPayout || 0;
    })
    .reduce((running, current) => running + current, 0);

  return {
    financingFee: 0,
    flossyFee: total - dentistPayoutTotal,
    promoAmount,
    refundAmount,
    savingsAmount,
    dentistPayoutTotal,
  };
}

export const formatCardTypeName = (cardType: string) => {
  if (!cardType || !cardType.startsWith('cc.')) {
    return cardType;
  }
  const extractedCardName = cardType.split('.')[1];
  return extractedCardName.charAt(0).toUpperCase() + extractedCardName.slice(1).toLowerCase();
};

export function isInvoicePaidEntirelyByPromos(invoice: Invoice): boolean {
  if (!invoice.pricingQuote) {
    return false;
  }

  if (!invoice.pricingQuote.promoSummary) {
    return false;
  }

  const patientPriceFromItems = invoice.invoiceItems.reduce((acc, i) => {
    return acc + (i.patientPrice || 0);
  }, 0);

  const promoSummary = invoice.pricingQuote.promoSummary;

  console.log('promoSummary.discountInCents', promoSummary.discountInCents);
  console.log('invoice', invoice);
  console.log('patientPriceFromItems', patientPriceFromItems);
  return promoSummary.discountInCents >= patientPriceFromItems;
}
