import React, { ChangeEvent, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { BookingWorkflow } from '../../amplitude';
import { cancelAppointment } from '../../API/appointments';
import { AuthProvider } from '../../Authentication/Authentication';
import PatientProfileButton from '../../Patients/PatientDirectory/components/PatientProfileButton';
import { RootRedirect } from '../../Routing/Router';
import { Appointment } from '../../ServiceContext/appointments';
import Alert, { AlertData, errorAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import { cancellationReasonsMap } from '../../shared/cancellation';
import { toShortDateTimeString } from '../../shared/dates/dates';
import LoadingSpinner from '../../shared/LoadingSpinner';
import Modal from '../../shared/Modal/Modal';
import { navigateToAppointments } from '../../shared/routing/routing';
import { SearchSelectOption } from '../../shared/SearchSelect/SearchSelect';
import AppointmentAndPatientDetails from '../RescheduleAppointment/AppointmentAndPatientDetails';
import CancelConfirmationModalContent from './CancelConfirmationModalContent';
import CancellationInputs from './CancellationInputs';

function createReasonOptions(reasons: { [key: string]: string }): SearchSelectOption[] {
  return Object.entries(reasons).map(([id, label]) => ({
    id,
    label,
    renderContent: <div className="cancellation-reason-option py-1 pl-2">{label}</div>,
  }));
}

const reasonOptions: SearchSelectOption[] = createReasonOptions(cancellationReasonsMap);

type Props = {
  authentication: AuthProvider;
  appointment: Appointment;
};

const VerticalSeparator = () => <div className="w-px bg-base-300 mx-4 -translate-y-8"></div>;

const CancelAppointment: React.FC<Props> = ({ authentication, appointment }) => {
  const navigate = useNavigate();

  const [reason, setReason] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [didCancel, setDidCancel] = useState(false);
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [cancellationNotes, setCancellationNotes] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { appointmentId } = useParams<{ appointmentId: string }>();

  const mutation = useMutation(cancelAppointment, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      navigate(`/appointments/${appointment.id}/details?successfulCancel=true`);
      setDidCancel(true);
    },
    onError: (error) => {
      setIsLoading(false);
      setAlert(errorAlert(error as any));
    },
  });

  const returnToAppointments = useCallback(() => {
    if (!appointment) {
      return null;
    }
    navigateToAppointments({ navigate });
  }, [navigate, appointment]);

  if (!appointmentId) {
    return <RootRedirect authentication={authentication} />;
  }

  if (isLoading || (!appointment && !alert)) {
    return <LoadingSpinner />;
  }

  const onReasonChanged = (o: SearchSelectOption) => {
    setReason(o.id);
  };

  const onCancellationNotesChanged = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCancellationNotes(e.target.value);
    setCharacterCount(e.target.value.length);
  };

  const renderAlert = () => {
    if (alert) {
      return <Alert {...alert} />;
    }

    return null;
  };

  if (!appointment || !appointment.user) {
    return null;
  }

  const onCancelAppointmentClick = async () => {
    setIsModalOpen(true);
  };

  const onConfirmClicked = () => {
    if (!reason) {
      return;
    }
    mutation.mutate({
      authProvider: authentication,
      appointmentId: appointment.id,
      reason,
      cancellationNotes,
    });
  };

  const renderControlButtons = () => {
    return (
      <div id={'control-buttons'} className="flex flex-row gap-2 w-full mb-8">
        {!didCancel && (
          <Button
            id="cancel-appointment-button"
            className="bg-primary text-white font-semibold hover:opacity-75 disabled:opacity-50"
            onClick={onCancelAppointmentClick}
            ariaLabel="Cancel Appointment Button"
            disabled={!cancellationNotes || !reason}
            workflow={BookingWorkflow}
            trackingLabel="Cancel Appointment Button"
            context="cancelAppointment"
            properties={{
              reason: reason || '',
              notes: cancellationNotes,
            }}
          >
            Cancel Appointment
          </Button>
        )}
        {!didCancel && (
          <Button
            id="keep-appointment-button"
            onClick={() => {
              setAlert(null);
              returnToAppointments();
            }}
            ariaLabel="Keep Appointment Button"
            className={'border border-rule text-primary bg-white shadow-none hover:opacity-75'}
            workflow={BookingWorkflow}
            trackingLabel="Keep Appointment Button"
            context="cancelAppointment"
          >
            Keep Appointment
          </Button>
        )}
      </div>
    );
  };

  const patientName = `${appointment.user.firstName} ${appointment.user.lastName}`;
  const appointmentDescription = `${toShortDateTimeString(
    appointment.start,
    appointment.timeZone
  )} for ${patientName}`;

  const renderPatientProfileButton = () => {
    return (
      <div id={'patient-profile-button'} className={'flex ml-6 mb-8'}>
        <PatientProfileButton userId={appointment.user!.id} />
      </div>
    );
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div id={'cancel-appointment-page'} className="flex flex-col text-secondary">
      <h5 id={'title'} className="font-sans font-semibold text-2xl text-left">
        Confirm Cancellation
      </h5>
      {renderAlert()}
      <div id={'body'} className={'flex flex-col gap-2 bg-white rounded-md'}>
        <div id={'patient-name-and-status'} className={'flex items-center space-x-3 ml-6 my-6'}>
          <span className={'bg-primary rounded-2xl font-semibold text-white text-xs px-2 py-1'}>
            {appointment.status}
          </span>
          <span className="text-xl font-bold">
            {appointment.user.firstName} {appointment.user.lastName}
          </span>
        </div>
        <div id={'details-and-selectors-wrapper'} className={'flex flex-row gap-4'}>
          <div className={'flex flex-col gap-3 w-full justify-between'}>
            <AppointmentAndPatientDetails appointment={appointment} />
            {renderPatientProfileButton()}
          </div>
          <VerticalSeparator />
          <div className={'flex flex-col gap-3 w-full justify-between'}>
            <CancellationInputs
              characterCount={characterCount}
              reasonOptions={reasonOptions}
              onReasonChanged={onReasonChanged}
              onCancellationNotesChanged={onCancellationNotesChanged}
              didCancel={didCancel}
              appointmentDescription={appointmentDescription}
            />
            {renderControlButtons()}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        onConfirm={onConfirmClicked}
        canConfirm
        confirmText={'Yes, Cancel Appointment'}
        cancelText={'No, Keep Appointment'}
        canCancel
        shape="square"
        properties={{
          reason: reason || '',
          notes: cancellationNotes,
        }}
      >
        <CancelConfirmationModalContent appointment={appointment} />
      </Modal>
    </div>
  );
};

export default CancelAppointment;
