import React from 'react';

import { DetailedTreatmentPlan, TreatmentPlanItem } from '../../../API/treatmentPlans';
import { stringifyMoney } from '../../../shared/money/stringifyMoney';

type Props = {
  treatmentPlan: DetailedTreatmentPlan;
};
const CDTTable: React.FC<Props> = ({ treatmentPlan }) => {
  const getItemApplicableFlossyPrice = (item: TreatmentPlanItem) => {
    return item.adjustedPrice ?? item.patientPrice;
  };

  return (
    <table className="w-full mt-8 text-[12px]">
      <thead className="text-start border-b border-rule">
        <tr>
          <th className="pb-2">CDT Code</th>
          <th className="pb-2">Procedure</th>
          <th className="pb-2">
            Retail Price <span className="text-base-content-flossy font-bold">*</span>
          </th>
          <th className="pb-2">Flossy Price</th>
        </tr>
      </thead>
      <tbody className="text-start text-[11px]">
        {treatmentPlan.items.map((code) => (
          <tr key={code.id} className="border-b border-rule items-start">
            <td className="py-2 font-bold">{code.cdtCode}</td>
            <td className="py-2">{code.description}</td>
            <td className="py-2 text-filter-content line-through">
              {stringifyMoney(code.anchorPrice, { includeCommas: true })}
            </td>
            <td className="py-">
              {stringifyMoney(getItemApplicableFlossyPrice(code), { includeCommas: true })}
            </td>
          </tr>
        ))}
        <tr key="total" className="border-b border-rule">
          <td className="py-2"></td>
          <td className="py-2 font-bold text-end pr-16">Total Amount</td>
          <td className="py-2 font-bold text-filter-content line-through">
            {stringifyMoney(treatmentPlan.items.reduce((acc, item) => acc + item.anchorPrice, 0))}
          </td>
          <td className="py-4 font-bold">
            {stringifyMoney(
              treatmentPlan.items.reduce(
                (acc, item) => acc + getItemApplicableFlossyPrice(item),
                0
              ),
              { includeCommas: true }
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CDTTable;
