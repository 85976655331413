export type Timestamp = string;

export interface Address {
  id: string;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  name: string;
  isAccessible: boolean | null;
  timeZone: string;
  coordinate: {
    lat: number;
    lng: number;
  };
}

export interface Service {
  name: string;
  duration: number;
  feeEstimate?: { max: number; min: number };
  relatedServicesNames: string[];
}

export interface GoogleCalendarEvent {
  calendarId: string;
  eventId: string;
}

const FLOSSY_API_URL = process.env.REACT_APP_FLOSSY_API_URL;

export function apiUrl(pathname: string) {
  if (FLOSSY_API_URL === '') {
    console.error('FLOSSY_API_URL is not set');
  }
  if (pathname.startsWith('/')) {
    return `${FLOSSY_API_URL}${pathname}`;
  }
  return `${FLOSSY_API_URL}/${pathname}`;
}
