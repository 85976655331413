import './wdyr';
import './index.css';
import '@stripe/stripe-js';
import './style/custom.css';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';

import { initializeAmplitude } from './amplitude';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeSentry } from './sentry';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ErrorPage from './shared/errors/ErrorPage';

initializeAmplitude();
initializeSentry();

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <Sentry.ErrorBoundary fallback={<ErrorPage />}>
    <App />
  </Sentry.ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register();
