import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  AppointmentWorkflow,
  ClickTrackingAction,
  InstantBookWorkflow,
  runFTrack,
  Tracking,
} from '../../amplitude';
import { getNewBookings } from '../../API/appointments';
import { ErrorResponse } from '../../API/response';
import AcknowledgeButton from '../../Appointments/AppointmentDetail/components/AcknowledgeButton';
import { useAcknowledgeAppointmentMutation } from '../../Appointments/AppointmentDetail/hooks/useAcknowledgeAppointmentMutation';
import { AuthProvider } from '../../Authentication/Authentication';
import { useSelectedDentist } from '../../Dentists/SelectedDentistContext';
import { Appointment, GetAppointmentsResponse } from '../../ServiceContext/appointments';
import Alert, { errorAlert } from '../../shared/Alert';
import { toShortDateString, toShortTimeString } from '../../shared/dates/dates';
import LinkText from '../../shared/Text/LinkText';
import AppointmentTileDetails from './AppointmentTileDetails';
import AppointmentTileNotes from './AppointmentTileNotes';
import CancelButton from './CancelButton';
import { BoxArrowRight } from './Icons';
import RescheduleButton from './RescheduleButton';

type Props = {
  authProvider: AuthProvider;
};

const AppointmentRequests: React.FC<Props> = ({ authProvider }) => {
  const tracking: Tracking = {
    workflow: InstantBookWorkflow,
    context: 'newBookingsTable',
  };
  const { selectedDentistId } = useSelectedDentist();
  const queryKey = 'getNewBookings';
  const {
    isLoading: isLoadingDisplayRequests,
    error: responseError,
    data: appointmentRequestsResponse,
  } = useQuery<GetAppointmentsResponse, ErrorResponse>(queryKey, () => {
    return getNewBookings({ authProvider, page: 1, pageSize: 3 });
  });

  const queryClient = useQueryClient();

  const {
    acknowledgeAppointment,
    isLoading: acknowledgingAppointment,
    error,
  } = useAcknowledgeAppointmentMutation({
    onSuccess: (data, appointmentId) => {
      const appointments = appointmentRequestsResponse?.appointments ?? [];
      const index = appointments.findIndex((appointment) => appointment.id === appointmentId);

      const todaysAppointmentsQueryKey = `getAppointments_${selectedDentistId}`;

      runFTrack({
        event: 'Acknowledge Appointment Success',
        workflow: AppointmentWorkflow,
        action: ClickTrackingAction,
        context: 'appointmentRequests',
        componentId: 'acknowledgeAppointmentButton',
        extraProps: {
          appointmentId,
        },
      });

      if (index !== -1) {
        appointments.splice(index, 1);
        queryClient.setQueryData(queryKey, {
          appointments,
          totalCount: appointments.length,
        });
        const appointmentDate = new Date(data.start!);
        const today = new Date();
        const isToday =
          appointmentDate.getDate() === today.getDate() &&
          appointmentDate.getMonth() === today.getMonth() &&
          appointmentDate.getFullYear() === today.getFullYear();

        if (isToday) {
          const todaysAppointments = queryClient.getQueryData<GetAppointmentsResponse>(
            todaysAppointmentsQueryKey
          );
          if (todaysAppointments) {
          }
          const acknowledgedAppointment = data;
          acknowledgedAppointment.status = 'requested';
          const updatedAppointments = [
            ...(todaysAppointments?.appointments ?? []),
            acknowledgedAppointment,
          ];
          queryClient.setQueryData(todaysAppointmentsQueryKey, {
            appointments: updatedAppointments,
            totalCount: updatedAppointments.length,
          });
        }
      }

      queryClient.invalidateQueries([queryKey, todaysAppointmentsQueryKey]);
    },
    onError: (error) => {
      runFTrack({
        event: 'Acknowledge Appointment Error',
        workflow: AppointmentWorkflow,
        action: ClickTrackingAction,
        context: 'appointmentRequests',
        componentId: 'acknowledgeAppointmentButton',
        extraProps: {
          error,
        },
      });
    },
  });

  const navigate = useNavigate();

  if (responseError) {
    return (
      <div className="no-print">
        <Alert {...errorAlert(responseError)} />
      </div>
    );
  }

  if (error) {
    return (
      <div id="loading-error-alert">
        <div className="alert alert-danger" role="alert">
          {error.errorResponse.toString()}
        </div>
      </div>
    );
  }

  const renderRescheduleAction = (record: Appointment) => {
    return (
      <RescheduleButton
        appointment={record}
        disabled={!!acknowledgingAppointment}
        tracking={tracking}
      />
    );
  };

  const renderCancelAction = (record: Appointment) => {
    return (
      <CancelButton
        appointment={record}
        disabled={!!acknowledgingAppointment}
        tracking={tracking}
      />
    );
  };

  const renderAppointmentRequestsData = () => {
    if (!appointmentRequestsResponse || isLoadingDisplayRequests) {
      return <div>Loading appointment requests...</div>;
    }

    if (appointmentRequestsResponse && appointmentRequestsResponse.appointments.length === 0) {
      return (
        <div>
          <center className="italic bg-info rounded-md py-4 text-base">
            You have no new appointment requests.
          </center>
        </div>
      );
    }

    return appointmentRequestsResponse.appointments
      .filter((e) => !selectedDentistId || e.dentistId === selectedDentistId)
      .map((appointment) => (
        <div
          key={appointment.id}
          className="flex flex-row text-left justify-between mb-2 h-20 py-2 px-4 bg-white items-center rounded-lg"
        >
          <div className="appointment-info flex flex-row gap-4 items-center w-3/4">
            <div className="datetime-column flex flex-col gap-2 mr-12 w-44">
              <span className="font-bold text-sm">
                {toShortDateString(appointment.start, appointment.timeZone)}
              </span>
              <span className="time text-xs">
                {toShortTimeString(appointment.start, appointment.timeZone)}
              </span>
            </div>
            <AppointmentTileDetails appointment={appointment} />
            <AppointmentTileNotes appointment={appointment} />
          </div>
          <div className="appointment-actions flex flex-row gap-2 items-center h-[60px]">
            <AcknowledgeButton
              appointment={appointment}
              onMarkAppointmentAcknowledged={acknowledgeAppointment}
              disabled={acknowledgingAppointment}
              isLoading={acknowledgingAppointment}
            />
            {renderRescheduleAction(appointment)}
            {renderCancelAction(appointment)}
          </div>
        </div>
      ));
  };

  return (
    <div id={'appointment-requests-wrapper'} className="flex flex-col mt-8 w-full">
      <div className="label flex flex-row gap-3 items-center mb-3">
        <span className="font-semibold text-lg text-start">Latest Appointment Requests</span>
        {appointmentRequestsResponse && (
          <span className="requests-count text-sm px-2.5 py-0.5 bg-accent font-semibold text-white rounded-lg">
            {appointmentRequestsResponse.totalCount}
          </span>
        )}
        <LinkText
          className="view-all !text-base-content !text-xs !pl-0 hover:opacity-75"
          onClick={() => {
            runFTrack({
              event: 'Click View All Appointment Requests',
              workflow: AppointmentWorkflow,
              action: ClickTrackingAction,
              context: 'appointmentRequests',
              componentId: 'viewAllAppointmentRequestsButton',
              extraProps: {
                extraProps: {
                  count: appointmentRequestsResponse?.totalCount,
                },
              },
            });
            navigate(`/appointments?category=Requested`);
          }}
        >
          <div className="flex gap-2 items-center">
            <div>View All Appointment Requests</div>
            <BoxArrowRight />
          </div>
        </LinkText>
      </div>
      {renderAppointmentRequestsData()}
    </div>
  );
};

export default AppointmentRequests;
