import React from 'react';

import { Timestamp } from '../../../ServiceContext/shared';
import styledFlossy from '../../../shared/assets/app-styled-name-orange-lg.svg';
import { toShortDateString } from '../../../shared/dates/dates';
import { stringifyMoney } from '../../../shared/money/stringifyMoney';
import { formatYearMonth } from '../BookingPeriod';

type Props = {
  balancePaid: number;
  paymentDate: Timestamp;
  paymentDetailsData: {
    type: 'single-month';
    year: number;
    month: number;
  } | null;
};

const PaymentConfirmation: React.FC<Props> = ({ balancePaid, paymentDate, paymentDetailsData }) => {
  const HorizontalSeparator = () => <div className="h-px bg-base-300 my-4 w-[70%]"></div>;
  return (
    <div className="content-wrapper flex flex-col gap-2 items-center text-secondary bg-white w-1/3 rounded-md py-12">
      <div className="header flex flex-col gap-2 items-center">
        <img
          src={styledFlossy}
          id="app-styled-name"
          alt="flossy-name"
          className="h-[62px] w-[200px] pt-2 pl-4 opacity-75"
        />

        <span className="text-sm font-bold">Thank you for your payment!</span>
      </div>
      <HorizontalSeparator />
      <span className="text-xs text-left mx-24 mb-2">
        This is a confirmation that we have just received your secure online payment. Thank you for
        your trust in Flossy. Log in to your Flossy account to view your Bookings and Credits
        information.
      </span>
      <div className="amount-wrapper flex flex-col gap-1 border border-rule bg-base-200 rounded-md w-[70%] py-16">
        <span className={'text-lg'}>{`${formatYearMonth(paymentDetailsData)} Balance`}</span>
        <span className="text-xl font-bold">
          {stringifyMoney(balancePaid, { includeCommas: true })}
        </span>
        {paymentDate && (
          <span className="text-xs">{`Paid on ${toShortDateString(paymentDate)}`}</span>
        )}
      </div>
    </div>
  );
};

export default PaymentConfirmation;
