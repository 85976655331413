import { useCallback, useMemo } from 'react';
import { NavigateFunction, NavigateOptions, useLocation, useNavigate } from 'react-router-dom';

import Cookies from '../cookies/cookies';

export function useURLQuery() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const getQuery = useCallback(
    (key: string) => {
      return query.get(key);
    },
    [query]
  );

  // passing empty would make an empty string (like 'http://url.com?empty=&anotherEmpty=')
  // passing undefined or null completely removes the param
  const setQueryString = useCallback(
    (
      keyValues: {
        [key: string]: string | null | undefined;
      },
      options?: NavigateOptions
    ): { pathname: string; search: string } => {
      const params = new URLSearchParams();
      Object.keys(keyValues).forEach((k) => {
        const value = keyValues[k];
        if (value) {
          params.set(k, value);
        } else if (value === '') {
          params.set(k, '');
        } else {
          params.delete(k);
        }
      });

      const navValue = {
        pathname,
        search: '?' + params.toString(),
      };

      navigate(navValue, options);

      return navValue;
    },
    [navigate, pathname]
  );

  const updateQueryString = useCallback(
    (
      newParam: {
        [key: string]: string | null | undefined;
      },
      options?: NavigateOptions
    ): { pathname: string; search: string } => {
      let currentQuery: any = {};
      for (const [key, value] of query.entries()) {
        currentQuery[key] = value;
      }
      return setQueryString(Object.assign(currentQuery, newParam), options);
    },
    [setQueryString, query]
  );

  const clearQuery = useCallback(() => {
    setQueryString({}, { replace: true });
  }, [setQueryString]);

  return {
    query,
    getQuery,
    setQuery: setQueryString,
    updateQuery: updateQueryString,
    clearQuery,
  };
}

export function navigateToAppointments({ navigate }: { navigate: NavigateFunction }) {
  const rememberedSearchParams = Cookies.appointmentSearchMemory.get();
  if (rememberedSearchParams) {
    navigate(rememberedSearchParams);
  } else {
    navigate(`/appointments`);
  }
}

export function redirectToExternalUrl(
  url: string,
  options?: { preservePathAndQuery: boolean }
): void {
  if (options && options.preservePathAndQuery) {
    const path = window.location.pathname;
    const search = window.location.search;
    window.location.href = `${url}${path}${search}`;
  } else {
    window.location.href = url;
  }
}

export function generateFullURL(path: string) {
  return `${window.location.origin}${path}`;
}
