import * as amplitude from '@amplitude/analytics-browser';

import {
  Dentist,
  isDentist,
  isOfficeManager,
  isRegionalManager,
  Manager,
} from './ServiceContext/user';
import { toTitleCase } from './shared/strings';

export function initializeAmplitude() {
  if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, undefined, {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true,
      },
    });
  }
}

export function setAmplitudeUser(user: Dentist | Manager | undefined) {
  if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
    if (user) {
      amplitude.setUserId(user.id);

      const role = user.isFlossyAdmin
        ? 'Admin'
        : isRegionalManager(user)
        ? 'Regional Manager'
        : isOfficeManager(user)
        ? 'Office Manager'
        : isDentist(user)
        ? 'Dentist'
        : 'Unknown'; // This should never happen

      const identify = new amplitude.Identify();

      identify.setOnce('email', user.email);
      identify.setOnce('type', user.type);
      identify.setOnce('role', role);

      amplitude.identify(identify);
    } else {
      amplitude.setUserId(undefined);

      const identify = new amplitude.Identify();
      identify.clearAll();
      amplitude.identify(identify);
    }
  }
}

function reportUserIsActive() {
  const identify = new amplitude.Identify();
  identify.set('lastActiveAt', new Date().toISOString());
  amplitude.identify(identify);
}

export const InstantBookWorkflow = 'Instant Booking';
export const InvoicingWorkflow = 'Invoicing';
export const EstimationWorkflow = 'Estimation';
export const TreatmentPlanWorkflow = 'Treatment Plan';
export const BookingWorkflow = 'Booking';
export const NotificationsWorkflow = 'Notifications';
export const AccountingWorkflow = 'Accounting';
export const BookingsAndCreditsWorkflow = 'Bookings and Credits';
export const AuthenticationWorkflow = 'Authentication';
export const ShareInvoiceWorkflow = 'Share Invoice';
export const AppointmentWorkflow = 'Appointment';
export const PayoutsWorkflow = 'Payouts';
export const RescheduleAppointmentWorkflow = 'Reschedule Appointment';
export const InsuranceWorkflow = 'Insurance';
export const AvailabilityWorkflow = 'Availability';
export const GeneralWorkflow = 'General';
export const ReferralWorkflow = 'Referral';
export const PatientWorkflow = 'Patient';

// TODO: I'll be happy to delete this once we can delete the scourge of a workflow that is public invoicing.
export const PublicInvoicingWorkflow = 'Public Invoicing';

export type Workflow =
  | typeof InstantBookWorkflow
  | typeof InvoicingWorkflow
  | typeof EstimationWorkflow
  | typeof TreatmentPlanWorkflow
  | typeof BookingWorkflow
  | typeof NotificationsWorkflow
  | typeof PublicInvoicingWorkflow
  | typeof AccountingWorkflow
  | typeof BookingsAndCreditsWorkflow
  | typeof AuthenticationWorkflow
  | typeof ShareInvoiceWorkflow
  | typeof AppointmentWorkflow
  | typeof PayoutsWorkflow
  | typeof RescheduleAppointmentWorkflow
  | typeof InsuranceWorkflow
  | typeof AvailabilityWorkflow
  | typeof GeneralWorkflow
  | typeof ReferralWorkflow
  | typeof PatientWorkflow;

export const ViewTrackingAction = 'view';
export const ClickTrackingAction = 'click';
export const ToggleTrackingAction = 'toggle';

// Use this when you want to track something that is not a direct user action.
export const SideEffectTrackingAction = 'sideEffect';

export type TrackingAction =
  | typeof ViewTrackingAction
  | typeof ClickTrackingAction
  | typeof SideEffectTrackingAction
  | typeof ToggleTrackingAction;

export type Tracking = {
  // File location
  context?: string;
  workflow: Workflow;
  properties?: Record<string, string>;
};

export type TrackedComponent = Tracking & {
  // Title Case label used in Amplitude Event
  trackingLabel: string;
};

export function updateContext(t: Omit<Tracking, 'context'>, context: string): Tracking {
  return {
    ...t,
    context,
  };
}

type CallbackFn = (fn?: () => void) => () => void;

/**
 * Event names should be in Title Case and should be a verb
 * Event names do not need to include the workflow name as it is added automatically
 */
export function runFTrack({
  event,
  workflow,
  action,
  context,
  componentId,
  extraProps,
}: {
  event: string;
  workflow: Workflow;
  action?: TrackingAction;
  context?: string;
  componentId?: string;
  extraProps?: Record<string, any>;
}) {
  fTrack(event, workflow, action, context, componentId, extraProps)()();
}

/**
 * This function is used to track user actions in the app.
 * @param event The event that you want to track. This is the name of the event that will be sent to Amplitude.
 * @param workflow The workflow that the action is part of. Stored as a property on the event.
 * @param action The action that the user took.
 * @param context The context of the action. This is used to provide more information about the action.
 * @param componentId The id of the component that the action was taken on.
 * @param extraProps Any extra properties that you want to track.
 */
export function fTrack(
  event: string,
  workflow: Workflow,
  action?: TrackingAction,
  context?: string,
  componentId?: string,
  extraProps?: Record<string, any>
): CallbackFn {
  return (fn?: () => void) => {
    return () => {
      if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
        reportUserIsActive();
        if (workflow && action && componentId) {
          amplitude.track(`${toTitleCase(event)} - ${workflow}`, {
            // ex. Press Dismiss Notification Button - Notifications
            workflow: workflow,
            action: action,
            context: context,
            componentId: componentId,
            ...extraProps,
          });
        }
      }
      if (fn) {
        fn();
      }
    };
  };
}
