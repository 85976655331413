import React from 'react';

import { DetailedTreatmentPlan } from '../../../API/treatmentPlans';
import SavingsSummary from '../../../Appointments/Invoicing/SavingsSummary';
import { patientSavingsFromPricingQuote } from '../../../ServiceContext/invoices';
import logo from '../../../shared/assets/app-logo.svg';
import styledFlossy from '../../../shared/BrandedLoadingPage/app-styled-name-orange-lg.svg';
import BasicInfo from './BasicInfo';
import CDTTable from './CDTTable';
import TermsConditionsSignature from './TermsConditionsSignature';

const TreatmentPlanPrintout = React.forwardRef<
  HTMLDivElement,
  { treatmentPlan: DetailedTreatmentPlan }
>(({ treatmentPlan }, ref) => {
  return (
    <div className="print: h-screen flex flex-col items-start w-full p-12" ref={ref}>
      <div className="branding-row flex flex-row gap-2 justify-center w-full h-[32px]">
        <img className="w-[30px]" src={logo} id="app-branding" alt="flossy-logo" />
        <img src={styledFlossy} className="w-[90px] h-auto" id={'styled-name'} alt="flossy-name" />
      </div>
      {<BasicInfo treatmentPlan={treatmentPlan} />}
      {<CDTTable treatmentPlan={treatmentPlan} />}
      <div className="py-3 border-b border-rule w-full text-start text-[10px]">
        <span className="text-base-content font-bold">Additional Notes: </span>
        <span className="text-base-content font-normal">
          {treatmentPlan.notes
            ? treatmentPlan.notes
            : 'Additional notes made by office manager or dentist will be listed here.'}
        </span>
      </div>
      <div className="w-full text-start pt-3 pb-12 text-[10px]">
        <span className="text-base-content-flossy font-bold">*</span>
        <span className="text-base-content font-normal">
          {' '}
          The fee dentists most frequently charge for this procedure.
        </span>
      </div>
      {treatmentPlan.pricingQuote && (
        <SavingsSummary
          savingsAmount={patientSavingsFromPricingQuote(treatmentPlan.pricingQuote)}
          savingsPercentage={treatmentPlan.pricingQuote?.realizedPatientSavings}
        />
      )}
      <div className="grow"></div>
      <TermsConditionsSignature treatmentPlan={treatmentPlan} />
    </div>
  );
});

export default TreatmentPlanPrintout;
