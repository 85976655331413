import { DateTime } from 'luxon';

import { Appointment } from '../ServiceContext/appointments';

const statusColors = {
  requested: 'bg-appointment-requested',
  scheduled: 'bg-appointment-scheduled',
  completed: 'bg-appointment-completed',
  unprocessed: 'bg-appointment-unprocessed',
  cancelled: 'bg-appointment-canceled',
};

const statusTexts = {
  requested: 'Requested',
  scheduled: 'Scheduled',
  completed: 'Completed',
  unprocessed: 'Unprocessed',
  cancelled: 'Canceled',
};

function getStatusString(
  appointment: Appointment
): 'requested' | 'scheduled' | 'completed' | 'unprocessed' | 'cancelled' {
  if (appointment.status === 'cancelled') {
    return 'cancelled';
  }

  if (appointment.status === 'completed') {
    return getCompletedStatus(appointment);
  }

  const isInFuture = appointment.start > DateTime.local().toUTC().toString();
  if (isInFuture) {
    return getScheduledStatus(appointment.acknowledgedByDentistAt);
  } else {
    return 'unprocessed';
  }
}

function getCompletedStatus(appointment: Appointment): 'unprocessed' | 'completed' {
  if (
    !appointment.invoice ||
    appointment.invoice.status === 'overdue' ||
    appointment.invoice.status === 'due' ||
    appointment.invoice.status === 'partially_paid'
  ) {
    return 'unprocessed';
  }

  return 'completed';
}

function getScheduledStatus(acknowledgedByDentistAt: string | null): 'requested' | 'scheduled' {
  if (acknowledgedByDentistAt) {
    return 'scheduled';
  } else {
    return 'requested';
  }
}

export function getColorFromStatus(appointment: Appointment): string {
  const statusString = getStatusString(appointment);

  return statusColors[statusString];
}

export function getStatusFromAppointment(appointment: Appointment): string {
  const statusString = getStatusString(appointment);

  return statusTexts[statusString];
}
