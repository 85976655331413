import React from 'react';

import { Appointment } from '../../ServiceContext/appointments';
import { toShortDateTimeString } from '../../shared/dates/dates';

type Props = {
  appointment: Appointment;
};
const CancelConfirmationModalContent: React.FC<Props> = ({ appointment }) => {
  if (!appointment.user) {
    return null;
  }
  return (
    <div id={'cancel-confirmation-modal-content'} className={'flex flex-col gap-3'}>
      <span className="font-semibold text-secondary text-lg">
        Are you sure you want to cancel this appointment?
      </span>
      <div
        className={
          'flex flex-col gap-1.5 border border-rule rounded-lg bg-blue-50 p-2 text-neutral'
        }
      >
        <span className={'text-sm font-semibold'}>
          {appointment.user.firstName} {appointment.user.lastName}
        </span>
        <span className={'text-xs'}>{`${toShortDateTimeString(
          appointment.start,
          appointment.timeZone
        )} with ${appointment.dentist.firstName} ${appointment.dentist.lastName}`}</span>
      </div>
    </div>
  );
};

export default CancelConfirmationModalContent;
