import React, { ReactNode, useEffect } from 'react';

interface ForceRefreshProps {
  children: ReactNode;
}

const ForceRefresh: React.FC<ForceRefreshProps> = ({ children }) => {
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'refreshNeeded' && event.newValue === 'true') {
        window.location.reload();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return <>{children}</>;
};

export default ForceRefresh;

export function forceRefreshAllTabs(): void {
  localStorage.setItem('refreshNeeded', 'true');
}
