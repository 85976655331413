import { AuthProvider } from '../Authentication/Authentication';

export function isDentistInManagedList({
  authProvider,
  dentistId,
}: {
  authProvider: AuthProvider;
  dentistId: string;
}): boolean {
  if (!authProvider.managedDentists) {
    return false;
  }

  return authProvider.managedDentists.map((d) => d.id).includes(dentistId);
}
