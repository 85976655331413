import React, { useState } from 'react';
import { useMutation } from 'react-query';

import { ReferralWorkflow } from '../amplitude';
import { QueryContext } from '../API/context';
import { PatientReferral, updateReferral } from '../API/referrals';
import { AuthProvider } from '../Authentication/Authentication';
import Alert, { AlertData, errorAlert } from '../shared/Alert';
import Button from '../shared/Button/Button';
import { emailRegex } from '../shared/strings/stringValidation';
import ValidatedInput from '../shared/ValidatedInput/ValidatedInput';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  referredPatientFirstName: string;
  referredPatientLastName: string;
  onCloseClick: () => void;
  requestEmail: string;
  referralId: string;
  onReferralResent: (resentReferral: PatientReferral) => void;
  queryContext: QueryContext;
};

const ResendReferralModalContent: React.FC<Props> = ({
  authProvider,
  practiceId,
  referredPatientFirstName,
  referredPatientLastName,
  onCloseClick,
  requestEmail,
  referralId,
  onReferralResent,
  queryContext,
}) => {
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [email, setEmail] = useState(requestEmail);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const updateReferralMutation = useMutation(updateReferral, {
    onMutate: () => {},
    onSuccess: (resentReferral) => {
      onReferralResent(resentReferral);

      const currentData = queryContext.client.getQueryData<PatientReferral[]>(queryContext.key);

      if (currentData) {
        queryContext.client.setQueryData(
          queryContext.key,
          currentData.map((referral) =>
            referral.id === resentReferral.id ? resentReferral : referral
          )
        );
      }
    },
    onError: (error) => {
      setAlert(errorAlert(error as any));
    },
  });

  const onResendClick = () => {
    updateReferralMutation.mutate({
      authProvider,
      practiceId,
      referralId,
      newEmail: email,
    });
  };
  const onReferralEmailChange = (newValue: string, isValid: boolean) => {
    setEmail(newValue);
    setIsEmailValid(isValid);
  };

  return (
    <div id="content-wrapper" className="flex flex-col gap-2 text-secondary mr-2">
      {alert && <Alert {...alert} />}
      <span id="title" className="font-bold text-xl">
        Resend Referral Email
      </span>
      <span className="text-xs">
        A referral email will be sent to
        <span className="font-bold">{` ${referredPatientFirstName} ${referredPatientLastName} `}</span>
        at the email address below. You may edit the email if this address is incorrect:{' '}
      </span>
      <span className="font-bold text-xs mt-3">Email Address</span>
      <ValidatedInput
        id="referral-email-input"
        className="w-full pr-2 mb-3 text-xs"
        validations={[
          {
            type: 'regex',
            regex: emailRegex,
            errorMessage: 'Please enter a valid email',
          },
        ]}
        onChange={onReferralEmailChange}
        defaultInputValue={email}
        allowClear
      />
      <div id={'buttons'} className={'flex flex-row gap-2 mb-2'}>
        <Button
          onClick={onResendClick}
          disabled={!isEmailValid || !email}
          className={'bg-primary text-white text-xs font-semibold hover:bg-opacity-75'}
          workflow={ReferralWorkflow}
          trackingLabel="Resend Referral Email Button"
          context="resendReferralModalContent"
        >
          Resend Referral Email
        </Button>
        <Button
          onClick={onCloseClick}
          className={
            'border border-rule text-xs text-semibold bg-white text-primary hover:opacity-75'
          }
          workflow={ReferralWorkflow}
          trackingLabel="Cancel Button"
          context="resendReferralModalContent"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default ResendReferralModalContent;
