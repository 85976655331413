import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthenticationWorkflow } from '../amplitude';
import { AuthProvider } from '../Authentication/Authentication';
import Alert, { AlertData, errorAlert, warningAlert } from '../shared/Alert';
import Button from '../shared/Button/Button';
import ClearIcon from '../shared/ClearIcon';
import { beautifyErrorMessage } from '../shared/errors/errorMessage';
import { emailRegex } from '../shared/strings/stringValidation';
import ValidatedInput from '../shared/ValidatedInput/ValidatedInput';

type Props = {
  authentication: AuthProvider;
};

const Login: React.FC<Props> = ({ authentication }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getInitialAlert = () => {
    const showResetPasswordAlert = location.state && location.state.didResetPassword;
    const setInstructionsToEmail = !!location.state ? location.state.sentInstructionsTo : '';
    if (showResetPasswordAlert) {
      return warningAlert(
        `An email has been sent to ${setInstructionsToEmail} with further instructions.`
      );
    }
    return null;
  };

  const passwordClearButtonRef = useRef<SVGSVGElement>(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState<AlertData | null>(getInitialAlert());
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLogInEmailValid, setIsLogInEmailValid] = useState(false);

  useEffect(() => {
    if (location.state && location.state.didResetPassword && location.state.sentInstructionsTo) {
      const clearedState = location.state;
      clearedState.didResetPassword = false;
      clearedState.sentInstructionsTo = '';
      navigate(location, { state: undefined });
    }
  }, [navigate, location]);

  const onLogin = async () => {
    if (isLoggingIn) {
      return;
    }

    try {
      setIsLoggingIn(true);
      await authentication.logIn(email, password);

      setIsLoggingIn(false);
    } catch (err: unknown) {
      if (err instanceof Error) {
        setAlert(errorAlert(beautifyErrorMessage(err.message)));
        setIsLoggingIn(false);
        return;
      }
    }
  };

  const onEmailChange = (newValue: string, isValid: boolean) => {
    setEmail(newValue);
    setIsLogInEmailValid(isValid);
    setAlert(null);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setAlert(null);
  };

  const renderAlert = () => {
    if (alert) {
      return <Alert {...alert} />;
    }

    return null;
  };

  const onPasswordFieldKeyDown = async (target: React.KeyboardEvent) => {
    if (target.key === 'Enter') {
      await onLogin();
    }
  };

  const onForgotPasswordClicked = () => {
    navigate('/reset-password');
  };
  const onPasswordClearButtonClick = () => {
    setPassword('');
  };

  return (
    <div id="login" className="login flex flex-col items-start w-full mt-2">
      {renderAlert()}
      <div className="field flex flex-col items-start w-1/3">
        <label htmlFor="login-page-email-input" className="email-label text-[#5a5a5a] pb-2">
          Email Address
        </label>
        <div className="email-input-field w-full">
          <ValidatedInput
            id="login-page-email-input"
            className="w-full"
            validations={[
              {
                type: 'regex',
                regex: emailRegex,
                errorMessage: 'Please enter a valid email',
              },
            ]}
            onChange={onEmailChange}
            allowClear
          />
        </div>
      </div>
      <div className="field flex flex-col items-start w-1/3 mt-3">
        <label htmlFor="login-page-password-input" className="password-label text-[#5a5a5a] pb-2">
          Password
        </label>
        <div className="password-input-field w-full relative">
          <input
            id="login-page-password-input"
            type="password"
            onChange={onPasswordChange}
            onKeyPress={onPasswordFieldKeyDown}
            className="border h-10 w-full rounded-lg pl-3 text-base-content focus:outline-blue-200"
            value={password}
          />
          <div className="password-clear-button absolute right-0 -translate-y-1/2 top-1/2">
            <ClearIcon
              ref={passwordClearButtonRef}
              noDisplay={!password}
              onClick={onPasswordClearButtonClick}
              color="grey"
            />
          </div>
        </div>
      </div>
      <div id="login-page-actions-row" className="pt-5 flex flex-row">
        <Button
          id="login-button"
          onClick={onLogin}
          loading={isLoggingIn}
          omitBorder
          disabled={!email || !password || !isLogInEmailValid}
          workflow={AuthenticationWorkflow}
          context="login"
          trackingLabel="Login Button"
        >
          Login
        </Button>
        <Button
          id="forgot-password-button"
          className="forgot-password-button ml-4"
          onClick={onForgotPasswordClicked}
          noFill
          workflow={AuthenticationWorkflow}
          context="login"
          trackingLabel="Forgot Password Button"
        >
          Forgot Password?
        </Button>
      </div>
    </div>
  );
};

export default Login;
