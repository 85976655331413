import { DateTime } from 'luxon';

export type BookingPeriod = {
  apiFriendlyForm: string;
  displayForm: string;
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function bookingPeriodFromAPIFriendlyForm(apiFriendlyForm: string): BookingPeriod {
  // Split the apiFriendlyForm into year and month.
  const [year, month] = apiFriendlyForm.split('-').map(Number);

  // Convert the month number to a month name; Subtract 1 because array indices are 0-based, while month numbers are
  // 1-based.
  const monthName = monthNames[month - 1];

  return {
    displayForm: `${monthName} ${year}`,
    apiFriendlyForm: apiFriendlyForm,
  };
}

export function parseBookingPeriodKey(bookingPeriodKey: string): BookingPeriod {
  const [year, month] = bookingPeriodKey.split('-').map(Number);

  // Luxon months are 1-based.
  const monthName = monthNames[month - 1];

  return {
    displayForm: `${monthName} ${year}`,
    apiFriendlyForm: bookingPeriodKey,
  };
}

export const formatYearMonth = (
  yearMonthObject: { year: number; month: number } | null
): string => {
  if (!yearMonthObject) {
    return '';
  }
  const { year, month } = yearMonthObject;
  const date = new Date(year, month - 1);
  const formattedDate = date.toLocaleDateString('default', { month: 'long', year: 'numeric' });
  return formattedDate;
};

export function utcTimestampToBookingPeriod(time: string): BookingPeriod {
  const chargeTimeToDateTime = DateTime.fromISO(time, { zone: 'utc' });

  // Luxon months are 1-based
  const month = monthNames[chargeTimeToDateTime.month - 1];

  const year = chargeTimeToDateTime.year;

  return {
    displayForm: `${month} ${year}`,
    apiFriendlyForm: `${year}-${String(chargeTimeToDateTime.month).padStart(2, '0')}`,
  };
}

export function beginningAndEndOfBookingPeriod(bookingPeriod: BookingPeriod): {
  start: string;
  end: string;
} {
  const [year, month] = bookingPeriod.apiFriendlyForm.split('-').map(Number);

  const startOfMonth = DateTime.local(year, month, 1);

  const endOfMonth = startOfMonth.endOf('month');

  return {
    start: startOfMonth.toISODate() || 'Invalid Date',
    end: endOfMonth.toISODate() || 'Invalid Date',
  };
}
