import React from 'react';

import { ManagerNotificationSetupExplanation } from '../../ServiceContext/managers';
import { Message, MessageType } from '../../ServiceContext/messages';
import { NotificationMessageRow, renderMessageType } from './common';

type Props = {
  explanation: ManagerNotificationSetupExplanation;
};

const ExplainManagerNotificationsSetup: React.FC<Props> = ({ explanation }) => {
  const { registeredEvents, trackedDentists, recentNotifications } = explanation;

  return (
    <div className="p-4 flex flex-column items-start">
      <h2 className="text-xl font-bold mb-4">Manager Notification Setup Explanation</h2>
      <h3 className="text-lg font-semibold mb-2">Registered Events</h3>
      <p className="italic">
        These are the types of events that this manager has registered to receive notifications for.
      </p>
      <ul className="list-disc pl-6 mb-4 text-left">
        {registeredEvents.map((event: MessageType, index: number) => (
          <li key={index}>{renderMessageType(event)}</li>
        ))}
      </ul>
      <h3 className="text-lg font-semibold mb-2">Tracked Dentists</h3>
      <p className="italic">
        These are the dentists for whom this manager will receive notifications for the registered
        events.
      </p>
      <ul className="list-disc pl-6 mb-4 text-left">
        {trackedDentists.map((dentist: string, index: number) => (
          <li key={index}>{dentist}</li>
        ))}
      </ul>
      <h3 className="text-lg font-semibold mb-2">Recent Notifications</h3>
      <p className="italic">
        These are the notifications that this manager has received in the last 7 days
      </p>
      <table className="table-auto w-full mb-4">
        <thead>
          <tr className="text-left font-semibold bg-base-100">
            <th>Type</th>
            <th>Target</th>
            <th>Patient Name</th>
            <th>Dentist Name</th>
            <th>Appt Start</th>
            <th>Appt ID</th>
            <th>Sent At</th>
          </tr>
        </thead>
        <tbody>
          {recentNotifications.map((message: Message, index: number) => (
            <NotificationMessageRow key={index} message={message} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExplainManagerNotificationsSetup;
