import React from 'react';

import { ErrorResponse } from '../API/response';
import Alert, { errorAlert } from './Alert';

type Props = {
  isLoading: boolean;
  error: ErrorResponse | null;
};

const StandardizedLoadingPage: React.FC<Props> = ({ isLoading, error }) => {
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <Alert {...errorAlert(error)} />;
  }
  return null;
};

export default StandardizedLoadingPage;
