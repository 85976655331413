import { faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { Practice } from '../ServiceContext/user';
import { stringifyMoney } from '../shared/money/stringifyMoney';

type Props = {
  practice: Practice;
};
const ReferralPromo: React.FC<Props> = ({ practice }) => {
  return (
    <div
      id="promo"
      className="flex flex-row gap-4 bg-white w-full items-center rounded-md py-4 pl-6"
    >
      <FontAwesomeIcon
        icon={faCircleDollarToSlot}
        style={{
          color: 'lightslategray',
          opacity: '70%',
          scale: '150%',
        }}
      />
      <div className="flex flex-col gap-2 items-start">
        <span className="font-bold text-sm">Refer patients to Flossy & earn Credits!</span>
        {practice.referralCreditAmount && (
          <span className="text-xs">
            You will receive
            <span className="font-bold">{` ${stringifyMoney(practice.referralCreditAmount, {
              includeCommas: true,
            })} in Flossy Credits `}</span>
            for every patient you refer who books an appointment with any Flossy provider.
          </span>
        )}
      </div>
    </div>
  );
};

export default ReferralPromo;
