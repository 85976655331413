import React, { PropsWithChildren, useCallback } from 'react';

import WithDocumentClickListener from './WithDocumentClickListener';

type Props = {
  showing: boolean;
  xOffset: number;
  yOffset: number;
  overlayComponent: React.ReactNode;
  onMaskClick?: () => void;
  componentContainerCustom?: string;
  overlayPlatformCustom?: string;
  height?: string;
};

const WithOverlay: React.FC<PropsWithChildren<Props>> = ({
  showing,
  children,
  xOffset,
  yOffset,
  overlayComponent,
  onMaskClick,
  componentContainerCustom,
  overlayPlatformCustom,
  height,
}) => {
  const onDocumentClick = useCallback(
    (e: MouseEvent) => {
      if (!e.target) {
        return;
      }
      if (document.getElementById('component-container')?.contains(e.target as Node)) {
        return;
      } else {
        if (onMaskClick) {
          onMaskClick();
        }
      }
    },
    [onMaskClick]
  );

  let componentContainerClassname =
    'component-container rounded-lg shadow-lg absolute z-10 bg-white py-1 mr-4 ';
  if (componentContainerCustom) {
    componentContainerClassname += componentContainerCustom;
  }
  if (height) {
    componentContainerClassname += height;
  }

  let overlayPlatformClassname = 'overlay-content-platform relative h-4/5';
  if (overlayPlatformCustom) {
    overlayPlatformClassname = `${overlayPlatformCustom} ${overlayPlatformClassname}`;
  }

  return (
    <div id="overlay-content-platform" className={overlayPlatformClassname}>
      {showing && (
        <WithDocumentClickListener onClick={onDocumentClick}>
          <div
            className={componentContainerClassname}
            style={{
              top: yOffset,
              right: xOffset,
            }}
          >
            {overlayComponent}
          </div>
        </WithDocumentClickListener>
      )}
      {children}
    </div>
  );
};

export default WithOverlay;
