import { useMutation } from 'react-query';

import { BookingsAndCreditsWorkflow } from '../../../amplitude';
import { testOnly_deletePracticePayments } from '../../../API/practice';
import { AuthProvider } from '../../../Authentication/Authentication';
import Button from '../../../shared/Button/Button';
import { Environment } from '../../../shared/environment';

export default function useTestOnlyClearBookingsAndCreditsPaymentHistory({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
}): {
  component: React.ReactNode;
} {
  const deletePracticePaymentsMutation = useMutation(testOnly_deletePracticePayments, {
    onError: (error) => {
      console.log('Error deleting practice payments', error);
    },
  });

  if (!Environment.allowTestOnlyFeatures()) {
    return {
      component: null,
    };
  }

  return {
    component: (
      <Button
        onClick={() => deletePracticePaymentsMutation.mutate({ authProvider, practiceId })}
        color="danger"
        className="w-full"
        workflow={BookingsAndCreditsWorkflow}
        context="TestOnlyClearBookingsAndCreditsPaymentHistory"
        trackingLabel="Delete Practice Payments Button"
      >
        TEST ONLY - Delete Practice Payments
      </Button>
    ),
  };
}
