import React from 'react';

import { stringifyMoney } from '../../shared/money/stringifyMoney';
import { formatNumberAsPercentage } from '../../shared/strings';

type Props = {
  savingsAmount: number;
  savingsPercentage?: number;
};

const SavingsSummary: React.FC<Props> = ({ savingsAmount, savingsPercentage }) => {
  if (savingsAmount <= 0) return null;

  return (
    <div className="flossy-savings-row flex flex-row justify-center w-full p-0">
      <span className="text-center text-lg font-bold text-secondary">
        You saved{' '}
        <span className="savings-amount font-extrabold text-lg text-base-content-flossy">
          {`${stringifyMoney(savingsAmount, { includeCommas: true })} ${
            savingsPercentage ? `(${formatNumberAsPercentage(savingsPercentage / 100)})` : ''
          }`}
        </span>{' '}
        booking with Flossy!
      </span>
    </div>
  );
};

export default SavingsSummary;
