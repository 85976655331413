import React, { ChangeEvent } from 'react';

import SearchSelect, { SearchSelectOption } from '../../shared/SearchSelect/SearchSelect';

type Props = {
  characterCount: number;
  reasonOptions: SearchSelectOption[];
  onReasonChanged: (o: SearchSelectOption) => void;
  onCancellationNotesChanged: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  didCancel: boolean;
  appointmentDescription: string;
};
const CancellationInputs: React.FC<Props> = ({
  characterCount,
  reasonOptions,
  onReasonChanged,
  onCancellationNotesChanged,
  didCancel,
  appointmentDescription,
}) => {
  const characterLimit = 500;
  return (
    <div id={'cancellation-inputs'} className={'flex flex-col gap-3 text-left w-[80%]'}>
      {!didCancel && (
        <span className="info font-bold text-left mt-3">
          You are cancelling appointment: {appointmentDescription}
        </span>
      )}
      <div className={'flex flex-col gap-2 mb-1'}>
        <span className={'font-semibold text-xs'}>Select cancellation reason</span>
        <SearchSelect
          id="cancellation-reason-dropdown"
          options={reasonOptions}
          onChange={onReasonChanged}
          placeholder="Choose a reason"
          allowClear={false}
          componentContainerCustomClass="w-full"
          includeAngle
        />
      </div>
      <div className={'flex flex-col gap-2'}>
        <span className={'font-semibold text-xs'}>Cancellation Details</span>
        <textarea
          className="cancellation-notes rounded-md p-3 text-xs border border-rule focus:outline-blue-200 pt-2"
          placeholder="Please provide more details on why you are cancelling this appointment."
          rows={4}
          maxLength={characterLimit}
          onChange={onCancellationNotesChanged}
        ></textarea>
        <span className={'text-left text-xs mt-2 text-base-content'}>
          {`${
            characterCount >= characterLimit ? 0 : characterLimit - characterCount
          } characters left`}
        </span>
      </div>
    </div>
  );
};

export default CancellationInputs;
