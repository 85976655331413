import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getAllPayoutsForDentist, getAllPayoutsForPractice, Payout } from '../../API/payouts';
import { ErrorResponse } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import { useSelectedDentist } from '../../Dentists/SelectedDentistContext';

export const useAPI_pastPayouts = ({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
}): {
  pastPayouts: Payout[] | undefined;
  isLoading: boolean;
  error: ErrorResponse | undefined;
} => {
  const { selectedDentistId } = useSelectedDentist();
  const isViewingAllDentists = !selectedDentistId;

  const getAllPayoutsForDentistKey = useMemo(
    () => ['getAllPayoutsForDentist', selectedDentistId],
    [selectedDentistId]
  );

  const {
    isLoading: isLoadingAllPayoutsForDentist,
    error: fetchAllPayoutsForDentistError,
    data: allPayoutsForDentist,
  } = useQuery<Payout[], ErrorResponse>(
    getAllPayoutsForDentistKey,
    () => {
      if (!selectedDentistId) {
        return [];
      }
      return getAllPayoutsForDentist({
        authProvider,
        dentistId: selectedDentistId,
      });
    },
    {
      enabled: !isViewingAllDentists,
    }
  );

  const getAllPayoutsForPracticeKey = useMemo(
    () => ['getAllPayoutsForPractice', selectedDentistId],
    [selectedDentistId]
  );

  const {
    isLoading: isLoadingAllPayoutsForPractice,
    error: fetchAllPayoutsForPracticeError,
    data: allPayoutsForPractice,
  } = useQuery<Payout[], ErrorResponse>(
    getAllPayoutsForPracticeKey,
    () => {
      return getAllPayoutsForPractice({
        authProvider,
        practiceId,
      });
    },
    {
      enabled: isViewingAllDentists,
    }
  );

  if (isLoadingAllPayoutsForPractice || isLoadingAllPayoutsForDentist) {
    return {
      pastPayouts: undefined,
      isLoading: true,
      error: undefined,
    };
  }

  if (fetchAllPayoutsForPracticeError) {
    return {
      pastPayouts: undefined,
      isLoading: false,
      error: fetchAllPayoutsForPracticeError,
    };
  }

  if (fetchAllPayoutsForDentistError) {
    return {
      pastPayouts: undefined,
      isLoading: false,
      error: fetchAllPayoutsForDentistError,
    };
  }

  if (allPayoutsForPractice) {
    return {
      pastPayouts: allPayoutsForPractice,
      isLoading: false,
      error: undefined,
    };
  }

  if (allPayoutsForDentist) {
    return {
      pastPayouts: allPayoutsForDentist,
      isLoading: false,
      error: undefined,
    };
  }

  return {
    pastPayouts: [],
    isLoading: false,
    error: undefined,
  };
};
