import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Appointment } from '../../ServiceContext/appointments';

export default function AppointmentTileNotes({ appointment }: { appointment: Appointment }) {
  return (
    <div className="w-1/2 flex flex-col gap-2">
      <div className="text-base font-bold">{appointment.appointmentReason || 'Office booking'}</div>
      <div className="notes flex flex-row items-center gap-2.5 ">
        <FontAwesomeIcon icon={faCommentDots} style={{ color: 'slategray', opacity: '80%' }} />
        <span className="text-xs line-clamp-1 text-ellipsis">
          {appointment.notes ? `"${appointment.notes}"` : ''}
        </span>
      </div>
    </div>
  );
}
