import React from 'react';

import { BookingWorkflow } from '../../amplitude';
import Button from '../../shared/Button/Button';

const ActionButton: React.FC<{
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}> = ({ children, onClick, disabled, className }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      htmlType="submit"
      omitBorder
      workflow={BookingWorkflow}
      trackingLabel="Action Button"
      context="actionButton"
    >
      {children}
    </Button>
  );
};

export default ActionButton;
