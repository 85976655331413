import React, { PropsWithChildren } from 'react';

type Props = {
  type?: 'success' | 'warning' | 'error' | 'neutral';
  className?: string;
};

const Bulletin: React.FC<PropsWithChildren<Props>> = ({
  type,
  className: propsClassName = '',
  children,
}) => {
  let className = 'w-full text-left rounded-md mt-4 pl-4 py-2 text-xs font-semibold';
  switch (type) {
    case 'success':
      className += ' alert-success bg-success text-success-content border-success';
      break;
    case 'warning':
      className += ' alert-warning bg-warning text-warning-content border-warning';
      break;
    case 'error':
      className += ' alert-danger bg-error text-error-content border-error';
      break;
    case 'neutral':
      className += ' bg-base-300';
      break;
  }

  if (propsClassName) {
    className += ` ${propsClassName}`;
  }

  return (
    <div className={className} role="alert">
      {children}
    </div>
  );
};

export default Bulletin;
