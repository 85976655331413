import React from 'react';

import { AuthProvider } from '../Authentication/Authentication';
import GatherBrowserNotificationPermission from '../shared/firebase/GatherBrowserNotificationPermission';
import AppointmentRequests from './components/AppointmentRequests';
import HomeHeader from './components/HomeHeader';
import NeedsAttention from './components/NeedsAttention';
import TodaysAppointments from './components/TodaysAppointments';

type Props = {
  authProvider: AuthProvider;
};

const Home: React.FC<Props> = ({ authProvider }) => {
  return (
    <div className="home-page flex flex-col items-start w-full bg-base-300">
      <HomeHeader authProvider={authProvider} />
      <GatherBrowserNotificationPermission />
      {<AppointmentRequests authProvider={authProvider} />}
      {<TodaysAppointments authProvider={authProvider} />}
      {<NeedsAttention authProvider={authProvider} />}
    </div>
  );
};

export default Home;
