import { CardNumberElement, useElements } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import Modal from 'react-modal';

import { Appointment } from '../../../ServiceContext/appointments';
import { PaymentMethod, SplitPayment } from '../../../ServiceContext/invoices';
import { Patient } from '../../../ServiceContext/patients';
import { toShortDateTimeString } from '../../../shared/dates/dates';
import MoneyInput from '../../../shared/MoneyInput/MoneyInput';
import { useAddPaymentMethod } from '../../AppointmentDetail/hooks/useAddPaymentMethod';
import { insurancePaymentsZeroOutInvoicesBlurb } from '../copy';
import AddCreditCardModalContent from './AddCreditCardModalContent';
import ChangeCreditCardModalContent from './ChangeCreditCardModalContent';
import { checkPreviousEventsForPaymentMethod } from './helperFns';
import PaymentEntryCardDetails from './PaymentEntryCardDetails';
import PaymentMethodDropdown from './PaymentMethodDropdown';
import PaymentRemoveButton from './PaymentRemoveButton';
import { ZeroPaymentWarning } from './ZeroPaymentWarning';

export type SplitPaymentMethodType =
  | 'credit-card'
  | 'cash'
  | 'sunbit'
  | 'care-credit'
  | 'other-financing'
  | 'office-payment-plan'
  | 'insurance';

type BaseSplitPaymentMethod = {
  id: string;
  amount: number;
};

export type SplitPaymentMethod = CardSplitPaymentMethod | NonCardSplitPaymentMethod;

export type CardSplitPaymentMethod = {
  methodType: 'credit-card';
  stripePaymentMethod: PaymentMethod | null;
} & BaseSplitPaymentMethod;

export type NonCardSplitPaymentMethod = {
  methodType:
    | 'cash'
    | 'sunbit'
    | 'care-credit'
    | 'other-financing'
    | 'office-payment-plan'
    | 'insurance';
} & BaseSplitPaymentMethod;

export function isSplitPaymentMethodValid(splitPaymentMethod: SplitPaymentMethod): boolean {
  if (splitPaymentMethod.amount === 0) {
    return false;
  }

  if (splitPaymentMethod.methodType === 'credit-card') {
    const typedPaymentMethod = splitPaymentMethod as CardSplitPaymentMethod;
    return typedPaymentMethod.stripePaymentMethod !== null;
  }

  return true;
}

type PaymentEntryProps = {
  appointment: Appointment;
  idx: number;
  splitPaymentMethod: SplitPaymentMethod;
  unusedPaymentMethods: PaymentMethod[];
  patientTotalCardsOnFileCount: number;
  handleMoneyInputBlur: (
    e: React.FocusEvent<HTMLInputElement>,
    valueInCents: number | undefined
  ) => void;
  removePayment: (id: string) => void;
  addPayment: (id: string) => void;
  onPaymentMethodChange: (updatedPaymentMethod: SplitPaymentMethod) => void;
  onAppointmentUpdated: (updatedAppointment: Appointment) => void;
  payment: SplitPayment | null;
  totalSplits: number;
};

const PaymentEntry: React.FC<PaymentEntryProps> = ({
  appointment,
  idx,
  splitPaymentMethod,
  unusedPaymentMethods,
  handleMoneyInputBlur,
  patientTotalCardsOnFileCount,
  removePayment,
  onPaymentMethodChange,
  onAppointmentUpdated,
  payment,
  totalSplits,
}) => {
  const { paymentMethodFailureMessage, didPaymentSucceed } = checkPreviousEventsForPaymentMethod({
    paymentMethod: splitPaymentMethod,
    payment,
    appointmentId: appointment.id,
  });

  const [isAddNewCardModalOpen, setIsAddNewCardModalOpen] = useState(false);
  const [isChangeCardModalOpen, setIsChangeCardModalOpen] = useState(false);
  const elements = useElements();

  const { createPaymentMethod, cardError, setCardError, loading } = useAddPaymentMethod({
    appointment: appointment,
    firstName: appointment.user!.firstName,
    lastName: appointment.user!.lastName,
    patientId: appointment.user!.id,
    onPatientPaymentMethodsUpdated: (updatedPatient: Patient) => {
      const updatedAppointment: Appointment = {
        ...appointment,
        user: updatedPatient,
      };
      onAppointmentUpdated(updatedAppointment);
    },
    onSuccess: (newPaymentMethod: PaymentMethod) => {
      onPaymentMethodChange({
        ...splitPaymentMethod,
        methodType: 'credit-card',
        stripePaymentMethod: newPaymentMethod,
      });
      setIsAddNewCardModalOpen(false);
    },
  });

  const PaymentMethodComponents = {
    'credit-card': () => {
      let typedPaymentMethod: CardSplitPaymentMethod;
      if (splitPaymentMethod.methodType === 'credit-card') {
        typedPaymentMethod = splitPaymentMethod;
      } else {
        return null;
      }

      const selectedPaymentMethod = typedPaymentMethod.stripePaymentMethod;
      const hasNoAvailablePaymentMethods: boolean = unusedPaymentMethods.length === 0;

      return (
        <div
          id={`payment-method-byline-${splitPaymentMethod.id}`}
          className={'flex flex-col gap-2 w-full'}
        >
          <PaymentEntryCardDetails
            selectedPaymentMethod={selectedPaymentMethod}
            hasNoAvailablePaymentMethods={hasNoAvailablePaymentMethods}
            patientTotalCardsOnFileCount={patientTotalCardsOnFileCount}
            paymentMethodFailureMessage={paymentMethodFailureMessage}
            renderAsSuccessfulPayment={didPaymentSucceed}
          />
          {!didPaymentSucceed && (
            <div id={'buttons'} className={'flex flex-row gap-2'}>
              {hasNoAvailablePaymentMethods ? (
                <button
                  onClick={() => setIsAddNewCardModalOpen(true)}
                  className={'underline hover:opacity-75'}
                >
                  Add New Card
                </button>
              ) : (
                <button
                  className={'underline hover:opacity-75'}
                  onClick={() => setIsChangeCardModalOpen(true)}
                >
                  Change Card
                </button>
              )}
            </div>
          )}
        </div>
      );
    },
    cash: () => (
      <div
        id={`payment-method-byline-${splitPaymentMethod.id}`}
        className={'w-full bg-highlight rounded-md p-2.5'}
      >
        <span>The Flossy Fee will be recouped in your next payout.</span>
      </div>
    ),
    sunbit: () => (
      <div
        id={`payment-method-byline-${splitPaymentMethod.id}`}
        className={'w-full bg-highlight rounded-md p-2.5'}
      >
        <span>The Flossy Fee will be recouped in your next payout.</span>
      </div>
    ),
    'care-credit': () => (
      <div
        id={`payment-method-byline-${splitPaymentMethod.id}`}
        className={'w-full bg-highlight rounded-md p-2.5'}
      >
        <span>The Flossy Fee will be recouped in your next payout.</span>
      </div>
    ),
    'other-financing': () => (
      <div
        id={`payment-method-byline-${splitPaymentMethod.id}`}
        className={'w-full bg-highlight rounded-md p-2.5'}
      >
        <span>The Flossy Fee will be recouped in your next payout.</span>
      </div>
    ),
    'office-payment-plan': () => (
      <div
        id={`payment-method-byline-${splitPaymentMethod.id}`}
        className={'w-full bg-highlight rounded-md p-2.5'}
      >
        <span>The Flossy Fee will be recouped in your next payout.</span>
      </div>
    ),
    insurance: () => (
      <div
        id={`payment-method-byline-${splitPaymentMethod.id}`}
        className={'w-full bg-highlight rounded-md p-2.5'}
      >
        <span>{insurancePaymentsZeroOutInvoicesBlurb}</span>
      </div>
    ),
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    createPaymentMethod.mutate({
      cardNumberElement: elements!.getElement(CardNumberElement)!,
    });
  };

  const shouldShowPaymentComponent = (methodType: SplitPaymentMethodType, amount: number) =>
    amount !== 0 || methodType === 'insurance';
  const shouldShowZeroPaymentWarning = (methodType: SplitPaymentMethodType, amount: number) =>
    amount === 0 && methodType !== 'insurance';

  return (
    <div className={'flex flex-row justify-between items-start w-full py-2.5'}>
      <span className={'text-xs text-left font-semibold pl-6'}>{`${toShortDateTimeString(
        appointment.start,
        appointment.timeZone
      )}, Payment ${idx + 1}`}</span>
      <PaymentMethodDropdown
        splitPaymentMethod={splitPaymentMethod}
        onPaymentMethodChange={onPaymentMethodChange}
        disabled={didPaymentSucceed}
        totalSplits={totalSplits}
        patient={appointment.user!}
        appointment={appointment}
      />
      <div id={'payment-method-details-wrapper'} className={'text-xs text-left w-[40%] '}>
        {shouldShowPaymentComponent(splitPaymentMethod.methodType, splitPaymentMethod.amount) &&
          PaymentMethodComponents[splitPaymentMethod.methodType] &&
          React.createElement(PaymentMethodComponents[splitPaymentMethod.methodType])}
        {shouldShowZeroPaymentWarning(splitPaymentMethod.methodType, splitPaymentMethod.amount) && (
          <ZeroPaymentWarning />
        )}
      </div>
      <div className={'flex flex-row gap-2 justify-end'}>
        <MoneyInput
          id={`amount-input-${splitPaymentMethod.id}`}
          name={`amount-input-name-${splitPaymentMethod.id}`}
          onBlur={handleMoneyInputBlur}
          disabled={didPaymentSucceed}
          defaultValueInCents={splitPaymentMethod.amount}
          showErrorFn={() => false}
          className={
            'text-xs border rounded-md text-right p-2 focus:outline-info w-[45%] mr-3 translate-x-20'
          }
          // Stripe cannot process payments less than 50 cents
          min={50}
        />
        <PaymentRemoveButton
          onClick={removePayment}
          splitPaymentMethodId={splitPaymentMethod.id}
          disabled={didPaymentSucceed}
        />
      </div>
      {splitPaymentMethod.methodType === 'credit-card' && (
        <>
          <Modal
            isOpen={isAddNewCardModalOpen}
            className="fixed inset-0 flex items-center justify-center bg-secondary/40"
          >
            <AddCreditCardModalContent
              loading={loading}
              setCardError={setCardError}
              cardError={cardError}
              handleSubmit={handleSubmit}
              closeModal={() => setIsAddNewCardModalOpen(false)}
            />
          </Modal>

          <Modal
            isOpen={isChangeCardModalOpen}
            className="fixed inset-0 flex items-center justify-center bg-secondary/40"
          >
            <ChangeCreditCardModalContent
              selectedPaymentMethod={
                splitPaymentMethod ? splitPaymentMethod.stripePaymentMethod : null
              }
              availablePaymentMethods={unusedPaymentMethods}
              onAddCardClick={() => {
                setIsChangeCardModalOpen(false);
                setIsAddNewCardModalOpen(true);
              }}
              onConfirmSelection={(pm: PaymentMethod) => {
                onPaymentMethodChange({
                  ...splitPaymentMethod,
                  stripePaymentMethod: pm,
                });
                setIsChangeCardModalOpen(false);
              }}
              closeModal={() => setIsChangeCardModalOpen(false)}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default PaymentEntry;
