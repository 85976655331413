import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { toast } from 'react-toastify';

import { AuthProvider } from './Authentication/Authentication';
import { Dentist, Manager } from './ServiceContext/user';
import { copyToClipboard } from './shared/copyPaste';

interface HotkeyCommanderProps {
  authProvider: AuthProvider;
  children: ReactNode;
}

const HotkeyCommander: React.FC<HotkeyCommanderProps> = ({ authProvider, children }) => {
  useHotkeys('ctrl+alt+meta+k', (event) => {
    event.preventDefault();

    const authUser = authProvider.authUser?.user;

    if (authUser) {
      toast.success(<UserInformation user={authUser as UserProps} />, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        style: { minWidth: '600px' },
      });
    } else {
      toast.info('No user information available');
    }
  });

  return <>{children}</>;
};

type UserProps = Manager | Dentist;

const UserInformation: React.FC<{ user: UserProps }> = ({ user }) => {
  const tableStyle: React.CSSProperties = {
    margin: '0',
    padding: '16px',
    lineHeight: '1.5',
    width: '100%',
  };

  const thStyle: React.CSSProperties = {
    textAlign: 'left',
    paddingRight: '16px',
    paddingBottom: '4px',
    color: '#888',
    minWidth: 64,
  };

  const tdStyle: React.CSSProperties = {
    textAlign: 'right',
    paddingBottom: '4px',
  };

  return (
    <div>
      <table style={tableStyle}>
        <tbody>
          <tr>
            <th style={thStyle}>ID</th>
            <td style={tdStyle}>
              {user.id}
              <button
                onClick={() => copyToClipboard(user.id)}
                aria-label="Copy ID"
                style={{
                  marginLeft: '10px',
                  cursor: 'pointer',
                  background: 'none',
                  border: 'none',
                  padding: '0',
                }}
              >
                <FontAwesomeIcon icon={faCopy} />
              </button>
            </td>
          </tr>
          <tr>
            <th style={thStyle}>Name</th>
            <td style={tdStyle}>{`${user.firstName} ${user.lastName}`}</td>
          </tr>
          <tr>
            <th style={thStyle}>Type</th>
            <td style={tdStyle}>{user.type}</td>
          </tr>
          <tr>
            <th style={thStyle}>Email</th>
            <td style={tdStyle}>{user.email}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HotkeyCommander;
