import * as Sentry from '@sentry/react';
import { QueryObserverResult, RefetchOptions, useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { isErrorResponse } from '../../../API/response';
import { Appointment } from '../../../ServiceContext/appointments';
import { useServices } from '../../../ServiceContext/ServiceContext';

/**
 * Custom hook to fetch and return appointment data.
 *
 * @param {string} appointmentId - The ID of the appointment to fetch.
 * @returns {object} An object containing:
 * - isLoading: A boolean indicating if the query is still loading.
 * - isError: A boolean indicating if the query encountered an error.
 * - data: The appointment data, or null if it's not available.
 * - error: The error object, or null if there was no error.
 * - refetch: A function to refetch the appointment data.
 */
export function useAppointment(appointmentId: string | null): {
  isLoading: boolean;
  isError: boolean;
  data: Appointment | null | undefined;
  error: Error | null | unknown;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<Appointment | null>>;
} {
  const services = useServices();

  const fetchAppointment = async (id: string) => {
    const res = await services.appointmentsService.getAppointment(id);
    if (isErrorResponse(res)) {
      throw new Error(res.errorResponse.toString());
    }
    return res;
  };

  const { isLoading, isError, data, error, refetch } = useQuery<Appointment | null>(
    `appointment_${appointmentId}`,
    () => {
      if (!appointmentId) {
        return null;
      }
      return fetchAppointment(appointmentId);
    }
  );

  if (isError) {
    if (data && !data.user) {
      const errorId = uuidv4();
      Sentry.captureMessage(
        `Attempted to load an appointment with id ${appointmentId} that had no user (errorId: ${errorId})`
      );
    } else {
      Sentry.captureException(error);
    }
  }

  return { isLoading, isError, data, error, refetch };
}
