import React, { useCallback, useMemo } from 'react';

import { Dentist } from '../../ServiceContext/user';
import { useURLQuery } from '../../shared/routing/routing';
import SearchSelect, { SearchSelectOption } from '../../shared/SearchSelect/SearchSelect';
import { usernameFromDentist } from '../../shared/strings';

type DentistSelectionWrapperChildProps = {
  selectedDentist: Dentist;
  onClearDentist: (() => void) | null;
};

type DentistSelectionWrapperProps = {
  dentists: Dentist[];
  flavorText: string;
  children: React.FC<DentistSelectionWrapperChildProps>;
};

const DentistSelectionWrapper: React.FC<DentistSelectionWrapperProps> = ({
  dentists,
  flavorText,
  children,
}) => {
  const { query, updateQuery, setQuery } = useURLQuery();
  const dentistId = query.get('dentistId');

  const dentistOptions: SearchSelectOption[] = useMemo(
    () =>
      dentists.map((d) => {
        return {
          id: d.id,
          label: usernameFromDentist(d),
          renderContent: (
            <div className="dentist-search-option py-2 px-2 text-left">
              {d.firstName} {d.lastName}{' '}
              <span className="dentist-search-option-username font-semibold">({d.username})</span>
            </div>
          ),
        };
      }),
    [dentists]
  );

  const onDentistSelect = useCallback(
    (d: SearchSelectOption | null) => {
      if (d) {
        updateQuery({ dentistId: d.id });
      } else {
        setQuery({});
      }
    },
    [updateQuery, setQuery]
  );

  const onClearDentist = useCallback(() => {
    onDentistSelect(null);
  }, [onDentistSelect]);

  let dentist: Dentist | null;
  if (dentists.length === 1) {
    dentist = dentists[0];
  } else {
    dentist = dentists.find((d) => d.id === dentistId) || null;
  }
  if (dentist) {
    return children({
      selectedDentist: dentist,
      onClearDentist: dentists.length === 1 ? null : onClearDentist,
    });
  }

  return (
    <div className="dentist-selection-wrapper bg-[#f9f9f9] rounded-lg mb-2.5">
      <div className="dentist-selection w-full flex flex-column h-32 items-start gap-[16px] text-left text-base">
        <span className="pt-4 text-[#5a5a5a] font-semibold pl-8">{flavorText}</span>
        <SearchSelect
          id="dentist-select"
          options={dentistOptions}
          onChange={onDentistSelect}
          placeholder="Choose a Dentist"
          wrapperCustomClass="w-1/4"
          overlayPlatformCustomClass="w-full translate-x-6"
          componentContainerCustomClass="w-full"
          allowClear={false}
          includeAngle
        />
      </div>
    </div>
  );
};

export default DentistSelectionWrapper;
