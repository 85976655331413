interface LoadingSpinnerProps {
  size?: 'xs' | 'sm' | undefined;
  className?: string;
}

function LoadingSpinner({ size = undefined, className = '' }: LoadingSpinnerProps) {
  const spinnerSize = size === 'xs' ? 'w-4 h-4' : 'sm' ? 'w-8 h-8' : 'w-16 h-16';
  return (
    <div className={`flex justify-center items-center w-full ${className}`}>
      <div
        className={`${spinnerSize} border-4 border-blue-500 border-dotted rounded-full animate-spin`}
      ></div>
    </div>
  );
}

export default LoadingSpinner;
