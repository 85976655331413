import { isNumber } from '../shared/math/math';
import { Timestamp } from './shared';

export const TransactionPaymentTypeCreditCard = 'credit-card';
export const TransactionPaymentTypeCash = 'cash';
export const TransactionPaymentTypeCareCredit = 'care-credit';
export const TransactionPaymentTypeSunbit = 'sunbit';
export const TransactionPaymentTypeOtherFinancing = 'other-financing';
export const TransactionPaymentTypeOfficePaymentPlan = 'office-payment-plan';
export const TransactionPaymentTypeInsurance = 'insurance';

export const formatPaymentType = (paymentType: string) => {
  const words = paymentType.replace(/-/g, ' ').split(' ');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return capitalizedWords.join(' ');
};

export type TransactionPaymentType =
  | typeof TransactionPaymentTypeCreditCard
  | typeof TransactionPaymentTypeCash
  | typeof TransactionPaymentTypeCareCredit
  | typeof TransactionPaymentTypeSunbit
  | typeof TransactionPaymentTypeOtherFinancing
  | typeof TransactionPaymentTypeOfficePaymentPlan
  | typeof TransactionPaymentTypeInsurance;

export enum InvoiceStatus {
  paid = 'paid',
  paidInOffice = 'paid_in_office',

  settled = 'settled',
  overdue = 'overdue',
  due = 'due',
  partiallyPaid = 'partially_paid',
}

export type PayoutMethod = 'virtual-card' | 'check-in-mail';

export type InvoicePricingAdjustment = {
  pricingSystemId: string;
  price: number;
};

export interface Invoice {
  id: string;
  userId: string;
  dentistId: string;
  date: string | undefined;
  paidDate: string | undefined;
  feeSchedule: string;
  status: InvoiceStatus;
  invoiceItems: InvoiceItem[];
  subtotal: number;
  total: number;
  balance: number;
  payoutAmount: number;
  payoutMethod: PayoutMethod | null;
  stripeCardId: string | null;
  customInvoicingConfigurationId: string | null;
  isDraft?: boolean;
  pricingQuote?: PricingQuote | null;
}

export function isInvoiceDue(invoice: Invoice) {
  return (
    invoice.status === InvoiceStatus.due ||
    invoice.status === InvoiceStatus.overdue ||
    invoice.status === InvoiceStatus.partiallyPaid
  );
}

export type AreaOfMouthValue =
  | 'entire oral cavity'
  | 'maxillary arch'
  | 'mandibular arch'
  | 'upper right quadrant'
  | 'lower right quadrant'
  | 'upper left quadrant'
  | 'lower left quadrant';

export function isAreaOfMouthValue(s: string): s is AreaOfMouthValue {
  return (
    s === 'entire oral cavity' ||
    s === 'maxillary arch' ||
    s === 'mandibular arch' ||
    s === 'upper right quadrant' ||
    s === 'lower right quadrant' ||
    s === 'upper left quadrant' ||
    s === 'lower left quadrant'
  );
}

export interface InvoiceItem {
  type:
    | 'procedure'
    | 'processingFee'
    | 'membershipFee'
    | 'promo'
    | 'refund'
    | 'financingFee'
    | 'savings';
  code: string;
  text: string;
  amount: number;
  areaOfMouthCode?: AreaOfMouthValue;
  toothNumberCode?: string;
  toothSurfaceCode?: string;
  additionalNotes?: string;

  // These will be present for all invoice items created in pricing system version >= v2
  pricingSystemId?: string | null;
  patientPrice?: number;
  anchorPrice?: number;
  dentistPayout?: number;
  adjustedPatientPrice?: number | null;
  adjustedDentistPayout?: number | null;
}

export type DollarsPromoSummary = {
  type: 'dollars';
  promoCode: string;
  discountInCents: number;
};

export type PercentPromoSummary = {
  type: 'percent';
  promoCode: string;
  percentageDiscount: number;
  cdtCodes: string[];

  discountInCents: number;
  discountInCentsByCDTCode: { [key: string]: number };
  discountInCentsByPricingAssociationID: { [key: string]: number };
  discountInCentsByPricingSystemID: { [key: string]: number };
};

export type LockedAmountPromoSummary = {
  type: 'lockedAmount';
  promoCode: string;
  amountInCents: number;
  cdtCodes: string[];

  discountInCents: number;
  discountInCentsByCDTCode: { [key: string]: number };
  discountInCentsByPricingAssociationID: { [key: string]: number };
  discountInCentsByPricingSystemID: { [key: string]: number };
};

export type PromoSummary = DollarsPromoSummary | PercentPromoSummary | LockedAmountPromoSummary;

export type PricingSystemItem = {
  code: string;
  patientPrice: number;
  anchorPrice: number;
  dentistPayout: number;
  adjustedPatientPrice: number | null;
  adjustedDentistPayout: number | null;

  // Allows association of items with the same pricing logic applied to them.
  pricingAssociationId: string | null;
  pricingSystemId: string | null;
};

export type PricingQuote = {
  id: string;
  dentistId: string;
  practiceId: string;
  patientId: string;
  randomSeed: number | null;
  items: PricingSystemItem[];
  subtotal: number;
  total: number;
  flossyProfit: number;
  targetPatientSavings: number;
  realizedPatientSavings: number;
  flossyFeesByCode: { [key: string]: number };
  isHot: boolean;
  promoSummary: PromoSummary | null;
  validationFailures: string[] | null;
  createdAt: string;
};

export function patientSavingsFromPricingQuote(quote: PricingQuote) {
  const amountSavedOnItems = quote.items.reduce((acc, item) => {
    const patientPrice = isNumber(item.adjustedPatientPrice)
      ? item.adjustedPatientPrice
      : item.patientPrice;
    return acc + (item.anchorPrice - patientPrice);
  }, 0);
  const amountSavedOnPromo = quote.promoSummary ? quote.promoSummary.discountInCents : 0;
  return amountSavedOnItems + amountSavedOnPromo;
}

export type Payment = OpenPayment | LegacyPayment | SplitPayment;

export type OpenPayment = { status: 'open' };

export interface LegacyPayment {
  id: string;
  date: string;
  dentistId: string;
  amount: number;
  userId: string;
  appointmentId: string;
  paymentMethod: PaymentMethod | {};
  status: 'open' | 'pending' | 'processed' | 'denied';
  errorMessage?: string;
  isFirst: boolean;
  processedByUserName?: string;
  paymentType?: TransactionPaymentType;
  otherFinancingDetails?: string;
}

export type LegacyPaymentSummary = Pick<LegacyPayment, 'paymentType' | 'paymentMethod' | 'amount'>;

export function isLegacyPayment(obj: any): obj is LegacyPayment {
  return (
    !!obj &&
    (obj as LegacyPayment).id !== undefined &&
    (obj as LegacyPayment).dentistId !== undefined &&
    (obj as LegacyPayment).amount !== undefined &&
    (obj as LegacyPayment).appointmentId !== undefined &&
    obj['paymentSplits'] === undefined &&
    (obj as LegacyPayment).status !== undefined
  );
}

export interface SplitPayment {
  id: string;
  date: string;
  dentistId: string;
  amount: number;
  userId: string;
  appointmentId: string;
  status: 'open' | 'pending' | 'processed' | 'partially_processed' | 'denied';
  errorMessage?: string;
  isFirst: boolean;
  processedByUserName?: string;
  processedByUserGoogleId?: string;

  paymentSplits: PaymentSplitMetadata[];
}

export interface PaymentSplitMetadata {
  date: Timestamp;
  paymentMethod?: PaymentMethod;
  paymentType: TransactionPaymentType;
  splitAmount: number;
  status: 'open' | 'pending' | 'processed' | 'denied';
  stripePaymentIntentId: string;
  errorCode?: string;
  errorMessage?: string;
}

export type PaymentSplitSummary = Pick<
  PaymentSplitMetadata,
  'paymentType' | 'paymentMethod' | 'splitAmount'
>;

export function isSplitPayment(obj: any): obj is SplitPayment {
  return (
    !!obj &&
    (obj as SplitPayment).id !== undefined &&
    (obj as SplitPayment).dentistId !== undefined &&
    (obj as SplitPayment).amount !== undefined &&
    (obj as SplitPayment).appointmentId !== undefined &&
    (obj as SplitPayment).paymentSplits !== undefined &&
    Array.isArray((obj as SplitPayment).paymentSplits) &&
    (obj as SplitPayment).status !== undefined
  );
}

export function isOpenPayment(obj: any): obj is OpenPayment {
  return (
    !!obj && (obj as LegacyPayment).status !== undefined && (obj as LegacyPayment).status === 'open'
  );
}

export function cardLastFourFromPayment(
  payment: LegacyPayment | OpenPayment | PaymentSplitMetadata
) {
  if (!payment || payment.status !== 'processed' || !payment.paymentMethod) {
    return undefined;
  }

  let components: string[] = [];
  if (isPaymentMethod(payment.paymentMethod) && payment.paymentMethod.name) {
    components = payment.paymentMethod.name.split(' ');
  }

  // We expect payment method names to have a form like 'Visa 4242'
  if (components.length === 2) {
    return `${components[1]}`;
  }

  return undefined;
}

export function cardLastFourFromPaymentMethod(paymentMethod: PaymentMethod) {
  if (paymentMethod && paymentMethod.id && paymentMethod.name) {
    const paymentMethodNameSplits = paymentMethod.name.split(' ');
    if (paymentMethodNameSplits.length === 2) {
      return `${paymentMethodNameSplits[1]}`;
    }
  }

  return undefined;
}

export function cardNameFromPaymentMethod(paymentMethod: PaymentMethod) {
  if (paymentMethod && paymentMethod.id && paymentMethod.name) {
    const paymentMethodNameSplits = paymentMethod.name.split(' ');
    if (paymentMethodNameSplits.length === 2) {
      return paymentMethodNameSplits[0];
    }
  }

  return undefined;
}

export function cardExpirationFromPaymentMethod(paymentMethod: PaymentMethod) {
  if (paymentMethod && paymentMethod.id && paymentMethod.expirationDate) {
    const date = new Date(paymentMethod.expirationDate);
    const formattedDate = `${date.getMonth() + 1}/${date.getFullYear().toString().slice(-2)}`;

    return formattedDate;
  }

  return undefined;
}

export function isPaymentMethod(obj: any): obj is PaymentMethod {
  return (
    !!obj &&
    (obj as PaymentMethod).id !== undefined &&
    (obj as PaymentMethod).type !== undefined &&
    (obj as PaymentMethod).name !== undefined &&
    (obj as PaymentMethod).expirationDate !== undefined &&
    (obj as PaymentMethod).stripeId !== undefined
  );
}

export interface PaymentMethod {
  id: string;
  type: string;
  name: string;
  expirationDate: string; // 09/25
  stripeId: string;
}

export function isValidPaymentMethod(pm: any): pm is PaymentMethod {
  return (
    Boolean(pm) &&
    typeof pm.id === 'string' &&
    typeof pm.type === 'string' &&
    typeof pm.name === 'string' &&
    typeof pm.expirationDate === 'string' &&
    typeof pm.stripeId === 'string'
  );
}

export interface PayoutInvoiceSummary {
  invoiceId: string;
  appointmentDate: string;
  paidDate: string;
  //A negative value indicates this is a recoup amount
  payoutAmount: number;
  patientName: string;
  appointmentId?: string;
  paymentType?: TransactionPaymentType;
}

export interface PayoutSummary {
  total: number;
  payoutDate: string;
  invoiceSummaries: PayoutInvoiceSummary[];
}

export interface Payout {
  payoutId: string;
  summary: PayoutSummary;
}

export interface PayoutCluster {
  dentistId: string;
  summary: {
    name: string;
    total: number;
    paymentDay: 1;
    payoutDate: string;
    cutoffDate: string;
    invoiceSummaries: PayoutInvoiceSummary[];
  };
}
