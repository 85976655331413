import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AuthenticationWorkflow,
  runFTrack,
  setAmplitudeUser,
  SideEffectTrackingAction,
} from '../amplitude';
import { firebaseAuth } from '../firebase';
import { reportErrorToSentry } from '../ServiceContext/error';
import { apiUrl } from '../ServiceContext/shared';
import { Dentist, isDentist, isManager, Manager } from '../ServiceContext/user';
import BrandedLoadingPage from '../shared/BrandedLoadingPage/BrandedLoadingPage';
import AuthenticationExpiredModal from './AuthenticationExpiredModal';

export type AuthUserTermsOfServiceStatus = {
  needsToAgreeToNewTermsOfService: boolean;
  practiceIds: string[];
  userId: string;
  userType: string;
};

export type FirebaseAuthInfo = {
  token: string;
  firebaseUserId: string;
};

export interface AuthProvider {
  isLoggedIn: boolean;
  authUser: AuthUser | null;
  getFirebaseAuthInfo: () => Promise<FirebaseAuthInfo | null>;
  isFlossyAdmin: boolean;
  managedDentists: Dentist[] | null;
  termsOfServiceStatus: AuthUserTermsOfServiceStatus | null;
  logIn: (email: string, password: string) => Promise<void>;
  logOut: () => Promise<void>;
  notifyUnauthorizedFromAPI: () => void;
}

type AuthenticationProps = {
  children: React.FC<AuthenticationChildProps>;
};

type AuthenticationChildProps = {
  authProvider: AuthProvider;
};

const Authentication: React.FC<AuthenticationProps> = ({ children }) => {
  const [isFirebaseLoading, setIsFirebaseLoading] = useState(true);
  const [isAuthenticationValid, setIsAuthenticationValid] = useState(false);
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);
  const [managedDentists, setManagedDentists] = useState<Dentist[] | null>(null);
  const [termsOfServiceStatus, setTermsOfServiceStatus] =
    useState<AuthUserTermsOfServiceStatus | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const fetchManagedDentists = useCallback(
    async (managerId: string, token: string, firebaseUserId: string) => {
      if (!firebaseUserId) {
        reportErrorToSentry({
          summary: 'Missing firebase token fetching managed dentists',
          extra: authUser,
        });
        return Promise.reject('Failed to fetch managed dentists');
      }

      const getManagedDentistsRes = await fetch(apiUrl(`/managers/${managerId}/dentists`), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'flossy-dentist-google-id': firebaseUserId,
        },
      });
      let managedDentists: Dentist[] = await getManagedDentistsRes.json();
      managedDentists = managedDentists.sort((a, b) => {
        const combinedNameA = a.lastName + a.firstName;
        const combinedNameB = b.lastName + b.firstName;
        if (combinedNameA < combinedNameB) {
          return -1;
        }
        if (combinedNameA > combinedNameB) {
          return 1;
        }

        return 0;
      });

      return Promise.resolve(managedDentists);
    },
    [authUser]
  );

  const getFirebaseAuthInfo = useCallback(async () => {
    if (!firebaseAuth.currentUser) {
      return null;
    }
    const token = await firebaseAuth.currentUser.getIdToken();
    if (!token) {
      return null;
    }

    return {
      token,
      firebaseUserId: firebaseAuth.currentUser.uid,
    };
  }, []);

  const logIn = useCallback(
    async (email: string, password: string) => {
      const firebaseRes = await signInWithEmailAndPassword(firebaseAuth, email, password);
      if (!firebaseRes.user) {
        setIsAuthenticationValid(false);
        return Promise.reject(new Error('Invalid username/password'));
      }

      const { user } = firebaseRes;
      const token = await user.getIdToken(false);

      if (!user.uid) {
        reportErrorToSentry({
          summary: 'Missing auth token on auth user during login',
          extra: user,
        });
        setIsAuthenticationValid(false);
        return Promise.reject('Login failed');
      }

      const flsyRes = await doFlossyLogin(token, user.uid);

      if (!flsyRes) {
        setIsAuthenticationValid(false);
        return Promise.reject(new Error(`An error occurred while logging in`));
      }

      setTermsOfServiceStatus({
        needsToAgreeToNewTermsOfService: flsyRes.needsToAgreeToLatestTermsOfService,
        practiceIds: flsyRes.practices.map((practice) => practice.id),
        userId: flsyRes.id,
        userType: flsyRes.type,
      });

      const userIsManager = isManager(flsyRes);
      let managedDentists: Dentist[] = [];
      const managerHasNoPractice = (flsyRes as Manager).practices.length === 0;
      const managerHasOnePractice = (flsyRes as Manager).practices.length === 1;

      if (userIsManager) {
        if (
          managerHasNoPractice ||
          (managerHasOnePractice &&
            (flsyRes as Manager).practices[0].name.toLowerCase() === 'no practice')
        ) {
          setIsAuthenticationValid(false);
          setManagedDentists(null);
          return Promise.reject(new Error('User is not assigned to a practice'));
        }

        const managedDentistsRes = await fetchManagedDentists(
          (flsyRes as Manager).id,
          token,
          user.uid
        );
        if (!managedDentistsRes || managedDentistsRes.length === 0) {
          setIsAuthenticationValid(false);
          setManagedDentists(null);
          console.error('failed to fetch dentist information for this practice');
          return Promise.reject(new Error('No dentist information exists for this practice'));
        }

        managedDentists = managedDentistsRes;
      } else if (isDentist(flsyRes)) {
        managedDentists = [flsyRes];
      }

      managedDentists = managedDentists.sort((a, b) => {
        if (a.lastName < b.lastName) {
          return -1;
        }
        if (a.lastName > b.lastName) {
          return 1;
        }

        return 0;
      });

      setManagedDentists(managedDentists);

      const authUser: AuthUser = {
        firebaseUserId: user.uid,
        user: flsyRes,
      };

      setAuthUser(authUser);
      setIsLoggedIn(true);
      setIsAuthenticationValid(true);
      setAmplitudeUser(authUser.user as Dentist | Manager);

      return Promise.resolve();
    },
    [fetchManagedDentists]
  );

  const logOut = useCallback(async () => {
    const firebaseAuthInfo = await getFirebaseAuthInfo();
    await signOut(firebaseAuth);
    runFTrack({
      event: 'Log Out',
      workflow: AuthenticationWorkflow,
      action: SideEffectTrackingAction,
      context: 'logout',
      componentId: 'authentication',
    });
    if (firebaseAuthInfo && authUser && authUser.firebaseUserId) {
      await fetch(apiUrl(`/dentists/logout`), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${firebaseAuthInfo.token}`,
          'flossy-dentist-google-id': authUser.firebaseUserId,
        },
      });
    } else {
      reportErrorToSentry({
        summary: 'Missing auth token on auth user while trying to log out',
        extra: authUser,
      });
    }

    setAuthUser(null);
    setIsLoggedIn(false);
    setIsAuthenticationValid(false);
    setAmplitudeUser(undefined);
    return Promise.resolve();
  }, [authUser, getFirebaseAuthInfo]);

  const doFlossyLogin = async (
    token: string,
    firebaseID: string
  ): Promise<LoginResponse | null> => {
    const response = await fetch(apiUrl(`/dentists/login`), {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'flossy-dentist-google-id': firebaseID,
      },
    });

    if (response.status < 200 || response.status > 299) {
      return null;
    }

    return await response.json();
  };

  const refreshFlossyUser = useCallback(
    async (firebaseAuthInfo: FirebaseAuthInfo): Promise<AuthUser | null> => {
      const flsyRes = await doFlossyLogin(firebaseAuthInfo.token, firebaseAuthInfo.firebaseUserId);
      if (!flsyRes) {
        setIsAuthenticationValid(false);
        await logOut();
        return null;
      }

      runFTrack({
        event: 'Log In',
        workflow: AuthenticationWorkflow,
        action: SideEffectTrackingAction,
        context: 'login',
        componentId: 'authentication',
        extraProps: {
          email: flsyRes.email,
          isAdmin: flsyRes.isFlossyAdmin,
          flossyUserId: flsyRes.id,
          practiceIds: flsyRes.practices.map((practice) => practice.id),
        },
      });

      setTermsOfServiceStatus({
        needsToAgreeToNewTermsOfService: flsyRes.needsToAgreeToLatestTermsOfService,
        practiceIds: flsyRes.practices.map((practice) => practice.id),
        userId: flsyRes.id,
        userType: flsyRes.type,
      });

      const userIsManager = isManager(flsyRes);
      let managedDentists: Dentist[] = [];
      const managerHasNoPractice = (flsyRes as Manager).practices.length === 0;
      const managerHasOnePractice = (flsyRes as Manager).practices.length === 1;

      if (userIsManager) {
        if (
          managerHasNoPractice ||
          (managerHasOnePractice &&
            (flsyRes as Manager).practices[0].name.toLowerCase() === 'no practice')
        ) {
          setIsAuthenticationValid(false);
          setManagedDentists(null);
          return Promise.reject(new Error('User is not assigned to a practice'));
        }

        const managedDentistsRes = await fetchManagedDentists(
          (flsyRes as Manager).id,
          firebaseAuthInfo.token,
          firebaseAuthInfo.firebaseUserId
        );
        if (!managedDentistsRes || managedDentistsRes.length === 0) {
          setIsAuthenticationValid(false);
          setManagedDentists(null);
          console.error('failed to fetch dentist information for this practice');
          return Promise.reject(new Error('No dentist information exists for this practice'));
        }

        managedDentists = managedDentistsRes;
      } else if (isDentist(flsyRes)) {
        managedDentists = [flsyRes];
      }

      managedDentists = managedDentists.sort((a, b) => {
        const combinedNameA = a.lastName + a.firstName;
        const combinedNameB = b.lastName + b.firstName;
        if (combinedNameA < combinedNameB) {
          return -1;
        }
        if (combinedNameA > combinedNameB) {
          return 1;
        }

        return 0;
      });

      setManagedDentists(managedDentists);

      const newAuthUser = {
        firebaseUserId: firebaseAuthInfo.firebaseUserId,
        user: flsyRes,
        isDentist: isDentist(flsyRes),
      };
      setAuthUser(newAuthUser);
      setIsLoggedIn(true);
      setIsAuthenticationValid(true);
      setAmplitudeUser(newAuthUser.user);

      return newAuthUser;
    },
    [fetchManagedDentists, logOut]
  );

  const notifyUnauthorizedFromAPI = useCallback(async () => {
    setIsAuthenticationValid(false);
  }, []);

  const auth = useMemo(
    () => ({
      isLoggedIn,
      authUser,
      getFirebaseAuthInfo,
      isFlossyAdmin: authUser ? authUser.user.isFlossyAdmin : false,
      managedDentists,
      termsOfServiceStatus,
      logIn,
      logOut,
      notifyUnauthorizedFromAPI,
    }),
    [
      authUser,
      getFirebaseAuthInfo,
      isLoggedIn,
      logIn,
      logOut,
      managedDentists,
      notifyUnauthorizedFromAPI,
      termsOfServiceStatus,
    ]
  );

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(async (user) => {
      let localAuthUser = authUser;
      if (user && !authUser) {
        const firebaseUserInfo: FirebaseAuthInfo = {
          token: await user.getIdToken(),
          firebaseUserId: user.uid,
        };
        localAuthUser = await refreshFlossyUser(firebaseUserInfo);
        setAuthUser(localAuthUser);
        setIsLoggedIn(true);
        setIsAuthenticationValid(true);
      }
      setIsFirebaseLoading(false);
    });

    // Clean up by unregistering the listener
    return () => unsubscribe();
  }, [authUser, refreshFlossyUser]);

  if (isFirebaseLoading) {
    return <BrandedLoadingPage />;
  } else {
    return (
      <div>
        {auth.isLoggedIn && !isAuthenticationValid && (
          <AuthenticationExpiredModal authProvider={auth} />
        )}
        {children({ authProvider: auth })}
      </div>
    );
  }
};

export type AuthUser = {
  firebaseUserId: string;
  user: Manager;
};

export type LoginResponse = Manager & {
  needsToAgreeToLatestTermsOfService: boolean;
};

export default Authentication;
