import { AuthProvider } from '../Authentication/Authentication';
import { authenticatedGet } from '../ServiceContext/service';
import { apiUrl } from '../ServiceContext/shared';
import { handleAPIResponse } from './response';

export type PricingInfo = {
  dentistPayout: number;
  anchorPrice: number;
  anchorSource: 'ada' | 'ucr';
  description: string;
};

export type ConsolidatedPricingTable = {
  dentistId: string;
  ucrFeeScheduleId: string;
  flossyFeeScheduleId: string;
  adaFeeScheduleId: string;
  pricings: {
    [code: string]: PricingInfo;
  };
};

export function codeToDescriptionFromPricingTable(table: ConsolidatedPricingTable): {
  [code: string]: string;
} {
  const codeToDescription: { [code: string]: string } = {};
  for (const code in table.pricings) {
    codeToDescription[code] = table.pricings[code].description;
  }
  return codeToDescription;
}

export async function getConsolidatedPricingTableForDentist({
  authProvider,
  dentistId,
}: {
  authProvider: AuthProvider;
  dentistId: string;
}): Promise<ConsolidatedPricingTable> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedGet<ConsolidatedPricingTable>(
      authProvider,
      apiUrl(`/dentists/${dentistId}/consolidated-pricing-table`)
    )
  );
}
