import cx from 'classnames';
import React from 'react';

import { Tracking } from '../../../amplitude';
import PaymentMethods from '../../../Invoices/PaymentMethods';
import {
  Invoice,
  InvoiceItem,
  LegacyPayment,
  LegacyPaymentSummary,
  patientSavingsFromPricingQuote,
  PaymentSplitSummary,
  PricingQuote,
} from '../../../ServiceContext/invoices';
import { stringifyMoney } from '../../../shared/money/stringifyMoney';
import SavingsSummary from '../SavingsSummary';
import { extractInvoicingComponents } from '../utilities';

type Props = {
  invoice: Invoice;
  disableTableRowStriping?: boolean;
  payment?: LegacyPayment;
  pricingQuote: PricingQuote | null;
  isInsurance: boolean;
  legacyPayment?: LegacyPaymentSummary | null;
  paymentSplits?: PaymentSplitSummary[];
} & Tracking;

const ItemizedInvoicePricingSheet: React.FC<Props> = ({
  legacyPayment,
  paymentSplits,
  invoice,
  disableTableRowStriping = false,
  payment,
  pricingQuote,
  isInsurance,
}) => {
  const getProcedureItems = () => {
    return invoice.invoiceItems.filter((i) => i.type === 'procedure');
  };
  const { financingFee, promoAmount, refundAmount, savingsAmount } = extractInvoicingComponents({
    invoice,
  });
  const totalRetailPrice =
    pricingQuote?.items.reduce((sum, item) => sum + item.anchorPrice, 0) || 0;

  const positiveSavings = savingsAmount > 0;

  const renderSelectedCDTsTable = () => {
    return (
      <table
        id="itemized-pricing-sheet"
        className={cx({
          table: true,
          'table-striped': !disableTableRowStriping,
          'mt-4': true,
          'text-left': true,
        })}
      >
        <thead>
          <tr className="title-row text-base text-secondary">
            <th className="cdt-code" scope="col">
              CDT Code
            </th>
            <th scope="col" colSpan={positiveSavings ? 1 : 2}>
              Procedure
            </th>
            {positiveSavings && (
              <th className="!text-secondary retail-price text-right" scope="col">
                Retail Price
              </th>
            )}
            <th className="flossy-price text-right " scope="col">
              Flossy Price
            </th>
          </tr>
        </thead>
        <tbody>
          {getProcedureItems().map((i: InvoiceItem, idx: number) => {
            const key = `${i.code}-${i.text}-${idx}`;
            let procedureSubtextElements: string[] = [];
            if (i.areaOfMouthCode) {
              procedureSubtextElements.push(`Area of mouth: ${i.areaOfMouthCode}`);
            }
            if (i.toothNumberCode) {
              procedureSubtextElements.push(`Tooth #: ${i.toothNumberCode}`);
            }
            if (i.toothSurfaceCode) {
              procedureSubtextElements.push(`Tooth Surface: ${i.toothSurfaceCode}`);
            }
            const procedureSubtext = procedureSubtextElements.join('; ');
            const pricingItem = pricingQuote?.items.find((item) => item.code === i.code);
            return (
              <tr
                key={key}
                className="procedure-table-content-row font-sans font-normal text-secondary"
              >
                <td className="procedure-code-content-data">
                  <span className="min-w-[150px] font-extrabold text-secondary text-base">
                    {i.code}
                  </span>
                </td>
                <td colSpan={positiveSavings ? 1 : 2}>
                  <div className="procedure-summary-content flex flex-col gap-1 text-secondary text-base">
                    <span>{i.text}</span>
                    {procedureSubtext && (
                      <div className="procedure-subtext italic text-sm text-secondary">
                        {procedureSubtext}
                      </div>
                    )}
                    <span className={'text-sm'}>{i.additionalNotes}</span>
                  </div>
                </td>
                {positiveSavings && (
                  <td className="!text-secondary text-right text-base opacity-50">
                    {pricingItem && (
                      <span style={{ textDecoration: 'line-through' }}>
                        {stringifyMoney(pricingItem.anchorPrice, { includeCommas: true })}
                      </span>
                    )}
                  </td>
                )}
                <td className="!text-secondary procedure-price text-right text-base">
                  <span>{stringifyMoney(i.amount, { includeCommas: true })}</span>
                </td>
              </tr>
            );
          })}
          {promoAmount !== 0 && (
            <tr id="promo-row">
              <td colSpan={3} className={'font-semibold'}>
                Promo
              </td>
              <td className={'text-right'}>
                {stringifyMoney(promoAmount * -1, { includeCommas: true })}
              </td>
            </tr>
          )}
          {financingFee > 0 && (
            <tr id={'financing-fee-row'}>
              <td colSpan={3} className={'font-semibold'}>
                Financing fee
              </td>
              <td className={'text-right'}>
                {stringifyMoney(financingFee, { includeCommas: true })}
              </td>
            </tr>
          )}
          {refundAmount !== 0 && (
            <tr id="refund-row">
              <td colSpan={3} className={'font-semibold'}>
                Refund
              </td>
              <td className={'text-right'}>
                {stringifyMoney(refundAmount, { includeCommas: true })}
              </td>
            </tr>
          )}
          {!isInsurance && (
            <tr className={'text-sm'}>
              <td
                colSpan={4}
                className={'text-left font-bold pl-6 py-2 text-base'}
                style={{ borderBottom: 'none' }}
              >
                Patient Payment Method
              </td>
            </tr>
          )}
          {!isInsurance && (
            <PaymentMethods legacyPayment={legacyPayment} paymentSplits={paymentSplits} />
          )}
          <tr id={'total-amount-row'} className={'text-xl font-bold'}>
            <td colSpan={positiveSavings ? 2 : 3} className={'text-center'}>
              Total Amount
            </td>
            {positiveSavings && (
              <td className={'text-right line-through opacity-50'}>
                {stringifyMoney(totalRetailPrice, { includeCommas: true })}
              </td>
            )}
            <td className={'text-right'}>
              {stringifyMoney(invoice.total, { includeCommas: true })}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const savingsPercentage = pricingQuote ? pricingQuote.realizedPatientSavings : null;
  const patientSavingsAmount = pricingQuote ? patientSavingsFromPricingQuote(pricingQuote) : null;

  const shouldDisplaySavings = !isInsurance && savingsPercentage && patientSavingsAmount;

  return (
    <>
      {renderSelectedCDTsTable()}
      <div className={'h-[48px] w-full'}></div>
      {shouldDisplaySavings && (
        <SavingsSummary
          savingsAmount={patientSavingsAmount}
          savingsPercentage={savingsPercentage}
        />
      )}
    </>
  );
};

export default ItemizedInvoicePricingSheet;
