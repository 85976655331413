import React from 'react';

import { emailRegex } from '../strings/stringValidation';
import ValidatedInput, { ValidatedInputProps, ValidationRule } from './ValidatedInput';

type EmailInputProps = {
  additionalValidations?: ValidationRule[];
  validationErrorMessageOverride?: string;
} & Pick<
  ValidatedInputProps,
  | 'id'
  | 'className'
  | 'disabled'
  | 'placeholder'
  | 'onChange'
  | 'onBlur'
  | 'supplementaryText'
  | 'allowClear'
>;

const EmailInput: React.FC<EmailInputProps> = ({
  additionalValidations,
  validationErrorMessageOverride,
  ...otherProps
}) => {
  const validations: ValidationRule[] = [
    {
      type: 'regex',
      regex: emailRegex,
      errorMessage: validationErrorMessageOverride || 'Please enter a valid email address.',
    },
    ...(additionalValidations || []),
  ];

  return <ValidatedInput validations={validations} {...otherProps} />;
};

export default EmailInput;
