import React from 'react';

import { AuthProvider } from '../../Authentication/Authentication';
import Alert, { errorAlert } from '../../shared/Alert';
import LoadingSpinner from '../../shared/LoadingSpinner';
import UpcomingPayoutTable from './UpcomingPayoutTable';
import { useAPI_upcomingPayout } from './useAPI_upcomingPayout';
import { usePayoutQuery } from './usePayoutQuery';

type Props = {
  authProvider: AuthProvider;
  practiceId: string;
  timeZone: string;
};

const UpcomingPayout: React.FC<Props> = ({ authProvider, practiceId, timeZone }) => {
  const { upcomingPayout, isLoading, error } = useAPI_upcomingPayout({
    authProvider,
    practiceId,
  });

  const { currentPage, onNewPageSelected } = usePayoutQuery();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Alert {...errorAlert(error)} />;
  }

  return (
    <UpcomingPayoutTable
      timeZone={timeZone}
      selectedPage={currentPage}
      payoutInfo={upcomingPayout || []}
      onNewPageSelected={onNewPageSelected}
    />
  );
};

export default UpcomingPayout;
