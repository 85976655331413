import { useState } from 'react';

import { DetailedTreatmentPlan, TreatmentPlanState } from '../../API/treatmentPlans';
import { XMark } from '../../HomePage/components/Icons';

export default function TreatmentPlanBanner({
  treatmentPlan,
}: {
  treatmentPlan: DetailedTreatmentPlan;
}) {
  const [showBanner, setShowBanner] = useState(true);

  let stateTitles: { [key in TreatmentPlanState]?: { title: string; description: string } } = {
    [TreatmentPlanState.PROPOSED]: {
      title: 'Treatment Plan Proposal generated successfully.',
      description: 'A new treatment plan has been generated below.',
    },
    [TreatmentPlanState.ACCEPTED]: {
      title: 'Treatment Plan Proposal has been Accepted',
      description: 'You can now book appointments and create invoices from this Treatment Plan.',
    },
    [TreatmentPlanState.IN_PROGRESS]: {
      title: 'Treatment Plan Proposal has been Accepted',
      description: 'You can now book appointments and create invoices from this Treatment Plan.',
    },
    [TreatmentPlanState.CANCELLED]: {
      title: 'Treatment Plan Proposal has been Cancelled.',
      description: '',
    },
    [TreatmentPlanState.REJECTED]: {
      title: 'Treatment Plan Proposal has been Rejected.',
      description: '',
    },
    [TreatmentPlanState.COMPLETED]: {
      title: 'Treatment Plan Proposal has been marked as Complete.',
      description: '',
    },
  };

  return (
    <div className="w-full">
      {showBanner ? (
        <div className="px-4 py-3.5 bg-info rounded-lg justify-start items-center gap-2.5 flex w-full mb-4">
          <div className="text-base-content text-sm font-bold">
            {stateTitles[treatmentPlan.state]?.title}
          </div>
          <div className="text-base-content text-sm font-normal grow text-start">
            {' '}
            {stateTitles[treatmentPlan.state]?.description}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setShowBanner(false);
            }}
          >
            <XMark />
          </div>
        </div>
      ) : null}
    </div>
  );
}
