import React from 'react';

import Frame from '../../Frame/Frame';

const ErrorPage: React.FC = () => {
  return (
    <Frame>
      <p>
        An unexpected error has occurred. If you continue to experience this error, please contact
        Flossy support.
      </p>

      <a href="/">Return to Home</a>
    </Frame>
  );
};

export default ErrorPage;
