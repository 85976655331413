import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import { GeneralWorkflow } from '../../amplitude';
import { deletePaymentMethod } from '../../API/practice';
import { formatCardTypeName } from '../../Appointments/Invoicing/utilities';
import { AuthProvider } from '../../Authentication/Authentication';
import {
  cardExpirationFromPaymentMethod,
  cardLastFourFromPaymentMethod,
  PaymentMethod,
} from '../../ServiceContext/invoices';
import { Practice } from '../../ServiceContext/user';
import Button from '../../shared/Button/Button';

export default function DefaultPaymentMethod({
  paymentMethod,
  authProvider,
  practice,
}: {
  paymentMethod: PaymentMethod;
  authProvider: AuthProvider;
  practice: Practice;
}) {
  const queryClient = useQueryClient();

  const additionalPaymentMethods = practice.paymentMethods.filter(
    (method) => method.id !== practice.defaultPaymentMethod?.id
  );

  return (
    <div className="mt-2 p-4 border rounded-lg flex justify-between items-center">
      <div className="text-base-content text-start">
        <span className="font-semibold">{`${formatCardTypeName(paymentMethod.type)}`}</span>
        {` ending in `}
        <span className="font-semibold">{`${
          cardLastFourFromPaymentMethod(paymentMethod) || ''
        }`}</span>
      </div>
      <div className="text-base-content text-start">
        Exp: {cardExpirationFromPaymentMethod(paymentMethod)}
      </div>
      <div className="flex space-x-2">
        <div
          data-tooltip-id={
            additionalPaymentMethods.length === 0
              ? 'remove-default-payment-method-tooltip'
              : undefined
          }
          data-tooltip-content={
            additionalPaymentMethods.length === 0
              ? 'To remove, add another payment method.'
              : undefined
          }
          data-tooltip-place={additionalPaymentMethods.length === 0 ? 'bottom' : undefined}
        >
          <Button
            className="px-4 py-2 bg-blue-600 rounded-lg"
            id="remove-default-payment-method-button"
            onClick={async () => {
              if (additionalPaymentMethods.length > 0) {
                try {
                  const updatedPractice = await deletePaymentMethod({
                    paymentMethodId: paymentMethod.id,
                    authProvider: authProvider,
                    practiceId: practice.id,
                  });

                  queryClient.setQueryData(['practice', practice?.id], updatedPractice);
                  toast.success('Payment method successfully deleted.');
                } catch (error) {
                  console.error(error);
                  toast.error('Failed to delete payment method.');
                }
              } else {
                toast.error(
                  'Cannot delete default payment method. Add another payment method first.'
                );
              }
            }}
            noFill
            workflow={GeneralWorkflow}
            context="removeDefaultPaymentMethod"
            trackingLabel="Remove Default Payment Method"
          >
            Remove
          </Button>
        </div>
        <Tooltip id="remove-default-payment-method-tooltip" />
      </div>
    </div>
  );
}
