import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { getPaymentLink, PaymentLinkContent } from '../../../API/bookingsAndCredits';
import { createCheckoutSessionUnauthenticated } from '../../../API/checkoutSessions';
import { ErrorResponse } from '../../../API/response';
import Alert, { AlertData, errorAlert } from '../../../shared/Alert';
import { redirectToExternalUrl, useURLQuery } from '../../../shared/routing/routing';
import BalancePaid from './BalancePaid';
import ContinueToStripe from './ContinueToStripe';
import PaymentConfirmation from './PaymentConfirmation';

const PaymentLinkContentPage: React.FC = () => {
  const { getQuery } = useURLQuery();
  const paymentLinkId = getQuery('identifier') || '';
  const isPaymentSuccessful = (getQuery('paymentSuccessful') || '') === 'true';

  const [alert, setAlert] = useState<AlertData | null>(null);

  const getPaymentLinkKey = useMemo(() => ['getPaymentLinkKey', paymentLinkId], [paymentLinkId]);

  const { error: getPaymentLinkError, data: paymentLink } = useQuery<
    PaymentLinkContent,
    ErrorResponse
  >(
    getPaymentLinkKey,
    () => {
      return getPaymentLink({
        paymentLinkId,
      });
    },
    {
      enabled: Boolean(paymentLinkId),
    }
  );

  const { mutate: createCheckoutSession, isLoading: isCreatingCheckoutSession } = useMutation(
    createCheckoutSessionUnauthenticated,
    {
      onSuccess: (res) => {
        redirectToExternalUrl(res.redirectUrl);
      },
      onError: (error) => {
        setAlert(errorAlert(error as any));
      },
    }
  );

  return (
    <div className="flex flex-col items-center">
      {alert && <Alert {...alert} />}
      {getPaymentLinkError && <Alert {...errorAlert(getPaymentLinkError)} />}
      {paymentLink && !isPaymentSuccessful && (
        <ContinueToStripe
          onClick={() => createCheckoutSession({ paymentLinkId })}
          isLoading={isCreatingCheckoutSession}
          practiceName={paymentLink.practiceName}
          balanceDue={paymentLink.balanceDue}
          paymentDetailsData={paymentLink.details?.data || null}
        />
      )}
      {paymentLink && isPaymentSuccessful && (
        <PaymentConfirmation
          balancePaid={paymentLink.balanceDue}
          paymentDate={paymentLink.paymentDate || ''}
          paymentDetailsData={paymentLink.details?.data || null}
        />
      )}
      {paymentLink && !isPaymentSuccessful && paymentLink.paymentDate && (
        <BalancePaid
          balancePaid={paymentLink.balanceDue}
          paymentDate={paymentLink.paymentDate || ''}
          paymentDetailsData={paymentLink.details?.data || null}
        />
      )}
    </div>
  );
};

export default PaymentLinkContentPage;
