import { BookingsBreakdown, LedgerMonth } from '../../../../API/bookingsAndCredits';
import BookingCharges from './BookingCharges';
import FlossyBookings from './FlossyBookings';
import FlossyCredits from './FlossyCredits';

type BookingsAndCreditsStatsProps = {
  breakdown: BookingsBreakdown;
  totalCredits: number;
  totalCreditsCount: number;
  charges: LedgerMonth['charges'];
};

const HorizontalSeparator = () => <div className="h-px bg-rule my-4 w-[70%] translate-x-4"></div>;

const BookingsAndCreditStats: React.FC<BookingsAndCreditsStatsProps> = ({
  breakdown,
  totalCredits,
  totalCreditsCount,
  charges,
}) => {
  return (
    <div id={'bookings-and-credits-stats'} className={'flex flex-col bg-white rounded-md'}>
      <FlossyBookings data={breakdown} />
      <HorizontalSeparator />
      <BookingCharges {...charges} />
      <HorizontalSeparator />
      <FlossyCredits totalCreditsSum={totalCredits} totalCreditsCount={totalCreditsCount} />
    </div>
  );
};

export default BookingsAndCreditStats;
