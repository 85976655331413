import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

type NewTooltipProps = {
  text: string;
};

const NewTooltip: React.FC<PropsWithChildren<NewTooltipProps>> = ({ text, children }) => {
  const [isTooltipVisible, setTooltipVisibility] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const childRef = useRef<HTMLElement>(null);

  const onMouseEnter = useCallback(() => {
    if (text && childRef.current) {
      const rect = childRef.current.getBoundingClientRect();
      setPosition({
        left: rect.left + window.scrollX,
        top: rect.bottom + window.scrollY + 10,
      });
    }
    setTooltipVisibility(true);
  }, [text]);

  return (
    <div className="tooltip-container relative inline-block">
      {React.cloneElement(React.Children.only(children) as React.ReactElement, {
        ref: childRef,
        onMouseEnter,
        onMouseLeave: () => {
          setTooltipVisibility(false);
        },
      })}
      {isTooltipVisible &&
        text &&
        ReactDOM.createPortal(
          <div
            style={{
              position: 'fixed',
              top: `${position.top}px`,
              left: `${position.left}px`,
              zIndex: 1000,
            }}
            className="px-2 py-1 bg-base-content text-white text-sm rounded-md shadow-lg w-64"
          >
            {text}
          </div>,
          document.body
        )}
    </div>
  );
};

export default NewTooltip;
