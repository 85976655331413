import { useState } from 'react';

import { TreatmentPlanWorkflow } from '../../amplitude';
import { DetailedTreatmentPlan, updateTreatmentPlanName } from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import { EditIcon } from '../../HomePage/components/Icons';
import Button from '../../shared/Button/Button';

export default function TreatmentPlanHeader({
  treatmentPlan,
  authProvider,
  onNameUpdated,
}: {
  treatmentPlan: DetailedTreatmentPlan;
  authProvider: AuthProvider;
  onNameUpdated: () => void;
}) {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState<string>(treatmentPlan.name || `Treatment Plan`);
  const [updatingName, setUpdatingName] = useState(false);

  const handleUpdateName = async () => {
    setUpdatingName(true);
    await updateTreatmentPlanName({
      authProvider: authProvider,
      name: name,
      treatmentPlanId: treatmentPlan.id,
    });
    setUpdatingName(false);
    setEditMode(false);
    onNameUpdated();
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await handleUpdateName();
    }
  };

  if (editMode) {
    return (
      <div className="flex items-center gap-2">
        <input
          className="border border-primary rounded-md px-2 py-1 text-3xl"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Enter Name"
        />
        <Button
          disabled={updatingName}
          onClick={handleUpdateName}
          workflow={TreatmentPlanWorkflow}
          trackingLabel="Edit Name Button"
          context="treatmentPlanHeader"
        >
          Edit Name
        </Button>
        <Button
          noFill
          className="bg-base-100"
          onClick={() => {
            setEditMode(false);
          }}
          workflow={TreatmentPlanWorkflow}
          trackingLabel="Cancel Edit Name Button"
          context="treatmentPlanHeader"
        >
          Cancel
        </Button>
      </div>
    );
  }
  return (
    <div className="flex items-center gap-4">
      <h5 className="text-left font-bold text-3xl">{name}</h5>
      <button
        className="bg-primary rounded-md text-base-100 px-2 py-1"
        onClick={() => {
          setEditMode(true);
        }}
      >
        <div className="flex items-center gap-2">
          <div>Edit Name</div>
          <EditIcon />
        </div>
      </button>
    </div>
  );
}
