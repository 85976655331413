import { useRef } from 'react';
import ReactToPrint from 'react-to-print';

import { InvoicingWorkflow } from '../../../amplitude';
import {
  Invoice,
  LegacyPaymentSummary,
  PaymentSplitSummary,
  PricingQuote,
} from '../../../ServiceContext/invoices';
import { Address } from '../../../ServiceContext/shared';
import Button from '../../../shared/Button/Button';
import PrintDropdownButton from '../../../shared/PrintDropdownButton';
import InvoicePrintout from './InvoicePrintout';

export const useInvoicePrintout = ({
  appointmentTimeInUTC,
  appointmentTimeZone,
  dentistLocation,
  invoice,
  dentistName,
  patientName,
  isInsurance,
  paymentLastFour,
  patientOnly,
  legacyPayment,
  paymentSplits,
}: {
  appointmentTimeInUTC: string;
  appointmentTimeZone: string;
  dentistLocation: Address;
  invoice: Invoice | null;
  dentistName: string;
  patientName: string;
  isInsurance: boolean;
  paymentLastFour: string;
  patientOnly?: boolean;
  legacyPayment?: LegacyPaymentSummary | null;
  paymentSplits?: PaymentSplitSummary[];
}) => {
  const dentistPrintContentRef = useRef<HTMLDivElement>(null);
  const patientPrintContentRef = useRef<HTMLDivElement>(null);

  let pricingQuote: PricingQuote | null = invoice?.pricingQuote || null;

  if (!invoice || !pricingQuote) {
    return {
      printButton: null,
      printComponent: null,
    };
  }

  if (patientOnly) {
    return {
      printButton: (
        <ReactToPrint
          trigger={() => {
            return (
              <Button
                id="print-button"
                className="mr-2"
                omitBorder
                onClick={() => {}}
                trackingLabel="Print Invoice Button"
                workflow={InvoicingWorkflow}
              >
                Print
              </Button>
            );
          }}
          content={() => patientPrintContentRef.current}
        />
      ),
      printComponent: (
        <div className="content-to-print hidden">
          <InvoicePrintout
            pricingQuote={pricingQuote}
            appointmentTimeInUTC={appointmentTimeInUTC}
            appointmentTimeZone={appointmentTimeZone}
            dentistLocation={dentistLocation}
            dentistName={dentistName}
            invoice={invoice}
            patientName={patientName}
            isInsurance={isInsurance}
            paidDate={undefined}
            paymentLastFour={paymentLastFour}
            ref={patientPrintContentRef}
            showSignatureLine
            legacyPayment={legacyPayment}
            paymentSplits={paymentSplits}
          />
        </div>
      ),
    };
  }

  return {
    printButton: (
      <PrintDropdownButton
        label="Print for..."
        options={[
          {
            id: 'dentist',
            label: 'Dentist',
            onBeforePrint: () => {},
            ref: dentistPrintContentRef,
          },
          {
            id: 'patient',
            label: 'Patient',
            onBeforePrint: () => {},
            ref: patientPrintContentRef,
          },
        ]}
      />
    ),
    printComponent: (
      <>
        <div className="content-to-print hidden">
          <InvoicePrintout
            pricingQuote={pricingQuote}
            appointmentTimeInUTC={appointmentTimeInUTC}
            appointmentTimeZone={appointmentTimeZone}
            dentistLocation={dentistLocation}
            dentistName={dentistName}
            invoice={invoice}
            patientName={patientName}
            isInsurance={isInsurance}
            paidDate={undefined}
            paymentLastFour={paymentLastFour}
            ref={dentistPrintContentRef}
            legacyPayment={legacyPayment}
            paymentSplits={paymentSplits}
          />
          <InvoicePrintout
            pricingQuote={pricingQuote}
            appointmentTimeInUTC={appointmentTimeInUTC}
            appointmentTimeZone={appointmentTimeZone}
            dentistLocation={dentistLocation}
            dentistName={dentistName}
            invoice={invoice}
            patientName={patientName}
            isInsurance={isInsurance}
            paidDate={undefined}
            paymentLastFour={paymentLastFour}
            ref={patientPrintContentRef}
            showSignatureLine
            legacyPayment={legacyPayment}
            paymentSplits={paymentSplits}
          />
        </div>
      </>
    ),
  };
};
