import React from 'react';

interface PageTopBarProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
}

const PageTopBar: React.FC<PageTopBarProps> = ({ title, children, ...props }) => {
  return (
    <div
      className={`title-and-controls font-sans text-secondary flex flex-row justify-between w-full ${
        props.className || ''
      }`}
      {...props}
    >
      <div className="details-title font-medium text-2xl">{title}</div>
      <div className="control-buttons flex self-end items-end gap-3">{children}</div>
    </div>
  );
};

export default PageTopBar;
