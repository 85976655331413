import { GeneralWorkflow } from '../../amplitude';
import Button from '../../shared/Button/Button';

export default function AddPaymentMethodButton({
  setIsNewCardModalOpen,
}: {
  setIsNewCardModalOpen: (arg0: boolean) => void;
}) {
  return (
    <div>
      <Button
        className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg"
        id="send-instructions-button"
        onClick={() => {
          setIsNewCardModalOpen(true);
        }}
        omitBorder
        workflow={GeneralWorkflow}
        context="addPaymentMethod"
        trackingLabel="Add Payment Method Button"
      >
        + Add a New Payment Method
      </Button>
      <div className="grow"></div>
    </div>
  );
}
