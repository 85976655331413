import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { useServices } from '../ServiceContext/ServiceContext';
import { AvailabilityEvent } from '../ServiceContext/calendar';
import { Dentist } from '../ServiceContext/user';
import Alert, { AlertData, errorAlert, successAlert } from '../shared/Alert';
import LoadingSpinner from '../shared/LoadingSpinner';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EventClickArg } from '@fullcalendar/core';
import DeleteAvailabilityEventModal, { EventDeletionModel } from './DeleteAvailabilityEvent';
import { recordAvailabilitySpotCreated, recordAvailabilitySpotDeleted } from '../shared/analytics';
import { toShortDateTimeString } from '../shared/dates/dates';
import AddAvailability from './AddAvailability';
import Button from '../shared/Button/Button';
import LinkText from '../shared/Text/LinkText';
import { isErrorResponse } from '../API/response';
import Modal from '../shared/Modal/Modal';
import { AvailabilityWorkflow } from '../amplitude';

import { getTimezoneFromDentist } from '../shared/time/time';
import { getTimeZoneOfDentist } from '../shared/timezone/timeZone';

interface Props {
  dentist: Dentist;
  onClearDentist?: (() => void) | null;
}

const Calendar: React.FC<Props> = ({ dentist, onClearDentist }) => {
  const calendarRef = useRef<FullCalendar>(null);

  const services = useServices();

  const [events, setEvents] = useState<AvailabilityEvent[]>([]);
  const [fetchingEvents, setFetchingEvents] = useState(false);
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [showMarkAvailabilityModal, setShowMarkAvailabilityModal] = useState(false);
  const [showDeleteAvailabilityModal, setShowDeleteAvailabilityModal] = useState(false);
  const [consideringEventDeletion, setConsideringEventDeletion] =
    useState<EventDeletionModel | null>(null);
  const [viewDate, setViewDate] = useState<Date | undefined>(undefined);

  const fetchCalendarEvents = useCallback(async () => {
    setFetchingEvents(true);
    const res = await services.calendarService.getAvailabilityEventsForDentist(dentist.id);
    setFetchingEvents(false);

    if (isErrorResponse(res)) {
      setAlert(errorAlert(res.errorResponse));
      return;
    }

    setEvents(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dentist.id]);

  useEffect(() => {
    fetchCalendarEvents().then();
  }, [fetchCalendarEvents]);

  const onMarkAvailabilityClicked = useCallback(() => {
    setShowMarkAvailabilityModal(true);
  }, []);

  const onAvailabilityAdded = async ({
    newAvailabilities,
    deletedAvailabilities,
    availabilityDate,
  }: {
    newAvailabilities: AvailabilityEvent[];
    deletedAvailabilities: AvailabilityEvent[];
    availabilityDate: Date;
  }) => {
    setShowMarkAvailabilityModal(false);
    await recordAvailabilitySpotCreated(`${dentist.firstName} ${dentist.lastName}`);
    const newEvents = [
      ...events.filter((e) => !deletedAvailabilities.find((d) => d.id === e.id)),
      ...newAvailabilities,
    ].sort((a, b) => a.start.localeCompare(b.start));
    setEvents(newEvents);
    setViewDate(availabilityDate);

    const formattedDateTime = toShortDateTimeString(
      availabilityDate.toISOString(),
      getTimezoneFromDentist(dentist) || undefined
    );
    setAlert(
      successAlert(
        <div>
          Availability added successfully on{' '}
          <LinkText
            onClick={() => {
              setViewDate(availabilityDate);
              if (calendarRef.current) {
                calendarRef.current.getApi().gotoDate(availabilityDate);
              }
            }}
          >
            {formattedDateTime}
          </LinkText>
        </div>
      )
    );
  };

  const onEventClicked = (info: EventClickArg) => {
    if (!info.event.start || !info.event.end) {
      return;
    }

    setConsideringEventDeletion({
      id: info.event.id,
      title: info.event.title,
      start: info.event.start,
      end: info.event.end,
    });
    setShowDeleteAvailabilityModal(true);
  };

  const onEventDeletionConfirmed = async () => {
    setShowDeleteAvailabilityModal(false);
    await recordAvailabilitySpotDeleted(`${dentist.firstName} ${dentist.lastName}`);
    await fetchCalendarEvents();
    if (consideringEventDeletion) {
      setViewDate(consideringEventDeletion.start);
    }
    setConsideringEventDeletion(null);
    setAlert(null);
  };

  if (fetchingEvents) {
    return <LoadingSpinner />;
  }

  const renderAlert = () => {
    if (alert) {
      return <Alert {...alert} />;
    }

    return null;
  };

  const renderExplanatoryText = () => {
    return (
      <div className="explanatory-text text-left">
        <p>
          Welcome to your Flossy calendar! Add at least 3 available time slots a week for patients
          to book with this Dentist on Flossy. Each time slot should be a minimum of an hour. As the
          availability changes, click on the time slot to delete it.
        </p>
      </div>
    );
  };

  const renderMarkAvailabilityModal = () => {
    return (
      <Modal
        isOpen={showMarkAvailabilityModal}
        onRequestClose={() => {
          setShowMarkAvailabilityModal(false);
        }}
        shape="square"
      >
        <AddAvailability
          dentist={dentist}
          onCancelled={() => setShowMarkAvailabilityModal(false)}
          onAvailabilityAdded={onAvailabilityAdded}
        />
      </Modal>
    );
  };

  const renderDeleteAvailabilityModal = () => {
    if (!consideringEventDeletion) {
      return null;
    }
    return (
      <Modal
        isOpen={showDeleteAvailabilityModal}
        onRequestClose={() => setShowDeleteAvailabilityModal(false)}
        shape="square"
      >
        <DeleteAvailabilityEventModal
          event={consideringEventDeletion}
          dentist={dentist}
          onDeletionConfirmed={onEventDeletionConfirmed}
          onCancelled={() => setShowDeleteAvailabilityModal(false)}
        />
      </Modal>
    );
  };

  const dentistName = `${dentist.firstName} ${dentist.lastName}`;

  const timeZone = getTimeZoneOfDentist(dentist);

  return (
    <div className="availability-calendar flex flex-col text-[#5a5a5a]">
      <div
        id="availability-header"
        className="availability-header flex flex-row items-center pb-4 mb-3 relative"
      >
        <div className="dentist-name font-semibold text-xl">
          {dentistName} ({dentist.username})
        </div>
        {onClearDentist && (
          <Button
            id="switch-dentist-button-for-availability"
            className="switch-dentist-button-for-availability absolute left-[420px]"
            onClick={onClearDentist}
            noFill
            workflow={AvailabilityWorkflow}
            context="calendar"
            trackingLabel="Switch Dentist Button"
          >
            Switch dentist
          </Button>
        )}
      </div>
      {renderExplanatoryText()}
      {renderAlert()}
      <div className="separator w-full my-4 border-b" />
      <div className="calendar-container">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
          initialView="timeGridWeek"
          slotMinTime="06:00:00"
          slotMaxTime="20:00:01"
          initialDate={viewDate}
          timeZone={timeZone}
          headerToolbar={{
            left: 'dayGridMonth,timeGridWeek,timeGridDay createEvent',
            center: 'title',
            right: 'prev,next',
          }}
          customButtons={{
            createEvent: {
              text: '+ Add Availability',
              click: onMarkAvailabilityClicked,
            },
            prev: {
              click: () => {
                if (calendarRef.current) {
                  const currentDate = calendarRef.current.getApi().getDate();
                  // the behavior here is very flaky due to timezones, so giving
                  // ourselves some wiggle room by using 2 (shaving a couple of
                  // days off what the calendar API gives us) helps keep the behavior
                  // stable
                  currentDate.setDate(calendarRef.current.getApi().view.activeStart.getDate() - 2);
                  setViewDate(currentDate);
                  calendarRef.current.getApi().prev();
                }
              },
            },
            next: {
              click: () => {
                if (calendarRef.current) {
                  const currentDate = calendarRef.current.getApi().getDate();
                  // the behavior here is very flaky due to timezones, so giving
                  // ourselves some wiggle room (by using 9, which is a couple more
                  // than the number of days in a week) helps keep the behavior stable
                  currentDate.setDate(calendarRef.current.getApi().view.activeEnd.getDate() + 2);
                  setViewDate(currentDate);
                  calendarRef.current.getApi().next();
                }
              },
            },
          }}
          contentHeight="auto"
          eventClick={onEventClicked}
          events={events.map((e) => {
            const availableColor = '#3788d8';
            const unavailableColor = '#b22b27';
            let eventColor;
            if (e.status === 'busy') {
              eventColor = unavailableColor;
            } else {
              eventColor = availableColor;
            }
            return {
              id: e.id,
              title: e.title,
              start: e.start,
              end: e.end,
              allDay: false,
              color: eventColor,
            };
          })}
        />
      </div>
      {renderMarkAvailabilityModal()}
      {renderDeleteAvailabilityModal()}
    </div>
  );
};

export default Calendar;
