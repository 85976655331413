import React, { useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import { useQuery, useQueryClient } from 'react-query';

import { Tracking } from '../../amplitude';
import { getPatientInsuranceForAppointment, PatientInsurance } from '../../API/insurance';
import { ErrorResponse } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import { Appointment, appointmentHasInsurance } from '../../ServiceContext/appointments';
import Alert, { errorAlert } from '../../shared/Alert';
import LoadingSpinner from '../../shared/LoadingSpinner';
import useLockBodyScroll from '../../shared/Modal/useLockBodyScroll';
import CreatePatientInsuranceForm from './CreatePatientInsuranceForm';
import InsuranceCreationAcknowledgement from './InsuranceCreationAcknowledgement';
import InsuranceInformation from './InsuranceInformation';

type Props = {
  authProvider: AuthProvider;
  appointment: Appointment;
  onAppointmentUpdated: (newValue: Appointment) => void;
  onCancel: () => void;
  onProcessTransactionClicked: () => void;
} & Tracking;

const ReviewPatientInsuranceModal: React.FC<Props> = ({
  authProvider,
  appointment,
  onAppointmentUpdated,
  onCancel,
  onProcessTransactionClicked,
  ...tracking
}) => {
  useLockBodyScroll(true);

  const queryClient = useQueryClient();

  const getPatientInsuranceQueryKey = useMemo(
    () => ['getPatientInsuranceForAppointment', appointment.id],
    [appointment.id]
  );
  const {
    isLoading: isLoadingPatientInsurance,
    error: fetchError,
    data: patientInsurance,
  } = useQuery<PatientInsurance | null, ErrorResponse>(
    getPatientInsuranceQueryKey,
    () => {
      if (!appointment || !appointment.id) {
        return null;
      }
      return getPatientInsuranceForAppointment({
        authProvider,
        appointmentId: appointment.id,
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: appointmentHasInsurance(appointment),
    }
  );

  const [hasAcknowledgedIntentToChange, setHasAcknowledgedIntentToChange] = useState(false);

  let modalContent: React.ReactNode | null;

  if (isLoadingPatientInsurance) {
    modalContent = (
      <div className="flex flex-row items-center justify-center w-[50vw] h-[80vh]">
        <LoadingSpinner />
      </div>
    );
  } else if (fetchError) {
    modalContent = <Alert {...errorAlert(fetchError)} />;
  } else if (patientInsurance) {
    modalContent = (
      <InsuranceInformation
        patientInsurance={patientInsurance}
        onCancel={onCancel}
        onProcessTransaction={onProcessTransactionClicked}
      />
    );
  } else if (!hasAcknowledgedIntentToChange) {
    modalContent = (
      <InsuranceCreationAcknowledgement
        onContinue={() => setHasAcknowledgedIntentToChange(true)}
        onCancel={onCancel}
      />
    );
  } else {
    modalContent = (
      <CreatePatientInsuranceForm
        authProvider={authProvider}
        appointment={appointment}
        onAppointmentUpdated={onAppointmentUpdated}
        onCancel={onCancel}
        queryContext={{
          client: queryClient,
          key: getPatientInsuranceQueryKey,
        }}
      />
    );
  }

  return (
    <ReactModal
      isOpen
      contentLabel="Create Patient Insurance"
      className="outline-none mx-auto my-10 bg-white rounded-lg md:w-3/4 lg:w-[25%] max-h-[85%] overflow-auto text-secondary"
      overlayClassName="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
    >
      <h2 className="text-2xl font-bold pt-8 pl-8">Review Insurance</h2>
      {modalContent}
    </ReactModal>
  );
};

export default ReviewPatientInsuranceModal;
