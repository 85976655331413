import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

import { Tracking } from '../../../amplitude';
import { BookingCharge, BookingLedger, refundCharge } from '../../../API/bookingsAndCredits';
import { QueryContext } from '../../../API/context';
import { AuthProvider } from '../../../Authentication/Authentication';
import Alert, { AlertData, errorAlert } from '../../../shared/Alert';
import Button from '../../../shared/Button/Button';

type Props = {
  authProvider: AuthProvider;
  onRefundCompleted: (refundedCharge: BookingCharge) => void;
  practiceId: string;
  charge: BookingCharge;
  onCancel: () => void;
  queryContext?: QueryContext;
} & Tracking;
const RefundChargeForm: React.FC<Props> = ({
  authProvider,
  onRefundCompleted,
  practiceId,
  charge,
  onCancel,
  queryContext,
  ...tracking
}) => {
  const [alert, setAlert] = useState<AlertData | null>(null);
  const [reason, setReason] = useState('');
  const [isRefundSuccessful, setIsRefundSuccessful] = useState<boolean>(false);

  const isFormFilled: boolean = Boolean(reason);

  const refundMutation = useMutation(refundCharge, {
    onSuccess: (updatedCharge) => {
      setAlert(null);
      onRefundCompleted(updatedCharge);

      const currentBookingLedger = queryContext?.client.getQueryData<BookingLedger>(
        queryContext?.key
      );

      if (currentBookingLedger) {
        queryContext?.client.setQueryData(queryContext?.key, currentBookingLedger);
      }
      setIsRefundSuccessful(true);
    },
    onError: (error) => {
      setAlert(errorAlert(error as any));
    },
  });

  const { isLoading: isRefunding } = refundMutation;

  const onSubmit = (e: React.FormEvent) => {
    if (!isFormFilled) {
      return;
    }

    e.preventDefault();

    refundMutation.mutate({
      authProvider,
      practiceId,
      reason,
      bookingChargeId: charge.id,
    });
  };

  if (isRefundSuccessful) {
    return (
      <div className={'flex flex-col gap-3 items-center'}>
        <div
          id="success-notification"
          className="rounded-md flex flex-row gap-3 w-[90%] items-center justify-center bg-primary-subtle py-3 pl-4 m-16 text-sm"
        >
          <FontAwesomeIcon icon={faCircleCheck} className={'text-primary size-5'} />
          <span className="font-bold">Refund processed successfully!</span>
        </div>
        <div className={'mb-4'}>
          <Button
            onClick={onCancel}
            className="bg-white text-primary border border-rule shadow-none hover:opacity-[75%]"
            context="refunding charge"
            trackingLabel="Close Button"
            {...tracking}
          >
            Close
          </Button>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit} className="pt-4 pl-4 pr-4 pb-20 bg-white rounded-md relative">
      <h2 className="text-2xl font-bold mb-4 pt-8 ml-6">Refund Charge</h2>
      {alert && <Alert {...alert} />}

      <div className="p-4 bg-white rounded-md">
        <div className="mb-4">
          <label className="block text-base font-bold mb-4" htmlFor="firstName">
            Reason
          </label>
          <textarea
            className={`appearance-none border rounded w-full py-2 px-3 outline-0 ${
              isRefunding ? 'bg-base-200 cursor-not-allowed' : ''
            }`}
            id="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Please enter a reason for refunding this charge"
            disabled={isRefunding}
          />
        </div>
      </div>

      <div className="flex flex-col items-end space-y-4 absolute bottom-4 right-4">
        <div className="flex space-x-4">
          <Button
            onClick={onCancel}
            disabled={isRefunding}
            className="bg-white text-primary border border-rule shadow-none hover:opacity-[75%]"
            context="refunding charge"
            trackingLabel="Cancel Refund Button"
            {...tracking}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            className="bg-primary text-white hover:opacity-[75%]"
            disabled={!isFormFilled || isRefunding}
            loading={isRefunding}
            trackingLabel="Refund Button"
            context="refunding charge"
            {...tracking}
          >
            Refund
          </Button>
        </div>
      </div>
    </form>
  );
};

export default RefundChargeForm;
