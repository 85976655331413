import { useEffect, useState } from 'react';

import { AuthProvider } from '../../Authentication/Authentication';
import { ArrowDown, CrowdIcon } from '../../HomePage/components/Icons';
import { Practice } from '../../ServiceContext/user';

export default function PracticeDropDown({
  authProvider,
  setSelectedPractice,
  selectedPractice,
}: {
  authProvider: AuthProvider;
  setSelectedPractice: (practice: Practice | undefined) => void;
  selectedPractice: Practice | undefined;
}) {
  const practices = authProvider.authUser?.user.practices;

  // State to manage dropdown visibility
  const [isOpen, setIsOpen] = useState(false);

  // Effect to set the default selected practice when practices are available
  useEffect(() => {
    if (practices && practices.length > 0) {
      setSelectedPractice(practices![0]);
    } else {
      setSelectedPractice(undefined);
    }
  }, [practices, setSelectedPractice]);

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle selecting a practice
  const selectPractice = (practice: Practice) => {
    setSelectedPractice(practice);
    setIsOpen(false); // Close dropdown after selection
  };

  return (
    <div className="pb-4">
      <button
        className={`w-[350px] bg-secondary text-white inline-flex 
        items-center font-semibold px-4 py-3 text-sm border border-rule rounded-lg`}
        id="practice-menu"
        onClick={toggleDropdown}
      >
        <div className="flex flex-row gap-2 items-center grow">
          <div className="h-6 w-6 place-content-center">
            <CrowdIcon />
          </div>
          <div className="line-clamp-1 grow text-start">
            {selectPractice !== null ? selectedPractice?.name : 'No Practice'}
          </div>
        </div>
        <ArrowDown />
      </button>
      {isOpen && (
        <div className="origin-top-right absolute mt-2 py-1 w-[350px] rounded-md shadow-lg bg-white z-40 items-start flex flex-col">
          {practices!.map((practice) => (
            <div
              key={practice.id}
              className="w-full text-left px-4 py-2 text-sm text-base-content hover:bg-base-200"
              onClick={() => selectPractice(practice)}
            >
              {practice.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
