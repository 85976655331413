import { AuthProvider } from '../Authentication/Authentication';
import { authenticatedGet } from '../ServiceContext/service';
import { apiUrl, Timestamp } from '../ServiceContext/shared';
import { handleAPIResponse } from './response';

export enum ReviewSource {
  // The review comes from our own review collection system.
  FLOSSY = 'flossy',

  // The review was imported from Google via the SerpApi (https://serpapi.com/).
  GOOGLE_VIA_SERP_API = 'google_via_serp_api',
}

export type Review = {
  id: string;
  dentistId: string | null;
  practiceId: string | null;
  sourceType: ReviewSource;
  sourceId: string;
  googlePlaceId: string | null;
  raw: JSON | null;
  text: string | null;
  rating: number;
  reviewerName: string;
  reviewerPhotoUrl: string | null;
  postDate: Timestamp;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};

export async function getReviews({
  authProvider,
  practiceId,
}: {
  authProvider: AuthProvider;
  practiceId: string;
}): Promise<Review[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedGet<Review[]>(authProvider, apiUrl(`/practices/${practiceId}/reviews`))
  );
}
