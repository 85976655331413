import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';

import { Dentist } from '../../ServiceContext/user';
import SearchSelect, { SearchSelectOption } from '../../shared/SearchSelect/SearchSelect';
import { usernameFromDentist } from '../../shared/strings';

type Props = {
  dentists: Dentist[];
  onNewDentistSelected: (o: SearchSelectOption) => void;
  selectedLocalDateTime: Date | null;
  onDateChange: (dateValue: Date | null) => void;
  onTimeChange: (dateValue: Date | null) => void;
  defaultSelectedDentist: SearchSelectOption;
};
const DentistAndAppointmentSelectors: React.FC<Props> = ({
  dentists,
  onNewDentistSelected,
  selectedLocalDateTime,
  onDateChange,
  onTimeChange,
  defaultSelectedDentist,
}) => {
  const dentistOptions: SearchSelectOption[] = useMemo(
    () =>
      dentists.map((d) => {
        return {
          id: d.id,
          label: usernameFromDentist(d),
          renderContent: (
            <div className="dentist-search-option text-xs py-2 px-2 text-left">
              {d.firstName} {d.lastName}{' '}
              <span className="dentist-search-option-username font-semibold">({d.username})</span>
            </div>
          ),
        };
      }),
    [dentists]
  );

  const filterDate = (d: Date) => {
    return (
      DateTime.fromJSDate(d) > DateTime.now() ||
      (DateTime.fromJSDate(d).hasSame(DateTime.now(), 'day') &&
        DateTime.fromJSDate(d).hasSame(DateTime.now(), 'month'))
    );
  };

  const filterTime = (d: Date) => {
    return DateTime.fromJSDate(d) > DateTime.now();
  };

  return (
    <div id={'selectors'} className={'flex flex-col gap-3 text-left w-[80%]'}>
      <span className={'font-semibold mb-2'}>Select a new time for this appointment</span>
      <div className={'flex flex-col gap-2 mb-1'}>
        <span className={'font-semibold text-xs'}>Select a dentist for this appointment</span>
        <SearchSelect
          id="dentist-filter"
          options={dentistOptions}
          onChange={onNewDentistSelected}
          defaultValue={defaultSelectedDentist}
          allowClear={false}
          componentContainerCustomClass="w-full"
          includeAngle
        />
      </div>
      <div className={'flex flex-col gap-2'}>
        <span className={'font-semibold text-xs'}>Pick a time for the appointment</span>
        <div className="flex gap-4">
          <div className="flossy-picker">
            <DatePicker
              inline
              id="appointment-date-picker"
              selected={selectedLocalDateTime}
              onChange={onDateChange}
              dateFormat="MMMM d, yyyy"
              filterDate={filterDate}
              minDate={new Date()}
              fixedHeight={true}
            />
          </div>
          <div className="flossy-picker">
            <DatePicker
              inline
              id="appointment-time-picker"
              selected={selectedLocalDateTime}
              onChange={onTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              filterTime={filterTime}
              fixedHeight={true}
              dateFormat="h:mm aa"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DentistAndAppointmentSelectors;
