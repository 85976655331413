import { logEvent } from 'firebase/analytics';

import { firebaseAnalytics, isUsingEmulation } from '../firebase';
import { Invoice, LegacyPayment } from '../ServiceContext/invoices';

export function isAnalyticsActive() {
  return !isUsingEmulation() && Boolean(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
}

export async function recordAvailabilitySpotCreated(dentistName: string) {
  const analytics = await firebaseAnalytics();
  if (isAnalyticsActive() && analytics) {
    logEvent(analytics, 'availability_created', {
      dentist_name: dentistName,
    });
  }
}

export async function recordAvailabilitySpotDeleted(dentistName: string) {
  const analytics = await firebaseAnalytics();
  if (isAnalyticsActive() && analytics) {
    logEvent(analytics, 'availability_deleted', {
      dentist_name: dentistName,
    });
  }
}

export async function recordPageView(path: string) {
  const analytics = await firebaseAnalytics();
  if (isAnalyticsActive() && analytics) {
    logEvent(analytics, 'page_view', { page_path: path });
  }
}

export async function recordPurchase({
  invoice,
  dentistName,
  payment,
  isEZCheckout,
}: {
  invoice: Invoice;
  dentistName: string;
  payment: LegacyPayment;
  isEZCheckout: boolean;
}) {
  const analytics = await firebaseAnalytics();
  if (isAnalyticsActive() && analytics) {
    logEvent(analytics, 'purchase', {
      is_ez_checkout: isEZCheckout,
      user_id: invoice.userId,
      currency: 'USD',
      transaction_id: invoice.id,
      value: invoice.subtotal / 100,
      tax: (invoice.total - invoice.subtotal) / 100,
      affiliation: dentistName,
      items: invoice.invoiceItems
        .filter((i) => i.type === 'procedure')
        .map((i) => {
          return {
            item_id: i.code,
            item_name: i.text,
            currency: 'USD',
            price: i.amount / 100,
            quantity: 1,
          };
        }),
    });

    const extraEvent = payment.isFirst ? 'initial_visit_purchase' : 'treatment_plan_purchase';
    logEvent(analytics, extraEvent, {
      user_id: invoice.userId,
      currency: 'USD',
      transaction_id: invoice.id,
      value: invoice.subtotal / 100,
      tax: (invoice.total - invoice.subtotal) / 100,
      affiliation: dentistName,
    });
  }
}
