import { DateTime } from 'luxon';

import { Appointment } from '../../ServiceContext/appointments';
import { reportErrorToSentry } from '../../ServiceContext/error';
import { Dentist } from '../../ServiceContext/user';
import { SearchSelectOption } from '../SearchSelect/SearchSelect';

export function getTimeZoneFromAppointment(a: Appointment): string | null {
  if (a.timeZone) {
    return a.timeZone;
  }

  if (a.dentist) {
    const timeZoneFromDentist = getTimeZoneOfDentist(a.dentist);
    if (timeZoneFromDentist) {
      return timeZoneFromDentist;
    }
  }

  if (a.location && a.location.timeZone) {
    return a.location.timeZone;
  }

  return null;
}

function findDentistById(dentists: Dentist[], id: string): Dentist | undefined {
  return dentists.find((d) => d.id === id);
}

function determineTimeZone(
  appointment: Appointment | undefined,
  selectedDentist: SearchSelectOption | null,
  selectedDentistId: string | null,
  practiceDentists: Dentist[]
): string | null {
  if (appointment) {
    return getTimeZoneFromAppointment(appointment);
  }

  let dentist: Dentist | undefined;
  if (selectedDentist) {
    dentist = findDentistById(practiceDentists, selectedDentist.id);
  } else if (selectedDentistId) {
    dentist = findDentistById(practiceDentists, selectedDentistId);
  }

  return dentist ? getTimeZoneOfDentist(dentist) : null;
}

function convertToOfficeTimeZone(localDateTime: Date, timeZone: string): DateTime {
  // This option ensures that the "clock" part of the time doesn't change even if the time zone changes. i.e., it will
  // change 12PM PST to 12PM EST, keeping the clock time the same. If you don't set this option, then converting
  // 12PM PST to EST will change it to 3PM EST.
  return DateTime.fromJSDate(localDateTime).setZone(timeZone, { keepLocalTime: true });
}

export const getTimeZoneOfDentist = (d: Dentist): string => {
  const getTimeZone = (): string | undefined => {
    if (d.locations && d.locations.length > 0) {
      return d.locations[0].timeZone;
    }
    if (d.practice && d.practice.timeZone) {
      return d.practice.timeZone;
    }
    return undefined;
  };

  const timeZone = getTimeZone();

  if (timeZone) {
    return timeZone;
  }

  reportErrorToSentry({
    summary: 'Error finding dentist timezone',
    extra: {
      dentistId: d.id,
    },
  });

  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export function getOfficeTimeZoneForAppointmentBooking({
  selectedLocalDateTime,
  selectedDentist,
  practiceDentists,
  appointment,
  selectedDentistId,
}: {
  selectedLocalDateTime: Date | null;
  selectedDentist: SearchSelectOption | null;
  selectedDentistId: string | null;
  practiceDentists: Dentist[];
  appointment?: Appointment;
}): DateTime {
  if (!selectedLocalDateTime) {
    return DateTime.local();
  }

  const timeZone = determineTimeZone(
    appointment,
    selectedDentist,
    selectedDentistId,
    practiceDentists
  );
  if (timeZone) {
    return convertToOfficeTimeZone(selectedLocalDateTime, timeZone);
  }

  return DateTime.fromJSDate(selectedLocalDateTime);
}
