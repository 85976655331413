import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useMemo, useState } from 'react';

import { InvoicingWorkflow } from '../../../amplitude';
import amex from '../../../shared/assets/amex.png';
import discover from '../../../shared/assets/discover.png';
import mastercard from '../../../shared/assets/mastercard.png';
import visa from '../../../shared/assets/visa.png';
import Button from '../../../shared/Button/Button';
import LoadingSpinner from '../../../shared/LoadingSpinner';

const buttonStyling = 'bg-primary text-white hover:bg-opacity-[75%]';

type Props = {
  loading: boolean;
  setCardError: React.Dispatch<React.SetStateAction<string | null>>;
  cardError: string | null;
  handleSubmit: (event: React.FormEvent) => {};
  closeModal: () => void;
};

const AddCreditCardModalContent: React.FC<Props> = ({
  loading,
  setCardError,
  cardError,
  handleSubmit,
  closeModal,
}) => {
  const [expirationError] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const stripeCardElementStyle = useMemo(
    () => ({
      base: {
        fontSize: '16px',
        color: '#495057',
        fontSmoothing: 'antialiased',
        fontFamily:
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        '::placeholder': {
          color: '#868e96',
        },
        backgroundColor: '#ffffff',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    }),
    []
  );

  return (
    <div className="bg-white w-96 p-4 rounded-lg">
      <div className="text-base-content text-2xl font-bold mb-3">Add new credit card</div>
      <div className="flex flex-row gap-2 mb-3">
        <img src={visa} alt="Visa" className="h-8 w-12" />
        <img src={mastercard} alt="Mastercard" className="h-8 w-12" />
        <img src={amex} alt="Amex" className="h-8 w-12" />
        <img src={discover} alt="Discover" className="h-8 w-12" />
      </div>
      <div className="text-base-content text-sm font-bold">Card Number</div>
      <CardNumberElement
        options={{
          placeholder: 'Card',
          style: stripeCardElementStyle,
          disabled: loading,
        }}
        className={`w-80 h-14 p-3 bg-white rounded-lg border-2 mt-1 ${'border-rule'}`}
        onChange={(event) => {
          setCardError(event.error ? event.error.message : '');
        }}
      />
      <div className="flex flex-row">
        <div className="w-1/2">
          <div className="text-base-content text-sm font-bold mt-3">Expiration</div>
          <CardExpiryElement
            options={{
              placeholder: 'MM/YY',
              style: stripeCardElementStyle,
              disabled: loading,
            }}
            className={`w-36 h-14 p-3 bg-white rounded-lg border-2 mt-1 ${
              expirationError ? 'border-red-500' : 'border-rule'
            }`}
            onChange={(event) => {
              setCardError(event.error ? event.error.message : '');
            }}
          />
        </div>
        <div className="w-1/2">
          <div className="text-base-content text-sm font-bold mt-3">CVC</div>
          <CardCvcElement
            options={{
              placeholder: 'CVC',
              style: stripeCardElementStyle,
              disabled: loading,
            }}
            className={`w-36 h-14 p-3 bg-white rounded-lg border-2 mt-1 ${'border-rule'}`}
            onChange={(event) => {
              setCardError(event.error ? event.error.message : '');
            }}
          />
        </div>
      </div>
      {cardError && (
        <div className="flex flex-row gap-3 items-center text-error-content bg-error p-2 rounded-md text-sm font-semibold mt-4 mr-3">
          <FontAwesomeIcon
            icon={faCircleExclamation}
            style={{ color: 'lightcoral', scale: '140%' }}
          />
          {cardError}
        </div>
      )}
      <div className="w-80 mt-3">
        <span className="text-base-content text-xs font-bold leading-none">Note: </span>
        <span className="text-base-content text-xs font-normal leading-none">
          Flossy processes cards with Stripe. We cannot accept debit cards, international credit
          cards, or cards with pin codes.
        </span>
      </div>
      <div className="flex flex-row mt-4">
        <Button
          id="add-new-card-submit-button"
          onClick={async (event) => {
            if (loading) {
              return;
            }
            if (!stripe || !elements) {
              // Stripe.js hasn't yet loaded.
              // Make sure to disable form submission until Stripe.js has loaded.
              return;
            }

            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            if (cardNumberElement && cardExpiryElement && cardCvcElement) {
              setCardError('');
              await handleSubmit(event);
            }
          }}
          trackingLabel="Sumbit New Card Button"
          workflow={InvoicingWorkflow}
          className={`${buttonStyling} mr-4`}
        >
          {loading && (
            <div className="pr-2">
              <LoadingSpinner size="sm" />
            </div>
          )}
          Add New Card
        </Button>
        <button
          id={'cancel-new-card-button'}
          className="rounded-full border text-sm border-rule text-primary font-medium px-3 py-2 hover:opacity-75"
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddCreditCardModalContent;
