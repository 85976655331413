import React from 'react';

import logo from './app-logo-lg.svg';
import styledFlossy from './app-styled-name-orange-lg.svg';

const BrandedLoadingPage: React.FC = () => {
  return (
    <div className="branded-loading flex justify-center items-center min-h-screen bg-white">
      <div className="flossy-branding flex flex-row gap-4 animate-pulse">
        <img src={logo} className="app-logo w-[200px] h-auto" alt="flossy-logo" />
        <img src={styledFlossy} className="styled-name w-[400px] h-auto" alt="flossy-name" />
      </div>
    </div>
  );
};

export default BrandedLoadingPage;
