import { AuthProvider } from '../Authentication/Authentication';
import { PricingQuote } from '../ServiceContext/invoices';
import { authenticatedPost } from '../ServiceContext/service';
import { apiUrl } from '../ServiceContext/shared';
import { handleAPIResponse } from './response';

export async function createPricingQuote({
  authProvider,
  dentistId,
  patientId,
  billingCodes,
  pricingSystemIds,
  promoCode,
}: {
  authProvider: AuthProvider;
  dentistId: string;
  patientId: string;
  billingCodes: string[];
  pricingSystemIds: string[];
  promoCode: string | null;
}): Promise<PricingQuote> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedPost<PricingQuote>(authProvider, apiUrl(`/practices/pricing-quotes`), {
      dentistId,
      patientId,
      billingCodes,
      pricingSystemIds,
      promoCode: promoCode || undefined,
    })
  );
}

export async function adjustPricingQuote({
  authProvider,
  pricingQuoteId,
  adjustments,
  promoCode,
}: {
  authProvider: AuthProvider;
  pricingQuoteId: string;
  adjustments: { pricingSystemId: string; price: number }[];
  promoCode: string | null;
}): Promise<PricingQuote> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedPost<PricingQuote>(
      authProvider,
      apiUrl(`/practices/pricing-quotes/${pricingQuoteId}/adjustments`),
      {
        adjustments: adjustments,
        promoCode: promoCode || undefined,
      }
    )
  );
}
