import { FieldArray, Form, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';

import { TreatmentPlanWorkflow } from '../../amplitude';
import { createTreatmentPlan, DetailedTreatmentPlan } from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import Alert, { AlertData, errorAlert, successAlert, warningAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import FieldWithError from '../../shared/FieldWithError';
import FileUploadButton from '../../shared/FileUploadButton';
import SearchSelect, { SearchSelectOption } from '../../shared/SearchSelect/SearchSelect';
import { toTitleCase } from '../../shared/strings';

interface Props {
  authProvider: AuthProvider;
  patientId: string;
  dentistId?: string;
  appointmentId?: string;
  practiceId?: string;
  onTreatmentPlanUploaded: (treatmentPlan: DetailedTreatmentPlan) => void;
  onSecondaryClick: () => void;
  secondaryActionText?: string;
  doAnalysis: boolean;
  notifyFlossyTeam: boolean;
}

export type TreatmentPlanFileRequest = {
  name: string;
  file: File;
};

type FormData = {
  patientRequiresFinancing: 'yes' | 'no' | null;
  patientRequiresReferral: 'yes' | 'no' | null;
  referredTo: string | null;
  treatmentPlanFiles: TreatmentPlanFileRequest[];
};

const formSchema = Yup.object().shape({
  patientRequiresFinancing: Yup.mixed().oneOf(['yes', 'no']).required(),
  patientRequiresReferral: Yup.mixed().oneOf(['yes', 'no']).required(),
  referredTo: Yup.string().max(200, 'Must not exceed 200 characters').nullable(true),
  treatmentPlans: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
    })
  ),
});

function UploadTreatmentPlanForm({
  authProvider,
  patientId,
  dentistId,
  appointmentId,
  practiceId,
  onTreatmentPlanUploaded,
  onSecondaryClick,
  secondaryActionText = 'Cancel',
  doAnalysis,
  notifyFlossyTeam,
}: Props) {
  const [selectedDentistId, setSelectedDentistId] = useState<string | null>(null);
  const [alert, setAlert] = useState<AlertData | null>(null);

  const showDentistSelection = !dentistId && !appointmentId;

  const dentists = useMemo(
    () => authProvider.managedDentists || [],
    [authProvider.managedDentists]
  );

  const dentistOptions: SearchSelectOption[] = useMemo(
    () =>
      dentists.map((p) => {
        const dentistName = `${p.firstName} ${p.lastName} (${p.username})`;
        return {
          id: p.id,
          label: dentistName,
          renderContent: (
            <div className="practice-search-option py-2 px-2 text-left">
              <span className="practice-search-option-username font-semibold">{dentistName}</span>
            </div>
          ),
        };
      }),
    [dentists]
  );

  const { mutate, isLoading: isCreatingTreatmentPlan } = useMutation(createTreatmentPlan, {
    onSuccess: (data) => {
      setAlert(
        successAlert(
          `You have successfully created a treatment plan for ${data.patient?.firstName} ${data.patient?.lastName}`
        )
      );
      onTreatmentPlanUploaded(data);
    },
    onError: (error) => {
      setAlert(errorAlert(error as any));
    },
  });

  const onSubmitTreatmentPlan = async ({
    patientRequiresFinancing,
    patientRequiresReferral,
    referredTo,
    treatmentPlanFiles,
  }: FormData) => {
    if (!patientRequiresFinancing || !patientRequiresReferral) {
      return;
    }

    setAlert(
      warningAlert(
        'Please wait for the treatment plan to be successfully uploaded before leaving the page.'
      )
    );
    mutate({
      authProvider,
      patientId,
      dentistId: dentistId || selectedDentistId || null,
      practiceId: practiceId || null,
      file: treatmentPlanFiles[0].file,
      fileName: treatmentPlanFiles[0].name || treatmentPlanFiles[0].file.name,
      requiresFinancing: patientRequiresFinancing === 'yes',
      wasReferredOut: patientRequiresReferral === 'yes',
      referredTo,
      appointmentId: appointmentId || null,
      sendInternalEmail: notifyFlossyTeam,
      analyzeTreatmentPlan: doAnalysis,
    });
  };

  const initialFormValues: FormData = {
    patientRequiresFinancing: null,
    patientRequiresReferral: null,
    referredTo: null,
    treatmentPlanFiles: [],
  };

  const formIsNotFilledOut = (values: FormData) => {
    const referralNotReady =
      values.patientRequiresReferral === null ||
      (values.patientRequiresReferral === 'yes' && !values.referredTo);
    const missingRequiredDentist = showDentistSelection && !dentistId && !selectedDentistId;
    return (
      missingRequiredDentist ||
      values.patientRequiresFinancing === null ||
      referralNotReady ||
      values.treatmentPlanFiles.length === 0
    );
  };

  const onDentistSelect = useCallback((d: SearchSelectOption | null) => {
    if (d) {
      setSelectedDentistId(d.id);
    } else {
      setSelectedDentistId(null);
    }
  }, []);

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={formSchema}
      onSubmit={(values) => {
        onSubmitTreatmentPlan(values).then();
      }}
    >
      {({ values }) => (
        <Form id="upload-treatment-plan-form" className="h-full flex flex-col items-start gap-3">
          <FieldArray
            name="treatmentPlanFiles"
            render={(arrayHelpers) => (
              <>
                <div className="page-header">
                  <span className="page-title text-[26px] font-medium text-secondary">
                    Upload Treatment Plan
                  </span>
                </div>
                <div className="separator w-full my-4 border-b" />
                {alert && <Alert {...alert} />}
                {showDentistSelection && (
                  <>
                    <label htmlFor="financing-question">
                      Select a dentist to associate with this treatment plan
                    </label>
                    <SearchSelect
                      id="dentist-select"
                      options={dentistOptions}
                      onChange={onDentistSelect}
                      placeholder=""
                      wrapperCustomClass="w-1/4"
                      overlayPlatformCustomClass="w-full border rounded"
                      componentContainerCustomClass="w-full"
                      allowClear={false}
                      includeAngle
                    />
                  </>
                )}
                <div className="financing-question-group flex flex-col items-start gap-3">
                  <label htmlFor="financing-question">
                    Is this patient interested in applying for financing?
                  </label>
                  <FieldWithError
                    name="patientRequiresFinancing"
                    type="yesNoGroup"
                    className="treatment-plan-file-name"
                  />
                </div>
                <div className="referrals-questions-group flex flex-col items-start gap-3">
                  <label htmlFor="referral-question">Does patient need to be referred out?</label>
                  <FieldWithError name="patientRequiresReferral" type="yesNoGroup" />
                  {values.patientRequiresReferral === 'yes' && (
                    <>
                      <label htmlFor="where-referred">Where is patient being referred?</label>
                      <FieldWithError
                        name="referredTo"
                        type="textarea"
                        className="border rounded-lg p-2 focus:outline-blue-200 referred-to-input !w-[480px]"
                      />
                    </>
                  )}
                </div>
                <div className="treatment-plan-files-list bg-[#f9f9f9] rounded-lg p-8 mt-2">
                  {values.treatmentPlanFiles.length > 0 ? (
                    values.treatmentPlanFiles.map((treatmentPlanFile, index) => (
                      <div
                        className="treatment-plan-file-row flex flex-row gap-6 justify-start items-center mb-3"
                        key={index}
                      >
                        <FieldWithError
                          id={`treatment-plan-file-name-custom-${index}`}
                          name={`treatmentPlanFiles[${index}].name`}
                          type="text"
                          placeholder="Customize file name (optional)"
                          className="treatment-plan-file-text-input border rounded-lg p-1.5 pl-2 focus:outline-blue-200"
                        />
                        <span className="treatment-plan-file-name underline underline-offset-2">
                          {treatmentPlanFile.file.name}
                        </span>
                      </div>
                    ))
                  ) : (
                    <span className="no-files-message text-left italic">No files uploaded yet</span>
                  )}
                </div>
                <div className="control-panel flex flex-row gap-3 mt-4">
                  <FileUploadButton
                    id="add-more-treatment-plan-files-button"
                    onFilesUploaded={async (files: File[]) => {
                      files.forEach((f) =>
                        arrayHelpers.push({
                          file: f,
                          name: '',
                        })
                      );
                    }}
                    disabled={isCreatingTreatmentPlan}
                    omitBorder
                    multiple
                    workflow={TreatmentPlanWorkflow}
                    context="uploadTreatmentPlanForm"
                    trackingLabel="Upload Treatment Plan File(s) Button"
                  >
                    Upload Treatment Plan File(s)
                  </FileUploadButton>
                  <Button
                    id="submit-treatment-plan-button"
                    omitBorder
                    htmlType="submit"
                    className="submit-button"
                    disabled={formIsNotFilledOut(values) || values.treatmentPlanFiles.length === 0}
                    loading={isCreatingTreatmentPlan}
                    workflow={TreatmentPlanWorkflow}
                    trackingLabel="Submit Treatment Plan Button"
                    context="uploadTreatmentPlanForm"
                  >
                    {values.treatmentPlanFiles.length > 0
                      ? `Submit Treatment Plan with ${values.treatmentPlanFiles.length} file(s)`
                      : 'Submit Treatment Plan'}
                  </Button>
                  <Button
                    id="secondary-uploading-treatment-plan-button"
                    onClick={onSecondaryClick}
                    noFill
                    disabled={isCreatingTreatmentPlan}
                    workflow={TreatmentPlanWorkflow}
                    trackingLabel={toTitleCase(secondaryActionText) + ' Button'}
                    context="uploadTreatmentPlanForm"
                  >
                    {secondaryActionText}
                  </Button>
                </div>
              </>
            )}
          />
        </Form>
      )}
    </Formik>
  );
}

export default UploadTreatmentPlanForm;
