import { useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { TreatmentPlanWorkflow } from '../../amplitude';
import {
  acceptTreatmentPlan,
  DetailedTreatmentPlan,
  rejectTreatmentPlan,
  TreatmentPlanState,
} from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import Button from '../../shared/Button/Button';
import Modal from '../../shared/Modal/Modal';
import BookAppointmentForm from '../BookAppointmentForm';
import { usePatient } from '../PatientDetails/hooks/usePatient';
import TreatmentPlanPrintout from './PrintTreatmentPlan/TreatmentPlanPrintout';

export default function TreatmentPlanButtonSection({
  treatmentPlan,
  authProvider,
  patientId,
}: {
  treatmentPlan: DetailedTreatmentPlan;
  authProvider: AuthProvider;
  patientId: string;
}) {
  const [isAcceptModalopen, setIsAcceptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isBookAppointmentModalOpen, setIsBookAppointmentModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const treatmentPlanPrintoutRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleTreatmentPlanDecision = (updatedTreatmentPlan: DetailedTreatmentPlan) => {
    queryClient.setQueryData<DetailedTreatmentPlan>(
      'getDetailedTreatmentPlan',
      updatedTreatmentPlan
    );
  };

  const { mutate: acceptTreatmentPlanMutation, isLoading: isAcceptingTreatmentPlan } = useMutation(
    acceptTreatmentPlan,
    {
      onSuccess: (tp) => {
        handleTreatmentPlanDecision(tp);
        setIsAcceptModalOpen(false);
        queryClient.setQueryData(['getDetailedTreatmentPlan', treatmentPlan.id], tp);
      },
      // onError: (error) => {
      //   setAlert(errorAlert(error as any));
      // },
    }
  );

  const { mutate: rejectTreatmentPlanMutation, isLoading: isRejectingTreatmentPlan } = useMutation(
    rejectTreatmentPlan,
    {
      onSuccess: (tp) => {
        handleTreatmentPlanDecision(tp);
        setIsRejectModalOpen(false);
      },
      // onError: (error) => {
      //   setAlert(errorAlert(error as any));
      // },
    }
  );

  const { data: patient } = usePatient(patientId);

  const upcomingAppointment =
    patient?.appointments &&
    patient.appointments.filter((appt) => new Date(appt.start) > new Date()).length > 0
      ? patient.appointments[0]
      : null;

  const renderButtons = () => {
    return [
      treatmentPlan.state === TreatmentPlanState.ACCEPTED ? (
        <Button
          id="book-appointment-treatment-plan-button"
          key="book-appointment-treatment-plan-button"
          onClick={() => {
            setIsBookAppointmentModalOpen(true);
          }}
          workflow={TreatmentPlanWorkflow}
          context="treatmentPlanButtonSection"
          trackingLabel="Book Appointment Button"
        >
          Book Appointment
        </Button>
      ) : null,
      treatmentPlan.state === TreatmentPlanState.ACCEPTED ? (
        <Button
          id="add-to-invoice-treatment-plan-button"
          key="add-to-invoice-treatment-plan-button"
          disabled={
            upcomingAppointment
              ? false
              : 'Book an appointment with this patient in order to associate a treatment plan'
          }
          onClick={() => {
            navigate(`/appointments/${upcomingAppointment!.id}/invoicing`, {
              state: { treatmentPlan },
            });
          }}
          workflow={TreatmentPlanWorkflow}
          context="treatmentPlanButtonSection"
          trackingLabel="Add To Invoice Button"
          className={'border-0'}
        >
          Add to Invoice
        </Button>
      ) : null,

      treatmentPlan.state !== TreatmentPlanState.CANCELLED ? (
        <ReactToPrint
          trigger={() => {
            return (
              <Button
                id="print-treatment-plan-button"
                key="print-treatment-plan-button"
                workflow={TreatmentPlanWorkflow}
                context="treatmentPlanButtonSection"
                trackingLabel="Print Treatment PlanButton"
              >
                Print
              </Button>
            );
          }}
          content={() => treatmentPlanPrintoutRef.current}
        ></ReactToPrint>
      ) : null,
      treatmentPlan.state === TreatmentPlanState.PROPOSED ? (
        <Button
          id="accept-treatment-plan"
          key="accept-treatment-plan"
          onClick={() => {
            setIsAcceptModalOpen(true);
          }}
          loading={isAcceptingTreatmentPlan}
          disabled={isRejectingTreatmentPlan}
          workflow={TreatmentPlanWorkflow}
          context="treatmentPlanButtonSection"
          trackingLabel="Accept Treatment Plan Button"
        >
          Accept
        </Button>
      ) : null,
      treatmentPlan.state === TreatmentPlanState.PROPOSED ? (
        <Button
          id="reject-treatment-plan"
          key="reject-treatment-plan"
          noFill
          onClick={() => {
            setIsRejectModalOpen(true);
          }}
          loading={isRejectingTreatmentPlan}
          disabled={isAcceptingTreatmentPlan}
          workflow={TreatmentPlanWorkflow}
          context="treatmentPlanButtonSection"
          trackingLabel="Reject Treatment Plan Button"
        >
          Reject
        </Button>
      ) : null,
    ];
  };
  return (
    <div className="w-1/3">
      <div className="flex flex-row justify-end gap-2 h-10">{renderButtons()}</div>
      <Modal
        isOpen={isAcceptModalopen}
        onRequestClose={() => setIsAcceptModalOpen(false)}
        shape={'big-square'}
      >
        <div className="flex flex-col items-center gap-4">
          <div className="text-base-content text-2xl font-bold ">
            Accept Treatment Plan Proposal
          </div>
          <div className="text-base-content text-sm font-normal leading-normal">
            By accepting this proposal, you agree that the patient has reviewed and accepted the
            proposed Treatment Plan. Once the proposal has been accepted, procedures can be
            scheduled and invoiced.
          </div>
          <div className="flex gap-3">
            <Button
              id={'accept-treatment-plan-proposal'}
              onClick={() => {
                acceptTreatmentPlanMutation({
                  authProvider,
                  treatmentPlanId: treatmentPlan.id,
                });
              }}
              workflow={TreatmentPlanWorkflow}
              context="treatmentPlanButtonSection"
              trackingLabel="Accept Treatment Plan Proposal Button"
            >
              Accept Proposal
            </Button>
            <Button
              id={'accept-treatment-plan-proposal-cancel'}
              noFill
              onClick={() => {
                setIsAcceptModalOpen(false);
              }}
              workflow={TreatmentPlanWorkflow}
              context="treatmentPlanButtonSection"
              trackingLabel="Cancel Treatment Plan Button"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isRejectModalOpen}
        onRequestClose={() => setIsRejectModalOpen(false)}
        shape={'big-square'}
      >
        <div className="flex flex-col items-center gap-4">
          <div className="text-base-content text-2xl font-bold ">
            Reject Treatment Plan Proposal
          </div>
          <div className="text-base-content text-sm font-normal leading-normal">
            The patient or dentist has rejected the proposed Treatment Plan. Once the proposal has
            been rejected, creating a new Treatment Plan with these procedures may produce a
            different discount for patient. Are you sure you want to reject the current proposal?
          </div>
          <div className="flex gap-3">
            <Button
              id={'reject-treatment-plan-proposal'}
              onClick={() => {
                rejectTreatmentPlanMutation({
                  authProvider,
                  treatmentPlanId: treatmentPlan.id,
                });
              }}
              workflow={TreatmentPlanWorkflow}
              context="treatmentPlanButtonSection"
              trackingLabel="Reject Treatment Plan Proposal Button"
            >
              Reject Proposal
            </Button>
            <Button
              id={'reject-treatment-plan-proposal-cancel'}
              noFill
              onClick={() => {
                setIsRejectModalOpen(false);
              }}
              workflow={TreatmentPlanWorkflow}
              context="treatmentPlanButtonSection"
              trackingLabel="Cancel Treatment Plan Button"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isBookAppointmentModalOpen}
        onRequestClose={() => setIsBookAppointmentModalOpen(false)}
        shape="big-square"
      >
        <BookAppointmentForm
          authProvider={authProvider}
          onCloseClick={() => setIsBookAppointmentModalOpen(false)}
          patientId={treatmentPlan.patient.id}
          includeActionButtons={true}
        />
      </Modal>
      <div className="hidden">
        <TreatmentPlanPrintout treatmentPlan={treatmentPlan} ref={treatmentPlanPrintoutRef} />
      </div>
    </div>
  );
}
