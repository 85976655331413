import React from 'react';

import { AuthProvider } from '../../Authentication/Authentication';
import ProvideConsolidatedDentistPricingTable from '../../DataProviders/ProvideConsolidatedDentistPricingTable';
import { Appointment } from '../../ServiceContext/appointments';
import { reportErrorToSentry } from '../../ServiceContext/error';
import Alert, { errorAlert } from '../../shared/Alert';
import Wizard from './Wizard';

type Props = {
  authProvider: AuthProvider;
  appointment: Appointment;
  refetchAppointment: () => void;
};

const Invoicing: React.FC<Props> = ({ authProvider, appointment, refetchAppointment }) => {
  if (!appointment.user) {
    reportErrorToSentry(
      `In new invoicing: No patient found on appointment with id: ${appointment.id}`
    );
    return <Alert {...errorAlert('No patient found on this appointment')} />;
  }
  if (!appointment.dentist) {
    reportErrorToSentry(
      `In new invoicing: No dentist found on appointment with id: ${appointment.id}`
    );
    return <Alert {...errorAlert('No dentist found on this appointment')} />;
  }

  return (
    <ProvideConsolidatedDentistPricingTable
      authProvider={authProvider}
      dentistId={appointment.dentist.id}
    >
      {({ consolidatedPricingTable }) => (
        <Wizard
          authProvider={authProvider}
          dentist={appointment.dentist}
          patient={appointment.user}
          consolidatedPricingTable={consolidatedPricingTable}
          appointment={appointment}
          refetchAppointment={refetchAppointment}
        />
      )}
    </ProvideConsolidatedDentistPricingTable>
  );
};

export default Invoicing;
