import currency from 'currency.js';

export type StringifyMoneyFlags = {
  omitDollarSign?: boolean;
  includeCommas?: boolean;
  roundCents?: boolean;
  ignoreZeroValue?: boolean;
};

export function stringifyMoney(pennies: number, flags?: StringifyMoneyFlags): string {
  let currencyValue;
  let symbol = '$';
  let separator = '';

  if (flags && flags.roundCents) {
    // If pennies is negative, round towards negative infinity, otherwise round towards positive infinity.
    const dollars = pennies < 0 ? Math.floor(pennies / 100) : Math.ceil(pennies / 100);
    currencyValue = currency(dollars);
  } else {
    currencyValue = currency(pennies, { fromCents: true });
  }

  if (flags && flags.omitDollarSign) {
    symbol = '';
  }
  if (flags && flags.includeCommas) {
    separator = ',';
  }

  let formatted = currencyValue.format({
    symbol,
    separator,
  });

  // When roundCents is true, remove the .00 decimal part
  if (flags && flags.roundCents) {
    formatted = formatted.replace(/\.00$/, '');
  }

  if (flags && flags.ignoreZeroValue && pennies === 0) {
    return '--';
  }

  return formatted;
}
