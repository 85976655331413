import { useContext } from 'react';

import { BirthdayIcon, EmailIcon, PhoneIcon } from '../../../HomePage/components/Icons';
import { PatientDetail } from '../../../ServiceContext/patients';
import PortalContainer from '../../../shared/Container/PortalContainer';
import { toShortDateString } from '../../../shared/dates/dates';
import { formatPhoneNumber } from '../../../shared/strings';
import { PortalSubHeader } from '../../../shared/Text/PortalHeaders';
import { PatientContext } from '../PatientDetailPage';

export default function PatientInformation() {
  const patient = useContext(PatientContext) as PatientDetail;

  return (
    <PortalContainer className="details-wrapper">
      <div className="patient-details flex flex-column gap-2">
        <PortalSubHeader>Patient Information</PortalSubHeader>
        <div className="space-y-2 flex flex-col items-start gap-1 pt-3">
          <div className="flex gap-2 items-center">
            <BirthdayIcon />
            <span className="text-base-content text-sm font-bold font-['Helvetica Neue']">
              Birthdate:
            </span>
            <span className="text-base-content text-sm font-normal font-['Helvetica Neue']">
              {toShortDateString(patient.birthDate) || 'N/A'}
            </span>
          </div>
          <div className="flex gap-2 items-center">
            <PhoneIcon />
            <span className="text-base-content text-sm font-bold font-['Helvetica Neue']">
              Phone number:
            </span>
            <span className="text-base-content text-sm font-normal font-['Helvetica Neue']">
              {' '}
              {formatPhoneNumber(patient.phone)}
            </span>
          </div>
          <div className="flex gap-2 items-center">
            <EmailIcon />
            <span className="text-base-content text-sm font-bold font-['Helvetica Neue']">
              Email address:
            </span>
            <span className="text-base-content text-sm font-normal font-['Helvetica Neue']">
              {patient.email || 'N/A'}
            </span>
          </div>
        </div>
      </div>
    </PortalContainer>
  );
}
