import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

import Cookies from './cookies';

const checkReminderTime = () => {
  const reminderTime = Cookies.newAppVersionReminderSleep.get();
  if (!reminderTime || !reminderTime.isValid) {
    return true;
  }
  const now = DateTime.now();
  return now >= reminderTime;
};

export const useReminderCookie = () => {
  const [cookieValue, setCookieValue] = useState(Cookies.newAppVersionReminderSleep.get());
  const [isTimeToRemind, setIsTimeToRemind] = useState(checkReminderTime());

  const setReminderCookie = useCallback((value: DateTime) => {
    Cookies.newAppVersionReminderSleep.set(value);
    setCookieValue(value);
    setIsTimeToRemind(checkReminderTime());
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsTimeToRemind(checkReminderTime());
    }, 1000 * 60); // Check every minute

    return () => clearInterval(intervalId);
  }, []);

  return {
    cookieValue,
    setReminderCookie,
    isTimeToRemind,
  };
};
