import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { getPractices } from '../../API/practice';
import { ErrorResponse } from '../../API/response';
import { AuthProvider } from '../../Authentication/Authentication';
import { Practice } from '../../ServiceContext/user';
import Alert, { errorAlert } from '../../shared/Alert';
import { useURLQuery } from '../../shared/routing/routing';
import SearchSelect, { SearchSelectOption } from '../../shared/SearchSelect/SearchSelect';

type PracticeSelectionWrapperChildProps = {
  selectedPractice: Practice;
  onClearPractice: (() => void) | null;
};

type PracticeSelectionWrapperProps = {
  authProvider: AuthProvider;
  flavorText: string;
  children: React.FC<PracticeSelectionWrapperChildProps>;
};

const PracticeSelectionWrapper: React.FC<PracticeSelectionWrapperProps> = ({
  authProvider,
  flavorText,
  children,
}) => {
  const { query, updateQuery, setQuery } = useURLQuery();
  const practiceId = query.get('practiceId');

  const getPracticesKey = ['getPractices', practiceId];
  const {
    isLoading: isLoadingPractices,
    error: fetchPracticesError,
    data: resPractices,
  } = useQuery<Practice[], ErrorResponse>(
    getPracticesKey,
    () => {
      return getPractices({ authProvider });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const practices = useMemo(() => resPractices || [], [resPractices]);

  const practiceOptions: SearchSelectOption[] = useMemo(
    () =>
      practices.map((p) => {
        return {
          id: p.id,
          label: p.name,
          renderContent: (
            <div className="practice-search-option py-2 px-2 text-left">
              <span className="practice-search-option-username font-semibold">{p.name}</span>
            </div>
          ),
        };
      }),
    [practices]
  );

  const onPracticeSelect = useCallback(
    (d: SearchSelectOption | null) => {
      if (d) {
        updateQuery({ practiceId: d.id });
      } else {
        setQuery({});
      }
    },
    [updateQuery, setQuery]
  );

  const onClearPractice = useCallback(() => {
    onPracticeSelect(null);
  }, [onPracticeSelect]);

  let practice: Practice | null;
  if (practices.length === 1) {
    practice = practices[0];
  } else {
    practice = practices.find((d) => d.id === practiceId) || null;
  }
  if (practice) {
    return children({
      selectedPractice: practice,
      onClearPractice: practices.length === 1 ? null : onClearPractice,
    });
  }

  return (
    <div className="practice-selection-wrapper bg-[#f9f9f9] rounded-lg mb-2.5">
      <div className="practice-selection w-full flex flex-column h-32 items-start gap-[16px] text-left text-base">
        {fetchPracticesError && <Alert {...errorAlert(fetchPracticesError)} />}
        <span className="pt-4 text-[#5a5a5a] font-semibold pl-8">{flavorText}</span>
        <SearchSelect
          id="practice-select"
          options={practiceOptions}
          onChange={onPracticeSelect}
          placeholder="Choose a Practice"
          wrapperCustomClass="w-1/4"
          overlayPlatformCustomClass="w-full translate-x-6"
          componentContainerCustomClass="w-full"
          allowClear={false}
          loading={isLoadingPractices}
          includeAngle
        />
      </div>
    </div>
  );
};

export default PracticeSelectionWrapper;
