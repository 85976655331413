import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { isErrorResponse } from '../../../API/response';
import { Appointment } from '../../../ServiceContext/appointments';
import { reportErrorToSentry } from '../../../ServiceContext/error';
import { isValidPaymentMethod, PaymentMethod } from '../../../ServiceContext/invoices';
import { useServices } from '../../../ServiceContext/ServiceContext';

/**
 * This hook changes the payment method associated with an appointment.
 */
export function useChangePaymentMethod({
  appointment,
  onSuccess,
}: {
  appointment?: Appointment;
  onSuccess: (createdPaymentMethod: PaymentMethod) => void;
}) {
  const services = useServices();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const changePaymentMethod = useMutation({
    mutationFn: ({ method }: { method: PaymentMethod }) => {
      return services.appointmentsService.associatePaymentMethod(method, appointment!.id!);
    },
    onSuccess: (data) => {
      if (isErrorResponse(data)) {
        setLoading(false);
        reportErrorToSentry(data);
        return;
      }

      const updatedAppointment = data;
      appointment!.paymentMethod = updatedAppointment.paymentMethod;
      queryClient.setQueryData('appointment_' + appointment!.id, appointment);

      if (isValidPaymentMethod(updatedAppointment.paymentMethod)) {
        onSuccess(updatedAppointment.paymentMethod);
      }
      setLoading(false);
    },
    onError: (error) => {
      reportErrorToSentry(error as any);
      setLoading(false);
    },
  });

  return {
    changePaymentMethod,
    loading,
  };
}
