import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useContext, useMemo, useState } from 'react';
import Modal from 'react-modal';

import { AppointmentWorkflow } from '../../../amplitude';
import {
  cardLastFourFromPaymentMethod,
  isPaymentMethod,
  PaymentMethod,
} from '../../../ServiceContext/invoices';
import amex from '../../../shared/assets/amex.png';
import discover from '../../../shared/assets/discover.png';
import mastercard from '../../../shared/assets/mastercard.png';
import visa from '../../../shared/assets/visa.png';
import Button from '../../../shared/Button/Button';
import LoadingSpinner from '../../../shared/LoadingSpinner';
import ChangeCreditCardModalContent from '../../Invoicing/PaymentEntry/ChangeCreditCardModalContent';
import { AppointmentContext } from '../contexts/appointmentContext';
import { useAddPaymentMethod } from '../hooks/useAddPaymentMethod';
import { useChangePaymentMethod } from '../hooks/useChangePaymentMethod';

const CreditCardSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_2142_7212)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.57143 3C1.88944 3 1.23539 3.27092 0.753154 3.75315C0.270918 4.23539 0 4.88944 0 5.57143L0 7.18286H24V5.57143C24 4.88944 23.7291 4.23539 23.2468 3.75315C22.7646 3.27092 22.1106 3 21.4286 3H2.57143ZM0 18.4286V9.32571H24V18.4286C24 19.1106 23.7291 19.7646 23.2468 20.2468C22.7646 20.7291 22.1106 21 21.4286 21H2.57143C1.88944 21 1.23539 20.7291 0.753154 20.2468C0.270918 19.7646 0 19.1106 0 18.4286ZM16.2857 14.7857C16.0016 14.7857 15.729 14.8986 15.5281 15.0995C15.3272 15.3005 15.2143 15.573 15.2143 15.8571C15.2143 16.1413 15.3272 16.4138 15.5281 16.6148C15.729 16.8157 16.0016 16.9286 16.2857 16.9286H18.8571C19.1413 16.9286 19.4138 16.8157 19.6148 16.6148C19.8157 16.4138 19.9286 16.1413 19.9286 15.8571C19.9286 15.573 19.8157 15.3005 19.6148 15.0995C19.4138 14.8986 19.1413 14.7857 18.8571 14.7857H16.2857Z"
          fill="#A8B7C8"
        />
      </g>
      <defs>
        <clipPath id="clip0_2142_7212">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const DollarSignSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path
          d="M12 3V21M15.679 6.63439C14.063 4.2691 7.94541 4.02196 7.94541 8.16745C7.94541 12.3129 16.7524 10.33 16.2439 15.2118C15.8199 19.2823 9.19299 19.3384 7.21094 16.0891"
          stroke="#A8B7C8"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  );
};

const PaymentMethodSection: React.FC = () => {
  const appointmentContext = useContext(AppointmentContext);

  const appointment = appointmentContext?.appointment;
  const refetchAppointment = appointmentContext?.refetchAppointment;

  const paymentMethodOnAppointment: {} | PaymentMethod | null = appointment?.paymentMethod || null;

  const paymentMethodsOnUser = appointment?.user?.paymentMethods || [];

  const [addCreditCardModalIsOpen, setAddCreditCardModalIsOpen] = useState(false);
  const [changeCreditCardModalIsOpen, setChangeCreditCardModalIsOpen] = useState(false);
  const [expirationError] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const {
    createPaymentMethod,
    cardError,
    setCardError,
    loading: isAddingPaymentMethod,
  } = useAddPaymentMethod({
    appointment: appointment!,
    firstName: appointment!.user!.firstName,
    lastName: appointment!.user!.lastName,
    patientId: appointment!.user!.id,
    onPatientPaymentMethodsUpdated: () => {},
    onSuccess: () => {
      setAddCreditCardModalIsOpen(false);
      refetchAppointment && refetchAppointment();
    },
  });

  const { changePaymentMethod, loading: isChangingPaymentMethod } = useChangePaymentMethod({
    appointment,
    onSuccess: () => {
      setChangeCreditCardModalIsOpen(false);
      refetchAppointment && refetchAppointment();
    },
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    createPaymentMethod.mutate({
      cardNumberElement: elements!.getElement(CardNumberElement)!,
    });
  };

  const stripeCardElementStyle = useMemo(
    () => ({
      base: {
        fontSize: '16px',
        color: '#495057',
        fontSmoothing: 'antialiased',
        fontFamily:
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        '::placeholder': {
          color: '#868e96',
        },
        backgroundColor: '#ffffff',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    }),
    []
  );

  const availablePaymentMethods = paymentMethodsOnUser.filter((pm) => {
    if (!isPaymentMethod(pm)) {
      return false;
    }

    if (paymentMethodOnAppointment && isPaymentMethod(paymentMethodOnAppointment)) {
      return pm.id !== paymentMethodOnAppointment?.id;
    }

    return true;
  });

  return (
    <div className="items-start flex flex-col text-sm font-light w-1/3 text-secondary">
      <div className="text-lg font-semibold pb-3">Payment Method on File</div>

      <div className="text-start p-4 h-12 bg-highlight rounded-lg flex flex-row items-center w-full">
        <CreditCardSvg />
        {paymentMethodOnAppointment ? (
          <div className="pl-4">
            <span className="text-base-content text-sm font-bold font-['Helvetica Neue'] leading-normal">
              Visa
            </span>
            <span className="text-base-content text-sm font-normal font-['Helvetica Neue'] leading-normal">
              {' '}
              ending in{' '}
            </span>
            <span className="text-base-content text-sm font-bold font-['Helvetica Neue'] leading-normal">
              {cardLastFourFromPaymentMethod(paymentMethodOnAppointment as PaymentMethod)}{' '}
            </span>
            <span className="text-base-content text-sm font-normal font-['Helvetica Neue'] leading-normal">
              is attached to the appointment.
            </span>
          </div>
        ) : (
          <div className="pl-4">
            <span className="text-base-content text-sm font-['Helvetica Neue'] leading-normal">
              Appointment has no payment method attached yet.
            </span>
          </div>
        )}
      </div>
      <div className="text-start p-4 h-12 bg-base-100 rounded-lg flex flex-row items-center w-full">
        <DollarSignSvg />
        <div className="pl-4">
          <span className="text-base-content text-sm font-normal font-['Helvetica Neue'] leading-normal">
            Patient has {appointment?.user.paymentMethods?.length || 0} payment methods on file.
          </span>
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <Button
          id="add-new-card-button"
          onClick={() => setAddCreditCardModalIsOpen(true)}
          trackingLabel="Add New Card Button"
          workflow={AppointmentWorkflow}
          className="mt-4"
        >
          Add New Card
        </Button>
        {availablePaymentMethods.length > 0 && (
          <Button
            id="change-card-button"
            onClick={() => setChangeCreditCardModalIsOpen(true)}
            trackingLabel="Change Card Button"
            workflow={AppointmentWorkflow}
            className="mt-4"
          >
            Change Card
          </Button>
        )}
        <Modal
          isOpen={addCreditCardModalIsOpen}
          onRequestClose={() => setAddCreditCardModalIsOpen(false)}
          className="fixed inset-0 flex items-center justify-center bg-secondary/40"
        >
          <div className="bg-white w-96 p-4 rounded-lg">
            <div className="text-base-content text-2xl font-bold mb-3">Add new credit card</div>
            <div className="flex flex-row gap-2 mb-3">
              <img src={visa} alt="Visa" className="h-8 w-12" />
              <img src={mastercard} alt="Mastercard" className="h-8 w-12" />
              <img src={amex} alt="Amex" className="h-8 w-12" />
              <img src={discover} alt="Discover" className="h-8 w-12" />
            </div>
            <div className="text-base-content text-sm font-bold">Card Number</div>
            <CardNumberElement
              options={{
                placeholder: 'Card',
                style: stripeCardElementStyle,
                disabled: isAddingPaymentMethod || isChangingPaymentMethod,
              }}
              className={`w-80 h-14 p-3 bg-white rounded-lg border-2 mt-1 ${'border-rule'}`}
              onChange={(event) => {
                setCardError(event.error ? event.error.message : '');
              }}
            />
            <div className="flex flex-row">
              <div className="w-1/2">
                <div className="text-base-content text-sm font-bold mt-3">Expiration</div>
                <CardExpiryElement
                  options={{
                    placeholder: 'MM/YY',
                    style: stripeCardElementStyle,
                    disabled: isAddingPaymentMethod || isChangingPaymentMethod,
                  }}
                  className={`w-36 h-14 p-3 bg-white rounded-lg border-2 mt-1 ${
                    expirationError ? 'border-error' : 'border-rule'
                  }`}
                  onChange={(event) => {
                    setCardError(event.error ? event.error.message : '');
                  }}
                />
              </div>
              <div className="w-1/2">
                <div className="text-base-content text-sm font-bold mt-3">CVC</div>
                <CardCvcElement
                  options={{
                    placeholder: 'CVC',
                    style: stripeCardElementStyle,
                    disabled: isAddingPaymentMethod || isChangingPaymentMethod,
                  }}
                  className={`w-36 h-14 p-3 bg-white rounded-lg border-2 mt-1 ${'border-rule'}`}
                  onChange={(event) => {
                    setCardError(event.error ? event.error.message : '');
                  }}
                />
              </div>
            </div>
            {cardError && <div className="text-error text-sm mt-2">{cardError}</div>}
            <div className="w-80 mt-3">
              <span className="text-base-content text-xs font-bold leading-none">Note: </span>
              <span className="text-base-content text-xs font-normal leading-none">
                Flossy processes cards with Stripe. We cannot accept debit cards, international
                credit cards, or cards with pin codes.
              </span>
            </div>
            <div className="flex flex-row mt-4">
              <Button
                id="add-new-card-submit-button"
                onClick={async (event) => {
                  if (isAddingPaymentMethod || isChangingPaymentMethod) {
                    return;
                  }
                  if (!stripe || !elements) {
                    // Stripe.js hasn't yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return;
                  }

                  const cardNumberElement = elements.getElement(CardNumberElement);
                  const cardExpiryElement = elements.getElement(CardExpiryElement);
                  const cardCvcElement = elements.getElement(CardCvcElement);

                  if (cardNumberElement && cardExpiryElement && cardCvcElement) {
                    setCardError('');
                    await handleSubmit(event);
                  }
                }}
                trackingLabel="Add New Card Button"
                workflow={AppointmentWorkflow}
                className="mr-4"
              >
                {isAddingPaymentMethod ||
                  (isChangingPaymentMethod && (
                    <div className="pr-2">
                      <LoadingSpinner size="sm" />
                    </div>
                  ))}
                Add New Card
              </Button>
              <button
                id={'cancel-new-card-button'}
                className="rounded-full border text-sm border-rule text-primary font-medium px-3 py-2 hover:opacity-75"
                onClick={() => {
                  setAddCreditCardModalIsOpen(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={changeCreditCardModalIsOpen}
          className="fixed inset-0 flex items-center justify-center bg-secondary/40"
        >
          <ChangeCreditCardModalContent
            selectedPaymentMethod={
              paymentMethodOnAppointment && isPaymentMethod(paymentMethodOnAppointment)
                ? paymentMethodOnAppointment
                : null
            }
            availablePaymentMethods={availablePaymentMethods}
            onAddCardClick={() => {
              setChangeCreditCardModalIsOpen(false);
              setAddCreditCardModalIsOpen(true);
            }}
            onConfirmSelection={(pm: PaymentMethod) => {
              setChangeCreditCardModalIsOpen(false);
              changePaymentMethod.mutate({ method: pm });
            }}
            closeModal={() => setChangeCreditCardModalIsOpen(false)}
          />
        </Modal>
      </div>
    </div>
  );
};

export default PaymentMethodSection;
