import { v4 as uuidv4 } from 'uuid';

import { Appointment } from '../../ServiceContext/appointments';
import {
  Invoice,
  isSplitPayment,
  isValidPaymentMethod,
  PaymentMethod,
} from '../../ServiceContext/invoices';
import { isNumber } from '../../shared/math/math';
import { SplitPaymentMethod } from './PaymentEntry/PaymentEntry';

export type PaymentAllocationEntry = {
  paymentInfo: SplitPaymentMethod;
  previousStatus: 'open' | 'pending' | 'processed' | 'denied' | null;
};

export const createSplitPaymentMethod = (
  appointment: Appointment,
  invoice: Invoice,
  pm?: PaymentMethod,
  defaultAmount?: number
): SplitPaymentMethod => {
  const isInsuranceOnFile = Boolean(appointment.patientInsuranceId);
  const invoiceRemainingTotal = invoice.isDraft
    ? invoice.total
    : isNumber(invoice.balance)
    ? invoice.balance
    : invoice.total;

  if (isInsuranceOnFile) {
    return {
      id: appointment.patientInsuranceId || '',
      methodType: 'insurance',
      amount: 0,
    };
  }

  if (pm) {
    return {
      id: pm.id,
      methodType: 'credit-card',
      amount: defaultAmount || invoiceRemainingTotal,
      stripePaymentMethod: pm,
    };
  }

  return {
    id: uuidv4(),
    methodType: 'credit-card',
    amount: defaultAmount || invoiceRemainingTotal,
    stripePaymentMethod: null,
  };
};

export const createEmptyPaymentAllocationEntry = (amount?: number): PaymentAllocationEntry => {
  return {
    paymentInfo: {
      id: uuidv4(),
      methodType: 'credit-card',
      amount: amount || 0,
      stripePaymentMethod: null,
    },
    previousStatus: null,
  };
};

export function getDefaultPaymentSplits(
  appointment: Appointment,
  invoice: Invoice
): PaymentAllocationEntry[] {
  const { payment, paymentMethod, user: patient } = appointment;

  if (payment && isSplitPayment(payment)) {
    return payment.paymentSplits.map((split) => ({
      paymentInfo: createSplitPaymentMethod(
        appointment,
        invoice,
        split.paymentMethod,
        split.splitAmount
      ),
      previousStatus: split.status || null,
    }));
  }

  if (isValidPaymentMethod(paymentMethod)) {
    return [
      {
        paymentInfo: createSplitPaymentMethod(appointment, invoice, paymentMethod),
        previousStatus: null,
      },
    ];
  }

  const arbitraryPaymentMethodFromPatient =
    patient.paymentMethods && patient.paymentMethods.length > 0 ? patient.paymentMethods[0] : null;

  if (arbitraryPaymentMethodFromPatient) {
    return [
      {
        paymentInfo: createSplitPaymentMethod(
          appointment,
          invoice,
          arbitraryPaymentMethodFromPatient
        ),
        previousStatus: null,
      },
    ];
  }

  if (Boolean(appointment.patientInsuranceId)) {
    return [
      {
        paymentInfo: createSplitPaymentMethod(appointment, invoice),
        previousStatus: null,
      },
    ];
  }

  return [createEmptyPaymentAllocationEntry(invoice.total)];
}

export function getUnusedPatientPaymentMethods(
  patient: Appointment['user'],
  paymentAllocations: PaymentAllocationEntry[]
): PaymentMethod[] {
  if (!patient || !patient.paymentMethods) {
    return [];
  }

  const cardSplitPaymentMethods = paymentAllocations
    .filter((pa) => pa.paymentInfo.methodType === 'credit-card')
    .map((pa) => pa.paymentInfo as SplitPaymentMethod & { methodType: 'credit-card' });

  const usedPaymentMethodIds = cardSplitPaymentMethods
    .filter((cardPa) => Boolean(cardPa.stripePaymentMethod))
    .map((cardPa) => cardPa.stripePaymentMethod!.id);

  return patient.paymentMethods.filter((pm) => !usedPaymentMethodIds.includes(pm.id));
}
