import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ClickTrackingAction, PatientWorkflow, runFTrack } from '../../amplitude';
import { AuthProvider } from '../../Authentication/Authentication';
import { ArrowLeft } from '../../HomePage/components/Icons';
import { RootRedirect } from '../../Routing/Router';
import { PatientDetail } from '../../ServiceContext/patients';
import Alert, { AlertData } from '../../shared/Alert';
import LoadingSpinner from '../../shared/LoadingSpinner';
import PageTopBar from '../../shared/PageTopBar';
import PatientAppointmentsTable from './components/PatientAppointmentsTable';
import PatientFilesTable from './components/PatientFilesTable';
import PatientInformation from './components/PatientInformation';
import PatientTab, { patientTabQueries, PatientTabs } from './components/PatientTab';
import PatientTreatmentPlansTable from './components/PatientTreatmentPlansTable';
import PaymentMethods from './components/PaymentMethods';
import UpcomingAppointment from './components/UpcomingAppointment';
import { usePatient } from './hooks/usePatient';

export const PatientContext = React.createContext<PatientDetail | null>(null);

type Props = {
  authProvider: AuthProvider;
};

const PatientDetailPage: React.FC<Props> = ({ authProvider }) => {
  const navigate = useNavigate();
  const { patientId } = useParams<{ patientId: string }>();
  const { isLoading, data: patient } = usePatient(patientId || null);

  const [alert] = useState<AlertData | null>(null);
  const url = new URL(window.location.href);
  const initialTab = url.searchParams.get('tab') || 'treatment-plans';
  const [selectedTab, setSelectedTab] = useState<PatientTabs>(
    patientTabQueries[initialTab] as PatientTabs
  );

  const handleTabChange = (tab: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set('tab', tab);
    window.history.pushState({}, '', url.toString());
    runFTrack({
      event: 'Select Patient Tab',
      workflow: PatientWorkflow,
      action: ClickTrackingAction,
      context: 'patientDetailPage',
      componentId: 'patientTab',
      extraProps: {
        patientId: patientId,
        tab: tab,
      },
    });
    switch (tab) {
      case 'appointments':
        setSelectedTab(PatientTabs.pastAppointments);
        break;
      case 'treatment-plans':
        setSelectedTab(PatientTabs.treatmentPlans);
        break;
      case 'files':
        setSelectedTab(PatientTabs.additionalFiles);
        break;
    }
  };

  if (!patientId) {
    return <RootRedirect authentication={authProvider} />;
  }

  if (isLoading || (!patient && !alert) || !patient) {
    return <LoadingSpinner />;
  }

  return (
    patient && (
      <PatientContext.Provider value={patient}>
        <div className="flex flex-col gap-4">
          <PageTopBar title={`${patient.firstName} ${patient.lastName}`}>
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => navigate('/patients')}
            >
              <ArrowLeft />
              <div className="text-base-content-navigation text-sm font-bold">
                Return to Patient Directory
              </div>
            </div>
          </PageTopBar>
          {alert && (
            <>
              <div className="separator w-full my-8" />
              <Alert {...alert} />
            </>
          )}
          <div className="flex flex-row">
            <PatientInformation />
            <div className="separator w-8" />
            <UpcomingAppointment
              appointment={
                patient.appointments &&
                patient.appointments.filter((appt) => new Date(appt.start) > new Date()).length > 0
                  ? patient.appointments[0]
                  : null
              }
              authProvider={authProvider}
            />
          </div>
          <PaymentMethods authProvider={authProvider} />
          <div className="w-full items-start flex flex-col">
            <div className="flex gap-8">
              <PatientTab
                tabName="Treatment Plans"
                tabQueryName="treatment-plans"
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setTabQuery={handleTabChange}
                patientTab={PatientTabs.treatmentPlans}
              />
              <PatientTab
                tabName="Past Appointments"
                tabQueryName="appointments"
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setTabQuery={handleTabChange}
                patientTab={PatientTabs.pastAppointments}
              />
              <PatientTab
                tabName="Additional Files"
                tabQueryName="files"
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setTabQuery={handleTabChange}
                patientTab={PatientTabs.additionalFiles}
              />
            </div>
            <div className="border-b-[1px] border-info w-full mt-2 mb-4" />{' '}
            {selectedTab === PatientTabs.pastAppointments && (
              <PatientAppointmentsTable patientAppointmentsData={patient.appointments} />
            )}
            {selectedTab === PatientTabs.treatmentPlans && (
              <PatientTreatmentPlansTable authProvider={authProvider} />
            )}
            {selectedTab === PatientTabs.additionalFiles && (
              <PatientFilesTable authProvider={authProvider} />
            )}
          </div>
        </div>
      </PatientContext.Provider>
    )
  );
};

export default PatientDetailPage;
