import { AuthProvider } from '../Authentication/Authentication';
import { Patient, PatientDirectorySearchResult } from '../ServiceContext/patients';
import { authenticatedGet, authenticatedPost } from '../ServiceContext/service';
import { apiUrl, Timestamp } from '../ServiceContext/shared';
import { ErrorResponse, handleAPIResponse } from './response';

export async function createPatient({
  authProvider,
  practiceId,
  firstName,
  lastName,
  email,
  phone,
  birthDate,
  originalPhone,
  preferredContactMethod,
}: {
  authProvider: AuthProvider;
  practiceId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  birthDate: string;
  originalPhone?: string;
  preferredContactMethod: string;
}): Promise<Patient> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedPost<Patient>(authProvider, apiUrl(`/practices/patients`), {
      practiceId,
      firstName,
      lastName,
      email,
      phone,
      birthDate,
      originalPhone,
      preferredContactMethod,
    })
  );
}

export async function getPatient({
  authProvider,
  patientId,
}: {
  authProvider: AuthProvider;
  patientId: string;
}): Promise<Patient> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }
  return handleAPIResponse(
    authenticatedGet<Patient>(authProvider, apiUrl(`/dentists/patients/${patientId}`))
  );
}

export type SupplementaryFile = {
  id: string;
  cloudFileObjectName: string;
  fileName: string;
  fileType: string | null;
  userId: string;
  userType: 'manager' | 'dentist' | 'user';
  uploadedByUserId: string | null;
  uploadedByUserType: 'manager' | 'dentist' | 'user' | null;
  signedDownloadUrl: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export async function getPatientFiles({
  authProvider,
  patientId,
}: {
  authProvider: AuthProvider;
  patientId: string;
}): Promise<SupplementaryFile[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  return handleAPIResponse(
    authenticatedGet<SupplementaryFile[] | ErrorResponse>(
      authProvider,
      apiUrl(`/practices/patients/${patientId}/files`)
    )
  );
}

export async function getPatients({
  authProvider,
  patientName,
  page,
  pageSize,
  orderBy,
  orderByDirection,
}: {
  authProvider: AuthProvider;
  patientName: string;
  page: number;
  pageSize: number;
  orderBy?: string;
  orderByDirection?: 'ASC' | 'DESC';
}): Promise<PatientDirectorySearchResult> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  let params = new URLSearchParams('');

  params.append('skip', (pageSize * (page - 1)).toString());
  params.append('maxResults', pageSize.toString());
  if (patientName && patientName !== '') {
    params.append('patientName', encodeURIComponent(patientName));
  }

  if (orderBy) {
    params.append('orderBy', orderBy);
  }

  if (orderByDirection) {
    params.append('orderByDirection', orderByDirection);
  }

  return handleAPIResponse(
    authenticatedGet<PatientDirectorySearchResult | ErrorResponse>(
      authProvider,
      apiUrl(`/dentist-patients?${params}`)
    )
  );
}

export async function uploadPatientFile({
  authProvider,
  patientId,
  file,
  fileName,
}: {
  authProvider: AuthProvider;
  patientId: string;
  file: File;
  fileName: string;
}): Promise<SupplementaryFile[]> {
  const authUser = authProvider.authUser;
  if (!authUser) {
    return Promise.reject();
  }

  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', fileName);
  return handleAPIResponse(
    authenticatedPost<SupplementaryFile[] | ErrorResponse>(
      authProvider,
      apiUrl(`/practices/patients/${patientId}/files`),
      formData
    )
  );
}
