import { DetailedTreatmentPlan, TreatmentPlanState } from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import { toShortDateString } from '../../shared/dates/dates';
import TreatmentPlanStatusDropdown from './TreatmentPlanStatusDropdown';

export default function TreatmentPlanStatusSection({
  treatmentPlan,
  authProvider,
}: {
  treatmentPlan: DetailedTreatmentPlan;
  authProvider: AuthProvider;
}) {
  return (
    <div className="flex flex-column text-left px-4 w-1/3">
      <div className="mb-2 text-base flex items-center gap-2">
        <div className="font-bold ">Status:</div>
        {(treatmentPlan.state === TreatmentPlanState.PROPOSED ||
          treatmentPlan.state === TreatmentPlanState.CANCELLED) && (
          <div className="text-sm text-primary border rounded-full px-2 font-bold">
            {treatmentPlan.state.charAt(0).toUpperCase() + treatmentPlan.state.slice(1)}
          </div>
        )}
        {treatmentPlan.state !== TreatmentPlanState.PROPOSED &&
          treatmentPlan.state !== TreatmentPlanState.CANCELLED && (
            <TreatmentPlanStatusDropdown
              treatmentPlan={treatmentPlan}
              authProvider={authProvider}
            />
          )}
      </div>
      <div className="mb-2 text-[16px]">
        <span className="font-bold">Date Created:</span>{' '}
        {toShortDateString(treatmentPlan.createdAt)}
      </div>
      <div className="mb-2 text-[16px]">
        <span className="font-bold">Date Updated:</span>{' '}
        {toShortDateString(treatmentPlan.updatedAt)}
      </div>
    </div>
  );
}
