import React from 'react';

import { Appointment } from '../../ServiceContext/appointments';
import { toShortDateString } from '../../shared/dates/dates';
import logo from '../BookingsAndCredits/InvoiceExport/flossy-branding-bw.svg';
import PayoutInvoiceItemsTable from './PayoutInvoiceItemsTable';

type Props = {
  appointment: Appointment;
};
export const PrintedPayoutInvoice = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { appointment } = props;
  return (
    <div className="content-to-export scale-[0.80]" ref={ref}>
      <div className="branding-row flex justify-start w-full mb-4">
        <img className="w-[150px]" src={logo} id="app-branding" alt="flossy-logo" />
      </div>
      <div id={'basic-info'} className="flex flex-col gap-2 ml-2">
        {appointment.user && (
          <span
            id={'patient-name'}
            className={'font-semibold'}
          >{`${appointment.user?.firstName} ${appointment.user?.lastName}`}</span>
        )}
        {appointment.start && (
          <span id={'appointment-date'} className={'text-sm'}>
            {toShortDateString(appointment.start)}
          </span>
        )}
      </div>
      <PayoutInvoiceItemsTable appointment={appointment} />
    </div>
  );
});
